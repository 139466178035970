import { IPageProp } from "@keri-base/interface/common/IPageProp";
import { Box, Theme, Typography } from "@mui/material";
import { SxProps } from "@mui/system";


const ContectItem = (prop: { children: React.ReactNode, sx?: SxProps<Theme>, title: string | React.ReactNode }) => {
    return (
        <Box
            sx={{
                pr: 5,
                pl: 5,
                flex: '1 1 40%',
                mb: 5,
                mt: 2,
            }}
        >
            <Typography variant="h5" sx={{ fontWeight: '700', mb: 3 }}>
                {prop.title}
            </Typography>
            <Box>
                {prop.children}
            </Box>
        </Box>
    )
}

const ContectItemText = (prop: { label: string }) => {
    return (
        <Typography variant="h6" sx={{ fontWeight: 'normal' , mb : 2 }}>
            {prop.label}
        </Typography>
    )
}

const BusChip = (prop: { color: string, label: string }) => {
    return (
        <Box
            sx={{
                backgroundColor: prop.color,
                borderRadius: '5px',
                p: '0.143rem 0.500rem',
                color: 'white',
                mr: 1,
                fontSize: '1rem',
                userSelect: 'none'
            }}
        >
            {prop.label}
        </Box>
    )
}



const ContectViewPage = (prop: IPageProp) => {

    const tMapIframe = () => {
        return {
            __html: '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1630.3227868480124!2d128.7176187583741!3d35.19038533709145!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3568cdc7c4d09e1d%3A0xe8191e154642f10a!2z7ZWc6rWt7KCE6riw7Jew6rWs7JuQ!5e0!3m2!1sko!2skr!4v1655455831598!5m2!1sko!2skr" width="100%" height="100%" style="border:none" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>'
        }
    }

    return (
        <Box>
            MAP PAGE
        </Box>
        // <BoardDefaultLayout
        //     backgroundImage="http://skinfoodco.mywisa.com/_skin/foodco/img/title/news.jpg"
        //     topHeight={'40.357rem'}
        //     title='센터 오시는 길'
        //     subTitle='Contact'
        // >
        //     <Box
        //         sx={{
        //             background: '#edf1f6',
        //             display: 'flex',
        //             justifyContent: 'center',
        //             pt: 3,
        //             pb: 3
        //         }}
        //     >
        //         <Box
        //             sx={{
        //                 width: '1240px',

        //             }}
        //         >
        //             <Box
        //                 sx={{
        //                     display: 'flex',
        //                     justifyContent: 'center',
        //                     flexDirection: 'column',
        //                     alignItems: 'center'
        //                 }}
        //             >
        //                 <Typography

        //                     sx={{
        //                         m: 1,
        //                         color: '#999'
        //                     }}
        //                 >
        //                     Address
        //                 </Typography>
        //                 <Typography
        //                     variant="h4"
        //                     sx={{
        //                         m: 2
        //                     }}
        //                 >
        //                     경상남도 창원시 성산구 전기의길 12 (성주동)
        //                 </Typography>
        //             </Box>

        //             <Box
        //                 sx={{
        //                     display: 'flex',
        //                     justifyContent: 'center',
        //                     alignItems: 'center',
        //                     mt: 3,
        //                     mb: 3
        //                 }}
        //             >
        //                 <div dangerouslySetInnerHTML={tMapIframe()} style={{ border: 'none', width: '1200px', height: '500px' }} />

        //             </Box>
        //             <Box
        //                 sx={{
        //                     display: 'flex',
        //                     flexWrap: 'wrap'
        //                 }}
        //             >
        //                 <ContectItem
        //                     title='기차 이용 시'
        //                 >
        //                     <ContectItemText label="창원중앙역 | 버스 50분 소요 (환승필요), 택시 15분 소요" />
        //                     <ContectItemText label="창원역 | 버스 60분 소요 (102번 버스 → 한국전기연구원 정류장 하차), 택시 30분 소요" />
        //                 </ContectItem>


        //                 <ContectItem
        //                     title='버스 이용 시'
        //                 >
        //                     <ContectItemText label="창원버스터미널(팔용동) | 버스 50분 소요 (환승필요), 택시 25분 소요" />
        //                     <ContectItemText label="남산시외버스정류소(남산동) | 버스 20분 소요 (17, 102, 214번 한국전기연구원 정류장 하차), 택시 5분 소요" />
        //                     <Box
        //                         sx={{
        //                             display: 'flex',
        //                             justifyContent: 'start',
        //                             mt: 2
        //                         }}
        //                     >
        //                         <BusChip color="#2a54be" label="17" />
        //                         <BusChip color="#2a54be" label="102" />
        //                         <BusChip color="#5ebf44" label="214" />
        //                     </Box>
        //                 </ContectItem>


        //                 <ContectItem
        //                     title='자동차 이용 시'
        //                 >
        //                     <ContectItemText label="서울 방면 | 출발 → 동마산I·C → 창원대로 → 부산(장유) 방향" />
        //                     <ContectItemText label="부산 방면 | 출발 → 장유I·C → 금관대로 → 창원터널 → 창원 방향" />
        //                 </ContectItem>

        //                 <ContectItem
        //                     title='비행기 이용 시'
        //                 >
        //                     <ContectItemText label=" 김해공항(부산)" />
        //                     <ContectItemText label="공항리무진 | 창원(마산)행 승차 → 남산시외버스정류소 하차 → 버스/택시 이용, 택시 30분 소요" />
        //                 </ContectItem>


        //             </Box>
        //         </Box>

        //     </Box>
        // </BoardDefaultLayout>
    )
}

export default ContectViewPage;