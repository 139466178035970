import { ITechServiceBenefit } from "@keri-portal/interfaces/process-object/tech-service/ITechServiceBenefit";
import { ITechServiceRequestAgree, ITechServiceRequestFiles } from "@keri-portal/interfaces/process-object/tech-service/ITechServiceRequest";


const TechServiceCommonValidation = {
    Agree : (data : ITechServiceRequestAgree) : {state : boolean , errorData : ITechServiceRequestAgreeError} => TechServiceRequestAgreeValidationCheck(data),
    RequierFiles : (data : ITechServiceRequestFiles) : {state : boolean , errorData : ITechServiceRequestUploadRequireFilesError} => TechServiceRequestUploadRequireFilesValidationCheck(data),
    Benefit : (data? : ITechServiceBenefit) : {state : boolean , errorData : string | undefined} => TechServiceRequestBenefitValidationCheck(data),
}




export interface ITechServiceRequestAgreeError {
    Agree?: string
}

const TechServiceRequestAgreeValidationCheck = (data : ITechServiceRequestAgree) : {state : boolean , errorData : ITechServiceRequestAgreeError} => {
    
    let state :boolean = true;
    let errorData : ITechServiceRequestAgreeError = {};


    if (!(data.data?.filter(x => x.required && !x.checked).length === 0??false)) {
        state = false;
        errorData.Agree = '기술지원 신청 시 약관 동의는 필수입니다.'
    }

    return {
        state : state,
        errorData : errorData
    }
}




export interface ITechServiceRequestUploadRequireFilesError {
    UploadFiles? : string | undefined
}


const TechServiceRequestUploadRequireFilesValidationCheck = (data : ITechServiceRequestFiles) : {state : boolean , errorData : ITechServiceRequestUploadRequireFilesError} => {
    
    let state :boolean = true;
    let errorData : ITechServiceRequestUploadRequireFilesError = {};


    if(data.data.filter(x=>x.Required === true && x.UploadedFile === undefined).length !== 0){
        state = false;
        errorData.UploadFiles = '필수 업로드 파일이 업로드되지 않았습니다.'
    }

 

    return {
        state : state,
        errorData : errorData
    }
}


const TechServiceRequestBenefitValidationCheck = (data? : ITechServiceBenefit) : {state : boolean , errorData : string |undefined } => {
    let state :boolean = true;
    let errorData : string | undefined = undefined;


    // if(!((data?.AfterSupport?.Sales === undefined && data?.BeforeSupport?.Sales === undefined) || (data?.AfterSupport?.Sales !== undefined && data?.BeforeSupport?.Sales !== undefined))){
    //     state = false;
    //     errorData = "매출(개발 및 관련 제품) 전/후 모두 입력하거나 모두 빈 값이어야 합니다."
    // }

    // if(state === true && !((data?.AfterSupport?.ReduceDevelopmentCost === undefined && data?.BeforeSupport?.ReduceDevelopmentCost === undefined) || (data?.AfterSupport?.ReduceDevelopmentCost !== undefined && data?.BeforeSupport?.ReduceDevelopmentCost !== undefined))){
    //     state = false;
    //     errorData = "개발비용 절감 효과전/후 모두 입력하거나 모두 빈 값이어야 합니다."
    // }

    // if(state === true && !((data?.AfterSupport?.ReduceDevelopmentPeriod === undefined && data?.BeforeSupport?.ReduceDevelopmentPeriod === undefined) || (data?.AfterSupport?.ReduceDevelopmentPeriod !== undefined && data?.BeforeSupport?.ReduceDevelopmentPeriod !== undefined))){
    //     state = false;
    //     errorData = "개발기간 단축 효과 전/후 모두 입력하거나 모두 빈 값이어야 합니다."
    // }


    // if(state === true && !((data?.AfterSupport?.Employ === undefined && data?.BeforeSupport?.Employ === undefined) || (data?.AfterSupport?.Employ !== undefined && data?.BeforeSupport?.Employ !== undefined))){
    //     state = false;
    //     errorData = "고용 전/후 모두 입력하거나 모두 빈 값이어야 합니다."
    // }

    // if(state === true && !((data?.AfterSupport?.MoU === undefined && data?.BeforeSupport?.MoU === undefined) || (data?.AfterSupport?.MoU !== undefined && data?.BeforeSupport?.MoU !== undefined))){
    //     state = false;
    //     errorData = "MoU 전/후 모두 입력하거나 모두 빈 값이어야 합니다."
    // }

    // if(state === true && !((data?.AfterSupport?.InvestmentAttraction === undefined && data?.BeforeSupport?.InvestmentAttraction === undefined) || (data?.AfterSupport?.InvestmentAttraction !== undefined && data?.BeforeSupport?.InvestmentAttraction !== undefined))){
    //     state = false;
    //     errorData = "투자유치 전/후 모두 입력하거나 모두 빈 값이어야 합니다."
    // }

    // if(state === true && !((data?.AfterSupport?.productionRateImprovement === undefined && data?.BeforeSupport?.productionRateImprovement === undefined) || (data?.AfterSupport?.productionRateImprovement !== undefined && data?.BeforeSupport?.productionRateImprovement !== undefined))){
    //     state = false;
    //     errorData = "생산률 개선 전/후 모두 입력하거나 모두 빈 값이어야 합니다."
    // }

    // if(state === true && !((data?.AfterSupport?.productionDefectRateImprovement === undefined && data?.BeforeSupport?.productionDefectRateImprovement === undefined) || (data?.AfterSupport?.productionDefectRateImprovement !== undefined && data?.BeforeSupport?.productionDefectRateImprovement !== undefined))){
    //     state = false;
    //     errorData = "불량률 개선 전/후 모두 입력하거나 모두 빈 값이어야 합니다."
    // }

    // if(state === true && !((data?.AfterSupport?.Vender === undefined && data?.BeforeSupport?.Vender === undefined) || (data?.AfterSupport?.Vender !== undefined && data?.BeforeSupport?.Vender !== undefined))){
    //     state = false;
    //     errorData = "1차 벤더사 등록 전/후 모두 입력하거나 모두 빈 값이어야 합니다."
    // }
    

 

    return {
        state : state,
        errorData : errorData
    }
}

export default TechServiceCommonValidation;