import { ObjectUtils } from "@ict-base/module/utils/ObjectUtils";
import RegexUtil from "@ict-base/module/utils/RegexUtil";
import KeriBaseConfig from "@keri-base/config/KeriBaseConfig";
import { IUserInfoInputData, IUserInfoInputError } from "@keri-portal/interfaces/process-object/account/IUserInfo";

/** 회원정보 수정 관련 Validation Check */
const UserInfoCommonValidationCheck = {
    /** 회원 정보 (비밀번호 제외) Validation Check */
    UpdateInfo : (data : IUserInfoInputData): { state: boolean, errorData: IUserInfoInputError } => UpdateUserInfo(data),
    /** 회원 비밀번호 Validation Check */
    UpdatePassword : (data : IUserInfoInputData): { state: boolean, errorData: IUserInfoInputError } => UpdateUserPassword(data),
}


/** 회원 정보 (비밀번호 제외) Validation Check */
const UpdateUserInfo = (data : IUserInfoInputData): { state: boolean, errorData: IUserInfoInputError } => {
    let state: boolean = true;
    let errorData: IUserInfoInputError = {};

    // 회원명
    if(ObjectUtils.IsNullOrEmpty(data.UserName)){
        state = false;
        errorData.UserName = '필수 값입니다.'
    }

    // 직급
    if(ObjectUtils.IsNullOrEmpty(data.UserRank)){
        state = false;
        errorData.UserRank = '필수 값입니다.'
    }

    // 부서
    if(ObjectUtils.IsNullOrEmpty(data.UserDepartment)){
        state = false;
        errorData.UserDepartment = '필수 값입니다.'
    }

    // 이메일
    if(ObjectUtils.IsNullOrEmpty(data.UserEmail)){
        state = false;
        errorData.UserEmail = '필수 값입니다.'
    }
    else if(!RegexUtil.CheckString(RegexUtil.RegexType.Email, data.UserEmail)){
        state = false;
        errorData.UserEmail = '이메일 형식이 맞지 않습니다.'
    }

    // 전화번호
    if(ObjectUtils.IsNullOrEmpty(data.UserCallNumber)){
        state = false;
        errorData.UserCallNumber = '필수 값입니다.'
    }
    else if(!RegexUtil.CheckString(RegexUtil.RegexType.PhoneNumber, data.UserCallNumber)){
        state = false;
        errorData.UserCallNumber = '번호 형식이 맞지 않습니다.'
    }

    // 휴대전화번호
    if(ObjectUtils.IsNullOrEmpty(data.UserCellPhoneNumber)){
        state = false;
        errorData.UserCellPhoneNumber = '필수 값입니다.'
    }
    else if(!RegexUtil.CheckString(RegexUtil.RegexType.CellPhoneNumber, data.UserCellPhoneNumber)){
        state = false;
        errorData.UserCellPhoneNumber = '번호 형식이 맞지 않습니다.'
    }

    // 인증 확인
    if(!(data.CertProcess?.ValidedCertCode??false)){
        state = false;
        errorData.CertProcess = {
            ...errorData.CertProcess,
            CertCode :  '인증이 완료되지 않았습니다.'
        };
    }



    return {
        state : state,
        errorData : errorData
    }

    
}


const UpdateUserPassword = (data : IUserInfoInputData): { state: boolean, errorData: IUserInfoInputError } => {
    let state: boolean = true;
    let errorData: IUserInfoInputError = {};

     // 비밀번호
     if(ObjectUtils.IsNullOrEmpty(data.UserPassword)){
        state = false;
        errorData.UserPassword = '필수 값입니다.'
    }
    else if(!RegexUtil.CheckString(KeriBaseConfig.PasswordRegExp, data.UserPassword)){
        state = false;
        errorData.UserPassword = '비밀번호 보안 형식에 만족하지 않았습니다.(영문, 숫자, 기호(@$!%*?&)를 조합하여 10자리 이상)'
    }


    // 비밀번호 재입력 (확인용)
    if(data.UserPassword?.trim() !== data.ValidPassword?.trim()){
        state = false;
        errorData.ValidPassword = '입력한 비밀번호가 동일하지 않습니다.'
    }

    return {
        state : state,
        errorData : errorData
    }
}



export default UserInfoCommonValidationCheck;