import { Add } from "@mui/icons-material";
import { Box, Button, Divider, Menu, MenuItem, Typography } from "@mui/material";
import URLStructure from "@src/modules/structure/URLStructure";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const options: { idx: number, name: string, url: string }[] = [
    {
        idx: 1,
        name: '한국산업단지공단',
        url: 'https://www.kicox.or.kr/index.do'
    },
    {
        idx: 2,
        name: '산업통산자원부',
        url: 'http://www.motie.go.kr/www/main.do'
    },
    {
        idx: 3,
        name: '경상남도',
        url: 'https://www.gyeongnam.go.kr/index.gyeong'
    },
    {
        idx: 4,
        name: '창원시',
        url: 'https://www.changwon.go.kr/cwportal/portal.web'
    }
];

const ITEM_HEIGHT = 48;



const KeriFooter = () => {

    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    return (
        <Box
            className='Keri-Footer'
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: '#333',
                pt: 3,
                pb: 3,
                width: '100%',
                // transform : 'translateY(-100%)'
            }}
        >
            <Box
                sx={{
                    // width: '100%',
                    // maxWidth: '1250px',
                    display: 'flex',
                    // justifyContent: 'space-between',
                    justifyContent : 'center',
                    alignItems : 'center',
                    pr: 4,
                    pl: 4,
                    cursor : 'default'
                }}
            >
                <Box>
                    <img
                        src={`${URLStructure.Asset}/img/ict/images/logo_f.svg`}
                        style={{
                            width: '120px',
                            // marginTop: 5
                        }}
                    />
                </Box>
                <Box
                    sx={{
                        flexGrow: 2,
                        ml: 10,
                        color: '#aaa',
                        mr: '70px'
                    }}
                >
                    <Box
                        sx={{
                            mb : 1,
                            display : 'flex',
                            justifyContent : 'start',
                            alignItems : 'center',
                            flexWrap : 'wrap'
                        }}
                    >
                        <Typography
                            sx={{
                                fontWeight: 500,
                                mb: '2px',
                                color: '#e7af3b',
                                cursor: 'pointer',
                                mr : 2
                            }}
                            onClick={() => {
                                window.open(
                                    'https://www.keri.re.kr/html/kr/guide/guide_0702.html',
                                    '_blank'
                                );
                            }}
                        >
                            개인정보처리방침
                        </Typography>

                        <Typography
                            sx={{
                                fontWeight: 500,
                                mb: '2px',
                                // color: '#e7af3b',
                                cursor: 'pointer',
                                mr : 2
                            }}
                            onClick={() => navigate(URLStructure.UsingPur)}
                        >
                            이용약관
                        </Typography>

                        <Typography
                            sx={{
                                fontWeight: 500,
                                mb: '2px',
                                // color: '#e7af3b',
                                cursor: 'pointer',
                                mr : 2
                            }}
                            onClick={() => navigate(URLStructure.AboutCenterVisitCenter)}
                        >
                            찾아오시는길
                        </Typography>

                    </Box>
                    <Box
                        sx={{
                            mb : 1,
                            display : 'flex',
                            justifyContent : 'start',
                            alignItems : 'center',
                            flexWrap : 'wrap'
                        }}
                    >
                        <Typography
                            sx={{
                                fontWeight: 500,
                                mb: '2px',
                                // color: '#e7af3b',
                                // cursor: 'pointer',
                                // ml : 2
                            }}
                            // onClick={() => navigate(URLStructure.AboutCenterVisitCenter)}
                        >
                            연락처 : 055-280-1568
                        </Typography>

                        <Typography
                            sx={{
                                fontWeight: 500,
                                mb: '2px',
                                // color: '#e7af3b',
                                // cursor: 'pointer',
                                ml : 2
                            }}
                            // onClick={() => navigate(URLStructure.AboutCenterVisitCenter)}
                        >
                            이메일 : mingyu@keri.re.kr
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                    >
                        <Typography sx={{ fontWeight: 500, mb: '2px' }}>
                            KERI for the Electrified World
                        </Typography>

                    </Box>

                    <Typography variant="body2" >
                        국민에게 신뢰받고, 국익 중심의 새로운 핵심 가치를 실현하는 세계 최고 수준의 전기전문연구기관
                    </Typography>
                    <Typography variant="body2" >
                        주소 : (51543) 경상남도 창원시 성산구 전기의길12 (성주동) 한국전기연구원 스마트이노베이션센터
                    </Typography>
                    <Typography variant="body2" sx={{ mt: 1, color: 'gray' }}>
                        COPYRIGHTⓒ2022 Korea Electrotechnology Research Institute
                    </Typography>
                </Box>
                <Box>


                </Box>


            </Box>
        </Box>
    )
}

export default KeriFooter;