import { IHookState, IKeriAPIHookBaseProp, IKeriAPIHookResult, IKeriAPIHookResultPagination } from "@keri-portal/interfaces/api/hook/IAPIHook";
import { IEducationItem } from "@keri-portal/interfaces/data-object/data-common/IEducation";
import RequestKeriAPI from "@keri-portal/module/method/api-connect/RequestKeriAPI";
import { useState, useMemo, useEffect } from "react";

export interface IUseGetEducationScheduleInfo extends IKeriAPIHookBaseProp{
    scheduleID? : string
}

const useGetEducationScheduleInfo = (prop : IUseGetEducationScheduleInfo) => {
    const [hookState , setHookState] = useState<IHookState<IKeriAPIHookResult<IEducationItem>>>({
        loading : false,
        data : undefined,
        complete : false
    });

    

    const getData = async () => {
        if(prop.scheduleID !== undefined){
            setHookState({
                loading : true,
                complete : false
            })
    
            const callAPI = await RequestKeriAPI.Education.GetEducationScheduleInfo({
                    scheduleID : prop.scheduleID
            })
    
    
            setHookState({
                loading: false,
                data: {
                    state: callAPI.state,
                    message: callAPI.message,
                    statusCode: callAPI.statusCode,
                    resultData : callAPI.resultData,
                },
                complete: true
            })
        }
        else{
            // setHookState({
            //     loading: false,
            //     data: {
            //         state: false,
            //         message: '교육과정 ID가 없습니다.',
            //         statusCode: 500,
            //         resultData : undefined,
            //     },
            //     complete: true
            // })
        }
        
    
    }

    const returnData = useMemo(() => getData() , [prop.scheduleID , prop.TriggerTime]);
  

    useEffect(() => {
        returnData
        .finally(() => {
        })
    },[prop])

    return hookState;
}


export default useGetEducationScheduleInfo;