import { Box, Button, CircularProgress, createTheme, Divider, SxProps, Theme, ThemeProvider } from "@mui/material";
import KeriTheme from "@keri-portal/config/KeriTheme";
import React from "react";
import { LoadingButton } from "@mui/lab";

interface IKeriActionButton {
    label: string
    variant?: "text" | "outlined" | "contained" | undefined
    icon?: React.ReactNode,
    color?: "primary" | "secondary" | "success" | "error" | "info" | "warning" | undefined,
    onClick: (e : React.MouseEvent<HTMLButtonElement , MouseEvent>) => void
    sx?: SxProps<Theme>,
    fullWidth?: boolean,
    disabled?: boolean,
    component?: React.ReactNode,
    size?: 'small' | 'medium' | 'large',
    isLoading?: boolean,
    className? : string,
    buttonRef? : React.RefObject<HTMLButtonElement>
}

const KeriActionButton = (prop: IKeriActionButton) => {


    // Button Design
    const buttonTheme = createTheme({
        palette: KeriTheme.KeriPalette,
        components: KeriTheme.KeriComponent.KeriActionButton
    })


    return (
        <ThemeProvider theme={buttonTheme}>
            <LoadingButton
                className={prop.className}
                variant={prop.variant}
                color={prop.color}
                disabled={prop.isLoading || prop.disabled}
                size={prop.size ?? 'medium'}
                ref={prop.buttonRef}
                sx={{
                    boxShadow: 'none',
                    textTransform: 'none',
                    borderRadius: 2,
                    mt: 1,
                    mb: 1,
                    whiteSpace: 'nowrap',
                    width: (prop.fullWidth ?? false) ? '100%' : undefined,
                    ...prop.sx
                }}
                {...(prop.component !== undefined ? { component: 'label' } : {})}
                startIcon={
                    prop.icon && !prop.isLoading &&
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'start',
                            '& svg': {
                                transform: 'scale(0.8)'
                            }
                        }}
                    >
                        {prop.icon}
                        <Divider orientation="vertical" flexItem sx={{ borderColor: '#aaa', ml: 1, mr: 0.5, mt: 0.5, mb: 0.5 }} />
                    </Box>

                }
                onClick={(e) => !prop.isLoading && prop.onClick(e)}
                {...(
                    prop.variant === 'text' &&
                    {
                        disableRipple: true,
                        disableFocusRipple: true,
                        disableTouchRipple: true,
                        disableElevation: true
                    }
                )}

            >
                {
                    !prop.isLoading
                        ?
                        <React.Fragment>
                            {prop.label}
                            {prop.component}
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <CircularProgress
                                color="inherit"
                                sx={{ 
                                    width : '20px !important',
                                    height : '20px !important'
                                 }}
                            />
                        </React.Fragment>
                }

            </LoadingButton>
        </ThemeProvider>
    )
}

export default KeriActionButton;