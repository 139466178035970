import { Box, Typography, TextField, Button, InputAdornment, TextFieldProps, IconButton } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { ObjectUtils } from "@ict-base/module/utils/ObjectUtils";
import { useTimer } from 'react-timer-hook';
import { Visibility, VisibilityOff } from "@mui/icons-material";
import KeriActionButton from "@ict-base/components/control/KeriActionButton";
import { KeriEditGrid, KeriEditGridInputField } from "@ict-base/components/control/KeriEditGrid";
import CallAPI from "@ict-base/module/utils/CallAPI";
import {  IAPISendCertCodeRequestBody, IAPIValidCertCodeRequestBody } from "@keri-portal/interfaces/api/keri-api/IKeriAPIRequest";
import { IKeriAPIResponse } from "@keri-portal/interfaces/api/keri-api/IKeriAPIResponse";
import { IPageProp } from "@keri-base/interface/common/IPageProp";
import RegLib from "@keri-portal/module/method/RegLib";
import KeriLogo from "../../common/KeriLogo";
import URLStructure from "@src/modules/structure/URLStructure";
import RequestKeriAPI from "@keri-portal/module/method/api-connect/RequestKeriAPI";
import { PlatformError } from "@keri-base/interface/common/PlatformError";

interface IFindPasswordComp {
    pageControl: {
        activePage: number,
        setActivePage: React.Dispatch<React.SetStateAction<number>>
    },
    pageProp : IPageProp
}


const FindPasswordComp = (prop: IFindPasswordComp) => {

    const [inputID , setInputID] = useState<string | undefined>(undefined);


    const resetPassword = async () => {
        try{
            if(inputID === undefined){
                throw new PlatformError("아이디가 빈 값입니다.");
            }

            const setReset = await RequestKeriAPI.Account.ResetUserPassword(inputID);

            if(setReset.state){
                alert(setReset.message);
                prop.pageControl.setActivePage(0)
            }
            else{
                throw new PlatformError(setReset.message)
            }
        }
        catch (e) {
            let errMessage : string | undefined = undefined;
            if(e instanceof PlatformError){
                errMessage = e.message;
            }
            else{
                errMessage = '시스템 에러입니다.'
            }   

            alert(errMessage);
        }
    }


    useEffect(() => {
        setInputID(undefined);
    },[])



    return (
        <Box>
            <Box>
                <KeriLogo style={{ width: '90%' }} moveURL={URLStructure.Main} />
            </Box>
            <Box sx={{ mb: 3, mt: 2 }}>
                <Typography variant='h6' sx={{ fontWeight: 'bold', userSelect: 'none', textTransform: 'uppercase' }}>
                    비밀번호 초기화
                </Typography>
                <Typography variant='body1' letterSpacing={-0.1} lineHeight={1.2} sx={{ mt: 1, color: '#75868f', userSelect: 'none', fontSize: '0.8rem' }}>
                    로그인 ID를 입력하고 ‘비밀번호 초기화’를 누르세요. <br />
                    등록된 이메일 주소로 인증번호가 발송됩니다.
                </Typography>
            </Box>
            <Box>
               <TextField 
               fullWidth
                    value={inputID}
                    size='small'
                    onChange={(e) => {
                        setInputID(e.currentTarget.value);
                    }}

               />
            </Box>
            <Box sx={{ mt: 2 }}>
                <KeriActionButton
                    variant='contained'
                    fullWidth
                    onClick={() => resetPassword()}
                    color="primary"
                    label="비밀번호 초기화"
                    sx={{
                        m: 0,
                        fontWeight: 400
                    }}
                />
                <KeriActionButton
                    variant='contained'
                    fullWidth
                    onClick={() => prop.pageControl.setActivePage(0)}
                    color="info"
                    label="뒤로가기"
                    sx={{
                        mt: 1,
                        fontWeight: 400
                    }}
                />

            </Box>
        </Box>
    )
}


export default FindPasswordComp;