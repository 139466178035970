import { IKeriAPIHookSearchProp, IHookState, IKeriAPIHookResultPagination, IKeriAPIHookBaseProp } from "@keri-portal/interfaces/api/hook/IAPIHook";
import { IEducationCategory, IEducationItem } from "@keri-portal/interfaces/data-object/data-common/IEducation";
import RequestKeriAPI from "@keri-portal/module/method/api-connect/RequestKeriAPI";
import { useState, useMemo, useEffect } from "react";

export interface IUseGetEducationCategory extends IKeriAPIHookBaseProp {

}


const useGetEducationCategory = (prop: IUseGetEducationCategory) => {
    // const userRedux = useSelector((state : any) => state.user);
    const [hookState, setHookState] = useState<IHookState<IKeriAPIHookResultPagination<IEducationCategory[]>>>({
        loading: false,
        data: undefined,
        complete: false
    });



    const getData = async () => {
        setHookState({
            loading: true,
            complete: false
        })

        const callAPI = await RequestKeriAPI.Education.GetEducationCategory();

        setHookState({
            loading: false,
            data: {
                state: callAPI.state,
                message: callAPI.message,
                statusCode: callAPI.statusCode,
                resultData : callAPI.resultData,
            },
            complete: true
        })

    }

    const returnData = useMemo(() => getData(), [prop.TriggerTime]);


    useEffect(() => {
        returnData
            .finally(() => {
            })
    }, [prop])

    return hookState;
}

export default useGetEducationCategory;