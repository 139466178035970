import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useRef, useState } from "react";
import { ObjectUtils } from "@ict-base/module/utils/ObjectUtils";
import KeriActionButton from "@ict-base/components/control/KeriActionButton";
import { KeriEditGrid, KeriEditGridInputField } from "@ict-base/components/control/KeriEditGrid";
import RegLib from "@keri-portal/module/method/RegLib";
import KeriLogo from "../../common/KeriLogo";
import URLStructure from "@src/modules/structure/URLStructure";
import RegexUtil from "@ict-base/module/utils/RegexUtil";
import RequestKeriAPI from "@keri-portal/module/method/api-connect/RequestKeriAPI";

interface IFindAccountComp {
    pageControl: {
        activePage: number,
        setActivePage: React.Dispatch<React.SetStateAction<number>>
    }
}

interface IDataState {
    state: boolean,
    errorMessage: string | undefined
}

const FindAccountComp = (prop: IFindAccountComp) => {

    const [findAccountErrorState, setFindAccountErrorState] = useState<IDataState>({
        state: false,
        errorMessage: undefined
    });

    const [inputData , setInputData] = useState<string | undefined>(undefined);


    const handleFindAccount = async () => {

        if (ObjectUtils.IsNullOrEmpty(inputData)) {
            setFindAccountErrorState({
                state: true,
                errorMessage: '이메일 주소를 입력해주세요.'
            });

            return;
        }

        if(!RegexUtil.CheckString(RegexUtil.RegexType.Email , inputData)){
            setFindAccountErrorState({
                state: true,
                errorMessage: '이메일 형식이 맞지 않습니다.'
            });

            return;
        }


        const requestFindAccount = await RequestKeriAPI.Account.FindAccountID(inputData);

        alert(requestFindAccount.message);
    }



    return (
        <Box>
            <Box>
                <KeriLogo style={{ width: '90%' }} moveURL={URLStructure.Main} />
            </Box>
            <Box sx={{ mb: 2, mt: 2 }}>
                <Typography variant='h6' sx={{ fontWeight: 'bold', userSelect: 'none', textTransform: 'uppercase' }}>
                    아이디 찾기
                </Typography>
                <Typography variant='body1' letterSpacing={-0.1} lineHeight={1.2} sx={{ mt: 1, color: '#75868f', userSelect: 'none', fontSize: '0.8rem' }}>
                    이메일 주소를 입력하고 ‘로그인 ID 찾기’를 누르세요. <br />
                    이메일 주소로 로그인 ID가 발송됩니다.
                </Typography>
            </Box>
            <Box>
                <KeriEditGrid
                    sx={{
                        p: 0,
                    }}
                >
                    <KeriEditGridInputField
                        disableTitle
                        props={{
                            placeHolder: 'Email Address',
                            sx: {
                                p: 0,
                                mb: 0
                            }
                        }}
                        error={{
                            isError: findAccountErrorState.state,
                            message: findAccountErrorState.errorMessage
                        }}
                        onChange={(text) => {
                            setInputData(ObjectUtils.IsNullOrEmpty(text) ? undefined : text);
                            if (findAccountErrorState.state) {
                                setFindAccountErrorState({
                                    state: false,
                                    errorMessage: undefined
                                })
                            }
                        }}
                        onKeyUp={(e) => {
                            if (e.key === 'Enter') {
                                handleFindAccount();
                            }
                        }}
                    />
                </KeriEditGrid>


            </Box>
            <Box sx={{ mt: 3 }}>
                <KeriActionButton
                    variant='contained'
                    fullWidth
                    onClick={() => handleFindAccount()}
                    color="primary"
                    label="아이디 찾기"
                    sx={{
                        m : 0,
                        fontWeight : 400
                    }}
                />
                <KeriActionButton
                    variant='contained'
                    fullWidth
                    onClick={() => prop.pageControl.setActivePage(0)}
                    color="info"
                    label="뒤로가기"
                    sx={{
                        mt : 1,
                        fontWeight : 400
                    }}
                />
            </Box>
        </Box>
    )
}

export default FindAccountComp;