import KeriActionButton from "@ict-base/components/control/KeriActionButton";
import LoginChecker from "@keri-base/components/common/LoginChecker";
import { IPageProp } from "@keri-base/interface/common/IPageProp";
import KeriBoardRating from "@keri-portal/component/control/board/KeriBoardRating";
import InfoLayoutPage from "@keri-portal/component/layout/InfoLayoutPage";
import { IFaqItem } from "@keri-portal/interfaces/data-object/data-common/IBoard";
import RequestKeriAPI from "@keri-portal/module/method/api-connect/RequestKeriAPI";
import { RemoveRedEyeOutlined } from "@mui/icons-material";
import { Box, CircularProgress, Divider, Paper, Typography } from "@mui/material";
// import { ILoginSessionData } from "@src/modules/redux/reducer/UserDataReducer";
import URLStructure from "@src/modules/structure/URLStructure";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const FaqBoardItemInfoPage = (prop: IPageProp) => {

    // const [loginData, setLoginData] = useState<ILoginSessionData | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(true);
    const [faqData, setFaqData] = useState<IFaqItem | undefined>(undefined);
    const param = useParams();
    const boardID = 'BBSMSTR_D99999999999';
    const boardItemID = param.boardItemID;
    const navigate = useNavigate();


    const getBoardData = async () => {
        setLoading(true);
        const getData = await RequestKeriAPI.Board.GetFaqItemData(boardItemID);

        if (getData.state) {
            setFaqData(getData.resultData);
        }
        else {
            alert(getData.message);
        }

        setLoading(false);
    }


    useEffect(() => {
        getBoardData();

        // eslint-disable-next-line
    }, [])


    return (
        <LoginChecker
            onCheck={(state, data) => {
                // if (state === 'LOGIN') {
                //     setLoginData(data);
                // }
                // else if (state === 'UNLOGIN') {
                //     setLoginData(undefined);
                // }
            }}
        >
            {
                loading
                    ?
                    <InfoLayoutPage>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                pt: 20
                            }}
                        >
                            <CircularProgress color="inherit" />
                        </Box>
                    </InfoLayoutPage>
                    :
                    <React.Fragment>
                        {
                            faqData === undefined
                                ?
                                <InfoLayoutPage>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            pt: 20
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                textAlign: 'center'
                                            }}
                                        >
                                            <img src={`${URLStructure.Main}assets/img/ict/images/top_logo.svg`} alt="logo"/>
                                            <Typography
                                                variant='h4'
                                                sx={{
                                                    mb: 4,
                                                    mt: 10
                                                }}
                                            >
                                                페이지를 찾을 수 없습니다.
                                            </Typography>
                                            <Typography
                                                onClick={() => navigate(-1)}
                                                sx={{
                                                    cursor: 'pointer',
                                                    transition: '0.5s all cubic-bezier(0,0,0,1)',
                                                    ":hover": {
                                                        color: '#52b5b0'
                                                    }
                                                }}
                                            >
                                                이전 페이지로 이동
                                            </Typography>
                                        </Box>

                                    </Box>
                                </InfoLayoutPage>

                                :
                                <InfoLayoutPage
                                    title={'자주 묻는 질문과 답'}
                                >
                                    <Paper
                                        sx={{
                                            p: 3,
                                            pr: 5,
                                            pl: 5
                                        }}
                                    >

                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'end'
                                            }}
                                        >
                                            <Box>
                                                <Typography
                                                    variant="h6"
                                                    sx={{
                                                        overflow: 'wrap'
                                                    }}
                                                >
                                                    {faqData.Subject}
                                                </Typography>
                                            </Box>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'end',
                                                    alignItems: 'end',
                                                    color: '#aaa',
                                                    '& *': {
                                                        fontSize: '0.8rem'
                                                    }
                                                }}
                                            >
                                                {/* 조회수 */}
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent: 'start',
                                                        alignItems: 'center',
                                                        ml: 1
                                                    }}
                                                >
                                                    <RemoveRedEyeOutlined
                                                        sx={{
                                                            mr: 0.5,
                                                            fontSize: '1.0rem !important',
                                                        }}
                                                    />
                                                    <Typography>
                                                        {faqData.viewCount ?? 0}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Divider sx={{ mt: 1, mb: 1 }} />
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'start',
                                                alignItems: 'center',
                                                // height: '30px',
                                                mt: 2,
                                                pl: 1,
                                                borderLeft: '4px solid gray'
                                            }}
                                        >
                                            <Typography>
                                                <div dangerouslySetInnerHTML={{__html : faqData.FaqQuestion}} />
                                            </Typography>
                                        </Box>
                                        <Box
                                            sx={{
                                                pt: 2,
                                                pb: 2
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    overflow: 'wrap'
                                                }}
                                            >
                                                <div dangerouslySetInnerHTML={{ __html: faqData.FaqAnswer ?? '' }} />
                                            </Typography>
                                        </Box>
                                        {
                                            boardItemID !== undefined &&
                                            <KeriBoardRating
                                                boardID={boardID}
                                                boardItemID={boardItemID}
                                            />
                                        }

                                    </Paper>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'end',
                                            alignItems: 'end'
                                        }}
                                    >
                                        <KeriActionButton
                                            label="목록"
                                            variant="contained"
                                            color="info"
                                            onClick={() => {
                                                navigate(`${process.env.PUBLIC_URL}/board/${boardID}`);
                                            }}
                                        />
                                    </Box>

                                </InfoLayoutPage>
                        }
                    </React.Fragment>

            }

        </LoginChecker>
    )
}

export default FaqBoardItemInfoPage;