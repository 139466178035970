import KeriScrollBox from "@ict-base/components/control/KeriScrollBox";
import { IKeriStepShareProps, KeriStepItemLayout, KeriStepItemContent, KeriStepItemSubContent } from "@keri-portal/component/layout/StepLayout";
import { IAccountRegister } from "@keri-portal/interfaces/process-object/account/IAccountRegister";


const fakeHTMLFirst = `
<h4 class="card-title bold h4 mb-2">회원 가입 절차 안내</h4>
<p class="card-text text-left"> 
    <ol>
        <li>
            <span class="h5 bold">1. 회원 가입은 다음과 같은 절차에 의해 진행됩니다.</span>
            <ul class="circle_list ml40">
                <li class="circle_item">개인정보 제공에 동의합니다.</li>
                <li class="circle_item">기업 정보를 입력합니다.</li>
                <li class="circle_item">회원 정보를 입력합니다.</li>
                <li class="circle_item">회원 가입 신청서를 제출합니다.</li>
                <li class="circle_item">시스템 관리자는 신청서의 내용을 확인하고 회원 가입을 승인합니다.</li>
            </ul>
        </li>
        <li>
            <span class="h5 bold">2. 회원 가입이 불허되는 경우</span>
            <ul class="num_list ml40">
                <li>기업 회원 계정이 이미 존재하는 경우</li>
                <li>기업 회원 계정이 이미 존재하는 경우</li>
                <li>기업 회원 계정이 이미 존재하는 경우</li>
            </ul>
        </li>
    </ol>
</p>
`;



const KeriRegisterStep1Item = (prop: IKeriStepShareProps<IAccountRegister>) => {

    return (
        <KeriStepItemLayout
            {...prop}
            validationCheck={() => true}
            step={prop.step}
            page={{
                moveNext: (setup, validation) => {
                    if (validation) {
                        setup(prop.step.activeStep + 1);
                    }
                }
            }}
        >
            <KeriStepItemContent
                title='회원 가입 안내 (요약)'
            >
                <KeriStepItemSubContent>
                    <KeriScrollBox height={400}>
                        <div dangerouslySetInnerHTML={{
                            __html: fakeHTMLFirst
                        }} />
                    </KeriScrollBox>
                </KeriStepItemSubContent>
            </KeriStepItemContent>
        </KeriStepItemLayout>
    )
}

export default KeriRegisterStep1Item;