import { ILoginUserVO } from '@keri-portal/interfaces/api/keri-api/object/ILoginUserVO';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import SessionStorageServ from '@ict-base/module/utils/SessionStorageServ';
import { IMenuItem } from '@keri-portal/interfaces/data-object/data-common/IMenu';
import { IKeriMenuCategoryItem } from '@ictpeople/misc-core';




export const MenuCategoryReducer = createSlice({
    name : 'menuCategory',
    initialState : [] as IKeriMenuCategoryItem[],
    reducers : {
        setMenuCategory : (state, action : PayloadAction<IKeriMenuCategoryItem[]>) => {
            // SessionStorageServ.setData(process.env.REACT_APP_USER_LOCAL_STORAGE_KEY, action.payload);
            return action.payload;
        },
        resetMenuCategory : () => {
            // SessionStorageServ.removeData(process.env.REACT_APP_USER_LOCAL_STORAGE_KEY)
            return [] as IKeriMenuCategoryItem[]
        }
    }
})


export const { setMenuCategory, resetMenuCategory } = MenuCategoryReducer.actions;
export default MenuCategoryReducer.reducer;