import { GetRandomString } from "@ictpeople/common";
import { IKeriAlert, IKeriAlertObject, IKeriMenuCategoryItem, IKeriUserRedux } from "@ictpeople/misc-core";
import { IUseGetMenuCategoryList, useGetMenuCategoryList } from "@ictpeople/misc-react";
import { ArrowForward, ArrowForwardIos, Close, LockOpen, Logout, Menu as MenuIcon, Person, Settings } from "@mui/icons-material";
import { Avatar, Badge, Box, Button, Container, Divider, IconButton, Paper, Typography, useMediaQuery } from "@mui/material";
import { setMenuCategory } from "@src/modules/redux/reducer/MenuCategoryReducer";
import { ILoginSessionData, removeUser } from "@src/modules/redux/reducer/UserDataReducer";
import URLStructure from "@src/modules/structure/URLStructure";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

type HeaderViewMode = 'PC' | 'MOBILE' | 'TABLET';

interface IMISCHeader {
    viewMode?: HeaderViewMode,
    height?: string | number,
    loginData?: IKeriUserRedux,
    alertData?: IKeriAlert,
}


const MISCHeader = (prop: IMISCHeader) => {

    const mainHeaderRef = useRef<HTMLDivElement>(null);
    const [hoverMenu, setHoverMenu] = useState<IKeriMenuCategoryItem | undefined>(undefined);
    const [hoverDropDown, setHoverDropDown] = useState<IKeriMenuCategoryItem | undefined>(undefined);
    const [openAllMenuDropDown, setOpenAllMenuDropDown] = useState<boolean>(false);
    const [openUserInfoDropDown, setOpenUserInfoDropDown] = useState<boolean>(false);

    const menuCategoryRedux: IKeriMenuCategoryItem[] = useSelector((state: any) => state.menuCategory);


    useMemo(() => openAllMenuDropDown === true && setOpenUserInfoDropDown(false), [openAllMenuDropDown]);
    useMemo(() => openUserInfoDropDown === true && setOpenAllMenuDropDown(false), [openUserInfoDropDown]);

    useMemo(() => {
        if (openAllMenuDropDown === true || openUserInfoDropDown === true) {
            document.body.style.overflow = 'hidden';
        }
        else {
            document.body.style.overflow = 'auto';
        }
    }, [openAllMenuDropDown, openUserInfoDropDown])

    return (
        <Box
            className="misc-header"
        >
            <MISCMainHeader
                headerRef={mainHeaderRef}
                onHover={(item) => setHoverMenu(item)}
                menuList={menuCategoryRedux}
                viewMode={prop.viewMode}
                onSiteMapState={[openAllMenuDropDown, setOpenAllMenuDropDown]}
                onUserInfoState={[openUserInfoDropDown, setOpenUserInfoDropDown]}
                loginData={prop.loginData}
                alertData={prop.alertData}
            />
            <MISCDropDownHeader
                mainRef={mainHeaderRef}
                onHover={(item) => {
                    setHoverMenu(item);
                    setHoverDropDown(item);
                }}
                open={(hoverMenu !== undefined || hoverDropDown !== undefined)}
                activeCategory={hoverMenu}
                onClick={() => {
                    setOpenAllMenuDropDown(false);
                    setOpenUserInfoDropDown(false);
                }}
            />

            <MISCAllCategoryDropDownHeader
                open={openAllMenuDropDown}
                onClose={() => setOpenAllMenuDropDown(false)}
                mainRef={mainHeaderRef}
                viewMode={prop.viewMode}
            />

            <MISCDropDownUserInfoHeader
                open={openUserInfoDropDown}
                onClose={() => setOpenUserInfoDropDown(false)}
                mainRef={mainHeaderRef}
                viewMode={prop.viewMode}
                alertData={prop.alertData}
            />
        </Box>
    )
}



interface IMISCMainHeader {
    headerRef?: React.RefObject<HTMLDivElement>,
    onHover?: (item?: IKeriMenuCategoryItem) => void,
    menuList?: IKeriMenuCategoryItem[],
    viewMode?: HeaderViewMode,
    onSiteMapState?: [state: boolean, changeFunction: (state: boolean) => void],
    onUserInfoState?: [state: boolean, changeFunction: (state: boolean) => void],
    loginData?: IKeriUserRedux,
    alertData?: IKeriAlert
}

const MISCMainHeader = (prop: IMISCMainHeader) => {

    const navigate = useNavigate();
    const location = useLocation();



    return (
        <Box
            className="header-main-box"
            sx={{
                width: '100%',
                position: 'fixed',
                top: 0,
                left: 0,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderBottom: '1px solid rgba(0,0,0,0.1)',
                zIndex: '999',
                background: 'white',
                height: '60px',
            }}
            ref={prop.headerRef}
        >
            <Box
                className="header-container"
                sx={{
                    maxWidth: '1600px',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    pr: 2,
                    pl: 2,
                    height: '100%'
                }}
            >
                <Box
                    className="logo-box"
                    sx={{
                        display: 'flex',
                        justifyContent: 'start',
                        alignItems: 'center',
                        width: prop.viewMode === 'MOBILE' ? '150px' : undefined
                    }}
                >
                    <img
                        src={`assets/img/ict/images/top_logo.svg`}
                        style={{
                            height: '30px',
                            cursor: 'pointer',
                            maxWidth: '100%'
                        }}
                        onClick={() => {
                            prop.onSiteMapState && prop.onSiteMapState[1](false);
                            prop.onUserInfoState && prop.onUserInfoState[1](false);
                            navigate(process.env.PUBLIC_URL);
                        }}
                    />
                </Box>
                <Box
                    className="header-action-box"
                    sx={{
                        display: 'flex',
                        justifyContent: 'end',
                        alignItems: 'center',
                        height: '100%'
                    }}
                >
                    <Box
                        className="header-menu-category-box"
                        sx={{
                            display: prop.viewMode === 'PC' ? 'flex' : 'none',
                            justifyContent: 'end',
                            alignItems: 'center',
                            height: '100%'
                        }}
                    >
                        {
                            (prop.menuList ?? []).map(mainCategory => (
                                <Box
                                    className={`header-menu-${mainCategory.idx}`}
                                    key={mainCategory.idx}
                                    onMouseEnter={() => prop.onHover && prop.onHover(mainCategory)}
                                    onMouseLeave={() => prop.onHover && prop.onHover(undefined)}
                                    sx={{
                                        cursor: 'pointer',
                                        pl: 2,
                                        pr: 2,
                                        height: '100%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Typography variant="subtitle1" sx={{ fontWeight: '500' }}>
                                        {mainCategory.title}
                                    </Typography>
                                </Box>
                            ))
                        }
                    </Box>


                    <Box
                        className="header-login-bt-box"
                        sx={{
                            ml: 2
                        }}
                    >
                        {
                            (prop.loginData?.token === undefined || (prop.loginData?.expireData === undefined || prop.loginData.expireData.getTime() < Date.now())) &&
                            <Button
                                onClick={() => navigate(`${URLStructure.UserLogin}?redirect=${location.pathname}${location.search}`)}
                            >
                                <LockOpen sx={{ mr: 1 }} /> {'로그인 / 회원가입'}
                            </Button>
                        }


                    </Box>
                    <Box
                        className="header-user-info-bt-box"
                    >
                        {
                            (prop.loginData?.token !== undefined && (prop.loginData?.expireData !== undefined && prop.loginData.expireData.getTime() > Date.now())) &&
                            <IconButton
                                onClick={() => prop.onUserInfoState && prop.onUserInfoState[1](!prop.onUserInfoState[0])}
                            >
                                <Badge badgeContent={(prop.alertData?.unreadTechServiceComment ?? []).length} color="primary" >
                                    <Person />
                                </Badge>

                            </IconButton>
                        }


                    </Box>
                    <Box
                        className="header-menu-bugger-bt-box"
                        sx={{
                            // display: prop.viewMode === 'MOBILE' ? undefined : 'none',

                        }}
                    >
                        <IconButton
                            onClick={() => prop.onSiteMapState && prop.onSiteMapState[1](!prop.onSiteMapState[0])}
                        >
                            <MenuIcon
                                sx={{
                                    m: 0,
                                    p: 0
                                }}
                            />
                        </IconButton>

                    </Box>
                </Box>
            </Box>
        </Box>
    )
}


interface IMISCDropDownHeader {
    open: boolean,
    mainRef?: React.RefObject<HTMLDivElement>,
    activeCategory?: IKeriMenuCategoryItem,
    onHover?: (item?: IKeriMenuCategoryItem) => void,
    onClick?: () => void
}

const MISCDropDownHeader = (prop: IMISCDropDownHeader) => {

    const navigate = useNavigate();

    const [lastActiveCategory, setLastActiveCategory] = useState<IKeriMenuCategoryItem | undefined>(undefined);

    useMemo(() => {
        if (prop.activeCategory !== undefined) {
            setLastActiveCategory(prop.activeCategory);
        }
    }, [prop.activeCategory])


    return (
        <Box
            sx={{
                // height: "500px",
                width: '100%',
                background: 'white',
                position: 'fixed',
                top: prop.mainRef?.current?.clientHeight ?? 0,
                left: 0,
                zIndex: '990',
                transform: prop.open ? 'translateY(0%)' : 'translateY(-100%)',
                transition: '0.3s all cubic-bezier(0,0,0,1)',
                borderBottom: '1px solid rgba(0,0,0,0.1)',
                userSelect: 'none',
                pt: 4,
                pb: 4
            }}
            onMouseEnter={() => prop.onHover && prop.onHover(lastActiveCategory)}
            onMouseLeave={() => prop.onHover && prop.onHover(undefined)}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'start',
                    alignItems: 'center'
                }}
            >
                <Box
                    sx={{
                        width: "30%",
                        minWidth: '100px',
                        p: 1,
                        height: 'auto',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            minHeight: '100px',
                            height: '100%'
                        }}
                    >
                        <Typography
                            variant="h5"
                        >
                            {prop.activeCategory?.title}
                        </Typography>
                    </Box>
                </Box>
                <Divider orientation="vertical" flexItem sx={{ borderColor: 'rgba(0,0,0,0.2)', ml: 0.5, mr: 0.5, mt: 2, mb: 2 }} />
                <Box
                    sx={{
                        width: "70%",
                        maxWidth: 'calc(100% - 100px)',
                        p: 1
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'start',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                            userSelect: 'none',
                        }}
                    >
                        {
                            (prop.activeCategory?.children ?? []).map(x => (
                                <Box
                                    key={GetRandomString(10)}
                                    sx={{
                                        width: '33%',
                                        height: "50px",
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        cursor: 'pointer',
                                        ":hover": {
                                            '& *': {
                                                color: 'blue'
                                            }
                                        }
                                    }}
                                    onClick={() => {
                                        x.url && navigate(x.url)
                                        prop.onClick && prop.onClick();
                                        prop.onHover && prop.onHover(undefined)
                                    }}
                                >

                                    <Typography>
                                        {x.title}
                                    </Typography>
                                </Box>
                            ))
                        }
                    </Box>

                </Box>
            </Box>
        </Box>
    )
}


interface IMISCAllCategoryDropDownHeader {
    open: boolean,
    onClose?: () => void,
    mainRef?: React.RefObject<HTMLDivElement>,
    viewMode?: HeaderViewMode
}

const MISCAllCategoryDropDownHeader = (prop: IMISCAllCategoryDropDownHeader) => {

    const navigate = useNavigate();

    const menuCategoryRedux: IKeriMenuCategoryItem[] = useSelector((state: any) => state.menuCategory);



    return (
        <Box
            className="header-sitemap"
            sx={{
                height: '100%',
                width: '100%',
                background: 'white',
                position: 'fixed',
                top: prop.mainRef?.current?.clientHeight ?? 0,
                left: 0,
                zIndex: '800',
                transform: prop.open ? 'translateY(0%)' : 'translateY(-100%)',
                transition: '0.3s all cubic-bezier(0,0,0,1)',
                borderBottom: '1px solid rgba(0,0,0,0.1)',
                pb: '40px'
            }}
        >
            <Box
                className="header-sitemap-close-bt-box"
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '50px',
                }}
            >
                <IconButton
                    sx={{
                        // border: '1px solid rgba(0,0,0,0.3)',
                        p: 0.5
                    }}
                    onClick={() => prop.onClose && prop.onClose()}
                >
                    <Close
                        sx={{
                            color: 'rgba(0,0,0,0.3)'
                        }}
                    />
                </IconButton>

            </Box>
            <Divider sx={{ mr: 2, ml: 2 }} />
            <Box
                className="header-sitemap-item-list-box"
                sx={{
                    p: 1,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'start',
                    textAlign: 'center',
                    overflow: 'auto',
                    height: 'calc(100% - 50px)',
                    pb: 10,
                    scrollbarWidth: '1px',
                    scrollMargin: 0,
                    '&::-webkit-scrollbar': {
                        width: '0.4em',
                        borderRadius: '5px',
                    },
                    '&::-webkit-scrollbar-track': {
                        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: 'rgba(0,0,0,.2)',
                        borderRadius: '10px'
                    },
                }}
            >
                <Box
                    sx={{
                        maxWidth: '1600px',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'start',
                        alignItems: 'start',
                        flexWrap: 'wrap'
                    }}
                >
                    {
                        menuCategoryRedux.map(menuCategory => (
                            <MISCHeaderSiteMapItem
                                key={`${GetRandomString(10)}-${menuCategory.idx}`}
                                item={menuCategory}
                                viewMode={prop.viewMode}
                                onClick={(itemUrl) => {
                                    itemUrl && navigate(itemUrl);
                                    prop.onClose && prop.onClose();
                                }}
                            />
                        ))
                    }

                </Box>



            </Box>
        </Box>
    )
}

interface IMISCHeaderSiteMapItem {
    item: IKeriMenuCategoryItem,
    viewMode?: HeaderViewMode,
    onClick: (url?: string) => void
}

const MISCHeaderSiteMapItem = (prop: IMISCHeaderSiteMapItem) => {



    return (
        <Box
            className="header-sitemap-item"
            sx={{
                width: prop.viewMode === 'MOBILE' ? '100%' : prop.viewMode === 'TABLET' ? '50%' : '33%',
                textAlign: 'center',
                p: 1,
                mb: 2,
                cursor: 'default',
                userSelect: 'none',

            }}
        >
            <Box
                className="sitemap-title"

            >
                <Box
                    sx={{
                        p: 1,
                        // border: '1px solid black'
                    }}
                >
                    <Typography
                        variant="h6"
                        sx={{
                            fontWeight: '700'
                        }}
                    >
                        {prop.item.title}
                    </Typography>
                </Box>

            </Box>
            <Divider sx={{ mr: 2, ml: 2 }} />
            <Box
                className="sitemap-child-item"
            >
                {
                    (prop.item.children ?? []).map(childItem => (
                        <Box
                            key={`${GetRandomString(10)}-${childItem.idx}`}
                            sx={{
                                pt: 2,
                                pb: 1,
                                cursor: 'pointer',
                                transition: 'all 0.5s ease-out',
                                ":hover": {
                                    '& *': {
                                        color: "blue",
                                        fontWeight: 'bold'
                                    }

                                }
                            }}
                            onClick={() => {
                                prop.onClick && prop.onClick(childItem.url)
                            }}
                        >
                            <Typography
                                variant="subtitle1"
                                sx={{
                                    transition: 'none'
                                }}
                            >
                                {childItem.title}
                            </Typography>

                        </Box>
                    ))
                }
            </Box>

        </Box>
    )
}



interface IMISCDropDownUserInfoHeader {
    viewMode?: HeaderViewMode,
    open: boolean,
    onClose: () => void,
    mainRef?: React.RefObject<HTMLDivElement>,
    alertData?: IKeriAlert
}

const MISCDropDownUserInfoHeader = (prop: IMISCDropDownUserInfoHeader) => {

    const navigate = useNavigate();
    const userRedux: ILoginSessionData = useSelector((state: any) => state.user);
    const dispatch = useDispatch();




    //#region ALERT DATA 





    //#endregion






    return (
        <Box
            className="header-user-info"
            sx={{
                height: '100%',
                width: '100%',
                background: 'white',
                position: 'fixed',
                top: prop.mainRef?.current?.clientHeight ?? 0,
                left: 0,
                zIndex: '800',
                transform: prop.open ? 'translateY(0%)' : 'translateY(-100%)',
                transition: '0.3s all cubic-bezier(0,0,0,1)',
                borderBottom: '1px solid rgba(0,0,0,0.1)',
                pb: '40px'
            }}
        >
            <Box
                className="header-user-info-close-bt-box"
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '50px',
                }}
            >
                <IconButton
                    sx={{
                        // border: '1px solid rgba(0,0,0,0.3)',
                        p: 0.5
                    }}
                    onClick={() => prop.onClose && prop.onClose()}
                >
                    <Close
                        sx={{
                            color: 'rgba(0,0,0,0.3)'
                        }}
                    />
                </IconButton>

            </Box>
            <Divider sx={{ mr: 2, ml: 2 }} />
            <Box
                className="header-user-info-item-list-box"
                sx={{
                    p: 1,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'start',
                    textAlign: 'center',
                    overflow: 'auto',
                    height: 'calc(100% - 50px)',
                    pb: 10,
                    scrollbarWidth: '1px',
                    scrollMargin: 0,
                    '&::-webkit-scrollbar': {
                        width: '0.4em',
                        borderRadius: '5px',
                    },
                    '&::-webkit-scrollbar-track': {
                        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: 'rgba(0,0,0,.2)',
                        borderRadius: '10px'
                    },
                }}
            >
                <Box
                    sx={{
                        maxWidth: '1600px',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        flexWrap: 'wrap'
                    }}
                >
                    <Box
                        sx={{
                            mt: 2,
                            mb: 2,
                            textAlign: 'center',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column'
                        }}
                    >
                        <Avatar
                            sx={{
                                mb: 2,
                                width: '70px',
                                height: '70px',
                                fontSize: '30px'
                            }}
                        >
                            {userRedux.resultVO?.name?.slice(0, 1).toUpperCase()}
                        </Avatar>
                        <Typography
                            variant="h6"
                        >
                            {userRedux.resultVO?.name} 님 환영합니다.
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            mb: 2
                        }}
                    >
                        <Button
                            variant="outlined"
                            sx={{
                                width: '180px',
                                color: 'black',
                                borderColor: 'rgba(0,0,0,0.7)',
                                borderRadius: '20px'
                            }}
                            startIcon={<Settings />}
                            onClick={() => {
                                navigate(`${process.env.PUBLIC_URL}/member-info/basic-info`);
                                prop.onClose();
                            }}
                        >
                            회원 정보
                        </Button>
                        <Button
                            variant="outlined"
                            sx={{
                                ml: 1,
                                width: '180px',
                                color: 'black',
                                borderColor: 'rgba(0,0,0,0.7)',
                                borderRadius: '20px'
                            }}
                            startIcon={<Logout />}
                            onClick={() => {
                                dispatch(removeUser());
                                // navigate(`${process.env.PUBLIC_URL}/login`)
                                prop.onClose();
                            }}
                        >
                            로그아웃
                        </Button>
                    </Box>

                    <Divider sx={{ width: "95%" }} />


                    <Box
                        sx={{
                            mt: 2,
                            maxWidth: '1600px',
                            width: "100%",
                            ...(prop.viewMode === 'MOBILE'
                                ?
                                {
                                }
                                :
                                {
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'start',
                                }
                            ),

                        }}
                    >
                        <Box
                            sx={{
                                width: prop.viewMode === 'MOBILE' ? '100%' : '50%',
                                pr: 5,
                                pl: 5
                            }}
                        >
                            <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                                메세지 리스트
                            </Typography>
                            {
                                (prop.alertData?.unreadTechServiceComment ?? []).map(x => (
                                    <MISCUserAlertItemBox
                                        key={`${x.alertDate?.toStringFormat('yyyy-MM-dd')}-${GetRandomString(10)}`}
                                        item={x}
                                        openLink={(link) => {
                                            link && navigate(link);
                                            prop.onClose();
                                        }}
                                    />
                                ))
                            }
                            {
                                (prop.alertData?.unreadTechServiceComment ?? []).length === 0 &&
                                <Paper
                                    elevation={0}
                                    sx={{
                                        border: '1px solid rgba(0,0,0,0.1)',
                                        p: 1,
                                        cursor: 'default',
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            p: 3
                                        }}
                                    >
                                        읽지 않은 메세지가 없습니다.
                                    </Typography>
                                </Paper>
                            }
                        </Box>
                        <Box
                            sx={{
                                width: prop.viewMode === 'MOBILE' ? '100%' : '50%',
                                pr: 5,
                                pl: 5,
                                ...(prop.viewMode === 'MOBILE' && { mt: 3 })
                            }}
                        >
                            <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                                Menu List
                            </Typography>
                            <Box>
                                <MISCUserActionButton title="신청 기술지원 내역 " onClick={() => {
                                    navigate(URLStructure.MemberInfoTechServiceList);
                                    prop.onClose();
                                }}/>
                            </Box>
                        </Box>
                    </Box>
                </Box>




            </Box>
        </Box>
    )
}


//#region User Alert Item Box

interface IMISCUserAlertItemBox {
    item: IKeriAlertObject,
    openLink?: (link?: string) => void
}

const MISCUserAlertItemBox = (prop: IMISCUserAlertItemBox) => {
    return (
        <Paper
            elevation={0}
            sx={{
                border: '1px solid rgba(0,0,0,0.1)',
                p: 1,
                cursor: 'pointer',
                ":hover": {
                    boxShadow: '0px 4px 4px #14658759'
                }
            }}
            onClick={() => {
                prop.openLink && prop.openLink(prop.item.link)
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <Typography
                    variant="body2"
                >
                    {prop.item.title}
                </Typography>
                <Typography
                    variant="body2"
                >
                    {prop.item.alertDate?.toStringFormat('yyyy-MM-dd')}
                </Typography>
            </Box>
            <Box
                sx={{
                    textAlign: 'end',
                    mt: 1
                }}
            >
                <Typography
                    variant="h6"
                >
                    {prop.item.subTitle}
                </Typography>
            </Box>

        </Paper>
    )
}

//#endregion


//#region User Action Button

interface IMISCUserActionButton {
    title: string,
    onClick: () => void
}

const MISCUserActionButton = (prop: IMISCUserActionButton) => {
    return (
        <Button
            sx={{
                border: '1px solid rgba(0,0,0,0.1)',
                borderRadius : '20px',
                p: 1.5,
                pr : 2,
                pl : 2,
                cursor: 'pointer',
                width : '100%',
                ":hover": {
                    boxShadow: '0px 4px 4px #14658759'
                },
                
            }}
            onClick={prop.onClick}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width : "100%",
                    color : 'CaptionText'
                }}
            >
                <Typography
                    variant="subtitle1"
                >
                    {prop.title}
                </Typography>
                <Typography
                    variant="body2"
                >
                    <ArrowForwardIos />
                </Typography>
            </Box>
        </Button>
    )
}

//#endregion


export default MISCHeader;