import { ObjectUtils } from "@ict-base/module/utils/ObjectUtils"
import { IUploadFileVO } from "@keri-portal/interfaces/api/keri-api/object/IUploadFileVO"
import { IUploadFile } from "@keri-portal/interfaces/control/IUploadFile"
import RequestKeriAPI from "@keri-portal/module/method/api-connect/RequestKeriAPI"
import { AddCircleOutline, AddCircleSharp, Delete } from "@mui/icons-material"
import { Box, CircularProgress, IconButton, LinearProgress, Paper, SxProps, Theme } from "@mui/material"
import { DefaultScrollDesign } from "@src/modules/styles/DefaultScrollDesign"
import { useEffect, useState } from "react"

interface IICTDropFile {
    value: IUploadFile<IUploadFileVO>[],
    onChange: (item: IUploadFile<IUploadFileVO>[]) => void,
    itemProps?: {
        limitCount?: number,
        category?: string,
        prefix?: string
    }
    boxProps?: {
        sx?: SxProps<Theme>
    }
}


const ICTDropImageFile = (prop: IICTDropFile) => {

    const wait = (timeToDelay: number) => new Promise((resolve) => setTimeout(resolve, timeToDelay))
    const [dropFileList, setDropFileList] = useState<IUploadFile<IUploadFileVO>[]>([]);
    const [uploading, setUploading] = useState<number>(100);

    const uploadFile = async (item: IUploadFile<IUploadFileVO>): Promise<IUploadFile<IUploadFileVO> | undefined> => {


        const getResponse = await RequestKeriAPI.File.UploadFile(item);
        console.log(getResponse);

        if (getResponse.state) {
            getResponse.data && setDropFileList([
                ...dropFileList,
                getResponse.data
            ]);
            return getResponse.data;
        }
        else {
            return undefined;
        }
    }

    useEffect(() => {
        prop.onChange(dropFileList)
    }, [dropFileList])



    const UploadInputComponent = () => (<input
        hidden
        // accept={prop.uploadFilter ?? '*'}
        accept='image/*'
        type="file"
        id="icon-button-video"
        multiple
        onChange={async (e) => {

            let fileList: File[] = Array.from(e.target.files ?? []);

            if (fileList.find(x => !(/image(.*?)/g.test(x.type))) !== undefined) {
                alert('이미지가 아닌 파일이 있습니다. \n이미지가 아닌 파일은 제외하고 업로드됩니다.');
            }


            fileList = fileList.filter(x => (/image(.*?)/g.test(x.type)))

            if (prop.itemProps?.limitCount !== undefined && dropFileList.length + fileList.length > prop.itemProps?.limitCount) {
                alert(`업로드 제한 파일 수(${prop.itemProps?.limitCount})가 초과되었습니다.`)
            }
            else {
                setUploading(0);
                let uploadCompleteList: IUploadFile<IUploadFileVO>[] = [];

                const uploadFileList: IUploadFile<IUploadFileVO>[] = fileList.map((x, idx) => ({
                    Index: fileList.length + idx,
                    Category: prop.itemProps?.category ?? 'thumbnail',
                    Prefix: prop.itemProps?.prefix ?? 'THUMB_',
                    Label: 'THUMBNAIL',
                    OriginFile: x
                }) as IUploadFile<IUploadFileVO>)

                for await (const x of uploadFileList) {
                    await wait(100)
                    const data = await uploadFile(x);
                    data && uploadCompleteList.push(data);
                    setUploading(uploadCompleteList.length / uploadFileList.length * 100);
                }

                console.log('fin', uploadCompleteList);




                setDropFileList([
                    ...dropFileList,
                    ...uploadCompleteList
                ])


            }
            setUploading(100);
        }}
    />)




    return (
        <Box
            className="ICT-DropImageField"
            sx={{
                width: '100%',
                border: '1px solid rgba(0,0,0,0.2)',
                borderRadius: '5px',
                minHeight: '100px',
                ...prop.boxProps?.sx
            }}
            onDragOver={(e) => {
                e.preventDefault();
                e.stopPropagation();

                // setDropOver(true);
            }}
            onDragLeave={(e) => {
                e.preventDefault();
                e.stopPropagation();

                // setDropOver(false);
            }}
            onDrop={async (e) => {
                e.preventDefault();
                e.stopPropagation();

                // setDropOver(true);

                let fileList: File[] = Array.from(e.dataTransfer.files);

                console.log('dropDown', fileList)

                if (fileList.find(x => !(/image(.*?)/g.test(x.type))) !== undefined) {
                    alert('이미지가 아닌 파일이 있습니다. \n이미지가 아닌 파일은 제외하고 업로드됩니다.');
                }


                fileList = fileList.filter(x => (/image(.*?)/g.test(x.type)))

                if (prop.itemProps?.limitCount !== undefined && dropFileList.length + fileList.length > prop.itemProps?.limitCount) {
                    alert(`업로드 제한 파일 수(${prop.itemProps?.limitCount})가 초과되었습니다.`)
                }
                else {
                    setUploading(0);
                    let uploadCompleteList: IUploadFile<IUploadFileVO>[] = [];

                    const uploadFileList: IUploadFile<IUploadFileVO>[] = fileList.map((x, idx) => ({
                        Index: fileList.length + idx,
                        Category: prop.itemProps?.category ?? 'thumbnail',
                        Prefix: prop.itemProps?.prefix ?? 'THUMB_',
                        Label: 'THUMBNAIL',
                        OriginFile: x
                    }) as IUploadFile<IUploadFileVO>)

                    // const foreachPromise = new Promise<IUploadFile<IUploadFileVO>[]>((resolve, reject) => {
                    //     uploadFileList.forEach(async (x, idx, arr) => {
                    //         // await wait(500);
                    //         await uploadFile(x);
                    //     })
                    // })

                    for await (const x of uploadFileList) {
                        await wait(100)
                        const data = await uploadFile(x);
                        data && uploadCompleteList.push(data);
                        setUploading(uploadCompleteList.length / uploadFileList.length * 100);
                    }

                    console.log('fin', uploadCompleteList);




                    setDropFileList([
                        ...dropFileList,
                        ...uploadCompleteList
                    ])


                }



            }}
        >
            {
                uploading !== 100 ?
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100px'
                        }}
                    >
                        <LinearProgress
                            variant="determinate"
                            value={uploading}
                            sx={{
                                width: '90%',
                                height: '15px',
                                borderRadius: '10px'
                            }}
                        />
                    </Box>
                    :
                    <Box
                        sx={{
                            p: 2,
                            display: 'flex',
                            justifyContent: 'start',
                            flexWrap: 'wrap',
                            maxHeight: '300px',
                            overflowY: 'scroll',
                            ...DefaultScrollDesign
                        }}
                    >

                        {
                            dropFileList.sort((x, y) => x.Index - y.Index).map(x => (
                                <ICTDromImageItem
                                    key={ObjectUtils.RandomStrings(10)}
                                    data={x}
                                    onDelete={(item) => {
                                        setDropFileList([
                                            ...dropFileList.filter(x => x.UploadedFile?.streFileNm !== item.UploadedFile?.streFileNm)
                                        ])
                                    }}
                                />
                            ))
                        }
                        {
                            (prop.itemProps?.limitCount === undefined || dropFileList.length < prop.itemProps.limitCount) &&
                            <Paper
                                elevation={0}
                                sx={{
                                    p: 1,
                                    m: 1,
                                    textAlign: 'center',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    ...(dropFileList.length === 0 ? { width: '100%' } : {}),
                                }}
                            >
                             

                                <UploadInputComponent />
                                <label htmlFor="icon-button-video">
                                    <IconButton component="span">
                                        <AddCircleSharp
                                            sx={{
                                                fontSize: '2.5rem'
                                            }}
                                        />
                                    </IconButton>
                                </label>
                            </Paper>
                        }
                    </Box>
            }

        </Box>
    )
}

interface IICTDromImageItem {
    data: IUploadFile<IUploadFileVO>,
    onDelete: (item: IUploadFile<IUploadFileVO>) => void,

}

const ICTDromImageItem = (prop: IICTDromImageItem) => {

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [imageURL, setImageURL] = useState<string>('');
    const [completeLoad, setCompleteLoad] = useState<boolean>(false);





    useEffect(() => {
        const reader = new FileReader();

        reader.onload = function () {
            setImageURL(reader.result as string);
        };

        reader.readAsDataURL(prop.data.OriginFile!);
    }, [prop.data])

    return (
        <Paper
            elevation={3}
            sx={{
                p: 1,
                m: 1,
                textAlign: 'center'
            }}
        >
            <Box>
                <Box>
                    <Box
                        sx={{
                            width: '80px',
                            height: '80px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            background: 'rgba(0,0,0,0.1)'
                        }}
                    >
                        <img
                            src={imageURL}
                            style={{
                                maxWidth: '80px',
                                maxHeight: '80px',
                            }}
                        />
                    </Box>

                    <Box>
                        <IconButton
                            sx={{ p: 0, m: 0 }}
                            onClick={() => {
                                prop.onDelete(prop.data)
                            }}
                        >
                            <Delete />
                        </IconButton>
                    </Box>
                </Box>
            </Box>
        </Paper>
    )
}


export default ICTDropImageFile;