import { CSSProperties } from "react";
import { useNavigate } from "react-router-dom";

interface IKeriLogo {
    style?: CSSProperties | undefined,
    moveURL?: string,
    isTop? :boolean
}

const LOGO_FILE_URL = `${process.env.PUBLIC_URL}/assets/img/ict/images/logo.svg`
const LOGO_TOP_FILE_URL=`${process.env.PUBLIC_URL}/assets/img/ict/images/top_logo.svg`

const KeriLogo = (prop: IKeriLogo) => {

    const navigate = useNavigate();

    return (
        <img
            src={(prop.isTop??false) ? LOGO_TOP_FILE_URL : LOGO_FILE_URL}
            style={{
                cursor : prop.moveURL!==undefined ? 'pointer' : undefined,
                ...prop.style
            }}
                
            onClick={() => {
                if(prop.moveURL !== undefined){
                    navigate(prop.moveURL);
                }
            }}
        />
    )
}

export default KeriLogo;