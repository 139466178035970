import { Typography, Box } from "@mui/material";
import URLStructure from "@src/modules/structure/URLStructure";
import { useNavigate, useParams } from "react-router-dom";

const Error404Page = () => {

    const navigate = useNavigate();
    const param = useParams();
    const errorState = param.state;

    let errorMessage = '현재 페이지를 이용할 수 없습니다.'

    switch(errorState){
        case '404' : errorMessage = '페이지를 찾을 수 없습니다.'; break;
        case '500' : errorMessage = '서버와 연결이 원활하지 않습니다.';break;
    }

    return (
        <Box
            sx={{
                background: "#edf1f6",
                width: '100%',
                height : '100%',
                display : 'flex',
                justifyContent : 'center',
                alignItems : 'center'
            }}

        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Box
                    sx={{
                        textAlign: 'center'
                    }}
                >
                    <img src={`${URLStructure.Main}assets/img/ict/images/top_logo.svg`} style={{width : '400px'}}/>
                    <Typography
                        variant='h4'
                        sx={{
                            mb: 4,
                            mt: 10,
                            fontFamily : 'Noto Sans KR, Roboto'
                        }}
                    >
                        {errorMessage}
                    </Typography>
                    <Typography
                        onClick={() => navigate(URLStructure.Main)}
                        sx={{
                            cursor: 'pointer',
                            fontFamily : 'Noto Sans KR, Roboto',
                            transition: '0.5s all cubic-bezier(0,0,0,1)',
                            ":hover": {
                                color: '#52b5b0'
                            }
                        }}
                    >
                        메인으로 이동
                    </Typography>
                </Box>

            </Box>
        </Box>

    )
}

export default Error404Page;