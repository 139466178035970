const RegexType = {
    /** 이메일 */
    Email: /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
    /** 전화번호 (집전화 , 휴대전화 통합) */
    PhoneNumber: /^[0-9]{2,3}-?[0-9]{3,4}-?[0-9]{4}$/,
    /** 휴대전화번호 (휴대전화 전용) */
    CellPhoneNumber: /^[0-9]{3}-?[0-9]{3,4}-?[0-9]{4}$/,
    /** 법인등록번호 */
    CorporateRegistrationNumber: /^[0-9]{6}-?[0-9]{7}$/,
    /** 사업자 등록번호 */
    CompanyRegistrationNumber: /^[0-9]{3}-?[0-9]{2}-?[0-9]{5}$/,
    /** 숫자만 */
    Number: /\d/,
    /** 문자만 */
    String: /\s/,
}



/** 정규식 체크 Util */
const RegexUtil = {
    /** 
     * 정규식 체크
     * @param regexType 정규식 (`RegexUtil.RegexType` 사용)
     * @param data 비교 문자열
     */
    CheckString: (regexType: RegExp, data: string | undefined): boolean => CheckRegex(regexType, data),
    ConvertRegexString: {
        CompanyRegistrationNumber: (data: string | undefined) => ConvertRegexString(/^(\d{3})(\d{2})(\d{5})$/, `$1-$2-$3`, data, /[^0-9]/g),
        PhoneNumber: (data: string | undefined) => ConvertRegexString(/^(02|01[0-9]{1}|0[3-6]{1}[1-5]{1})(\d{3,4})(\d{4})$/, `$1-$2-$3`, data, /[^0-9]/g),
        CellPhoneNumber : (data : string | undefined) => ConvertRegexString(/^(\d{3})(\d{3,4})(\d{4})$/, `$1-$2-$3`, data, /[^0-9]/g),
        CorporateRegistrationNumber : (data: string | undefined) => ConvertRegexString(/^(\d{6})(\d{7})$/, `$1-$2`, data, /[^0-9]/g),
    },
    RegexType: RegexType
}


const CheckRegex = (regexType: RegExp, data: string | undefined): boolean => {
    return regexType.test(data ?? '');
}

const ConvertRegexString = (regexType: RegExp, resultFormat: string, data: string | undefined, replaceString?: RegExp | string) => {
    let strData = data;
    if (replaceString !== undefined) {
        strData = strData?.replace(replaceString, '');
    }

    return strData?.replace(regexType, resultFormat);
}

export default RegexUtil;