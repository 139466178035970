import KeriActionButton from "@ict-base/components/control/KeriActionButton";
import { KeriEditGrid, KeriEditGridEmptyText, KeriEditGridInputField } from "@ict-base/components/control/KeriEditGrid";
import { IKeriStepShareProps, KeriStepItemLayout, KeriStepItemContent } from "@keri-portal/component/layout/StepLayout";
import KeriSearchCompanyModal from "@keri-portal/component/modal/KeriSearchCompanyModal";
import { IAccountRegisterCompany, IAccountRegister } from "@keri-portal/interfaces/process-object/account/IAccountRegister";
import InterfaceExtensionMethod from "@keri-portal/module/method/extension-method/InterfaceExtensionMethod";
import { Info, PanoramaFishEye } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { useState } from "react";

const KeriRegisterStepSearchCompanyItem = (prop: IKeriStepShareProps<IAccountRegister>) => {

    const [formData, setFormData] = useState<IAccountRegisterCompany | undefined>(prop.result.data.ExistCompanyInfoData ?? undefined);

    const validationCheck = (): boolean => true;

    const [openCompanyModal, setOpenCompanyModal] = useState<boolean>(false);

    return (
        <KeriStepItemLayout
            {...prop}
            validationCheck={validationCheck}
            page={{
                moveNext: (setup, validation) => {
                    if (formData !== undefined) {
                        if (validation) {
                            prop.result.onChange({
                                ...prop.result.data,
                                ExistCompanyInfoData: formData
                            });
                            setup(prop.step.activeStep + 2);
                        }

                    }
                    else {
                        if (validation) {
                            prop.result.onChange({
                                ...prop.result.data,
                                ExistCompanyInfoData: undefined
                            });
                            setup(prop.step.activeStep + 1);
                        }
                    }
                }
            }}
        >

            <KeriStepItemContent
                title='기업정보 검색'
                endAdornment={

                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'end',
                            alignItems: 'center'
                        }}
                    >
                        {
                            formData !== undefined &&
                            <KeriActionButton
                                label="초기화"
                                variant="outlined"
                                color="primary"
                                sx={{
                                    m: 0,
                                    mr : 1
                                }}
                                onClick={() => {
                                    setFormData(undefined);
                                }}
                            />
                        }
                        <KeriActionButton
                            label="검색"
                            variant="outlined"
                            color="primary"
                            sx={{
                                m: 0
                            }}
                            onClick={() => {
                                setOpenCompanyModal(true);
                            }}
                        />
                    </Box>

                }
            >
                <KeriEditGrid>
                    {
                        formData === undefined &&
                        <KeriEditGridEmptyText>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexDirection: 'column'
                                }}
                            >
                                <Box
                                    sx={{
                                        textAlign: 'center',
                                        mb: 3,
                                        color: '#2F5597'
                                    }}
                                >
                                    <Info sx={{mb : 1}}/>

                                    <Typography>
                                        사업자등록번호를 기준으로 이미 등록된 기업인지 여부를 확인하는 단계입니다.
                                    </Typography>

                                    <Typography>
                                        '검색' 기능을 이용해 기업의 등록 여부를 확인해주시기 바랍니다.
                                    </Typography>
                                </Box>

                                <Box
                                    sx={{
                                        width: "600px",
                                    }}
                                >

                                    <Typography>
                                        <PanoramaFishEye sx={{ fontSize: '0.7rem', mr: 1 }} />
                                        검색된 기업 정보가 있는 경우, 검색 결과에서 해당 항목(기업)을 선택하고 '다음' 버튼을 누르면 '개인정보 입력' 단계로 바로 이동합니다.
                                    </Typography>
                                </Box>


                                <Box
                                    sx={{
                                        mt: 1,
                                        width: "600px"
                                    }}
                                >
                                    <Typography>
                                        <PanoramaFishEye sx={{ fontSize: '0.7rem', mr: 1 }} />
                                        검색된 기업 정보가 없는 경우, '다음' 버튼을 누르면 '신규 기업 등록' 단계로 이동합니다.
                                    </Typography>
                                </Box>

                            </Box>

                        </KeriEditGridEmptyText>
                    }
                    {
                        formData !== undefined &&
                        [
                            <KeriEditGridInputField
                                editDisabled
                                value={(formData.CompanyRegistrationNumber ?? '').replace(/[^0-9]/g, '').replace(/^(\d{3})(\d{2})(\d{5})$/, `$1-$2-$3`)}
                                title="사업자등록번호"
                                props={{
                                    sx: {
                                        width: '50%'
                                    }
                                }}
                            />,
                            <KeriEditGridInputField
                                editDisabled
                                value={(formData.CEOName ?? '').replace(/[^0-9]/g, '').replace(/^(\d{6})(\d{7})$/, `$1-$2`)}
                                title="대표자명"
                                props={{
                                    sx: {
                                        width: '50%'
                                    }
                                }}
                            />
                            ,
                            <KeriEditGridInputField
                                editDisabled
                                value={formData.BusinessName ?? ''}
                                title="상호명"
                                props={{
                                    sx: {
                                        width: '50%'
                                    }
                                }}
                            />
                            ,
                            <KeriEditGridInputField
                                editDisabled
                                value={`${(formData.CompanyAddress?.roadAddr ?? '')} ${formData.CompanyDetailAddress}`}
                                title="주소"
                                props={{
                                    sx: {
                                        width: '100%'
                                    }
                                }}
                            />
                        ]
                    }
                    {
                        formData !== undefined &&
                        <KeriEditGridEmptyText
                            sx={{
                                pt: 5,
                                pb: 1
                            }}
                        >
                            <Typography>
                                해당 기업 정보로 가입합니다.
                            </Typography>
                        </KeriEditGridEmptyText>
                    }
                </KeriEditGrid>
            </KeriStepItemContent>



            <KeriSearchCompanyModal
                open={openCompanyModal}
                onClose={() => setOpenCompanyModal(false)}
                onSubmit={(item) => {
                    setFormData(item);
                    setOpenCompanyModal(false);
                }}
            />
        </KeriStepItemLayout>
    )
}


export default KeriRegisterStepSearchCompanyItem;