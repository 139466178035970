import { ICompanyNumberAPIErrorResult, ICompanyNumberAPIRequest, ICompanyNumberAPIResult } from "@ict-base/interfaces/api/company-number/ICompanyNumberAPI";
import { AxiosError, AxiosResponse } from "axios";
import CallAPI from "../utils/CallAPI";

export interface IKeriAPIState {
    state: boolean,
    message: string | undefined,
    statusCode?: number,
}

const ICTRequestAPI = {
    common : {
        /** 주소 조회 API */
        KoreaAddress : () => {return undefined},
        /** 사업자 등록번호 조회 API */
        GetCompanyNumber : async (searchText : string) : Promise<IGetCompanyNumber> => GetCompanyNumber(searchText), 
    }   
}



interface IGetCompanyNumber extends IKeriAPIState{

}

const GetCompanyNumber = async ( searchText : string) : Promise<IGetCompanyNumber> => {
    let apiState: boolean = true;
    let errorMessage: string | undefined = undefined;

    const reqBody : ICompanyNumberAPIRequest = {
        b_no : [searchText.replaceAll(/-/g,'')]
    }

    await CallAPI<ICompanyNumberAPIResult>({
        nonBaseURL : true,
        url: `https://api.odcloud.kr/api/nts-businessman/v1/status?serviceKey=${encodeURIComponent(process.env.REACT_APP_COMPANY_NUMBER_API_KEY)}`,
        method: "POST",
        requestBody: reqBody,
    })
        .then((res: AxiosResponse<ICompanyNumberAPIResult>) => {
            if (res.data.status_code === 'OK') {
                if(res.data.data[0].tax_type === '국세청에 등록되지 않은 사업자등록번호입니다.'){
                    apiState = false;
                    errorMessage = res.data.data[0].tax_type    
                }
                else{
                    apiState = true;
                }
            }
            else {
                apiState = false;
                errorMessage = res.data.data[0].tax_type
            }
        })
        .catch((err : AxiosError<ICompanyNumberAPIErrorResult>) => {
            apiState = false;
            errorMessage = err.response?.data.msg
        })


    return {
        state: apiState,
        message: errorMessage,
    };
}

export default ICTRequestAPI