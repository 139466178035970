import KeriActionButton from "@ict-base/components/control/KeriActionButton";
import { IKeriStepShareProps, KeriStepItemLayout } from "@keri-portal/component/layout/StepLayout";
import { IAccountRegister } from "@keri-portal/interfaces/process-object/account/IAccountRegister";
import { CheckCircleRounded } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { Box } from "@mui/material";
import URLStructure from "@src/modules/structure/URLStructure";
import { useNavigate } from "react-router-dom";



const KeriRegisterStep5Item = (prop: IKeriStepShareProps<IAccountRegister>) => {
    const navigate = useNavigate();


    return (
        <KeriStepItemLayout
            {...prop}
            validationCheck={() => false}
            disableButton
        >
            <Box
                sx={{
                    textAlign: 'center'
                }}
            >
                <CheckCircleRounded
                    sx={{
                        fontSize: 200,
                        color: '#b7e349'
                    }}
                />
                <Box
                    sx={{
                        textAlign: 'center'
                    }}
                >
                    <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
                        회원 가입 신청을 완료하였습니다.
                    </Typography>
                    <Typography>
                        회원 가입 신청이 접수되었습니다. 자세한 사항은 이메일로 발송된 안내 메일을 참고하시기 바랍니다.
                    </Typography>
                    <KeriActionButton 
                        variant="text"
                        label="메인으로 바로가기"
                        onClick={() => navigate(URLStructure.Main)}
                    />
                </Box>
            </Box>
        </KeriStepItemLayout>
    )
}

export default KeriRegisterStep5Item;