import { Components, PaletteOptions, Theme } from "@mui/material"
import createPalette from "@mui/material/styles/createPalette"

const KeriPalette = createPalette({
    primary: {
        main: '#25476a',

    },
    secondary: {
        main: '#75868f'
    },
    error: {
        main: '#df5645'
    },
    info: {
        main: 'rgb(39, 165, 145)'
    }
})


const KeriActionButtonTheme: Components<Theme> = {
    MuiButton: {
        styleOverrides: {
            root: (prop) => {
                return {
                    fontFamily: 'Noto Sans KR',
                    // border  : `1px solid ${prop.ownerState.color}`
                }
            },
            outlinedError: (prop) => {
                return {
                    border: `1px solid ${KeriPalette.error.main}`,
                    background : 'white',
                    ":hover": {
                        backgroundColor: '#df5645',
                        color: 'white',

                    }
                }
            },
            containedPrimary: (prop) => {
                
                return {
                    // border: `1px solid ${!prop.ownerState.disabled ? KeriPalette.primary.main : '#65727A'}`,
                    ":hover": {
                        // backgroundColor : prop.ownerState.color
                    }
                }
            },
            containedError : (prop) => {
                return {
                    boarder : `1px solid ${prop.ownerState.color}`
                }
            },
            containedSecondary: (prop) => {
                return {
                    border: `1px solid ${KeriPalette.secondary.main}`,
                }
            },
            outlinedSecondary: (prop) => {
                return {
                    border: `1px solid ${KeriPalette.secondary.main}`,
                    background : 'white',
                    ":hover": {
                        color: 'black',
                        backgroundColor: '#e1e7f0'
                    }
                }
            },
            containedInfo: (prop) => {

                return {
                    // border: `1px solid ${KeriPalette.info.main}`,
                    ":hover": {
                        color: 'white',
                        backgroundColor: 'rgb(20, 84, 74)'
                    }
                }
            },
            textPrimary: (prop) => {
                return {
                    border: `none`,
                    ":hover": {
                        color: '#6666CC',
                        background: 'none'
                    }
                }
            }
        }
    },
    
}



const KeriStepTheme : Components<Theme>  = {
    MuiStepIcon: {
        styleOverrides: {
            text: {
                fontFamily: 'Roboto',
                fontWeight : 'bold'
            },
        }
    },
    MuiStepLabel : {
        styleOverrides : {
            label : {
                fontFamily : 'Noto Sans KR',
                marginTop : '8px !important',
                fontWeight : '700 !important',
            }
        }
    }
}





interface IKeriTheme {
    KeriPalette: PaletteOptions | undefined,
    KeriComponent: {
        KeriActionButton: Components<Theme>,
        KeriStep : Components<Theme>,
    }
}



const KeriTheme: IKeriTheme = {
    KeriPalette: KeriPalette,
    KeriComponent: {
        KeriActionButton: KeriActionButtonTheme,
        KeriStep : KeriStepTheme
    }
}

export default KeriTheme