import ICTPagination from "@ict-base/components/control/ICTPagination";
import { ICTSearchBox } from "@ict-base/components/control/ICTSearchBox";
import KeriActionButton from "@ict-base/components/control/KeriActionButton";
import { KeriEditGrid, KeriEditGridImageField, KeriEditGridInputField, KeriEditGridMultiItemField, KeriEditGridWebEditorField } from "@ict-base/components/control/KeriEditGrid";
import KeriUploadFileField from "@ict-base/components/control/KeriUploadFileField";
import { ObjectUtils } from "@ict-base/module/utils/ObjectUtils";
import LoginChecker from "@keri-base/components/common/LoginChecker";
import { IPageProp } from "@keri-base/interface/common/IPageProp";
import { PlatformError } from "@keri-base/interface/common/PlatformError";
import { IListPagination } from "@keri-base/interface/control/IListPagination";
import InfoLayoutPage from "@keri-portal/component/layout/InfoLayoutPage";
import KeriConfig from "@keri-portal/config/KeriConfig";
import { IUploadFileVO } from "@keri-portal/interfaces/api/keri-api/object/IUploadFileVO";
import { IUploadFile } from "@keri-portal/interfaces/control/IUploadFile";
import { IBoard } from "@keri-portal/interfaces/data-object/data-common/IBoard";
import useLoginCheck from "@keri-portal/module/hook/useLoginCheck";
import RequestKeriAPI from "@keri-portal/module/method/api-connect/RequestKeriAPI";
import { RemoveRedEyeOutlined, PersonOutline, Add, InsertDriveFile, InsertDriveFileOutlined, CancelOutlined } from "@mui/icons-material";
import { CircularProgress, Typography, Paper, Chip, Divider, alertTitleClasses, Button, Box, IconButton, Backdrop } from "@mui/material";
import { ILoginSessionData } from "@src/modules/redux/reducer/UserDataReducer";
import URLStructure from "@src/modules/structure/URLStructure";
import { DefaultScrollDesign } from "@src/modules/styles/DefaultScrollDesign";
import React, { useImperativeHandle, useRef } from "react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

interface IShareBoardDataObject {
    boardID?: string,
    boardItemID?: string,
    title?: string,
    content?: string,
    password?: string,
    addFiles?: IUploadFile<IUploadFileVO>[],
    removeFiles?: IUploadFile<IUploadFileVO>[],
}


const ShareBoardWritePage = (prop: IPageProp) => {


    const [loginData, setLoginData] = useState<ILoginSessionData | undefined>(undefined);
    const param = useParams();
    const boardID = param.boardID;
    const navigate = useNavigate();
    const location = useLocation();

    const [loading, setLoading] = useState<boolean>(false);
    const [boardList, setBoardList] = useState<IBoard | undefined>(undefined);
    const [loadEditor, setLoadEditor] = useState<boolean>(false)

    const uploadRef = useRef<IUploadSectionRef | null>(null);

    const [editData, setEditData] = useState<IShareBoardDataObject>({});

    const saveRef = useRef<ISavePopperRef | null>(null);


    const getData = async (searchText?: string, page?: number) => {
        setLoading(true);

        const getResult = await RequestKeriAPI.Board.GetBoardContentList(loginData?.token, boardID, searchText, page, 6);

        if (getResult.state) {
            setBoardList(getResult.resultData)
        }
        else {
            alert(getResult.message);
        }

        setLoading(false);
    }



    useEffect(() => {
        console.log(boardList)
        console.log(loginData);
        if (boardList === undefined && (loginData?.loadComplete ?? false)) {
            console.log('getdata');
            getData();
        }
    }, [loginData])

    return (
        <LoginChecker
            onCheck={(state, data) => {
                console.log('WWWWWWWWWW', data);
                if (state === 'LOGIN') {
                    setLoginData(data);
                }
                else if (state === 'UNLOGIN') {
                    setLoginData(data);
                }
            }}
        >
            {
                loading ?
                    <InfoLayoutPage>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                pt: 20
                            }}
                        >
                            <CircularProgress color="inherit" />
                        </Box>
                    </InfoLayoutPage>
                    :
                    <React.Fragment>
                        {
                            boardList === undefined
                                ?
                                <InfoLayoutPage>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            pt: 20
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                textAlign: 'center'
                                            }}
                                        >
                                            <img src={`${URLStructure.Main}assets/img/ict/images/top_logo.svg`} />
                                            <Typography
                                                variant='h4'
                                                sx={{
                                                    mb: 4,
                                                    mt: 10
                                                }}
                                            >
                                                페이지를 찾을 수 없습니다.
                                            </Typography>
                                            <Typography
                                                onClick={() => navigate(URLStructure.Main)}
                                                sx={{
                                                    cursor: 'pointer',
                                                    transition: '0.5s all cubic-bezier(0,0,0,1)',
                                                    ":hover": {
                                                        color: '#52b5b0'
                                                    }
                                                }}
                                            >
                                                메인 페이지로 이동
                                            </Typography>
                                        </Box>
                                    </Box>
                                </InfoLayoutPage>
                                :
                                <InfoLayoutPage
                                    title={boardList.BoardTitle}
                                >
                                    <Paper
                                        sx={{
                                            p: 2,
                                            pr: 1,
                                            pl: 1,
                                        }}
                                    >
                                        <Box>
                                            <KeriEditGrid>
                                                <KeriEditGridInputField
                                                    title="제목"
                                                    onChange={(changeText) => {
                                                        setEditData({
                                                            ...editData,
                                                            title: ObjectUtils.IsNullOrEmpty(changeText) ? undefined : changeText
                                                        })
                                                    }}
                                                />

                                            </KeriEditGrid>
                                        </Box>
                                        <Box
                                            sx={{
                                                mt: 1
                                            }}
                                        >
                                            <KeriEditGrid>
                                                {
                                                    !loadEditor &&
                                                    <Box
                                                        sx={{
                                                            width: '100%',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            pt: 4,
                                                            pb: 4,
                                                        }}
                                                    >
                                                        <CircularProgress color="inherit" />
                                                    </Box>
                                                }
                                                <Box
                                                    sx={!loadEditor ? {
                                                        opacity: 0,
                                                        height: '0px'
                                                    } : {
                                                        width: '100%'
                                                    }}
                                                >
                                                    <KeriEditGridWebEditorField
                                                        disableTitle
                                                        onChange={(changeText) => {
                                                            setEditData({
                                                                ...editData,
                                                                content: ObjectUtils.IsNullOrEmpty(changeText) ? undefined : changeText
                                                            })
                                                        }}
                                                        onLoadEditor={(e) => setLoadEditor(e.initial ?? false)}
                                                    />
                                                </Box>



                                            </KeriEditGrid>
                                        </Box>
                                        <Box>
                                            {
                                                (loginData?.token === undefined) &&
                                                <KeriEditGrid>
                                                    <KeriEditGridInputField
                                                        title="비밀번호"
                                                        type={'password'}
                                                        onChange={(changeText) => {
                                                            setEditData({
                                                                ...editData,
                                                                password: ObjectUtils.IsNullOrEmpty(changeText) ? undefined : changeText
                                                            })
                                                        }}
                                                    />
                                                </KeriEditGrid>
                                            }

                                            <Box
                                                sx={{

                                                    m: 1,
                                                    mt: 0,
                                                    p: 1,
                                                    display: 'flex',
                                                    justifyContent: 'start',
                                                    alignItems: 'start'
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        width: '160px'
                                                    }}
                                                >
                                                    <Typography>
                                                        첨부 파일
                                                    </Typography>
                                                </Box>
                                                <Box
                                                    sx={{
                                                        width: 'calc(100% - 160px)'
                                                    }}
                                                >
                                                    <UploadSection
                                                        ref={uploadRef}
                                                    />
                                                </Box>

                                            </Box>
                                        </Box>

                                    </Paper>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'end',
                                            alignItems: 'center',
                                            pt: 2
                                        }}
                                    >
                                        <KeriActionButton
                                            label="목록"
                                            variant="contained"
                                            color="info"
                                            onClick={() => {
                                                const pageExit = window.confirm('작성중인 글이 있습니다. 페이지를 나가시겠습니까?');
                                                if (pageExit) {
                                                    navigate(`${process.env.PUBLIC_URL}/share-board/${boardID}`);
                                                }
                                            }}
                                        />


                                        <KeriActionButton
                                            label="저장"
                                            variant="contained"
                                            sx={{
                                                ml: 1
                                            }}
                                            onClick={async () => {
                                                let valCheck: boolean = true;

                                                console.log('---------WW-------------',loginData , editData);

                                                if (loginData?.token === undefined) {
                                                    if (ObjectUtils.IsNullOrEmpty(editData.password)) {
                                                        alert('비밀번호가 설정되지 않았습니다.');
                                                        valCheck = false;
                                                    }
                                                }

                                                if (valCheck) {
                                                    const saveState = await saveRef.current?.RunSave({
                                                        boardID: boardID,
                                                        content: editData.content,
                                                        password: editData.password,
                                                        title: editData.title,
                                                        addFiles: uploadRef.current?.GetUploadFiles()
                                                    })

                                                    if ((saveState?.state ?? false)) {
                                                        navigate(`${process.env.PUBLIC_URL}/share-board/${boardID}`);
                                                    }
                                                    else {
                                                        alert(saveState?.message);
                                                    }
                                                }

                                            }}
                                        />

                                    </Box>

                                </InfoLayoutPage>
                        }
                    </React.Fragment>
            }

            <SavePopper ref={saveRef} />
        </LoginChecker>
    )
}


//#region Upload Section

interface IUploadSection {
    defaultValue?: IUploadFile<IUploadFileVO>[]
}

export interface IUploadSectionRef {
    GetUploadFiles: () => IUploadFile<IUploadFileVO>[],
}

export const UploadSection = React.forwardRef<IUploadSectionRef, IUploadSection>((prop, ref) => {


    const [uploadFile, setUploadFile] = useState<IUploadFile<IUploadFileVO>[]>([]);
    const [loadUpload, setLoadUpload] = useState<boolean>(false);
    const uploadInputRef = useRef<HTMLInputElement | null>(null);


    useImperativeHandle(ref, () => ({
        GetUploadFiles: () => {
            return uploadFile;
        }
    }))

    useEffect(() => {
        // if(uploadFile.)
        console.log('UPLOADFILE_----------------', uploadFile)
    }, [uploadFile])


    useEffect(() => {
        setUploadFile(prop.defaultValue ?? []);
    }, [prop.defaultValue])


    return (
        <Box
            sx={{
                width: '100%'
            }}
        >
            <Box>
                <Box
                    sx={{
                        border: '1px solid rgba(0,0,0,0.1)',
                        width: '100%',
                        borderRadius: '5px',
                        overflow: 'hidden'
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: "100%",
                            height: '40px',
                            cursor: 'pointer',
                            transition: '0.3s all cubic-bezier(0,0,0,1)',
                            ":hover": {
                                background: 'rgba(0,0,0,0.3)'
                            }
                        }}
                        onClick={() => uploadInputRef.current && uploadInputRef.current.click()}
                    >
                        <React.Fragment>
                            <input
                                ref={uploadInputRef}
                                type="file"
                                accept={'*'}
                                style={{ display: "none" }}
                                multiple={true}
                                onChange={(e) => {
                                    // setLoadUpload(true);
                                    const fileArray: File[] = Array.from(e.target.files ?? []);

                                    let resultFileArray: File[] = [];
                                    console.log(fileArray.length !== 0);
                                    if (fileArray.length !== 0) {



                                        for (let fileItem of fileArray) {
                                            const fileExt = fileItem.name.split('.')[fileItem.name.split('.').length - 1];
                                            if (KeriConfig.DenyUploadFileExt.find(x => x === fileExt) === undefined && fileItem.size <= 500 * 1024 * 1024) {
                                                resultFileArray.push(fileItem);
                                            }
                                        }

                                        if (resultFileArray.length !== fileArray.length) {
                                            alert('허용되지 않은 확장자 포함한 파일이나 500MB 이상의 파일이 있습니다.\n해당 파일을 제외하고 업로드됩니다.');
                                            if (resultFileArray.length === 0) {
                                                return;
                                            }
                                        }




                                        const fileObj: IUploadFile<IUploadFileVO>[] = resultFileArray.map((x, index) => ({
                                            Category: 'ShareBoardFiles',
                                            Index: parseInt(`${Date.now()}${index}`),
                                            Label: 'ShareBoardFiles',
                                            Prefix: 'SHARE_BBS_',
                                            OriginFile: x,
                                            Required: false,
                                        }))

                                        console.log('---FILEOBJ---', fileObj)

                                        try {
                                            setUploadFile([
                                                ...uploadFile,
                                                ...fileObj
                                            ]);
                                        }
                                        catch (e) {
                                            alert('업로드에 실패했습니다.');
                                            console.error(e);
                                        }




                                        e.target.files = null;
                                        e.target.value = '';
                                        // setLoadUpload(false);
                                    }


                                }}
                            />
                            <Typography
                                sx={{
                                    color: 'gray'
                                }}
                            >
                                Upload
                            </Typography>
                        </React.Fragment>
                    </Box>
                    {
                        uploadFile.length > 0 && <Divider />
                    }
                    <Box
                        sx={{
                            maxHeight: '200px',
                            overflow: 'auto',
                            ...DefaultScrollDesign
                        }}
                    >
                        {
                            uploadFile.length !== 0 && [...uploadFile].map(uploadItem => (
                                <Box
                                    key={ObjectUtils.RandomStrings(10)}
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'start',
                                        alignItems: 'center',
                                        p: 1,
                                        ml: 1,
                                        mr: 1,
                                    }}
                                >
                                    <IconButton
                                        sx={{
                                            m: 0,
                                            p: 0,
                                            mr: 2
                                        }}
                                        onClick={() => {
                                            setUploadFile([
                                                ...uploadFile.filter(x => x.Index !== uploadItem.Index)
                                            ])
                                        }}
                                    >
                                        <CancelOutlined
                                            sx={{
                                                m: 0,
                                                p: 0
                                            }}
                                        />
                                    </IconButton>
                                    <InsertDriveFileOutlined
                                        sx={{
                                            mr: 1
                                        }}
                                    />
                                    <Box>
                                        {uploadItem.OriginFile?.name ?? uploadItem.UploadedFile?.orignlFileNm}
                                    </Box>
                                    <Box
                                        sx={{
                                            ml: 2
                                        }}
                                    >
                                        {ObjectUtils.FileSizeFormater(uploadItem.OriginFile?.size ?? uploadItem.UploadedFile?.fileMg ?? 0)}
                                    </Box>




                                </Box>

                            ))
                        }
                    </Box>
                    {
                        uploadFile.length !== 0 &&
                        <React.Fragment>
                            <Divider />
                            <Box
                                sx={{
                                    width: '100%',
                                    p: 1,
                                    textAlign: 'center',
                                    userSelect: 'none',
                                    color: 'red',
                                    cursor: 'pointer',
                                    transition: '0.3s all cubic-bezier(0,0,0,1)',
                                    ":hover": {
                                        background: '#ff000029'
                                    }
                                }}
                                onClick={() => {
                                    setUploadFile([]);
                                }}
                            >
                                Reset
                            </Box>
                        </React.Fragment>
                    }


                </Box>

            </Box>
        </Box>
    )
})

//#endregion


//#region Save State Popper


export interface ISavePopperRef {
    RunSave: (data: IShareBoardDataObject) => Promise<{ state: boolean, message?: string }>,
    RunEdit: (data: IShareBoardDataObject, orgFiles?: IUploadFile<IUploadFileVO>[]) => Promise<{ state: boolean, message?: string }>
}

export const SavePopper = React.forwardRef<ISavePopperRef, unknown>((prop, ref) => {

    const [popperOpen, setPopperOpen] = useState<boolean>(false);

    const [stateMessage, setStateMessage] = useState<string>("");
    const userRedux = useLoginCheck();

    useImperativeHandle(ref, () => ({
        RunSave: async (data) => {
            setPopperOpen(true);

            let resultState: { state: boolean, message?: string } = {
                state: true,
                message: undefined
            }

            try {


                if (ObjectUtils.IsNullOrEmpty(data.title)) {
                    throw new PlatformError("제목이 빈 값입니다.");
                }

                if (ObjectUtils.IsNullOrEmpty(data.content)) {
                    throw new PlatformError("내용이 빈 값입니다.");
                }





                let uploadFileArray: IUploadFile<IUploadFileVO>[] = [];

                if ((data.addFiles ?? []).length > 0) {
                    setStateMessage(`파일 업로드 (0/${data.addFiles?.length})`);

                    for (let i = 0; i < (data.addFiles ?? []).length; i++) {
                        const fileItem = (data.addFiles ?? [])[i];

                        setStateMessage(`파일 업로드 (${i + 1}/${data.addFiles?.length})`);
                        const uploadFileAPI = await RequestKeriAPI.File.UploadFile(fileItem);

                        if (uploadFileAPI.state && uploadFileAPI.data !== undefined) {
                            uploadFileArray.push(uploadFileAPI.data);
                        }
                        else {
                            console.error(`KERI SCRIPT ERROR : UPLOAD API - ${uploadFileAPI.message}`);
                            throw new PlatformError("파일을 업로드 할 수 없습니다.");
                        }
                    }

                }
                


                setStateMessage(`게시글 저장`);

                const addData = await RequestKeriAPI.Board.AddBoardData({
                    boardID: data.boardID,
                    content: data.content,
                    subject: data.title,
                    token: userRedux?.token,
                    password: data.password,
                    addFiles: uploadFileArray.filter(x => x.UploadedFile !== undefined).map(x => x.UploadedFile!.streFileNm!)
                });

                if (addData.state) {
                    setStateMessage(`저장 완료`);
                }
                else {
                    throw new PlatformError(addData.message);
                }


            }
            catch (e) {
                console.error('SCRIPT ERROR', e);
                resultState.state = false;
                if (e instanceof PlatformError) {

                    resultState.message = e.message;
                }
                else {
                    resultState.message = '시스템 에러';
                }
            }



            setPopperOpen(false);
            return resultState;
        },
        RunEdit: async (data) => {
            setPopperOpen(true);

            let resultState: { state: boolean, message?: string } = {
                state: true,
                message: undefined
            }

            try {


                if (ObjectUtils.IsNullOrEmpty(data.title)) {
                    throw new PlatformError("제목이 빈 값입니다.");
                }

                if (ObjectUtils.IsNullOrEmpty(data.content)) {
                    throw new PlatformError("내용이 빈 값입니다.");
                }





                let uploadFileArray: IUploadFile<IUploadFileVO>[] = [];

                if ((data.addFiles ?? []).length > 0) {
                    setStateMessage(`파일 업로드 (0/${data.addFiles?.length})`);

                    for (let i = 0; i < (data.addFiles ?? []).length; i++) {
                        const fileItem = (data.addFiles ?? [])[i];

                        setStateMessage(`파일 업로드 (${i + 1}/${data.addFiles?.length})`);
                        const uploadFileAPI = await RequestKeriAPI.File.UploadFile(fileItem);

                        if (uploadFileAPI.state && uploadFileAPI.data !== undefined) {
                            uploadFileArray.push(uploadFileAPI.data);
                        }
                        else {
                            console.error(`KERI SCRIPT ERROR : UPLOAD API - ${uploadFileAPI.message}`);
                            throw new PlatformError("파일을 업로드 할 수 없습니다.");
                        }
                    }

                }


                setStateMessage(`게시글 수정`);

                console.log('WWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWW',

                    data.boardID!,
                    data.boardItemID!,
                    data.title!,
                    data.content!,
                    userRedux?.token,
                    data.password,
                    uploadFileArray.filter(x => x.UploadedFile !== undefined).map(x => x.UploadedFile!.streFileNm!),
                    data.removeFiles?.filter(x => x.UploadedFile !== undefined).map(x => x.UploadedFile!.streFileNm!)
                )

                const addData = await RequestKeriAPI.Board.EditBoardItem(
                    data.boardID!,
                    data.boardItemID!,
                    data.title!,
                    data.content!,
                    userRedux?.token,
                    data.password,
                    uploadFileArray.filter(x => x.UploadedFile !== undefined).map(x => x.UploadedFile!.streFileNm!),
                    data.removeFiles?.filter(x => x.UploadedFile !== undefined).map(x => x.UploadedFile!.streFileNm!)
                );

                if (addData.state) {
                    setStateMessage(`저장 완료`);
                }
                else {
                    throw new PlatformError(addData.message);
                }


            }
            catch (e) {
                console.error('SCRIPT ERROR', e);
                resultState.state = false;
                if (e instanceof PlatformError) {

                    resultState.message = e.message;
                }
                else {
                    resultState.message = '시스템 에러';
                }
            }



            setPopperOpen(false);
            return resultState;
        }
    }));

    return (
        <Backdrop
            open={popperOpen}
            sx={{
                zIndex: "9999"
            }}
        >
            <Paper
                sx={{
                    width: '300px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    p: 2
                }}
            >
                <CircularProgress />
                <Box
                    sx={{
                        mt: 2
                    }}
                >
                    <Typography>
                        {stateMessage}
                    </Typography>
                </Box>

            </Paper>
        </Backdrop>
    )
})

//#endregion

export default ShareBoardWritePage;