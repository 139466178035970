import KeriActionButton from "@ict-base/components/control/KeriActionButton";
import { KeriFileTable, KeriFileTableItem } from "@ict-base/components/control/KeriFileTable";
import { ObjectUtils } from "@ict-base/module/utils/ObjectUtils";
import KeriBaseConfig from "@keri-base/config/KeriBaseConfig";
import RegisterTechServiceRequesterInputField from "@keri-portal/component/input-field/RegisterTechServiceRequesterInputField";
import RegisterTechServiceRequestInputField, { IRegisterTechServiceRequestInputFieldErrorData } from "@keri-portal/component/input-field/RegisterTechServiceRequestInputField";
import { IKeriStepShareProps, KeriStepItemContent, KeriStepItemLayout, KeriStepItemSubContent } from "@keri-portal/component/layout/StepLayout";
import { IUploadFileVO } from "@keri-portal/interfaces/api/keri-api/object/IUploadFileVO";
import { IUploadFile } from "@keri-portal/interfaces/control/IUploadFile";
import { TechServiceType } from "@keri-portal/interfaces/data-object/tech-service/ITechService";
import { ITechServiceRequest } from "@keri-portal/interfaces/process-object/tech-service/ITechServiceRequest";
import useLoginCheck from "@keri-portal/module/hook/useLoginCheck";
import RequestKeriAPI from "@keri-portal/module/method/api-connect/RequestKeriAPI";
import { TechServiceValidationCheck } from "@keri-portal/module/method/validation-check/tech-service/ITechServiceRequestTypeValidationCheck";
import { Add } from "@mui/icons-material";
import { useEffect, useState } from "react";

const KeriRegisterTechServiceRequestData = (prop: IKeriStepShareProps<ITechServiceRequest>) => {

    const [formData, setFormData] = useState<ITechServiceRequest | undefined>();


    const [errorData, setErrorData] = useState<IRegisterTechServiceRequestInputFieldErrorData>({});
    const [techTitle, setTechTitle] = useState<string | undefined>(undefined);

    const userRedux = useLoginCheck();

    const validationCheck = () => {
        const valCheck = TechServiceValidationCheck(formData?.TechServiceType, formData?.RequesterData);

        setErrorData(valCheck.error)
        return valCheck.state;
    }

    const addEmptyFileItem = () => {

        console.log(prop.result.data.TechServiceType);

        setFormData({
            ...formData,
            RequesterData: {
                ...formData?.RequesterData,
                RequestType: prop.result.data.TechServiceType!,
                RequestInputFile: [
                    ...(formData?.RequesterData?.RequestInputFile ?? []),
                    {
                        Index: (formData?.RequesterData?.RequestInputFile ?? []).length !== 0 ? ((formData?.RequesterData?.RequestInputFile ?? []).sort((x, y) => y.Index - x.Index)[0].Index + 1) : 1, 
                        Label: '참고자료',
                        Category: 'ServiceFiles',
                        Prefix: 'TEC_SERV_',
                    }
                ]
            },
            TechServiceType: prop.result.data.TechServiceType!,
            RequestTempUniqueKey: prop.result.data.RequestTempUniqueKey!,
        })


    }

    useEffect(() => {
        const beforeData = prop.result.data === undefined ? undefined : {...prop.result.data};
       
        
        if(formData === undefined && beforeData !== undefined){

            let inputFormData : ITechServiceRequest | undefined = undefined;

            switch (beforeData.TechServiceType) {
                case 'A': setTechTitle('해석기술지원'); break;
                case 'T': setTechTitle('제품성능시험'); break;
                case 'S': setTechTitle('지능형 시뮬레이션'); break;
                case 'R': setTechTitle('AR/VR 서비스'); break;
                case 'C': setTechTitle('제어 및 신호측정 서비스'); break;
                case 'P': setTechTitle('3D 프린팅 서비스'); break;
                default: throw 'NOT FOUND TYPE';
            }


            let requiredFiles: IUploadFile<IUploadFileVO>[] = [];

            inputFormData = {
                ...beforeData,
                RequesterData : {
                    ...beforeData.RequesterData,
                    RequestType : beforeData.TechServiceType!,
                    RequestInputFile : [...requiredFiles]
                }
            }
            
            setFormData({...inputFormData});
        }
    }, [prop.result.data,formData])

    useEffect(() => {
        console.log('change form data',formData);
    },[formData])

    return (
        <KeriStepItemLayout
            {...prop}
            validationCheck={validationCheck}
            step={prop.step}
            page={{
                moveNext: async (pageSetup, validation) => {
                    if (validation) {
                        prop.result.onChange({
                            ...prop.result.data,
                            ...formData
                        })

                        pageSetup(prop.step.activeStep + 1);
                      
                    }

                }
            }}
        >


            <KeriStepItemContent
                title={`${techTitle} 신청`}
            >
                <KeriStepItemSubContent
                    title='기술지원 신청 정보'
                >
                    <RegisterTechServiceRequestInputField
                        editMode
                        techServiceType={prop.result.data.TechServiceType!}
                        value={formData?.RequesterData ?? { RequestType: prop.result.data.TechServiceType! }}
                        onChange={(item) => {
                            console.log('item change' , item);
                            setFormData({
                                ...formData,
                                TechServiceType: prop.result.data.TechServiceType!,
                                RequestTempUniqueKey: prop.result.data.RequestTempUniqueKey!,
                                RequesterData: item
                            })
                        }}
                        error={{
                            data: errorData,
                            onChange: (item) => {

                                setErrorData(item)
                            }
                        }}
                    />
                </KeriStepItemSubContent>


            </KeriStepItemContent>
            <KeriStepItemContent
                title={(prop.result.data.TechServiceType === 'A' || prop.result.data.TechServiceType === 'T') ? '기술지원 기초 자료' : prop.result.data.TechServiceType === 'S' ? '시뮬레이션 입력 자료' : '참고 자료'}
                endAdornment={
                    <KeriActionButton
                        label="참고자료 추가"
                        variant="outlined"
                        color="primary"
                        sx={{
                            m: 0,
                            // p: 0
                        }}
                        icon={<Add />}
                        onClick={() => addEmptyFileItem()}
                    />
                }
            >
                <KeriFileTable
                    error={{
                        isError: errorData.requestFiles !== undefined,
                        message: errorData.requestFiles
                    }}
                    denyFileExtension={KeriBaseConfig.DenyFileExtension}
                    fileMode='Upload'
                >
                    {
                        (formData?.RequesterData?.RequestInputFile ?? []).sort((x, y) => x.Index - y.Index).map(item => (
                            <KeriFileTableItem
                                key={ObjectUtils.RandomStrings(10)}
                                fileObject={item}
                                fileMode='Upload'
                                displayObject={{
                                    displayTitle: (item) => item.Label,
                                    displayFileName: (item) => item.UploadedFile === undefined ? item.OriginFile?.name : item.UploadedFile.orignlFileNm,
                                    displayDeleteRow: (item) => !item.Required
                                }}
                                uploadProp={{
                                    denyFileExtension : KeriBaseConfig.DenyFileExtension
                                }}
                                event={{
                                    onFileChange: (changeItem) => {
                                        setFormData({
                                            ...formData,
                                            RequesterData: {
                                                ...formData?.RequesterData,
                                                RequestType: formData?.RequesterData?.RequestType!,
                                                RequestInputFile: [
                                                    ...(formData?.RequesterData?.RequestInputFile ?? []).filter(reqItem => reqItem.Index !== changeItem.Index),
                                                    changeItem
                                                ]
                                            },
                                            TechServiceType: prop.result.data.TechServiceType!,
                                            RequestTempUniqueKey: prop.result.data.RequestTempUniqueKey!,
                                        })


                                        if (errorData.requestFiles !== undefined) {
                                            setErrorData({
                                                ...errorData,
                                                requestFiles: undefined
                                            })
                                        }
                                    },
                                    onDeleteUploadFile: (changeItem) => {

                                        setFormData({
                                            ...formData,
                                            RequesterData: {
                                                ...formData?.RequesterData,
                                                RequestType: prop.result.data.TechServiceType!,
                                                RequestInputFile: [
                                                    ...(prop.result.data.RequesterData?.RequestInputFile ?? []).filter(reqItem => reqItem.Index !== changeItem.Index),
                                                    {
                                                        ...changeItem,
                                                        UploadedFile: undefined,
                                                        OriginFile: undefined
                                                    }
                                                ]
                                            },
                                            TechServiceType: prop.result.data.TechServiceType!,
                                            RequestTempUniqueKey: prop.result.data.RequestTempUniqueKey!,
                                        })




                                    },
                                    onDeleteRow: (deleteItem) => {

                                        setFormData({
                                            ...formData,
                                            RequesterData: {
                                                ...formData?.RequesterData,
                                                RequestType: prop.result.data.TechServiceType!,
                                                RequestInputFile: [
                                                    ...(formData?.RequesterData?.RequestInputFile ?? []).filter(reqItem => reqItem.Index !== deleteItem.Index)

                                                ]
                                            },
                                            TechServiceType: prop.result.data.TechServiceType!,
                                            RequestTempUniqueKey: prop.result.data.RequestTempUniqueKey!,
                                        })
                                    }
                                }}

                            />
                        ))
                    }

                </KeriFileTable>
            </KeriStepItemContent>
            {
                <KeriStepItemContent
                    title='신청자 정보'
                >
                    <RegisterTechServiceRequesterInputField
                        editMode
                        value={formData?.RequesterData ?? { RequestType: prop.result.data.TechServiceType! }}
                        onChange={(item) => setFormData({
                            ...formData,
                            RequesterData: item,
                            TechServiceType: prop.result.data.TechServiceType!,
                            RequestTempUniqueKey: prop.result.data.RequestTempUniqueKey!,
                        })}
                        error={{
                            data: errorData,
                            onChange: (item) => setErrorData(item)
                        }}
                    />
                </KeriStepItemContent>
            }

        </KeriStepItemLayout>
    )
}

export default KeriRegisterTechServiceRequestData;