import { Box, Divider, Typography } from "@mui/material"
import React from "react"
import KeriActionButton from "../control/KeriActionButton"

export interface ILoadControl {
    isLoading: boolean,
    isComplete: boolean,
    errorMessage?: string
}

export const InitLoadControl: ILoadControl = {
    isLoading: false,
    isComplete: false,
    errorMessage: undefined
}

interface ILoadControlUnit {
    loadComponent?: React.ReactNode,
    errorComponent?: React.ReactNode,
    children?: React.ReactNode | React.ReactNode[],
    isLoading?: boolean,
    errorMessage?: string,
    title?: string,
    titleEndComponent? : React.ReactNode
}


export const LoadControlUnit = (prop: ILoadControlUnit) => {
    return (
        <Box>
            {
                prop.title !== undefined &&
                <React.Fragment>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            mt: 2,
                            ":nth-of-type(1)" : {
                                mt : 0
                            }
                        }}
                    >
                        <Box
                            sx={{
                                p: 1
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: '1.2rem',
                                    fontWeight: 'bold'
                                }}
                            >
                                {prop.title}
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                p: 1
                            }}
                        >
                           {prop.errorMessage === undefined && prop.titleEndComponent}
                        </Box>
                    </Box>
                    <Divider
                        sx={{
                            mb: 1
                        }}
                    />
                </React.Fragment>
            }
            {
                (prop.errorMessage !== undefined && prop.errorComponent !== undefined) && prop.errorComponent
            }
            {
                (prop.errorMessage === undefined && (prop.isLoading ?? false) && prop.loadComponent !== undefined) && prop.loadComponent
            }
            {
                (prop.errorMessage === undefined && !(prop.isLoading ?? false) && prop.children !== undefined) && prop.children
            }
        </Box>
    )
}

