/**
 * 기술지원 상세 - 시뮬레이션 리스트 
 * @since 2023.04.21
 * @author shlee
 * 
 */


import { ObjectUtils } from '@ict-base/module/utils/ObjectUtils';
import { DateToString } from '@ictpeople/common';
import { MISCFileAPI } from '@ictpeople/misc-api';
import { IKeriServerFile, IKeriTechServiceSimulation } from '@ictpeople/misc-core';
import { MISCModal } from '@ictpeople/misc-react';
import RequestKeriAPI from '@keri-portal/module/method/api-connect/RequestKeriAPI';
import { Add, Check, CheckCircle, Close, Dangerous, DataSaverOn, DataSaverOnRounded, Delete, DeleteSharp, DomainVerification, Download, ExpandLess, ExpandMore, FileDownload, FilePresent, FilePresentRounded, Pending, PlayArrow } from '@mui/icons-material';
import { Box, SxProps, Theme, Typography, IconButton, Divider, Tooltip, ThemeProvider, createTheme, CircularProgress } from '@mui/material';
import { ILoginSessionData } from '@src/modules/redux/reducer/UserDataReducer';
import React, { useState, useEffect, Fragment } from 'react'
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import RefetchSimulation from './RefetchSimulation';


//#region Simulation List


/** 기술지원 시뮬레이션 리스트 */
interface ISimulationInfoListProp {
    children: React.ReactNode | React.ReactNode[]
    boxStyle?: SxProps<Theme>,
}

export const SimulationInfoList = (prop: ISimulationInfoListProp) => {
    return (
        <Box
            sx={{
                border: '1px solid rgba(0,0,0,0.1)',
                borderRadius: '10px',
                background: 'rgba(0,0,0,0.05)',
                overflow: 'hidden',
                height: '250px',
                ...prop.boxStyle
            }}
        >
            <Box
                sx={{
                    overflowY: 'scroll',
                    height: '100%',
                    p: 1
                }}
            >
                {prop.children}
            </Box>

        </Box>
    )
}


//#endregion





//#region Simulation Item

interface ISimulationInfoItemProp {
    item: IKeriTechServiceSimulation
    boxStyle?: SxProps<Theme>,
    defaultOpen?: boolean,
    onDelete?: (item: IKeriTechServiceSimulation) => void,
    onRefresh?: () => void,
    isLastItem?: boolean
}


/** 기술지원 시뮬레이션 아이템 */
export const SimulationInfoItem = (prop: ISimulationInfoItemProp) => {

    const [openDetail, setOpenDetail] = useState<boolean>(false);
    const [openSimulationFileList, setOpenSimulationFileList] = useState<boolean>(false);
    const [openRefetchSimulation, setOpenRefetchSimulation] = useState<boolean>(false);

    //#region Component

    const StatusIcon = (props: { sx?: SxProps<Theme> }) => {
        switch (prop.item.statusCode) {
            case 'Request': return <PlayArrow sx={{ color: 'darkblue', ...props.sx }} />
            case 'Failed': return <Dangerous sx={{ color: '#df3030', ...props.sx }} />
            case 'Complete': return <DomainVerification sx={{ color: '#2abf43', ...props.sx }} />
            default: return <Fragment />
        }
    }

    const AttributeItem = (props: { title: string, value?: string }) => (
        <Box
            sx={{
                minWidth: '50%',
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'center',
                mb: 0.5
            }}
        >
            <Typography sx={{ minWidth: '130px', mr: 1, fontWeight: 'bold' }}>
                {props.title}
            </Typography>
            <Typography variant='body1' sx={{ color: 'rgba(0,0,0,0.5)' }}>
                {props.value}
            </Typography>
        </Box>
    )

    //#endregion

    useEffect(() => {
        setOpenDetail(prop.defaultOpen ?? false);
    }, [prop.defaultOpen])

    return (
        <Box
            sx={{
                width: '100%',
                background: 'white',
                borderRadius: '10px',
                border: '1px solid rgba(0,0,0,0.1)',
                height: openDetail ? undefined : '50px',
                // minHeight: openDetail ? '200px' : undefined,
                overflow: 'hidden',
                p: 1,
                mb: 1,
                ...prop.boxStyle
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: "center"
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'start',
                        alignItems: "center"
                    }}
                >
                    <StatusIcon sx={{ mr: 1 }} />
                    <Typography sx={{ fontWeight: 'bold', mr: 1, lineHeight: '1rem', fontSize: '1rem' }}>
                        {prop.item.order}번 시뮬레이션
                    </Typography>
                    <Typography variant='subtitle2' sx={{ color: 'gray' }}>
                        ({DateToString(prop.item.createDate, 'yyyy-MM-dd HH:mm:ss')})
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'end',
                        alignItems: "center",
                    }}
                >
                    {

                        ((prop.item.statusCode === 'Failed' || prop.item.statusCode === 'Complete') && prop.isLastItem) &&
                        <Tooltip
                            title="시뮬레이션 재생성"
                        >
                            <IconButton
                                sx={{
                                    p: 0.5
                                }}
                                onClick={() => setOpenRefetchSimulation(true)}
                            >
                                <DataSaverOn />
                            </IconButton>
                        </Tooltip>
                    }


                    {/* <Tooltip
                        title="시뮬레이션 삭제"
                    >
                        <IconButton
                            sx={{
                                p: 0.5
                            }}
                            onClick={() => prop.onDelete && prop.onDelete(prop.item)}
                        >
                            <Delete />
                        </IconButton>
                    </Tooltip> */}

                    <Tooltip title="입력/출력 파일 조회">
                        <IconButton
                            sx={{
                                p: 0.5
                            }}
                            onClick={() => setOpenSimulationFileList(true)}
                        >
                            <FilePresentRounded />
                        </IconButton>
                    </Tooltip>
                    <IconButton
                        sx={{
                            p: 0.5
                        }}
                        onClick={() => setOpenDetail(!openDetail)}
                    >
                        {
                            openDetail
                                ? <ExpandLess />
                                : <ExpandMore />
                        }
                    </IconButton>

                </Box>
            </Box>

            <Divider sx={{ mt: 1, mb: 1, display: openDetail ? undefined : 'none' }} />

            <Box
                sx={{
                    pr: 1,
                    pl: 1,
                    display: openDetail ? 'flex' : 'none',
                    justifyContent: 'start',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                }}
            >
                <AttributeItem title="실행 시간" value={DateToString(prop.item.createDate, 'yyyy-MM-dd HH:mm:ss')} />
                <AttributeItem title="상태" value={prop.item.statusName ?? '정보 없음'} />
                {prop.item.supporterName && <AttributeItem title="담당자명" value={prop.item.supporterName ?? '정보 없음'} />}
                {prop.item.supporterDepartment && <AttributeItem title="담당자 부서" value={prop.item.supporterDepartment ?? '정보 없음'} />}
                {prop.item.supporterEmail && <AttributeItem title="담당자 이메일" value={prop.item.supporterEmail ?? '정보 없음'} />}
                {prop.item.supporterCallNumber && <AttributeItem title="담당자 번호" value={prop.item.supporterCallNumber ?? '정보 없음'} />}

            </Box>



            <SimulationFilesModal
                open={openSimulationFileList}
                onClose={() => setOpenSimulationFileList(false)}
                item={prop.item}
            />

            <RefetchSimulation
                open={openRefetchSimulation}
                onClose={() => setOpenRefetchSimulation(false)}
                item={prop.item}
                onSubmit={() => prop.onRefresh && prop.onRefresh()}
            />
        </Box>
    )
}


//#endregion



//#region Simulation create modal

interface ISimulationCreateModalProp {
    open: boolean,
    onClose: () => void
}

export const SimulationCreateModal = (prop: ISimulationCreateModalProp) => {
    return (
        <MISCModal
            open={prop.open}
            onClose={prop.onClose}
        />
    )
}



//#endregion



//#region Simulation File List Modal

interface ISimulationFilesModalProp {
    open: boolean,
    onClose: () => void
    item?: IKeriTechServiceSimulation
}

export const SimulationFilesModal = (prop: ISimulationFilesModalProp) => {

    return (
        <MISCModal
            open={prop.open && prop.item !== undefined}
            onClose={prop.onClose}
            paperProps={{
                sx: {
                    width: '500px'
                }
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    pt: 1,
                    pr: 2,
                    pl: 2,
                    pb: 0
                }}
            >
                <Typography
                    variant="subtitle1"
                    sx={{
                        fontWeight: 'bold'
                    }}
                >
                    시뮬레이션 파일
                </Typography>
                <IconButton
                    sx={{
                        m: 0,
                        p: 0
                    }}
                    onClick={prop.onClose}
                >
                    <Close />
                </IconButton>
            </Box>
            <Divider sx={{ mt: 1, mb: 1 }} />
            <Box
                sx={{
                    pl: 2,
                    pr: 2,
                    pb: 2
                }}
            >
                <FileList
                    title='Input 파일'
                    items={prop.item?.inputFiles}
                    type='input'
                />
                <FileList
                    title='Output 파일'
                    items={prop.item?.outputFiles }
                    // items={[
                    //     {
                    //         atchFileID: 'filei',
                    //         fileCategory: 'cat',
                    //         fileTitle: '111',
                    //         fileSize: 22,
                    //         fileStoreName: '121',
                    //         fileOriginalName: '111'
                    //     }
                    // ] as IKeriServerFile[]}
                    // items={[...prop.item?.outputFiles??[],...prop.item?.outputFiles??[],...prop.item?.outputFiles??[],...prop.item?.outputFiles??[],...prop.item?.outputFiles??[],]}
                    BoxStyle={{
                        mt: 2
                    }}
                    type='output'
                />
            </Box>
        </MISCModal>
    )
}


const FileList = (prop: {
    title: string,
    items?: IKeriServerFile[],
    BoxStyle?: SxProps<Theme>,
    type: 'input' | 'output'
}) => {
    return (
        <Box
            sx={{
                ...prop.BoxStyle
            }}
        >
            <Typography
                variant='subtitle2'
                sx={{
                    mb: 0.5,

                }}
            >
                {prop.title}
            </Typography>
            <Box
                sx={{
                    border: '1px solid rgba(0,0,0,0.2)',
                    borderRadius: '5px',
                    p: 1,
                    maxHeight: '150px',
                    overflowY: 'auto'
                }}
            >
                {
                    (prop.items ?? []).length === 0 &&
                    <Box
                        sx={{
                            textAlign: 'center'
                        }}
                    >
                        <Typography
                            variant='body2'
                        >
                            데이터가 없습니다
                        </Typography>

                    </Box>

                }
                {
                    prop.items?.map(item => (
                        <FileItem
                            item={item}
                            type={prop.type}
                        />
                    ))
                }
            </Box>
        </Box>
    )
}

const FileItem = (prop: {
    item: IKeriServerFile,
    type: 'input' | 'output'
}) => {
    const userRedux: ILoginSessionData = useSelector((state: any) => state.user);
    const downloadOutputMutation = useMutation(
        async () => await MISCFileAPI.GetSimulationOutputFile({
            atchFileId: prop.item.atchFileID,
            fileSn: prop.item.fileSequence,
            token: userRedux.token
        }, process.env.REACT_APP_API_URL),
        {
            onSuccess: (data) => {
                if (data.state === true && data.file !== undefined) {
                    const jsonFile = new Blob([data.file]);
                    const element = document.createElement("a");

                    element.href = URL.createObjectURL(jsonFile);
                    element.download = prop.item.fileOriginalName ?? 'no_title.txt';
                    document.body.appendChild(element);
                    element.click();
                    element.remove();
                }
                else {
                    alert('파일을 다운로드 할 수 없습니다.');
                    console.log(data);
                }
            },
            onError: (err) => {
                console.log('err', err);
                alert('파일을 다운로드 할 수 없습니다.');
            }
        }
    )

    const downloadInputMutation = useMutation(
        async () => await MISCFileAPI.GetFile({
            atchFileId: prop.item.atchFileID,
            fileSn: prop.item.fileSequence,
            token: userRedux.token
        }, process.env.REACT_APP_API_URL),
        {
            onSuccess: (data) => {
                if (data.state === true && data.file !== undefined) {
                    const jsonFile = new Blob([data.file]);
                    const element = document.createElement("a");

                    element.href = URL.createObjectURL(jsonFile);
                    element.download = prop.item.fileOriginalName ?? 'no_title.txt';
                    document.body.appendChild(element);
                    element.click();
                    element.remove();
                }
                else {
                    console.log('download-success-error', data);
                    alert('파일을 다운로드 할 수 없습니다.');
                }
            },
            onError: (err) => {
                console.log('download-err', err);
                alert('파일을 다운로드 할 수 없습니다.');
            }
        }
    )


    const downloadHandler = () => {
        if (prop.type === 'input') {
            downloadInputMutation.mutate();
        }
        else {
            // downloadOutputMutation.mutate();
            try{
                const element = document.createElement("form");
                element.setAttribute("charset","UTF-8");
                element.action = `${process.env.REACT_APP_API_URL}/kri/cmm/lightWeightFileDownload.do`;
                element.name = "download-outputfile-form";
                element.method = "post";
                element.hidden = true;
                
    
                const tokenInput = document.createElement("input");
                tokenInput.name="token";
                tokenInput.hidden = true;
                tokenInput.value = userRedux.token ?? "";
    
                const atchFileInput = document.createElement("input");
                atchFileInput.name="atchFileId";
                atchFileInput.hidden = true;
                atchFileInput.value = prop.item.atchFileID?.toString() ?? "";
    
                const fileSnInput = document.createElement("input");
                fileSnInput.name="fileSn";
                fileSnInput.hidden = true;
                fileSnInput.value = prop.item.fileSequence?.toString() ?? "";
    
                element.appendChild(tokenInput);
                element.appendChild(atchFileInput);
                element.appendChild(fileSnInput);

                document.body.appendChild(element);
    
                element.addEventListener('submit' , (evt) => {evt.preventDefault()});
                element.submit();
                
                element.remove();
            }
            catch{
                alert('다운로드 실패');
            }
           
        }

    }

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: 0.5,
                mt: 0.5,
                cursor: 'pointer',
                ":hover": {
                    color: '#72afb5'
                }
            }}
            onClick={downloadHandler}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'start',
                    alignItems: 'center'
                }}
            >
                {
                    (downloadInputMutation.isLoading || downloadOutputMutation.isLoading)
                        ?
                        <CircularProgress
                            size={15}
                            sx={{
                                mr: 1,
                                mt: 0.5,
                                color: 'black'
                            }}
                        />
                        :
                        <Download
                            fontSize='small'
                            sx={{
                                mr: 1,
                                mt: 0.5
                            }}
                        />
                }


                <Typography
                    variant='body2'
                >
                    {prop.item.fileOriginalName}
                </Typography>
            </Box>
            <Box>
                <Typography
                    variant='body2'
                >
                    {ObjectUtils.FileSizeFormater(prop.item.fileSize ?? 0)}
                </Typography>

            </Box>


        </Box>
    )
}


//#endregion