import { IUploadFileVO } from "@keri-portal/interfaces/api/keri-api/object/IUploadFileVO"
import { IUploadFile } from "@keri-portal/interfaces/control/IUploadFile"
import { IMenuItem } from "@keri-portal/interfaces/data-object/data-common/IMenu"
import URLStructure from "@src/modules/structure/URLStructure"

interface IFakeMainNoticesData {
    idx: number,
    title: string,
    description: string,
    imageURL: string[]
}


export const FakeMainNoticesData: IFakeMainNoticesData[] = [
    {
        idx: 1,
        imageURL: [
            `${process.env.PUBLIC_URL}/assets/img/ict/images/bis_1.jpg`,
            `${process.env.PUBLIC_URL}/assets/img/ict/images/bis_2.jpg`
        ],
        description: '<한국전기연구원 공정해석 의뢰 신청 실시 안내> ㅇ 경남도, 창원시 중소기업의 해석 문제 해결을 위한 진입 장벽을 낮추고, 해석 전문가, 컴퓨팅 파워의 활용을 통한 개발 제품의 성능 분석을 신속히 해결 ㅇ 해석 기술지원을 위한 개방형 웹 포털 사이트의 개설을 통하여 중소기업의 해석기술 문의, 컨설팅, 해석 의뢰, 문제 분석, 해석 결과 도출의 절차를 표준화하고 일원화 / 공정해석 신청 바로가기 / 여러분의 많은 참여 부탁드립니다. 감사합니다.',
        title: '시뮬레이션 센터 오픈 일정 안내'
    },
    {
        idx: 1,
        imageURL: [
            `${process.env.PUBLIC_URL}/assets/img/ict/images/bis_4.jpg`,
            `${process.env.PUBLIC_URL}/assets/img/ict/images/bis_3.jpg`
        ],
        description: '전기연구원이 주관하는 공정 혁신 시뮬레이션은 3년 동안 350억 원을 들여, 중소기업이 제품 개발 단계에서 겪는 어려움을 시뮬레이션을 통해 예측하고, 해결 방안을 제시하기 위한 사업입니다. 창원대가 주관하고 경남대, 창원 문성대 등이 참여하는 스마트제조 인력 양성사업에는 3년 동안 428억 원이 투입됩니다.',
        title: '시스템 정기 점검에 따른 운휴 기간 공지'
    },
    {
        idx: 1,
        imageURL: [
            `${process.env.PUBLIC_URL}/assets/img/ict/images/newsImg1.jpg`,
            `${process.env.PUBLIC_URL}/assets/img/ict/images/newsImg2.jpg`
        ],
        description: '안녕하세요. 연간 해석관련 정기 교육일정이 업데이트 되었습니다. 감사합니다.',
        title: '연간 해석관련 정기 교육일정 업데이트 공지'
    },
    {
        idx: 1,
        imageURL: [
            `${process.env.PUBLIC_URL}/assets/img/ict/images/sumul_thum.jpg`,
            `${process.env.PUBLIC_URL}/assets/img/ict/images/bis_1.jpg`
        ],
        description: '안녕하세요. 공정혁신 시뮬레이션 센터입니다. 해석의뢰 정보를 입력시 아래의 내용으로 간략히 작성해 주십시오. 1.개요(2줄 이내) -해석 제품 소개, 필요성 등 2.완료 요청일 -0000.00.00 3. 첨부 파일(신청기업 담당자 계정으로 아래 서류를 업로드함) 감사합니다.',
        title: '해석기술지원 서비스 설명회 개최 안내'
    },
    {
        idx: 1,
        imageURL: [
            `${process.env.PUBLIC_URL}/assets/img/ict/images/center.jpg`,
            `${process.env.PUBLIC_URL}/assets/img/ict/images/bis_4.jpg`
        ],
        description: '중소·중견기업이 제품개발 단계에서 겪는 각종 어려움을 시뮬레이션을 통해 사전에 예측하고 해결방안을 제시해주는 "공정혁신 시뮬레이터" 구축 사업 본격화 2022년 초 센터 건물 구축 예정 한국전기연구원(KERI) -‘경남형 히든 챔피언’ 양성 목적 / 제조 공정 혁신, 해결방안 등 제시 / 소요 기간·비용 획기적 절감 도와 기계·자동차·방산분야 집중 활용',
        title: '제품성능시험기 신규 도입에 따른 서비스 점검 안내'
    },
    {
        idx: 1,
        imageURL: [
            `${process.env.PUBLIC_URL}/assets/img/ict/images/main01.jpg`,
            `${process.env.PUBLIC_URL}/assets/img/ict/images/main02.jpg`
        ],
        description: '안녕하세요. 공정혁신 시뮬레이션 센터 관리자입니다. 최초 회원가입 승인 후 해석신청하기의 제출 단계에서 일시적인 오류가 발생하고 있습니다. 로그아웃전 해석신청하기의 입력사항이 삭제될수 있으니, 반드시 백업하시고 진행하시기 바랍니다.',
        title: '지능형 시뮬레이션 이용 안내'
    }



]



export const FakeMainFAQData: IFakeMainNoticesData[] = [
    {
        idx: 1,
        // imageURL: [
        //     'https://raw.githubusercontent.com/PKief/vscode-material-icon-theme/main/logo.png'
        // ],
        imageURL: [`${process.env.PUBLIC_URL}/assets/img/main-techservice-image/is.png`],
        description: '지능형 시뮬레이션을 신청하려는 회사 및 기관은 먼저 본 사이트에 기업회원으로 가입하셔야 합니다. 이미 기업회원으로 가입되어 있으신 경우에는 <기술지원 신청>을 통해 지능형 시뮬레이션을 신청하실 수 있습니다. 자세한 사항은 <지능형 시뮬레이션 신청 안내>를 참고하시기 바랍니다.',
        title: '지능형 시뮬레이션 신청시 필요한 서류 및 절차는 어떻게 되는지요?'
    },
    {
        idx: 1,
        imageURL: [`${process.env.PUBLIC_URL}/assets/img/main-techservice-image/arvr.png`],
        description: 'AR/VR 서비스시에 관한 요구사항 및 제약사항 등에 관한 질문이나 자료 제공 요청에 성실히 임해주셔야 합니다.',
        title: 'AR/VR 서비스 진행시 신청자/제작자의 협조사항은 있는지요?'
    },
    {
        idx: 1,
        imageURL: [`${process.env.PUBLIC_URL}/assets/img/main-techservice-image/ct.png`],
        description: '제어 및 신호측정 서비스 신청에 관한 일반적인 문의 사항은 <자주 묻는 질문과 답>이나 <제어 및 신호측정 신청 안내>를 참고하시기 바랍니다. 제어 및 신호측정 서비스 신청이나 기술적인 상담은 <기술지원 상담 게시판>을 통해 글을 남겨주시기 바랍니다. 신청이 완료된 제어 및 신호측정 서비스에 관한 문의 사항은 해당 기술지원 목록 창에서 해당하는 제어 및 신호측정의 상세 창 하단에 있는 메시지 전송 기능을 이용해 문의하실 수 있습니다.',
        title: '제어 및 신호측정 서비스 신청에 대한 문의는 어떻게 하는지요?'
    }
]

interface IFakeTechInfoData {
    idx: number,
    title: string,
    description: string,
    iconURL: string,
    color: string,
    link : string
}

export const FakeTechInfoData: IFakeTechInfoData[] = [
    {
        idx: 1,
        title: '해석 기술 지원',
        description: '시뮬레이션을 통한 제품의 성능 및 안정성 테스트',
        iconURL: `${process.env.PUBLIC_URL}/assets/img/main-techservice-image/ar_bg.png`,
        color: '#f2f2f2',
        link : URLStructure.GuildesSimulationGuide
    },
    {
        idx: 2,
        title: '제품 성능 시험',
        description: '시편의 시뮬레이션용 재료 데이터와 내구한도 측정',
        iconURL: `${process.env.PUBLIC_URL}/assets/img/main-techservice-image/pt_bg.png`,
        color: '#bdd7ee',
        link : URLStructure.GuildesPerformanceTestGuide
    },
    {
        idx: 3,
        title: '지능형 시뮬레이션',
        description: '해석 기술 지원 이후 변경된 변수에 따른 제품 재해석',
        iconURL: `${process.env.PUBLIC_URL}/assets/img/main-techservice-image/is_bg.png`,
        color: '#b4c7e7',
        link : URLStructure.GuidesActiveSimulationGuide
    },
    {
        idx: 2,
        title: 'AR/VR',
        description: '가상공간에서 시뮬레이션 결과 확인 및 제조 공정 시연',
        iconURL: `${process.env.PUBLIC_URL}/assets/img/main-techservice-image/arvr_bg.png`,
        color: '#dae3f3',
        link : URLStructure.GuidesArVrServiceGuide
    },
    {
        idx: 5,
        title: '3D 프린팅',
        description: '3D 프린터를 이용한 다양한 소재의 시제품 제작',
        iconURL: `${process.env.PUBLIC_URL}/assets/img/main-techservice-image/3d_bg.png`,
        color: '#deebf7',
        link : URLStructure.Guides3dPrintingGuide
        
    },
    {
        idx: 6,
        title: '제어 및 신호측정',
        description: '전기/전자 분야 제품의 신호 측정 및 제품의 효율과 전력 분석',
        iconURL: `${process.env.PUBLIC_URL}/assets/img/main-techservice-image/ct_bg.png`,
        color: '#d9d9d9',
        link : URLStructure.GuidesControlsignalmeasurementGuide
    }
]

interface IFakeReportData {
    idx: number,
    title: string,
    description: string,
    url: string
}

export const FakeReportData: IFakeReportData[] = [
    {
        idx: 1,
        title: '전기연 ‘공정혁신 시뮬레이션센터’, 총 200억 규모 제품개발 및 생산기간 단축 효과',
        description: '경남 창원에 위치한 한국전기연구원(KERI, 원장 명성호)이 똑똑한 ‘지능 전기기술’을 활용해 펼치는 지역기업 지원 사업들이 큰 호평을 받고 있다. 그 중 대표적인 사업이 바로 ‘공정혁신 시뮬레이션센터’다. ‘공정혁신 시뮬레이션센터’는 한국전기연구원이 산업통상자원부(장관 문승욱), 경남도(경남도지사 권한대행 하병필) 및 창원시(시장 허성무), 경남창원스마트산단사업단과 함께 구축한 인프라로, 기업들이 제품 개발 단계에서 겪는 각종 어려움을 해결해주는 역할을 수행하고 있다.',
        url: '#'
    },
    {
        idx: 1,
        title: '한국전기연구원, 노후화 산단에 혁신 불어넣는다',
        description: '경남 창원시에 본원을 둔 한국전기연구원(KERI)이 개발한 ‘공정혁신 시뮬레이션센터’가 국가산업단지에 입주한 기업들의 애로사항을 적극적으로 해소하면서 기업 경쟁력 제고의 일등공신으로 부상하고 있다. 지능형 전기기술을 기반으로 각 지역 기업을 대상으로 한 지원 사업들이 호평을 받으면서 기업의 수출 경쟁력을 끌어올리는 원동력으로 자리재김하고 있다는 평가다.',
        url: '#'
    },
    {
        idx: 1,
        title: "한국전기연구원, 3대 핵심 기업 기반시설 구축 완료…'전기화 시대' 창원中企 경쟁력강화",
        description: "국책연구기관인 한국전기연구원(KERI)이 3대 핵심 인프라스트럭처를 올해 완료하고 본격 운영에 들어간다.전기연구원이 올해 구축한 3대 대형 기업 지원 기반 시설은 '스마트이노베이션센터' '초고압 직류전송 전력기기 국제공인 시험 장비' '기술창업센터 증축'이다. 이들 시설은 중소·지역기업 지원으로 지역경제를 활성화하기 위해 창원시와 함께 추진돼왔다. 우선 '스마트이노베이션센터'는 140억원이 투입돼 올여름 완공될 예정이다. '스마트이노베이션센터'에는 '공정혁신 시뮬레이션'과 '스마트 제조 고급 인력 양성 사업'이 추진된다.",
        url: '#'
    },
]


interface IFakeWorkType {
    idx: number,
    title: string,
    iconURL: string,
}

export const FakeWorkType: IFakeWorkType[] = [
    {
        idx: 1,
        title: '전산해석 기반 데이터 제공 및 축적',
        iconURL: `${process.env.PUBLIC_URL}/assets/img/ict/taskicon/mainTask1.svg`,
    },
    {
        idx: 2,
        title: '결과분석 및 목표성능 검증',
        iconURL: `${process.env.PUBLIC_URL}/assets/img/ict/taskicon/mainTask2.svg`,
    },
    {
        idx: 3,
        title: '맞춤형 컨설팅 및 솔루션',
        iconURL: `${process.env.PUBLIC_URL}/assets/img/ict/taskicon/mainTask3.svg`,
    },
    {
        idx: 4,
        title: '제품의 불량원인 파악 및 해결방안 제시',
        iconURL: `${process.env.PUBLIC_URL}/assets/img/ict/taskicon/mainTask4.svg`,
    },
    {
        idx: 5,
        title: '전산해석 기반 연구개발 지원',
        iconURL: `${process.env.PUBLIC_URL}/assets/img/ict/taskicon/mainTask5.svg`,
    },
    {
        idx: 6,
        title: '제조생산 정보 디지털화',
        iconURL: `${process.env.PUBLIC_URL}/assets/img/ict/taskicon/mainTask6.svg`,
    },
    {
        idx: 7,
        title: '최적화 시뮬레이션 및 설계',
        iconURL: `${process.env.PUBLIC_URL}/assets/img/ict/taskicon/mainTask7.svg`,
    },
    {
        idx: 8,
        title: '개발단계에서의 어려움 사전예측',
        iconURL: `${process.env.PUBLIC_URL}/assets/img/ict/taskicon/mainTask8.svg`,
    }
]




export const FakeTechServiceInfoDownloadFileList: IUploadFile<IUploadFileVO>[] = [
    {
        Index: 1,
        Label: '제출자료 양식',
        Category: 'TechServiceDownload',
        Prefix: 'TSD_',
        UploadedFile: {
            orignlFileNm: '기술지원 신청 안내문.pdf',
            fileMg: '1000000'
        }
    },
    {
        Index: 2,
        Label: '제출자료 양식',
        Category: 'TechServiceDownload',
        Prefix: 'TSD_',
        UploadedFile: {
            orignlFileNm: '기술지원 신청 자격 적정성 확인서 양식.pdf',
            fileMg: '1000000'
        }
    },
    {
        Index: 3,
        Label: '제출자료 양식',
        Category: 'TechServiceDownload',
        Prefix: 'TSD_',
        UploadedFile: {
            orignlFileNm: '국가 R&D과제 우대기업 확인서 양식.pdf',
            fileMg: '1000000'
        }
    },
    {
        Index: 4,
        Label: '제출자료 양식',
        Category: 'TechServiceDownload',
        Prefix: 'TSD_',
        UploadedFile: {
            orignlFileNm: '기술지원 신청서 작성 예시.pdf',
            fileMg: '1000000'
        }
    }
]



export const FAKEDATA_BOARD_DATA = {
    BoardTitle: '공지사항',
    BoardList: [
        {
            BoardItemID: 'test1',
            BoardItemTitle: '공지사항1',
            BoardItemContent: 'testTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwe',
            BoardItemThumbnailURL: [
                'https://velog.velcdn.com/images/real-bird/post/e78122ca-dd16-4ae6-832d-03d0edfb18cb/image.png',
                'https://uploads.sitepoint.com/wp-content/uploads/2022/08/1660112010typescript-code.png'
            ],
            BoardItemWriteDate: new Date('2022-10-10 17:00:00'),
            BoardItemWriter: 'Writer1',
            BoardItemViewCount: 10
        },
        {
            BoardItemID: 'test2',
            BoardItemTitle: '공지사항2공지사항2공지사항2공지사항2공지사항2공지사항2공지사항2공지사항2공지사항2공지사항2공지사항2공지사항2공지사항2공지사항2공지사항2공지사항2공지사항2공지사항2공지사항2공지사항2공지사항2공지사항2공지사항2',
            BoardItemContent: 'testTestesttwetw3etwtweagwwr23twetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwe',
            BoardItemThumbnailURL: [],
            BoardItemWriteDate: new Date('2022-10-10 17:00:00'),
            BoardItemWriter: 'Writer1'
        },
        {
            BoardItemID: 'test1',
            BoardItemTitle: '공지사항3',
            BoardItemContent: 'testTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwe',
            BoardItemThumbnailURL: [
                'https://velog.velcdn.com/images/real-bird/post/e78122ca-dd16-4ae6-832d-03d0edfb18cb/image.png',
                'https://uploads.sitepoint.com/wp-content/uploads/2022/08/1660112010typescript-code.png'
            ],
            BoardItemWriteDate: new Date('2022-10-10 17:00:00'),
            BoardItemWriter: 'Writer1',
            BoardItemViewCount: 10
        },
        {
            BoardItemID: 'test2',
            BoardItemTitle: '공지사항4',
            BoardItemContent: 'testTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwe',
            BoardItemThumbnailURL: [
                'https://velog.velcdn.com/images/real-bird/post/e78122ca-dd16-4ae6-832d-03d0edfb18cb/image.png',
                'https://uploads.sitepoint.com/wp-content/uploads/2022/08/1660112010typescript-code.png'
            ],
            BoardItemWriteDate: new Date('2022-10-10 17:00:00'),
            BoardItemWriter: 'Writer1'
        },
        {
            BoardItemID: 'test1',
            BoardItemTitle: '공지사항5',
            BoardItemContent: 'testTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwe',
            BoardItemThumbnailURL: [
                'https://velog.velcdn.com/images/real-bird/post/e78122ca-dd16-4ae6-832d-03d0edfb18cb/image.png',
                'https://uploads.sitepoint.com/wp-content/uploads/2022/08/1660112010typescript-code.png'
            ],
            BoardItemWriteDate: new Date('2022-10-10 17:00:00'),
            BoardItemWriter: 'Writer1',
            BoardItemViewCount: 10
        },
        {
            BoardItemID: 'test2',
            BoardItemTitle: '공지사항6',
            BoardItemContent: 'testTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwe',
            BoardItemThumbnailURL: [],
            BoardItemWriteDate: new Date('2022-10-10 17:00:00'),
            BoardItemWriter: 'Writer1'
        },
        {
            BoardItemID: 'test1',
            BoardItemTitle: '공지사항7',
            BoardItemContent: 'testTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwe',
            BoardItemThumbnailURL: [
                'https://velog.velcdn.com/images/real-bird/post/e78122ca-dd16-4ae6-832d-03d0edfb18cb/image.png',
                'https://uploads.sitepoint.com/wp-content/uploads/2022/08/1660112010typescript-code.png'
            ],
            BoardItemWriteDate: new Date('2022-10-10 17:00:00'),
            BoardItemWriter: 'Writer1',
            BoardItemViewCount: 10
        },
        {
            BoardItemID: 'test2',
            BoardItemTitle: '공지사항8',
            BoardItemContent: 'testTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwe',
            BoardItemThumbnailURL: [
                'https://velog.velcdn.com/images/real-bird/post/e78122ca-dd16-4ae6-832d-03d0edfb18cb/image.png',
                'https://uploads.sitepoint.com/wp-content/uploads/2022/08/1660112010typescript-code.png'
            ],
            BoardItemWriteDate: new Date('2022-10-10 17:00:00'),
            BoardItemWriter: 'Writer1'
        },
        {
            BoardItemID: 'test1',
            BoardItemTitle: '공지사항9',
            BoardItemContent: 'testTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwe',
            BoardItemThumbnailURL: [
                'https://velog.velcdn.com/images/real-bird/post/e78122ca-dd16-4ae6-832d-03d0edfb18cb/image.png',
                'https://uploads.sitepoint.com/wp-content/uploads/2022/08/1660112010typescript-code.png'
            ],
            BoardItemWriteDate: new Date('2022-10-10 17:00:00'),
            BoardItemWriter: 'Writer1',
            BoardItemViewCount: 10
        },
        {
            BoardItemID: 'test2',
            BoardItemTitle: '공지사항10',
            BoardItemContent: 'testTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwe',
            BoardItemThumbnailURL: [],
            BoardItemWriteDate: new Date('2022-10-10 17:00:00'),
            BoardItemWriter: 'Writer1'
        },
        {
            BoardItemID: 'test1',
            BoardItemTitle: '공지사항11',
            BoardItemContent: 'testTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwe',
            BoardItemThumbnailURL: [
                'https://velog.velcdn.com/images/real-bird/post/e78122ca-dd16-4ae6-832d-03d0edfb18cb/image.png',
                'https://uploads.sitepoint.com/wp-content/uploads/2022/08/1660112010typescript-code.png'
            ],
            BoardItemWriteDate: new Date('2022-10-10 17:00:00'),
            BoardItemWriter: 'Writer1',
            BoardItemViewCount: 10
        },
        {
            BoardItemID: 'test2',
            BoardItemTitle: '공지사항12',
            BoardItemContent: 'testTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwe',
            BoardItemThumbnailURL: [
                'https://velog.velcdn.com/images/real-bird/post/e78122ca-dd16-4ae6-832d-03d0edfb18cb/image.png',
                'https://uploads.sitepoint.com/wp-content/uploads/2022/08/1660112010typescript-code.png'
            ],
            BoardItemWriteDate: new Date('2022-10-10 17:00:00'),
            BoardItemWriter: 'Writer1'
        },
        {
            BoardItemID: 'test1',
            BoardItemTitle: '공지사항13',
            BoardItemContent: 'testTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwe',
            BoardItemThumbnailURL: [
                'https://velog.velcdn.com/images/real-bird/post/e78122ca-dd16-4ae6-832d-03d0edfb18cb/image.png',
                'https://uploads.sitepoint.com/wp-content/uploads/2022/08/1660112010typescript-code.png'
            ],
            BoardItemWriteDate: new Date('2022-10-10 17:00:00'),
            BoardItemWriter: 'Writer1',
            BoardItemViewCount: 10
        },
        {
            BoardItemID: 'test2',
            BoardItemTitle: '공지사항14',
            BoardItemContent: 'testTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwe',
            BoardItemThumbnailURL: [],
            BoardItemWriteDate: new Date('2022-10-10 17:00:00'),
            BoardItemWriter: 'Writer1'
        },
        {
            BoardItemID: 'test1',
            BoardItemTitle: '공지사항15',
            BoardItemContent: 'testTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwe',
            BoardItemThumbnailURL: [
                'https://velog.velcdn.com/images/real-bird/post/e78122ca-dd16-4ae6-832d-03d0edfb18cb/image.png',
                'https://uploads.sitepoint.com/wp-content/uploads/2022/08/1660112010typescript-code.png'
            ],
            BoardItemWriteDate: new Date('2022-10-10 17:00:00'),
            BoardItemWriter: 'Writer1',
            BoardItemViewCount: 10
        },
        {
            BoardItemID: 'test2',
            BoardItemTitle: '공지사항16',
            BoardItemContent: 'testTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwe',
            BoardItemThumbnailURL: [
                'https://velog.velcdn.com/images/real-bird/post/e78122ca-dd16-4ae6-832d-03d0edfb18cb/image.png',
                'https://uploads.sitepoint.com/wp-content/uploads/2022/08/1660112010typescript-code.png'
            ],
            BoardItemWriteDate: new Date('2022-10-10 17:00:00'),
            BoardItemWriter: 'Writer1'
        },
        {
            BoardItemID: 'test1',
            BoardItemTitle: '공지사항17',
            BoardItemContent: 'testTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwe',
            BoardItemThumbnailURL: [
                'https://velog.velcdn.com/images/real-bird/post/e78122ca-dd16-4ae6-832d-03d0edfb18cb/image.png',
                'https://uploads.sitepoint.com/wp-content/uploads/2022/08/1660112010typescript-code.png'
            ],
            BoardItemWriteDate: new Date('2022-10-10 17:00:00'),
            BoardItemWriter: 'Writer1',
            BoardItemViewCount: 10
        },
        {
            BoardItemID: 'test2',
            BoardItemTitle: '공지사항18',
            BoardItemContent: 'testTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwe',
            BoardItemThumbnailURL: [],
            BoardItemWriteDate: new Date('2022-10-10 17:00:00'),
            BoardItemWriter: 'Writer1'
        },
        {
            BoardItemID: 'test1',
            BoardItemTitle: '공지사항19',
            BoardItemContent: 'testTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwe',
            BoardItemThumbnailURL: [
                'https://velog.velcdn.com/images/real-bird/post/e78122ca-dd16-4ae6-832d-03d0edfb18cb/image.png',
                'https://uploads.sitepoint.com/wp-content/uploads/2022/08/1660112010typescript-code.png'
            ],
            BoardItemWriteDate: new Date('2022-10-10 17:00:00'),
            BoardItemWriter: 'Writer1',
            BoardItemViewCount: 10
        },
        {
            BoardItemID: 'test2',
            BoardItemTitle: '공지사항20',
            BoardItemContent: 'testTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwetestTestesttwetw3etwtweatwetwetwetewtewtwetwetwetwetwetwe',
            BoardItemThumbnailURL: [
                'https://velog.velcdn.com/images/real-bird/post/e78122ca-dd16-4ae6-832d-03d0edfb18cb/image.png',
                'https://uploads.sitepoint.com/wp-content/uploads/2022/08/1660112010typescript-code.png'
            ],
            BoardItemWriteDate: new Date('2022-10-10 17:00:00'),
            BoardItemWriter: 'Writer1'
        }
    ]
}