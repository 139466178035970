import { AxiosError, AxiosResponse } from "axios"
import { useEffect, useState } from "react"
import { CallAPI } from "@ict-base/module/utils/CallAPI";
import KeriSelectItemModal from "./KeriSelectItemModal"
import IJusoResult, { IJusoAddressData } from "@ict-base/interfaces/api/koera-juso/IJusoResult";
import JusoAPI from "@ict-base/module/service/JusoAPI";

interface IKeriSelectAddressModal {
    open: boolean
    onClose: () => void
    onSubmit: (item: IJusoAddressData | undefined) => void
}





const KeriSelectAddressModal = (prop: IKeriSelectAddressModal) => {

    const [loadData, setLoadData] = useState<IJusoAddressData[]>([]);
    const [page, setPage] = useState<number>(1);
    const [pageMaxCount, setPageMaxCount] = useState<number>(1);
    const [searchText, setSearchText] = useState<string>('');
    const [startProcess, setStartProcess] = useState<boolean>(false);

    const getSearchAddressFromAPI = async () => {
        const getJusoData = await JusoAPI({
            page: page,
            pageItemCount: 5,
            searchText: searchText
        });

        if(getJusoData.state){
            setLoadData(getJusoData.data??[]);
            setPageMaxCount(getJusoData.totalPage);
        }
        else{
            alert(getJusoData.resultMessage);
        }
    }

    useEffect(() => {
        if (startProcess) {
            getSearchAddressFromAPI();
        }
    }, [page, searchText])

    return (
        <KeriSelectItemModal
            open={prop.open}
            onClose={() => {
                setStartProcess(false);
                prop.onClose();
            }}
            dataObject={{
                onSubmit : (item) => {
                    if (!Array.isArray(item)) {
                        prop.onSubmit(item as IJusoAddressData | undefined);
                    }
                    else {
                        alert('error');
                    }
                },
                value : loadData
            }}
            displayObject={{
                windowTitle : '주소',
                displayItemTitle : (obj) => obj.roadAddr,
                displayItemSubTitle : (obj) => obj.jibunAddr,
                displayItemCode : (obj) => obj.zipNo
            }}
            searchProp={{
                onSearch : (text) => {
                    setStartProcess(true);
                    setSearchText(text);
                },
                placeHolder : "주소 검색 (도로명, 지번명, 우편번호 등)"
            }}
            modalProp={{
                windowWidth : 600
            }}
            paging={{
                count: pageMaxCount,
                page: page,
                onPageChange: (changePage: number) => {
                    setPage(changePage);
                }
            }}
        />
    )
}

export default KeriSelectAddressModal;