import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import MenuCategoryReducer from './reducer/MenuCategoryReducer';
import MenuDataReducer from './reducer/MenuDataReducer';
import UserAlertReducer from './reducer/UserAlertReducer';
import UserDataReducer from './reducer/UserDataReducer';



const ReduxStore = configureStore({
  reducer : {
    user : UserDataReducer,
    menu : MenuDataReducer,
    menuCategory : MenuCategoryReducer,
    userAlert : UserAlertReducer
  },
  // middleware : [logger]
});


export default ReduxStore;