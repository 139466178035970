import { IKeriAlert, IKeriMenuCategoryItem, IKeriUserRedux } from "@ictpeople/misc-core";
import { ILoginSessionData } from "@src/modules/redux/reducer/UserDataReducer";
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { MISCSystemAlertScript } from "./MISCSystemAlertScript";
import { MISCSystemMenuListScript } from "./MISCSystemMenuListScript";

interface IMISCSystemBackground {
    userSessionData?: IKeriUserRedux,
    reduxUpdate: {
        alert: [selector: IKeriAlert | undefined, dispatcher: (item? : IKeriAlert) => void],
        menu: [selector: IKeriMenuCategoryItem[], dispatcher: (item: IKeriMenuCategoryItem[]) => void],
        user: [selector: ILoginSessionData, dispatcher: (item: ILoginSessionData) => void],
    }
}



export const MISCSystemBackground = (prop: IMISCSystemBackground) => {

    const userRedux: ILoginSessionData = prop.reduxUpdate.user[0];


    return (
        <React.Fragment>
            <MISCSystemAlertScript
                token={prop.userSessionData?.token ?? userRedux.token}
                redux={{
                    data : prop.reduxUpdate.alert[0],
                    update : (item) => prop.reduxUpdate.alert[1](item)
                }}
            />
            <MISCSystemMenuListScript
                token={prop.userSessionData?.token ?? userRedux.token} 
                redux={{
                    data : prop.reduxUpdate.menu[0],
                    update : (item) => {
                        console.log('UPDATE REDUX' , item);
                        return prop.reduxUpdate.menu[1](item)
                    }
                }}
            />
        </React.Fragment>
    )
}