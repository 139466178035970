/* eslint-disable react-hooks/exhaustive-deps */
import { IUseGetTechServiceList, MISCDefaultPageLayout, MISCTechServiceList, MISCPageStateComponent, useGetTechServiceList, IUseGetImage, useGetImage } from "@ictpeople/misc-react";
import { IKeriCommonEnv, IKeriTechService } from '@ictpeople/misc-core';
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { ILoginSessionData } from "@src/modules/redux/reducer/UserDataReducer";
import { BlobToURL, DateToString, IsUndefined, URLParameterUtil } from "@ictpeople/common";
import { MISCFileAPI } from "@ictpeople/misc-api";
import { Paper, CircularProgress, Chip, Typography, Rating } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import URLStructure from "@src/modules/structure/URLStructure";
import { useMutation, useQuery } from "react-query";
import { ObjectUtils } from "@ict-base/module/utils/ObjectUtils";



const RequestTechServiceListPage = () => {

    const userRedux: ILoginSessionData = useSelector((state: any) => state.user);
    const location = useLocation();

    const [hookData, setHookData] = useState<IUseGetTechServiceList>({
        page: 1,
        pagePerCount: 10,
        TriggerTime: new Date(),
        usePagination: false,
    })

    useMemo(() => setHookData({
        ...hookData,
        token: userRedux.token
    }), [userRedux])

    const techServiceHook = useGetTechServiceList(hookData);



    //#region URL Function

    /**
     * URL Parameter 조회 후 page , search keyword 적용
     */
    const URLFormetting = () => {
        const paramData = URLParameterUtil.GetParams(location.search);

        let stateData = hookData;

        ;

        if (paramData.searchKeyword !== undefined) {
            stateData.searchKeyword = paramData.searchKeyword;
        }


        if (paramData.page !== undefined) {
            stateData.page = isNaN(parseInt(paramData.page)) ? 1 : parseInt(paramData.page)
        }
        else {
            URLParameterUtil.SetParam({
                searchKeyword: paramData.searchKeyword
            }).BuildURL(location.pathname)
        }

        if (paramData.techStatus !== undefined) {
            stateData.statusFilter = (techServiceHook.data?.resultData?.statusList ?? []).filter((x: IKeriCommonEnv) => paramData.techStatus.split(',').find(px => px === x.code) !== undefined);
        }


        if (paramData.techType !== undefined) {
            stateData.typeFilter = (techServiceHook.data?.resultData?.typeList ?? []).filter((x: IKeriCommonEnv) => paramData.techType.split(',').find(px => px === x.code) !== undefined);
        }

        setUrlParamChecked(true);
        setHookData(stateData);

    }


    /**
     * URL Parameter 변경 함수
     * @description null 일 경우 값 없음 처리 
     * @description any | undefined 의 경우 값 처리
     */
    const changeURLParam = (_page?: number | null, _searchKeyword?: string | null, _techStatus?: IKeriCommonEnv[] | null, _techType?: IKeriCommonEnv[] | null) => {
        let inputParamData: { [key: string]: string | number | undefined } = {};

        inputParamData.page = _page === null ? 1 : _page;
        if (_searchKeyword !== null) {
            inputParamData.searchKeyword = _searchKeyword;
        }

        if (_techType !== null) {
            inputParamData.techType = _techType?.map(x => x.code).join(',');
        }

        if (_techStatus !== null) {
            inputParamData.techStatus = _techStatus?.map(x => x.code).join(',');
        }




        URLParameterUtil.SetParam(inputParamData).BuildURL(location.pathname)
    }


    const [urlParamChecked, setUrlParamChecked] = useState<boolean>(false);

    //#endregion



    useEffect(() => {
        if ((techServiceHook.data?.resultData?.statusList ?? []).length !== 0 && (techServiceHook.data?.resultData?.typeList.length !== 0) && !urlParamChecked) {
            URLFormetting();
        }
    }, [location, techServiceHook.data?.resultData?.statusList, techServiceHook.data?.resultData?.typeList])




    return (
        <MISCPageStateComponent
            isLoading={techServiceHook.loading}
            errorMessage={techServiceHook.data?.state === false ? techServiceHook.data?.message : undefined}
        >

            <MISCDefaultPageLayout
                pageTitle="기술지원 내역"
                searchOptions={{
                    enableFilter: true,
                    enableSearchKeyword: true,
                    defaultSearchKeyword: hookData.searchKeyword,
                    filterList: [
                        {
                            code: 'TechServiceStatus',
                            title: '기술지원 상태',
                            type: 'chip',
                            lookupList: techServiceHook.data?.resultData?.statusList ?? [],
                            displayItemValue: (item: IKeriCommonEnv) => item.name ?? '',
                            itemCode: (item: IKeriCommonEnv) => item.code,
                            defaultCheck: hookData.statusFilter
                        },
                        {
                            code: 'TechServiceType',
                            title: '기술지원 종류',
                            type: 'chip',
                            lookupList: techServiceHook.data?.resultData?.typeList ?? [],
                            defaultCheck: hookData.typeFilter,
                            displayItemValue: (item: IKeriCommonEnv) => item.name ?? '',
                            itemCode: (item: IKeriCommonEnv) => item.code,
                        },
                    ]
                }}
                onSearch={(item) => {
                    setHookData({
                        ...hookData,
                        searchKeyword: item.searchKeyword,
                        statusFilter: item.filterData?.filter(x => x.code === 'TechServiceStatus').map(x => x.item) ?? [],
                        typeFilter: item.filterData?.filter(x => x.code === 'TechServiceType').map(x => x.item) ?? [],
                    })

                    changeURLParam(
                        hookData.page,
                        item.searchKeyword,
                        item.filterData?.filter(x => x.code === 'TechServiceStatus').map(x => x.item) ?? [],
                        item.filterData?.filter(x => x.code === 'TechServiceType').map(x => x.item) ?? [],
                    )
                }}
            >
                <MISCTechServiceList
                    techServiceList={techServiceHook.data?.resultData?.techServiceList}
                    sort={(a, b) => (b.createDt?.getTime() ?? 0) - (a.createDt?.getTime() ?? 0)}
                    token={userRedux.token}
                    renderDom={(item, idx) => <MISCTechServiceListItem item={item} key={idx} token={userRedux.token} defaultImageURL={URLStructure.PlaceHolderImage} />}

                />
            </MISCDefaultPageLayout>
        </MISCPageStateComponent>
    )
}





















































//#region Tech Service List Item

interface IMISCTechServiceListItem {
    item: IKeriTechService,
    token?: string,
    defaultImageURL?: string
}

const MISCTechServiceListItem = (prop: IMISCTechServiceListItem) => {


    //#region New Code
    const userRedux : ILoginSessionData = useSelector((state : any) => state.user);

    const navigate = useNavigate();
    const [abortControl] = useState<AbortController>(new AbortController());
    const [imageURL , setImageURL ]= useState<string | undefined>(undefined);

    const getImageQuery = useQuery(
        `tech-service-item-image-${prop.item.images![0].atchFileID}-${prop.item.images![0].fileSequence}`,
        () => MISCFileAPI.GetFile({
            atchFileId: prop.item.images![0].atchFileID,
            config: {
                abortSignal: abortControl.signal
            },
            fileSn: prop.item.images![0].fileSequence,
            token: userRedux.token
        }, process.env.REACT_APP_API_URL),
        {
            // refetchOnWindowFocus : false,
            staleTime : 99999,
            enabled : userRedux.token !== undefined && (prop.item.images ?? []).length !== 0,
            onSuccess : (data) => {
                console.log('GET DATA ' , data);
                if(data.file === undefined){
                    setImageURL(prop.defaultImageURL)
                }
                else{
                    BlobToURL(data.file!)
                        .then((url : string) => {
                            setImageURL(url);
                        })
                        .catch(() => {
                            setImageURL(prop.defaultImageURL);
                        })
                }
            },
            onError : (err) => {
                console.error('GET DATA ERROR' , err);
            }
        }
    )

        useEffect(() => {
            console.log('RELOAD_TECHSERIVCE_ITEM');
            getImageQuery.refetch();    
        },[prop.item])


    useEffect(() => {
        getImageQuery.refetch();

        return (() => {
            abortControl.abort();
        })
    },[])


    //#endregion

    return (
        <Paper
            elevation={0}
            sx={{
                width: '100%',
                border: '1px solid rgba(0,0,0,0.1)',
                borderRadius: '10px',
                overflow: 'hidden',
                p: 0,
                mb: 2,
                transition: '0.3s all ease',
                cursor: 'pointer',
                ":hover": {
                    boxShadow: '0px 4px 4px #14658759'
                }
            }}
            onClick={() => {
                prop.item.uniqueId && navigate(prop.item.uniqueId)
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'start',
                    height: '200px',

                }}
            >
                <Box
                    sx={{
                        width: '30%',
                        height: '100%',
                        borderRight: '1px solid rgba(0,0,0,0.1)',
                        minWidth: '200px',
                        overflow: 'hidden'
                    }}
                >
                    {
                        getImageQuery.isLoading &&
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '100%',
                                height: '100%'
                            }}
                        >
                            <CircularProgress />
                        </Box>
                    }
                    {
                        getImageQuery.isSuccess &&
                        <img
                            src={imageURL ?? prop.defaultImageURL}
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                // transform: 'scale(1.2)'
                            }}
                            alt="techserviceimage"
                        />
                    }


                </Box>
                <Box
                    sx={{
                        width: '70%',
                        height: '100%',
                        maxWidth: 'calc(100% - 200px)'
                    }}
                >
                    <Box
                        sx={{
                            minHeight: '100%',
                            p: 1,
                            pb: '30px',
                        }}
                    >
                        <Box
                            className="techServiceListItem-Header"
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'start',
                                    alignItems: 'center',
                                    width: "80%"
                                }}
                            >
                                <Chip
                                    label={prop.item.type?.name}
                                    sx={{
                                        fontWeight: 'bold',
                                        m: 0,
                                        p: 0,
                                        pt: 0.5,
                                        pb: 0.5,
                                        mr: 0.5,
                                        height: 'auto'
                                    }}
                                />
                                <Typography
                                    variant="h6"
                                    sx={{
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                    }}
                                >
                                    {prop.item.name}
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    width: '20%',
                                    overflow: 'hidden',
                                    textAlign: 'end'
                                }}
                            >
                                <Typography
                                    variant="subtitle1"
                                    sx={{
                                        fontWeight: '500',
                                        mr: 1,
                                        whiteSpace: 'nowrap'
                                    }}
                                >
                                    {prop.item.status?.name}
                                </Typography>
                            </Box>
                        </Box>
                        <Box>
                            <MISCTechServiceItemContent
                                item={prop.item}
                            />
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            transform: 'translateY(-100%)',
                            height: '40px',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            borderTop: '1px solid rgba(0,0,0,0.1)',
                            background: '#f7f7f7',
                            pr: 1,
                            pl: 1
                        }}
                    >
                        <Box>
                            <Typography
                                variant="body2"
                                sx={{
                                    color: 'gray',
                                    ml: 0.5
                                }}
                            >
                                {DateToString(prop.item.createDt, 'yyyy-MM-dd HH:mm:ss')}
                            </Typography>
                        </Box>
                        <Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'end',
                                    alignItems: 'center'
                                }}
                            >
                                {
                                    (prop.item.rating !== undefined && prop.item.rating !== 0) &&
                                    <React.Fragment>
                                        {/* Custom Rating */}
                                        <Rating
                                            value={prop.item.rating ?? 0 / 2}
                                            max={5}
                                            precision={0.5}
                                            readOnly
                                            size="small"
                                            sx={{
                                                mr: 1
                                            }}
                                        />

                                    </React.Fragment>
                                }


                                <React.Fragment>
                                    {/* Custom Chip */}
                                    <Box
                                        sx={{
                                            background: 'rgb(37,71,106)',
                                            height: 'auto',
                                            pr: 1,
                                            pl: 1,
                                            borderRadius: '10px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Typography
                                            variant="caption"
                                            sx={{
                                                m: 0,
                                                p: 0,
                                                color: 'white',
                                            }}
                                        >
                                            {prop.item.replyCount ?? 0}
                                        </Typography>
                                    </Box>
                                </React.Fragment>


                            </Box>



                        </Box>
                    </Box>
                </Box>
            </Box>
        </Paper>
    )
}


//#endregion


//#region Tech Service Item Content

const MISCTechServiceItemContent = (prop: { item: IKeriTechService }) => {
    return (
        <Box
            sx={{
                p: 1,
                mt: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'start',
                flexWrap: 'wrap',
                maxHeight: '80px',
                overflow: 'hidden'
            }}
        >
            <MISCTechServiceItemContentObject title="신청 번호" value={prop.item.uniqueId} />
            {prop.item.purpose?.name && <MISCTechServiceItemContentObject title={prop.item.type?.code === 'AR' ? '해석 목적' : '시험 목적'} value={prop.item.purpose?.name} />}
            <MISCTechServiceItemContentObject title="제품" value={prop.item.product?.name} />
            <MISCTechServiceItemContentObject title="신청자" value={`${prop.item.requester?.name}(${prop.item.requester?.department})`} />
            {
                prop.item.type?.code === 'PT' &&
                <React.Fragment>
                    <MISCTechServiceItemContentObject title="시험 방법" value={prop.item.test?.method} />
                    <MISCTechServiceItemContentObject title="시험 유형" value={prop.item.test?.type} />
                    <MISCTechServiceItemContentObject title="시험 항목" value={prop.item.test?.item} />
                </React.Fragment>
            }
        </Box>
    )
}

//#endregion


//#region Tech Service Item Content Attribute Object


const MISCTechServiceItemContentObject = (prop: { title: string, value: string | number | undefined, chipMode?: boolean }) => {
    return (
        <React.Fragment>
            {
                !IsUndefined(prop.title, prop.value) &&
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'start',
                        height: '40px',
                        mr: 5,
                        whiteSpace: 'nowrap'
                    }}
                >
                    <Typography
                        variant="subtitle2"
                        sx={{
                            lineHeight: '40px',
                            fontWeight: '700'
                        }}
                    >
                        {prop.title}
                    </Typography>

                    {
                        prop.chipMode
                            ?
                            <Chip
                                label={prop.value}
                                sx={{
                                    m: 0,
                                    p: 0,
                                    pt: 0.5,
                                    pb: 0.5,
                                    ml: 1,
                                    height: 'auto'
                                }}
                            />
                            :
                            <Typography
                                variant="body2"
                                sx={{
                                    ml: 1,
                                    lineHeight: '40px',
                                    color: 'gray'
                                }}
                            >
                                {prop.value}
                            </Typography>
                    }

                </Box>
            }

        </React.Fragment>
    )
}

//#endregion















export default RequestTechServiceListPage;