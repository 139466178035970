import { Backdrop, Box, Button, CircularProgress, Divider, Paper, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

import DefaultRoute from '@keri-portal/module/router/DefaultRoute';
import { useDispatch, useSelector } from 'react-redux';
import { ILoginSessionData, removeUser, setUser } from './modules/redux/reducer/UserDataReducer';
import SessionStorageServ from '@ict-base/module/utils/SessionStorageServ';
import { IPageProp } from '@keri-base/interface/common/IPageProp';
import RequestKeriAPI from '@keri-portal/module/method/api-connect/RequestKeriAPI';
import { setMenuList } from './modules/redux/reducer/MenuDataReducer';
import useLoginCheck from '@keri-portal/module/hook/useLoginCheck';
import { useLocation } from 'react-router-dom';
import KeriLogo from '@keri-portal/component/common/KeriLogo';
import MainEventPopup from '@keri-portal/component/control/main/MainEventPopup';


//declare object extension
import '@ict-base/module/extensions/string.extension'
import '@ict-base/module/extensions/date.extension'
import { MISCSystemBackground } from '@keri-portal/component/system/MISCSystemBackground';
import { setMenuCategory } from './modules/redux/reducer/MenuCategoryReducer';
import { setAlert } from './modules/redux/reducer/UserAlertReducer';
import { IKeriAlert, IKeriMenuCategoryItem } from '@ictpeople/misc-core';


function App() {

  const [isBackDrop, setIsBackDrop] = useState<boolean>(false)

  const propData: IPageProp = {
    EventFunction: {
      EnableBackDrop: setIsBackDrop
    }
  }

  const location = useLocation();
  const userRedux: ILoginSessionData = useSelector((state: any) => state.user);
  const dispatch = useDispatch();
  const userReduxHook = useLoginCheck();


  const checkToken = async () => {
    setIsBackDrop(true);
    if (userReduxHook?.token !== undefined) {
      const getRequest = await RequestKeriAPI.Common.CheckTokenValidation(userReduxHook?.token);
      if (!getRequest.state) {
        if (!getRequest.tokenValid) {
          alert(getRequest.message);
          dispatch(removeUser());
        }
      }
      else {


        if ((userRedux.expireData ?? new Date(Date.now())) < (new Date(new Date(Date.now()).getTime() + 50 * 60000))) {
          dispatch(setUser({
            resultVO: userRedux.resultVO,
            token: getRequest.refreshAccessToken,
            expireData: getRequest.expireDate,
            loadComplete: true
          }))
        }
      }

    }

    setIsBackDrop(false);
  }


  useEffect(() => {
    // Page Refresh / Tab 이동 등 redux data 소실 시 session storage에서 다시 읽어옴.
    // 데이터가 없거나 Expire Date가 지났을 경우 읽어오지 않음.

    if (userRedux.token === undefined) {
      var localStorageData = SessionStorageServ.getData<ILoginSessionData>(process.env.REACT_APP_USER_LOCAL_STORAGE_KEY);
      if (localStorageData !== undefined) {
        localStorageData.expireData = localStorageData.expireData !== undefined ? new Date(Date.parse(localStorageData.expireData!.toLocaleString('ko-KR', { timeZone: 'Asia/Seoul' }))) : undefined;
      }
      if (localStorageData !== undefined && localStorageData?.expireData !== undefined && (localStorageData?.expireData ?? new Date(localStorageData?.expireData)) > new Date(Date.now())) {
        dispatch(setUser(localStorageData));
      }
      else {
        dispatch(setUser({
          token: undefined,
          resultVO: undefined,
          loadComplete: true,
          expireData: undefined
        }))
      }
    }





    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (userReduxHook?.loadComplete ?? false) {
      checkToken();
    }

    // eslint-disable-next-line
  }, [location, userReduxHook])




  //#region test

  const [snackOpen, setSnackOpen] = useState<boolean>(true);

  //#endregion




  const alertData = useSelector((state : any) => state.alert) as IKeriAlert | undefined;
  const menuData = useSelector((state: any) => state.menuCategory) as IKeriMenuCategoryItem[];



  return (
    <React.Fragment>
      {
        // process.env.NODE_ENV === 'development' &&
        <MISCSystemBackground
          userSessionData={userRedux}
          reduxUpdate={{
            user: [userRedux, (item) => dispatch(setUser(item))],
            alert: [alertData, (item) => dispatch(setAlert(item))],
            menu: [menuData, (item) => dispatch(setMenuCategory(item))],
          }}
        />
      }


      <DefaultRoute {...propData} />


      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isBackDrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <MainEventPopup
        open={snackOpen}
        onClose={() => setSnackOpen(false)}
      />

    </React.Fragment>
  );
}

export default App;
