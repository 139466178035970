import { KeriEditGrid, KeriEditGridInputField } from "@ict-base/components/control/KeriEditGrid";
import ICTRequestAPI from "@ict-base/module/service/ICTRequestAPI";
import { ObjectUtils } from "@ict-base/module/utils/ObjectUtils";
import RegexUtil from "@ict-base/module/utils/RegexUtil";
import { ICompanyInfo } from "@keri-portal/interfaces/data-object/data-common/ICompanyInfo";
import { ICompanyInfoError } from "@keri-portal/interfaces/process-object/account/register/ICompanyInfoError";
import React, { useState } from "react";
import KeriSelectAddressModal from "../modal/KeriSelectAddressModal";
import KeriSelectTechCodeModal from "../modal/KeriSelectTechCodeModal";

interface ICompanyInputField {
    editMode: 'Edit' | 'View'
    dataObject: {
        data: ICompanyInfo,
        onChange: (item: ICompanyInfo) => void
    },
    error: {
        data: ICompanyInfoError,
        onChange: (item: ICompanyInfoError) => void
    },
    displayObject?: {
        /** 사업자 등록번호 */
        CompanyRegistrationNumber?: boolean,
        /** 법인등록번호 */
        CorporateRegistrationNumber?: boolean,
        /** 상호명 */
        BusinessName?: boolean,
        /** 상호명(약어) */
        AbbreviationBusinessName?: boolean,
        /** 대표자명 */
        CEOName?: boolean,
        /** 대표자 이메일 */
        CEOEmail?: boolean,
        /** 산업기술코드 */
        TechCode?: boolean,
        /** 기업 형태 */
        // CorporateType?: boolean,
        /** 업태 */
        BusinessStatus?: boolean,
        /** 종목 */
        BusinessItem?: boolean,
        /** 전화번호 */
        CompanyCallNumber?: boolean,
        /** 팩스번호 */
        CompanyFaxNumber?: boolean,
        /** 주소 */
        CompanyAddress?: boolean,
        /** 상세주소 */
        CompanyDetailAddress?: boolean,
        /** 자본금액 */
        CompanyCapital?: boolean,
        /** 종업원 수 */
        CompanyWorkerCount?: boolean,
    }
}

const DISABLE_BUSINESS_NUMBER_VALIDATION_CHECK: boolean = false;


const CompanyInputField = (prop: ICompanyInputField) => {

    const [openCodeModal, setOpenCodeModal] = useState<boolean>(false);
    const [openAddressModal, setOpenAddressModal] = useState<boolean>(false);


    return (
        <React.Fragment>
            <KeriEditGrid>
                {
                    (prop.displayObject?.CompanyRegistrationNumber ?? true) &&
                    <KeriEditGridInputField
                        title="사업자등록번호"
                        props={{
                            width: '50%'
                        }}
                        required={prop.editMode === 'Edit'}
                        defaultValue={prop.dataObject.data.CompanyRegistrationNumber}
                        value={prop.editMode === 'View' ? prop.dataObject.data.CompanyRegistrationNumber : undefined}
                        editDisabled={prop.editMode === 'View'}
                        tooltip={{
                            // enable: prop.editMode === 'Edit',
                            enable : false,
                            message: 't'
                        }}
                        onChange={(changeText, e) => {
                            e.target.value = RegexUtil.ConvertRegexString.CompanyRegistrationNumber(e.target.value)!;
                            prop.dataObject.onChange({
                                ...prop.dataObject.data,
                                CompanyRegistrationNumber: ObjectUtils.IsNullOrEmpty(changeText) ? undefined : changeText.replace('-', ''),
                                ...(DISABLE_BUSINESS_NUMBER_VALIDATION_CHECK ? { CompanyNumberValidCheck: true } : {})
                            })

                            if (prop.error.data.CompanyRegistrationNumber !== undefined) {
                                prop.error.onChange({
                                    ...prop.error.data,
                                    CompanyRegistrationNumber: undefined,
                                    ...(DISABLE_BUSINESS_NUMBER_VALIDATION_CHECK ? { CompanyNumberValidCheck: true } : {})
                                });
                            }

                            if (prop.dataObject.data.CompanyNumberValidCheck) {
                                prop.dataObject.onChange({
                                    ...prop.dataObject.data,
                                    CompanyNumberValidCheck: false
                                })
                            }
                        }}
                        error={{
                            isError: prop.error.data.CompanyRegistrationNumber !== undefined,
                            message: prop.error.data.CompanyRegistrationNumber
                        }}
                        button={DISABLE_BUSINESS_NUMBER_VALIDATION_CHECK ? { isEnable: false } : {
                            isEnable: prop.editMode === 'Edit',
                            onClick: async () => {
                                const requestData = await ICTRequestAPI.common.GetCompanyNumber(prop.dataObject.data.CompanyRegistrationNumber ?? '')
                                if (requestData.state) {

                                    prop.dataObject.onChange({
                                        ...prop.dataObject.data,
                                        CompanyNumberValidCheck: true
                                    })
                                    alert('조회 성공')
                                }
                                else {
                                    alert(requestData.message)
                                }
                            },
                            label: '조회'
                        }}
                    />
                }
                {
                    (prop.displayObject?.CorporateRegistrationNumber ?? true) &&
                    <KeriEditGridInputField
                        title="법인등록번호"
                        props={{
                            width: '50%'
                        }}
                        // required={prop.editMode === 'Edit'}
                        value={prop.editMode === 'View' ? prop.dataObject.data.CorporateRegistrationNumber : undefined}
                        editDisabled={prop.editMode === 'View'}
                        tooltip={{
                            // enable: prop.editMode === 'Edit',
                            enable : false,
                            message: 't'
                        }}
                        defaultValue={prop.dataObject.data.CorporateRegistrationNumber}
                        onChange={(changeText, e) => {
                            e.target.value = RegexUtil.ConvertRegexString.CorporateRegistrationNumber(e.target.value)!;

                            prop.dataObject.onChange({
                                ...prop.dataObject.data,
                                CorporateRegistrationNumber: ObjectUtils.IsNullOrEmpty(changeText) ? undefined : changeText.replace('-', '')
                            })

                            if (prop.error.data.CorporateRegistrationNumber !== undefined) {
                                prop.error.onChange({
                                    ...prop.error.data,
                                    CorporateRegistrationNumber: undefined
                                });
                            }


                        }}
                        error={{
                            isError: prop.error.data.CorporateRegistrationNumber !== undefined,
                            message: prop.error.data.CorporateRegistrationNumber
                        }}
                    />
                }

                {
                    (prop.displayObject?.BusinessName ?? true) &&
                    <KeriEditGridInputField
                        title="상호명(한글)"
                        props={{
                            width: '50%'
                        }}
                        required={prop.editMode === 'Edit'}
                        value={prop.editMode === 'View' ? prop.dataObject.data.BusinessName : undefined}
                        editDisabled={prop.editMode === 'View'}
                        tooltip={{
                            // enable: prop.editMode === 'Edit',
                            enable : false,
                            message: 't'
                        }}
                        defaultValue={prop.dataObject.data.BusinessName}
                        onChange={(changeText, e) => {
                            prop.dataObject.onChange({
                                ...prop.dataObject.data,
                                BusinessName: ObjectUtils.IsNullOrEmpty(changeText) ? undefined : changeText
                            })

                            if (prop.error.data.BusinessName !== undefined) {
                                prop.error.onChange({
                                    ...prop.error.data,
                                    BusinessName: undefined
                                });
                            }
                        }}
                        error={{
                            isError: prop.error.data.BusinessName !== undefined,
                            message: prop.error.data.BusinessName
                        }}
                    />
                }

                {
                    (prop.displayObject?.AbbreviationBusinessName ?? true) &&
                    <KeriEditGridInputField
                        title="영문 약어"
                        props={{
                            width: '50%'
                        }}
                        value={prop.editMode === 'View' ? prop.dataObject.data.AbbreviationBusinessName : undefined}
                        editDisabled={prop.editMode === 'View'}
                        required={prop.editMode === 'Edit'}
                        tooltip={{
                            // enable: prop.editMode === 'Edit',
                            enable : false,
                            // message: 't'
                            message : undefined
                        }}
                        defaultValue={prop.dataObject.data.AbbreviationBusinessName}
                        onChange={(changeText, e) => {
                            prop.dataObject.onChange({
                                ...prop.dataObject.data,
                                AbbreviationBusinessName: ObjectUtils.IsNullOrEmpty(changeText) ? undefined : changeText
                            })

                            if (prop.error.data.AbbreviationBusinessName !== undefined) {
                                prop.error.onChange({
                                    ...prop.error.data,
                                    AbbreviationBusinessName: undefined
                                });
                            }
                        }}
                        error={{
                            isError: prop.error.data.AbbreviationBusinessName !== undefined,
                            message: prop.error.data.AbbreviationBusinessName
                        }}
                    />
                }

                {
                    (prop.displayObject?.CEOName ?? true) &&
                    <KeriEditGridInputField
                        title="대표자명"
                        props={{
                            width: '50%'
                        }}
                        value={prop.editMode === 'View' ? prop.dataObject.data.CEOName : undefined}
                        editDisabled={prop.editMode === 'View'}
                        required={prop.editMode === 'Edit'}
                        tooltip={{
                            // enable: prop.editMode === 'Edit',
                            enable : false,
                            // message: 't'
                            message : undefined
                        }}
                        defaultValue={prop.dataObject.data.CEOName}
                        onChange={(changeText, e) => {
                            prop.dataObject.onChange({
                                ...prop.dataObject.data,
                                CEOName: ObjectUtils.IsNullOrEmpty(changeText) ? undefined : changeText
                            })

                            if (prop.error.data.CEOName !== undefined) {
                                prop.error.onChange({
                                    ...prop.error.data,
                                    CEOName: undefined
                                });
                            }
                        }}
                        error={{
                            isError: prop.error.data.CEOName !== undefined,
                            message: prop.error.data.CEOName
                        }}
                    />
                }

                {
                    (prop.displayObject?.CEOEmail ?? true) &&
                    <KeriEditGridInputField
                        title="대표자 이메일"
                        props={{
                            width: '50%'
                        }}
                        value={prop.editMode === 'View' ? prop.dataObject.data.CEOEmail : undefined}
                        editDisabled={prop.editMode === 'View'}
                        tooltip={{
                            // enable: prop.editMode === 'Edit',
                            enable : false,
                            // message: 't'
                            message : undefined
                        }}
                        defaultValue={prop.dataObject.data.CEOEmail}
                        onChange={(changeText, e) => {
                            prop.dataObject.onChange({
                                ...prop.dataObject.data,
                                CEOEmail: ObjectUtils.IsNullOrEmpty(changeText) ? undefined : changeText
                            })

                            if (prop.error.data.CEOEmail !== undefined) {
                                prop.error.onChange({
                                    ...prop.error.data,
                                    CEOEmail: undefined
                                });
                            }
                        }}
                        error={{
                            isError: prop.error.data.CEOEmail !== undefined,
                            message: prop.error.data.CEOEmail
                        }}
                    />
                }

                {
                    (prop.displayObject?.TechCode ?? true) &&
                    <KeriEditGridInputField
                        title="산업기술코드"
                        value={prop.dataObject.data.TechCode?.TechCode}
                        editDisabled
                        defaultValue={prop.dataObject.data.TechCode?.TechCode}
                        props={{
                            width: '50%'
                        }}
                        required={prop.editMode === 'Edit'}
                        tooltip={{
                            // enable: prop.editMode === 'Edit',
                            enable : false,
                            // message: 't'
                            message : undefined
                        }}
                        button={{
                            isEnable: prop.editMode === 'Edit',
                            label: '선택',
                            onClick: () => setOpenCodeModal(true)
                        }}
                        error={{
                            isError: prop.error.data.TechCode !== undefined,
                            message: prop.error.data.TechCode,
                        }}
                    />
                }


                {/* {
                    (prop.displayObject?.CorporateType ?? true) &&
                    <KeriEditGridInputField
                        title="기업형태"
                        props={{
                            width: '50%'
                        }}
                        value={prop.editMode === 'View' ? prop.dataObject.data.CorporateType : undefined}
                        editDisabled={prop.editMode === 'View'}
                        tooltip={{
                            enable: prop.editMode === 'Edit',
                            message: 't'
                        }}
                        defaultValue={prop.dataObject.data.CorporateType}
                        onChange={(changeText, e) => {
                            prop.dataObject.onChange({
                                ...prop.dataObject.data,
                                CorporateType: ObjectUtils.IsNullOrEmpty(changeText) ? undefined : changeText
                            })

                            if (prop.error.data.CorporateType !== undefined) {
                                prop.error.onChange({
                                    ...prop.error.data,
                                    CorporateType: undefined
                                });
                            }
                        }}
                        error={{
                            isError: prop.error.data.CorporateType !== undefined,
                            message: prop.error.data.CorporateType
                        }}
                    />
                } */}

                {
                    (prop.displayObject?.BusinessStatus ?? true) &&
                    <KeriEditGridInputField
                        title="업태"
                        props={{
                            width: '50%'
                        }}
                        value={prop.editMode === 'View' ? prop.dataObject.data.BusinessStatus : undefined}
                        editDisabled={prop.editMode === 'View'}
                        // required={prop.editMode === 'Edit'}
                        tooltip={{
                            // enable: prop.editMode === 'Edit',
                            enable : false,
                            // message: 't'
                            message : undefined
                        }}
                        defaultValue={prop.dataObject.data.BusinessStatus}
                        onChange={(changeText, e) => {
                            prop.dataObject.onChange({
                                ...prop.dataObject.data,
                                BusinessStatus: ObjectUtils.IsNullOrEmpty(changeText) ? undefined : changeText
                            })

                            if (prop.error.data.BusinessStatus !== undefined) {
                                prop.error.onChange({
                                    ...prop.error.data,
                                    BusinessStatus: undefined
                                });
                            }
                        }}
                        error={{
                            isError: prop.error.data.BusinessStatus !== undefined,
                            message: prop.error.data.BusinessStatus
                        }}
                    />
                }


                {
                    (prop.displayObject?.BusinessItem ?? true) &&
                    <KeriEditGridInputField
                        title="종목"
                        props={{
                            width: '50%'
                        }}
                        value={prop.editMode === 'View' ? prop.dataObject.data.BusinessItem : undefined}
                        // editDisabled={prop.editMode === 'View'}
                        required={prop.editMode === 'Edit'}
                        tooltip={{
                            // enable: prop.editMode === 'Edit',
                            enable : false,
                            // message: 't'
                            message : undefined
                        }}
                        defaultValue={prop.dataObject.data.BusinessItem}
                        onChange={(changeText, e) => {
                            prop.dataObject.onChange({
                                ...prop.dataObject.data,
                                BusinessItem: ObjectUtils.IsNullOrEmpty(changeText) ? undefined : changeText
                            })

                            if (prop.error.data.BusinessItem !== undefined) {
                                prop.error.onChange({
                                    ...prop.error.data,
                                    BusinessItem: undefined
                                });
                            }
                        }}
                        error={{
                            isError: prop.error.data.BusinessItem !== undefined,
                            message: prop.error.data.BusinessItem
                        }}
                    />
                }

                {
                    (prop.displayObject?.CompanyCallNumber ?? true) &&
                    <KeriEditGridInputField
                        title="전화번호"
                        props={{
                            width: '50%'
                        }}
                        value={prop.editMode === 'View' ? prop.dataObject.data.CompanyCallNumber : undefined}
                        editDisabled={prop.editMode === 'View'}
                        tooltip={{
                            // enable: prop.editMode === 'Edit',
                            enable : false,
                            // message: 't'
                            message : undefined
                        }}
                        defaultValue={prop.dataObject.data.CompanyCallNumber}
                        onChange={(changeText, e) => {
                            e.target.value = RegexUtil.ConvertRegexString.PhoneNumber(e.target.value)!

                            prop.dataObject.onChange({
                                ...prop.dataObject.data,
                                CompanyCallNumber: ObjectUtils.IsNullOrEmpty(changeText) ? undefined : RegexUtil.ConvertRegexString.PhoneNumber(changeText)
                            })

                            if (prop.error.data.CompanyCallNumber !== undefined) {
                                prop.error.onChange({
                                    ...prop.error.data,
                                    CompanyCallNumber: undefined
                                });
                            }
                        }}
                        error={{
                            isError: prop.error.data.CompanyCallNumber !== undefined,
                            message: prop.error.data.CompanyCallNumber
                        }}
                    />
                }

                {
                    (prop.displayObject?.CompanyFaxNumber ?? true) &&
                    <KeriEditGridInputField
                        title="팩스번호"
                        props={{
                            width: '50%'
                        }}
                        value={prop.editMode === 'View' ? prop.dataObject.data.CompanyFaxNumber : undefined}
                        editDisabled={prop.editMode === 'View'}
                        tooltip={{
                            // enable: prop.editMode === 'Edit',
                            enable : false,
                            // message: 't'
                            message : undefined
                        }}
                        defaultValue={prop.dataObject.data.CompanyFaxNumber}
                        onChange={(changeText, e) => {
                            e.target.value = RegexUtil.ConvertRegexString.PhoneNumber(e.target.value)!;
                            prop.dataObject.onChange({
                                ...prop.dataObject.data,
                                CompanyFaxNumber: ObjectUtils.IsNullOrEmpty(changeText) ? undefined : RegexUtil.ConvertRegexString.PhoneNumber(changeText)
                            })

                            if (prop.error.data.CompanyFaxNumber !== undefined) {
                                prop.error.onChange({
                                    ...prop.error.data,
                                    CompanyFaxNumber: undefined
                                });
                            }
                        }}
                        error={{
                            isError: prop.error.data.CompanyFaxNumber !== undefined,
                            message: prop.error.data.CompanyFaxNumber
                        }}
                    />
                }
                {
                    (prop.displayObject?.CompanyAddress ?? true) &&
                    <KeriEditGridInputField
                        title="주소"
                        value={prop.dataObject.data.CompanyAddress?.roadAddr}
                        editDisabled
                        required={prop.editMode === 'Edit'}
                        tooltip={{
                            // enable: prop.editMode === 'Edit',
                            enable : false,
                            // message: 't'
                            message : undefined
                        }}
                        defaultValue={prop.dataObject.data.CompanyAddress?.roadAddr}
                        button={{
                            isEnable: prop.editMode === 'Edit',
                            label: '주소 검색',
                            onClick: () => setOpenAddressModal(true)
                        }}
                        error={{
                            isError: prop.error.data.CompanyAddress !== undefined,
                            message: prop.error.data.CompanyAddress
                        }}
                    />
                }

                {
                    (prop.displayObject?.CompanyDetailAddress ?? true) &&
                    <KeriEditGridInputField
                        value={prop.editMode === 'View' ? prop.dataObject.data.CompanyDetailAddress : undefined}
                        editDisabled={prop.editMode === 'View'}
                        onChange={(changeText) => {

                            prop.dataObject.onChange({
                                ...prop.dataObject.data,
                                CompanyDetailAddress: ObjectUtils.IsNullOrEmpty(changeText) ? undefined : changeText
                            })

                            if (prop.error.data.CompanyDetailAddress !== undefined) {
                                prop.error.onChange({
                                    ...prop.error.data,
                                    CompanyDetailAddress: undefined
                                });
                            }

                        }}
                        defaultValue={prop.dataObject.data.CompanyDetailAddress}
                        error={{
                            isError: prop.error.data.CompanyDetailAddress !== undefined,
                            message: prop.error.data.CompanyDetailAddress
                        }}
                    />
                }

                {
                    (prop.displayObject?.CompanyCapital ?? true) &&
                    <KeriEditGridInputField
                        title="매출액"
                        // type={'number'}
                        props={{
                            width: '50%',
                            endAdornment: '백만원'
                        }}
                        value={prop.editMode === 'View' ? prop.dataObject.data.CompanyCapital?.toString() : undefined}
                        editDisabled={prop.editMode === 'View'}
                        // required={prop.editMode === 'Edit'}
                        tooltip={{
                            // enable: prop.editMode === 'Edit',
                            enable : false,
                            // message: 't'
                            message : undefined
                        }}
                        defaultValue={prop.dataObject.data.CompanyCapital?.toString()}
                        onChange={(changeText, e) => {
                            e.target.value = e.target.value.replace(/[^0-9]/g, '');
                            prop.dataObject.onChange({
                                ...prop.dataObject.data,
                                CompanyCapital: isNaN(parseInt(changeText.replace(/[^0-9]/g, ''), 10)) ? undefined : parseInt(changeText.replace(/[^0-9]/g, ''), 10)
                            })

                            if (prop.error.data.CompanyCapital !== undefined) {
                                prop.error.onChange({
                                    ...prop.error.data,
                                    CompanyCapital: undefined
                                });
                            }


                        }}
                        error={{
                            isError: prop.error.data.CompanyCapital !== undefined,
                            message: prop.error.data.CompanyCapital
                        }}
                    />
                }


                {
                    (prop.displayObject?.CompanyWorkerCount ?? true) &&
                    <KeriEditGridInputField
                        title="종업원 수"
                        // type={'number'}
                        props={{
                            width: '50%',
                            endAdornment: '명',
                            inputPattern: '[0-9]'
                        }}
                        value={prop.editMode === 'View' ? prop.dataObject.data.CompanyWorkerCount?.toString() : undefined}
                        editDisabled={prop.editMode === 'View'}
                        // required={prop.editMode === 'Edit'}
                        tooltip={{
                            // enable: prop.editMode === 'Edit',
                            enable : false,
                            // message: 't'
                            message : undefined
                        }}
                        defaultValue={prop.dataObject.data.CompanyWorkerCount?.toString()}
                        onChange={(changeText, e) => {
                            e.target.value = e.target.value.replace(/[^0-9]/g, '');
                            prop.dataObject.onChange({
                                ...prop.dataObject.data,
                                CompanyWorkerCount: isNaN(parseInt(changeText.replace(/[^0-9]/g, ''), 10)) ? undefined : parseInt(changeText.replace(/[^0-9]/g, ''), 10)
                            })

                            if (prop.error.data.CompanyWorkerCount !== undefined) {
                                prop.error.onChange({
                                    ...prop.error.data,
                                    CompanyWorkerCount: undefined
                                });
                            }

                        }}
                        error={{
                            isError: prop.error.data.CompanyWorkerCount !== undefined,
                            message: prop.error.data.CompanyWorkerCount
                        }}
                    />
                }

            </KeriEditGrid>

            {/* Modal */}
            <KeriSelectTechCodeModal
                open={openCodeModal}
                onClose={() => setOpenCodeModal(false)}
                onSubmit={(item) => {
                    prop.dataObject.onChange({
                        ...prop.dataObject.data,
                        TechCode: item
                    })

                    if (prop.error.data.TechCode !== undefined) {
                        prop.error.onChange({
                            ...prop.error.data,
                            TechCode: undefined
                        });
                    }

                    setOpenCodeModal(false);
                }}
                defaultValue={prop.dataObject.data.TechCode}
            />
            <KeriSelectAddressModal
                open={openAddressModal}
                onClose={() => setOpenAddressModal(false)}
                onSubmit={(item) => {

                    prop.dataObject.onChange({
                        ...prop.dataObject.data,
                        CompanyAddress: item
                    })

                    if (prop.error.data.CompanyAddress !== undefined) {
                        prop.error.onChange({
                            ...prop.error.data,
                            CompanyAddress: undefined
                        });
                    }

                    setOpenAddressModal(false);
                }}
            />

        </React.Fragment>
    )
}

export default CompanyInputField;