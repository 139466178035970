import { Box } from "@mui/material";
import { Editor } from '@tinymce/tinymce-react';
import { useRef, useState } from "react";
import KeriActionButton from "./KeriActionButton";



interface IKeriEditor {
    onChange: (newValue: string) => void,
    onLoad : (e : any) => void,
    defaultValue?: string,
    prop?: {
        height?: number
    },
}

const KeriEditor = (prop: IKeriEditor) => {

    const [editorContent, setEditorContent] = useState<string>('');

    const editorRef = useRef<any>(null);

    return (
        <Box
            className='Keri-Editor'
        >
            <input
                id="my-file"
                type="file"
                name="my-file"
                style={{ display: "none" }}
            />
            <Editor
                apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
                onInit={(evt, editor) => editorRef.current = editor}
                initialValue={prop.defaultValue}
                onLoadContent={(e) => prop.onLoad(e)}
                onEditorChange={(newValue, editor) => {
                    prop.onChange(newValue);
                }}
                init={{
                    language: 'ko_KR',
                    language_url: `${process.env.PUBLIC_URL}/assets/langs/ko_KR.js`,
                    height: prop.prop?.height ?? 300,
                    menubar: true,
                    content_css: 'p {margin: 0}',
                    plugins: [
                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                        'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount',
                        'image'
                    ],
                    toolbar: 'undo redo | blocks | ' +
                        'bold italic forecolor | image | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'removeformat | help',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                    editimage_cors_hosts: ['localhost', process.env.PUBLIC_URL, process.env.PUBLIC_URL],
                    editimage_credentials_hosts: ['localhost', process.env.PUBLIC_URL, process.env.PUBLIC_URL],
                    image_uploadtab: true,
                    images_upload_url: `${process.env.REACT_APP_API_URL}/kri/cmm/uploadImage.do/`,
                    // images_reuse_filename : true,
                    automatic_uploads : true,
                    // images_upload_handler : (blobInfo, progress)  => new Promise((resolve, reject) => {

                    // }),
                    file_picker_callback: (callback, value, meta) => {
                        if (meta.filetype === "image") {
                            let input = document.getElementById(
                                "my-file"
                            ) as HTMLInputElement;
                            if (!input) return;
                            input.click();
                            input.onchange = function () {
                                let file = (input as any)?.files[0];
                                let reader = new FileReader();
                                reader.onload = function (e: ProgressEvent<FileReader>) {
                                    callback((e.target as FileReader).result as string, {
                                        alt: file.name,
                                    });
                                };
                                reader.readAsDataURL(file);
                            };
                        }
                    }
                }}
            />
        </Box>
    )
}

export default KeriEditor;