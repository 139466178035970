import { ObjectUtils } from "@ict-base/module/utils/ObjectUtils";
import { IKeriAlert } from "@ictpeople/misc-core";
import { IPageProp } from "@keri-base/interface/common/IPageProp";
import { Box, createTheme, Divider, Paper, ThemeProvider, Typography, useMediaQuery } from "@mui/material";
import { ILoginSessionData } from "@src/modules/redux/reducer/UserDataReducer";
import { useMemo, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import KeriFooter from "../common/KeriFooter";
import MISCHeader from "../common/MISCHeader";

export const defaultTheme = createTheme({
    typography: {
        fontFamily: [
            '\'Noto Sans KR\'',
            // 'AppleGothic',
            // 'Dotum',
            // 'sans-serif'
        ].join(',')
    },
    components: {
        MuiButton: {
            styleOverrides: {
                text: {
                    fontFamily: [
                        '\'Noto Sans KR\'',
                        // 'AppleGothic',
                        // 'Dotum',
                        // 'sans-serif'
                    ].join(',')
                }
            }
        },
        MuiStepLabel: {
            styleOverrides: {
                label: {
                    fontWeight: '600',
                    '&.Mui-active': {
                        fontWeight: '600',
                    },
                    '&.Mui-completed': {
                        fontWeight: '600'
                    }
                },
            }
        },
        MuiTextField: {
            styleOverrides: {
                root: {

                }
            }
        },
        MuiTooltip : {
            styleOverrides : {
                tooltip : {
                    fontFamily : 'Roboto'
                }
            }
        }
    },

})


const HEADER_HEIGHT = 60;


/**
 * 기본 Page Layout
 * (단독 사용 불가)
 * 
 * @param prop Page Prop
 * @returns 
 */
const DefaultLayoutPage = (prop: IPageProp) => {



    const isTablet = useMediaQuery('(max-width:1024px)');
    const isMobile = useMediaQuery('(max-width:425px)')

    const userRedux: ILoginSessionData = useSelector((state: any) => state.user);
    const alertRedux: IKeriAlert = useSelector((state: any) => state.userAlert);

    const [siteLink, setSiteLink] = useState<{ img: string, url: string }[]>([]);
    const [siteNavigateComponent, setSiteNavigateComponent] = useState<React.ReactNode>(undefined);

    useMemo(() => {
        setSiteNavigateComponent(
            siteLink.map(item => (
                <Box
                    key={ObjectUtils.RandomStrings(10)}
                    sx={{
                        ml: 4,
                        mr: 4
                    }}
                >
                    <img
                        alt={`site-logo`}
                        src={item.img}
                        style={{
                            height: '34px',
                            cursor: 'pointer'
                        }}
                        onClick={() => {
                            window.location.href = item.url
                        }}
                    />
                </Box>
            ))
        )
    }, [siteLink])



    useEffect(() => {
        if (siteLink.length === 0) {
            console.log('RERENDER');
            setSiteLink(
                [
                    {
                        img: `${process.env.PUBLIC_URL}/assets/img/site-logo/fs_ki.jpg`,
                        url: 'http://www.motie.go.kr/www/main.do'
                    },
                    {
                        img: `${process.env.PUBLIC_URL}/assets/img/site-logo/fs_kicc.jpg`,
                        url: 'https://www.kicox.or.kr/index.do'
                    },
                    {
                        img: `${process.env.PUBLIC_URL}/assets/img/site-logo/fs_gn.png`,
                        url: 'https://www.gyeongnam.go.kr/index_home.jsp'
                    },
                    {
                        img: `${process.env.PUBLIC_URL}/assets/img/site-logo/fs_cw.png`,
                        url: 'https://www.changwon.go.kr/cwportal/portal.web'
                    },
                ]
            )
        }
    }, [])

    return (
        <ThemeProvider theme={defaultTheme}>

            <Box
                sx={{
                    width: '100%',
                    height: '100%'
                }}
            >

                <MISCHeader
                    viewMode={isMobile ? 'MOBILE' : isTablet ? 'TABLET' : 'PC'}
                    loginData={{
                        expireData: userRedux.expireData,
                        token: userRedux.token,
                        userData: (userRedux.resultVO ?? undefined) === undefined ? undefined : {
                            id: userRedux.resultVO?.id ?? undefined,
                            name: userRedux.resultVO?.name ?? undefined,
                        }
                    }}
                    alertData={alertRedux}
                />


                <Box
                    sx={{
                        width: "100%",
                        // height: '100%',
                        height: 'auto',
                        minHeight: '100%',
                        pb: '300px',
                        pt: `${HEADER_HEIGHT}px`,
                        background: "#f5f7fab0"
                    }}
                >
                    <Outlet />
                </Box>
                <Box
                    sx={{
                        transform: 'translateY(-100%)'
                    }}
                >
                    <Box
                        sx={{
                            width: '100%',
                            height: '60px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            background: 'white',
                            borderTop: '1px solid rgba(0,0,0,0.05)'
                        }}
                    >
                        {siteNavigateComponent}

                    </Box>
                    <KeriFooter />
                </Box>


            </Box>
            {/* Floating Box */}
            <Paper
                elevation={3}
                sx={{
                    position: 'fixed',
                    // width: '80px',
                    // height : '200px',
                    bottom: '10%',
                    right: '20px',
                    ":hover": {
                        '.ConnectSiteName': {
                            display: 'block'
                        }
                    }
                }}
            >
                <ConnectSiteItem
                    title="산업통상자원부"
                    img="/misc/assets/img/site-logo/icon_ki.png"
                    link="http://www.motie.go.kr/www/main.do"
                />
                <Divider sx={{ mr: 1, ml: 1 }} />
                <ConnectSiteItem
                    title="한국산업단지공단"
                    img="/misc/assets/img/site-logo/icon_kicc.png"
                    link="https://www.kicox.or.kr/index.do"
                />

                <Divider sx={{ mr: 1, ml: 1 }} />
                <ConnectSiteItem
                    title="경상남도"
                    img="/misc/assets/img/site-logo/icon_gn.png"
                    link="https://www.gyeongnam.go.kr/index.gyeong"
                />

                <Divider sx={{ mr: 1, ml: 1 }} />
                <ConnectSiteItem
                    title="창원특례시"
                    img="/misc/assets/img/site-logo/icon_cw.png"
                    link="https://www.changwon.go.kr/cwportal/portal.web"
                />
            </Paper>
        </ThemeProvider>

    )
}




const ConnectSiteItem = (prop: { title: string, img: string, link?: string }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                transition: '0.3s all cubic-bezier(0,0,0,1)',
                p: 1,
                // width : '220px'
                cursor: 'pointer',
                ":hover": {
                    backgroundColor: '#f5f7fab0',
                    ".ConnectSiteName": {
                        '& *': {
                            fontWeight: 'bold'
                        }
                    }
                }
            }}
        >
            <Box
                className="ConnectSiteName"
                sx={{
                    mr: 2,
                    ml: 1,
                    width: '150px',
                    display: 'none'
                }}
                onClick={() => {
                    if (prop.link !== undefined) {
                        window.location.href = prop.link;
                    }
                }}
            >
                <Typography
                >
                    {prop.title}
                </Typography>
            </Box>
            <Box
                sx={{
                    width: '50px',
                    height: '50px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <img
                    alt="site"
                    src={prop.img}
                    style={{
                        width: '40px',
                        height: '40px'
                    }}
                />
            </Box>
        </Box>
    )
}


export default DefaultLayoutPage;