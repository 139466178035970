import InfoLayoutPage from "@keri-portal/component/layout/InfoLayoutPage";
import { IPageProp } from "@keri-base/interface/common/IPageProp";
import { Add, ArrowForward, AssignmentLate, AssignmentTurnedIn, Cancel, CheckCircle, Download, Error, FileDownload, Refresh, Start, Upload, UploadFile, Warning, WarningRounded, } from "@mui/icons-material";
import { Box, Button, Chip, CircularProgress, Divider, IconButton, Rating, SxProps, TextField, Theme, Tooltip, Typography, } from "@mui/material";
import LoginChecker from "@keri-base/components/common/LoginChecker";
import ICTAccordion from "@ict-base/components/control/ICTAccordion";
import KeriActionButton from "@ict-base/components/control/KeriActionButton";
import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import { ITechServiceInfoData, ITechServiceSimulationData, TechServiceSimulationStatus, TechServiceType, } from "@keri-portal/interfaces/data-object/tech-service/ITechService";
import useLoginCheck from "@keri-portal/module/hook/useLoginCheck";
import RequestKeriAPI from "@keri-portal/module/method/api-connect/RequestKeriAPI";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { IUploadFile } from "@keri-portal/interfaces/control/IUploadFile";
import { IUploadFileVO } from "@keri-portal/interfaces/api/keri-api/object/IUploadFileVO";
import { ObjectUtils } from "@ict-base/module/utils/ObjectUtils";
import RegexUtil from "@ict-base/module/utils/RegexUtil";
import ICTBaseModal from "@ict-base/components/control/ICTBaseModal";
import { ILoginSessionData } from "@src/modules/redux/reducer/UserDataReducer";
import URLStructure from "@src/modules/structure/URLStructure";
import { ITechServiceComment } from "@keri-portal/interfaces/data-object/tech-service/ITechServiceComment";
import ICTReplyBox from "@ict-base/components/control/ICTReplyBox";
import KeriObjectUtil from "@keri-portal/module/utils/KeriObjectUtil";
import KeriSelectAnalysisPurposeModal from "@keri-portal/component/modal/purpose/KeriSelectAnalysisPurposeModal";
import KeriSelectProductItem from "@keri-portal/component/modal/product-item/KeriSelectProductItem";
import KeriSelectTestAttributeModel from "@keri-portal/component/modal/test-object/KeriSelectTestAttributeModel";
import { TestAttribute } from "@keri-portal/interfaces/data-object/data-common/ITestAttribute";
import { ILoadControl, InitLoadControl, LoadControlUnit, } from "@ict-base/components/common/LoadControl";
import EditControl from "@ict-base/components/common/EditControl";
import KeriConfig from "@keri-portal/config/KeriConfig";
import { PlatformError } from "@keri-base/interface/common/PlatformError";
import KeriTechServiceSurveyModal from "@keri-portal/component/modal/tech-service/KeriTechServiceSurveyModal";
import { KeriEditGrid, KeriEditGridInputField, } from "@ict-base/components/control/KeriEditGrid";
import { MISCFullBlurLoadingModal } from "@ictpeople/misc-react";
import { useSelector } from "react-redux";
import { IKeriTechServiceSimulation } from "@ictpeople/misc-core";
import { SimulationInfoItem, SimulationInfoList } from "@keri-portal/component/control/tech-service/SimulationInfo";
import { TechServiceProcessFilesComponent } from "@keri-portal/component/service-components/tech-service/info/TechServiceProcessFileComponent";
import { TechServiceProcessCompleteFilesComponent } from "@keri-portal/component/service-components/tech-service/info/TechServiceProcessCompleteFilesComponent";
import { MISCTechServiceSectionErrorBox } from "@keri-portal/component/control/tech-service/info/MISCTechServiceSectionErrorBox";
import { MISCTechSErviceSectionEmptyDataBox } from "@keri-portal/component/control/tech-service/info/MISCTechSErviceSectionEmptyDataBox";

const TechServiceItemViewPage = (prop: IPageProp) => {
  const [loginData, setLoginData] = useState<ILoginSessionData | undefined>(
    undefined
  );
  const navigate = useNavigate();
  const location = useLocation();
  const userRedux: ILoginSessionData = useSelector((state: any) => state.user);
  const param = useParams();
  const [checkTechService, setCheckTechService] = useState<{
    loadComplete: boolean;
    state?: boolean;
    techServiceID?: string;
    techServiceType?: TechServiceType;
    techServiceStatus?: string;
  }>({ loadComplete: false });

  const [loadingOpen, setLoadingOpen] = useState<{ state: boolean, message?: string }>({ state: false });

  //#region Component Ref

  const techServiceCompleteFilesComponentRef = useRef<IForwardFunc>(null);
  const techServiceUserComponentRef = useRef<IForwardFunc>(null);
  const techServiceInfoComponentRef = useRef<IForwardFunc>(null);
  const techServiceProcessFilesComponentRef = useRef<IForwardFunc>(null);
  const techServiceSimulationComponentRef = useRef<IForwardFunc>(null);
  const tehcServiceEffectComponentRef = useRef<IForwardFunc>(null);
  const techServiceReplyComponentRef = useRef<IForwardFunc>(null);
  const techServiceCompleteComponentRef = useRef<IForwardFunc>(null);

  //#endregion

  const refreshComponent = () => {
    techServiceCompleteFilesComponentRef.current?.refresh();
    techServiceUserComponentRef.current?.refresh();
    techServiceInfoComponentRef.current?.refresh();
    techServiceProcessFilesComponentRef.current?.refresh();
    techServiceSimulationComponentRef.current?.refresh();
    tehcServiceEffectComponentRef.current?.refresh();
    techServiceReplyComponentRef.current?.refresh();
    techServiceCompleteComponentRef.current?.refresh();
  };

  const checkTechServiceExist = async (
    techServiceID?: string,
    token?: string
  ) => {
    const getTechService = await RequestKeriAPI.TechService.GetTechServiceInfo(
      token ?? loginData?.token,
      techServiceID
    );

    if (getTechService.state) {
      setCheckTechService({
        loadComplete: true,
        state: true,
        techServiceID: techServiceID,
        techServiceType: getTechService.resultData.TechServiceData?.RequestType,
        techServiceStatus:
          getTechService.resultData.TechServiceData?.RequestStatus,
      });
    } else {
      if (getTechService.message !== undefined) {
        alert(getTechService.message);
      } else {
        alert("기술지원 내역을 찾을 수 없습니다.");
      }

      navigate(-1);
    }
  };

  useEffect(() => {
    if (param.id === undefined) {
      alert("잘못된 접근입니다.");
      navigate(-1);
    }
  }, [param.id]);

  return (
    <LoginChecker
      onCheck={(state, data) => {
        if (state === "UNLOGIN") {
          alert("로그인이 필요합니다.");
          navigate(
            `${URLStructure.UserLogin}?redirect=${location.pathname}${location.search}`
          );
        } else {
          setLoginData(data);
          if (param.id !== undefined) {
            checkTechServiceExist(param.id, data.token);
          }
        }
      }}
    >
      <InfoLayoutPage title="기술지원 상세">
        {checkTechService.loadComplete &&
          (checkTechService.state ?? false) &&
          checkTechService.techServiceID !== undefined ? (
          <React.Fragment>

            <Box
              sx={{
                mb: 3,
              }}
            >
              <ICTAccordion title="등록자 정보">
                <TechServiceUserComponent
                  key={checkTechService.techServiceID}
                  TechServiceID={checkTechService.techServiceID}
                  refreshPage={() => refreshComponent()}
                  ref={techServiceUserComponentRef}
                  onLoading={(_state, _message) => setLoadingOpen({
                    state: _state,
                    message: _state === false ? undefined : _message
                  })}
                />
              </ICTAccordion>

              <ICTAccordion title="기술지원 정보" isExpend>
                <TechServiceInfoComponent
                  key={checkTechService.techServiceID}
                  TechServiceID={checkTechService.techServiceID}
                  refreshPage={() => refreshComponent()}
                  ref={techServiceInfoComponentRef}
                  onLoading={(_state, _message) => setLoadingOpen({
                    state: _state,
                    message: _state === false ? undefined : _message
                  })}
                />
              </ICTAccordion>

              <ICTAccordion title="기술지원 진행 자료">
                <TechServiceProcessFilesComponent
                  key={checkTechService.techServiceID}
                  TechServiceID={checkTechService.techServiceID}
                  refreshPage={() => refreshComponent()}
                  ref={techServiceProcessFilesComponentRef}
                  onLoading={(_state, _message) => setLoadingOpen({
                    state: _state,
                    message: _state === false ? undefined : _message
                  })}
                />

                <TechServiceProcessCompleteFilesComponent
                  key={checkTechService.techServiceID}
                  TechServiceID={checkTechService.techServiceID}
                  refreshPage={() => refreshComponent()}
                  ref={techServiceCompleteFilesComponentRef}
                  onLoading={(_state, _message) => setLoadingOpen({
                    state: _state,
                    message: _state === false ? undefined : _message
                  })}
                />
              </ICTAccordion>
              {(checkTechService.techServiceType === "A" ||
                checkTechService.techServiceType === "S") && (
                  <ICTAccordion title="시뮬레이션 정보">
                    <TechServiceSimulationComponent
                      key={checkTechService.techServiceID}
                      TechServiceID={checkTechService.techServiceID}
                      refreshPage={() => refreshComponent()}
                      ref={techServiceSimulationComponentRef}
                      onLoading={(_state, _message) => setLoadingOpen({
                        state: _state,
                        message: _state === false ? undefined : _message
                      })}
                    />
                  </ICTAccordion>
                )}

              <ICTAccordion title="기술지원 기대효과">
                <TechServiceEffectComponent
                  key={checkTechService.techServiceID}
                  TechServiceID={checkTechService.techServiceID}
                  refreshPage={() => refreshComponent()}
                  ref={tehcServiceEffectComponentRef}
                  onLoading={(_state, _message) => setLoadingOpen({
                    state: _state,
                    message: _state === false ? undefined : _message
                  })}
                />
              </ICTAccordion>
              <ICTAccordion title="댓글">
                <TechServiceReplyComponent
                  key={checkTechService.techServiceID}
                  TechServiceID={checkTechService.techServiceID}
                  refreshPage={() => refreshComponent()}
                  ref={techServiceReplyComponentRef}
                  onLoading={(_state, _message) => setLoadingOpen({
                    state: _state,
                    message: _state === false ? undefined : _message
                  })}
                />
              </ICTAccordion>
              {((checkTechService.techServiceStatus === "RstApproval" && (userRedux.resultVO?.isProfesnal ?? 'N') !== 'Y') ||
                checkTechService.techServiceStatus === "Complete") && (
                  <ICTAccordion title="기술지원 완료">
                    <TechServiceCompleteComponent
                      key={checkTechService.techServiceID}
                      ref={techServiceCompleteComponentRef}
                      refreshPage={() => refreshComponent()}
                      TechServiceID={checkTechService.techServiceID}
                      onLoading={(_state, _message) => setLoadingOpen({
                        state: _state,
                        message: _state === false ? undefined : _message
                      })}
                    />
                  </ICTAccordion>
                )}
            </Box>
          </React.Fragment>
        ) : (
          <Box
            sx={{
              width: "100%",
            }}
          >
            <Typography>기술지원 내역을 찾을 수 없습니다.</Typography>
          </Box>
        )}
      </InfoLayoutPage>

      {/* Modal */}
      <MISCFullBlurLoadingModal
        open={loadingOpen.state}
        message={loadingOpen.message}
        onClose={() => { }}
      />
    </LoginChecker>
  );
};

//#region Sub Components

export interface IForwardFunc {
  refresh: () => void;
}

export interface IComponentRefresh {
  refreshPage: () => void;
  onLoading: (state: boolean, message?: string) => void;
}

export const RowBox = (prop: { children: React.ReactNode | React.ReactNode[] }) => (
  <Box
    sx={{
      display: "flex",
      justifyContent: "space-between",
      alignItems: "start",
    }}
  >
    {prop.children}
  </Box>
);

export const ItemBox = (prop: {
  title: string;
  content: React.ReactNode;
  sx?: SxProps<Theme>;
  titleSx?: SxProps<Theme>;
  endContent?: React.ReactNode;
}) => (
  <Box
    sx={{
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      // border: '1px solid black',
      ...prop.sx,
    }}
  >
    <Box
      sx={{
        display: "flex",
        justifyContent: "start",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Box
        sx={{
          m: 1,
          minWidth: "130px",
          ...prop.titleSx,
        }}
      >
        <Typography>{prop.title}</Typography>
      </Box>
      <Box
        sx={{
          m: 1,
          overflow: "hidden",
          overflowWrap: "break-word",
          maxWidth: "calc(100% - 130px)",
          width: "100%",
          p: 0.1,
        }}
      >
        {prop.content}
      </Box>
    </Box>
    {prop.endContent !== undefined && (
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            m: 1,
            overflow: "hidden",
            overflowWrap: "break-word",
            maxWidth: "100%",
            width: "100%",
            p: 0.1,
          }}
        >
          {prop.endContent}
        </Box>
      </Box>
    )}
  </Box>
);

export const LoadingBox = () => (
  <Box
    sx={{
      width: "100%",
      height: "150px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <CircularProgress color="inherit" />
  </Box>
);


export const NoDataBox = (prop: { refreshFunction?: () => void }) => (
  <Box
    sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "150px",
      flexDirection: "column",
    }}
  >
    <Typography variant="h6">데이터가 없습니다.</Typography>
    <IconButton
      onClick={() => prop.refreshFunction && prop.refreshFunction()}
      disableRipple
    >
      <Refresh
        sx={{
          color: "rgba(0,0,0,0.3)",
        }}
      />
    </IconButton>
  </Box>
);

//#endregion

//#region 등록자 정보 Component

interface ITechServiceUserComponent extends IComponentRefresh {
  TechServiceID: string;
}

const TechServiceUserComponent = React.forwardRef<
  IForwardFunc,
  ITechServiceUserComponent
>((prop: ITechServiceUserComponent, ref) => {
  interface IRequester {
    CompanyName: string;
    CompanyBusinessNumber: string;
    Name: string;
    Rank: string;
    Department: string;
    Email: string;
    PhoneNumber: string;
    CellPhoneNumber: string;
  }

  const userRedux = useLoginCheck();
  const [requesterData, setRequesterData] = useState<IRequester | undefined>(
    undefined
  );

  const [loadControl, setLoadControl] = useState<ILoadControl>(InitLoadControl);

  const getTechServiceRequester = async () => {
    setLoadControl({
      isComplete: false,
      isLoading: true,
      errorMessage: undefined,
    });

    const getData = await RequestKeriAPI.TechService.GetTechServiceInfo(
      userRedux?.token,
      prop.TechServiceID
    );

    if (getData.state) {
      try {
        if (getData.resultData.TechServiceWriter === undefined) {
          throw "Not found requester";
        }

        const writerData = getData.resultData.TechServiceWriter;

        setRequesterData({
          CompanyName: writerData.Company!.CompanyName!,
          CompanyBusinessNumber: writerData.Company!.CompanyNumber!,
          Name: writerData.User!.UserName!,
          Department: writerData.User!.UserDepartment!,
          Email: writerData.User!.UserEmail!,
          Rank: writerData.User!.UserRank!,
          PhoneNumber: writerData.User!.UserCallNumber!,
          CellPhoneNumber: writerData.User!.UserCallNumber!,
        });

        setLoadControl({
          isComplete: true,
          isLoading: false,
          errorMessage: undefined,
        });
      } catch (e) {
        setLoadControl({
          isComplete: true,
          isLoading: false,
          errorMessage: "데이터를 정상적으로 로드할 수 없습니다.",
        });
      }
    }
  };

  useImperativeHandle(ref, () => ({
    refresh: () => {
      getTechServiceRequester();
    },
  }));

  useEffect(() => {
    if ((userRedux?.loadComplete ?? false) && userRedux?.token !== undefined) {
      getTechServiceRequester();
    }
  }, [prop.TechServiceID, userRedux]);

  return (
    <LoadControlUnit
      isLoading={loadControl.isLoading && !loadControl.isComplete}
      errorMessage={loadControl.errorMessage}
      loadComponent={<LoadingBox />}
      errorComponent={
        <MISCTechServiceSectionErrorBox
          errorMessage={loadControl.errorMessage}
          refreshFunction={getTechServiceRequester}
        />
      }
    >
      <React.Fragment>
        <RowBox>
          <ItemBox
            title="상호명(한글)"
            content={requesterData?.CompanyName}
            sx={{
              width: "50%",
            }}
          />
          <ItemBox
            title="사업자등록번호"
            content={requesterData?.CompanyBusinessNumber}
            sx={{
              width: "50%",
            }}
          />
        </RowBox>
        <RowBox>
          <ItemBox
            title="담당자명"
            content={requesterData?.Name}
            sx={{
              width: "50%",
            }}
          />
          <ItemBox
            title="직급"
            content={requesterData?.Rank}
            sx={{
              width: "50%",
            }}
          />
        </RowBox>
        <RowBox>
          <ItemBox
            title="부서"
            content={requesterData?.Department}
            sx={{
              width: "50%",
            }}
          />
          <ItemBox
            title="이메일 주소"
            content={requesterData?.Email}
            sx={{
              width: "50%",
            }}
          />
        </RowBox>
        <RowBox>
          <ItemBox
            title="전화번호"
            content={
              RegexUtil.ConvertRegexString.PhoneNumber(
                requesterData?.PhoneNumber
              )!
            }
            sx={{
              width: "50%",
            }}
          />
          <ItemBox
            title="휴대전화번호"
            content={
              RegexUtil.ConvertRegexString.CellPhoneNumber(
                requesterData?.CellPhoneNumber
              )!
            }
            sx={{
              width: "50%",
            }}
          />
        </RowBox>
      </React.Fragment>
    </LoadControlUnit>
  );
});

//#endregion

//#region 기술지원 정보

interface ITechServiceInfoComponent extends IComponentRefresh {
  TechServiceID: string;
}

const TechServiceInfoComponent = React.forwardRef<
  IForwardFunc,
  ITechServiceInfoComponent
>((prop: ITechServiceInfoComponent, ref) => {
  interface ITechServiceData {
    Type: TechServiceType;
    No: string;
    RepeatCount: string;
    StateName: string;
    StateCode: string;
    Title: string;
    PurposeName: string;
    PurposeCode: string;
    ProductName: string;
    ProductCode: string;
    Description: string;
    CompleteDate: string;
    Files: IUploadFile<IUploadFileVO>[];
    TestMethod?: string;
    TestType?: string;
    TestItem?: string[];
    RequesterName?: string;
    RequesterDepartment?: string;
    RequesterPhoneNumber?: string;
    RequesterEmail?: string;
  }

  interface ITechServiceEditData extends ITechServiceData {
    AddFiles?: IUploadFile<IUploadFileVO>[];
    DeleteFiles?: IUploadFile<IUploadFileVO>[];
  }

  const userRedux = useLoginCheck();
  const navigate = useNavigate();

  const [techServiceData, setTechServiceData] = useState<
    ITechServiceData | undefined
  >(undefined);

  const [loadControl, setLoadControl] = useState<ILoadControl>(InitLoadControl);

  const [editMode, setEditMode] = useState<boolean>(false);
  const [editData, setEditData] = useState<ITechServiceEditData | undefined>(
    undefined
  );

  const [purposeModalOpen, setPurposeModalOpen] = useState<boolean>(false);
  const [productModalOpen, setProductModalOpen] = useState<boolean>(false);
  const [expertUserModalOpen, setExpertUserModalOpen] =
    useState<boolean>(false);

  const [testAttributeModalOpen, setTestAttributeModalOpen] = useState<{
    state: boolean;
    type?: TestAttribute;
  }>({ state: false });

  const getTechServiceData = async () => {
    setLoadControl({
      isComplete: false,
      isLoading: true,
      errorMessage: undefined,
    });

    const getData = await RequestKeriAPI.TechService.GetTechServiceInfo(
      userRedux?.token,
      prop.TechServiceID
    );

    if (getData.state) {
      try {
        if (getData.resultData.TechServiceData === undefined) {
          throw "Not found requester";
        }

        const techData = getData.resultData.TechServiceData;

        setTechServiceData({
          No: techData.RequestUniqueID!,
          RepeatCount: techData.RequestSimulationOrder!.toString(),
          StateCode: techData.RequestStatus!,
          StateName: techData.RequestStatusName!,
          Title: techData.RequestInputData!.RequestTitle!,
          PurposeName:
            techData.RequestInputData!.RequestPurpose![0].purposeName!,
          PurposeCode:
            techData.RequestInputData!.RequestPurpose![0].purposeCode!,
          ProductCode: techData.RequestInputData!.RequestProduct!.productID!,
          ProductName:
            techData.RequestInputData!.RequestProduct!.productName!
              .productName!,
          Description: techData.RequestInputData!.RequestInfo!,
          CompleteDate: ObjectUtils.DateToString(
            techData.RequestInputData!.RequestDesiredDate!,
            "yyyy-MM-dd"
          )!,
          Files: techData.RequestInputFile ?? [],
          Type: techData.RequestType,
          TestItem: (techData.RequestInputData?.RequestTestItem ?? [])
            .filter((x) => x.TestName !== undefined)
            .map((x) => x.TestName!),
          TestMethod: techData.RequestInputData?.RequestTestMethod?.TestName,
          TestType: techData.RequestInputData?.RequestTestType?.TestName,
          RequesterName: techData.RequesterData?.RequesterName,
          RequesterDepartment: techData.RequesterData?.RequesterDepartment,
          RequesterEmail: techData.RequesterData?.RequesterEmail,
          RequesterPhoneNumber: techData.RequesterData?.RequesterCallNumber,
        });

        setLoadControl({
          isComplete: true,
          isLoading: false,
        });
      } catch (e) {
        setLoadControl({
          isComplete: true,
          isLoading: false,
          errorMessage: "데이터를 정상적으로 로드할 수 없습니다.",
        });
      }
    } else {
      setLoadControl({
        isComplete: true,
        isLoading: false,
        errorMessage: getData.message,
      });
    }
  };

  const categoryFilter = (
    editType: "request" | "process" | "complete",
    category: string
  ): boolean => {
    switch (editType) {
      case "request":
        return category === "ServiceFiles" || category === "ServiceRequestFile";
      case "process":
        return category === "TechServiceProcessFiles";
      case "complete":
        return category === "TechServiceCompleteFiles";
      default:
        return false;
    }
  };

  const editTechService = async () => {
    prop.onLoading(true, '기술지원 정보 수정중..');
    let filterEditData = { ...editData };
    let deleteFiles = [...(editData?.DeleteFiles ?? [])];
    let addFiles = [...(editData?.AddFiles ?? [])];

    for (let addFileItem of addFiles ?? []) {
      if (
        deleteFiles.find(
          (x) =>
            x.UploadedFile?.streFileNm === addFileItem.UploadedFile?.streFileNm
        ) !== undefined
      ) {
        filterEditData.AddFiles = [
          ...(filterEditData.AddFiles?.filter(
            (x) =>
              x.UploadedFile?.streFileNm !==
              addFileItem.UploadedFile?.streFileNm
          ) ?? []),
        ];
        filterEditData.DeleteFiles = [
          ...(filterEditData.DeleteFiles?.filter(
            (x) =>
              x.UploadedFile?.streFileNm !==
              addFileItem.UploadedFile?.streFileNm
          ) ?? []),
        ];
      }
    }

    if (editData !== undefined) {
      const editRequestData = await RequestKeriAPI.TechService.EditTechService(
        userRedux?.token,
        prop.TechServiceID,
        {
          TechServiceData: {
            RequestType: editData!.Type,
            RequestInputData: {
              RequestPurpose: [
                {
                  purposeCode: editData.PurposeCode,
                  purposeName: editData.PurposeName,
                },
              ],
              RequestProduct: {
                productID: editData.ProductCode,
                productName: {
                  productName: editData.ProductName,
                },
              },
              RequestInfo: editData.Description,
              RequestDesiredDate: new Date(editData.CompleteDate),
              RequestTestItem: (editData.TestItem ?? []).map((x) => ({
                TestType: "TestItem",
                TestName: x,
              })),
              RequestTestMethod: {
                TestType: "TestMethod",
                TestName: editData.TestMethod,
              },
              RequestTestType: {
                TestType: "TestType",
                TestName: editData.TestType,
              },
              RequestTitle: editData.Title,
            },
            RequesterData: {
              RequesterCallNumber: editData.RequesterPhoneNumber,
              RequesterDepartment: editData.RequesterDepartment,
              RequesterEmail: editData.RequesterEmail,
              RequesterName: editData.RequesterName,
            },
          },
        },
        filterEditData.AddFiles,
        filterEditData.DeleteFiles,
        "Receipt",
        (techServiceData?.Files ?? []).length === 0
          ? undefined
          : techServiceData?.Files[0].UploadedFile?.atchFileId
      );

      if (editRequestData.state) {
        setEditMode(false);
        getTechServiceData();
      } else {
        alert(editRequestData.message);
        // setEditMode(false);
      }
    }

    prop.onLoading(false);
  };

  useImperativeHandle(ref, () => ({
    refresh: () => {
      getTechServiceData();
    },
  }));

  useEffect(() => {
    if ((userRedux?.loadComplete ?? false) && userRedux?.token !== undefined) {
      getTechServiceData();
    }
  }, [prop.TechServiceID, userRedux]);

  return (
    <React.Fragment>
      <LoadControlUnit
        isLoading={loadControl.isLoading && !loadControl.isComplete}
        errorMessage={loadControl.errorMessage}
        title="기술지원 신청 내역"
        titleEndComponent={
          <Box>
            {userRedux?.resultVO?.isProfesnal === "N" && (
              <Box
                sx={{
                  p: 1,
                }}
              >
                {!editMode &&
                  userRedux?.resultVO?.isProfesnal === "N" &&
                  (techServiceData?.StateCode === "New" ||
                    techServiceData?.StateCode === "Edit") && (
                    <KeriActionButton
                      label="수정"
                      variant="outlined"
                      onClick={() => {
                        setEditData({ ...techServiceData });
                        setEditMode(true);
                      }}
                      sx={{
                        m: 0,
                      }}
                    />
                  )}
                {/* <KeriActionButton
                  label="새로 신청하기"
                  variant="outlined"
                  onClick={() =>
                    navigate(
                      `${URLStructure.TechServiceRegister}?orgtech=${prop.TechServiceID}`
                    )
                  }
                  sx={{
                    m: 0,
                    ml: 1,
                  }}
                /> */}
                {techServiceData?.Type === "A" && (
                  <KeriActionButton
                    label="지능형 시뮬레이션 신청"
                    variant="outlined"
                    onClick={() =>
                      navigate(
                        `${URLStructure.TechServiceRegister}?orgtech=${prop.TechServiceID}&type=is`
                      )
                    }
                    sx={{
                      m: 0,
                      ml: 1,
                    }}
                  />
                )}
              </Box>
            )}
          </Box>
        }
        loadComponent={<LoadingBox />}
        errorComponent={
          <MISCTechServiceSectionErrorBox
            refreshFunction={getTechServiceData}
            errorMessage={loadControl.errorMessage}
          />
        }
      >
        <EditControl
          isEdit={editMode}
          editComponent={
            <React.Fragment>
              {techServiceData !== undefined && editData !== undefined && (
                <React.Fragment>
                  <RowBox>
                    <ItemBox
                      title="대상 제품"
                      content={
                        <Box
                          sx={{
                            width: "100%",
                            border: "1px solid #c4c4c4",
                            minHeight: "40px",
                            borderRadius: "3px",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            overflow: "hidden",
                          }}
                        >
                          <Box
                            sx={{
                              pr: 2,
                              pl: 2,

                              maxWidth: "calc(100% - 70px)",
                            }}
                          >
                            <Tooltip title={editData?.ProductName ?? ""}>
                              <Typography
                                sx={{
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                  width: "100%",
                                }}
                              >
                                {editData?.ProductName}
                              </Typography>
                            </Tooltip>
                          </Box>
                          <Box
                            sx={{
                              // background: '#25476a',
                              height: "100%",
                              minHeight: "40px",
                              width: "70px",
                              textAlign: "center",
                            }}
                          >
                            <Button
                              sx={{
                                background: "#25476a",
                                width: "100%",
                                minHeight: "40px",
                                height: "100%",
                                m: 0,
                                borderRadius: "0px",
                                color: "white",
                                ":hover": {
                                  color: "#6666CC",
                                },
                              }}
                              onClick={() => setProductModalOpen(true)}
                            >
                              선택
                            </Button>
                          </Box>
                        </Box>
                      }
                      sx={{
                        width: "100%",
                      }}
                    />
                  </RowBox>
                  <RowBox>
                    <ItemBox
                      title="완료 희망일"
                      content={
                        <TextField
                          type={"date"}
                          value={editData?.CompleteDate}
                          size="small"
                          fullWidth
                          onChange={(e) => {
                            if (
                              !ObjectUtils.IsNullOrEmpty(e.currentTarget.value)
                            ) {
                              setEditData({
                                ...editData!,
                                CompleteDate: e.currentTarget.value,
                              });
                            }
                          }}
                          inputProps={{
                            min: ObjectUtils.DateToString(
                              Date.now(),
                              "yyyy-MM-dd"
                            ),
                          }}
                        />
                      }
                      sx={{
                        width: "50%",
                      }}
                    />
                    <ItemBox
                      title="신청 목적"
                      content={
                        <Box
                          sx={{
                            width: "100%",
                            border: "1px solid #c4c4c4",
                            minHeight: "40px",
                            borderRadius: "3px",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            overflow: "hidden",
                          }}
                        >
                          <Box
                            sx={{
                              pr: 2,
                              pl: 2,

                              maxWidth: "calc(100% - 70px)",
                            }}
                          >
                            <Tooltip title={editData?.PurposeName ?? ""}>
                              <Typography
                                sx={{
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                  width: "100%",
                                }}
                              >
                                {editData?.PurposeName}
                              </Typography>
                            </Tooltip>
                          </Box>
                          <Box
                            sx={{
                              // background: '#25476a',
                              height: "100%",
                              minHeight: "40px",
                              width: "70px",
                              textAlign: "center",
                            }}
                          >
                            <Button
                              sx={{
                                background: "#25476a",
                                width: "100%",
                                minHeight: "40px",
                                height: "100%",
                                m: 0,
                                borderRadius: "0px",
                                color: "white",
                                ":hover": {
                                  color: "#6666CC",
                                },
                              }}
                              onClick={() => setPurposeModalOpen(true)}
                            >
                              선택
                            </Button>
                          </Box>
                        </Box>
                      }
                      sx={{
                        width: "50%",
                        alignItems: "center",
                      }}
                    />
                  </RowBox>
                  <RowBox>
                    <ItemBox
                      title="신청 내용"
                      content={
                        <TextField
                          multiline
                          size="small"
                          fullWidth
                          value={editData!.Description.replaceAll(
                            "<br />",
                            "\n"
                          )}
                          onChange={(e) => {
                            setEditData({
                              ...editData!,
                              Description: e.currentTarget.value.replaceAll(
                                "\n",
                                "<br />"
                              ),
                            });
                          }}
                        />
                      }
                      sx={{
                        width: "100%",
                      }}
                    />
                  </RowBox>
                  {techServiceData.Type === "T" && (
                    <React.Fragment>
                      <RowBox>
                        <ItemBox
                          title="시험 방법"
                          content={
                            <Box
                              sx={{
                                width: "100%",
                                border: "1px solid #c4c4c4",
                                minHeight: "40px",
                                borderRadius: "3px",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                overflow: "hidden",
                              }}
                            >
                              <Box
                                sx={{
                                  pr: 2,
                                  pl: 2,

                                  maxWidth: "calc(100% - 70px)",
                                }}
                              >
                                <Tooltip title={editData?.TestMethod ?? ""}>
                                  <Typography
                                    sx={{
                                      overflow: "hidden",
                                      whiteSpace: "nowrap",
                                      textOverflow: "ellipsis",
                                      width: "100%",
                                    }}
                                  >
                                    {editData?.TestMethod}
                                  </Typography>
                                </Tooltip>
                              </Box>
                              <Box
                                sx={{
                                  // background: '#25476a',
                                  height: "100%",
                                  minHeight: "40px",
                                  width: "70px",
                                  textAlign: "center",
                                }}
                              >
                                <Button
                                  sx={{
                                    background: "#25476a",
                                    width: "100%",
                                    minHeight: "40px",
                                    height: "100%",
                                    m: 0,
                                    borderRadius: "0px",
                                    color: "white",
                                    ":hover": {
                                      color: "#6666CC",
                                    },
                                  }}
                                  onClick={() =>
                                    setTestAttributeModalOpen({
                                      state: true,
                                      type: "TestMethod",
                                    })
                                  }
                                >
                                  선택
                                </Button>
                              </Box>
                            </Box>
                          }
                          sx={{
                            width: "50%",
                          }}
                        />

                        <ItemBox
                          title="시험 유형"
                          content={
                            <Box
                              sx={{
                                width: "100%",
                                border: "1px solid #c4c4c4",
                                minHeight: "40px",
                                borderRadius: "3px",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                overflow: "hidden",
                              }}
                            >
                              <Box
                                sx={{
                                  pr: 2,
                                  pl: 2,

                                  maxWidth: "calc(100% - 70px)",
                                }}
                              >
                                <Tooltip title={editData?.TestType ?? ""}>
                                  <Typography
                                    sx={{
                                      overflow: "hidden",
                                      whiteSpace: "nowrap",
                                      textOverflow: "ellipsis",
                                      width: "100%",
                                    }}
                                  >
                                    {editData?.TestType}
                                  </Typography>
                                </Tooltip>
                              </Box>
                              <Box
                                sx={{
                                  // background: '#25476a',
                                  height: "100%",
                                  minHeight: "40px",
                                  width: "70px",
                                  textAlign: "center",
                                }}
                              >
                                <Button
                                  sx={{
                                    background: "#25476a",
                                    width: "100%",
                                    minHeight: "40px",
                                    height: "100%",
                                    m: 0,
                                    borderRadius: "0px",
                                    color: "white",
                                    ":hover": {
                                      color: "#6666CC",
                                    },
                                  }}
                                  onClick={() =>
                                    setTestAttributeModalOpen({
                                      state: true,
                                      type: "TestType",
                                    })
                                  }
                                >
                                  선택
                                </Button>
                              </Box>
                            </Box>
                          }
                          sx={{
                            width: "50%",
                          }}
                        />
                      </RowBox>
                      <RowBox>
                        <ItemBox
                          title="시험 항목"
                          content={
                            <Box
                              sx={{
                                width: "100%",
                                border: "1px solid #c4c4c4",
                                minHeight: "40px",
                                borderRadius: "3px",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                overflow: "hidden",
                              }}
                            >
                              <Box
                                sx={{
                                  pr: 0.5,
                                  pl: 0.5,
                                  maxWidth: "calc(100% - 70px)",
                                }}
                              >
                                <Box
                                  sx={{
                                    overflowX: "auto",
                                    display: "flex",
                                    justifyContent: "start",
                                    alignItems: "center",
                                    flexWrap: "nowrap",
                                    "&::-webkit-scrollbar": {
                                      height: "4px",
                                      width: "20px",
                                      background: "rgba(0,0,0,0.1)",
                                    },
                                    "&::-webkit-scrollbar-thumb": {
                                      backgroundColor: "rgba(0,0,0,.2)",
                                    },
                                    "&::-webkit-scrollbar-track": {
                                      boxShadow:
                                        "inset 0 0 6px rgba(0,0,0,0.00)",
                                      webkitBoxShadow:
                                        "inset 0 0 6px rgba(0,0,0,0.00)",
                                    },
                                    scrollbarWidth: "1px",
                                    scrollMargin: 0,
                                  }}
                                >
                                  {editData?.TestItem?.map((testItem) => (
                                    <Chip label={testItem} sx={{ mr: 1 }} />
                                  ))}
                                </Box>
                              </Box>
                              <Box
                                sx={{
                                  // background: '#25476a',
                                  height: "100%",
                                  minHeight: "40px",
                                  width: "70px",
                                  textAlign: "center",
                                }}
                              >
                                <Button
                                  sx={{
                                    background: "#25476a",
                                    width: "100%",
                                    minHeight: "40px",
                                    height: "100%",
                                    m: 0,
                                    borderRadius: "0px",
                                    color: "white",
                                    ":hover": {
                                      color: "#6666CC",
                                    },
                                  }}
                                  onClick={() =>
                                    setTestAttributeModalOpen({
                                      state: true,
                                      type: "TestItem",
                                    })
                                  }
                                >
                                  선택
                                </Button>
                              </Box>
                            </Box>
                          }
                          sx={{
                            width: "100%",
                          }}
                        />
                      </RowBox>
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
            </React.Fragment>
          }
          viewComponent={
            <React.Fragment>
              {techServiceData !== undefined && (
                <React.Fragment>
                  <RowBox>
                    <ItemBox
                      title="신청번호"
                      content={techServiceData.No}
                      sx={{
                        width: "50%",
                      }}
                    />
                    <ItemBox
                      title="차수"
                      content={techServiceData.RepeatCount}
                      sx={{
                        width: "50%",
                      }}
                    />
                  </RowBox>
                  <RowBox>
                    <ItemBox
                      title="신청 제목"
                      content={techServiceData.Title}
                      sx={{
                        width: "100%",
                      }}
                    />
                  </RowBox>
                  <RowBox>
                    <ItemBox
                      title="완료 희망일"
                      content={techServiceData.CompleteDate}
                      sx={{
                        width: "50%",
                      }}
                    />
                    <ItemBox
                      title="상태"
                      content={techServiceData.StateName}
                      sx={{
                        width: "50%",
                      }}
                    />
                  </RowBox>

                  <RowBox>
                    <ItemBox
                      title="신청 목적"
                      content={<Chip label={techServiceData.PurposeName} />}
                      sx={{
                        width: "50%",
                        alignItems: "center",
                      }}
                    />
                    <ItemBox
                      title="대상 제품"
                      content={techServiceData.ProductName}
                      sx={{
                        width: "50%",
                      }}
                    />
                  </RowBox>
                  <RowBox>
                    <ItemBox
                      title="신청 내용"
                      content={techServiceData.Description}
                      sx={{
                        width: "100%",
                      }}
                    />
                  </RowBox>
                  {techServiceData.Type === "T" && (
                    <React.Fragment>
                      <RowBox>
                        <ItemBox
                          title="시험 방법"
                          content={techServiceData.TestMethod}
                          sx={{
                            width: "50%",
                          }}
                        />

                        <ItemBox
                          title="시험 유형"
                          content={techServiceData.TestType}
                          sx={{
                            width: "50%",
                          }}
                        />
                      </RowBox>
                      <RowBox>
                        <ItemBox
                          title="시험 항목"
                          content={
                            <Box>
                              {techServiceData.TestItem?.map((testItem) => (
                                <Chip label={testItem} sx={{ mr: 1, mb: 1 }} />
                              ))}
                            </Box>
                          }
                          sx={{
                            width: "100%",
                          }}
                        />
                      </RowBox>
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
            </React.Fragment>
          }
        />
      </LoadControlUnit>
      <LoadControlUnit
        isLoading={loadControl.isLoading && !loadControl.isComplete}
        errorMessage={loadControl.errorMessage}
        title="신청자 정보"
        loadComponent={<LoadingBox />}
        errorComponent={
          <MISCTechServiceSectionErrorBox
            refreshFunction={getTechServiceData}
            errorMessage={loadControl.errorMessage}
          />
        }
      >
        <EditControl
          isEdit={editMode}
          editComponent={
            <React.Fragment>
              <RowBox>
                <ItemBox
                  title="이름"
                  content={
                    <TextField
                      value={editData?.RequesterName}
                      size="small"
                      fullWidth
                      onChange={(e) => {
                        if (!ObjectUtils.IsNullOrEmpty(e.currentTarget.value)) {
                          setEditData({
                            ...editData!,
                            RequesterName: e.currentTarget.value,
                          });
                        }
                      }}
                    />
                  }
                  sx={{
                    width: "50%",
                  }}
                />
                <ItemBox
                  title="부서/직급"
                  content={
                    <TextField
                      value={editData?.RequesterDepartment}
                      size="small"
                      fullWidth
                      onChange={(e) => {
                        if (!ObjectUtils.IsNullOrEmpty(e.currentTarget.value)) {
                          setEditData({
                            ...editData!,
                            RequesterDepartment: e.currentTarget.value,
                          });
                        }
                      }}
                    />
                  }
                  sx={{
                    width: "50%",
                  }}
                />
              </RowBox>
              <RowBox>
                <ItemBox
                  title="휴대전화 번호"
                  content={
                    <TextField
                      value={editData?.RequesterPhoneNumber}
                      size="small"
                      fullWidth
                      onChange={(e) => {
                        e.currentTarget.value =
                          RegexUtil.ConvertRegexString.PhoneNumber(
                            e.currentTarget.value
                          ) ?? "";
                        if (!ObjectUtils.IsNullOrEmpty(e.currentTarget.value)) {
                          setEditData({
                            ...editData!,
                            RequesterPhoneNumber: e.currentTarget.value,
                          });
                        }
                      }}
                    />
                  }
                  sx={{
                    width: "50%",
                  }}
                />
                <ItemBox
                  title="이메일"
                  content={
                    <TextField
                      value={editData?.RequesterEmail}
                      size="small"
                      fullWidth
                      onChange={(e) => {
                        if (!ObjectUtils.IsNullOrEmpty(e.currentTarget.value)) {
                          setEditData({
                            ...editData!,
                            RequesterEmail: e.currentTarget.value,
                          });
                        }
                      }}
                    />
                  }
                  sx={{
                    width: "50%",
                  }}
                />
              </RowBox>
            </React.Fragment>
          }
          viewComponent={
            <React.Fragment>
              {techServiceData !== undefined && (
                <React.Fragment>
                  <RowBox>
                    <ItemBox
                      title="이름"
                      content={techServiceData.RequesterName}
                      sx={{
                        width: "50%",
                      }}
                    />
                    <ItemBox
                      title="부서/직급"
                      content={techServiceData.RequesterDepartment}
                      sx={{
                        width: "50%",
                      }}
                    />
                  </RowBox>
                  <RowBox>
                    <ItemBox
                      title="휴대전화 번호"
                      content={techServiceData.RequesterPhoneNumber}
                      sx={{
                        width: "50%",
                      }}
                    />
                    <ItemBox
                      title="이메일"
                      content={techServiceData.RequesterEmail}
                      sx={{
                        width: "50%",
                      }}
                    />
                  </RowBox>
                </React.Fragment>
              )}
            </React.Fragment>
          }
        />
      </LoadControlUnit>
      {/* {
                !editMode &&
                <LoadControlUnit
                    isLoading={loadControl.isLoading && !loadControl.isComplete}
                    errorMessage={loadControl.errorMessage}
                    title="지원 담당자 정보"
                    loadComponent={<LoadingBox />}
                    errorComponent={
                        <ErrorBox
                            refreshFunction={getTechServiceData}
                            errorMessage={loadControl.errorMessage}
                        />
                    }
                >
                    <React.Fragment>
                        {
                            techServiceData !== undefined &&
                            <React.Fragment>
                                <RowBox>
                                    <ItemBox
                                        title="이름"
                                        content={'CenterSupporterName'}
                                        sx={{
                                            width: '50%'
                                        }}
                                    />
                                    <ItemBox
                                        title="부서"
                                        content={'CenterSupporterDepartment'}
                                        sx={{
                                            width: '50%'
                                        }}
                                    />
                                </RowBox>
                                <RowBox>
                                    <ItemBox
                                        title="연락처"
                                        content={'CenterSupporterPhoneNumber'}
                                        sx={{
                                            width: '50%'
                                        }}
                                    />
                                    <ItemBox
                                        title="이메일"
                                        content={'CenterSupporterEmail'}
                                        sx={{
                                            width: '50%'
                                        }}
                                    />
                                </RowBox>
                            </React.Fragment>
                        }

                    </React.Fragment>
                </LoadControlUnit>
            } */}

      <LoadControlUnit
        isLoading={loadControl.isLoading && !loadControl.isComplete}
        errorMessage={loadControl.errorMessage}
        title="기술지원 신청(참고) 자료"
        titleEndComponent={
          <React.Fragment>
            {editMode && (
              <Box
                sx={{
                  p: 1,
                }}
              >
                <KeriActionButton
                  label="참고자료 추가"
                  variant="outlined"
                  icon={<Add />}
                  onClick={() => {
                    setEditData({
                      ...editData!,
                      Files: [
                        ...(editData?.Files ?? []),
                        {
                          Category: "ServiceRequestFile",
                          Prefix: "TEC_SERV_",
                          Index: 1,
                          Label: "참고 자료",
                        },
                      ],
                    });
                  }}
                  sx={{
                    m: 0,
                  }}
                />
              </Box>
            )}
          </React.Fragment>
        }
        loadComponent={<LoadingBox />}
        errorComponent={
          <MISCTechServiceSectionErrorBox
            refreshFunction={getTechServiceData}
            errorMessage={loadControl.errorMessage}
          />
        }
      >
        <EditControl
          isEdit={editMode}
          viewComponent={
            <React.Fragment>
              {techServiceData !== undefined && (
                <React.Fragment>
                  {(techServiceData.Files ?? [])
                    .filter((x) => categoryFilter("request", x.Category))
                    .map((fileItem) => (
                      <RowBox key={ObjectUtils.RandomStrings(10)}>
                        <ItemBox
                          title={fileItem.Label}
                          content={
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "start",
                                alignItems: "center",
                                transition: "0.3s all cubic-bezier(0,0,0,1)",
                                cursor: "pointer",
                                ":hover": {
                                  color: "#7d7dc1",
                                },
                              }}
                              onClick={async () => {
                                prop.onLoading(true, '다운로드 중..');
                                const downloadData =
                                  await RequestKeriAPI.File.DownloadFileUsingAtchFileID(
                                    userRedux?.token,
                                    fileItem.UploadedFile?.atchFileId,
                                    parseInt(
                                      fileItem.UploadedFile?.fileSn ?? "-1"
                                    )
                                  );

                                if (downloadData.state) {
                                  const jsonFile = new Blob([
                                    downloadData.data!,
                                  ]);
                                  const element = document.createElement("a");

                                  element.href = URL.createObjectURL(jsonFile);
                                  element.download =
                                    fileItem.UploadedFile?.orignlFileNm ??
                                    "undefined";
                                  document.body.appendChild(element);
                                  element.click();
                                  element.remove();
                                } else {
                                  alert(downloadData.message);
                                }
                                prop.onLoading(false, '다운로드 중..');
                              }}
                            >
                              <Download
                                sx={{
                                  mr: 1,
                                }}
                              />
                              <Typography>
                                {fileItem.UploadedFile?.orignlFileNm}
                              </Typography>
                              <Typography
                                sx={{
                                  ml: 3,
                                  color: "#8f8a8a",
                                }}
                              >
                                {ObjectUtils.FileSizeFormater(
                                  fileItem.UploadedFile?.fileMg ?? 0
                                )}
                              </Typography>
                            </Box>
                          }
                          titleSx={{
                            width: "200px",
                          }}
                          sx={{
                            width: "100%",
                          }}
                        />
                      </RowBox>
                    ))}
                  {
                    (techServiceData.Files ?? []).filter((x) => categoryFilter("request", x.Category)).length === 0 &&
                    <MISCTechSErviceSectionEmptyDataBox
                      refresh={getTechServiceData}
                    />
                  }
                </React.Fragment>
              )}
            </React.Fragment>
          }
          editComponent={
            <React.Fragment>
              {(editData?.Files ?? []).filter((x) =>
                categoryFilter("request", x.Category)
              ).length === 0 ? (
                <NoDataBox refreshFunction={getTechServiceData} />
              ) : (
                <React.Fragment>
                  {(editData?.Files ?? [])
                    .filter((x) => categoryFilter("request", x.Category))
                    .map((fileItem) => (
                      <RowBox key={ObjectUtils.RandomStrings(10)}>
                        <ItemBox
                          title={fileItem.Label}
                          content={
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "start",
                                alignItems: "center",
                                transition: "0.3s all cubic-bezier(0,0,0,1)",
                                ...(fileItem.UploadedFile !== undefined
                                  ? {
                                    cursor: "pointer",
                                    ":hover": {
                                      color: "#7d7dc1",
                                    },
                                  }
                                  : { cursor: "default" }),
                              }}
                              onClick={async () => {
                                if (fileItem.UploadedFile !== undefined) {
                                  const downloadData =
                                    await RequestKeriAPI.File.DownloadFileUsingAtchFileID(
                                      userRedux?.token,
                                      fileItem.UploadedFile?.atchFileId,
                                      parseInt(
                                        fileItem.UploadedFile?.fileSn ?? "-1"
                                      )
                                    );

                                  if (downloadData.state) {
                                    const jsonFile = new Blob([
                                      downloadData.data!,
                                    ]);
                                    const element = document.createElement("a");

                                    element.href =
                                      URL.createObjectURL(jsonFile);
                                    element.download =
                                      fileItem.UploadedFile?.orignlFileNm ??
                                      "undefined";
                                    document.body.appendChild(element);
                                    element.click();
                                    element.remove();
                                  } else {
                                    alert(downloadData.message);
                                  }
                                }
                              }}
                            >
                              {fileItem.UploadedFile !== undefined ? (
                                <React.Fragment>
                                  <Download
                                    sx={{
                                      mr: 1,
                                    }}
                                  />
                                  <Typography>
                                    {fileItem.UploadedFile?.orignlFileNm}
                                  </Typography>
                                  <Typography
                                    sx={{
                                      ml: 3,
                                      color: "#8f8a8a",
                                    }}
                                  >
                                    {ObjectUtils.FileSizeFormater(
                                      fileItem.UploadedFile?.fileMg ?? 0
                                    )}
                                  </Typography>
                                </React.Fragment>
                              ) : (
                                <Box>
                                  <Typography>파일 없음</Typography>
                                </Box>
                              )}
                            </Box>
                          }
                          endContent={
                            <KeriActionButton
                              label="파일 변경"
                              variant="outlined"
                              component={
                                <input
                                  hidden
                                  accept="*"
                                  type="file"
                                  onChange={async (e) => {
                                    const fileArray: File[] = Array.from(
                                      e.target.files ?? []
                                    );

                                    if (fileArray.length !== 0) {
                                      const fileSplit =
                                        fileArray[0].name.split(".");

                                      if (
                                        KeriConfig.DenyUploadFileExt.indexOf(
                                          fileSplit[fileSplit.length - 1]
                                        ) > -1
                                      ) {
                                        alert(
                                          "업로드 할 수 없는 확장자입니다."
                                        );
                                        throw "업로드 할 수 없는 확장자입니다.";
                                      } else if (fileSplit.length === 1) {
                                        alert(
                                          "확장자가 없는 파일은 업로드 할 수 없습니다."
                                        );
                                        throw "확장자가 없는 파일은 업로드 할 수 없습니다.";
                                      } else if (
                                        fileArray[0].size >
                                        500 * 1024 * 1024
                                      ) {
                                        alert(
                                          "500MB 이상 업로드 할 수 없습니다."
                                        );
                                        throw "500MB 이상 업로드 할 수 없습니다.";
                                      }

                                      const uploadFile =
                                        await RequestKeriAPI.File.UploadFile({
                                          Index: fileItem.Index,
                                          Category: fileItem.Category,
                                          Prefix: fileItem.Prefix,
                                          Label: fileItem.Label,
                                          OriginFile: fileArray[0],
                                        });

                                      if (uploadFile.state) {
                                        let addFiles = [
                                          ...(editData?.AddFiles ?? []),
                                        ];
                                        let deleteFiles = [
                                          ...(editData?.DeleteFiles ?? []),
                                        ];

                                        uploadFile.data &&
                                          addFiles.push(uploadFile.data);
                                        fileItem.UploadedFile &&
                                          deleteFiles.push(fileItem);

                                        setEditData({
                                          ...editData!,
                                          AddFiles: addFiles,
                                          DeleteFiles: deleteFiles,
                                          Files: [
                                            ...(editData?.Files ?? []).filter(
                                              (x) =>
                                                x.UploadedFile?.streFileNm !==
                                                fileItem.UploadedFile
                                                  ?.streFileNm
                                            ),
                                            ...(uploadFile.data === undefined
                                              ? []
                                              : [uploadFile.data]),
                                          ],
                                        });
                                      } else {
                                        alert(uploadFile.message);
                                      }
                                    } else {
                                      alert("파일이 선택되지 않았습니다.");
                                    }
                                  }}
                                />
                              }
                              onClick={() => { }}
                            />
                          }
                          titleSx={{
                            width: "200px",
                          }}
                          sx={{
                            width: "100%",
                          }}
                        />
                      </RowBox>
                    ))}
                  <Box>
                    <Typography
                      variant="body2"
                      sx={{
                        color: "#7171dd",
                      }}
                    >
                      ⚠ 일부 확장자는 업로드 할 수 없습니다. (
                      {KeriConfig.DenyUploadFileExt.join(", ")}) <br />⚠ 500MB
                      이상 파일은 업로드 할 수 없습니다.
                    </Typography>
                  </Box>
                </React.Fragment>
              )}
            </React.Fragment>
          }
        />
      </LoadControlUnit>
      {editMode && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
          }}
        >
          <KeriActionButton
            label="취소"
            variant="contained"
            color="error"
            onClick={() => {
              setEditData(undefined);
              setEditMode(false);
            }}
          />

          <KeriActionButton
            label="적용"
            variant="contained"
            onClick={editTechService}
            sx={{
              ml: 1,
            }}
          />
        </Box>
      )}

      <React.Fragment>
        {techServiceData !== undefined && (
          <React.Fragment>
            <KeriSelectAnalysisPurposeModal
              open={purposeModalOpen}
              techServiceType={techServiceData.Type}
              onClose={() => {
                setPurposeModalOpen(false);
              }}
              submit={{
                onFilter: (item) => {
                  if (item.length === 0) {
                    return {
                      state: false,
                      message: "선택된 값이 없습니다.",
                    };
                  } else if (item.length > 1) {
                    return {
                      state: false,
                      message: "두개 이상 선택할 수 없습니다.",
                    };
                  }

                  return { state: true };
                },
                onSubmit: (item) => {
                  if (item !== undefined) {
                    setEditData({
                      ...editData!,
                      PurposeCode: item[0].purposeCode,
                      PurposeName: item[0].purposeName,
                    });
                  }
                },
              }}
            />

            <KeriSelectProductItem
              open={productModalOpen}
              onClose={() => setProductModalOpen(false)}
              onSubmit={(item) => {
                setEditData({
                  ...editData!,
                  ProductCode: item.productID!,
                  ProductName: item.productName!.productName!,
                });
              }}
            />

            {/* <KeriSelectProductItem
              open={productModalOpen}
              onClose={() => setProductModalOpen(false)}
              dataObject={{
                onSubmit: (item) => {
                  setEditData({
                    ...editData!,
                    ProductCode: item[0].productID!,
                    ProductName: item[0].productName!.productName!,
                  });
                },
              }}
              modalObject={{
                selectMode: "Single",
              }}
            /> */}

            <KeriSelectTestAttributeModel
              open={testAttributeModalOpen.state}
              showCategory={testAttributeModalOpen.type ?? "ALL"}
              onClose={() => setTestAttributeModalOpen({ state: false })}
              submit={{
                onFilter: (item) => {
                  if (item.length === 0) {
                    return {
                      state: false,
                      message: "선택된 값이 없습니다.",
                    };
                  } else {
                    if (testAttributeModalOpen.type === "TestItem") {
                      return { state: true };
                    } else {
                      if (item.length > 1) {
                        return {
                          state: false,
                          message: "두개 이상 선택할 수 없습니다.",
                        };
                      } else {
                        return { state: true };
                      }
                    }
                  }
                },
                onSubmit: (item) => {
                  if (item.length !== 0) {
                    if (testAttributeModalOpen.type === "TestItem") {
                      setEditData({
                        ...editData!,
                        TestItem:
                          item.map((x) => x.TestName!).length === 0
                            ? undefined
                            : item.map((x) => x.TestName!),
                      });
                    } else if (testAttributeModalOpen.type === "TestMethod") {
                      setEditData({
                        ...editData!,
                        TestMethod: item[0].TestName,
                      });
                    } else if (testAttributeModalOpen.type === "TestType") {
                      setEditData({
                        ...editData!,
                        TestType: item[0].TestName,
                      });
                    }
                  }
                },
              }}
            />
          </React.Fragment>
        )}
      </React.Fragment>
    </React.Fragment>
  );
});

//#endregion





//#region 시뮬레이션 현황

interface ITechServiceSimulationComponent extends IComponentRefresh {
  TechServiceID: string;
}

const TechServiceSimulationComponent = React.forwardRef<
  IForwardFunc,
  ITechServiceSimulationComponent
>((prop, ref) => {
  const userRedux = useLoginCheck();
  const navigate = useNavigate();
  const location = useLocation();

  const [loadControl, setLoadControl] = useState<ILoadControl>(InitLoadControl);
  const [simulationData, setSimulationData] = useState<IKeriTechServiceSimulation[]>([]);

  const [openSimulationFileUploadModal, setOpenSimulationFileUploadModal] =
    useState<{ state: boolean; ord?: number }>({ state: false });

  // const statusColor: {
  //   key: TechServiceSimulationStatus;
  //   state?: boolean;
  //   value: string;
  // }[] = [
  //     {
  //       key: "New",
  //       value: "gray",
  //     },
  //     {
  //       key: "Approval",
  //       value: "",
  //     },
  //     {
  //       key: "Complete",
  //       state: false,
  //       value: "red",
  //     },
  //     {
  //       key: "Complete",
  //       state: true,
  //       value: "green",
  //     },
  //     {
  //       key: "Request",
  //       value: "darkblue",
  //     },
  //   ];

  const getTechServiceSimulation = async () => {
    setLoadControl({
      isLoading: true,
      isComplete: false,
    });

    const getData =
      await RequestKeriAPI.TechService.Simulation.GetSimulationList(
        userRedux?.token,
        prop.TechServiceID
      );

    if (getData.state) {
      setSimulationData(getData.resultData);

      setLoadControl({
        isLoading: false,
        isComplete: true,
      });
    } else {
      setLoadControl({
        isLoading: false,
        isComplete: true,
        errorMessage: getData.message,
      });
    }
  };

  const addTechServiceSimulation = async () => {
    setLoadControl({
      isLoading: true,
      isComplete: false,
    });

    const addSimulation =
      await RequestKeriAPI.TechService.Simulation.AddSimulation(
        userRedux?.token,
        prop.TechServiceID
      );

    if (addSimulation.state) {
      getTechServiceSimulation();
    } else {
      setLoadControl({
        isLoading: false,
        isComplete: true,
        errorMessage: addSimulation.message,
      });
    }
  };

  const deleteTechServiceSimulation = async (simulOrder: number) => {
    setLoadControl({
      isLoading: true,
      isComplete: false,
    });

    const deleteSimulation =
      await RequestKeriAPI.TechService.Simulation.DeleteSimulation(
        userRedux?.token,
        prop.TechServiceID,
        simulOrder
      );

    if (deleteSimulation.state) {
      getTechServiceSimulation();
    } else {
      setLoadControl({
        isLoading: false,
        isComplete: true,
        errorMessage: deleteSimulation.message,
      });
    }
  };

  useImperativeHandle(ref, () => ({
    refresh: () => {
      getTechServiceSimulation();
    },
  }));

  useEffect(() => {
    if (
      prop.TechServiceID !== undefined &&
      (userRedux?.loadComplete ?? false)
    ) {
      getTechServiceSimulation();
    }

    // eslint-disable-next-line
  }, [prop.TechServiceID, userRedux]);

  return (
    <React.Fragment>
      {
        loadControl.isLoading === true &&
        <Box
          sx={{
            width: '100%',
            minHeight: '150px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <CircularProgress sx={{ color: 'black' }} />
        </Box>
      }
      {
        (loadControl.isLoading === false && loadControl.errorMessage !== undefined) &&
        // <Box
        //   sx={{
        //     width: '100%',
        //     minHeight: '200px',
        //     display: 'flex',
        //     alignItems: 'center',
        //     justifyContent: 'center',
        //     flexDirection: 'column'
        //   }}
        // >
        //   <WarningRounded fontSize="large" sx={{ mb: 1 }} />
        //   <Typography>
        //     데이터 읽어올 수 없습니다.
        //   </Typography>
        //   <IconButton
        //     onClick={() => getTechServiceSimulation()}
        //     sx={{
        //       mt: 2,
        //       borderRadius: '10px'
        //     }}
        //   >
        //     <Refresh />
        //     <Typography sx={{ ml: 1 }}>
        //       다시 로드
        //     </Typography>
        //   </IconButton>
        // </Box>

        <MISCTechServiceSectionErrorBox
          errorMessage={loadControl.errorMessage}
          refreshFunction={() => getTechServiceSimulation()}
        />
      }

      {
        (loadControl.isLoading === false && loadControl.isComplete === true && loadControl.errorMessage === undefined) &&
        <React.Fragment>
          <Box>

          </Box>
          <Box>
            <Box>

            </Box>
            <SimulationInfoList>
              {
                simulationData.filter(x => x !== undefined).sort((x, y) => (y.order ?? 1) - (x.order ?? 0)).map((simulItem, idx, arr) => (
                  <SimulationInfoItem
                    item={simulItem}
                    defaultOpen={Math.max(...simulationData.map(x => x.order ?? -1)) === (simulItem.order ?? -1)}
                    onDelete={(item) => {
                      if (item.order !== undefined) {
                        var comf = window.confirm('시뮬레이션을 삭제하시겠습니까?\n삭제 이후 복구가 불가능합니다.');
                        if (comf === true) deleteTechServiceSimulation(item.order);
                      }
                    }}
                    onRefresh={prop.refreshPage}
                    isLastItem={idx === 0}
                  />
                ))
              }
              {
                simulationData.filter(x => x !== undefined).length === 0 &&
                <Box
                  sx={{
                    width: '100%',
                    minHeight: '200px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column'
                  }}
                >
                  <WarningRounded fontSize="large" sx={{ mb: 1 }} />
                  <Typography>
                    시뮬레이션 데이터가 없습니다.
                  </Typography>
                  <IconButton
                    onClick={() => getTechServiceSimulation()}
                    sx={{
                      mt: 2,
                      borderRadius: '10px'
                    }}
                  >
                    <Refresh />
                    <Typography sx={{ ml: 1 }}>
                      다시 로드
                    </Typography>
                  </IconButton>
                </Box>
              }
            </SimulationInfoList>
          </Box>
        </React.Fragment>
      }
    </React.Fragment>

  );
});

//#endregion

//#region 기술지원 기대 효과

interface ITechServiceEffectComponent extends IComponentRefresh {
  TechServiceID: string;
}

const TechServiceEffectComponent = React.forwardRef<
  IForwardFunc,
  ITechServiceEffectComponent
>((prop: ITechServiceEffectComponent, ref) => {
  const [loadControl, setLoadControl] = useState<ILoadControl>(InitLoadControl);
  const userRedux = useLoginCheck();
  const [techServiceData, setTechServiceData] = useState<
    ITechServiceInfoData | undefined
  >(undefined);

  const getTechServiceData = async () => {
    setLoadControl({
      isLoading: true,
      isComplete: false,
    });

    const getData = await RequestKeriAPI.TechService.GetTechServiceInfo(
      userRedux?.token,
      prop.TechServiceID
    );

    if (getData.state) {
      setTechServiceData(getData.resultData);

      setLoadControl({
        isLoading: false,
        isComplete: true,
      });
    } else {
      setLoadControl({
        isLoading: false,
        isComplete: true,
        errorMessage: getData.message,
      });
    }
  };

  useImperativeHandle(ref, () => ({
    refresh: () => {
      getTechServiceData();
    },
  }));

  useEffect(() => {
    if (
      prop.TechServiceID !== undefined &&
      (userRedux?.loadComplete ?? false)
    ) {
      getTechServiceData();
    }

    // eslint-disable-next-line
  }, [userRedux, prop.TechServiceID]);

  return (
    <LoadControlUnit
      isLoading={loadControl.isLoading && !loadControl.isComplete}
      errorMessage={loadControl.errorMessage}
      loadComponent={<LoadingBox />}
      errorComponent={
        <MISCTechServiceSectionErrorBox
          errorMessage={loadControl.errorMessage}
          refreshFunction={() => {
            getTechServiceData();
          }}
        />
      }
    >
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "1px solid rgba(0,0,0,0.3)",
            ":nth-last-of-type(1)": {
              borderBottom: "none",
            },
          }}
        >
          <Box
            sx={{
              width: "230px",
            }}
          >
            <Typography>항목</Typography>
          </Box>
          <Box
            sx={{
              width: "calc(100% - 230px)",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              p: 1,
            }}
          >
            <Box
              sx={{
                width: "calc(100% - 10px)",
                textAlign: "center",
              }}
            >
              지원 후 기대효과
            </Box>
          </Box>
        </Box>
        {techServiceData?.TechServiceData?.RequestEffect?.AfterSupport ===
          undefined ||
          techServiceData?.TechServiceData?.RequestEffect?.BeforeSupport ===
          undefined
          ? undefined
          : Object.keys(
            techServiceData?.TechServiceData?.RequestEffect?.AfterSupport
          ).map((objItem) => {
            const aftVal = Object.entries(
              techServiceData!.TechServiceData!.RequestEffect!.AfterSupport!
            ).find((x) => x[0] === objItem);
            const befVal = Object.entries(
              techServiceData!.TechServiceData!.RequestEffect!.BeforeSupport!
            ).find((x) => x[0] === objItem);

            const aftRealVal =
              aftVal !== undefined && (aftVal ?? []).length === 2
                ? aftVal[1]
                : undefined;
            const befRealVal =
              befVal !== undefined && (befVal ?? []).length === 2
                ? befVal[1]
                : undefined;

            return aftRealVal === undefined &&
              befRealVal === undefined ? undefined : (
              <Box
                key={ObjectUtils.RandomStrings(10)}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderBottom: "1px solid rgba(0,0,0,0.1)",
                  ":nth-last-of-type(1)": {
                    borderBottom: "none",
                  },
                }}
              >
                <Box
                  sx={{
                    width: "230px",
                  }}
                >
                  <Typography>
                    {`${KeriObjectUtil.CastTechServiceEffectToKorean(
                      objItem
                    )} `}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: "calc(100% - 230px)",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    p: 1,
                  }}
                >
                  {/* <Box
                                        sx={{
                                            width: 'calc(50% - 10px)',
                                            textAlign: 'center'
                                        }}
                                    >
                                        {`${aftVal === undefined ? 'und' : aftVal[1]} ${KeriObjectUtil.CastTechServiceEffectToUnit(objItem)}`}
                                    </Box>
                                    <Box
                                        sx={{
                                            width: '20px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <ArrowForward />
                                    </Box> */}
                  <Box
                    sx={{
                      width: "calc(100% - 10px)",
                      textAlign: "center",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography sx={{ mr: 1, fontWeight: "bold" }}>
                      {`${befVal === undefined ? "und" : befVal[1]} `}
                    </Typography>
                    <Typography>
                      {`${KeriObjectUtil.CastTechServiceEffectToUnit(
                        objItem
                      )}`}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            );
          })}
      </Box>
    </LoadControlUnit>
  );
});

//#endregion

//#region 기술지원 완료

interface ITechServiceCompleteComponent extends IComponentRefresh {
  TechServiceID: string;
}

const TechServiceCompleteComponent = React.forwardRef<
  IForwardFunc,
  ITechServiceCompleteComponent
>((prop, ref) => {
  const userRedux = useLoginCheck();
  const [loadControl, setLoadControl] = useState<ILoadControl>(InitLoadControl);
  const [techServiceData, setTechServiceData] = useState<
    ITechServiceInfoData | undefined
  >(undefined);
  const [completeDocs, setCompleteDocs] = useState<string | undefined>(
    undefined
  );

  const [surveyModalOpen, setSurveyModalOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();

  const getTechServiceData = async () => {
    setLoadControl({
      isLoading: true,
      isComplete: false,
    });

    const getData = await RequestKeriAPI.TechService.GetTechServiceInfo(
      userRedux?.token,
      prop.TechServiceID
    );

    if (getData.state) {
      // let applyData = {...getData.resultData};
      // setTechServiceData(applyData);
      setTechServiceData(getData.resultData);

      setLoadControl({
        isLoading: false,
        isComplete: true,
      });
    } else {
      setLoadControl({
        isLoading: false,
        isComplete: true,
        errorMessage: getData.message,
      });
    }
  };

  useImperativeHandle(ref, () => ({
    refresh: () => {
      getTechServiceData();
    },
  }));

  useEffect(() => {
    if (
      prop.TechServiceID !== undefined &&
      (userRedux?.loadComplete ?? false)
    ) {
      setCompleteDocs(undefined);
      getTechServiceData();
    }
  }, [userRedux, prop.TechServiceID]);

  return (
    <LoadControlUnit
      isLoading={loadControl.isLoading && !loadControl.isComplete}
      errorMessage={loadControl.errorMessage}
      loadComponent={<LoadingBox />}
      errorComponent={
        <MISCTechServiceSectionErrorBox
          errorMessage={loadControl.errorMessage}
          refreshFunction={() => {
            getTechServiceData();
          }}
        />
      }
    >
      {/* {
                // techServiceData?.TechServiceData?.RequestStatus === 'Complete' &&
                true &&
                <Box
                    sx={{
                        mb: 2
                    }}
                >
                    <Box>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                p: 1
                            }}
                        >
                            <Typography
                                variant="body1"
                                sx={{
                                    fontSize: '1.2rem',
                                    fontWeight: '700'
                                }}
                            >
                                완료확인서 샘플 다운로드
                            </Typography>
                        </Box>
                        <Divider />

                    </Box>
                    <Box>
                        <Box className="TechService-Complete-Docs-Files" sx={techServiceData?.TechServiceData?.RequestStatus === 'Complete' ? { pt: 1, pb: 1 } : {}}>
                            {
                                (techServiceData?.TechServiceData?.RequestInputFile ?? []).filter(x => categoryFilter('completeDocs', x.Category)).length === 0 &&
                                <NoDataBox
                                    refreshFunction={getTechServiceData}
                                />
                            }
                            {
                                techServiceData?.TechCompleteConfirm?.CompleteDocs?.map(fileItem => (
                                    // (techServiceData?.TechServiceData?.RequestInputFile ?? []).filter(x => categoryFilter('completeDocs', x.Category)).map(fileItem => (
                                    <RowBox
                                        key={ObjectUtils.RandomStrings(10)}
                                    >
                                        <ItemBox
                                            title={fileItem.Label}
                                            content={
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent: 'start',
                                                        alignItems: "center",
                                                        transition: '0.3s all cubic-bezier(0,0,0,1)',
                                                        ...((fileItem.UploadedFile !== undefined && techServiceData?.TechServiceData?.RequestStatus === 'Complete') ? {
                                                            cursor: 'pointer',
                                                            ":hover": {
                                                                color: '#7d7dc1'
                                                            }
                                                        } : { cursor: 'default' })

                                                    }}
                                                    onClick={async () => {
                                                        if (fileItem.UploadedFile !== undefined && techServiceData?.TechServiceData?.RequestStatus === 'Complete') {
                                                            const downloadData = await RequestKeriAPI.File.DownloadFileUsingAtchFileID(userRedux?.token, fileItem.UploadedFile?.atchFileId, parseInt(fileItem.UploadedFile?.fileSn ?? '-1'));

                                                            if (downloadData.state) {
                                                                const jsonFile = new Blob([downloadData.data!]);
                                                                const element = document.createElement("a");

                                                                element.href = URL.createObjectURL(jsonFile);
                                                                element.download = fileItem.UploadedFile?.orignlFileNm ?? 'undefined';
                                                                document.body.appendChild(element);
                                                                element.click();
                                                                element.remove();
                                                            }
                                                            else {
                                                                alert(downloadData.message);
                                                            }
                                                        }
                                                    }}
                                                >
                                                    {
                                                        fileItem.UploadedFile !== undefined ?
                                                            <React.Fragment>
                                                                {
                                                                    techServiceData?.TechServiceData?.RequestStatus === 'Complete' &&
                                                                    <Download
                                                                        sx={{
                                                                            mr: 1
                                                                        }}
                                                                    />
                                                                }
                                                                <Typography>
                                                                    {fileItem.UploadedFile?.orignlFileNm}
                                                                </Typography>
                                                                <Typography
                                                                    sx={{
                                                                        ml: 3,
                                                                        color: '#8f8a8a'
                                                                    }}
                                                                >
                                                                    {ObjectUtils.FileSizeFormater(fileItem.UploadedFile?.fileMg ?? 0)}
                                                                </Typography>
                                                            </React.Fragment>
                                                            :
                                                            <Box>
                                                                <Typography>
                                                                    파일 없음
                                                                </Typography>
                                                            </Box>
                                                    }

                                                </Box>
                                            }
                                            endContent={
                                                // techServiceData?.TechServiceData?.RequestStatus !== 'Complete' &&
                                                <KeriActionButton
                                                    label="파일 변경"
                                                    variant="outlined"
                                                    component={
                                                        <input
                                                            hidden
                                                            accept="*"
                                                            type='file'
                                                            onChange={async (e) => {
                                                                const fileArray: File[] = Array.from(e.target.files ?? []);

                                                                if (fileArray.length !== 0) {


                                                                    const fileSplit = fileArray[0].name.split('.');

                                                                    if (KeriConfig.DenyUploadFileExt.indexOf(fileSplit[fileSplit.length - 1]) > -1) {
                                                                        alert('업로드 할 수 없는 확장자입니다.');
                                                                        throw "업로드 할 수 없는 확장자입니다.";
                                                                    }
                                                                    else if (fileSplit.length === 1) {
                                                                        alert('확장자가 없는 파일은 업로드 할 수 없습니다.');
                                                                        throw '확장자가 없는 파일은 업로드 할 수 없습니다.';
                                                                    }
                                                                    else if (fileArray[0].size > 500 * 1024 * 1024) {
                                                                        alert('500MB 이상 업로드 할 수 없습니다.');
                                                                        throw '500MB 이상 업로드 할 수 없습니다.';
                                                                    }

                                                                    const uploadFile = await RequestKeriAPI.File.UploadFile({
                                                                        Index: fileItem.Index,
                                                                        Category: fileItem.Category,
                                                                        Prefix: fileItem.Prefix,
                                                                        Label: fileItem.Label,
                                                                        OriginFile: fileArray[0]
                                                                    });

                                                                    if (uploadFile.state) {
                                                                        let applyData = { ...techServiceData };

                                                                        if (uploadFile.data !== undefined) {
                                                                            applyData.TechServiceData && (applyData.TechServiceData.RequestInputFile = [
                                                                                ...(applyData.TechServiceData?.RequestInputFile?.filter(x => x.Index !== fileItem.Index) ?? []),
                                                                                uploadFile.data
                                                                            ]);
                                                                        }




                                                                        setTechServiceData(applyData);
                                                                        // let addFiles = [...editData?.AddFiles ?? []];
                                                                        // let deleteFiles = [...editData?.DeleteFiles ?? []];

                                                                        // uploadFile.data && addFiles.push(uploadFile.data);
                                                                        // fileItem.UploadedFile && deleteFiles.push(fileItem);

                                                                        // setEditData({
                                                                        //     ...editData!,
                                                                        //     AddFiles: addFiles,
                                                                        //     DeleteFiles: deleteFiles,
                                                                        //     Files: [
                                                                        //         ...((editData?.Files ?? []).filter(x => x.UploadedFile?.streFileNm !== fileItem.UploadedFile?.streFileNm)),
                                                                        //         ...(uploadFile.data === undefined ? [] : [uploadFile.data])
                                                                        //     ]
                                                                        // })

                                                                        // prop.refreshPage();
                                                                    }
                                                                    else {
                                                                        alert(uploadFile.message);
                                                                    }
                                                                }
                                                                else {
                                                                    alert('파일이 선택되지 않았습니다.')
                                                                }

                                                            }}
                                                        />
                                                    }
                                                    onClick={() => { }}
                                                />

                                            }
                                            titleSx={{
                                                width: '200px'
                                            }}
                                            sx={{
                                                width: '100%'
                                            }}
                                        />

                                    </RowBox>

                                ))
                            }
                            <Box>
                                {
                                    techServiceData?.TechServiceData?.RequestStatus !== 'Complete' &&
                                    <Typography
                                        variant='body2'
                                        sx={{
                                            color: '#7171dd'
                                        }}
                                    >
                                        ⚠ 일부 확장자는 업로드 할 수 없습니다. ({KeriConfig.DenyUploadFileExt.join(', ')}) <br />
                                        ⚠ 500MB 이상 파일은 업로드 할 수 없습니다.
                                    </Typography>
                                }

                            </Box>

                        </Box>


                    </Box>


                </Box>
            } */}

      {
        // (completeDocs === undefined && techServiceData?.TechServiceData?.RequestStatus !== 'Complete')

        techServiceData?.TechServiceData?.RequestStatus !== "Complete" ? (
          <React.Fragment>
            <Box>
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    p: 1,
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "1.2rem",
                      fontWeight: "700",
                    }}
                  >
                    제출 내용
                  </Typography>
                </Box>
                <Divider />
              </Box>

              <KeriEditGrid>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    p: 2,
                  }}
                >
                  <Box>
                    <Typography>완료확인서 양식</Typography>
                  </Box>
                  <Box>
                    <KeriActionButton
                      icon={<FileDownload />}
                      variant={"outlined"}
                      label="다운로드"
                      onClick={async () => {
                        const getDocs =
                          await RequestKeriAPI.TechService.DownloadTechServiceCompleteDocs(
                            prop.TechServiceID,
                            userRedux?.token
                          );

                        if (getDocs.state) {
                          if (getDocs.pdfFile !== undefined) {
                            const jsonFile = new Blob([getDocs.pdfFile]);
                            const element = document.createElement("a");

                            element.href = URL.createObjectURL(jsonFile);
                            element.download = `완료확인서_${prop.TechServiceID}.pdf`;
                            document.body.appendChild(element);
                            element.click();
                            element.remove();
                          } else {
                            alert();
                          }
                        } else {
                        }
                      }}
                    />
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    p: 2,
                  }}
                >
                  <Box>
                    <Typography>완료확인서</Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "end",
                    }}
                  >
                    <Typography
                      sx={{
                        mr: 2,
                        color: "gray",
                      }}
                    >
                      {techServiceData?.TechCompleteConfirm?.CompleteDocs?.filter(
                        (x) => x.Category === "TechServiceConfirmCompleteDocs"
                      )
                        .map((x) => x.OriginFile?.name)
                        .join(",")}
                    </Typography>
                    <KeriActionButton
                      icon={
                        (
                          techServiceData?.TechCompleteConfirm?.CompleteDocs ??
                          []
                        ).length !== 0 ? (
                          <CheckCircle color="success" />
                        ) : (
                          <UploadFile />
                        )
                      }
                      variant={"outlined"}
                      color={
                        (
                          techServiceData?.TechCompleteConfirm?.CompleteDocs ??
                          []
                        ).length !== 0
                          ? "success"
                          : "primary"
                      }
                      label="업로드"
                      onClick={() => { }}
                      component={
                        <input
                          hidden
                          accept="*"
                          type="file"
                          onChange={async (e) => {
                            const fileArray: File[] = Array.from(
                              e.target.files ?? []
                            );

                            if (fileArray.length !== 0) {
                              const fileSplit = fileArray[0].name.split(".");

                              if (
                                KeriConfig.DenyUploadFileExt.indexOf(
                                  fileSplit[fileSplit.length - 1]
                                ) > -1
                              ) {
                                alert("업로드 할 수 없는 확장자입니다.");
                                throw "업로드 할 수 없는 확장자입니다.";
                              } else if (fileSplit.length === 1) {
                                alert(
                                  "확장자가 없는 파일은 업로드 할 수 없습니다."
                                );
                                throw "확장자가 없는 파일은 업로드 할 수 없습니다.";
                              } else if (
                                fileArray[0].size >
                                500 * 1024 * 1024
                              ) {
                                alert("500MB 이상 업로드 할 수 없습니다.");
                                throw "500MB 이상 업로드 할 수 없습니다.";
                              }

                              setTechServiceData({
                                ...techServiceData,
                                TechCompleteConfirm: {
                                  ...techServiceData?.TechCompleteConfirm,
                                  CompleteDocs: fileArray.map((x) => ({
                                    Category: "TechServiceConfirmCompleteDocs",
                                    Label: "완료확인서",
                                    OriginFile: x,
                                    Index: Date.now(),
                                    Prefix: "CERT_",
                                  })),
                                },
                              });
                            } else {
                              alert("파일이 선택되지 않았습니다.");
                            }
                          }}
                        />
                      }
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    p: 2,
                  }}
                >
                  <Box>
                    <Typography>설문 평가</Typography>
                  </Box>
                  <Box>
                    <KeriActionButton
                      icon={
                        (
                          techServiceData?.TechCompleteConfirm?.ApplySurvey ??
                          []
                        ).length !== 0 ? (
                          <AssignmentTurnedIn color="success" />
                        ) : (
                          <AssignmentLate />
                        )
                      }
                      variant={"outlined"}
                      color={
                        (
                          techServiceData?.TechCompleteConfirm?.ApplySurvey ??
                          []
                        ).length !== 0
                          ? "success"
                          : "primary"
                      }
                      label="설문 조사"
                      onClick={() => setSurveyModalOpen(true)}
                    />
                  </Box>
                </Box>

                <KeriEditGridInputField
                  title="총평"
                  props={{
                    multiline: true,
                    height: "100px",
                  }}
                  value={
                    techServiceData?.TechCompleteConfirm?.ServiceReview ?? ""
                  }
                  onChange={(changeText) => {
                    let applyData = { ...techServiceData };

                    applyData.TechCompleteConfirm === undefined &&
                      (applyData.TechCompleteConfirm = {});

                    applyData.TechCompleteConfirm.ServiceReview = changeText;

                    setTechServiceData(applyData);
                  }}
                />

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    p: 2,
                  }}
                >
                  <Box>
                    <Typography>만족도 평가</Typography>
                  </Box>
                  <Box>
                    <Rating
                      value={
                        techServiceData?.TechCompleteConfirm?.ServiceRank ?? 0
                      }
                      max={5}
                      onChange={(e, value) => {
                        let applyData = { ...techServiceData };
                        applyData.TechCompleteConfirm === undefined &&
                          (applyData.TechCompleteConfirm = {});
                        applyData.TechCompleteConfirm.ServiceRank =
                          value ?? undefined;

                        setTechServiceData(applyData);
                      }}
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    p: 2,
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      color: "#7171dd",
                    }}
                  >
                    ⚠ 일부 확장자는 업로드 할 수 없습니다. (
                    {KeriConfig.DenyUploadFileExt.join(", ")}) <br />⚠ 500MB
                    이상 파일은 업로드 할 수 없습니다.
                  </Typography>
                </Box>
              </KeriEditGrid>
            </Box>
            {techServiceData?.TechServiceData?.RequestStatus ===
              "RstApproval" && (
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "center",
                      mt: 3,
                    }}
                  >
                    <KeriActionButton
                      label="초기화"
                      variant="contained"
                      color="error"
                      onClick={() => {
                        getTechServiceData();
                      }}
                      sx={{
                        mr: 1,
                        height: "40px",
                      }}
                    />
                    <KeriActionButton
                      label="적용"
                      variant="contained"
                      onClick={async () => {
                        let isError: boolean = false;
                        let errorList: string[] = [];

                        console.log(
                          techServiceData.TechCompleteConfirm?.CompleteDocs
                        );

                        if (
                          (
                            techServiceData.TechCompleteConfirm?.CompleteDocs ??
                            []
                          ).length === 0
                        ) {
                          isError = true;
                          errorList.push("완료확인서 업로드");
                        }

                        if (
                          (
                            techServiceData?.TechCompleteConfirm?.ApplySurvey ??
                            []
                          ).length === 0
                        ) {
                          isError = true;
                          errorList.push("설문 평가");
                        }

                        if (
                          techServiceData?.TechCompleteConfirm?.ServiceRank ===
                          undefined
                        ) {
                          isError = true;
                          errorList.push("만족도 평가");
                        }

                        if (isError) {
                          alert(
                            `아래 항목이 입력되지 않았습니다.\n${errorList
                              .map((x) => `  - ${x}`)
                              .join("\n")}`
                          );
                        } else {
                          // File Upload
                          prop.onLoading(true, '기술지원 완료 처리 중..')
                          setLoadControl({
                            isComplete: false,
                            isLoading: true,
                          });

                          const uploadCompleteDocs =
                            await RequestKeriAPI.File.UploadFile(
                              (techServiceData.TechCompleteConfirm
                                ?.CompleteDocs)![0]
                            );

                          if (uploadCompleteDocs.state) {
                            const uploadData =
                              await RequestKeriAPI.TechService.SetCompleteTechService(
                                userRedux?.token,
                                prop.TechServiceID,
                                uploadCompleteDocs.data?.UploadedFile?.streFileNm,
                                techServiceData?.TechCompleteConfirm
                              );

                            if (uploadData.state) {
                              setLoadControl({
                                isComplete: false,
                                isLoading: true,
                              });
                              alert("지원 완료 처리되었습니다.");
                              prop.refreshPage();
                            } else {
                              setLoadControl({
                                isComplete: false,
                                isLoading: true,
                              });

                              alert(uploadData.message);
                              if (!uploadData.tokenValid) {
                                navigate(
                                  `${URLStructure.UserLogin}?redirect=${location.pathname}${location.search}`
                                );
                              }
                            }
                          } else {
                            alert(
                              "완료확인서 업로드가 정상적으로 완료되지 않았습니다."
                            );
                            setLoadControl({
                              isComplete: false,
                              isLoading: true,
                            });
                          }
                          prop.onLoading(false)
                        }
                      }}
                      sx={{
                        height: "40px",
                      }}
                    />
                  </Box>
                </Box>
              )}
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Box>
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    p: 1,
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "1.2rem",
                      fontWeight: "700",
                    }}
                  >
                    기술지원 완료 및 평가 내용
                  </Typography>
                </Box>
                <Divider />
              </Box>

              <KeriEditGrid>
                {techServiceData.TechCompleteConfirm?.CompleteDocs?.map(
                  (fileItem) => (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                        p: 2,
                      }}
                    >
                      <Box>
                        <Typography>{fileItem.Label}</Typography>
                      </Box>
                      <Box>
                        <KeriActionButton
                          icon={<FileDownload />}
                          variant={"outlined"}
                          label="다운로드"
                          onClick={async () => {
                            const getDocs =
                              await RequestKeriAPI.File.DownloadFileUsingAtchFileID(
                                userRedux?.token,
                                fileItem.UploadedFile?.atchFileId,
                                parseInt(fileItem.UploadedFile?.fileSn ?? "-1")
                              );

                            if (getDocs.state) {
                              if (getDocs.data !== undefined) {
                                const jsonFile = new Blob([getDocs.data]);
                                const element = document.createElement("a");

                                element.href = URL.createObjectURL(jsonFile);
                                element.download =
                                  fileItem.UploadedFile?.orignlFileNm ?? "";
                                document.body.appendChild(element);
                                element.click();
                                element.remove();
                              } else {
                                alert();
                              }
                            } else {
                            }
                          }}
                        />
                      </Box>
                    </Box>
                  )
                )}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    p: 2,
                  }}
                >
                  <Box>
                    <Typography>만족도 평가</Typography>
                  </Box>
                  <Box>
                    <Rating
                      value={
                        techServiceData?.TechCompleteConfirm?.ServiceRank ?? 0
                      }
                      max={5}
                      disabled
                      sx={{
                        opacity: `1 !important`,
                      }}
                    />
                  </Box>
                </Box>
              </KeriEditGrid>
            </Box>
          </React.Fragment>
        )
      }

      {/* Modal */}
      <KeriTechServiceSurveyModal
        open={surveyModalOpen}
        onClose={() => setSurveyModalOpen(false)}
        onSubmit={(data, surveyId, surveyTempleteId) => {
          let applyData = { ...techServiceData };

          applyData.TechCompleteConfirm === undefined &&
            (applyData.TechCompleteConfirm = {});

          applyData.TechCompleteConfirm.ApplySurvey = data;
          applyData.TechCompleteConfirm.SurveyID = surveyId;
          applyData.TechCompleteConfirm.SurveyTempleteID = surveyTempleteId;

          setTechServiceData(applyData);
        }}
        value={techServiceData?.TechCompleteConfirm?.ApplySurvey ?? []}
        token={userRedux?.token}
        isEdit={techServiceData?.TechServiceData?.RequestStatus !== "Complete"}
      />
    </LoadControlUnit>
  );
});

//#endregion

//#region 댓글

interface ITechServiceReplyComponent extends IComponentRefresh {
  TechServiceID: string;
}

const TechServiceReplyComponent = React.forwardRef<
  IForwardFunc,
  ITechServiceReplyComponent
>((prop, ref) => {
  const userRedux = useLoginCheck();
  const [loadControl, setLoadControl] = useState<ILoadControl>(InitLoadControl);
  const [commentList, setCommentList] = useState<ITechServiceComment[]>([]);

  const getComment = async () => {
    setLoadControl({
      isLoading: true,
      isComplete: false,
    });

    const getData =
      await RequestKeriAPI.TechService.Comment.GetTechServiceCommentList(
        userRedux?.token,
        prop.TechServiceID
      );

    if (getData.state) {
      setCommentList(getData.resultData);

      setLoadControl({
        isLoading: false,
        isComplete: true,
      });
    } else {
      setLoadControl({
        isLoading: false,
        isComplete: true,
        errorMessage: getData.message,
      });
    }
  };

  useEffect(() => {
    if (
      prop.TechServiceID !== undefined &&
      (userRedux?.loadComplete ?? false)
    ) {
      getComment();
    }

    // eslint-disable-next-line
  }, [userRedux, prop.TechServiceID]);

  return (
    <LoadControlUnit
      isLoading={loadControl.isLoading && (loadControl.isComplete ?? false)}
      errorMessage={loadControl.errorMessage}
      loadComponent={<LoadingBox />}
      errorComponent={
        <MISCTechServiceSectionErrorBox
          errorMessage={loadControl.errorMessage}
          refreshFunction={() => {
            getComment();
          }}
        />
      }
    >
      {commentList !== undefined && (
        <React.Fragment>
          <ICTReplyBox
            value={commentList.sort((x, y) =>
              y.CommentDate! > x.CommentDate! ? -1 : 1
            )}
            displayProps={{
              displayText: (item) => item.CommentContent ?? "",
              displayUserName: (item) => item.CommentUserName ?? "",
              filterUserCode: (item) => item.CommentUserID ?? "",
              displayDate: (item) =>
                ObjectUtils.DateToString(
                  item.CommentDate,
                  "yyyy-MM-dd HH:mm:ss"
                ),
            }}
            onSubmit={async (changeText) => {
              const addComment =
                await RequestKeriAPI.TechService.Comment.AddTechServiceComment(
                  userRedux?.token,
                  prop.TechServiceID,
                  changeText?.replaceAll("\n", "<br />")
                );

              if (addComment.state) {
                await getComment();
              } else {
                setLoadControl({
                  isLoading: false,
                  isComplete: true,
                  errorMessage: addComment.message,
                });
              }
            }}
          />
        </React.Fragment>
      )}
    </LoadControlUnit>
  );
});

//#endregion

//#region 시뮬레이션 파일 업로드 Modal

interface IUploadSimulationFileModal {
  open: boolean;
  onClose: () => void;
  onSubmit: (state: boolean) => void;
  token?: string;
  techServiceID?: string;
  simulationOrder?: number;
}
const UploadSimulationFileModal = (prop: IUploadSimulationFileModal) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (prop.open) {
    } else {
      setIsLoading(false);
    }
  }, [prop.open]);

  return (
    <ICTBaseModal
      open={prop.open}
      onClose={prop.onClose}
      title="변수 파일 업로드"
    >
      <Box
        sx={{
          width: "400px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "200px",
          }}
        >
          {isLoading ? (
            <CircularProgress color="inherit" />
          ) : (
            <KeriActionButton
              label="파일 업로드"
              icon={<Upload />}
              onClick={() => { }}
              sx={{
                mb: 3,
              }}
              component={
                <input
                  hidden
                  accept="*"
                  type="file"
                  onChange={async (e) => {
                    const fileArray: File[] = Array.from(e.target.files ?? []);

                    if (fileArray.length !== 0) {
                      setIsLoading(true);
                      const uploadSimulationFile =
                        await RequestKeriAPI.TechService.Simulation.UploadSimulationFile(
                          prop.token,
                          prop.techServiceID,
                          prop.simulationOrder,
                          {
                            Index: 1,
                            Category: "inputSimulation",
                            Prefix: "TECH_SIMUL_",
                            Label: "시뮬레이션 입력 파일",
                            OriginFile: fileArray[0],
                          }
                        );

                      if (uploadSimulationFile.state) {
                        prop.onSubmit && prop.onSubmit(true);
                        prop.onClose && prop.onClose();
                      } else {
                        if (!uploadSimulationFile.tokenValid) {
                          alert("로그인이 필요합니다.");
                          navigate(
                            `${URLStructure.UserLogin}?redirect=${location.pathname}${location.search}`
                          );
                        } else {
                          alert(uploadSimulationFile.message);
                          prop.onSubmit && prop.onSubmit(false);
                          prop.onClose && prop.onClose();
                        }
                      }

                      setIsLoading(false);
                    } else {
                      alert("파일이 선택되지 않았습니다.");
                    }
                  }}
                />
              }
            />
          )}
        </Box>
      </Box>
    </ICTBaseModal>
  );
};

//#endregion

export default TechServiceItemViewPage;
