/* eslint-disable react-hooks/exhaustive-deps */
import { Paper, Box, CircularProgress, Typography, ButtonBase } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MISCModal } from "@ictpeople/misc-react";
import { useMutation, useQuery } from "react-query";
import { MISCCommonAPI } from "@ictpeople/misc-api";

interface IMainEventPopup {
    open: boolean,
    onClose: () => void
}

const POPUP_SESSION_KEY = 'keri-portal-popup';

const MainEventPopup = (prop: IMainEventPopup) => {

    const [selectItem, setSelectItem] = useState<{ url: string, link?: string } | undefined>(undefined);
    const [abortControl] = useState<AbortController>(new AbortController());
    const navigate = useNavigate();


    const getTemplateListQuery = useQuery(
        'template-list',
        () => MISCCommonAPI.Template.GetTemplateList({
            config: {
                abortSignal: abortControl.signal
            }
        }, process.env.REACT_APP_API_URL),
        {
            refetchOnWindowFocus: false,
            staleTime: 9999,
            onSuccess: (data) => {
                

                const findMainPopupList = data.templateList.find(x => x.uniqueId === 'MainNoticePopupTemplate');

                if (findMainPopupList === undefined) {
                    prop.onClose();
                }
                else {
                    if (findMainPopupList.atchFileId !== undefined) {
                        getEventListMutate.mutate({ atchFileId: findMainPopupList.atchFileId });
                    }
                    else {
                        alert('AtchFileId not found')
                    }

                }
            },
            onError: (err) => prop.onClose
        }
    )

    const getEventListMutate = useMutation(
        (prop: { atchFileId?: string }) => MISCCommonAPI.Template.GetTemplateDetailFileURL({
            templateId: 'MainNoticePopupTemplate',
            atchFileId: prop.atchFileId,
            config: {
                abortSignal: abortControl.signal
            }
        }, process.env.REACT_APP_KERI_API_URL),
        {
            onSuccess: (data) => {
                
                if (data.fileList.length === 0) {
                    prop.onClose();
                }
                else {
                    setSelectItem(data.fileList[0])
                }

            },
            onError: (err) => prop.onClose()
        }
    )


    const checkDailyDisable = (): Boolean => {
        try {
            const popupJsonObj = JSON.parse(sessionStorage.getItem(POPUP_SESSION_KEY) ?? '');
            
            if (popupJsonObj.skipday === false) return false;

            if (Date.now() - new Date(popupJsonObj.applyDate).setDate(new Date(popupJsonObj.applyDate).getDate() + 1) <= 0) {
                return true
            }
            else {
                return false;
            }
        }
        catch (e) {
            return false;
        }

    }


    const isLoading = getTemplateListQuery.isLoading || getEventListMutate.isLoading;



    useEffect(() => {


        if (prop.open === true) {
            if (!checkDailyDisable()) {
                getTemplateListQuery.refetch();
            }
            else {
                prop.onClose();
            }
        }
        else {
            getTemplateListQuery.remove();
        }
    }, [prop.open])

    useEffect(() => {
        return (() => {
            abortControl.abort();
        })
    }, [prop.open])


    return (
        <MISCModal
            open={prop.open}
            onClose={prop.onClose}
            paperProps={{
                elevation: 0,
                sx: {
                    width: '700px',
                    height: '80vh',
                    background: 'none',
                    p: 1
                }
            }}
        >
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    display: isLoading ? 'flex' : 'none',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <CircularProgress sx={{ color: 'black' }} />
            </Box>
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    display: isLoading ? 'none' : 'flex',
                    justifyContent: 'start',
                    alignItems: 'center',
                }}
            >
                <Paper
                    sx={{
                        width: '70%',
                        height: '100%'
                    }}
                >
                    {
                        (isLoading || selectItem === undefined)
                            ?
                            <Box
                                sx={{
                                    width: '100%',
                                    height: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                            >
                                <CircularProgress
                                    sx={{
                                        color: 'black'
                                    }}
                                />
                            </Box>
                            :
                            <MainImageViewer
                                imageSrc={selectItem.url}
                                hrefUrl={selectItem.link}
                                abortSignal={abortControl.signal}
                                onLink={(link) => {
                                    if (/^http(s?):\/\//g.test(link)) {
                                        window.open(link, '_blank', "noopener, noreferrer");
                                    }
                                    else {
                                        navigate(link);
                                    }
                                    prop.onClose();
                                }}
                            />
                    }

                </Paper>
                <Box
                    sx={{
                        width: '30%',
                        height: '100%'
                    }}
                >
                    <Box
                        sx={{
                            width: '100%',
                            height: '85%',
                            p: 0.5,
                            pt: 0,
                            pr: 0
                        }}
                    >
                        <Paper
                            sx={{
                                width: '100%',
                                height: '100%',
                                background : '#e5e5e5',
                                p : 1
                            }}
                        >
                            {
                                (isLoading)
                                    ?
                                    <Box
                                        sx={{
                                            width: '100%',
                                            height: '100%',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <CircularProgress
                                            sx={{
                                                color: 'black'
                                            }}
                                        />
                                    </Box>
                                    :
                                    <Box
                                        sx={{
                                            width: '100%',
                                            height: '100%',
                                            overflowY: 'scroll',
                                            scrollbarWidth: '1px',
                                            '::-webkit-scrollbar': {
                                                width: '5px',
                                                borderRadius: '3px'
                                            },
                                            '::-webkit-scrollbar-track': {
                                                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                                                '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
                                            },
                                            '::-webkit-scrollbar-thumb': {
                                                backgroundColor: 'rgba(0,0,0,0.5)',
                                                borderRadius: '10px'
                                            }
                                        }}
                                    >
                                        {
                                            getEventListMutate.data?.fileList.map(eventItem => (
                                                <SubImageViewer
                                                    imageSrc={eventItem.url}
                                                    hrefUrl={eventItem.link}
                                                    onClick={(url, link) => setSelectItem({
                                                        url: url,
                                                        link: link
                                                    })}
                                                    abortSignal={abortControl.signal}
                                                />
                                            ))
                                        }

                                    </Box>

                            }
                        </Paper>
                    </Box>
                    <Box
                        sx={{
                            width: '100%',
                            height: '15%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column'
                        }}
                    >
                        <ButtonBase
                            sx={{
                                background: 'black',
                                width: '90%',
                                height: "40px",
                                borderRadius: '20px',
                                color: 'white',
                                mb: 1
                            }}
                            onClick={() => {
                                sessionStorage.setItem(POPUP_SESSION_KEY, JSON.stringify({
                                    skipday: true,
                                    applyDate: Date.now()
                                }));
                                prop.onClose()
                            }}
                        >
                            <Typography
                                variant="subtitle1"
                                sx={{
                                    fontWeight: '500',
                                    fontFamily: 'Noto Sans KR , Roboto'
                                }}
                            >
                                하루동안 보지 않기
                            </Typography>
                        </ButtonBase>
                        <ButtonBase
                            sx={{
                                background: 'white',
                                width: '90%',
                                height: "40px",
                                borderRadius: '20px',
                                color: 'black',
                                transition: 'background 0.3s ease-out',
                                ":hover": {
                                    background: 'gray'
                                }
                            }}
                            onClick={prop.onClose}
                        >
                            <Typography
                                variant="subtitle1"
                                sx={{
                                    fontWeight: '500',
                                    fontFamily: 'Noto Sans KR , Roboto'
                                }}
                            >
                                팝업 닫기
                            </Typography>
                        </ButtonBase>
                    </Box>
                </Box>
            </Box >

        </MISCModal >
    )
}


const MainImageViewer = (prop: {
    imageSrc: string,
    hrefUrl?: string
    abortSignal?: AbortSignal,
    onLink?: (link: string) => void
}) => {
    return (
        <Box
            sx={{
                width: '100%',
                height: '100%'
            }}
        >
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: prop.hrefUrl === undefined ? undefined : 'pointer'
                }}
                onClick={() => (prop.onLink && prop.hrefUrl !== undefined) && prop.onLink(prop.hrefUrl)}
            >
                <img src={prop.imageSrc} alt="popupimage"
                    style={{
                        width: '100%'
                    }}
                />
            </Box>
        </Box>
    )
}

const SubImageViewer = (prop: {
    imageSrc: string,
    hrefUrl?: string
    onClick: (src: string, link?: string) => void,
    abortSignal?: AbortSignal
}) => {

    return (
        <Box
            sx={{
                width: '100%',
                p: 1,
                mb: 1,
            }}

        >
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    border: '1px solid rgba(0,0,0,0.3)',
                    borderRadius: '5px',
                    overflow: 'hidden',
                    cursor: 'pointer'
                }}
                onClick={() => prop.onClick(prop.imageSrc, prop.hrefUrl)}
            >
                <img src={prop.imageSrc} alt="popupimage"
                    style={{
                        width: '100%'
                    }}
                />
            </Box>
        </Box>
    )
}



export default MainEventPopup;