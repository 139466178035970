import { ObjectUtils } from "@ict-base/module/utils/ObjectUtils";
import useLoginCheck from "@keri-portal/module/hook/useLoginCheck";
import { Avatar, Typography, Divider, TextField, Box, BoxProps, TextFieldProps } from "@mui/material";
import { DefaultScrollDesign } from "@src/modules/styles/DefaultScrollDesign";
import { useEffect, useReducer, useRef, useState } from "react";
import KeriActionButton from "./KeriActionButton";

interface IReplyBox<T> {
    value: T[],
    displayProps: {
        displayText: (item: T) => string,
        displayUserName: (item: T) => string,
        filterUserCode: (item: T) => string,
        displayDate?: (item: T) => string | undefined,
    },
    onSubmit: (text?: string) => void
}

const ICTReplyBox = <T extends any>(prop: IReplyBox<T>) => {

    const userRedux = useLoginCheck();
    const [inputText, setInputText] = useState<string | undefined>(undefined);

    const replyBoxRef = useRef<HTMLDivElement>();
    const textFieldRef = useRef<TextFieldProps>();

    useEffect(() => {
        if (replyBoxRef.current !== undefined) {
            replyBoxRef.current.scrollTo({
                top: replyBoxRef.current.scrollHeight,
                // behavior : 'smooth'
            })
        }

    }, [prop.value])


    return (
        <Box>
            <Box
                sx={{
                    height: '300px',
                    maxHeight: '300px',
                    overflow: 'scroll',
                    overflowX: 'hidden',
                    ...DefaultScrollDesign
                }}
                ref={replyBoxRef}
            >
                <Box>
                    {
                        prop.value.map(item => (
                            <Box
                                key={ObjectUtils.RandomStrings(10)}
                                sx={{
                                    display: 'flex',
                                    justifyContent: userRedux?.resultVO?.uniqId === prop.displayProps.filterUserCode(item) ? 'end' : 'start',
                                    alignItems: 'center',
                                    mb: 1
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'end',
                                        alignItems: 'start',
                                        flexDirection: userRedux?.resultVO?.uniqId !== prop.displayProps.filterUserCode(item) ? 'row' : 'row-reverse'
                                    }}
                                >
                                    <Box
                                        sx={{
                                            p: 1,
                                            pt: 0.5,
                                        }}
                                    >
                                        <Avatar />
                                    </Box>

                                    <Box
                                        sx={{
                                            textAlign: userRedux?.resultVO?.uniqId !== prop.displayProps.filterUserCode(item) ? 'start' : 'end',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: userRedux?.resultVO?.uniqId !== prop.displayProps.filterUserCode(item) ? 'start' : 'end',
                                            }}
                                        >
                                            <Typography
                                                variant='body2'
                                                sx={{

                                                }}
                                            >
                                                {prop.displayProps.displayUserName(item)}

                                            </Typography>
                                            {
                                                prop.displayProps.displayDate && prop.displayProps.displayDate(item) &&
                                                <Typography
                                                    variant='body2'
                                                    sx={{
                                                        pl: 1,
                                                        pr: 1,
                                                        color: '#aaa'
                                                    }}
                                                >
                                                    ({prop.displayProps.displayDate(item)})
                                                </Typography>
                                            }
                                        </Box>


                                        <Box
                                            sx={{
                                                m: 0,
                                                mt: 0.4,
                                                display: 'flex',
                                                justifyContent: userRedux?.resultVO?.uniqId !== prop.displayProps.filterUserCode(item) ? 'start' : 'end',
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    m: 0,
                                                    background: userRedux?.resultVO?.uniqId !== prop.displayProps.filterUserCode(item) ? '#dfdfdf' : '#e7e359',
                                                    borderRadius: '10px',
                                                    width: 'fit-content',
                                                    p: 1,
                                                    pr: 2,
                                                    pl: 2,
                                                }}
                                            >
                                                {
                                                    prop.displayProps.displayText(item).split('<br />').map(splitItem => (
                                                        <Typography>
                                                            {splitItem}
                                                        </Typography>
                                                    ))
                                                }

                                            </Box>
                                        </Box>


                                    </Box>


                                </Box>

                            </Box>
                        ))
                    }
                </Box>
            </Box>
            <Divider
                sx={{
                    mt: 1,
                    mb: 1
                }}
            />
            <Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'end'
                    }}
                >
                    <Box
                        sx={{
                            width: 'calc(100% - 100px)'
                        }}
                    >
                        <TextField
                            size="small"
                            fullWidth
                            multiline
                            inputRef={textFieldRef}
                            onChange={(e) => {
                                setInputText(ObjectUtils.IsNullOrEmpty(e.currentTarget.value) ? undefined : e.currentTarget.value);
                            }}
                        />
                    </Box>
                    <Box
                        sx={{
                            width: '100px',
                            textAlign: 'center'
                        }}
                    >
                        <KeriActionButton
                            label="적용"
                            variant="contained"
                            sx={{
                                width: '90px',
                                m: 0
                            }}
                            onClick={() => {
                                if (!ObjectUtils.IsNullOrEmpty(inputText)) {
                                    prop.onSubmit(inputText);
                                    if (textFieldRef.current !== undefined) {
                                        textFieldRef.current.value = '';
                                        setInputText(undefined);
                                    }
                                }
                            }}
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default ICTReplyBox;