import { Typography, TextFieldProps, Box } from "@mui/material";
import { SetStateAction, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CallAPI } from "@ict-base/module/utils/CallAPI";
import { ObjectUtils } from "@ict-base/module/utils/ObjectUtils";
import KeriActionButton from "@ict-base/components/control/KeriActionButton";
import { KeriEditGrid, KeriEditGridInputField } from "@ict-base/components/control/KeriEditGrid";
import { IAPILoginRequestBody } from "@keri-portal/interfaces/api/keri-api/IKeriAPIRequest";
import { IAPILoginResult } from "@keri-portal/interfaces/api/keri-api/IKeriAPIResponse";
import { IPageProp } from "@keri-base/interface/common/IPageProp";
import { labelButtonStyle } from "@keri-portal/page/account/LoginViewPage";
import { setUser } from "@src/modules/redux/reducer/UserDataReducer";
import KeriLogo from "../../common/KeriLogo";
import URLStructure from "@src/modules/structure/URLStructure";
import RequestKeriAPI from "@keri-portal/module/method/api-connect/RequestKeriAPI";

interface ILoginComp extends IPageProp {
    pageControl: {
        activePage: number,
        setActivePage: React.Dispatch<React.SetStateAction<number>>
    },
    paramDictionary: any,
    apiStateControl: {
        state: boolean,
        setState: React.Dispatch<SetStateAction<boolean>>
    }

}

interface IErrData {
    id: [boolean, (string | undefined)],
    password: [boolean, (string | undefined)]
}

const LoginComp = (prop: ILoginComp) => {


    const navigate = useNavigate();

    //#region Redux 
    const userRedux = useSelector((state: any) => state.user);
    const dispatch = useDispatch();
    //#endregion

    const userNameRef = useRef<TextFieldProps>();
    const passwordRef = useRef<TextFieldProps>();

    const initErrData : IErrData= {
        id: [false, undefined],
        password: [false, undefined]
    }
    const [errData, setErrData] = useState<IErrData>(initErrData);

    const validationCheck = (): boolean => {

        let errObj = initErrData;
        let isErr = false;

        if(ObjectUtils.IsNullOrEmpty(userNameRef.current?.value as string | undefined | null)){
            errObj = {
                ...errObj,
                id : [true, '아이디가 빈 값입니다.']
            }

            isErr = true;
        }   

        if(ObjectUtils.IsNullOrEmpty(passwordRef.current?.value as string | undefined | null)){
            errObj = {
                ...errObj,
                password : [true, '비밀번호가 빈 값입니다.']
            }

            isErr = true;
        }

        if(isErr){
            setErrData(errObj);
        }

        return !isErr;
    }

    const handleSubmit = async () => {
        prop.EventFunction.EnableBackDrop(true);

        prop.apiStateControl.setState(true);

        if (validationCheck()) {

            const requestData = await RequestKeriAPI.Account.Login((userNameRef.current?.value as string)??'' , (passwordRef.current?.value as string)??'' ,  "GNR");

            if(requestData.state) {
                console.log('login',requestData);
                dispatch(setUser({
                    ...userRedux,
                    token: requestData.token,
                    resultVO: requestData.data,
                    expireData: requestData.expireDate,
                    loadComplete : true
                }));
                prop.EventFunction.EnableBackDrop(false);
                navigate(prop.paramDictionary.redirect ?? URLStructure.Main, { replace: true })
            }
            else{
                alert(requestData.message);
                prop.EventFunction.EnableBackDrop(false);
            }
        }
        else{
            prop.EventFunction.EnableBackDrop(false);
        }


    };


    return (
        <Box>
            <Box>
                <KeriLogo style={{ width: '90%'}} moveURL={URLStructure.Main} />
            </Box>
            <Box sx={{ mb: 3, mt: 2 }}>
                <Typography variant='h6' sx={{ fontWeight: 'bold', userSelect: 'none', textTransform: 'uppercase' }}>
                    로그인
                </Typography>
                <Typography variant='body1' letterSpacing={-0.1} lineHeight={1.2} sx={{ mt: 1, color: '#75868f', userSelect: 'none', fontSize: '0.8rem' }}>
                    방문을 환영합니다! <br />
                    로그인 ID와 비밀번호를 입력해주세요.
                </Typography>
            </Box>
            <Box>
                <KeriEditGrid
                    sx={{
                        p: 0
                    }}
                >
                    <KeriEditGridInputField
                        disableTitle
                        props={{
                            placeHolder: 'User name',
                            sx: {
                                p: 0
                            }
                        }}
                        inputRef={userNameRef}
                        onKeyUp={(e) => {
                            if (e.key === 'Enter') {
                                handleSubmit();
                            }
                        }}
                        onChange={() => {
                            if(errData.id[0]){
                                setErrData({
                                    ...errData,
                                    id : [false ,undefined]
                                })
                            }
                            
                        }}
                        error={{
                            isError : errData.id[0],
                            message : errData.id[1]
                        }}
                    />

                    <KeriEditGridInputField
                        disableTitle
                        type={'password'}
                        props={{
                            placeHolder: 'Password',
                            sx: {
                                p: 0
                            }
                        }}
                        inputRef={passwordRef}
                        onKeyUp={(e) => {
                            if (e.key === 'Enter') {
                                handleSubmit();
                            }
                        }}
                        onChange={() => {
                            if(errData.password[0]){
                                setErrData({
                                    ...errData,
                                    password : [false ,undefined]
                                })
                            }
                            
                        }}
                        error={{
                            isError : errData.password[0],
                            message : errData.password[1]
                        }}
                    />
                </KeriEditGrid>


            </Box>
            <Box sx={{ mt: 3 }}>
                <KeriActionButton
                    label={"로그인"}
                    variant='contained'
                    fullWidth
                    sx={{
                        mt: 0
                    }}
                    onClick={() => handleSubmit()}
                />

                <KeriActionButton
                    label={"회원가입"}
                    variant='contained'
                    color="info"
                    sx={{
                        mt: 0
                    }}
                    fullWidth
                    onClick={() => navigate(URLStructure.UserRegister, { replace: true })}
                />

            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                <Typography sx={labelButtonStyle} onClick={() => prop.pageControl.setActivePage(1)}>아이디 찾기</Typography>
                <Typography sx={labelButtonStyle} onClick={() => prop.pageControl.setActivePage(2)}>비밀번호 초기화</Typography>
            </Box>
        </Box>
    )
}

export default LoginComp;