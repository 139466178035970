import { AccountCircle, AccountCircleOutlined, Campaign, Info, Login, ManageAccounts, Person, WindowSharp } from "@mui/icons-material";
import MenuIcon from '@mui/icons-material/Menu';
import { Avatar, Badge, Box, BoxProps, Button, ClickAwayListener, Divider, Drawer, Fade, IconButton, List, ListItem, ListItemText, Menu, MenuItem, Paper, Popper, SxProps, Theme, Tooltip, Typography, useScrollTrigger } from "@mui/material";
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate } from "react-router-dom";
import KeriMobileSideMenu from "@ict-base/components/control/KeriMobileSideMenu";
import { DesktopPage, MobilePage } from "@ict-base/module/hook/usePageMediaQuery";
import useWindowSize from "@ict-base/module/hook/useWindowSize";
import { IPageProp } from "@keri-base/interface/common/IPageProp";
import { ILoginSessionData, removeUser, setUser } from "@src/modules/redux/reducer/UserDataReducer";
import URLStructure from "@src/modules/structure/URLStructure";
import { IMenuItem } from "@keri-portal/interfaces/data-object/data-common/IMenu";
import useHeaderMenu from "@keri-portal/module/hook/useHeaderMenu";
import KeriUserProfilePopper from "../control/common/KeriUserProfilePopper";
import KeriLogo from "./KeriLogo";
import { ObjectUtils } from "@ict-base/module/utils/ObjectUtils";
import { useTimer } from "react-timer-hook";
import RequestKeriAPI from "@keri-portal/module/method/api-connect/RequestKeriAPI";
import useLoginCheck from "@keri-portal/module/hook/useLoginCheck";
import KeriUserMessagePopper from "../control/common/KeriUserMessagePopper";


interface IKeriHeader extends IPageProp {
    isMain: boolean,
    sx?: SxProps<Theme>
}


const SIDE_MENU_WIDTH = 180;


export const KeriHeaderHeight: number = 70;


const KeriHeaderLayout = (prop: {
    isTrans?: boolean,
    startColumn?: (prop: { isTrans: boolean }) => React.ReactNode,
    endColumn?: (prop: { isTrans: boolean }) => React.ReactNode,
    content?: (prop: { isTrans: boolean }) => React.ReactNode,
    disabled?: boolean,
    backgroundColor?: string,
    boxRef?: React.MutableRefObject<BoxProps | undefined>,
    boxProp?: {
        sx: SxProps<Theme>
    }

}) => {

    const navigate = useNavigate();

    return (
        <Box
            sx={{
                m: '0 auto',
                maxWidth: '1600px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                p: prop.disabled ? 0 : 2,
                pr: 2,
                pl: 2,
                height: prop.disabled ? '0px' : undefined,
                // opacity: prop.disabled ? 0 : 1,
                transition: '0.5s all cubic-bezier(0,0,0,1)',
                ...prop.boxProp?.sx
            }}
            ref={prop.boxRef}
        >
            <Box
                sx={{
                    width: SIDE_MENU_WIDTH,
                    textAlign: 'center',
                    color: prop.isTrans === undefined ? prop.backgroundColor : (prop.isTrans ? 'white' : undefined),
                    display: 'flex',
                    alignItems: 'center'
                }}
            >
                {prop.startColumn && prop.startColumn({ isTrans: prop.isTrans ?? false })}
            </Box>
            <Box
                sx={{
                    width: `calc(100% - ${SIDE_MENU_WIDTH * 2}px)`,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',

                    color: prop.isTrans ? 'white' : undefined
                }}
            >
                {prop.content && prop.content({ isTrans: prop.isTrans ?? false })}
            </Box>
            <Box
                sx={{
                    width: SIDE_MENU_WIDTH,
                    textAlign: 'end',
                    color: prop.isTrans ? 'white' : undefined
                }}
            >
                {prop.endColumn && prop.endColumn({ isTrans: prop.isTrans ?? false })}
            </Box>
        </Box>
    )
}

const KeriHeaderContentLayout = (prop: {
    children?: React.ReactNode[] | React.ReactNode,
    containerProp?: {
        containerItemAlign: 'start' | 'end' | 'center'
    }
}) => {
    return (
        <Box
            sx={{
                width: '80%',
                maxWidth: 1100,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: prop.containerProp?.containerItemAlign ?? 'center'
            }}
        >
            {prop.children}
        </Box>
    )
}

const KeriHeaderContentItemBox = (prop: {
    children?: React.ReactNode,
    itemCount: number,
    onClick?: React.MouseEventHandler<HTMLDivElement>,
    onMouseOver?: React.MouseEventHandler<HTMLDivElement>
}) => {
    return (
        <Box
            sx={{
                width: `${1 / (prop.itemCount === 0 ? 1 : prop.itemCount) * 100}%`,
                overflowWrap: 'break-word',
                textAlign: 'center',
                overflow: 'hidden'
            }}
            onClick={prop.onClick}
            onMouseOver={prop.onMouseOver}
        >
            {prop.children}
        </Box>
    )
}



const KeriHeader = (prop: IKeriHeader) => {


    const menuObj = useHeaderMenu();

    const [menuList, setMenuList] = useState<IMenuItem[]>([]);


    const [alertList, setAlertList] = useState<{
        message?: {
            techServiceID: string,
            techServiceTitle: string,
            commentList: {
                CommentID: string,
                CommentDate: Date,
                CommentContents: string
            }[]
        }[]
    }>({})



    const [scrollTrigger, setScrollTrigger] = useState(false);

    //#region Hover

    const [isPageExpend, setPageExpend] = useState(false);
    const [isSideMenuOpen, setSideMenuOpen] = useState<boolean>(false);

    //#endregion

    const navigate = useNavigate();

    //Redux 
    const userRedux = useLoginCheck();
    const dispatch = useDispatch();


    const location = useLocation();


    const fixedBoxRef = useRef<BoxProps>();


    const updateScroll = () => {
        if (window.scrollY > 0 && !scrollTrigger) {
            setScrollTrigger(window.scrollY > 20);
        }
        else if (window.scrollY > 0 && scrollTrigger) {
            setScrollTrigger(window.scrollY > 20);
        }
    }




    const logoutHandler = () => {
        if (userRedux?.token === undefined) {
            navigate(`${URLStructure.UserLogin}?redirect=${location.pathname}${location.search}`);
        }
        else {
            dispatch(removeUser());
        }
    }


    useEffect(() => {
        window.addEventListener('scroll', updateScroll);
    });

    useEffect(() => {
        setMenuList(menuObj);
    }, [menuObj])



    // User Profile Popper
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const openPopper = Boolean(anchorEl);
    const handlePopperOpenClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };
    const popperId = openPopper ? 'user-profile-anchor' : undefined;


    // User Message Popper
    const [anchorElMessage, setAnchorElMessage] = React.useState<null | HTMLElement>(null);
    const openPopperMessage = Boolean(anchorElMessage);
    // const openPopperMessage = Boolean(true);
    const handleMessagePopperOpenClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElMessage(anchorElMessage ? null : event.currentTarget);
    };
    const messagePopperId = openPopperMessage ? 'user-profile-anchor' : undefined;




    const addAlert = async (): Promise<{ state: boolean, authState: boolean }> => {
        const getList = await RequestKeriAPI.TechService.Comment.GetUnreadCommentList(userRedux?.token);
        if (getList.state) {
            setAlertList({
                message: getList.resultData,
            })
            return {
                state: true,
                authState: true
            }
        }
        else {
            return {
                state: false,
                authState: getList.tokenValid
            }
        }



    }


    useEffect(() => {

        if ((userRedux?.loadComplete ?? false) && (userRedux?.token !== undefined)) {
            let interval = setInterval(async () => {

                const alertUpdate = await addAlert();

                if (!alertUpdate.authState) {
                    clearInterval(interval)
                    alert('로그인이 필요합니다.');
                    dispatch(removeUser())
                    navigate(`${URLStructure.UserLogin}?redirect=${location.pathname}${location.search}`);
                }

            }, 3000)
            return () => clearInterval(interval)
        }
    }, [userRedux])


    return (
        <Box
            sx={{
                ...prop.sx,
                position: 'fixed',
                left: 0,
                top: 0,
                width: '100%',
                pr: 0,
                transition: '0.5s all cubic-bezier(0,0,0,1)',
                background: (prop.isMain && !scrollTrigger && !isPageExpend) ? 'none' : 'white',
            }}
            onMouseLeave={() => {
                setPageExpend(false);
            }}
        >
            <KeriHeaderLayout
                key='TopMenu'
                boxProp={{
                    sx: {
                        zIndex: 750
                    }
                }}
                boxRef={fixedBoxRef}
                isTrans={prop.isMain && !scrollTrigger && !isPageExpend}
                startColumn={(layoutProp) =>
                    <KeriLogo
                        isTop
                        moveURL={URLStructure.Main}
                        style={{
                            height: '30px'
                        }}
                    />
                }
                endColumn={(layoutProp) =>
                    <React.Fragment>
                        <DesktopPage>
                            {
                                userRedux?.token === undefined
                                    ?
                                    <Button
                                        variant="contained"
                                        sx={{
                                            background: 'none',
                                            border: layoutProp.isTrans ? '1px solid white' : '1px solid black',
                                            color: layoutProp.isTrans ? 'white' : 'black',
                                            borderRadius: 0,
                                            pl: 1,
                                            fontWeight: 'bold',
                                            '&:hover': {
                                                background: 'none',
                                                color: 'gray',
                                                border: '1px solid gray'
                                            },
                                            boxShadow: 'none'
                                        }}
                                        onClick={() => {
                                            navigate(`${URLStructure.UserLogin}?redirect=${location.pathname}${location.search}`);
                                        }}
                                    >
                                        <Login sx={{ p: 0, m: 0, mr: 1, fontSize: '18px' }} /> <Typography>로그인</Typography>
                                    </Button>
                                    :
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'end',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <Tooltip title="메세지">
                                            <IconButton
                                                sx={{
                                                    ml: 1
                                                }}
                                                onClick={handleMessagePopperOpenClick}
                                                aria-describedby={messagePopperId}
                                            >
                                                <Badge badgeContent={(alertList.message ?? []).length} color='primary'>
                                                    <Campaign
                                                        sx={{
                                                            color: prop.isMain && !scrollTrigger && !isPageExpend ? 'white' : undefined
                                                        }}
                                                    />
                                                </Badge>
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="사용자">
                                            <IconButton
                                                sx={{
                                                    ml: 1
                                                }}
                                                onClick={handlePopperOpenClick}
                                                aria-describedby={popperId}
                                            >
                                                <Badge color='primary'>
                                                    <Person
                                                        sx={{
                                                            color: prop.isMain && !scrollTrigger && !isPageExpend ? 'white' : undefined
                                                        }}
                                                    />
                                                </Badge>
                                            </IconButton>
                                        </Tooltip>
                                        <KeriUserProfilePopper
                                            id={popperId}
                                            open={openPopper}
                                            anchorEl={anchorEl}
                                            onClose={() => {
                                                setAnchorEl(null);
                                            }}
                                        />

                                        <KeriUserMessagePopper
                                            id={messagePopperId}
                                            open={openPopperMessage}
                                            anchorEl={anchorElMessage}
                                            value={alertList.message ?? []}
                                            onClose={() => {
                                                setAnchorElMessage(null);
                                            }}
                                        />


                                    </Box>

                            }



                        </DesktopPage>
                        <MobilePage>
                            <Box
                                sx={{
                                    width: '100%',
                                    textAlign: 'end'
                                }}
                            >
                                <IconButton
                                    onClick={() => {
                                        setSideMenuOpen(true);
                                    }}
                                >
                                    <MenuIcon
                                        sx={{
                                            color: layoutProp.isTrans ? 'white' : undefined
                                        }}
                                    />
                                </IconButton>
                            </Box>
                        </MobilePage>
                    </React.Fragment>

                }
                content={(layoutProp) =>
                    <React.Fragment>
                        <DesktopPage>
                            <KeriHeaderContentLayout>
                                {
                                    [...menuList].sort((x, y) => x.idx - y.idx).map(topItem =>
                                        <KeriHeaderContentItemBox
                                            key={ObjectUtils.RandomStrings(10)}
                                            itemCount={menuList.length}
                                            onMouseOver={() => {
                                                setPageExpend(true);
                                            }}
                                        >
                                            <Typography sx={{
                                                cursor: 'pointer',
                                                userSelect: 'none',
                                                fontWeight: 500
                                            }}>
                                                {topItem.title}
                                            </Typography>
                                        </KeriHeaderContentItemBox>
                                    )
                                }



                            </KeriHeaderContentLayout>
                        </DesktopPage>
                    </React.Fragment>
                }
            />



            <Divider />
            <DesktopPage>
                <KeriHeaderLayout
                    key='ExpendMenu'
                    backgroundColor="white"
                    disabled={!isPageExpend}
                    boxProp={{
                        sx: {
                            zIndex: 740,
                            overflow: 'hidden'
                        }
                    }}

                    content={(layoutProp) =>
                        <KeriHeaderContentLayout
                            containerProp={{
                                containerItemAlign: 'start'
                            }}
                        >
                            {
                                [...menuList].sort((x, y) => x.idx - y.idx).map(topItem =>
                                    <KeriHeaderContentItemBox
                                        key={ObjectUtils.RandomStrings(10)}
                                        itemCount={menuList.length}
                                        onMouseOver={() => {
                                            setPageExpend(true);
                                        }}
                                    >
                                        <Box>
                                            {
                                                [...(topItem.child ?? [])].sort((x, y) => x.idx - y.idx).map(childItem => (
                                                    <Box
                                                        key={ObjectUtils.RandomStrings(10)}
                                                        sx={{
                                                            p: 1,
                                                        }}
                                                        onClick={() => {
                                                            childItem.url && navigate(childItem.url);
                                                            setPageExpend(false);
                                                        }}
                                                    >
                                                        <Typography sx={{
                                                            cursor: 'pointer',
                                                            userSelect: 'none',
                                                            fontWeight: 500,
                                                            wordBreak: 'keep-all',
                                                            fontSize: layoutProp.isTrans ? 0 : undefined,
                                                            color: '#aaa',
                                                            transition: '0.5s all cubic-bezier(0,0,0,1)',
                                                            ":hover": {
                                                                color: 'black',
                                                            }
                                                        }}>
                                                            {childItem.title}
                                                        </Typography>
                                                    </Box>

                                                ))
                                            }
                                        </Box>


                                    </KeriHeaderContentItemBox>
                                )
                            }
                        </KeriHeaderContentLayout>
                    }
                />
            </DesktopPage>

            <MobilePage>
                <Drawer
                    anchor="right"
                    open={isSideMenuOpen}
                    onClose={() => {
                        setSideMenuOpen(false);
                    }}
                >
                    <KeriMobileSideMenu
                        menuObject={menuList}
                        isLogin={userRedux?.token !== undefined}
                        event={{
                            login: () => navigate(URLStructure.UserLogin),
                            logout: () => logoutHandler()
                        }}
                        onClose={() => setSideMenuOpen(false)}
                    />
                </Drawer>
            </MobilePage>




        </Box>
    )

}

export default KeriHeader;