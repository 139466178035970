import IJusoResult, { IJusoAddressData } from "@ict-base/interfaces/api/koera-juso/IJusoResult";
import { AxiosResponse } from "axios";
import CallAPI from "../utils/CallAPI";

interface IJusoAPI {
    /** 검색어 */
    searchText: string,
    /** 페이지 당 표시 아이템 수 */
    pageItemCount: number,
    /** 검색 페이지 */
    page: number
}

interface IJusoAPIResult {
    /** 응답 여부 */
    state: boolean,
    /** 응답 메세지 */
    resultMessage: string | undefined,
    /** 검색 주소 데이터 */
    data: IJusoAddressData[] | undefined,
    /** 검색 페이지 */
    nowPage: number,
    /** 전체 페이지 */
    totalPage: number,
    /** 전체 아이템 수 */
    totalCount: number,
    /** 페이지 당 표시 아이템 수 */
    pageItemCount: number,
}

/**
 * 행정안전부 주소 API 호출 Function
 */
const JusoAPI = async (prop: IJusoAPI): Promise<IJusoAPIResult> => {
    let state: boolean = false;
    let message: string | undefined = undefined;
    let data: IJusoAddressData[] | undefined = undefined;
    let totalCount: number = 0;


    await CallAPI<IJusoResult>({
        nonBaseURL: true,
        url: `https://www.juso.go.kr/addrlink/addrLinkApi.do?confmKey=${process.env.REACT_APP_JUSO_CONFM_KEY}&resultType=json&keyword=${prop.searchText}&countPerPage=${prop.pageItemCount}&currentPage=${prop.page}`,
        method: "GET"
    })
        .then((res: AxiosResponse<IJusoResult, any>) => {
            if (res.data.results.common.errorCode === "0") {
                state = true;
                data = res.data.results.juso as IJusoAddressData[];
                totalCount = parseInt(res.data.results.common.totalCount, 10);
            }
            else {
                state = false;
                message = res.data.results.common.errorMessage;
            }
        })
        .catch(err => {
            message = '서버와 연결이 원활하지 않습니다.'
        })

    return {
        state: state,
        resultMessage: message,
        data: data,
        nowPage: prop.page,
        pageItemCount: prop.pageItemCount,
        totalCount: totalCount,
        totalPage: Math.ceil(totalCount / prop.pageItemCount)
    }
}

export default JusoAPI;