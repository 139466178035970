import ICTCategorySearchModal, { ICTCategorySearchModalCategory } from "@ict-base/components/control/ICTCategorySearchModal";
import { ITestAttribute, TestAttribute } from "@keri-portal/interfaces/data-object/data-common/ITestAttribute";
import useLoginCheck from "@keri-portal/module/hook/useLoginCheck";
import RequestKeriAPI from "@keri-portal/module/method/api-connect/RequestKeriAPI";
import React, { useEffect, useState } from "react";

interface IKeriSelectTestAttributeModel {
    open : boolean,
    onClose : () => void,
    showCategory: TestAttribute | 'ALL',
    submit?: {
        onFilter: (item: ITestAttribute[]) => { state: boolean, message?: string },
        onSubmit: (item: ITestAttribute[]) => void,
    },
    defaultValue?: ITestAttribute[]
}

const KeriSelectTestAttributeModel = (prop: IKeriSelectTestAttributeModel) => {

    const [lazyLoad , setLazyLoad] = useState<boolean>(false);

    const userRedux = useLoginCheck();

    const [defValue, setDefValue] = useState<ITestAttribute[]>(prop.defaultValue ?? []);

    const [modalTitle, setModalTitle] = useState<string>('품목명/구분 관리');

    const [openCategory, setOpenCategory] = useState<string | undefined>(undefined);

    useEffect(() => {
        if(prop.open){
            setLazyLoad(true);
        }
        else{
            setLazyLoad(false);
        }
    },[prop.open])

    useEffect(() => {
        if(prop.open){
            setLazyLoad(true);
        }
    }, [defValue])

    useEffect(() => {
        setDefValue(prop.defaultValue??[])
    },[prop.defaultValue])

    useEffect(() => {
        if (prop.showCategory === 'ALL') {
            setModalTitle('시험 속성 관리');
        }
        else if (prop.showCategory === 'TestMethod') {
            setModalTitle('시험 방법 관리');
        }
        else if (prop.showCategory === 'TestType') {
            setModalTitle('시험 유형 관리');
        }
        else if (prop.showCategory === 'TestItem') {
            setModalTitle('시험 항목 관리');
        }
        else {
            setModalTitle('-');
        }
    }, [prop.showCategory])


    return (
        <React.Fragment>
            {
                lazyLoad &&
                <ICTCategorySearchModal
                    open={prop.open}
                    onClose={() => prop.onClose()}
                    title={modalTitle}
                    openCategory={(category) => { 
                        setOpenCategory(category)
                    }}
                >
                    <ICTCategorySearchModalCategory
                        enable={prop.showCategory === 'TestMethod'}
                        activeCategory={openCategory}
                        category="시험 방법"
                        dataLoad={{
                            getDataFunction: (page, searchText) => RequestKeriAPI.Attribute.GetTestAttribute(userRedux?.token,'TestMethod',searchText , page),
                            getDataArray: (item) => item.resultData ?? [],
                            getAPIState: {
                                state: (item) => item.state,
                                errorMessage: (item) => item.message
                            },
                            getPagination: {
                                nowPage: (item) => item.pageObject?.nowPage,
                                totalPage: (item) => item.pageObject?.totalPage
                            }
                        }}
                        display={{
                            displayItemTitle: (item) => item.TestName??'',
                            displayKey: (item) => `${item.TestName}-${item.TestType}`,
                            disableAddButton : true,
                            disableDeleteButton : true
                        }}
                        event={{
                            onSubmit: (item) => {
                                prop.submit?.onSubmit && prop.submit.onSubmit(item);
                                prop.onClose();
                            },
                            onClose: () => { prop.onClose() }
                        }}
                        selectData={{
                            selectFilter: (item) => prop.submit?.onFilter(item),
                            defaultValue: defValue
                        }}
                        // addData={{
                        //     addDataFunction: (addText) => RequestKeriAPI.Attribute.AddAnalysisPurpose(userRedux?.token, addText),
                        //     addAPIState: {
                        //         state: (item) => item.state,
                        //         errorMessage: (item) => item.message
                        //     }
                        // }}
                        // deleteData={{
                        //     deleteDataFunction: (item) => RequestKeriAPI.Attribute.DeleteAnalysisPurpose(userRedux?.token, item),
                        //     deleteAPIState: {
                        //         state: (item) => item.state,
                        //         errorMessage: (item) => item.message
                        //     }
                        // }}
                        elementProps={{
                            activeSubmit: true,
                        }}
                    />
                    <ICTCategorySearchModalCategory
                        enable={prop.showCategory === 'TestType'}
                        activeCategory={openCategory}
                        category="시험 유형"
                        dataLoad={{
                            getDataFunction: (page, searchText) => RequestKeriAPI.Attribute.GetTestAttribute(userRedux?.token,'TestType',searchText , page),
                            getDataArray: (item) => item.resultData ?? [],
                            getAPIState: {
                                state: (item) => item.state,
                                errorMessage: (item) => item.message
                            },
                            getPagination: {
                                nowPage: (item) => item.pageObject?.nowPage,
                                totalPage: (item) => item.pageObject?.totalPage
                            }
                        }}
                        display={{
                            displayItemTitle: (item) => item.TestName??'',
                            displayKey: (item) => `${item.TestName}-${item.TestType}`,
                            disableAddButton : true,
                            disableDeleteButton : true
                        }}
                        event={{
                            onSubmit: (item) => {
                                prop.submit?.onSubmit && prop.submit.onSubmit(item);
                                prop.onClose();
                            },
                            onClose: () => { prop.onClose() }
                        }}
                        selectData={{
                            selectFilter: (item) => prop.submit?.onFilter(item),
                            defaultValue: defValue
                        }}
                        // addData={{
                        //     addDataFunction: (addText) => RequestKeriAPI.Attribute.AddAnalysisPurpose(userRedux?.token, addText),
                        //     addAPIState: {
                        //         state: (item) => item.state,
                        //         errorMessage: (item) => item.message
                        //     }
                        // }}
                        // deleteData={{
                        //     deleteDataFunction: (item) => RequestKeriAPI.Attribute.DeleteAnalysisPurpose(userRedux?.token, item),
                        //     deleteAPIState: {
                        //         state: (item) => item.state,
                        //         errorMessage: (item) => item.message
                        //     }
                        // }}
                        elementProps={{
                            activeSubmit: true,
                        }}
                    />
                    <ICTCategorySearchModalCategory
                        enable={prop.showCategory === 'TestItem'}
                        activeCategory={openCategory}
                        category="시험 항목"
                        dataLoad={{
                            getDataFunction: (page, searchText) => RequestKeriAPI.Attribute.GetTestAttribute(userRedux?.token,'TestItem',searchText , page),
                            getDataArray: (item) => item.resultData ?? [],
                            getAPIState: {
                                state: (item) => item.state,
                                errorMessage: (item) => item.message
                            },
                            getPagination: {
                                nowPage: (item) => item.pageObject?.nowPage,
                                totalPage: (item) => item.pageObject?.totalPage
                            }
                        }}
                        display={{
                            displayItemTitle: (item) => item.TestName??'',
                            displayKey: (item) => `${item.TestName}-${item.TestType}`,
                            disableAddButton : true,
                            disableDeleteButton : true
                        }}
                        event={{
                            onSubmit: (item) => {
                                prop.submit?.onSubmit && prop.submit.onSubmit(item);
                                prop.onClose();
                            },
                            onClose: () => { prop.onClose() }
                        }}
                        selectData={{
                            selectFilter: (item) => prop.submit?.onFilter(item),
                            defaultValue: defValue
                        }}
                        // addData={{
                        //     addDataFunction: (addText) => RequestKeriAPI.Attribute.AddAnalysisPurpose(userRedux?.token, addText),
                        //     addAPIState: {
                        //         state: (item) => item.state,
                        //         errorMessage: (item) => item.message
                        //     }
                        // }}
                        // deleteData={{
                        //     deleteDataFunction: (item) => RequestKeriAPI.Attribute.DeleteAnalysisPurpose(userRedux?.token, item),
                        //     deleteAPIState: {
                        //         state: (item) => item.state,
                        //         errorMessage: (item) => item.message
                        //     }
                        // }}
                        elementProps={{
                            activeSubmit: true,
                        }}
                    />
                </ICTCategorySearchModal>
            }
        </React.Fragment>
    )
}

export default KeriSelectTestAttributeModel;