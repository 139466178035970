import useLoginCheck from "@keri-portal/module/hook/useLoginCheck";
import { ILoginSessionData } from "@src/modules/redux/reducer/UserDataReducer";
import URLStructure from "@src/modules/structure/URLStructure";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

interface ILoginChecker {
    children?: React.ReactNode | React.ReactNode[],
    onCheck : (state : 'LOGIN' | 'UNLOGIN', userData : ILoginSessionData) => void
}

const LoginChecker = (prop: ILoginChecker) => {

    const loginHook = useLoginCheck();

    useEffect(() => {

        if (loginHook !== undefined) {
            if(loginHook.loadComplete){
                if(loginHook.token === undefined){
                    prop.onCheck('UNLOGIN',loginHook);
                }
                else{
                    prop.onCheck('LOGIN',loginHook);
                }
            }
        }
        // eslint-disable-next-line
    }, [loginHook])


    return (
        <React.Fragment>
            {prop.children}
        </React.Fragment>
    )
}

export default LoginChecker;