import ICTAgreeGroup, { IICTAgreeItem } from "@ict-base/components/control/ICTAgreeGroup";
import { KeriCheckLabel } from "@ict-base/components/control/KeriCheckBox";
import KeriScrollBox from "@ict-base/components/control/KeriScrollBox";
import { IKeriStepShareProps, KeriStepItemLayout, KeriStepItemContent, KeriStepItemSubContent } from "@keri-portal/component/layout/StepLayout";
import { ITechServiceRequest } from "@keri-portal/interfaces/process-object/tech-service/ITechServiceRequest";
import TechServiceCommonValidation, { ITechServiceRequestAgreeError } from "@keri-portal/module/method/validation-check/tech-service/ITechServiceRequestCommonValidationCheck";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";




const KeriRegisterTechServiceAgreeItem = (prop: IKeriStepShareProps<ITechServiceRequest>) => {

    const location = useLocation();

    const [formData, setFormData] = useState<IICTAgreeItem[]>([

        {
            idx: 1,
            title: '개인정보 수집·이용 동의',
            htmlName: `pi-consent-1`,
            required: true
        },
        {
            idx: 2,
            title: '개인정보 제3자 제공 및 위탁 제공 동의',
            htmlName: `pi-consent-2`,
            required: true
        },
    ]);

    useEffect(() => {
        if (prop.result.data.TechServiceType !== undefined) {
            if (prop.result.data.TechServiceType === 'T') {
                setFormData([
                    ...formData,
                    {
                        idx: 3,
                        title: '시험 완료 후 시료는 신청 기업에서 모두 회수함',
                        // htmlName: `privacy`,
                        required: true
                    }
                ]);
            }

        }
    }, [prop.result.data.TechServiceType])

    const [errorData, setErrorData] = useState<ITechServiceRequestAgreeError>({});

    const validationCheck = async (): Promise<boolean> => {

        let isError: boolean = false;

        const validationMethod = await TechServiceCommonValidation.Agree({ data: formData });

        if (!validationMethod.state) {
            isError = !validationMethod.state;
            setErrorData(validationMethod.errorData);
        }

        return !isError;
    }


    return (
        <KeriStepItemLayout
            {...prop}
            validationCheck={validationCheck}
            step={prop.step}
            page={{
                moveNext: (setup, validation) => {
                    if (validation) {
                        prop.result.onChange({
                            ...prop.result.data,
                            RequestAgree: {
                                data: formData
                            }
                        })
                        setup(prop.step.activeStep + 1);
                    }
                }
            }}
        >
            <KeriStepItemContent
                title='정보제공 동의'
            >
                <ICTAgreeGroup
                    data={formData}
                    nowUrl={location.pathname}
                    event={{
                        onChange: (item) => {
                            setFormData(item)

                            if (errorData.Agree !== undefined) {
                                setErrorData({});
                            }
                        }
                    }}
                    error={{
                        isError: errorData.Agree !== undefined,
                        message: errorData.Agree
                    }}
                />
            </KeriStepItemContent>



        </KeriStepItemLayout>
    )
}

export default KeriRegisterTechServiceAgreeItem;