import KeriActionButton from "@ict-base/components/control/KeriActionButton";
import { ObjectUtils } from "@ict-base/module/utils/ObjectUtils";
import LoginChecker from "@keri-base/components/common/LoginChecker";
import { IPageProp } from "@keri-base/interface/common/IPageProp";
import { IKeriStepPhase, StepLayout } from "@keri-portal/component/layout/StepLayout";
import KeriRegisterTechServiceAgreeItem from "@keri-portal/component/step-item/tech-service/KeriRegisterTechServiceAgreeItem";
import KeriRegisterTechServiceCompleteItem from "@keri-portal/component/step-item/tech-service/KeriRegisterTechServiceCompleteItem";
import KeriRegisterTechServiceInfoItem from "@keri-portal/component/step-item/tech-service/KeriRegisterTechServiceInfoItem";
import KeriRegisterTechServiceRequestData from "@keri-portal/component/step-item/tech-service/KeriRegisterTechServiceRequestData";
import KeriRegisterTechServiceRequestFileItem from "@keri-portal/component/step-item/tech-service/KeriRegisterTechServiceRequestFileItem";
import KeriRegisterTechServiceSubmitItem from "@keri-portal/component/step-item/tech-service/KeriRegisterTechServiceSubmitItem";
import { ITechServiceRequest } from "@keri-portal/interfaces/process-object/tech-service/ITechServiceRequest";
import RequestKeriAPI from "@keri-portal/module/method/api-connect/RequestKeriAPI";
import { Alert, Box, Snackbar, Typography } from "@mui/material";
import { ILoginSessionData } from "@src/modules/redux/reducer/UserDataReducer";
import URLStructure from "@src/modules/structure/URLStructure";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";




const RegisterTechService = (prop: IPageProp) => {

    const [loginData, setLoginData] = useState<ILoginSessionData | undefined>(undefined);

    const [resultData, setResultData] = useState<ITechServiceRequest>({ RequestTempUniqueKey: `${ObjectUtils.DateToString(Date.now() , 'yyyyMMdd')}${ObjectUtils.RandomStrings(12)}` })
    const param = useLocation().search.replace('?', '').split('&').map(x => ({ key: x.split('=')[0], value: x.split('=')[1] }));
    const orgTechService = param.find(x => x.key === 'orgtech');
    const techTypeParam = param.find(x => x.key === 'type');
    const [loadData, setLoadData] = useState<{ loading: boolean, complete: boolean }>({ loading: false, complete: false });

    const [openSnack, setOpenSnack] = useState<boolean>(false);

    const navigate = useNavigate();
    const location = useLocation();



    const stepData: IKeriStepPhase<ITechServiceRequest>[] = [
        {
            idx: 1,
            title: '절차 안내',
            tabComponent: (obj) => {
                return (
                    <KeriRegisterTechServiceInfoItem
                        key={ObjectUtils.RandomStrings(10)}
                        result={obj.result}
                        step={obj.step}
                        routeProp={prop}
                    />

                )
            }
        },
        {
            idx: 2,
            title: '자료 제출',
            tabComponent: (obj) => {
                return (
                    <KeriRegisterTechServiceRequestFileItem
                        key={ObjectUtils.RandomStrings(10)}
                        result={obj.result}
                        step={obj.step}
                        routeProp={prop}
                    />
                )
            }
        },
        {
            idx: 3,
            title: '정보제공 동의',
            tabComponent: (obj) => {
                return (
                    <KeriRegisterTechServiceAgreeItem
                        key={ObjectUtils.RandomStrings(10)}
                        result={obj.result}
                        step={obj.step}
                        routeProp={prop}
                    />
                )
            }
        },
        {
            idx: 4,
            title: '상세 정보 입력',
            tabComponent: (obj) => {
                return (
                    <KeriRegisterTechServiceRequestData
                        key={ObjectUtils.RandomStrings(10)}
                        result={obj.result}
                        step={obj.step}
                        routeProp={prop}
                    />
                )
            }
        },
        {
            idx: 5,
            title: '기대효과 입력',
            tabComponent: (obj) => {
                return (
                    <KeriRegisterTechServiceSubmitItem
                        result={obj.result}
                        step={obj.step}
                        routeProp={prop}
                    />
                )
            }
        },
        {
            idx: 6,
            title: '신청 완료',
            tabComponent: (obj) => {
                return (
                    <KeriRegisterTechServiceCompleteItem
                        key={ObjectUtils.RandomStrings(10)}
                        result={obj.result}
                        step={obj.step}
                        routeProp={prop}
                    />
                )
            }
        },

    ]

    const loadOrgData = async () => {
        prop.EventFunction.EnableBackDrop(true);
        setLoadData({ loading: true, complete: false });
        const getData = await RequestKeriAPI.TechService.GetTechServiceInfo(loginData?.token, orgTechService?.value);

        if (getData.state) {

            console.error({
                RequestTempUniqueKey: `${ObjectUtils.DateToString(Date.now() , 'yyyyMMdd')}${ObjectUtils.RandomStrings(12)}`,
                TechServiceType: (techTypeParam === undefined || techTypeParam.value !== 'is') ? getData.resultData.TechServiceData?.RequestType : 'S',
                RequesterData: {
                    RequestType: getData.resultData.TechServiceData?.RequestType!,
                    RequestInputData: getData.resultData.TechServiceData?.RequestInputData,
                    RequesterData: getData.resultData.TechServiceData?.RequesterData
                },
                FixType: true
            });

            setResultData({
                RequestTempUniqueKey: `${ObjectUtils.DateToString(Date.now() , 'yyyyMMdd')}${ObjectUtils.RandomStrings(12)}`,
                TechServiceType: (techTypeParam === undefined || techTypeParam.value !== 'is') ? getData.resultData.TechServiceData?.RequestType : 'S',
                RequesterData: {
                    RequestType: getData.resultData.TechServiceData?.RequestType!,
                    RequestInputData: getData.resultData.TechServiceData?.RequestInputData,
                    RequesterData: getData.resultData.TechServiceData?.RequesterData
                },
                FixType: true
            })
        }
        else {
            setOpenSnack(true);
        }

        setLoadData({ loading: false, complete: true });
        prop.EventFunction.EnableBackDrop(false);
    }

    useEffect(() => {
        console.log(loadData);
        if (!loadData.loading && !loadData.complete) {
            if (loginData !== undefined) {
                if (loginData.token !== undefined) {
                    if (orgTechService?.value !== undefined) {

                        loadOrgData();

                    }
                    else {
                        setLoadData({ loading: false, complete: true });
                    }
                }
                else {
                    alert('로그인이 필요합니다.');
                    navigate(`${URLStructure.UserLogin}?redirect=${location.pathname}${location.search}`);
                }
            }
        }

        // eslint-disable-next-line
    }, [loginData])

    useEffect(() => {
        console.log('root data' , resultData);
    }, [resultData])




    return (
        <LoginChecker
            onCheck={(state, data) => {
                console.log('login state',state);
                if(state === 'LOGIN'){
                    setLoginData(data)
                }
                else if(state === 'UNLOGIN'){
                    alert('로그인이 필요합니다.');
                    navigate(`${URLStructure.UserLogin}?redirect=${location.pathname}${location.search}`);
                }
                
            }}
        >
            {
                loadData.complete &&
                <StepLayout
                    enableLogo
                    customLogo={
                        <Typography
                            sx={{
                                fontSize: '24px',
                                fontWeight: 'bold',
                                p: 1,
                                borderBottom: '3px groove #86918585'
                            }}
                        >
                            기술지원 신청
                        </Typography>
                    }
                    stepData={stepData}
                    result={{
                        data: resultData,
                        onChange: (item) => setResultData(item)
                    }}
                />
            }

            {
                orgTechService !== undefined &&
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    open={openSnack}
                >
                    <Alert onClose={() => setOpenSnack(false)} severity="error" sx={{ width: '100%' }}>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }}
                        >
                            <Typography variant="body2">
                                {`원본 기술지원(${orgTechService.value})을 찾을 수 없습니다.`}
                            </Typography>
                            <KeriActionButton
                                label="이전 페이지로 이동"
                                onClick={() => navigate(-1)}
                                sx={{
                                    m: 0,
                                    p: 0,
                                    ml: 2
                                }}
                            />
                        </Box>

                    </Alert>
                </Snackbar>
            }

        </LoginChecker>

    )
}

export default RegisterTechService;