import { ObjectUtils } from "../utils/ObjectUtils";

declare global {
    interface String {
        isNullOrEmpty(): boolean,
        emptySpaceToUndefined(): string | undefined,
        toDate(): Date,
        replaceHTMLTag(): string | undefined
    }
}

String.prototype.isNullOrEmpty = function (this: string | undefined) {
    return ObjectUtils.IsNullOrEmpty(this);
}

String.prototype.emptySpaceToUndefined = function (this: string) {
    if (ObjectUtils.IsNullOrEmpty(this)) {
        return undefined;
    }
    else {
        return this;
    }
}

String.prototype.toDate = function (this: string) {
    return new Date(Date.parse(this))
}

String.prototype.replaceHTMLTag = function (this: string | undefined) {
    return this?.replace(/<(.*?)>/g, '');
}

export { }