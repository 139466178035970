

import { IKeriAPIState as IKeriAPIState } from "@ict-base/module/service/ICTRequestAPI";
import CallAPI from "@ict-base/module/utils/CallAPI";
import { IListPagination } from "@keri-base/interface/control/IListPagination";
import KeriAPIStructure from "@keri-portal/config/KeriAPIStructure";
import { IAPIAddProductNameRequestBody, IAPIAddProductRequestBody, IAPIAddProductTypeRequestBody, IAPIDeleteProductNameRequestBody, IAPIDeleteProductRequestBody, IAPIDeleteProductTypeRequestBody, IAPIGetProductListRequestBody, IAPIGetProductNameRequestBody, IAPIGetProductTypeRequestBody } from "@keri-portal/interfaces/api/keri-api/IKeriAPIRequest";
import { IAPIAddProductNameResult, IAPIAddProductResult, IAPIAddProductTypeResult, IAPIDeleteProductNameResult, IAPIDeleteProductResult, IAPIDeleteProductTypeResult, IAPIGetProductListResult, IAPIGetProductNameResult, IAPIGetProductTypeResult } from "@keri-portal/interfaces/api/keri-api/response/IKeriAPIProductResponse";
import { IKeriProduct, IKeriProductName, IKeriProductType } from "@keri-portal/interfaces/data-object/IProduct";
import { AxiosError } from "axios";
import InterfaceExtensionMethod from "../../extension-method/InterfaceExtensionMethod";
import { IKeriAuthAPIState } from "../RequestKeriAPI";

/**
 * 품목 관련 API Call Object
 * 
 * @author shlee
 * @since 2022.10.09
 * 
 */
const KeriProductAPI = {
    /** 품목 추가 */
    AddProduct: async (token?: string, data?: IKeriProduct): Promise<IKeriAuthAPIState> => AddProduct(token, data),
    /** 품목 조회 */
    GetProduct: async (token?: string, searchText?: string, page?: number): Promise<IAPIGetProductAttributeDataList<IKeriProduct>> => GetProductList(token, searchText, page),
    /** 품목 삭제 */
    DeleteProduct: async (token?: string, data?: IKeriProduct[]): Promise<IKeriAuthAPIState> => DeleteProduct(token, data),

    /** 품목명 리스트 조회 */
    GetProductName: async (token?: string, page?: number, searchText?: string): Promise<IAPIGetProductAttributeDataList<IKeriProductName>> => GetProductNameList(token, page, searchText),
    /** 품목명 추가 */
    AddProductName: async (data: IKeriProductName, token?: string): Promise<IKeriAuthAPIState> => AddProductName(data, token),
    /** 품목명 삭제 */
    DeleteProductName: async (data: IKeriProductName[], token?: string): Promise<IKeriAuthAPIState> => DeleteProductName(data, token),

    /** 품목 구분 조회 */
    GetProductType: async (token?: string, page?: number, searchText?: string): Promise<IAPIGetProductAttributeDataList<IKeriProductType>> => GetProductTypeList(token, page, searchText),
    /** 품목 구분 추가 */
    AddProductType: async (data: IKeriProductType, token?: string): Promise<IKeriAPIState> => AddProductType(data, token),
    /** 품목 구분 삭제 */
    DeleteProductType: async (data: IKeriProductType[], token?: string): Promise<IKeriAPIState> => DeleteProductType(data, token),
}



/** Result Interface */


/** 품목 조회 Result interface */
export interface IAPIGetProductAttributeDataList<T> extends IKeriAuthAPIState, IListPagination {
    resultData?: T[]
}


/** 품목 */

/** 품목 생성 */
const AddProduct = async (token?: string, data?: IKeriProduct): Promise<IKeriAuthAPIState> => {
    let apiState: IKeriAuthAPIState = {
        state: true,
        message: undefined,
        tokenValid: true
    }

    const reqBody: IAPIAddProductRequestBody =
    {
        prodNm: data?.productName?.productName,
        prodNumber: data?.productNumber,
        prodTypeNm: data?.productType?.productTypeName,
        streFileNm: data?.productImage?.UploadedFile?.streFileNm !== undefined ? [data?.productImage.UploadedFile?.streFileNm] : undefined
    }

    await CallAPI<IAPIAddProductResult>({
        url: KeriAPIStructure.Product.AddProduct,
        method: "PUT",
        token: token,
        requestBody: reqBody
    })
        .then(res => {
            if (res.data.resultCode !== 'S') {
                apiState.state = false;
                apiState.message = res.data.resultMessage;
            }
        })
        .catch((err) => {
            if (err.response?.status === 401) {
                apiState.tokenValid = false;
                apiState.message = '로그인이 만료되었습니다. \n다시 로그인해주시길 바랍니다.'
            }
            else {
                apiState.message = '서버와 연결이 원활하지 않습니다.'
            }
            apiState.state = false;

        })


    return apiState;
}

/** 품목 조회 */
const GetProductList = async (token?: string, searchText?: string, page?: number): Promise<IAPIGetProductAttributeDataList<IKeriProduct>> => {
    let apiState: IAPIGetProductAttributeDataList<IKeriProduct> = {
        state: false,
        tokenValid: true,
        message: undefined,
        resultData: [],
        pageObject: {
            nowPage: page,
            totalPage: page ?? 1 > 1 ? page : 1
        }
    }

    let reqBody: IAPIGetProductListRequestBody = {
        pageIndex: (page ?? 1).toString(),
        pageUnit: '5',
        searchKeyword: searchText,
    }



    await CallAPI<IAPIGetProductListResult>({
        url: KeriAPIStructure.Product.GetProduct,
        method: "POST",
        requestBody: reqBody,
        token: token
    })
        .then(res => {
            if (res.data.resultCode === 'S') {
                apiState.state = true;
                apiState.resultData = res.data.resultData.filter(x => x !== undefined).map(x => InterfaceExtensionMethod.API.Response.CastAPIToProductData(x)!);
                apiState.pageObject = {
                    nowPage: page,
                    totalPage: res.data.paginationInfo?.lastPageNo
                }
            }
            else {
                apiState.state = false;
                apiState.message = res.data.resultMessage
            }
        })
        .catch(err => {
            if (err.response?.status === 401) {
                apiState.tokenValid = false;
                apiState.message = '로그인이 만료되었습니다. \n다시 로그인해주시길 바랍니다.'
            }
            else {
                apiState.message = '서버와 연결이 원활하지 않습니다.'
            }
            apiState.state = false;
        })






    return apiState;
}

/** 품목 수정 */

/** 품목 삭제 */
const DeleteProduct = async (token?: string, data?: IKeriProduct[]): Promise<IKeriAuthAPIState> => {

    let apiState: IKeriAuthAPIState = {
        state: true,
        message: undefined,
        tokenValid: true
    }

    const reqBody: IAPIDeleteProductRequestBody =
    {
        deletedItems: (data ?? []).filter(x => x.productID !== undefined).map(x => x.productID!)
    }

    await CallAPI<IAPIDeleteProductResult>({
        url: KeriAPIStructure.Product.DeleteProduct,
        method: "DELETE",
        token: token,
        requestBody: {data : reqBody} 
    })
        .then(res => {
            if (res.data.resultCode !== 'S') {
                apiState.state = false;
                apiState.message = res.data.resultMessage;
            }
        })
        .catch((err) => {
            if (err.response?.status === 401) {
                apiState.tokenValid = false;
                apiState.message = '로그인이 만료되었습니다. \n다시 로그인해주시길 바랍니다.'
            }
            else {
                apiState.message = '서버와 연결이 원활하지 않습니다.'
            }
            apiState.state = false;

        })


    return apiState;


}







/** 품목명 */

/** 품목명 생성 */
const AddProductName = async (data: IKeriProductName, token?: string): Promise<IKeriAuthAPIState> => {
    let apiState: IKeriAuthAPIState = {
        state: true,
        tokenValid: true,
        message: undefined,
    }

    const reqBody: IAPIAddProductNameRequestBody = {
        prodNm: data.productName!
    }

    await CallAPI<IAPIAddProductNameResult>({
        url: KeriAPIStructure.Product.AddProductName,
        method: "PUT",
        token: token,
        requestBody: reqBody
    })
        .then(res => {
            if (res.data.resultCode === 'S') {
                apiState.state = true;
                apiState.message = res.data.resultMessage;
            }
            else {
                apiState.state = false;
                apiState.message = res.data.resultMessage;
            }
        })
        .catch((err: AxiosError) => {
            if (err.response?.status === 401) {
                apiState.tokenValid = false;
                apiState.message = '로그인이 만료되었습니다. \n다시 로그인해주시길 바랍니다.'
            }
            else {
                apiState.message = '서버와 연결이 원활하지 않습니다.'
            }
            apiState.state = false;

        })


    return apiState;
}

/** 품목명 조회 */
const GetProductNameList = async (token?: string, page?: number, searchText?: string): Promise<IAPIGetProductAttributeDataList<IKeriProductName>> => {
    let apiState: IAPIGetProductAttributeDataList<IKeriProductName> = {
        state: false,
        tokenValid: true,
        message: undefined,
        resultData: undefined,
        pageObject: {
            nowPage: page,
            totalPage: page ?? 1 > 1 ? page : 1
        }
    }

    let reqBody: IAPIGetProductNameRequestBody = {
        pageIndex: (page ?? 1).toString(),
        pageUnit: '10',
        searchKeyword: searchText,
    }



    await CallAPI<IAPIGetProductNameResult>({
        url: KeriAPIStructure.Product.GetProductName,
        method: "POST",
        requestBody: reqBody,
        token: token
    })
        .then(res => {
            if (res.data.resultCode === 'S') {
                apiState.state = true;
                apiState.resultData = res.data.resultData.filter(x => x !== undefined).map(x => InterfaceExtensionMethod.API.Response.CastAPIToProductNameData(x)!);
                apiState.pageObject = {
                    nowPage: page,
                    totalPage: res.data.paginationInfo?.lastPageNo
                }
            }
            else {
                apiState.state = false;
                apiState.message = res.data.resultMessage
            }
        })
        .catch(err => {
            if (err.response?.status === 401) {
                apiState.tokenValid = false;
                apiState.message = '로그인이 만료되었습니다. \n다시 로그인해주시길 바랍니다.'
            }
            else {
                apiState.message = '서버와 연결이 원활하지 않습니다.'
            }
            apiState.state = false;
        })






    return apiState;
}

/** 품목명 삭제 */
const DeleteProductName = async (data: IKeriProductName[], token?: string): Promise<IKeriAuthAPIState> => {
    let apiState: IKeriAuthAPIState = {
        state: true,
        message: undefined,
        tokenValid: true
    }

    const reqBody: IAPIDeleteProductNameRequestBody =
    {
        deletedItems: data.filter(x => x !== undefined && x.productNameSequence !== undefined).map(x => x.productNameSequence!.toString())
    }

    await CallAPI<IAPIDeleteProductNameResult>({
        url: KeriAPIStructure.Product.DeleteProductName,
        method: "DELETE",
        token: token,
        requestBody: {
            data: reqBody
        }
    })
        .then(res => {
            if (res.data.resultCode !== 'S') {
                apiState.state = false;
                apiState.message = res.data.resultMessage;
            }
        })
        .catch((err) => {
            if (err.response?.status === 401) {
                apiState.tokenValid = false;
                apiState.message = '로그인이 만료되었습니다. \n다시 로그인해주시길 바랍니다.'
            }
            else {
                apiState.message = '서버와 연결이 원활하지 않습니다.'
            }
            apiState.state = false;

        })


    return apiState;
}






/** 품목 구분 */

/** 품목 구분 생성 */
const AddProductType = async (data: IKeriProductType, token?: string): Promise<IKeriAuthAPIState> => {
    let apiState: IKeriAuthAPIState = {
        state: true,
        tokenValid: true,
        message: undefined,
    }

    const reqBody: IAPIAddProductTypeRequestBody = {
        prodTypeNm: data.productTypeName!
    }

    await CallAPI<IAPIAddProductTypeResult>({
        url: KeriAPIStructure.Product.AddProductType,
        method: "PUT",
        token: token,
        requestBody: reqBody
    })
        .then(res => {
            if (res.data.resultCode === 'S') {
                apiState.state = true;
                apiState.message = res.data.resultMessage;
            }
            else {
                apiState.state = false;
                apiState.message = res.data.resultMessage;
            }
        })
        .catch((err: AxiosError) => {
            if (err.response?.status === 401) {
                apiState.tokenValid = false;
                apiState.message = '로그인이 만료되었습니다. \n다시 로그인해주시길 바랍니다.'
            }
            else {
                apiState.message = '서버와 연결이 원활하지 않습니다.'
            }
            apiState.state = false;

        })


    return apiState;
}

/** 품목 구분 조회 */
const GetProductTypeList = async (token?: string, page?: number, searchText?: string): Promise<IAPIGetProductAttributeDataList<IKeriProductType>> => {
    let apiState: IAPIGetProductAttributeDataList<IKeriProductType> = {
        state: false,
        tokenValid: true,
        message: undefined,
        resultData: undefined,
        pageObject: {
            nowPage: page,
            totalPage: page ?? 1 > 1 ? page : 1
        }
    }

    let reqBody: IAPIGetProductTypeRequestBody = {
        pageIndex: (page ?? 1).toString(),
        pageUnit: '10',
        searchKeyword: searchText,
    }


    await CallAPI<IAPIGetProductTypeResult>({
        url: KeriAPIStructure.Product.GetProductType,
        method: "POST",
        requestBody: reqBody,
        token: token
    })
        .then(res => {
            if (res.data.resultCode === 'S') {
                apiState.state = true;
                apiState.resultData = res.data.resultData.filter(x => x !== undefined).map(x => InterfaceExtensionMethod.API.Response.CastAPIToProductTypeData(x)!);
                apiState.pageObject = {
                    nowPage: page,
                    totalPage: res.data.paginationInfo?.lastPageNo
                }
            }
            else {
                apiState.state = false;
                apiState.message = res.data.resultMessage
            }
        })
        .catch(err => {
            if (err.response?.status === 401) {
                apiState.tokenValid = false;
                apiState.message = '로그인이 만료되었습니다. \n다시 로그인해주시길 바랍니다.'
            }
            else {
                apiState.message = '서버와 연결이 원활하지 않습니다.'
            }
            apiState.state = false;
        })




    return apiState;
}

/** 품목 구분 삭제 */
const DeleteProductType = async (data: IKeriProductType[], token?: string): Promise<IKeriAPIState> => {
    let apiState: IKeriAuthAPIState = {
        state: true,
        message: undefined,
        tokenValid: true
    }

    const reqBody: IAPIDeleteProductTypeRequestBody =
    {
        deletedItems: data.filter(x => x !== undefined && x.productTypeSequence !== undefined).map(x => x.productTypeSequence!.toString())
    }

    await CallAPI<IAPIDeleteProductTypeResult>({
        url: KeriAPIStructure.Product.DeleteProductType,
        method: "DELETE",
        token: token,
        requestBody: {
            data: reqBody
        }
    })
        .then(res => {
            if (res.data.resultCode !== 'S') {
                apiState.state = false;
                apiState.message = res.data.resultMessage;
            }
        })
        .catch((err) => {
            if (err.response?.status === 401) {
                apiState.tokenValid = false;
                apiState.message = '로그인이 만료되었습니다. \n다시 로그인해주시길 바랍니다.'
            }
            else {
                apiState.message = '서버와 연결이 원활하지 않습니다.'
            }
            apiState.state = false;

        })


    return apiState;
}





export default KeriProductAPI;