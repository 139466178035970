import axios, { AxiosError, AxiosRequestConfig, AxiosRequestHeaders, AxiosResponse } from "axios";


interface ICallAPI<T> {
    nonBaseURL? : boolean,
    url: string,
    method: WebRequestMethod,
    requestBody? : any | undefined,
    header?: AxiosRequestHeaders ,
    token?: string | undefined,
    responseType? : 'blob' | 'json',
    data? : any
}


export type WebRequestMethod =  'GET' | 'POST' | 'PUT' | 'DELETE' | 'OPTION';

export const CallAPI = <T extends any = any> (prop : ICallAPI<T>) => {

    if(prop.token !== undefined) {
        prop.header = {
            ...prop.header,
            'Content-Type' : 'application/json',
            'Authorization' : `${prop.token}`,
        };
    }

    const axiosInstance = axios.create({
        ...((prop.nonBaseURL??false) ? {} : {baseURL: process.env.REACT_APP_API_URL === undefined ? "/" : `${process.env.REACT_APP_API_URL}`}),
        headers:  {
            ...prop.header
        },
        responseType : prop.responseType,
        // data : prop.data
    });

    switch (prop.method) {
        case 'GET': return axiosInstance.get<T>((prop.url), prop.requestBody); 
        case 'POST': return axiosInstance.post<T>((prop.url), prop.requestBody); 
        case 'DELETE': return axiosInstance.delete<T>((prop.url), prop.requestBody);
        case 'PUT': return axiosInstance.put<T>((prop.url), prop.requestBody);
        // eslint-disable-next-line
        default: throw 'Not Found Type';
    }

}

export default CallAPI;