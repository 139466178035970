import { ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from "@mui/material"
import React, { useState } from "react";

interface IICTAccordion {
    title: string,
    isExpend?: boolean,
    titleProps?: {
        expandIcon?: React.ReactNode
    },
    itemProps?: {

    },
    children?: React.ReactNode | React.ReactNode[],
    display?: boolean
}

const ICTAccordion = (prop: IICTAccordion) => {
    const [accordionExpend, setAccordionExpend] = useState<boolean>(prop.isExpend ?? false);

    return (
        <React.Fragment>
            {
                (prop.display ?? true) &&
                <Accordion
                    elevation={2}
                    expanded={accordionExpend}
                    sx={{
                        
                    }}
                >
                    <AccordionSummary
                        expandIcon={prop.titleProps?.expandIcon ?? <ExpandMore />}
                        onClick={() => setAccordionExpend(!accordionExpend)}
                    >
                        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                            {prop.title}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                        sx={{
                            borderTop: '1px solid rgba(0,0,0,0.2)',
                            p: 2
                        }}
                    >
                        {prop.children}
                    </AccordionDetails>
                </Accordion>
            }

        </React.Fragment>

    )
}

export default ICTAccordion;