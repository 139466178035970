import KeriActionButton from "@ict-base/components/control/KeriActionButton";
import { ICTSearchTypeBox } from "@ict-base/components/control/ICTSearchBox";
import { IPageProp } from "@keri-base/interface/common/IPageProp";
import InfoLayoutPage from "@keri-portal/component/layout/InfoLayoutPage";
import KeriAddProductItemsModal from "@keri-portal/component/modal/product-item/KeriAddProductItemsModal";
import useLoginCheck from "@keri-portal/module/hook/useLoginCheck";
import { AddOutlined, DeleteOutline, FolderOutlined } from "@mui/icons-material";
import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import URLStructure from "@src/modules/structure/URLStructure";
import KeriProductAttributeModel from "@keri-portal/component/modal/product-item/KeriProductAttributeModel";
import RequestKeriAPI from "@keri-portal/module/method/api-connect/RequestKeriAPI";
import { ProductSearchCondition } from "@keri-portal/interfaces/api/keri-api/IKeriAPIRequest";
import { IKeriProduct } from "@keri-portal/interfaces/data-object/IProduct";
import { IListPagination } from "@keri-base/interface/control/IListPagination";





const UserItemManagementViewPage = (prop: IPageProp) => {


    const [searchText, setSearchText] = useState<{ condition: ProductSearchCondition, value?: string }>({ condition: 'prodNumber', value: undefined });

    const [prodData, setProdData] = useState<IKeriProduct[]>([]);
    const [checkItem, setCheckItem] = useState<IKeriProduct[]>([]);

    const [page, setPage] = useState<IListPagination>({
        pageObject: {
            nowPage: 1,
            totalPage: 1
        }
    });

    const loginHook = useLoginCheck();

    const getAPIData = async (searchText?: string, page?: number) => {
        prop.EventFunction.EnableBackDrop(true);

        const getResponse = await RequestKeriAPI.Product.GetProduct(loginHook?.token, searchText, page);

        if (getResponse.state) {
            setProdData(getResponse.resultData??[]);
        }
        else {
            alert(getResponse.message)

            if (getResponse.tokenValid) {
                //TODO : Navigate Login Page
            }

        }

        prop.EventFunction.EnableBackDrop(false);
    }

    //#region Login Check



    const navigate = useNavigate();

    useEffect(() => {
        if ((loginHook?.loadComplete ?? false)) {

            if (loginHook?.token === undefined) {
                alert('로그인이 필요합니다.');
                navigate(URLStructure.Main);
            }
            else {
                getAPIData(undefined,  1);
            }
        }



        // eslint-disable-next-line
    }, [loginHook])


    //#endregion


    //#region Add Item Modal

    const [isOpenAddItemModal, setOpenAddItemModal] = useState<boolean>(false);
    const [isOpenProductAttributeModal, setOpenProductAttributeModal] = useState<boolean>(false);




    //#endregion

    return (
        <InfoLayoutPage
            title="품목 관리"
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    mb: 4,
                    mt: 4
                }}
            >
                <ICTSearchTypeBox
                    // width={500}

                    display={{
                        typeDisplayName: (item) => item.value,
                        typeKey: (item) => item.key
                    }}
                    type={[
                        {
                            key: 'prodTypeNm',
                            value: '품목 구분'
                        },
                        {
                            key: 'prodNm',
                            value: '품목명'
                        },
                        {
                            key: 'prodNumber',
                            value: '품번'
                        }
                    ]}
                    onSearch={(condition, text) => {
                        setSearchText({
                            condition: condition.key as ProductSearchCondition,
                            value: text
                        })
                    }}
                />
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <Box>
                    <KeriActionButton
                        label="품목 추가"
                        variant="contained"
                        color='primary'
                        // color="#25476a"
                        icon={<AddOutlined />}
                        onClick={() => setOpenAddItemModal(true)}
                        sx={{
                            mr: 1
                        }}
                    />

                    <KeriActionButton
                        label="품목명/구분 관리"
                        variant="outlined"
                        color='secondary'
                        // color="#25476a"
                        icon={<FolderOutlined />}
                        sx={{
                            mr: 1
                        }}
                        onClick={() => setOpenProductAttributeModal(true)}
                    />

                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'end',
                        alignItems: 'center'
                    }}
                >
                    <KeriActionButton
                        label="선택항목 삭제"
                        variant="outlined"
                        color="error"
                        icon={<DeleteOutline />}
                        onClick={() => {
                            if (prodData.length !== 0) {
                                // alert(`delete item : \n${selectItem.map(x => `[${x.code}]${x.title}`).join('\n')}`)
                            }
                            else {
                                alert('no delete item');
                            }
                        }}
                    />
                </Box>

            </Box>
            <Box>

            </Box>
          

            {/* Modal */}
            <KeriAddProductItemsModal
                open={isOpenAddItemModal}
                onClose={() => {
                    setOpenAddItemModal(false);
                }}
                onSubmit={() => {
                    setOpenAddItemModal(false);
                }}
            />

            <KeriProductAttributeModel
                open={isOpenProductAttributeModal}
                onClose={() => setOpenProductAttributeModal(false)}
                showCategory='ALL'
            
            />
        </InfoLayoutPage>
    )
}

export default UserItemManagementViewPage;