import ICTBaseModal, { KeriModalSubmitAndCancelButton } from "@ict-base/components/control/ICTBaseModal";
import { ObjectUtils } from "@ict-base/module/utils/ObjectUtils";
import { SurveyQuestionType, ISurvey, ISurveyAnswer } from "@keri-portal/interfaces/data-object/data-common/ISurvey";
import RequestKeriAPI from "@keri-portal/module/method/api-connect/RequestKeriAPI";
import { Circle, CircleOutlined, RadioButtonChecked, RadioButtonUnchecked } from "@mui/icons-material";
import { CircularProgress, Typography, TextField, Rating, Box, Checkbox } from "@mui/material";
import URLStructure from "@src/modules/structure/URLStructure";
import { DefaultScrollDesign } from "@src/modules/styles/DefaultScrollDesign";
import {  useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

interface IKeriTechServiceSurveyModal{
    open: boolean,
    onClose: () => void,
    onSubmit: (data: { key: string, value: string, type: SurveyQuestionType }[], surveyID?: string, surveyTempleteID?: string) => void,
    value: { key: string, value: string, type: SurveyQuestionType }[] | undefined,
    token?: string,
    isEdit? : boolean
}

const KeriTechServiceSurveyModal = (prop : IKeriTechServiceSurveyModal) => {

    const [loadComplete, setLoadComplete] = useState<{ load: boolean, complete: boolean }>({ load: false, complete: false })
    const [surveyList, setSurveyList] = useState<ISurvey | undefined>(undefined);
    const [resultValue, setResultValue] = useState<{ key: string, value?: string, type: SurveyQuestionType }[]>([]);
    const [errorData, setErrorData] = useState<{ key: string, state?: boolean }[]>([]);
    const [surveyInfo, setSurveyInfo] = useState<{ surveyID?: string, surveyTempleteID?: string } | undefined>(undefined);

    const navigate = useNavigate();
    const location = useLocation();

    const submitHandler = () => {

        let isError: boolean = false;
        let errorResultData = errorData;

        resultValue.forEach(item => {
            if (item.value === undefined) {
                errorResultData = [
                    ...errorResultData.filter(x => x.key !== item.key),
                    {
                        key: item.key,
                        state: true
                    }
                ]
                isError = true;
            }
        })

        if (isError) {
            setErrorData(errorResultData);
        }
        else {
            prop.onSubmit(resultValue.map(x => ({ key: x.key, value: x.value!, type: x.type })), surveyInfo?.surveyID, surveyInfo?.surveyTempleteID)
            prop.onClose();
        }

    }

    const getSurveyObject = async () => {
        setLoadComplete({ load: true, complete: false })


        const getList = await RequestKeriAPI.Common.GetSurveyList(prop.token);
        console.log(location);

        if (getList.state) {
            console.log(getList.resultData);
            setSurveyList(getList.resultData);

            console.log('valu', prop.value);


            setSurveyInfo({
                surveyID: getList.resultData?.SurveyID,
                surveyTempleteID: getList.resultData?.SurveyTempleteID
            })

            if ((prop.value ?? []).length === 0) {
                setResultValue([
                    ...(getList.resultData?.SurveyQuestion ?? []).filter(x => x.QuestionID !== undefined && x.QuestionType !== 'Text').map(x => ({ key: x.QuestionID!, type: x.QuestionType! })),
                    ...(getList.resultData?.SurveyQuestion ?? []).filter(x => x.QuestionID !== undefined && x.QuestionType === 'Text').map(x => ({ key: x.QuestionID!, value: '', type: x.QuestionType! }))
                ]);
            }
            else {
                setResultValue(prop.value ?? []);
            }
            setErrorData((getList.resultData?.SurveyQuestion ?? []).filter(x => x.QuestionID !== undefined).map(x => ({ key: x.QuestionID! })));
        }
        else {
            alert(getList.message);
            if (!getList.tokenValid) {
                navigate(`${URLStructure.UserLogin}?redirect=${location.pathname}${location.search}`);
            }
        }

        setLoadComplete({ load: false, complete: true })

    }

    useEffect(() => {

        console.log('DEFAULT DATA' , prop.value);

        if (prop.open) {
            if (!loadComplete.load && !loadComplete.complete) {
                if (prop.value !== undefined) {
                    getSurveyObject();
                }
            }
        }
        else {
            setLoadComplete({ load: false, complete: false })
        }
    }, [prop.open, prop.value])




    return (
        <ICTBaseModal
            open={prop.open}
            onClose={prop.onClose}
            submitButton={
                prop.isEdit &&
                <KeriModalSubmitAndCancelButton
                    onCancel={prop.onClose}
                    onSubmit={submitHandler}
                />
            }
            title="설문 조사"
        >
            <Box
                sx={{
                    width: '600px'
                }}
            >
                {
                    loadComplete.load
                        ?
                        <Box
                            sx={{
                                height: '300px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            <CircularProgress color="inherit" />
                        </Box>
                        :
                        <Box
                            sx={{
                                maxHeight: '600px',
                                overflow: 'scroll',
                                overflowX: 'hidden',
                                ...DefaultScrollDesign
                            }}
                        >
                            {
                                (surveyList?.SurveyQuestion ?? []).filter(x => x.QuestionID !== undefined).map(queItem => (
                                    <Box
                                        sx={{
                                            p: 1
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                borderLeft: '4px solid #aaa',
                                                pl: 1,
                                                userSelect: 'none'
                                            }}
                                        >
                                            <Typography onClick={() => { console.log(errorData.filter(x => x.key === queItem.QuestionID)) }}>
                                                {queItem.QuestionText}
                                            </Typography>
                                            {
                                                errorData.filter(x => x.key === queItem.QuestionID && (x.state === undefined ? false : x.state) === true).length !== 0 &&
                                                <Typography color="error" variant="subtitle2" >
                                                    문항이 작성되지 않았습니다.
                                                </Typography>
                                            }
                                        </Box>
                                        {
                                            queItem.QuestionType === 'Select' &&
                                            <Box
                                                sx={{
                                                    p: 1
                                                }}
                                            >
                                                <SurveyAnswerSelectItemGroup
                                                    value={queItem.QuestionAnswer ?? []}
                                                    checked={queItem.QuestionAnswer?.find(x => resultValue.find(x => x.key === queItem.QuestionID) !== undefined && x.AnswerID === resultValue.find(x => x.key === queItem.QuestionID)?.value)}
                                                    onChange={(item) => {
                                                        setResultValue([
                                                            ...resultValue.filter(x => x.key !== queItem.QuestionID),
                                                            {
                                                                key: queItem.QuestionID!,
                                                                value: item.AnswerID,
                                                                type: queItem.QuestionType!
                                                            }
                                                        ])

                                                        if (errorData.filter(x => x.key === queItem.QuestionID && (x.state === undefined ? false : x.state) === true).length !== 0) {
                                                            setErrorData([
                                                                ...errorData.filter(x => x.key !== queItem.QuestionID),
                                                                {
                                                                    key: queItem.QuestionID!,
                                                                    state: false
                                                                }
                                                            ])
                                                        }
                                                    }}
                                                />
                                            </Box>
                                        }
                                        {
                                            queItem.QuestionType === 'Text' &&
                                            <Box
                                                sx={{
                                                    p: 1
                                                }}
                                            >
                                                <TextField
                                                    multiline
                                                    fullWidth
                                                    size="small"
                                                    maxRows={3}
                                                    inputProps={{
                                                        sx: {
                                                            ...DefaultScrollDesign
                                                        }
                                                    }}
                                                    value={resultValue.find(x => x.key === queItem.QuestionID)?.value}
                                                    onChange={(e) => {
                                                        setResultValue([
                                                            ...resultValue.filter(x => x.key !== queItem.QuestionID),
                                                            {
                                                                key: queItem.QuestionID!,
                                                                value: e.currentTarget.value ?? "",
                                                                type: queItem.QuestionType!
                                                            }
                                                        ])

                                                        if (errorData.filter(x => x.key === queItem.QuestionID && (x.state === undefined ? false : x.state) === true).length !== 0) {
                                                            setErrorData([
                                                                ...errorData.filter(x => x.key !== queItem.QuestionID),
                                                                {
                                                                    key: queItem.QuestionID!,
                                                                    state: false
                                                                }
                                                            ])
                                                        }
                                                    }}
                                                />
                                            </Box>
                                        }
                                        {
                                            queItem.QuestionType === 'Rank' &&
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center ',
                                                    p: 2
                                                }}
                                            >
                                                <Box>
                                                    불만족
                                                </Box>
                                                <Box
                                                    sx={{
                                                        pr: 1.5,
                                                        pl: 1.5
                                                    }}
                                                >
                                                    <Rating
                                                        max={10}
                                                        icon={<Circle />}
                                                        value={
                                                            resultValue.filter(x => x.key === queItem.QuestionID).length === 0
                                                                ? undefined
                                                                : resultValue.filter(x => x.key === queItem.QuestionID)[0].value === undefined
                                                                    ? undefined
                                                                    : isNaN(parseInt(resultValue.filter(x => x.key === queItem.QuestionID)[0].value!))
                                                                        ? undefined
                                                                        : parseInt(resultValue.filter(x => x.key === queItem.QuestionID)[0].value!)

                                                        }
                                                        emptyIcon={<CircleOutlined />}
                                                        onChange={(e, value) => {
                                                            setResultValue([
                                                                ...resultValue.filter(x => x.key !== queItem.QuestionID),
                                                                {
                                                                    key: queItem.QuestionID!,
                                                                    value: (value?.toString() ?? undefined),
                                                                    type: queItem.QuestionType!
                                                                }
                                                            ])

                                                            console.log(errorData.filter(x => x.key === queItem.QuestionID && (x.state === undefined ? false : x.state) === true))

                                                            if (errorData.filter(x => x.key === queItem.QuestionID && (x.state === undefined ? false : x.state) === true).length !== 0) {
                                                                setErrorData([
                                                                    ...errorData.filter(x => x.key !== queItem.QuestionID),
                                                                    {
                                                                        key: queItem.QuestionID!,
                                                                        state: false
                                                                    }
                                                                ])
                                                            }
                                                        }}
                                                    />
                                                </Box>
                                                <Box>
                                                    만족
                                                </Box>
                                            </Box>
                                        }

                                    </Box>
                                ))
                            }
                        </Box>
                }
            </Box>
        </ICTBaseModal>
    )

}





interface ISurveyAnswerSelectItemGroup {
    value: ISurveyAnswer[],
    checked?: ISurveyAnswer,
    onChange: (item: ISurveyAnswer) => void
}

const SurveyAnswerSelectItemGroup = (prop: ISurveyAnswerSelectItemGroup) => {

    const [selectValue, setSelectValue] = useState<ISurveyAnswer | undefined>(undefined);

    useEffect(() => {
        setSelectValue(prop.checked)
    }, [prop.checked])


    return (
        <Box>
            {
                prop.value.map(ansItem => (
                    <Box
                        key={ObjectUtils.RandomStrings(10)}
                        sx={{
                            display: 'flex',
                            justifyContent: 'start',
                            alignItems: 'center'
                        }}
                    >

                        <Checkbox
                            checked={selectValue?.AnswerID === ansItem.AnswerID}
                            onChange={(e) => {
                                if (e.currentTarget.checked) {
                                    setSelectValue(ansItem);
                                    prop.onChange(ansItem);
                                }
                            }}
                            icon={<RadioButtonUnchecked />}
                            checkedIcon={<RadioButtonChecked />}
                        />

                        <Box>
                            {ansItem.AnswerText}
                        </Box>
                    </Box>


                ))
            }
        </Box>
    )
}


export default KeriTechServiceSurveyModal;