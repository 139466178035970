import { Box, Divider, SxProps, Theme, Typography } from "@mui/material";

interface IContentGroupLayout{
    children?: React.ReactNode,
    title: string,
    sx?: SxProps<Theme>
}

const ContentGroupLayout = (prop :  IContentGroupLayout) => {
    return (
        <Box
            sx={{
                p : 2,
                ...prop.sx
            }}
        >
            <Typography
                sx={{
                    fontWeight: 'bold',
                    userSelect: 'none',
                    fontSize : '18px'
                }}
            >
                {prop.title}
            </Typography>

            <Divider sx={{ mt: 1.5, mb: 1.5 }} />
            <Box sx={{ mb: 3 }}>
                {prop.children}
            </Box>
        </Box>
    )
}

export default ContentGroupLayout;