import { Box, SxProps, Theme } from "@mui/material";
import { DefaultScrollDesign } from "../../../modules/styles/DefaultScrollDesign";

interface IKeriScrollBox {
    children? : React.ReactNode,
    darkMode? : boolean,
    height? : number,
    sx? : SxProps<Theme>
}

const KeriScrollBox = (prop : IKeriScrollBox) => {
    return (
        <Box
            className='Keri-ScrollBox'
            sx={{
                border : '1px solid #ddd',
                borderRadius : '0.7rem',
                overflow : 'hidden',
                background : (prop.darkMode??false) ? 'rgb(225, 231, 240)' : 'white',
                mt : 1,
                mb : 1,
                p : '0.5rem',
                ...prop.sx
            }}
        >
            <Box
                sx={{
                    overflowX : 'auto',
                    overflowY : 'scroll',
                    height : prop.height === undefined ? '100px' : prop.height,
                    p : '0.75rem',
                    ...DefaultScrollDesign
                }}
            >
                {prop.children}
            </Box>
        </Box>
    )
}

export default KeriScrollBox;