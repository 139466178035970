import { ObjectUtils } from "@ict-base/module/utils/ObjectUtils";
import { IKeriStepShareProps, KeriStepItemLayout, KeriStepItemContent } from "@keri-portal/component/layout/StepLayout";
import { IUploadFileVO } from "@keri-portal/interfaces/api/keri-api/object/IUploadFileVO";
import { IUploadFile } from "@keri-portal/interfaces/control/IUploadFile";
import { ITechServiceRequest } from "@keri-portal/interfaces/process-object/tech-service/ITechServiceRequest";
import useLoginCheck from "@keri-portal/module/hook/useLoginCheck";
import RequestKeriAPI from "@keri-portal/module/method/api-connect/RequestKeriAPI";
import TechServiceCommonValidation from "@keri-portal/module/method/validation-check/tech-service/ITechServiceRequestCommonValidationCheck";
import { Info } from "@mui/icons-material";
import { Box, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";

const KeriRegisterTechServiceSubmitItem = (prop: IKeriStepShareProps<ITechServiceRequest>) => {

    const [formData, setFormData] = useState<ITechServiceRequest | undefined>();
    const userRedux = useLoginCheck();

    const validationCheck = (): boolean => {

        const valCheck = TechServiceCommonValidation.Benefit(formData?.RequestBenefitData);

        if (!valCheck.state) {
            alert(valCheck.errorData);
        }

        return valCheck.state;
        // return false;
    }



    useEffect(() => {
        const beforeData = prop.result.data === undefined ? undefined : { ...prop.result.data };


        if (formData === undefined && beforeData !== undefined) {

            let inputFormData: ITechServiceRequest | undefined = undefined;

            let requiredFiles: IUploadFile<IUploadFileVO>[] = [];

            inputFormData = {
                ...beforeData,
                RequesterData: {
                    ...beforeData.RequesterData,
                    RequestType: beforeData.TechServiceType!,
                    RequestInputFile: [...requiredFiles, ...(beforeData.RequesterData?.RequestInputFile ?? [])]
                }
            }

            setFormData({ ...inputFormData });
        }
    }, [prop.result.data, formData])


    useEffect(() => {
        console.log('change form data', formData);
    }, [formData])


    return (
        <KeriStepItemLayout
            {...prop}
            validationCheck={validationCheck}
            step={prop.step}
            page={{
                moveNext: async (setup, validation) => {
                    prop.routeProp.EventFunction.EnableBackDrop(true);
                    if (validation) {
                        const requestData = await RequestKeriAPI.TechService.AddTechService(userRedux?.token, prop.result.data.TechServiceType, formData);

                        if (requestData.state) {

                            setup(prop.step.activeStep + 1);
                        }
                        else {
                            alert(requestData.message);
                        }


                    }
                    prop.routeProp.EventFunction.EnableBackDrop(false);
                }
            }}
        >
            <KeriStepItemContent
                title='기대효과'
                tooltip={{
                    enableTooltip: false,
                    tooltipMessage: '1',
                }}
            >
                {/* Table */}
                <Box
                    sx={{
                        border: '1px solid rgba(0,0,0,0.2)',
                        p: 1,
                        borderRadius: '5px'
                    }}
                >
                    <Typography>
                        기대효과는 기술지원 선정위원회에서 지원 여부를 판단하는 근거 자료로 활용됩니다.
                        작성자께서는 기술지원 이후에 예상되는 기대효과를 입력해 주시기 바랍니다.
                        아래 기대효과가 명확하지 않는 경우에는 입력없이 다음 단계로 진행할 수 있습니다.
                    </Typography>
                </Box>

                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" width={'30%'}>구분</TableCell>
                            <TableCell align="center" width={'35%'}>지원 후</TableCell>
                            <TableCell align="center" width={'35%'}>작성요령</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {/* 매출 */}
                        <TableRow>
                            <TableCell align="left">매출(개발 및 관련 제품)</TableCell>

                            <TableCell >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center'
                                    }}
                                >
                                    <TextField
                                        size="small"
                                        inputProps={{
                                            placeholder: '0',
                                            style: {
                                                textAlign: 'right'
                                            }
                                        }}
                                        sx={{
                                            width: '70%'
                                        }}
                                        onChange={(e) => {
                                            if (ObjectUtils.IsNullOrEmpty(e.target.value) || /^[0-9]{1,7}([.][0-9]{0,1})?$/.test(e.target.value)) {
                                                setFormData({
                                                    ...formData,
                                                    RequestBenefitData: {
                                                        ...formData?.RequestBenefitData,
                                                        BeforeSupport: {
                                                            ...formData?.RequestBenefitData?.BeforeSupport,
                                                            Sales: e.target.value === '' ? undefined : parseInt(e.target.value)
                                                        }
                                                    },
                                                    RequestTempUniqueKey: formData?.RequestTempUniqueKey!
                                                })
                                            }
                                            else {
                                                alert('입력값은 숫자 7자리 (소수점 1자리)만 가능합니다.');
                                                e.target.value = (/[0-9]{1,7}([.][0-9]{0,1})?/.exec(e.target.value) ?? [''])[0];
                                            }
                                        }}
                                    />
                                    <Typography
                                        sx={{
                                            fontSize: '0.875rem'
                                        }}
                                    >
                                        백만원
                                    </Typography>
                                </Box>

                            </TableCell>
                            <TableCell>
                                <Typography
                                    sx={{
                                        fontSize: '0.875rem'
                                    }}
                                >
                                    기술지원으로 인해 예상되는 매출 증가액을 입력합니다. (효과가 없을 경우 빈칸)
                                </Typography>
                            </TableCell>
                        </TableRow>

                        {/* 개발비용 절감 효과 */}
                        <TableRow>
                            <TableCell align="left">개발비용 절감 효과</TableCell>
                            <TableCell >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center'
                                    }}
                                >
                                    <TextField
                                        size="small"
                                        inputProps={{
                                            placeholder: '0',
                                            style: {
                                                textAlign: 'right'
                                            }
                                        }}
                                        sx={{
                                            width: '70%'
                                        }}
                                        onChange={(e) => {

                                            if (ObjectUtils.IsNullOrEmpty(e.target.value) || /^[0-9]{1,7}([.][0-9]{0,1})?$/.test(e.target.value)) {
                                                setFormData({
                                                    ...formData,
                                                    RequestBenefitData: {
                                                        ...formData?.RequestBenefitData,
                                                        BeforeSupport: {
                                                            ...formData?.RequestBenefitData?.BeforeSupport,
                                                            ReduceDevelopmentCost: e.target.value === '' ? undefined : parseInt(e.target.value)
                                                        }
                                                    },
                                                    RequestTempUniqueKey: formData?.RequestTempUniqueKey!
                                                })
                                            }
                                            else {
                                                alert('입력값은 숫자 7자리 (소수점 1자리)만 가능합니다.');
                                                e.target.value = (/[0-9]{1,7}([.][0-9]{0,1})?/.exec(e.target.value) ?? [''])[0];
                                            }
                                        }}
                                    />
                                    <Typography
                                        sx={{
                                            fontSize: '0.875rem'
                                        }}
                                    >
                                        백만원
                                    </Typography>
                                </Box>

                            </TableCell>
                            <TableCell>
                                <Typography
                                    sx={{
                                        fontSize: '0.875rem'
                                    }}
                                >
                                    기술지원으로 인해 예상되는 개발비용 절감액을 입력합니다. (효과가 없을 경우 빈칸)
                                </Typography>
                            </TableCell>
                        </TableRow>

                        {/* 개발기간 단축 효과 */}
                        <TableRow>
                            <TableCell align="left">개발기간 단축 효과</TableCell>
                            <TableCell >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center'
                                    }}
                                >
                                    <TextField
                                        size="small"
                                        inputProps={{
                                            placeholder: '0',
                                            style: {
                                                textAlign: 'right'
                                            }
                                        }}
                                        sx={{
                                            width: '70%'
                                        }}
                                        onChange={(e) => {

                                            if (ObjectUtils.IsNullOrEmpty(e.target.value) || /^[0-9]{1,7}([.][0-9]{0,1})?$/.test(e.target.value)) {
                                                setFormData({
                                                    ...formData,
                                                    RequestBenefitData: {
                                                        ...formData?.RequestBenefitData,
                                                        BeforeSupport: {
                                                            ...formData?.RequestBenefitData?.BeforeSupport,
                                                            ReduceDevelopmentPeriod: e.target.value === '' ? undefined : parseInt(e.target.value)
                                                        }
                                                    },
                                                    RequestTempUniqueKey: formData?.RequestTempUniqueKey!
                                                })
                                            }
                                            else {
                                                alert('입력값은 숫자 7자리 (소수점 1자리)만 가능합니다.');
                                                e.target.value = (/[0-9]{1,7}([.][0-9]{0,1})?/.exec(e.target.value) ?? [''])[0];
                                            }

                                        }}
                                    />
                                    <Typography
                                        sx={{
                                            fontSize: '0.875rem'
                                        }}
                                    >
                                        개월
                                    </Typography>
                                </Box>

                            </TableCell>
                            <TableCell>
                                <Typography
                                    sx={{
                                        fontSize: '0.875rem'
                                    }}
                                >
                                    기술지원으로 인해 단축될 것으로 예상되는 개발기간을 입력합니다. (효과가 없을 경우 빈칸)
                                </Typography>
                            </TableCell>
                        </TableRow>

                        {/* 고용 */}
                        <TableRow>
                            <TableCell align="left">고용</TableCell>

                            <TableCell >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center'
                                    }}
                                >
                                    <TextField
                                        size="small"
                                        inputProps={{
                                            placeholder: '0',
                                            style: {
                                                textAlign: 'right'
                                            }
                                        }}
                                        sx={{
                                            width: '70%'
                                        }}
                                        onChange={(e) => {
                                            if (ObjectUtils.IsNullOrEmpty(e.target.value) || /^[0-9]{1,7}([.][0-9]{0,1})?$/.test(e.target.value)) {
                                                setFormData({
                                                    ...formData,
                                                    RequestBenefitData: {
                                                        ...formData?.RequestBenefitData,
                                                        BeforeSupport: {
                                                            ...formData?.RequestBenefitData?.BeforeSupport,
                                                            Employ: e.target.value === '' ? undefined : parseInt(e.target.value)
                                                        }
                                                    },
                                                    RequestTempUniqueKey: formData?.RequestTempUniqueKey!
                                                })
                                            }
                                            else {
                                                alert('입력값은 숫자 7자리 (소수점 1자리)만 가능합니다.');
                                                e.target.value = (/[0-9]{1,7}([.][0-9]{0,1})?/.exec(e.target.value) ?? [''])[0];
                                            }
                                        }}
                                    />
                                    <Typography
                                        sx={{
                                            fontSize: '0.875rem'
                                        }}
                                    >
                                        명
                                    </Typography>
                                </Box>

                            </TableCell>
                            <TableCell >
                                <Typography
                                    sx={{
                                        fontSize: '0.875rem'
                                    }}
                                >
                                    기술지원으로 인해 예상되는 고용증가 또는 감소 인원 수를 입력합니다. (효과가 없을 경우 빈칸)
                                </Typography>
                            </TableCell>
                        </TableRow>

                        {/* MoU */}
                        <TableRow>
                            <TableCell align="left">MoU</TableCell>
                            <TableCell >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center'
                                    }}
                                >
                                    <TextField
                                        size="small"
                                        inputProps={{
                                            style: {
                                                textAlign: 'right'
                                            }
                                        }}
                                        sx={{
                                            width: '50%'
                                        }}
                                        onChange={(e) => {
                                            setFormData({
                                                ...formData,
                                                RequestBenefitData: {
                                                    ...formData?.RequestBenefitData,
                                                    BeforeSupport: {
                                                        ...formData?.RequestBenefitData?.BeforeSupport,
                                                        MoU: e.target.value === '' ? undefined : e.target.value
                                                    }
                                                },
                                                RequestTempUniqueKey: formData?.RequestTempUniqueKey!
                                            })
                                        }}
                                    />
                                    <Typography
                                        sx={{
                                            fontSize: '0.875rem'
                                        }}
                                    >
                                        과(는) MoU 체결
                                    </Typography>
                                </Box>

                            </TableCell>
                            <TableCell >
                                <Typography
                                    sx={{
                                        fontSize: '0.875rem'
                                    }}
                                >
                                    기술지원으로 인해 신규 MoU체결이 예상된다면 해당 기업명을 입력합니다. (효과가 없을 경우 빈칸)
                                </Typography>
                            </TableCell>
                        </TableRow>

                        {/* 투자유치 */}
                        <TableRow>
                            <TableCell align="left">투자유치</TableCell>
                            <TableCell >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center'
                                    }}
                                >
                                    <TextField
                                        size="small"
                                        inputProps={{
                                            placeholder: '0',
                                            style: {
                                                textAlign: 'right'
                                            }
                                        }}
                                        sx={{
                                            width: '70%'
                                        }}
                                        onChange={(e) => {


                                            if (ObjectUtils.IsNullOrEmpty(e.target.value) || /^[0-9]{1,7}([.][0-9]{0,1})?$/.test(e.target.value)) {
                                                setFormData({
                                                    ...formData,
                                                    RequestBenefitData: {
                                                        ...formData?.RequestBenefitData,
                                                        BeforeSupport: {
                                                            ...formData?.RequestBenefitData?.BeforeSupport,
                                                            InvestmentAttraction: e.target.value === '' ? undefined : parseInt(e.target.value)
                                                        }
                                                    },
                                                    RequestTempUniqueKey: formData?.RequestTempUniqueKey!
                                                })
                                            }
                                            else {
                                                alert('입력값은 숫자 7자리 (소수점 1자리)만 가능합니다.');
                                                e.target.value = (/[0-9]{1,7}([.][0-9]{0,1})?/.exec(e.target.value) ?? [''])[0];
                                            }

                                        }}
                                    />
                                    <Typography
                                        sx={{
                                            fontSize: '0.875rem'
                                        }}
                                    >
                                        백만원
                                    </Typography>
                                </Box>

                            </TableCell>
                            <TableCell >
                                <Typography
                                    sx={{
                                        fontSize: '0.875rem'
                                    }}
                                >
                                    기술지원으로 인해 새로운 투자유치가 예상된다면 해당 금액을 입력합니다. (효과가 없을 경우 빈칸)
                                </Typography>
                            </TableCell>
                        </TableRow>

                        {/* 생산률 개선 */}
                        <TableRow>
                            <TableCell align="left">생산률 개선</TableCell>
                            <TableCell >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center'
                                    }}
                                >
                                    <TextField
                                        size="small"
                                        inputProps={{
                                            placeholder: '0',
                                            style: {
                                                textAlign: 'right'
                                            }
                                        }}
                                        sx={{
                                            width: '70%'
                                        }}
                                        onChange={(e) => {
                                            if (ObjectUtils.IsNullOrEmpty(e.target.value) || /^[0-9]{1,7}([.][0-9]{0,1})?$/.test(e.target.value)) {
                                                setFormData({
                                                    ...formData,
                                                    RequestBenefitData: {
                                                        ...formData?.RequestBenefitData,
                                                        BeforeSupport: {
                                                            ...formData?.RequestBenefitData?.BeforeSupport,
                                                            productionRateImprovement: e.target.value === '' ? undefined : parseInt(e.target.value)
                                                        }
                                                    },
                                                    RequestTempUniqueKey: formData?.RequestTempUniqueKey!
                                                })
                                            }
                                            else {
                                                alert('입력값은 숫자 7자리 (소수점 1자리)만 가능합니다.');
                                                e.target.value = (/[0-9]{1,7}([.][0-9]{0,1})?/.exec(e.target.value) ?? [''])[0];
                                            }
                                        }}
                                    />
                                    <Typography
                                        sx={{
                                            fontSize: '0.875rem'
                                        }}
                                    >
                                        %
                                    </Typography>
                                </Box>

                            </TableCell>
                            <TableCell >
                                <Typography
                                    sx={{
                                        fontSize: '0.875rem'
                                    }}
                                >
                                    기술지원으로 인해 생산률 개선이 예상된다면 생산률 증가분을 입력합니다. (효과가 없을 경우 빈칸)
                                </Typography>
                            </TableCell>
                        </TableRow>

                        {/* 불량률 개선 */}
                        <TableRow>
                            <TableCell align="left">불량률 개선</TableCell>
                            <TableCell >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center'
                                    }}
                                >
                                    <TextField
                                        size="small"
                                        inputProps={{
                                            placeholder: '0',
                                            style: {
                                                textAlign: 'right'
                                            }
                                        }}
                                        sx={{
                                            width: '70%'
                                        }}
                                        onChange={(e) => {
                                            if (ObjectUtils.IsNullOrEmpty(e.target.value) || /^[0-9]{1,7}([.][0-9]{0,1})?$/.test(e.target.value)) {
                                                setFormData({
                                                    ...formData,
                                                    RequestBenefitData: {
                                                        ...formData?.RequestBenefitData,
                                                        BeforeSupport: {
                                                            ...formData?.RequestBenefitData?.BeforeSupport,
                                                            productionDefectRateImprovement: e.target.value === '' ? undefined : parseInt(e.target.value)
                                                        }
                                                    },
                                                    RequestTempUniqueKey: formData?.RequestTempUniqueKey!
                                                })
                                            }
                                            else {
                                                alert('입력값은 숫자 7자리 (소수점 1자리)만 가능합니다.');
                                                e.target.value = (/[0-9]{1,7}([.][0-9]{0,1})?/.exec(e.target.value) ?? [''])[0];
                                            }
                                        }}
                                    />
                                    <Typography
                                        sx={{
                                            fontSize: '0.875rem'
                                        }}
                                    >
                                        %
                                    </Typography>
                                </Box>

                            </TableCell>
                            <TableCell >
                                <Typography
                                    sx={{
                                        fontSize: '0.875rem'
                                    }}
                                >
                                    기술지원으로 인해 불량률 개선이 예상된다면 불량률 감소분을 입력합니다. (효과가 없을 경우 빈칸)
                                </Typography>
                            </TableCell>
                        </TableRow>

                        {/* 1차 벤더사 등록 */}
                        <TableRow>
                            <TableCell align="left">1차 벤더사 등록</TableCell>
                            <TableCell >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center'
                                    }}
                                >
                                    <TextField
                                        size="small"
                                        inputProps={{
                                            style: {
                                                textAlign: 'right'
                                            }
                                        }}
                                        sx={{
                                            width: '50%'
                                        }}
                                        onChange={(e) => {

                                            setFormData({
                                                ...formData,
                                                RequestBenefitData: {
                                                    ...formData?.RequestBenefitData,
                                                    BeforeSupport: {
                                                        ...formData?.RequestBenefitData?.BeforeSupport,
                                                        Vender: e.target.value === '' ? undefined : e.target.value
                                                    }
                                                },
                                                RequestTempUniqueKey: formData?.RequestTempUniqueKey!
                                            })

                                        }}
                                    />
                                    <Typography
                                        sx={{
                                            fontSize: '0.875rem'
                                        }}
                                    >
                                        과(는) 벤더사 등록
                                    </Typography>
                                </Box>

                            </TableCell>
                            <TableCell >
                                <Typography
                                    sx={{
                                        fontSize: '0.875rem'
                                    }}
                                >
                                    기술지원으로 인해 1차 벤더사로 등록이 예상된다면 해당 기업명을 입력합니다. (효과가 없을 경우 빈칸)
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>

                {/* End Table */}
            </KeriStepItemContent>
            {/* <KeriStepItemContent
                title='계산서 발급 정보'
            >
                <KeriEditGrid>
                    <KeriEditGridInputField
                        title="계좌번호"
                        props={{
                            width: '25%',
                            titleBoxWidth : '100px'
                        }}
                    />
                    <KeriEditGridInputField
                    disableTitle
                        props={{
                            width: '41%',
                        }}
                    />
                    <KeriEditGridInputField
                        title="예금주"
                        props={{
                            width: '33%',
                            titleBoxWidth : '100px'
                        }}
                    />

                    <KeriEditGridInputField
                        title="담당자"
                        props={{
                            width: '33%',
                            titleBoxWidth : '100px'
                        }}
                    />
                    <KeriEditGridInputField
                        title="담당자"
                        props={{
                            width: '33%',
                            titleBoxWidth : '100px'
                        }}
                    />
                    <KeriEditGridInputField
                        title="이메일"
                        props={{
                            width: '33%',
                            titleBoxWidth : '100px'
                        }}
                    />
                </KeriEditGrid>
            </KeriStepItemContent> */}
        </KeriStepItemLayout>
    )
}

export default KeriRegisterTechServiceSubmitItem;