import { KeriFileTable, KeriFileTableItem } from "@ict-base/components/control/KeriFileTable";
import { IPageProp } from "@keri-base/interface/common/IPageProp";
import { Edit } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import InfoLayoutPage from '@keri-portal/component/layout/InfoLayoutPage'
import { IUserInfoInputData, IUserInfoInputError } from "@keri-portal/interfaces/process-object/account/IUserInfo";
import LoginChecker from "@keri-base/components/common/LoginChecker";
import ContentGroupLayout from "@keri-portal/component/layout/ContentGroupLayout";
import RequestKeriAPI from "@keri-portal/module/method/api-connect/RequestKeriAPI";
import CompanyInputField from "@keri-portal/component/input-field/CompanyInputField";
import ICTAccordion from "@ict-base/components/control/ICTAccordion";
import KeriActionButton from "@ict-base/components/control/KeriActionButton";
import UserInputField from "@keri-portal/component/input-field/UserInputField";
import UserInfoCommonValidationCheck from "@keri-portal/module/method/validation-check/account-info/UserInfoCommonValidationCheck";
import useLoginCheck from "@keri-portal/module/hook/useLoginCheck";
import InterfaceExtensionMethod from "@keri-portal/module/method/extension-method/InterfaceExtensionMethod";
import { ICompanyInfo } from "@keri-portal/interfaces/data-object/data-common/ICompanyInfo";
import { useLocation, useNavigate } from "react-router-dom";
import URLStructure from "@src/modules/structure/URLStructure";
import { ILoginSessionData } from "@src/modules/redux/reducer/UserDataReducer";


const UserBasicInfoViewPage = (prop: IPageProp) => {

    const [loginData, setLoginData] = useState<ILoginSessionData | undefined>(undefined);


    const navigate = useNavigate();
    const location = useLocation();

    const [compData, setCompData] = useState<ICompanyInfo>({});
    const [editUserData, setEditUserData] = useState<IUserInfoInputData>({});

    const [errorData, setErrorData] = useState<IUserInfoInputError>({});

    const [enableUserEdit, setEnableUserEdit] = useState<boolean>(false);


    const updateUserInfo = async () => {
        const validCheck = UserInfoCommonValidationCheck.UpdateInfo(editUserData);

        if (validCheck.state) {
            const requestData = await RequestKeriAPI.Account.UpdateUserData(editUserData, loginData?.token ?? '');

            if (requestData.state) {
                setEnableUserEdit(false);
                setEditUserData(InterfaceExtensionMethod.API.Response.CastAPIToMemberInfoData(requestData.data) ?? {})
            }
            else {
                alert(requestData.message)

                if (!requestData.tokenValid) {
                    navigate(URLStructure.UserLogin);
                }
            }
        }
        else {
            setErrorData(validCheck.errorData);
        }
    }


    const updateUserPassword = async () => {
        const validCheck = UserInfoCommonValidationCheck.UpdatePassword(editUserData);

        if (validCheck.state) {


            const requestData = await RequestKeriAPI.Account.ChangeUserPassword(editUserData.UserPassword!, loginData?.token ?? '');

            if (requestData.state) {
                alert(requestData.message);
            }
            else {
                alert(requestData.message)

                if (!requestData.tokenValid) {
                    navigate(URLStructure.UserLogin);
                }
            }



        }
        else {
            setErrorData(validCheck.errorData);
        }
    }

    const withDrawUserAccount = async () => {

        const requestData = await RequestKeriAPI.Account.WithDrawUserAccount(loginData?.token ?? '');

        if (requestData.state) {
            alert(requestData.message);
        }
        else {
            alert(requestData.message)

            if (!requestData.tokenValid) {
                navigate(URLStructure.UserLogin);
            }
        }
    }

    const getUserData = async () => {
        prop.EventFunction.EnableBackDrop(true);
        const requestData = await RequestKeriAPI.Account.GetUserAndCompanyInfo(loginData?.token);

        if (requestData.state) {
            prop.EventFunction.EnableBackDrop(false);

            setEditUserData(InterfaceExtensionMethod.API.Response.CastAPIToMemberInfoData(requestData.userData)!)
            setCompData({
                ...InterfaceExtensionMethod.API.Response.CastAPIToCompanyData(requestData.comData)!,
                CompanyFiles: requestData.comFiles?.map(x => {
                    return {
                        Index: parseInt(x.fileSn!, 10),
                        Label: x.fileCn!,
                        UploadedFile: x,
                        Category: 'CompanyAccount',
                        Prefix: 'COM_',
                        Required: x.isRequired === 'Y' ? true : false
                    }
                })
            })


        }
        else {
            alert(requestData.message);
            prop.EventFunction.EnableBackDrop(false);

            if (!requestData.tokenValid) {

                navigate(URLStructure.UserLogin);
            }
        }


    }



    useEffect(() => {
        if (loginData !== undefined) {
            if (loginData?.token !== undefined) {
                getUserData();
            }
            else {
                alert('로그인이 필요합니다.');
                navigate(`${URLStructure.UserLogin}?redirect=${location.pathname}${location.search}`);
            }
        }
    }, [loginData?.loadComplete])


    return (
        <LoginChecker
            onCheck={(state, data) => {
                if (state === 'LOGIN') {
                    setLoginData(data);
                }
                else if (state === 'UNLOGIN') {
                    setLoginData(data);
                }
            }}
        >
            <InfoLayoutPage
                title="회원 정보"
            >
                <Box>
                    <ICTAccordion
                        title="기업 정보"
                    >
                        <CompanyInputField
                            editMode="View"
                            dataObject={{
                                data: compData ?? {},
                                onChange: (item) => setCompData(item)
                            }}
                            error={{
                                data: errorData.UserCompany ?? {},
                                onChange: (item) => setErrorData({
                                    ...errorData,
                                    UserCompany: {
                                        ...errorData.UserCompany,
                                        ...item
                                    }
                                })
                            }}
                        />
                        <ContentGroupLayout
                            title="제출 자료"
                        >
                            <KeriFileTable
                                fileMode="Download"
                            >
                                {
                                    compData.CompanyFiles?.map(userFile => (
                                        <KeriFileTableItem
                                            fileMode="Download"
                                            fileObject={userFile}
                                            downloadProp={{
                                                enableDownload: true
                                            }}
                                            event={{
                                                onDownload: async (item) => {
                                                    const requestData = await RequestKeriAPI.File.DownloadFile(loginData?.token, item);

                                                    if (requestData.state) {
                                                        const jsonFile = new Blob([requestData.data]);
                                                        const element = document.createElement("a");

                                                        element.href = URL.createObjectURL(jsonFile);
                                                        element.download = item.UploadedFile?.orignlFileNm ?? 'undefined';
                                                        document.body.appendChild(element);
                                                        element.click();
                                                        element.remove();
                                                    }
                                                    else {
                                                        alert(requestData.message);
                                                    }
                                                }
                                            }}
                                            displayObject={{
                                                displayFileName: (item) => item.UploadedFile?.orignlFileNm,
                                                displayTitle: (item) => item.Label,
                                                displayDate: (item) => item.UploadedFile?.fileCreatDt ?? ''
                                            }}
                                        />
                                    ))
                                }
                            </KeriFileTable>
                            <Typography variant="body2" sx={{ color: '#7171dd' }}>
                                ⚠ 기업 정보의 수정은 관리자에게 문의하시기 바랍니다. <br />
                                관리자 이메일: admin_misc@keri.re.kr, 전화: 055-123-1234
                            </Typography>
                        </ContentGroupLayout>
                    </ICTAccordion>



                    <ICTAccordion
                        title="개인 정보"
                        isExpend={true}
                    >
                        {
                            !enableUserEdit &&
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'start',
                                    alignItems: 'center',
                                    pr: 2,
                                    pl: 2
                                }}
                            >
                                <KeriActionButton
                                    label="수정"
                                    icon={<Edit />}
                                    variant='outlined'
                                    onClick={() => setEnableUserEdit(true)}
                                />
                            </Box>
                        }

                        <UserInputField
                            editMode={!enableUserEdit ? "View" : 'Edit'}
                            dataObject={{
                                data: editUserData,
                                onChange: (item) => setEditUserData(item)
                            }}
                            error={{
                                data: errorData,
                                onChange: (item) => setErrorData(item)
                            }}
                            displayObject={{
                                UserID: false,
                                UserPassword: false,
                                ValidPassword: false,
                                UserType: false
                            }}
                        />
                        {
                            enableUserEdit &&
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'end',
                                    pr: 2
                                }}
                            >
                                <KeriActionButton
                                    label="취소"
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => {
                                        setErrorData({
                                            ...errorData,
                                            UserName: undefined,
                                            UserRank: undefined,
                                            UserDepartment: undefined,
                                            UserEmail: undefined,
                                            CertProcess: undefined,
                                            UserCallNumber: undefined,
                                            UserCellPhoneNumber: undefined
                                        });
                                        setEditUserData({
                                            ...editUserData,
                                            CertProcess: undefined
                                        })
                                        setEnableUserEdit(false);
                                    }}
                                    sx={{
                                        mr: 1
                                    }}
                                />
                                <KeriActionButton
                                    label="변경"
                                    variant="contained"
                                    onClick={() => updateUserInfo()}
                                />
                            </Box>
                        }
                    </ICTAccordion>

                    <ICTAccordion
                        title="비밀번호 변경"
                    >
                        <UserInputField
                            editMode={'Edit'}
                            dataObject={{
                                data: editUserData,
                                onChange: (item) => setEditUserData(item)
                            }}
                            error={{
                                data: errorData,
                                onChange: (item) => setErrorData(item)
                            }}
                            displayObject={{
                                UserID: false,
                                UserType: false,
                                UserName: false,
                                UserRank: false,
                                UserDepartment: false,
                                UserEmail: false,
                                UserCallNumber: false,
                                UserCellPhoneNumber: false
                            }}
                        />
                        {/* <KeriEditGrid>
                            <KeriEditGridInputField
                                title="현재 비밀번호"
                                props={{
                                    sx: {
                                        mb: 4
                                    }
                                }}
                            />

                            <KeriEditGridInputField
                                title="변경할 비밀번호"
                            />

                            <KeriEditGridInputField
                                title="비밀번호 재입력"
                            />
                        </KeriEditGrid> */}

                        <Typography variant="body2" sx={{ color: '#7171dd' }}>
                            ⚠ 비밀번호는 영문, 숫자, 기호(@$!%*?&)를 조합하여 10자리 이상이어야 합니다.
                        </Typography>


                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'end',
                                pr: 2
                            }}
                        >
                            <KeriActionButton
                                label="변경"
                                variant="contained"
                                onClick={() => updateUserPassword()}
                            />
                        </Box>


                    </ICTAccordion>

                    <ICTAccordion
                        title="회원 탈퇴 신청"
                    >
                        <Box>
                            <Typography>
                                회원 탈퇴 신청 시 관리자 승인 이후 탈퇴 절차가 진행됩니다.
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'end',
                                pr: 2
                            }}
                        >

                            <KeriActionButton
                                label="회원 탈퇴 신청"
                                color="error"
                                variant="contained"
                                onClick={() => withDrawUserAccount()}
                            />
                        </Box>
                    </ICTAccordion>
                </Box>



                {/* <KeriChangePasswordModal
                    open={changePasswordModalState}
                    matchRegex={RegLib.passwordReg}
                    onClose={() => {
                        setChangePasswordModalState(false);
                    }}
                    onSubmit={(changePassword, nowPassword) => {
                        prop.EventFunction.EnableBackDrop(true);
                        setChangePasswordModalState(false);



                        prop.EventFunction.EnableBackDrop(false);
                    }}

                /> */}

            </InfoLayoutPage>
        </LoginChecker>

    )
}

export default UserBasicInfoViewPage;