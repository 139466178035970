import { Search } from "@mui/icons-material";
import { Box, createTheme, Divider, IconButton, InputAdornment, MenuItem, Select, SxProps, TextField, TextFieldProps, ThemeProvider , Theme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { ObjectUtils } from "@ict-base/module/utils/ObjectUtils";

interface ISearchBox {
    label?: string | undefined,
    onSearch: (searchText: string) => void,
    sx?: SxProps<Theme>,
    width?: string | number,
    inputStyle?: SxProps<Theme>,
    fontSize?: string | number,
    maskRegex?: (text: string) => string,
    value? : string,
    onChange? : (changeString : string) => void
}

export const ICTSearchBox = (prop: ISearchBox) => {

    const [isEmpty, setIsEmpty] = useState<boolean>(true);
    const textRef = useRef<TextFieldProps>();

    const submitHandler = () => {
        prop.onSearch(textRef.current?.value as string);
    }

    return (
        <Box
            sx={{
                background: 'white',
                width : prop.width??'100%',
                ...prop.sx,
            }}
        >
            <TextField
                size="small"
                label={prop.label}
                value={prop.value}
                inputRef={textRef}
                sx={{
                    width : '100%',
                    ...prop.inputStyle
                }}
                InputLabelProps={{
                    shrink: false,
                    sx: {
                        display: !isEmpty ? 'none' : 'block',
                        '&.Mui-focused': {
                            color: 'rgba(0,0,0,0.6)'
                        },
                        fontSize: prop.fontSize
                    }
                }}
                InputProps={{
                    onKeyUp: (e) => {
                        if (e.key === 'Enter') {
                            submitHandler();
                        }

                    },
                    sx: {
                        pr: 0,
                        fontSize: prop.fontSize
                    },
                    endAdornment: (
                        <InputAdornment position='end' sx={{ m: 0, p: 0 }}>
                            <IconButton
                                onClick={() => {
                                    submitHandler();
                                }}
                            >
                                <Search />
                            </IconButton>
                        </InputAdornment>
                    )

                }}
                onChange={(e) => {
                    if (prop.maskRegex !== undefined) {
                        e.target.value = prop.maskRegex(e.target.value)
                    }
                    if (ObjectUtils.IsNullOrEmpty(e.target.value)) {
                        setIsEmpty(true);
                    }
                    else {
                        setIsEmpty(false);
                    }
                    prop.onChange && prop.onChange(e.currentTarget.value);
                }}
            />
        </Box>
    )
}


interface IICTSearchTypeBox<T> {
    type: T[],
    onSearch: (type: T, searchText: string | undefined) => void,
    display: {
        typeDisplayName: (item: T) => string,
        typeKey: (item: T) => string
    }
}

export const ICTSearchTypeBox = <T extends any>(prop: IICTSearchTypeBox<T>) => {

    const [searchType, setSearchType] = useState<T>(prop.type[0]);
    const [searchText, setSearchText] = useState<string | undefined>(undefined);


    useEffect(() => {

    },[searchType, searchText])
   
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                p : 1,
                boxSizing : 'border-box',
                border : '1px solid rgba(0,0,0,0.20)',
                borderRadius : '5px',
                background : 'white'
            }}
        >
                <Box>
                    <Select
                        value={prop.display.typeKey(searchType)}
                        variant="outlined"
                        size="small"
                        onChange={(e) => {
                            setSearchType(prop.type.find(x => prop.display.typeKey(x) === e.target.value)!);
                        }}
                        sx={{
                            '& fieldset' : {
                                border : 'none'
                            },
                            '& input' : {
                                p : 0
                            },
                            '& .MuiSelect-select' : {
                                p : 0,
                                minWidth : '50px',
                                textAlign : 'center'
                            }
                        }}
                    >
                        {
                            prop.type.map((typeItem, typeIndex) => (
                                <MenuItem key={typeIndex} value={prop.display.typeKey(typeItem)}>
                                    {prop.display.typeDisplayName(typeItem)}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </Box>
                <Divider orientation="vertical" />
                <Box>
                    <TextField
                        size="small"
                        sx={{
                            '& fieldset' : {
                                border : 'none'
                            },
                            '& input' : {
                                pt : 0,
                                pb : 0
                            }
                        }}
                        onChange={(e) => {
                            setSearchText(e.target.value);
                        }}
                        onKeyUp={(key) => {
                            if (key.code === 'Enter' || key.code === 'NumpadEnter' ) {
                                prop.onSearch(searchType, searchText);
                            }
                        }}
                    />
                </Box>

        </Box>
    )
}

// export default KeriSearchBox;