import { ObjectUtils } from "@ict-base/module/utils/ObjectUtils";
import RegexUtil from "@ict-base/module/utils/RegexUtil";
import { IRegisterTechServiceRequestInputFieldErrorData } from "@keri-portal/component/input-field/RegisterTechServiceRequestInputField";
import { ITechServiceObjectData, TechServiceType } from "@keri-portal/interfaces/data-object/tech-service/ITechService";



/** 기술지원신청 Validation Check */
export const TechServiceValidationCheck = (type? : TechServiceType, data? : ITechServiceObjectData) : {state : boolean , error : IRegisterTechServiceRequestInputFieldErrorData} => {
    switch(type){
        case 'A' : return TechServiceAnalysisValidationCheck(data);
        case 'T' : return TechServiceProductTestValidationCheck(data);
        case 'S' : return TechServiceSimulationValidationCheck(data);
        case 'R' : return TechServiceARVRServiceValidationCheck(data);
        case 'C' : return TechServiceControlValidationCheck(data);
        case 'P' : return TechService3DPrintingValidationCheck(data);
        default : return {state : false, error : {}};
    }
}


/** 제품성능시험 Validation Check */
const TechServiceProductTestValidationCheck = (data? : ITechServiceObjectData) : {state : boolean , error : IRegisterTechServiceRequestInputFieldErrorData} => {
    let state : boolean = true;
    let errorMessage : IRegisterTechServiceRequestInputFieldErrorData = {};

   
    if((data?.RequestInputData?.RequestPurpose??[]).length === 0){
        state = false;
        errorMessage.purpose = '신청 목적이 선택되지 않았습니다.'
    }

    if(data?.RequestInputData?.RequestProduct === undefined){
        state = false;
        errorMessage.product = '대상 제품이 선택되지 않았습니다.'
    }

    if(ObjectUtils.IsNullOrEmpty(data?.RequestInputData?.RequestInfo)){
        state = false;
        errorMessage.info = '신청 내용이 빈 값입니다.'
    }

    if(ObjectUtils.DateToString(data?.RequestInputData?.RequestDesiredDate) === undefined){
        state = false;
        errorMessage.desiredDate = '완료 희망일이 설정되지 않았습니다.'
    }

    if(data?.RequestInputData?.RequestTestMethod === undefined){
        state = false;
        errorMessage.testMethod = '시험 방법이 선택되지 않았습니다.'
    }

    if(data?.RequestInputData?.RequestTestType === undefined){
        state = false;
        errorMessage.testType = '시험 유형이 선택되지 않았습니다.'
    }

    if(data?.RequestInputData?.RequestTestItem === undefined){
        state = false;
        errorMessage.testItem = '시험 항목이 선택되지 않았습니다.'
    }


    
    if(data?.RequestInputFile?.filter(x=>x.Required && x.UploadedFile?.streFileNm === undefined).length !== 0){
        state = false;
        errorMessage.requestFiles = '필수 자료가 업로드되지 않았습니다.'
    }
    else if(data?.RequestInputFile?.filter(x=>x.UploadedFile?.streFileNm === undefined).length !== 0){
        state = false;
        errorMessage.requestFiles = '추가한 참고자료에 파일이 업로드되지 않았습니다. \n 불필요한 참고자료를 제거해주십시오.'
    }


    if(ObjectUtils.IsNullOrEmpty(data?.RequesterData?.RequesterDepartment)){
        state = false;
        errorMessage.requesterDept = '부서가 입력되지 않았습니다.'
    }

    if(ObjectUtils.IsNullOrEmpty(data?.RequesterData?.RequesterName)){
        state = false;
        errorMessage.requesterName = '신청자가 입력되지 않았습니다.'
    }

    if(ObjectUtils.IsNullOrEmpty(data?.RequesterData?.RequesterCallNumber)){
        state = false;
        errorMessage.requesterCallNumber = '연락처가 입력되지 않았습니다.'
    }
    else if(!RegexUtil.CheckString(RegexUtil.RegexType.PhoneNumber,data?.RequesterData?.RequesterCallNumber)){
        state = false;
        errorMessage.requesterCallNumber = '연락처 형식이 맞지 않습니다.'
    }
    

    if(ObjectUtils.IsNullOrEmpty(data?.RequesterData?.RequesterEmail)){
        state = false;
        errorMessage.requesterEmail = '이메일이 입력되지 않았습니다.'
    }
    else if(!RegexUtil.CheckString(RegexUtil.RegexType.Email,data?.RequesterData?.RequesterEmail)){
        state = false;
        errorMessage.requesterEmail = '이메일 형식이 맞지 않습니다.'
    }


    return {
        state : state,
        error : errorMessage
    }
}

/** 해석기술지원 Validation Check */
const TechServiceAnalysisValidationCheck = (data? : ITechServiceObjectData): {state : boolean , error : IRegisterTechServiceRequestInputFieldErrorData} =>{
    let state : boolean = true;
    let errorMessage : IRegisterTechServiceRequestInputFieldErrorData = {};

    // if(ObjectUtils.IsNullOrEmpty(data?.RequestInputData?.RequestTitle)){
    //     state = false;
    //     errorMessage.title = '지원요청 제목이 빈 값입니다.'
    // }

    if((data?.RequestInputData?.RequestPurpose??[]).length === 0){
        state = false;
        errorMessage.purpose = '해석 목적이 선택되지 않았습니다.'
    }

    if(data?.RequestInputData?.RequestProduct === undefined){
        state = false;
        errorMessage.product = '대상 제품이 선택되지 않았습니다.'
    }

    if(ObjectUtils.IsNullOrEmpty(data?.RequestInputData?.RequestInfo)){
        state = false;
        errorMessage.info = '신청 내용이 빈 값입니다.'
    }

    if(ObjectUtils.DateToString(data?.RequestInputData?.RequestDesiredDate) === undefined){
        state = false;
        errorMessage.desiredDate = '완료 희망일이 설정되지 않았습니다.'
    }
    
    // if(data?.RequestInputData?.RequestExpertUser === undefined){
    //     state = false;
    //     errorMessage.expert = '전문가가 설정되지 않았습니다.'
    // }






    if(data?.RequestInputFile?.filter(x=>x.Required && x.UploadedFile?.streFileNm === undefined).length !== 0){
        state = false;
        errorMessage.requestFiles = '필수 자료가 업로드되지 않았습니다.'
    }
    else if(data?.RequestInputFile?.filter(x=>x.UploadedFile?.streFileNm === undefined).length !== 0){
        state = false;
        errorMessage.requestFiles = '추가한 참고자료에 파일이 업로드되지 않았습니다. \n 불필요한 참고자료를 제거해주십시오.'
    }





    if(ObjectUtils.IsNullOrEmpty(data?.RequesterData?.RequesterDepartment)){
        state = false;
        errorMessage.requesterDept = '부서가 입력되지 않았습니다.'
    }

    if(ObjectUtils.IsNullOrEmpty(data?.RequesterData?.RequesterName)){
        state = false;
        errorMessage.requesterName = '신청자가 입력되지 않았습니다.'
    }

    if(ObjectUtils.IsNullOrEmpty(data?.RequesterData?.RequesterCallNumber)){
        state = false;
        errorMessage.requesterCallNumber = '연락처가 입력되지 않았습니다.'
    }
    else if(!RegexUtil.CheckString(RegexUtil.RegexType.PhoneNumber,data?.RequesterData?.RequesterCallNumber)){
        state = false;
        errorMessage.requesterCallNumber = '연락처 형식이 맞지 않습니다.'
    }
    

    if(ObjectUtils.IsNullOrEmpty(data?.RequesterData?.RequesterEmail)){
        state = false;
        errorMessage.requesterEmail = '이메일이 입력되지 않았습니다.'
    }
    else if(!RegexUtil.CheckString(RegexUtil.RegexType.Email,data?.RequesterData?.RequesterEmail)){
        state = false;
        errorMessage.requesterEmail = '이메일 형식이 맞지 않습니다.'
    }

    return {
        state : state,
        error : errorMessage
    }
}

/** 지능형 시뮬레이션 Validation Check */
const TechServiceSimulationValidationCheck = (data? : ITechServiceObjectData): {state : boolean , error : IRegisterTechServiceRequestInputFieldErrorData} =>{
    let state : boolean = true;
    let errorMessage : IRegisterTechServiceRequestInputFieldErrorData = {};

   

    



    if((data?.RequestInputData?.RequestPurpose??[]).length === 0){
        state = false;
        errorMessage.purpose = '신청 목적이 선택되지 않았습니다.'
    }

    if(data?.RequestInputData?.RequestProduct === undefined){
        state = false;
        errorMessage.product = '대상 제품이 선택되지 않았습니다.'
    }

    if(ObjectUtils.IsNullOrEmpty(data?.RequestInputData?.RequestInfo)){
        state = false;
        errorMessage.info = '신청 내용이 빈 값입니다.'
    }

    if(ObjectUtils.DateToString(data?.RequestInputData?.RequestDesiredDate) === undefined){
        state = false;
        errorMessage.desiredDate = '완료 희망일이 설정되지 않았습니다.'
    }

    
    if(data?.RequestInputFile?.filter(x=>x.Required && x.UploadedFile?.streFileNm === undefined).length !== 0){
        state = false;
        errorMessage.requestFiles = '필수 자료가 업로드되지 않았습니다.'
    }
    else if(data?.RequestInputFile?.filter(x=>x.UploadedFile?.streFileNm === undefined).length !== 0){
        state = false;
        errorMessage.requestFiles = '추가한 참고자료에 파일이 업로드되지 않았습니다. \n 불필요한 참고자료를 제거해주십시오.'
    }

    if(ObjectUtils.IsNullOrEmpty(data?.RequesterData?.RequesterDepartment)){
        state = false;
        errorMessage.requesterDept = '부서가 입력되지 않았습니다.'
    }

    if(ObjectUtils.IsNullOrEmpty(data?.RequesterData?.RequesterName)){
        state = false;
        errorMessage.requesterName = '신청자가 입력되지 않았습니다.'
    }

    if(ObjectUtils.IsNullOrEmpty(data?.RequesterData?.RequesterCallNumber)){
        state = false;
        errorMessage.requesterCallNumber = '연락처가 입력되지 않았습니다.'
    }
    else if(!RegexUtil.CheckString(RegexUtil.RegexType.PhoneNumber,data?.RequesterData?.RequesterCallNumber)){
        state = false;
        errorMessage.requesterCallNumber = '연락처 형식이 맞지 않습니다.'
    }
    

    if(ObjectUtils.IsNullOrEmpty(data?.RequesterData?.RequesterEmail)){
        state = false;
        errorMessage.requesterEmail = '이메일이 입력되지 않았습니다.'
    }
    else if(!RegexUtil.CheckString(RegexUtil.RegexType.Email,data?.RequesterData?.RequesterEmail)){
        state = false;
        errorMessage.requesterEmail = '이메일 형식이 맞지 않습니다.'
    }



    return {
        state : state,
        error : errorMessage
    }
}

/** AR/VR 서비스 Validation Check */
const TechServiceARVRServiceValidationCheck = (data? : ITechServiceObjectData): {state : boolean , error : IRegisterTechServiceRequestInputFieldErrorData} =>{
    let state : boolean = true;
    let errorMessage : IRegisterTechServiceRequestInputFieldErrorData = {};

    if((data?.RequestInputData?.RequestPurpose??[]).length === 0){
        state = false;
        errorMessage.purpose = '신청 목적이 선택되지 않았습니다.'
    }

    if(data?.RequestInputData?.RequestProduct === undefined){
        state = false;
        errorMessage.product = '대상 제품이 선택되지 않았습니다.'
    }

    if(ObjectUtils.IsNullOrEmpty(data?.RequestInputData?.RequestInfo)){
        state = false;
        errorMessage.info = '신청 내용이 빈 값입니다.'
    }
    
    if(ObjectUtils.DateToString(data?.RequestInputData?.RequestDesiredDate) === undefined){
        state = false;
        errorMessage.desiredDate = '완료 희망일이 설정되지 않았습니다.'
    }

    
    if(data?.RequestInputFile?.filter(x=>x.Required && x.UploadedFile?.streFileNm === undefined).length !== 0){
        state = false;
        errorMessage.requestFiles = '필수 자료가 업로드되지 않았습니다.'
    }
    else if(data?.RequestInputFile?.filter(x=>x.UploadedFile?.streFileNm === undefined).length !== 0){
        state = false;
        errorMessage.requestFiles = '추가한 참고자료에 파일이 업로드되지 않았습니다. \n 불필요한 참고자료를 제거해주십시오.'
    }


    if(ObjectUtils.IsNullOrEmpty(data?.RequesterData?.RequesterDepartment)){
        state = false;
        errorMessage.requesterDept = '부서가 입력되지 않았습니다.'
    }

    if(ObjectUtils.IsNullOrEmpty(data?.RequesterData?.RequesterName)){
        state = false;
        errorMessage.requesterName = '신청자가 입력되지 않았습니다.'
    }

    if(ObjectUtils.IsNullOrEmpty(data?.RequesterData?.RequesterCallNumber)){
        state = false;
        errorMessage.requesterCallNumber = '연락처가 입력되지 않았습니다.'
    }
    else if(!RegexUtil.CheckString(RegexUtil.RegexType.PhoneNumber,data?.RequesterData?.RequesterCallNumber)){
        state = false;
        errorMessage.requesterCallNumber = '연락처 형식이 맞지 않습니다.'
    }
    

    if(ObjectUtils.IsNullOrEmpty(data?.RequesterData?.RequesterEmail)){
        state = false;
        errorMessage.requesterEmail = '이메일이 입력되지 않았습니다.'
    }
    else if(!RegexUtil.CheckString(RegexUtil.RegexType.Email,data?.RequesterData?.RequesterEmail)){
        state = false;
        errorMessage.requesterEmail = '이메일 형식이 맞지 않습니다.'
    }
   
    return {
        state : state,
        error : errorMessage
    }
}

/** 제어 및 신호측정 서비스 Validation Check */
const TechServiceControlValidationCheck = (data? : ITechServiceObjectData): {state : boolean , error : IRegisterTechServiceRequestInputFieldErrorData} =>{
    let state : boolean = true;
    let errorMessage : IRegisterTechServiceRequestInputFieldErrorData = {};



   
    if((data?.RequestInputData?.RequestPurpose??[]).length === 0){
        state = false;
        errorMessage.purpose = '신청 목적이 선택되지 않았습니다.'
    }

    if(data?.RequestInputData?.RequestProduct === undefined){
        state = false;
        errorMessage.product = '대상 제품이 선택되지 않았습니다.'
    }

    if(ObjectUtils.IsNullOrEmpty(data?.RequestInputData?.RequestInfo)){
        state = false;
        errorMessage.info = '신청 내용이 빈 값입니다.'
    }
   
    if(ObjectUtils.DateToString(data?.RequestInputData?.RequestDesiredDate) === undefined){
        state = false;
        errorMessage.desiredDate = '완료 희망일이 설정되지 않았습니다.'
    }

    
    if(data?.RequestInputFile?.filter(x=>x.Required && x.UploadedFile?.streFileNm === undefined).length !== 0){
        state = false;
        errorMessage.requestFiles = '필수 자료가 업로드되지 않았습니다.'
    }
    else if(data?.RequestInputFile?.filter(x=>x.UploadedFile?.streFileNm === undefined).length !== 0){
        state = false;
        errorMessage.requestFiles = '추가한 참고자료에 파일이 업로드되지 않았습니다. \n 불필요한 참고자료를 제거해주십시오.'
    }


    if(ObjectUtils.IsNullOrEmpty(data?.RequesterData?.RequesterDepartment)){
        state = false;
        errorMessage.requesterDept = '부서가 입력되지 않았습니다.'
    }

    if(ObjectUtils.IsNullOrEmpty(data?.RequesterData?.RequesterName)){
        state = false;
        errorMessage.requesterName = '신청자가 입력되지 않았습니다.'
    }

    if(ObjectUtils.IsNullOrEmpty(data?.RequesterData?.RequesterCallNumber)){
        state = false;
        errorMessage.requesterCallNumber = '연락처가 입력되지 않았습니다.'
    }
    else if(!RegexUtil.CheckString(RegexUtil.RegexType.PhoneNumber,data?.RequesterData?.RequesterCallNumber)){
        state = false;
        errorMessage.requesterCallNumber = '연락처 형식이 맞지 않습니다.'
    }
    

    if(ObjectUtils.IsNullOrEmpty(data?.RequesterData?.RequesterEmail)){
        state = false;
        errorMessage.requesterEmail = '이메일이 입력되지 않았습니다.'
    }
    else if(!RegexUtil.CheckString(RegexUtil.RegexType.Email,data?.RequesterData?.RequesterEmail)){
        state = false;
        errorMessage.requesterEmail = '이메일 형식이 맞지 않습니다.'
    }


    return {
        state : state,
        error : errorMessage
    }
}

/** 3D프린팅 서비스 Validation Check */
const TechService3DPrintingValidationCheck = (data? : ITechServiceObjectData): {state : boolean , error : IRegisterTechServiceRequestInputFieldErrorData} =>{
    let state : boolean = true;
    let errorMessage : IRegisterTechServiceRequestInputFieldErrorData = {};


    
    if((data?.RequestInputData?.RequestPurpose??[]).length === 0){
        state = false;
        errorMessage.purpose = '신청 목적이 선택되지 않았습니다.'
    }

    if(data?.RequestInputData?.RequestProduct === undefined){
        state = false;
        errorMessage.product = '대상 제품이 선택되지 않았습니다.'
    }

    if(ObjectUtils.IsNullOrEmpty(data?.RequestInputData?.RequestInfo)){
        state = false;
        errorMessage.info = '신청 내용이 빈 값입니다.'
    }
   
    if(ObjectUtils.DateToString(data?.RequestInputData?.RequestDesiredDate) === undefined){
        state = false;
        errorMessage.desiredDate = '완료 희망일이 설정되지 않았습니다.'
    }

    
    if(data?.RequestInputFile?.filter(x=>x.Required && x.UploadedFile?.streFileNm === undefined).length !== 0){
        state = false;
        errorMessage.requestFiles = '필수 자료가 업로드되지 않았습니다.'
    }
    else if(data?.RequestInputFile?.filter(x=>x.UploadedFile?.streFileNm === undefined).length !== 0){
        state = false;
        errorMessage.requestFiles = '추가한 참고자료에 파일이 업로드되지 않았습니다. \n 불필요한 참고자료를 제거해주십시오.'
    }


    if(ObjectUtils.IsNullOrEmpty(data?.RequesterData?.RequesterDepartment)){
        state = false;
        errorMessage.requesterDept = '부서가 입력되지 않았습니다.'
    }

    if(ObjectUtils.IsNullOrEmpty(data?.RequesterData?.RequesterName)){
        state = false;
        errorMessage.requesterName = '신청자가 입력되지 않았습니다.'
    }

    if(ObjectUtils.IsNullOrEmpty(data?.RequesterData?.RequesterCallNumber)){
        state = false;
        errorMessage.requesterCallNumber = '연락처가 입력되지 않았습니다.'
    }
    else if(!RegexUtil.CheckString(RegexUtil.RegexType.PhoneNumber,data?.RequesterData?.RequesterCallNumber)){
        state = false;
        errorMessage.requesterCallNumber = '연락처 형식이 맞지 않습니다.'
    }
    

    if(ObjectUtils.IsNullOrEmpty(data?.RequesterData?.RequesterEmail)){
        state = false;
        errorMessage.requesterEmail = '이메일이 입력되지 않았습니다.'
    }
    else if(!RegexUtil.CheckString(RegexUtil.RegexType.Email,data?.RequesterData?.RequesterEmail)){
        state = false;
        errorMessage.requesterEmail = '이메일 형식이 맞지 않습니다.'
    }


   
    return {
        state : state,
        error : errorMessage
    }
}