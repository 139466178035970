// import KeriBaseLayout from "@keri-component/default/layout/KeriBaseLayout";
import { IPageProp } from "@keri-base/interface/common/IPageProp";
import { useLocation, useParams } from "react-router-dom";
// import DefaultLayoutPage from "./layout/DefaultLayoutPage";
import { useEffect, useState } from "react";
import RequestKeriAPI from "@keri-portal/module/method/api-connect/RequestKeriAPI";
import URLStructure from "@src/modules/structure/URLStructure";

const StaticHTMLViewPage = (prop: IPageProp) => {
    const param = useParams();
    const htmlFileName = param.staticFileName + '.html';

    const [iframeHeight, setIframeHeight] = useState(0);
    const location = useLocation();

    const [htmlCode, setHtmlCode] = useState<string>('')

    // let htmlCode = undefined;
    // try {
    //     htmlCode = require('./../../../public/test/' + htmlFileName);
    // }
    // catch { }

    const getHTMLCode = async (fileName: string) => {

        const getRequest = await RequestKeriAPI.Common.GetStaticPage(fileName);

        if (getRequest.state) {
            setHtmlCode(getRequest.code.replaceAll('[%NOWURL%]',location.pathname).replaceAll('[%MAINURL%]',URLStructure.Main));
        }
    }


    useEffect(() => {
        getHTMLCode(htmlFileName);
    }, [param])


    return (
            <div dangerouslySetInnerHTML={{ __html: htmlCode }} style={{marginBottom:'30px'}} />

    )

    // return (
    //     <InfoLayoutPage>
    //         <Paper
    //             sx={{
    //                 height : '100%'
    //             }}
    //         >
    //         <div dangerouslySetInnerHTML={{ __html: `<iframe src="/assets/html/${htmlFileName}" width="100%" style="border:none"></iframe>` }} />
    //         </Paper>
    //     </InfoLayoutPage>
    // )
}

export default StaticHTMLViewPage;