import { ILoadControl, InitLoadControl, LoadControlUnit } from "@ict-base/components/common/LoadControl";
import KeriActionButton from "@ict-base/components/control/KeriActionButton";
import { KeriEditGridWebEditorField } from "@ict-base/components/control/KeriEditGrid";
import LoginChecker from "@keri-base/components/common/LoginChecker";
import { IPageProp } from "@keri-base/interface/common/IPageProp";
import { PlatformError } from "@keri-base/interface/common/PlatformError";
import KeriBoardLockUnit from "@keri-portal/component/control/board/KeriBoardLockUnit";
import InfoLayoutPage from "@keri-portal/component/layout/InfoLayoutPage";
import { IUploadFileVO } from "@keri-portal/interfaces/api/keri-api/object/IUploadFileVO";
import { IUploadFile } from "@keri-portal/interfaces/control/IUploadFile";
import { IBoard, IBoardItem } from "@keri-portal/interfaces/data-object/data-common/IBoard";
import RequestKeriAPI from "@keri-portal/module/method/api-connect/RequestKeriAPI";
import { Error, Refresh } from "@mui/icons-material";
import { CircularProgress, Typography, Paper, TextField, IconButton } from "@mui/material";
import { Box } from "@mui/system";
import { ILoginSessionData } from "@src/modules/redux/reducer/UserDataReducer";
import { useEffect, useRef } from "react";
import { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ISavePopperRef, IUploadSectionRef, SavePopper, UploadSection } from "./ShareBoardWritePage";

const ShareBoardEditPage = (prop: IPageProp) => {

    const [loginData, setLoginData] = useState<ILoginSessionData | undefined>(undefined);
    const [loadControl, setLoadControl] = useState<ILoadControl>(InitLoadControl);

    const param = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    const lockQuery = location.search.replace('?', '').split('&').map(x => ({ key: x.split('=')[0], value: x.split('=')[1] })).find(x => x.key === 'auth')?.value;

    const [boardMaster, setBoardMaster] = useState<IBoard | undefined>(undefined);
    const [boardItem, setBoardItem] = useState<IBoardItem | undefined>(undefined);

    const [lockData, setLockData] = useState<{ isLock: boolean, password?: string }>({ isLock: false });

    const [editData, setEditData] = useState<{ title: string, content?: string, newAuthKey?: string } | undefined>();


    const uploadRef = useRef<IUploadSectionRef | null>(null);
    const saveRef = useRef<ISavePopperRef | null>(null);

    const [fileList, setFileList] = useState<IUploadFile<IUploadFileVO>[]>([]);



    //#region function

    const funcObj = {
        GetBoardMasterData: async (boardID: string) => {

            const getData = await RequestKeriAPI.Board.GetBoardContentList(loginData?.token, boardID, undefined, 0, 1);

            if (getData.state) {
                setBoardMaster(getData.resultData);
            }
            else {
                throw new PlatformError(getData.message);
            }
        },
        GetBoardItemData: async (boardID: string, boardItemID: string, authKey?: string) => {
            const getData = await RequestKeriAPI.Board.GetBoardItemData(loginData?.token, boardID, boardItemID, authKey, lockQuery === 'y');

            if (getData.state) {
                if ((getData.resultData?.BoardList ?? []).length !== 0) {
                    setBoardItem(getData.resultData!.BoardList[0]);
                    setEditData({
                        title: getData.resultData!.BoardList[0].BoardItemTitle,
                        content: getData.resultData?.BoardList[0].BoardItemContent,
                    })

                    setFileList(getData.fileList ?? []);

                    console.log(getData.fileList);


                }

            }
            else {
                throw new PlatformError(getData.message);
            }
        }

    }

    const loadContent = async (boardID: string, boardItemID: string) => {
        setLoadControl({
            isLoading: true,
            isComplete: false
        })

        try {
            await funcObj.GetBoardMasterData(boardID);

            console.log(lockQuery);


            if (location.search.replace('?', '').split('&').map(x => ({ key: x.split('=')[0], value: x.split('=')[1] })).find(x => x.key === 'auth') !== undefined) {
                setLockData({ isLock: true });
            }
            else {
                loadContentBeforeAuth(boardID, boardItemID);
            }

            setLoadControl({
                isLoading: false,
                isComplete: true,
            })
        }
        catch (e) {
            let errMessage: string | undefined = 'undefined';
            if (e instanceof PlatformError) {
                errMessage = e.message;
            }
            else {
                errMessage = "시스템 에러";
            }

            setLoadControl({
                isLoading: false,
                isComplete: true,
                errorMessage: errMessage
            })
        }
    }

    const loadContentBeforeAuth = async (boardID: string, boardItemID: string, password?: string) => {
        await funcObj.GetBoardItemData(boardID, boardItemID, password ?? lockData.password);
    }

    const checkParams = () => {
        console.log('checkParam');
        if (param.boardID === undefined || param.boardItemID === undefined) {
            setLoadControl({
                isLoading: false,
                isComplete: true,
                errorMessage: '게시글을 찾을 수 없습니다.'
            })
        }
        else {
            if (loginData?.loadComplete) {
                loadContent(param.boardID, param.boardItemID);
            }
        }
    }

    //#endregion

    useEffect(() => {
        checkParams();
    }, [param, loginData])


    useEffect(() => {
        console.log('lockdata', lockData);
    }, [lockData])




    return (
        <LoginChecker
            onCheck={(state, data) => {
                console.log('loginState', state);
                if (state !== undefined) {
                    setLoginData({ ...data });
                }
            }}
        >

            <LoadControlUnit
                isLoading={loadControl.isLoading && !loadControl.isComplete}
                errorMessage={loadControl.errorMessage}
                loadComponent={
                    <InfoLayoutPage>
                        <Paper>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: '100%',
                                    height: '200px'
                                }}
                            >
                                <CircularProgress color="inherit" />
                            </Box>
                        </Paper>
                    </InfoLayoutPage>

                }
                errorComponent={
                    <InfoLayoutPage>
                        <Paper>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: '100%',
                                    height: '200px',
                                    flexDirection: 'column'
                                }}
                            >
                                <Error color="error" sx={{ fontSize: '2rem', mb: 1.5 }} />
                                <Typography variant="h6">
                                {loadControl.errorMessage}
                                </Typography>
                                {/* <Typography variant="body1" sx={{ color: 'rgba(0,0,0,0.5)' }}>
                                    {loadControl.errorMessage}
                                </Typography> */}
                                <IconButton
                                    onClick={() => checkParams()}
                                    disableRipple
                                >
                                    <Refresh
                                        sx={{
                                            color: 'rgba(0,0,0,0.3)'
                                        }}
                                    />
                                </IconButton>
                            </Box>
                        </Paper>
                    </InfoLayoutPage>
                }
            >
                <KeriBoardLockUnit
                    isLock={lockData.isLock}
                    checkAuth={async (password) => {

                        const checkAuth = await RequestKeriAPI.Board.GetBoardItemData(loginData?.token, param.boardID, param.boardItemID, password, true);

                        return {
                            state: checkAuth.state,
                            errorMessage: checkAuth.message
                        }
                    }}
                    onAuth={async (state, password) => {
                        if (state) {
                            setLoadControl({
                                isLoading: true,
                                isComplete: false,
                            })

                            setLockData({ isLock: false, password: password });
                            try {
                                await loadContentBeforeAuth(param.boardID!, param.boardItemID!, password);
                                setLoadControl({
                                    isLoading: false,
                                    isComplete: true,
                                })
                            }
                            catch (e) {
                                let errMessage: string | undefined = 'undefined';
                                if (e instanceof PlatformError) {
                                    errMessage = e.message;
                                }
                                else {
                                    errMessage = "시스템 에러";
                                }

                                setLoadControl({
                                    isLoading: false,
                                    isComplete: true,
                                    errorMessage: errMessage
                                })
                            }
                        }
                    }}
                >
                    <InfoLayoutPage
                        title={boardMaster?.BoardTitle}
                        showChild={boardItem !== undefined}
                    >
                        <Paper
                            sx={{
                                p: 2,
                                mb: 2
                            }}
                        >
                            <Box>
                                <Box
                                    sx={{
                                        mb: 1
                                    }}
                                >
                                    <TextField
                                        fullWidth
                                        size="small"
                                        value={editData?.title}
                                        onChange={(e) => {
                                            setEditData({
                                                ...editData,
                                                title: e.currentTarget.value
                                            })
                                        }}
                                    />
                                </Box>
                            </Box>
                            <Box>
                                <KeriEditGridWebEditorField
                                    defaultValue={boardItem?.BoardItemContent}
                                    disableTitle
                                    value={editData?.content}
                                    onChange={(text) => {
                                        setEditData({
                                            ...editData!,
                                            content: text
                                        })
                                    }}
                                />
                            </Box>
                            <Box
                                sx={{

                                    m: 1,
                                    mt: 2,
                                    display: 'flex',
                                    justifyContent: 'start',
                                    alignItems: 'start'
                                }}
                            >
                                <Box
                                    sx={{
                                        width: '160px',
                                        height: '40px',
                                        display: 'flex',
                                        justifyContent: 'start',
                                        alignItems: 'center'
                                    }}
                                >
                                    <Typography>
                                        첨부 파일
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        width: 'calc(100% - 160px)'
                                    }}
                                >
                                    <UploadSection
                                        ref={uploadRef}
                                        defaultValue={fileList}
                                    />
                                </Box>

                            </Box>
                            {/* <Box>
                                {
                                    lockData.password !== undefined &&
                                    <KeriEditGrid>
                                        <KeriEditGridInputField
                                            title="비밀번호"
                                            type={'password'}
                                            onChange={(changeText) => {
                                                let editObj = {...editData!};
                                                editObj.newAuthKey =  ObjectUtils.IsNullOrEmpty(changeText) ? undefined : changeText;
                                                setEditData(editObj);
                                            }}
                                        />
                                    </KeriEditGrid>
                                }
                            </Box> */}
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'end',
                                    alignItems: 'center'
                                }}
                            >
                                <KeriActionButton
                                    label="취소"
                                    color="error"
                                    variant="contained"
                                    onClick={() => navigate(-1)}
                                    sx={{
                                        mr: 1
                                    }}
                                />
                                <KeriActionButton
                                    label="저장"
                                    variant="contained"
                                    onClick={async () => {
                                        // funcObj.EditBoardItem(param.boardID!, param.boardItemID!, editData!.title, editData?.content, lockData.password)

                                        const editFiles = uploadRef.current?.GetUploadFiles() ?? [];


                                        console.log('EDOR',editFiles);

                                        let removeList: IUploadFile<IUploadFileVO>[] = [];

                                        for (let orgItem of fileList) {
                                            if (editFiles.find(x => x.Index === orgItem.Index) === undefined) {
                                                removeList.push(orgItem);
                                            }
                                        }

                                        const editState = await saveRef.current?.RunEdit({
                                            boardID: param.boardID!,
                                            boardItemID: param.boardItemID!,
                                            title: editData!.title,
                                            content: editData!.content,
                                            password: lockData.password,
                                            addFiles: editFiles.filter(x => x.UploadedFile === undefined),
                                            removeFiles: removeList
                                        })

                                        console.log('EDIT STATE', editState);

                                        if (editState?.state ?? false) {
                                            alert('수정이 완료되었습니다');
                                            navigate(`${process.env.PUBLIC_URL}/share-board/${param.boardID}/${param.boardItemID}${location.search.replace('?', '').split('&').map(x => ({ key: x.split('=')[0], value: x.split('=')[1] })).find(x => x.key === 'auth') !== undefined ? '?auth=y' : ''}`);
                                        }
                                        else{
                                            alert(editState?.message);
                                        }
                                    }}

                                />
                            </Box>
                        </Paper>

                        <SavePopper ref={saveRef} />
                    </InfoLayoutPage>
                </KeriBoardLockUnit>

            </LoadControlUnit>



        </LoginChecker>
    )
}


export default ShareBoardEditPage;