import { IKeriAPIState } from "@ict-base/module/service/ICTRequestAPI";
import CallAPI from "@ict-base/module/utils/CallAPI";
import SessionStorageServ from "@ict-base/module/utils/SessionStorageServ";
import RegexUtil from "@ict-base/module/utils/RegexUtil";
import { IListPagination } from "@keri-base/interface/control/IListPagination";
import { IAPIChangePasswordNonLoginRequestBody, IAPIDownloadFileRequestBody, IAPIGetStaticHTMLRequestBody, IAPILoginRequestBody, IAPIRemoveTempFileRequestBody, IAPISendCertCodeRequestBody as IAPIRequestCertCodeRequestBody, IAPITechCodeRequestBody, IAPIValidCertCodeRequestBody } from "@keri-portal/interfaces/api/keri-api/IKeriAPIRequest";
import { IAPIAccountRegisterResult, IAPICompanyResult, IAPIGetStaticHTMLResult, IAPIGetUserAndCompanyInfoResult, IAPILoginResult, IAPIRemoveTempFileResult, IKeriAPIResponse, IAPITechCodeResult, IAPITokenValidationCheckResult, IAPIUpdateUserResult, IAPIUploadFileResult, IAPIChangePasswordNonLoginResult } from "@keri-portal/interfaces/api/keri-api/IKeriAPIResponse";
import { IUploadFileVO } from "@keri-portal/interfaces/api/keri-api/object/IUploadFileVO";
import { IUploadFile } from "@keri-portal/interfaces/control/IUploadFile";
import { IAccountRegister } from "@keri-portal/interfaces/process-object/account/IAccountRegister";
import { ICompanyInfo } from "@keri-portal/interfaces/data-object/data-common/ICompanyInfo";
import { ITechCodeInfo } from "@keri-portal/interfaces/data-object/data-common/ITechCodeInfo";
import { AxiosError, AxiosResponse } from "axios";
import InterfaceExtensionMethod from "../extension-method/InterfaceExtensionMethod";
import { IMenuItem } from "@keri-portal/interfaces/data-object/data-common/IMenu";
import { ILoginUserVO } from "@keri-portal/interfaces/api/keri-api/object/ILoginUserVO";
import { IMemberInfoVO } from "@keri-portal/interfaces/api/keri-api/object/IMemberInfoVO";
import { ICompanyInfoVO } from "@keri-portal/interfaces/api/keri-api/object/ICompanyInfoVO";
import { IExpertUser } from "@keri-portal/interfaces/process-object/account/IUserInfo";
import KeriAPIStructure from "@keri-portal/config/KeriAPIStructure";
import { IKeriPurpose } from "@keri-portal/interfaces/data-object/data-common/IKeriPurpose";
import { IAPIAddAnalysisPurposeRequestBody, IAPIDeleteAnalysisPurposeRequestBody, IAPIGetAnalysisPurposeRequestBody } from "@keri-portal/interfaces/api/keri-api/request/IKeriAPIPurposeRequest";
import { IAPIGetAnalysisPurposeResult } from "@keri-portal/interfaces/api/keri-api/response/IKeriAPIPurposeResponse";
import { ITestAttribute, TestAttribute } from "@keri-portal/interfaces/data-object/data-common/ITestAttribute";
import { IKeriAPITestAttributeRequestBody } from "@keri-portal/interfaces/api/keri-api/request/IKeriAPITestRequest";
import { IKeriTestAttributeResult } from "@keri-portal/interfaces/api/keri-api/response/IKeriAPITestResponse";
import { IKeriAPIFindAccountIDRequestBody, IKeriAPIGetExpertUserListRequestBody as IKeriAPIGetExpertUserListRequestBody, IKeriAPIResetUserPasswordRequestBody } from "@keri-portal/interfaces/api/keri-api/request/IKeriAPIAccountRequest";
import { IKeriAPIFindAccountIDResult, IKeriAPIGetExpertUserListResult, IKeriAPIResetUserPasswordResult } from "@keri-portal/interfaces/api/keri-api/response/IKeriAPIAccountResponse";
import { ObjectUtils } from "@ict-base/module/utils/ObjectUtils";
import KeriProductAPI from "./api-modules/KeriProductAPI";

import KeriTechServiceAPI from "./api-modules/KeriTechServiceAPI";
import { IUserInfo } from "@keri-portal/interfaces/data-object/data-common/IUserObject";
import { IKeriAPIGetSurveyListRequestBody, IkeriAPIGetTechCodeRequestBody, IkeriAPIGetTechCodeSubCategoryRequestBody } from "@keri-portal/interfaces/api/keri-api/request/IKeriAPICommonRequest";
import { IKeriAPIGetMenuListResult, IKeriAPIGetSurveyListResult, IKeriAPIGetTechCodeListResult } from "@keri-portal/interfaces/api/keri-api/response/IKeriAPICommonResponse";
import { ISurvey, ISurveyAnswer, ISurveyQuestion } from "@keri-portal/interfaces/data-object/data-common/ISurvey";
import KeriBoardAPI from "./api-modules/KeriBoardAPI";
import { PlatformError } from "@keri-base/interface/common/PlatformError";
import { IKeriAPIAddGetCertCodeResult as IKeriAPIRequestCertCodeResult } from "@keri-portal/interfaces/api/keri-api/response/IKeriAPICertCodeResponse";
import { IKeriAPIGetMainDataRequestBody } from "@keri-portal/interfaces/api/keri-api/request/IKeriAPIBoardRequest";
import { IKeriAPIGetBoardItemResult, IKeriAPIGetMainDataResult } from "@keri-portal/interfaces/api/keri-api/response/IKeriAPIBoardResponse";
import { IBoardVO, IFAQItemVO } from "@keri-portal/interfaces/api/keri-api/object/IBoardVO";
import { IBoardItem, IFaqItem } from "@keri-portal/interfaces/data-object/data-common/IBoard";
import { IMainData } from "@keri-portal/interfaces/data-object/data-common/IMainData";
import URLStructure from "@src/modules/structure/URLStructure";
import { ISwiperImage } from "@keri-portal/page/MainViewPage";
import { Typography } from "@mui/material/styles/createTypography";
import { ReactNode } from "react";
import KeriTemplateAPI from "./api-modules/KeriTemplateAPI";
import { IKeriAPIGetTemplateListResult } from "@keri-portal/interfaces/api/keri-api/response/IKeriAPITemplateResponse";
import KeriAPIException from "./api-utils/KeriAPIException";
import KeriAPIExceptionFilter from "./api-utils/KeriAPIExceptionFilter";
import KeriEducationAPI from "./api-modules/KeriEducationAPI";


export interface IKeriAuthAPIState extends IKeriAPIState {
    tokenValid: boolean,
    refreshAccessToken?: string,
    expireDate?: Date,
}



const ReturnMessage = {
    ServerError: "서버와 연결이 원활하지 않습니다.",
};

/** KERI API Request Method */
const RequestKeriAPI = {
    /** 회원가입 */
    RegisterAccount: {
        /** 회원 등록 */
        User: async (data: IAccountRegister): Promise<IKeriAPIState> => RequestAPIRegisterAccountUser(data),
        /** 기업 등록 */
        Company: async (data: IAccountRegister): Promise<IRequestAPIRegisterAccountCompany> => RequestAPIRegisterAccountCompany(data),
    },
    Account: {
        /** 기업 정보 조회 */
        LoadCompany: async (companyNumber: string): Promise<IRequestAPILoadCompanyData> => RequestAPILoadCompanyData(companyNumber),
        /** 로그인 */
        Login: async (id: string, password: string, userType: 'GNR' | 'USR'): Promise<IRunLogin> => Login(id, password, userType),
        /** 로그인한 유저의 기업 및 회원 정보 조회 */
        GetUserAndCompanyInfo: async (token?: string): Promise<IGetUserAndCompanyInfo> => GetUserAndCompanyInfo(token),
        /** 개인정보 변경 */
        UpdateUserData: async (data: IUserInfo, token: string): Promise<IUpdateUserData> => UpdateUserData(data, token),
        /** 비밀번호 변경 */
        ChangeUserPassword: async (changePassword: string, token: string): Promise<IKeriAuthAPIState> => ChangeUserPassword(changePassword, token),
        /** 비밀번호 변경 (로그인 X) */
        ChangeUserPasswordNonLogin: async (id: string, changePassword: string): Promise<IKeriAPIState> => ChangeUserPasswordNonLogin(id, changePassword),
        /** 회원 탈퇴 신청 */
        WithDrawUserAccount: async (token: string): Promise<IKeriAuthAPIState> => WithDrawUserAccount(token),
        /** 외부전문가 리스트 조회 */
        GetExpertUserList: async (token?: string, searchKeyword?: string, page?: number): Promise<IGetExpertUserList> => GetExpertUserList(token, searchKeyword, page),
        /**비밀번호 초기화 */
        ResetUserPassword: async (userID?: string): Promise<IResetUserPassword> => ResetUserPassword(userID),
        /** 아이디 찾기 */
        FindAccountID : async (userEmail? : string) : Promise<IFindAccountID> => FindAccountID(userEmail),
    },
    File: {
        /** 파일 업로드 */
        UploadFile: async (data: IUploadFile<IUploadFileVO>): Promise<{ state: boolean, data: IUploadFile<IUploadFileVO> | undefined, message: string | undefined }> => UploadFile(data),
        /** 임시 파일 삭제 */
        RemoveUploadTempFile: async (data: IUploadFile<IUploadFileVO>): Promise<IKeriAPIState> => RemoveUploadTempFile(data),
        /** 파일 다운로드 */
        DownloadFile: async (token?: string, data?: IUploadFile<IUploadFileVO>): Promise<IRequestDownloadFile> => DownloadFile(token, data),
        /** AtchFileID 이용 파일 다운로드 */
        DownloadFileUsingAtchFileID: async (token?: string, atchFileID?: string, fileSn?: number): Promise<IRequestDownloadFile> => DownloadFileUsingAtchFileID(token, atchFileID, fileSn)
    },
    Product: KeriProductAPI,
    Attribute: {
        /** 해석목적 검색 */
        GetAnalysisPurpose: async (token?: string, page?: number, searchText?: string, pagePerCount?: number): Promise<IGetAnalysisPurpose> => GetAnalysisPurpose(token, page, searchText, pagePerCount),
        /** 해석목적 삭제 */
        // DeleteAnalysisPurpose: async (token?: string, data?: IKeriPurpose[]): Promise<IKeriAuthAPIState> => DeleteAnalysisPurpose(token, data),
        /** 해석목적 생성 */
        AddAnalysisPurpose: async (token?: string, purposeString?: string): Promise<IKeriAuthAPIState> => AddAnalysisPurpose(token, purposeString),
        /** 시험 방법 / 시험 유형 / 시험 항목 조회 */
        GetTestAttribute: async (token?: string, type?: TestAttribute, searchText?: string, page?: number): Promise<IGetTestAttribute> => GetTestAttribute(token, type, searchText, page),
        /** 산업분류코드 대분류 조회 */
        GetTechCodeMainCategoryList: async (): Promise<IGetTechCodeMainCategoryList> => GetTechCodeMainCategoryList(),
        /** 산업분류코드 중분류 조회 */
        GetTechCodeSubCategoryList: async (mainCategoryCode?: string): Promise<IGetTechCodeSubCategoryList> => GetTechCodeSubCategoryList(mainCategoryCode),
        /** 산업분류코드 소분류 조회 */
        GetTechCodeList: async (mainCategoryCode?: string, subCategoryCode?: string): Promise<IGetTechCodeList> => GetTechCodeList(mainCategoryCode, subCategoryCode),
    },
    Common: {

        /** 정적 페이지 코드 조회 */
        GetStaticPage: async (pageFileName: string): Promise<IGetStaticPage> => GetStaticPage(pageFileName),
        /** 토큰 체크 */
        CheckTokenValidation: async (token: string): Promise<IKeriAuthAPIState> => CheckTokenValidation(token),
        /** 설문조사 조회 */
        GetSurveyList: async (token?: string): Promise<IGetSurveyList> => GetSurveyList(token),
        /** 인증번호 발급 */
        RequestCertKey: async (type: 'ID' | 'EMAIL', objectID?: string): Promise<IKeriAPIState> => RequestCertKey(type, objectID),
        ValidCertKey: async (type: 'ID' | 'EMAIL', objectID?: string, certKey?: string): Promise<IKeriAPIState> => ValidCertKey(type, objectID, certKey),
        GetTemplateList: async (): Promise<IGetTemplateList> => GetTemplateList()
    },
    Main: {
        /** 메뉴 리스트 출력 */
        GetMenuList: async (token?: string): Promise<IGetMenuList> => GetMenuList(token),
        GetMainData: async (): Promise<IGetMainData> => GetMainData(),
        GetSwiperImageList: async (): Promise<IGetSwiperImageList> => GetSwiperImageList(),
    },
    Template: KeriTemplateAPI,
    Board: KeriBoardAPI,
    TechService: KeriTechServiceAPI,
    Education : KeriEducationAPI
};


const RequestAPIRegisterAccountUser = async (data: IAccountRegister): Promise<IKeriAPIState> => {
    const convertData = InterfaceExtensionMethod.API.Request.CastAccountRegisterObjectToAPI(data);

    let apiState: boolean = true;
    let errorMessage: string | undefined = undefined;

    await CallAPI<IAPIAccountRegisterResult>({
        url: KeriAPIStructure.Login.AddUserInfo,
        method: "PUT",
        requestBody: convertData,
    })
        .then((res: AxiosResponse<IAPIAccountRegisterResult>) => {
            if (res.data.resultCode !== "S") {
                apiState = false;
                errorMessage = res.data.resultMessage;
            }
        })
        .catch((err) => {
            apiState = false;
            errorMessage = ReturnMessage.ServerError;
        });

    return {
        state: apiState,
        message: errorMessage,
    };
};

interface IRequestAPIRegisterAccountCompany extends IKeriAPIState {
    companyID: string | undefined,
}

const RequestAPIRegisterAccountCompany = async (data: IAccountRegister): Promise<IRequestAPIRegisterAccountCompany> => {
    const convertData = InterfaceExtensionMethod.API.Request.CastAccountRegisterCompanyObjectToAPI(data);
    let apiState: boolean = true;
    let errorMessage: string | undefined = undefined;
    let comId: string | undefined = undefined;

    await CallAPI<IAPIAccountRegisterResult>({
        url: KeriAPIStructure.Login.AddCompanyInfo,
        method: "PUT",
        requestBody: convertData,
    })
        .then((res: AxiosResponse<IAPIAccountRegisterResult>) => {
            if (res.data.resultCode !== "S") {
                apiState = false;
                errorMessage = res.data.resultMessage;
            }
            else {
                comId = res.data.comId
            }
        })
        .catch((err) => {
            apiState = false;
            errorMessage = ReturnMessage.ServerError;
        });

    return {
        state: apiState,
        companyID: comId,
        message: errorMessage,
    };
};


interface IRequestAPILoadCompanyData extends IKeriAPIState {
    data: ICompanyInfo | undefined
}

const RequestAPILoadCompanyData = async (companyNumber: string): Promise<IRequestAPILoadCompanyData> => {

    let apiState: boolean = true;
    let errorMessage: string | undefined = undefined;
    let companyData: ICompanyInfo | undefined = undefined;


    await CallAPI<IAPICompanyResult>({
        url: KeriAPIStructure.Login.GetCompanyInfo,
        method: "POST",
        requestBody: {
            comBusNo: RegexUtil.ConvertRegexString.CompanyRegistrationNumber(companyNumber)
        },
    })
        .then((res: AxiosResponse<IAPICompanyResult>) => {
            if (res.data.resultCode !== "S") {
                apiState = false;
                errorMessage = res.data.resultMessage;
            }
            else if (res.data.resultCode === 'S') {
                companyData = InterfaceExtensionMethod.API.Response.CastAPIToCompanyData(res.data.resultData)
            }
        })
        .catch((err) => {
            apiState = false;
            errorMessage = ReturnMessage.ServerError;
        });

    return {
        state: apiState,
        data: companyData,
        message: errorMessage,
    };
}

interface IUploadFileResponse extends IKeriAPIState {
    data: IUploadFile<IUploadFileVO> | undefined
}

const UploadFile = async (data: IUploadFile<IUploadFileVO>): Promise<IUploadFileResponse> => {

    let apiState: boolean = true;
    let errorMessage: string | undefined = undefined;
    let uploadFile: IUploadFile<IUploadFileVO> = data;

    var bodyFormData = new FormData();
    bodyFormData.append('file', data.OriginFile!);
    bodyFormData.append('fileCn', data.Label);
    bodyFormData.append('isRequired', (data.Required ?? false) ? 'Y' : 'N');
    bodyFormData.append('filePrefix', data.Prefix ?? '');
    bodyFormData.append('fileCategory', data.Category ?? '')



    await CallAPI<IAPIUploadFileResult>({
        url: KeriAPIStructure.Common.FileUpload,
        method: "POST",
        header: {
            'Content-Type': 'multipart/form-data'
        },
        requestBody: bodyFormData,
    })
        .then((res: AxiosResponse<IAPIUploadFileResult>) => {
            if (res.data.resultCode === 'S') {
                apiState = true;
                uploadFile.UploadedFile = res.data.fileInfo
            }
            else {
                apiState = false;
                errorMessage = res.data.resultMessage
            }
        })
        .catch(err => {
            apiState = false;
            errorMessage = '서버와 연결이 원활하지 않습니다.'
        })


    return {
        state: apiState,
        data: uploadFile,
        message: errorMessage,
    };
}



const RemoveUploadTempFile = async (data: IUploadFile<IUploadFileVO>): Promise<{ state: boolean, message: string | undefined }> => {

    let apiState: boolean = true;
    let errorMessage: string | undefined = undefined;


    await CallAPI<IAPIRemoveTempFileResult>({
        url: 'kri/cmm/TempFileRemove.do',
        method: "POST",
        requestBody: {
            streFileNm: data.UploadedFile?.streFileNm
        } as IAPIRemoveTempFileRequestBody,
    })
        .then(res => {
            if (res.data.resultCode === 'S') {
                apiState = true;
            }
            else {
                apiState = false;
                errorMessage = res.data.resultMessage
            }
        })
        .catch(err => {
            apiState = false;
            errorMessage = '서버와 연결이 원활하지 않습니다.'
        })

    return {
        state: apiState,
        message: errorMessage,
    };
}


interface IGetStaticPage extends IKeriAPIState {
    code: string
}

const GetStaticPage = async (pageFileName: string): Promise<IGetStaticPage> => {
    let apiState: boolean = true;
    let errorMessage: string | undefined = undefined;
    let code: string = '';

    await CallAPI<IAPIGetStaticHTMLResult>({
        url: 'kri/cmm/HtmlReader.do',
        method: "POST",
        requestBody: {
            targeFileName: pageFileName
        } as IAPIGetStaticHTMLRequestBody,
    })
        .then(res => {
            if (res.data.resultCode === 'S') {
                apiState = true;
                code = res.data.htmlData;
            }
            else {
                apiState = false;
                errorMessage = res.data.resultMessage
            }
        })
        .catch(err => {
            apiState = false;
            errorMessage = '서버와 연결이 원활하지 않습니다.'
        })


    return {
        state: apiState,
        code: code,
        message: errorMessage,
    };

}

interface IGetMenuList extends IKeriAuthAPIState {
    data: IMenuItem[]

}

const GetMenuList = async (token?: string): Promise<IGetMenuList> => {
    let apiState: IGetMenuList = {
        state: true,
        message: undefined,
        tokenValid: true,
        data: [],
    }



    await CallAPI<IKeriAPIGetMenuListResult>({
        url: KeriAPIStructure.Main.GetMenuList,
        method: "POST",
        token: token
    })
        .then(async res => {
            if (res.data.resultCode === 'S') {




                try {
                    apiState.state = true;
                    apiState.data = (res.data.menuList ?? []);
                    apiState.tokenValid = true;
                }
                catch (e) {
                    if (e instanceof PlatformError) {
                        throw e;
                    }
                    else {
                        throw new PlatformError("데이터를 불러올 수 없습니다.", false);
                    }
                }


            }
            else {
                apiState.state = false;
                apiState.message = res.data.resultMessage;
            }

        })
        .catch((err) => {
            if (err instanceof AxiosError) {
                apiState.message = '서버와 연결이 원활하지 않습니다.'
                console.log(err);
            }
            else if (err instanceof PlatformError) {
                apiState.message = err.message
                if (!err.isSystemError) {
                    apiState.message = err.message;
                }
                else {
                    apiState.message = '시스템 오류입니다.'
                }
            }
            else {
                apiState.message = '시스템 오류입니다.'
            }
            apiState.state = false;
        })


    return apiState;
}


interface IRunLogin extends IKeriAPIState {
    data: ILoginUserVO | undefined,
    token: string | undefined,
    expireDate: Date | undefined
}

const Login = async (id: string, password: string, userType: 'GNR' | 'USR'): Promise<IRunLogin> => {
    let apiState: boolean = true;
    let errorMessage: string | undefined = undefined;
    let data: ILoginUserVO | undefined = undefined;
    let token: string | undefined = undefined;
    let expireDate: Date | undefined = undefined;

    const reqBody: IAPILoginRequestBody = {
        mberId: id,
        password: password,
        userSe: userType
    }

    await CallAPI<IAPILoginResult>({
        url: 'uat/uia/actionLoginAPI.do',
        method: "POST",
        requestBody: reqBody
    })
        .then((res: AxiosResponse<IAPILoginResult>) => {
            if (res.data.resultCode === 'S') {
                apiState = true;
                data = res.data.resultVO;
                token = res.data.token;
                expireDate = res.data.expireDate === undefined ? undefined : isNaN(Date.parse(res.data.expireDate ?? '')) ? undefined : new Date(Date.parse(res.data.expireDate ?? ''))
            }
            else {
                apiState = false;
                errorMessage = res.data.resultMessage;
            }
        })
        .catch(err => {
            apiState = false;
            errorMessage = '서버와 연결이 원활하지 않습니다.'
        })


    return {
        state: apiState,
        data: data,
        token: token,
        message: errorMessage,
        expireDate: expireDate
    };
}


interface IGetUserAndCompanyInfo extends IKeriAuthAPIState {
    userData?: IMemberInfoVO,
    userFiles?: IUploadFileVO[],
    comData?: ICompanyInfoVO,
    comFiles?: IUploadFileVO[]
}

const GetUserAndCompanyInfo = async (token?: string): Promise<IGetUserAndCompanyInfo> => {
    let apiState: IGetUserAndCompanyInfo = {
        state: true,
        tokenValid: true,
        message: undefined,
        comData: undefined,
        comFiles: undefined,
        userData: undefined,
        userFiles: undefined
    }

    await CallAPI<IAPIGetUserAndCompanyInfoResult>({
        url: 'uss/umt/MemberWithCompanyInfo.do',
        method: "POST",
        token: token
    })
        .then((res: AxiosResponse<IAPIGetUserAndCompanyInfoResult>) => {
            if (res.data.resultCode === 'S') {
                apiState.state = true;

                apiState.comData = res.data.companyInfo.properties;
                apiState.comFiles = res.data.companyInfo.attachFiles;
                apiState.userData = res.data.memberInfo.properties;
                apiState.userFiles = res.data.memberInfo.attachFiles;
            }
            else {
                apiState.state = false;
                apiState.message = res.data.resultMessage;
            }
        })
        .catch((err: AxiosError) => {
            if (err.response?.status === 401) {
                apiState.tokenValid = false;
                apiState.message = '로그인이 만료되었습니다. \n다시 로그인해주시길 바랍니다.'
            }
            else {
                apiState.message = '서버와 연결이 원활하지 않습니다.'
            }
            apiState.state = false;

        })


    return apiState;
}

interface IUpdateUserData extends IKeriAuthAPIState {
    data?: IMemberInfoVO
}

const UpdateUserData = async (data: IUserInfo, token: string): Promise<IUpdateUserData> => {
    let apiState: IUpdateUserData = {
        state: true,
        tokenValid: true,
        message: undefined,
        data: undefined
    }

    let reqBody: IMemberInfoVO = InterfaceExtensionMethod.API.Request.CastMemberInfoObjectToAPIMemberVO(data) ?? {};

    await CallAPI<IAPIUpdateUserResult>({
        url: 'uss/umt/MemberUpdate.do',
        method: "PUT",
        token: token,
        requestBody: reqBody
    })
        .then((res: AxiosResponse<IAPIUpdateUserResult>) => {
            if (res.data.resultCode === 'S') {
                apiState.state = true;

                apiState.data = res.data.memberInfo
            }
            else {
                apiState.state = false;
                apiState.message = res.data.resultMessage;
            }
        })
        .catch((err: AxiosError) => {
            if (err.response?.status === 401) {
                apiState.tokenValid = false;
                apiState.message = '로그인이 만료되었습니다. \n다시 로그인해주시길 바랍니다.'
            }
            else {
                apiState.message = '서버와 연결이 원활하지 않습니다.'
            }
            apiState.state = false;

        })


    return apiState;
}



const ChangeUserPassword = async (changePassword: string, token: string): Promise<IKeriAuthAPIState> => {
    let apiState: IKeriAuthAPIState = {
        state: true,
        tokenValid: true,
        message: undefined,
    }

    await CallAPI<IAPIUpdateUserResult>({
        url: 'uss/umt/MemberPasswordChange.do',
        method: "PUT",
        token: token,
        requestBody: {
            password: changePassword
        }
    })
        .then((res: AxiosResponse<IAPIUpdateUserResult>) => {
            if (res.data.resultCode === 'S') {
                apiState.state = true;
                apiState.message = res.data.resultMessage;
            }
            else {
                apiState.state = false;
                apiState.message = res.data.resultMessage;
            }
        })
        .catch((err: AxiosError) => {
            if (err.response?.status === 401) {
                apiState.tokenValid = false;
                apiState.message = '로그인이 만료되었습니다. \n다시 로그인해주시길 바랍니다.'
            }
            else {
                apiState.message = '서버와 연결이 원활하지 않습니다.'
            }
            apiState.state = false;

        })


    return apiState;
}

const WithDrawUserAccount = async (token: string): Promise<IKeriAuthAPIState> => {
    let apiState: IKeriAuthAPIState = {
        state: true,
        tokenValid: true,
        message: undefined,
    }

    await CallAPI<IAPIUpdateUserResult>({
        url: 'uss/umt/MemberWithdrawRequest.do',
        method: "PUT",
        token: token
    })
        .then((res: AxiosResponse<IAPIUpdateUserResult>) => {
            if (res.data.resultCode === 'S') {
                apiState.state = true;
                apiState.message = res.data.resultMessage;
            }
            else {
                apiState.state = false;
                apiState.message = res.data.resultMessage;
            }
        })
        .catch((err: AxiosError) => {
            if (err.response?.status === 401) {
                apiState.tokenValid = false;
                apiState.message = '로그인이 만료되었습니다. \n다시 로그인해주시길 바랍니다.'
            }
            else {
                apiState.message = '서버와 연결이 원활하지 않습니다.'
            }
            apiState.state = false;

        })


    return apiState;
}


const CheckTokenValidation = async (token: string): Promise<IKeriAuthAPIState> => {
    let apiState: IKeriAuthAPIState = {
        state: true,
        tokenValid: true,
        message: undefined,
        refreshAccessToken: undefined,
        expireDate: undefined
    }

    await CallAPI<IAPITokenValidationCheckResult>({
        url: KeriAPIStructure.ETC.RefreshToken,
        method: "POST",
        token: token
    })
        .then((res: AxiosResponse<IAPITokenValidationCheckResult>) => {

            if (res.data.resultCode === 'S') {
                apiState.state = true;
                apiState.message = res.data.resultMessage;
                apiState.refreshAccessToken = res.data.token;
                apiState.expireDate = res.data.expireDate === undefined ? undefined : isNaN(Date.parse(res.data.expireDate ?? '')) ? undefined : new Date(Date.parse(res.data.expireDate ?? ''))
            }
            else {
                apiState.state = false;
                apiState.message = res.data.resultMessage;
            }
        })
        .catch((err: AxiosError) => {
            if (err.response?.status === 401) {
                apiState.tokenValid = false;
                apiState.message = '로그인이 만료되었습니다. \n다시 로그인해주시길 바랍니다.'
            }
            else {
                apiState.message = '서버와 연결이 원활하지 않습니다.'
            }
            apiState.state = false;

        })

    return apiState;
}


interface IRequestDownloadFile extends IKeriAPIState {
    data: Blob
}

const DownloadFile = async (token?: string, data?: IUploadFile<IUploadFileVO>): Promise<IRequestDownloadFile> => {


    return DownloadFileUsingAtchFileID(token, data?.UploadedFile?.atchFileId, data?.UploadedFile?.fileSn);
}

const DownloadFileUsingAtchFileID = async (token?: string, atchFileID?: string, fileSn?: number | string): Promise<IRequestDownloadFile> => {
    let apiState: IRequestDownloadFile = {
        state: true,
        message: undefined,
        data: new Blob(),
    }

    const reqBody: IAPIDownloadFileRequestBody = {
        atchFileId: atchFileID ?? '',
        fileSn: (fileSn ?? -1).toString()
    }

    await CallAPI<Blob>({
        url: KeriAPIStructure.Common.FileDownload,
        method: "POST",
        requestBody: reqBody,
        responseType: 'blob',
        token: token
    })
        .then(res => {
            apiState.data = res.data;


        })
        .catch((err: AxiosError) => {

            apiState.message = '서버와 연결이 원활하지 않습니다.'
            apiState.state = false;

        })

    return apiState;
}









interface IGetAnalysisPurpose extends IKeriAuthAPIState, IListPagination {
    resultData: IKeriPurpose[]
}

const GetAnalysisPurpose = async (token?: string, page?: number, searchText?: string, pagePerCount?: number): Promise<IGetAnalysisPurpose> => {
    let apiState: IGetAnalysisPurpose = {
        state: true,
        resultData: [],
        message: undefined,
        tokenValid: true
    }

    // const reqBody: IAPIGetAnalysisPurposeRequestBody =
    // {
    //     pageIndex: (page ?? 1).toString(),
    //     pageUnit: (pagePerCount ?? 10).toString(),
    //     purposeNm: ObjectUtils.IsNullOrEmpty(searchText) ? undefined : searchText,
    //     purposeType: 'AR'
    // }

    await CallAPI<IAPIGetAnalysisPurposeResult>({
        url: KeriAPIStructure.Product.GetAnalysisPurpose,
        method: "POST",
        token: token,
        // requestBody: reqBody
    })
        .then(res => {
            if (res.data.resultCode === 'S') {
                apiState.resultData = res.data.arPurposeList.filter(x => x !== undefined).map(x => InterfaceExtensionMethod.API.Response.CastAPIToAnalysisPurposeData(x)!)
                apiState.state = true;
                apiState.pageObject = {
                    nowPage: page,
                    totalPage: res.data.paginationInfo?.lastPageNo
                }


            }
            else {
                apiState.state = false;
                apiState.message = res.data.resultMessage;
            }
        })
        .catch((err) => {
            console.log(err);

            if (err.response?.status === 401) {
                apiState.tokenValid = false;
                apiState.message = '로그인이 만료되었습니다. \n다시 로그인해주시길 바랍니다.'
            }
            else {
                apiState.message = '서버와 연결이 원활하지 않습니다.'
            }
            apiState.state = false;

        })


    return apiState;
}




// const DeleteAnalysisPurpose = async (token?: string, data?: IKeriPurpose[]): Promise<IKeriAuthAPIState> => {
//     let apiState: IKeriAuthAPIState = {
//         state: true,
//         message: undefined,
//         tokenValid: true
//     }

//     const reqBody: IAPIDeleteAnalysisPurposeRequestBody =
//     {
//         deletedItems: (data ?? []).map(x => (x.purposeSequence ?? -1).toString())
//     }

//     await CallAPI<IKeriAPIResponse>({
//         url: KeriAPIStructure.Product.DeleteAnalysisPurpose,
//         method: "DELETE",
//         token: token,
//         requestBody: { data: reqBody }
//     })
//         .then(res => {
//             if (res.data.resultCode !== 'S') {
//                 apiState.state = false;
//                 apiState.message = res.data.resultMessage;
//             }
//         })
//         .catch((err) => {
//             if (err.response?.status === 401) {
//                 apiState.tokenValid = false;
//                 apiState.message = '로그인이 만료되었습니다. \n다시 로그인해주시길 바랍니다.'
//             }
//             else {
//                 apiState.message = '서버와 연결이 원활하지 않습니다.'
//             }
//             apiState.state = false;

//         })


//     return apiState;
// }



const AddAnalysisPurpose = async (token?: string, purposeString?: string): Promise<IKeriAuthAPIState> => {
    let apiState: IKeriAuthAPIState = {
        state: true,
        message: undefined,
        tokenValid: true
    }

    const reqBody: IAPIAddAnalysisPurposeRequestBody =
    {
        purposeNm: purposeString ?? '',
        purposeType: 'AR'
    }

    await CallAPI<IKeriAPIResponse>({
        url: KeriAPIStructure.Product.AddAnalysisPurpose,
        method: "PUT",
        token: token,
        requestBody: reqBody
    })
        .then(res => {
            if (res.data.resultCode !== 'S') {
                apiState.state = false;
                apiState.message = res.data.resultMessage;
            }
        })
        .catch((err) => {
            if (err.response?.status === 401) {
                apiState.tokenValid = false;
                apiState.message = '로그인이 만료되었습니다. \n다시 로그인해주시길 바랍니다.'
            }
            else {
                apiState.message = '서버와 연결이 원활하지 않습니다.'
            }
            apiState.state = false;

        })


    return apiState;
}

interface IGetTestAttribute extends IKeriAuthAPIState, IListPagination {
    resultData: ITestAttribute[]
}

const GetTestAttribute = async (token?: string, type?: TestAttribute, searchText?: string, page?: number): Promise<IGetTestAttribute> => {
    let apiState: IGetTestAttribute = {
        state: true,
        resultData: [],
        tokenValid: true,
        message: undefined,
        pageObject: {
            nowPage: 1,
            totalPage: 1
        }
    }

    const reqBody: IKeriAPITestAttributeRequestBody =
    {
        pageIndex: (page ?? 1).toString(),
        pageUnit: '10',
        searchCondition: type!,
        searchKeyword: searchText
    }

    await CallAPI<IKeriTestAttributeResult>({
        url: KeriAPIStructure.Common.GetTestAttribute,
        method: "POST",
        requestBody: reqBody,
        token: token
    })
        .then(res => {
            if (res.data.resultCode === 'S') {
                apiState.resultData = res.data.resultData.filter(x => x !== undefined).map(x => InterfaceExtensionMethod.API.Response.CastAPIToTestAttributeData(x, type!)!)
                apiState.state = true;
                apiState.pageObject = {
                    nowPage: page,
                    totalPage: res.data.paginationInfo?.lastPageNo
                }
            }
            else {
                apiState.state = false;
                apiState.message = res.data.resultMessage;
            }
        })
        .catch((err) => {
            if (err.response?.status === 401) {
                apiState.tokenValid = false;
                apiState.message = '로그인이 만료되었습니다. \n다시 로그인해주시길 바랍니다.'
            }
            else {
                apiState.message = '서버와 연결이 원활하지 않습니다.'
            }
            apiState.state = false;
        })


    return apiState;
}

interface IGetExpertUserList extends IKeriAuthAPIState, IListPagination {
    resultData: IExpertUser[]
}

const GetExpertUserList = async (token?: string, searchKeyword?: string, page?: number): Promise<IGetExpertUserList> => {
    let apiState: IGetExpertUserList = {
        state: true,
        resultData: [],
        message: undefined,
        tokenValid: true,
        pageObject: {
            nowPage: 1,
            totalPage: 1
        }
    }

    const reqBody: IKeriAPIGetExpertUserListRequestBody =
    {
        pageIndex: (page ?? 1).toString(),
        pageUnit: '10',
        searchKeyword: searchKeyword
    }

    await CallAPI<IKeriAPIGetExpertUserListResult>({
        url: KeriAPIStructure.AnalysisRequest.GetExpertUserList,
        method: "POST",
        token: token,
        requestBody: reqBody
    })
        .then(res => {
            if (res.data.resultCode === 'S') {
                try {
                    apiState.resultData = res.data.exportList.filter(x => x !== undefined).map(x => InterfaceExtensionMethod.API.Response.CastAPIToExpertUserData(x)!)
                    apiState.state = true;
                    apiState.pageObject = {
                        nowPage: page,
                        totalPage: res.data.paginationInfo?.lastPageNo
                    }
                }
                catch (e) {
                    alert(e);
                }


            }
            else {
                apiState.state = false;
                apiState.message = res.data.resultMessage;
            }
        })
        .catch((err) => {
            if (err.response?.status === 401) {
                apiState.tokenValid = false;
                apiState.message = '로그인이 만료되었습니다. \n다시 로그인해주시길 바랍니다.'
            }
            else {
                apiState.message = '서버와 연결이 원활하지 않습니다.'
            }
            apiState.state = false;

        })


    return apiState;
}


const ChangeUserPasswordNonLogin = async (id: string, changePassword: string): Promise<IKeriAPIState> => {
    let apiState: IKeriAPIState = {
        state: true,
        message: undefined,
    }

    const reqBody: IAPIChangePasswordNonLoginRequestBody = {
        mberId: id,
        password: changePassword
    }

    await CallAPI<IAPIChangePasswordNonLoginResult>({
        url: KeriAPIStructure.Common.GetTestAttribute,
        method: "POST",
        requestBody: reqBody
    })
        .then(res => {
            if (res.data.resultCode === 'S') {
                apiState.state = true;
            }
            else {
                apiState.state = false;
                apiState.message = res.data.resultMessage;
            }
        })
        .catch((err) => {
            apiState.message = '서버와 연결이 원활하지 않습니다.'
            apiState.state = false;
        })


    return apiState;
}


interface IGetSurveyList extends IKeriAuthAPIState {
    resultData?: ISurvey
}

const GetSurveyList = async (token?: string): Promise<IGetSurveyList> => {
    let apiState: IGetSurveyList = {
        state: true,
        message: undefined,
        tokenValid: true,
        resultData: undefined,
    }

    const reqBody: IKeriAPIGetSurveyListRequestBody = {}

    await CallAPI<IKeriAPIGetSurveyListResult>({
        url: KeriAPIStructure.AnalysisRequest.GetSurveyList,
        method: "POST",
        requestBody: reqBody,
        token: token
    })
        .then(res => {
            if (res.data.resultCode === 'S') {
                console.log(res.data);
                apiState.state = true;
                apiState.resultData = {
                    SurveyID: res.data.qustnrInfo.qestnrId ?? undefined,
                    SurveyTempleteID: res.data.qustnrInfo.qestnrTmplatId ?? undefined,
                    SurveyTitle: res.data.qustnrInfo.qestnrSj ?? undefined,
                    SurveyQuestion: res.data.qustnrQesitmInfo.map(qus => (
                        {
                            QuestionID: qus.qestnrQesitmId ?? undefined,
                            QuestionSequence: qus.qestnSn ?? undefined,
                            QuestionText: qus.qestnCn ?? undefined,
                            QuestionType: (qus.qestnTyCode ?? undefined) === '2' ? 'Text' : (qus.qestnTyCode ?? undefined) === '1' ? 'Select' : (qus.qestnTyCode ?? undefined) === '3' ? 'Rank' : undefined,
                            QuestionAnswer: (qus.qustnrIemInfo ?? []).map(ans => (
                                {
                                    AnswerID: ans.qustnrIemId ?? undefined,
                                    AnswerSequence: ans.iemSn ?? undefined,
                                    AnswerText: ans.iemCn ?? undefined
                                } as ISurveyAnswer
                            ))
                        } as ISurveyQuestion
                    ))
                }

            }
            else {
                apiState.state = false;
                apiState.message = res.data.resultMessage;
            }
        })
        .catch((err: AxiosError) => {

            if (err.response?.status === 401) {
                apiState.tokenValid = false;
                apiState.message = '로그인이 만료되었습니다. \n다시 로그인해주시길 바랍니다.'
            }
            else {
                apiState.message = '서버와 연결이 원활하지 않습니다.'
            }
            apiState.state = false;
        })


    return apiState;
}


interface IGetMainData extends IKeriAPIState {
    resultData?: IMainData
}

const GetMainData = async (): Promise<IGetMainData> => {
    let apiState: IGetMainData = {
        state: true,
        message: undefined,
        resultData: undefined,
        statusCode: undefined,
    }

    const reqBody: IKeriAPIGetMainDataRequestBody = {
        bbsIds: ["BBSMSTR_B99999999999", "BBSMSTR_E99999999999", "BBSMSTR_F99999999999", "BBSMSTR_D99999999999"],
        displayCnts: [2, 2, 2, 3]
    }



    await CallAPI<IKeriAPIGetMainDataResult>({
        url: KeriAPIStructure.Main.GetMainData,
        method: "POST",
        requestBody: reqBody
    })
        .then(async res => {
            if (res.data.resultCode === 'S') {
                try {
                    let listImage: { bbsId: string, nttId: string, fileSn: string, url: string | undefined }[] = [];
                    let beforeDownloadList: { bbsId: string, nttId: string, fileSn: string, atchFileID: string }[] = [];
                    try {
                        // Get Main Thumbnail AtchfileID
                        res.data.boardList.forEach(boardList => {
                            boardList.bbsList.forEach(bbsItemList => {

                                if (boardList.bbsId === 'BBSMSTR_D99999999999') {

                                    let faqThumbnail: string | undefined = undefined;


                                    switch ((bbsItemList.properties as IFAQItemVO).qestnTyCd) {
                                        case 'AR': faqThumbnail = `${URLStructure.Asset}/img/main-techservice-image/ar_bg.png`; break;
                                        case 'CS': faqThumbnail = `${URLStructure.Asset}/img/main-techservice-image/ct_bg.png`; break;
                                        case 'IS': faqThumbnail = `${URLStructure.Asset}/img/main-techservice-image/is_bg.png`; break;
                                        case 'PS': faqThumbnail = `${URLStructure.Asset}/img/main-techservice-image/3d_bg.png`; break;
                                        case 'PT': faqThumbnail = `${URLStructure.Asset}/img/main-techservice-image/pt_bg.png`; break;
                                        case 'VR': faqThumbnail = `${URLStructure.Asset}/img/main-techservice-image/arvr_bg.png`; break;
                                        default: faqThumbnail = `${URLStructure.Asset}/img/main-techservice-image/etc_bg.png`; break;
                                    }

                                    listImage.push({
                                        bbsId: boardList.bbsId!,
                                        nttId: (bbsItemList.properties as IFAQItemVO).faqId!,
                                        fileSn: '0',
                                        url: faqThumbnail
                                    })
                                }
                                else {
                                    (bbsItemList.attachFiles ?? [] as IUploadFileVO[]).forEach(atchFileItem => {


                                        if (atchFileItem.fileCategory === 'THUMBNAIL') {
                                            beforeDownloadList.push({
                                                bbsId: boardList.bbsId!,
                                                nttId: (bbsItemList.properties as IBoardVO).nttId!.toString(),
                                                atchFileID: (bbsItemList.properties as IBoardVO).atchFileId!,
                                                fileSn: atchFileItem.fileSn!
                                            })
                                        }

                                    })




                                }



                            })
                        })


                        // Get Blob

                        const getFileDownload = new Promise<{ state: boolean, message?: string, result?: { bbsId: string, nttId: string, fileSn: string, url: string | undefined }[] }>(async (resolve, reject) => {
                            if (beforeDownloadList.length === 0) resolve({ state: true, result: [] });
                            let imgArr: { bbsId: string, nttId: string, fileSn: string, url: string | undefined }[] = [];

                            const downloadList = [...beforeDownloadList];


                            const downloadFunc = (promAtchFileID: string | undefined, promAtchFileSn: string) => new Promise<{ state: boolean, result?: string, message?: string }>(async (downloadResolve, downloadReject) => {
                                const getImage = await RequestKeriAPI.File.DownloadFileUsingAtchFileID(undefined, promAtchFileID ?? undefined, parseInt(promAtchFileSn));

                                if (getImage.state) {
                                    const imageURL = await ObjectUtils.CastBlobToURLStringAsync(getImage.data);
                                    downloadResolve({ state: true, result: imageURL });
                                }
                                else {
                                    // reject(getImage.message);
                                    console.log('failed', getImage.message);
                                    // throw new PlatformError("이미지를 불러올 수 없습니다.");
                                    downloadResolve({ state: false, message: getImage.message });
                                }
                            })



                            for (let i = 0; i < downloadList.length; i++) {
                                const url = await downloadFunc(downloadList[i].atchFileID, downloadList[i].fileSn);
                                if (url.state) {
                                    imgArr.push({ bbsId: downloadList[i].bbsId, fileSn: downloadList[i].fileSn, nttId: downloadList[i].nttId, url: url.result });
                                }
                                else {
                                    resolve({ state: false, message: url.message });
                                }

                            }

                            resolve({ state: true, result: imgArr });
                        })

                        const downloadFileArray = await getFileDownload;

                        if (downloadFileArray.state) {
                            listImage = [
                                ...listImage,
                                ...downloadFileArray.result ?? []
                            ]
                        }
                        else {
                            throw new PlatformError(downloadFileArray.message ?? '이미지를 불러올 수 없습니다.')
                        }




                    }
                    catch (e) {
                        console.log(e);
                        throw new PlatformError("이미지를 불러올 수 없습니다.", false);
                    }


                    apiState.statusCode = 200;
                    apiState.state = true;

                    apiState.resultData = {
                        notice: res.data.boardList.find(x => x.bbsId === 'BBSMSTR_B99999999999') === undefined ? [] : res.data.boardList.find(x => x.bbsId === 'BBSMSTR_B99999999999')!.bbsList.map(boardItem => ({
                            BoardItemID: (boardItem.properties as IBoardVO).nttId!.toString(),
                            BoardItemTitle: (boardItem.properties as IBoardVO).nttSj!,
                            BoardItemContent: ObjectUtils.DecodeHTMLCode((boardItem.properties as IBoardVO).nttCn!),
                            BoardItemThumbnailURL: listImage.find(x => x.bbsId === 'BBSMSTR_B99999999999' && x.nttId === (boardItem.properties as IBoardVO).nttId!.toString() && x.url !== undefined) === undefined
                                ? []
                                : [listImage.find(x => x.bbsId === 'BBSMSTR_B99999999999' && x.nttId === (boardItem.properties as IBoardVO).nttId!.toString() && x.url !== undefined)!.url!],
                        })),
                        case: res.data.boardList.find(x => x.bbsId === 'BBSMSTR_F99999999999') === undefined ? [] : res.data.boardList.find(x => x.bbsId === 'BBSMSTR_F99999999999')!.bbsList.map(boardItem => ({
                            BoardItemID: (boardItem.properties as IBoardVO).nttId!.toString(),
                            BoardItemTitle: (boardItem.properties as IBoardVO).nttSj!,
                            BoardItemContent: ObjectUtils.DecodeHTMLCode((boardItem.properties as IBoardVO).nttCn!),
                            BoardItemThumbnailURL: listImage.find(x => x.bbsId === 'BBSMSTR_F99999999999' && x.nttId === (boardItem.properties as IBoardVO).nttId!.toString() && x.url !== undefined) === undefined
                                ? []
                                : [listImage.find(x => x.bbsId === 'BBSMSTR_F99999999999' && x.nttId === (boardItem.properties as IBoardVO).nttId!.toString() && x.url !== undefined)!.url!],
                        })),
                        news: res.data.boardList.find(x => x.bbsId === 'BBSMSTR_E99999999999') === undefined ? [] : res.data.boardList.find(x => x.bbsId === 'BBSMSTR_E99999999999')!.bbsList.map(boardItem => ({
                            BoardItemID: (boardItem.properties as IBoardVO).nttId!.toString(),
                            BoardItemTitle: (boardItem.properties as IBoardVO).nttSj!,
                            BoardItemContent: ObjectUtils.DecodeHTMLCode((boardItem.properties as IBoardVO).nttCn!),
                            BoardItemThumbnailURL: listImage.find(x => x.bbsId === 'BBSMSTR_E99999999999' && x.nttId === (boardItem.properties as IBoardVO).nttId!.toString() && x.url !== undefined) === undefined
                                ? []
                                : [listImage.find(x => x.bbsId === 'BBSMSTR_E99999999999' && x.nttId === (boardItem.properties as IBoardVO).nttId!.toString() && x.url !== undefined)!.url!],
                        })),
                        faq: res.data.boardList.find(x => x.bbsId === 'BBSMSTR_D99999999999') === undefined ? [] : res.data.boardList.find(x => x.bbsId === 'BBSMSTR_D99999999999')!.bbsList.map(boardItem => ({
                            FaqID: (boardItem.properties as IFAQItemVO).faqId!.toString(),
                            FaqQuestion: ObjectUtils.DecodeHTMLCode((boardItem.properties as IFAQItemVO).qestnCn!)!,
                            FaqAnswer: ObjectUtils.DecodeHTMLCode((boardItem.properties as IFAQItemVO).answerCn!)!,
                            FaqThumbnail: listImage.find(x => x.bbsId === 'BBSMSTR_D99999999999' && x.nttId === (boardItem.properties as IFAQItemVO).faqId!.toString() && x.url !== undefined) === undefined
                                ? []
                                : [listImage.find(x => x.bbsId === 'BBSMSTR_D99999999999' && x.nttId === (boardItem.properties as IFAQItemVO).faqId!.toString() && x.url !== undefined)!.url!],
                            Subject: (boardItem.properties as IFAQItemVO).qestnSj!,
                        })),
                    }
                }
                catch (e) {
                    if (e instanceof PlatformError) {
                        throw e;
                    }
                    else {
                        console.log(e);
                        throw new PlatformError("데이터를 불러올 수 없습니다.", false);
                    }
                }

            }
            else {
                apiState.state = false;
                apiState.message = res.data.resultMessage;
            }
        })
        .catch((err) => {
            if (err instanceof AxiosError) {
                apiState.statusCode = err.request.status;
                if (err.request.status === 403) {
                    apiState.message = '권한이 없습니다.'
                }
                else {
                    apiState.message = '서버와 연결이 원활하지 않습니다.'
                }
            }
            else if (err instanceof PlatformError) {
                apiState.message = err.message
                apiState.statusCode = 500;
                if (!err.isSystemError) {
                    apiState.message = err.message;
                }
                else {
                    apiState.message = '시스템 오류입니다.'
                }
            }
            else {
                apiState.statusCode = 500;
                apiState.message = '시스템 오류입니다.'
            }
            apiState.state = false;
        })




    return apiState;
}



const RequestCertKey = async (type: 'ID' | 'EMAIL', objectID?: string): Promise<IKeriAPIState> => {
    let apiState: IKeriAPIState = {
        state: true,
        message: undefined,
    }

    try {
        let reqBody: IAPIRequestCertCodeRequestBody = {};

        try {
            if (type === 'EMAIL') {
                reqBody = {
                    mberEmailAdres: objectID
                }
            }
            else if (type === 'ID') {
                reqBody = {
                    mberId: objectID
                }
            }

        }
        catch (e) {
            throw new PlatformError("요청을 보낼 수 없습니다.", true);
        }


        await CallAPI<IKeriAPIRequestCertCodeResult>({
            url: KeriAPIStructure.ETC.RequestCertCode,
            method: "POST",
            requestBody: reqBody
        })
            .then(res => {
                try {
                    if (res.data.resultCode !== 'S') {
                        apiState.state = false;
                        apiState.message = res.data.resultMessage;
                    }
                }
                catch (e) {
                    throw new PlatformError("데이터를 읽어올 수 없습니다.", false);
                }
            })
            .catch((e) => {
                throw e;
            })

    }
    catch (err) {
        if (err instanceof AxiosError) {
            if (err.response?.status === 401) {
                apiState.message = '로그인이 만료되었습니다. \n다시 로그인해주시길 바랍니다.'
            }
            else if (err.response?.status === 403) {
                apiState.message = '권한이 없습니다.'
            }
            else {
                apiState.message = '서버와 연결이 원활하지 않습니다.'
            }
            console.log(err);
        }
        else if (err instanceof PlatformError) {
            apiState.message = err.message
            if (!err.isSystemError) {
                apiState.message = err.message;
            }
            else {
                apiState.message = err.message !== undefined ? err.message : '시스템 오류입니다.'
            }
        }
        else {
            apiState.message = '시스템 오류입니다.'
        }
        apiState.state = false;
    }




    return apiState;




}





const ValidCertKey = async (type: 'ID' | 'EMAIL', objectID?: string, certKey?: string): Promise<IKeriAPIState> => {
    let apiState: IKeriAPIState = {
        state: true,
        message: undefined,
    }

    try {
        let reqBody: IAPIValidCertCodeRequestBody = {};

        try {
            if (type === 'EMAIL') {
                reqBody = {
                    mberEmailAdres: objectID,
                    certNumber: certKey
                }
            }
            else if (type === 'ID') {
                reqBody = {
                    mberId: objectID,
                    certNumber: certKey
                }
            }

        }
        catch (e) {
            throw new PlatformError("요청을 보낼 수 없습니다.", true);
        }


        await CallAPI<IKeriAPIRequestCertCodeResult>({
            url: KeriAPIStructure.ETC.ValidCertCode,
            method: "POST",
            requestBody: reqBody
        })
            .then(res => {
                try {
                    if (res.data.resultCode !== 'S') {
                        apiState.state = false;
                        apiState.message = res.data.resultMessage;
                    }
                }
                catch (e) {
                    throw new PlatformError("데이터를 읽어올 수 없습니다.", false);
                }
            })
            .catch((e) => {
                throw e;
            })

    }
    catch (err) {
        if (err instanceof AxiosError) {
            if (err.response?.status === 401) {
                apiState.message = '로그인이 만료되었습니다. \n다시 로그인해주시길 바랍니다.'
            }
            else if (err.response?.status === 403) {
                apiState.message = '권한이 없습니다.'
            }
            else {
                apiState.message = '서버와 연결이 원활하지 않습니다.'
            }
            console.log(err);
        }
        else if (err instanceof PlatformError) {
            apiState.message = err.message
            if (!err.isSystemError) {
                apiState.message = err.message;
            }
            else {
                apiState.message = err.message !== undefined ? err.message : '시스템 오류입니다.'
            }
        }
        else {
            apiState.message = '시스템 오류입니다.'
        }
        apiState.state = false;
    }




    return apiState;
}


interface IGetTechCodeMainCategoryList extends IKeriAPIState {
    resultData: ITechCodeInfo[]
}

const GetTechCodeMainCategoryList = async (): Promise<IGetTechCodeMainCategoryList> => {
    let apiState: IGetTechCodeMainCategoryList = {
        state: true,
        message: undefined,
        resultData: [],
    }

    try {


        // let reqBody: IKeriAPIGetFAQItemRequestBody = {};

        // try {
        //     reqBody = {
        //         faqId: faqID
        //     }
        // }
        // catch (e) {
        //     throw new PlatformError("요청을 보낼 수 없습니다.", true);
        // }


        await CallAPI<IKeriAPIGetTechCodeListResult>({
            url: KeriAPIStructure.Common.GetTechCodeMainCategory,
            method: "POST",
        })
            .then(res => {
                try {
                    if (res.data.resultCode !== 'S') {
                        apiState.state = false;
                        apiState.message = res.data.resultMessage;
                    } else if (res.data.resultCode === 'S') {

                        apiState.state = true;
                        apiState.resultData = res.data.resultData.map(resultItem => (
                            {
                                TechName: resultItem.mainCtgryNm!,
                                TechCode: resultItem.mainCtgryCd!,
                            }
                        ))

                    }
                }
                catch (e) {
                    throw new PlatformError("데이터를 읽어올 수 없습니다.", false);
                }
            })
            .catch((e) => {
                throw e;
            })

    }
    catch (err) {
        if (err instanceof AxiosError) {
            if (err.response?.status === 401) {
                apiState.message = '로그인이 만료되었습니다. \n다시 로그인해주시길 바랍니다.'
            }
            else if (err.response?.status === 403) {
                apiState.message = '권한이 없습니다.'
            }
            else {
                apiState.message = '서버와 연결이 원활하지 않습니다.'
            }
            console.log(err);
        }
        else if (err instanceof PlatformError) {
            apiState.message = err.message
            if (!err.isSystemError) {
                apiState.message = err.message;
            }
            else {
                apiState.message = err.message !== undefined ? err.message : '시스템 오류입니다.'
            }
        }
        else {
            apiState.message = '시스템 오류입니다.'
        }
        apiState.state = false;
    }




    return apiState;
}


interface IGetTechCodeSubCategoryList extends IKeriAPIState {
    resultData: ITechCodeInfo[]
}

const GetTechCodeSubCategoryList = async (mainCategoryCode?: string): Promise<IGetTechCodeSubCategoryList> => {
    let apiState: IGetTechCodeSubCategoryList = {
        state: true,
        message: undefined,
        resultData: [],
    }

    try {


        let reqBody: IkeriAPIGetTechCodeSubCategoryRequestBody = {};

        try {
            reqBody = {
                mainCtgryCd: mainCategoryCode!
            }
        }
        catch (e) {
            throw new PlatformError("요청을 보낼 수 없습니다.", true);
        }


        await CallAPI<IKeriAPIGetTechCodeListResult>({
            url: KeriAPIStructure.Common.GetTechCodeSubCategory,
            method: "POST",
            requestBody: reqBody
        })
            .then(res => {
                try {
                    if (res.data.resultCode !== 'S') {
                        apiState.state = false;
                        apiState.message = res.data.resultMessage;
                    } else if (res.data.resultCode === 'S') {

                        apiState.state = true;
                        apiState.resultData = res.data.resultData.map(resultItem => (
                            {
                                TechName: resultItem.subCtgryNm!,
                                TechCode: resultItem.subCtgryCd!,
                                TechCategoryCode: resultItem.mainCtgryCd!
                            }
                        ))

                    }
                }
                catch (e) {
                    throw new PlatformError("데이터를 읽어올 수 없습니다.", false);
                }
            })
            .catch((e) => {
                throw e;
            })

    }
    catch (err) {
        if (err instanceof AxiosError) {
            if (err.response?.status === 401) {
                apiState.message = '로그인이 만료되었습니다. \n다시 로그인해주시길 바랍니다.'
            }
            else if (err.response?.status === 403) {
                apiState.message = '권한이 없습니다.'
            }
            else {
                apiState.message = '서버와 연결이 원활하지 않습니다.'
            }
            console.log(err);
        }
        else if (err instanceof PlatformError) {
            apiState.message = err.message
            if (!err.isSystemError) {
                apiState.message = err.message;
            }
            else {
                apiState.message = err.message !== undefined ? err.message : '시스템 오류입니다.'
            }
        }
        else {
            apiState.message = '시스템 오류입니다.'
        }
        apiState.state = false;
    }




    return apiState;
}


interface IGetTechCodeList extends IKeriAPIState {
    resultData: ITechCodeInfo[]
}

const GetTechCodeList = async (mainCategoryCode?: string, subCategoryCode?: string): Promise<IGetTechCodeList> => {
    let apiState: IGetTechCodeList = {
        state: true,
        message: undefined,
        resultData: [],
    }

    try {


        let reqBody: IkeriAPIGetTechCodeRequestBody = {};

        try {
            reqBody = {
                mainCtgryCd: mainCategoryCode!,
                subCtgryCd: subCategoryCode!,
                pageIndex: "1",
                pageUnit: "10000"
            }
        }
        catch (e) {
            throw new PlatformError("요청을 보낼 수 없습니다.", true);
        }


        await CallAPI<IKeriAPIGetTechCodeListResult>({
            url: KeriAPIStructure.Common.GetTechCode,
            method: "POST",
            requestBody: reqBody
        })
            .then(res => {
                try {
                    if (res.data.resultCode !== 'S') {
                        apiState.state = false;
                        apiState.message = res.data.resultMessage;
                    } else if (res.data.resultCode === 'S') {

                        apiState.state = true;
                        apiState.resultData = res.data.resultData.map(resultItem => (
                            {
                                TechName: resultItem.indsTechClsNm!,
                                TechCode: resultItem.indsTechClsCd!,
                                TechCategoryCode: resultItem.mainCtgryCd!,
                                TechCategoryName: resultItem.mainCtgryNm!,
                                TechSubCategoryCode: resultItem.subCtgryCd!,
                                TechSubCategoryName: resultItem.subCtgryNm
                            }
                        ))

                    }
                }
                catch (e) {
                    throw new PlatformError("데이터를 읽어올 수 없습니다.", false);
                }
            })
            .catch((e) => {
                throw e;
            })

    }
    catch (err) {
        if (err instanceof AxiosError) {
            if (err.response?.status === 401) {
                apiState.message = '로그인이 만료되었습니다. \n다시 로그인해주시길 바랍니다.'
            }
            else if (err.response?.status === 403) {
                apiState.message = '권한이 없습니다.'
            }
            else {
                apiState.message = '서버와 연결이 원활하지 않습니다.'
            }
            console.log(err);
        }
        else if (err instanceof PlatformError) {
            apiState.message = err.message
            if (!err.isSystemError) {
                apiState.message = err.message;
            }
            else {
                apiState.message = err.message !== undefined ? err.message : '시스템 오류입니다.'
            }
        }
        else {
            apiState.message = '시스템 오류입니다.'
        }
        apiState.state = false;
    }




    return apiState;
}

interface IGetTemplateList extends IKeriAuthAPIState {
    resultData: {
        TemplateTitle: string,
        TemplateID: string,
        TemplateAtchFileID: string
    }[]
}

const GetTemplateList = async (): Promise<IGetTemplateList> => {
    let apiState: IGetTemplateList = {
        state: true,
        message: undefined,
        resultData: [],
        tokenValid: true
    }

    try {

        await CallAPI<IKeriAPIGetTemplateListResult>({
            url: KeriAPIStructure.Template.GetTemplateList,
            method: "POST",
            requestBody: {}
        })
            .then(res => {
                try {
                    if (res.data.resultCode !== 'S') {
                        apiState.state = false;
                        apiState.message = res.data.resultMessage;
                    } else if (res.data.resultCode === 'S') {

                        apiState.state = true;
                        console.error(res.data.resultData);
                        apiState.resultData = res.data.resultData.map(resultItem => (
                            {
                                TemplateAtchFileID: resultItem.atchFileId!,
                                TemplateID: resultItem.templateId!,
                                TemplateTitle: resultItem.templateSj!
                            }
                        ))

                    }
                }
                catch (e) {
                    throw new PlatformError("데이터를 읽어올 수 없습니다.", false);
                }
            })
            .catch((e) => {
                throw e;
            })

    }
    catch (err) {
        if (err instanceof AxiosError) {
            if (err.response?.status === 401) {
                apiState.message = '로그인이 만료되었습니다. \n다시 로그인해주시길 바랍니다.'
                apiState.tokenValid = false;
            }
            else if (err.response?.status === 403) {
                apiState.message = '권한이 없습니다.'
            }
            else {
                apiState.message = '서버와 연결이 원활하지 않습니다.'
            }
            console.log(err);
        }
        else if (err instanceof PlatformError) {
            apiState.message = err.message
            if (!err.isSystemError) {
                apiState.message = err.message;
            }
            else {
                apiState.message = err.message !== undefined ? err.message : '시스템 오류입니다.'
            }
        }
        else {
            apiState.message = '시스템 오류입니다.'
        }
        apiState.state = false;
    }




    return apiState;
}


interface IGetSwiperImageList extends IKeriAPIState {
    resultData: ISwiperImage[]
}

const GetSwiperImageList = async (): Promise<IGetSwiperImageList> => {
    let apiState: IGetSwiperImageList = {
        state: true,
        message: undefined,
        resultData: []
    }

    try {

        apiState.state = true;
        apiState.statusCode = 200;

        apiState.resultData = [
            // {
            //     idx: 1,
            //     url: `${process.env.PUBLIC_URL}/assets/img/ict/images/main01.jpg`,
            //     title: <>Korea Electrotechnology <br /> Research Institute</>,
            //     subTitle: <>공정혁신 시뮬레이션 센터</>
            // },
            // {
            //     idx: 2,
            //     url: `${process.env.PUBLIC_URL}/assets/img/ict/images/main02.jpg`,
            //     title: <>공정혁신 <br /> 시물레이션 센터 개관</>,
            //     subTitle: <>경상남도 제조기업의 기술 경쟁력을 뒷받침하겠습니다.</>,
            //     button: {
            //         value: 'VIEW MORE',
            //         link: URLStructure.Main
            //     }
            // }
            {
                idx: 1,
                url: `${process.env.PUBLIC_URL}/assets/img/main-swiper-image/main01.jpg`,
                subTitle: '경남창원 스마트산업단지<br />공정혁신시뮬레이션 사업'
            },
            {
                idx: 2,
                url: `${process.env.PUBLIC_URL}/assets/img/main-swiper-image/main02.jpg`,
                title: '기술지원 사례',
                subTitle: '끊임없는 혁신과 도전정신으로<br />인류의 삶의 질을 향상시키다',
                endTitle: '고객의 가치 추구를 위한 혁신과 도전',
                siteName: '㈜지유'
            },
            {
                idx: 3,
                url: `${process.env.PUBLIC_URL}/assets/img/main-swiper-image/main03.jpg`,
                title: '기술지원 사례',
                subTitle: '최상의 기술을 쉽고 간단하게 이용할 수 있는<br />최고의 솔루션을 제공하다',
                endTitle: '용접의 자동화로 삶을 더욱 풍요롭게 만드는',
                siteName: '베스트에프에이㈜'
            },
            {
                idx: 4,
                url: `${process.env.PUBLIC_URL}/assets/img/main-swiper-image/main04.jpg`,
                title: '기술지원 사례',
                subTitle: '첨단 기술에 대한 도전과 혁신으로 고객에게<br />최고의 제품과 서비스를 제공하다',
                endTitle: '자동화 정보제어 기술로 인간의 미래를 더 풍요롭게 하는',
                siteName: '㈜나산전기산업'
            },
            {
                idx: 5,
                url: `${process.env.PUBLIC_URL}/assets/img/main-swiper-image/main05.jpg`,
                title: '기술지원 사례',
                subTitle: '의지와 열정으로<br />새로운 기술세계를 열다',
                endTitle: '고품질과 기술력을 자랑하는 국가 방위산업 전문기업',
                siteName: '㈜골드테크'
            },
            {
                idx: 6,
                url: `${process.env.PUBLIC_URL}/assets/img/main-swiper-image/main06.jpg`,
                title: '기술지원 사례',
                subTitle: '즐거움이 있는 100년 기업을 위한<br />Global Top Cylinder',
                endTitle: '명실상부한 세계 최고의 유압실린더 제조기업',
                siteName: '디와이파워㈜'
            },
            {
                idx: 7,
                url: `${process.env.PUBLIC_URL}/assets/img/main-swiper-image/main07.jpg`,
                title: '기술지원 사례',
                subTitle: '세계 최초의 기술, 초청정 이송용 무선 전력 공급 및<br />선형 전동기 결합 기술을 구현하다',
                endTitle: '가치를 창조하는 기술중심 세계일류 Mechatronics 기업',
                siteName: '㈜브이에이엠'
            },
            {
                idx: 8,
                url: `${process.env.PUBLIC_URL}/assets/img/main-swiper-image/main08.jpg`,
                title: '기술지원 사례',
                subTitle: 'Superconductivity(초전도성),<br />미래를 준비하다',
                endTitle: '세계 최고의 고온 초전도 기술 강소기업으로 성장하는',
                siteName: '㈜수퍼제닉스'
            },
            {
                idx: 9,
                url: `${process.env.PUBLIC_URL}/assets/img/main-swiper-image/main09.jpg`,
                title: '기술지원 사례',
                subTitle: '지치지 않는 열정으로<br />국내외 의료기기산업 성장에 기여하다',
                endTitle: '유아동, 청소년의 질병 치료 선두주자',
                siteName: '㈜제이앤킴'
            },
            {
                idx: 10,
                url: `${process.env.PUBLIC_URL}/assets/img/main-swiper-image/main10.jpg`,
                title: '기술지원 사례',
                subTitle: '탄소 제로 시대,<br />폐플라스틱을 재활용하다',
                endTitle: '아시아 넘버1 해양산업 전문기업을 향하는',
                siteName: '㈜지주'
            },
            {
                idx: 11,
                url: `${process.env.PUBLIC_URL}/assets/img/main-swiper-image/main11.jpg`,
                title: '기술지원 사례',
                subTitle: '의지와 열정으로<br />경쟁력을 더하다',
                endTitle: '고품질과 기술력을 자랑하는 국가 방위산업 전문기업',
                siteName: '㈜코코드론'
            },
            {
                idx: 12,
                url: `${process.env.PUBLIC_URL}/assets/img/main-swiper-image/main12.jpg`,
                title: '기술지원 사례',
                subTitle: '대한민국 대표 등대공장,<br />새로운 기술세계를 열다',
                endTitle: '미래의 자동차산업을 위한 이상적인 파트너',
                siteName: '태림산업㈜'
            },
            {
                idx: 13,
                url: `${process.env.PUBLIC_URL}/assets/img/main-swiper-image/main13.jpg`,
                title: '기술지원 사례',
                subTitle: '독보적인 기술과 풍부한 경험으로<br />명품 베어링을 만들다',
                endTitle: '20년간 오로지 유체윤활베어링 한길만 걸어온',
                siteName: '㈜터보링크'
            },
        ]

        // await CallAPI<IKeriAPIGetTemplateListResult>({
        //     url: KeriAPIStructure.Template.GetTemplateList,
        //     method: "POST",
        //     requestBody: {}
        // })
        //     .then(res => {
        //         try {
        //             if (res.data.resultCode !== 'S') {
        //                 apiState.state = false;
        //                 apiState.message = res.data.resultMessage;
        //             } else if (res.data.resultCode === 'S') {

        //                 apiState.state = true;
        //                 console.error(res.data.resultData);
        //                 apiState.resultData = res.data.resultData.map(resultItem => (
        //                     {
        //                         TemplateAtchFileID: resultItem.atchFileId!,
        //                         TemplateID: resultItem.templateId!,
        //                         TemplateTitle: resultItem.templateSj!
        //                     }
        //                 ))

        //             }
        //         }
        //         catch (e) {
        //             throw new PlatformError("데이터를 읽어올 수 없습니다.", false);
        //         }
        //     })
        //     .catch((e) => {
        //         throw e;
        //     })


    }
    catch (err) {
        if (err instanceof AxiosError) {
            if (err.response?.status === 401) {
                apiState.message = '로그인이 만료되었습니다. \n다시 로그인해주시길 바랍니다.'
            }
            else if (err.response?.status === 403) {
                apiState.message = '권한이 없습니다.'
            }
            else {
                apiState.message = '서버와 연결이 원활하지 않습니다.'
            }
            console.log(err);
        }
        else if (err instanceof PlatformError) {
            apiState.message = err.message
            if (!err.isSystemError) {
                apiState.message = err.message;
            }
            else {
                apiState.message = err.message !== undefined ? err.message : '시스템 오류입니다.'
            }
        }
        else {
            apiState.message = '시스템 오류입니다.'
        }
        apiState.state = false;
    }




    return apiState;
}


interface IResetUserPassword extends IKeriAPIState {

}

const ResetUserPassword = async (userID?: string): Promise<IResetUserPassword> => {
    let apiState: IResetUserPassword = {
        state: true,
        message: undefined,
    }

    try {


        let reqBody: IKeriAPIResetUserPasswordRequestBody = {};

        try {
            reqBody = {
                mberId: userID!
            }
        }
        catch (e) {
            throw new PlatformError("요청을 보낼 수 없습니다.", true);
        }


        await CallAPI<IKeriAPIResetUserPasswordResult>({
            url: KeriAPIStructure.Login.ResetUserPassword,
            method: "PUT",
            requestBody: reqBody
        })
            .then(res => {
                try {
                    if (res.data.resultCode !== 'S') {
                        apiState.state = false;
                        apiState.message = res.data.resultMessage;
                        apiState.statusCode = 400;
                    } else if (res.data.resultCode === 'S') {

                        apiState.state = true;
                        apiState.statusCode = 200;
                        apiState.message = res.data.resultMessage;
                    }
                }
                catch (e) {
                    throw new PlatformError("데이터를 읽어올 수 없습니다.", false);
                }
            })
            .catch((e) => {
                throw e;
            })

    }
    catch (err) {
        if (err instanceof AxiosError) {
            if (err.response?.status === 401) {
                apiState.message = '로그인이 만료되었습니다. \n다시 로그인해주시길 바랍니다.'
            }
            else if (err.response?.status === 403) {
                apiState.message = '권한이 없습니다.'
            }
            else {
                apiState.message = '서버와 연결이 원활하지 않습니다.'
            }
            console.log(err);
        }
        else if (err instanceof PlatformError) {
            apiState.message = err.message
            if (!err.isSystemError) {
                apiState.message = err.message;
            }
            else {
                apiState.message = err.message !== undefined ? err.message : '시스템 오류입니다.'
            }
        }
        else {
            apiState.message = '시스템 오류입니다.'
        }
        apiState.state = false;
    }




    return apiState;
}


interface IFindAccountID extends IKeriAPIState{

}


const FindAccountID = async (userEmail? : string) : Promise<IFindAccountID> => {
    let apiResult: IFindAccountID = {
        message: undefined,
        state: true,
        statusCode: -1,
    }

    try {

        let reqBody: IKeriAPIFindAccountIDRequestBody = {};

        try {
            reqBody = {
                mberEmailAdres : userEmail!
            }
        }
        catch (e) {
            throw new KeriAPIException('API 요청 데이터 생성 실패')
        }


        await CallAPI<IKeriAPIFindAccountIDResult>({
            url: KeriAPIStructure.Login.FindAccount,
            method: 'POST',
            requestBody: reqBody
        })
            .then(res => {
                try {
                    if (res.data.resultCode === 'S') {
                        apiResult.state = true;
                        apiResult.statusCode = 200;
                        apiResult.message = res.data.resultMessage;
                    }
                    else {
                        throw new KeriAPIException(res.data.resultMessage, 400);
                    }
                }
                catch (ex) {
                    if (ex instanceof KeriAPIException) {
                        throw ex;
                    }
                    else {
                        throw new KeriAPIException('API 요청 데이터 수집 실패');
                    }
                }
            })
            .catch(e => {
                throw e;
            })
    }
    catch (e) {
        const exp = KeriAPIExceptionFilter(e);
        apiResult.message = exp.message;
        apiResult.state = false;
        apiResult.statusCode = exp.state;
    }


    return apiResult;
}


export default RequestKeriAPI;
