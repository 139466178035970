import { ILoadControl, InitLoadControl, LoadControlUnit } from "@ict-base/components/common/LoadControl";
import ICTBaseModal, { KeriModalSubmitAndCancelButton } from "@ict-base/components/control/ICTBaseModal";
import ICTReplyBox from "@ict-base/components/control/ICTReplyBox";
import KeriActionButton from "@ict-base/components/control/KeriActionButton";
import { ObjectUtils } from "@ict-base/module/utils/ObjectUtils";
import LoginChecker from "@keri-base/components/common/LoginChecker";
import { IPageProp } from "@keri-base/interface/common/IPageProp";
import { PlatformError } from "@keri-base/interface/common/PlatformError";
import KeriBoardLockUnit from "@keri-portal/component/control/board/KeriBoardLockUnit";
import InfoLayoutPage from "@keri-portal/component/layout/InfoLayoutPage";
import { IUploadFileVO } from "@keri-portal/interfaces/api/keri-api/object/IUploadFileVO";
import { IUploadFile } from "@keri-portal/interfaces/control/IUploadFile";
import { IBoard, IBoardItem, IBoardItemComment } from "@keri-portal/interfaces/data-object/data-common/IBoard";
import RequestKeriAPI from "@keri-portal/module/method/api-connect/RequestKeriAPI";
import { ArrowDownward, ArrowUpward, Error, InsertDriveFile, Refresh, RemoveRedEyeOutlined } from "@mui/icons-material";
import { CircularProgress, Typography, Avatar, Divider, Paper, TextField, IconButton } from "@mui/material";
import { Box } from "@mui/system";
import { ILoginSessionData } from "@src/modules/redux/reducer/UserDataReducer";
import URLStructure from "@src/modules/structure/URLStructure";
import React, { useEffect } from "react";
import { useState } from "react";
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom";

const ShareBoardItemInfoPage = (prop: IPageProp) => {

    const [loginData, setLoginData] = useState<ILoginSessionData | undefined>(undefined);
    const [loadControl, setLoadControl] = useState<ILoadControl>(InitLoadControl);

    const param = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    const lockQuery = location.search.replace('?', '').split('&').map(x => ({ key: x.split('=')[0], value: x.split('=')[1] })).find(x => x.key === 'auth')?.value;

    const [boardMaster, setBoardMaster] = useState<IBoard | undefined>(undefined);
    const [boardItem, setBoardItem] = useState<IBoardItem | undefined>(undefined);
    const [boardFileList, setBoardFileList] = useState<IUploadFile<IUploadFileVO>[]>([]);
    const [commentList, setCommentList] = useState<IBoardItemComment[]>([]);

    const [lockData, setLockData] = useState<{ isLock: boolean, password?: string }>({ isLock: false });

    const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

    const [openDownloadSection, setOpenDownloadSection] = useState<boolean>(false);

    //#region function

    const funcObj = {
        GetBoardMasterData: async (boardID: string) => {

            const getData = await RequestKeriAPI.Board.GetBoardContentList(loginData?.token, boardID, undefined, 0, 1);

            if (getData.state) {

                setBoardMaster(getData.resultData);
            }
            else {
                throw new PlatformError(getData.message);
            }
        },
        GetBoardItemData: async (boardID: string, boardItemID: string, authKey?: string): Promise<{ state: boolean, message?: string }> => {
            const getData = await RequestKeriAPI.Board.GetBoardItemData(loginData?.token, boardID, boardItemID, authKey, lockQuery === 'y');

            if (getData.state) {
                console.log('ITEM_DATA', getData.resultData);
                if ((getData.resultData?.BoardList ?? []).length !== 0) {
                    setBoardItem(getData.resultData!.BoardList[0]);
                    setCommentList(getData.resultData?.BoardList[0].CommentList ?? []);
                    setBoardFileList(getData.fileList ?? []);
                }

                return { state: true }
            }
            else {
                let message: string | undefined = undefined;
                if (getData.statusCode === 401) {
                    message = '권한이 없습니다.';
                }
                else {
                    message = getData.message;
                }
                return { state: false, message: message }
            }
        },
        GetCommentList: async (boardID: string, boardItemID: string) => {
            const getData = await RequestKeriAPI.Board.Comment.GetCommentList(boardID, boardItemID, loginData?.token);

            if (getData.state) {
                setCommentList(getData.resultData);
            }
            else {
                throw new PlatformError(getData.message);
            }
        },
        SetComment: async (boardID: string, boardItemID: string, commentText?: string) => {
            try {
                console.log(commentText);
                if (ObjectUtils.IsNullOrEmpty(commentText)) {
                    throw new PlatformError("빈 값입니다.");
                }

                const setData = await RequestKeriAPI.Board.Comment.SetComment(boardID, boardItemID, commentText!, loginData?.token);

                if (setData.state) {
                    await funcObj.GetCommentList(boardID, boardItemID);
                }
                else {
                    throw new PlatformError(setData.message);
                }
            }
            catch (e) {
                console.log(e);
                let errMessage: string | undefined = 'undefined';
                if (e instanceof PlatformError) {
                    errMessage = e.message;
                }
                else {
                    errMessage = "시스템 에러";
                }

                alert(errMessage);
            }
        }

    }

    const loadContent = async (boardID: string, boardItemID: string) => {
        setLoadControl({
            isLoading: true,
            isComplete: false
        })

        try {
            await funcObj.GetBoardMasterData(boardID);

            console.log(lockQuery);


            if (location.search.replace('?', '').split('&').map(x => ({ key: x.split('=')[0], value: x.split('=')[1] })).find(x => x.key === 'auth') !== undefined) {
                setLockData({ isLock: true });
            }
            else {

                const loadAuthCheck = await funcObj.GetBoardItemData(boardID, boardItemID, lockData.password);
                if (!loadAuthCheck.state) {
                    throw new PlatformError(loadAuthCheck.message);
                }
            }

            setLoadControl({
                isLoading: false,
                isComplete: true,
            })
        }
        catch (e) {
            console.log('wgwegew', e);
            let errMessage: string | undefined = 'undefined';
            if (e instanceof PlatformError) {
                errMessage = e.message;
            }
            else {
                errMessage = "시스템 에러";
            }

            console.log('WEER', errMessage);

            setLoadControl({
                isLoading: false,
                isComplete: true,
                errorMessage: errMessage
            })
        }
    }



    const checkParams = () => {
        console.log('checkParam');
        if (param.boardID === undefined || param.boardItemID === undefined) {
            setLoadControl({
                isLoading: false,
                isComplete: true,
                errorMessage: '게시글을 찾을 수 없습니다.'
            })
        }
        else {
            if (loginData?.loadComplete) {
                loadContent(param.boardID, param.boardItemID);
            }
        }
    }

    //#endregion

    useEffect(() => {
        checkParams();
    }, [param, loginData])


    useEffect(() => {
        console.log('lockdata', lockData);
    }, [lockData])


    useEffect(() => {
        console.error(boardItem);
    },[boardItem])




    return (
        <LoginChecker
            onCheck={(state, data) => {
                console.log('loginState', state);
                if (state !== undefined) {
                    console.warn('LOGIN_DATA', data);
                    setLoginData({ ...data });
                }
            }}
        >

            <LoadControlUnit
                isLoading={loadControl.isLoading && !loadControl.isComplete}
                errorMessage={loadControl.errorMessage}
                loadComponent={
                    <InfoLayoutPage>
                        <Paper>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: '100%',
                                    height: '200px'
                                }}
                            >
                                <CircularProgress color="inherit" />
                            </Box>
                        </Paper>
                    </InfoLayoutPage>

                }
                errorComponent={
                    <InfoLayoutPage>
                        <Paper>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: '100%',
                                    height: '200px',
                                    flexDirection: 'column'
                                }}
                            >
                                <Error color="error" sx={{ fontSize: '2rem', mb: 1.5 }} />
                                <Typography variant="h6">
                                    {loadControl.errorMessage}
                                </Typography>
                                {/* <Typography variant="body1" sx={{ color: 'rgba(0,0,0,0.5)' }}>
                                    {loadControl.errorMessage}
                                </Typography> */}
                                <IconButton
                                    onClick={() => checkParams()}
                                    disableRipple
                                >
                                    <Refresh
                                        sx={{
                                            color: 'rgba(0,0,0,0.3)'
                                        }}
                                    />
                                </IconButton>
                            </Box>
                        </Paper>
                    </InfoLayoutPage>
                }
            >
                <KeriBoardLockUnit
                    isLock={lockData.isLock}
                    checkAuth={async (password) => {

                        const checkAuth = await RequestKeriAPI.Board.GetBoardItemData(loginData?.token, param.boardID, param.boardItemID, password, true);

                        return {
                            state: checkAuth.state,
                            errorMessage: checkAuth.message
                        }
                    }}
                    onAuth={async (state, password) => {
                        if (state) {
                            setLoadControl({
                                isLoading: true,
                                isComplete: false,
                            })

                            setLockData({ isLock: false, password: password });
                            try {
                                const loadAuthCheck = await funcObj.GetBoardItemData(param.boardID!, param.boardItemID!, password);
                                if (loadAuthCheck.state) {
                                    setLoadControl({
                                        isLoading: false,
                                        isComplete: true,
                                    })
                                }
                                else {
                                    throw new PlatformError(loadAuthCheck.message);
                                }
                            }
                            catch (e) {
                                let errMessage: string | undefined = 'undefined';
                                if (e instanceof PlatformError) {
                                    errMessage = e.message;
                                }
                                else {
                                    errMessage = "시스템 에러";
                                }

                                setLoadControl({
                                    isLoading: false,
                                    isComplete: true,
                                    errorMessage: errMessage
                                })
                            }
                        }
                    }}
                >
                    <InfoLayoutPage
                        title={boardMaster?.BoardTitle}
                        showChild={boardItem !== undefined}
                    >
                        <Paper
                            sx={{
                                p: 2,
                                mb: 2
                            }}
                        >
                            <Box>
                                <Box
                                    sx={{
                                        mb: 1
                                    }}
                                >
                                    <Typography variant="h6">
                                        {boardItem?.BoardItemTitle}
                                    </Typography>
                                </Box>

                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'end'
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'start',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <Box>
                                            <Avatar
                                                sx={{
                                                    width: '2rem',
                                                    height: '2rem',
                                                }}
                                            />
                                        </Box>
                                        <Box
                                            sx={{
                                                ml: 1
                                            }}
                                        >
                                            <Typography variant="body2">
                                                {boardItem?.BoardItemWriter}
                                            </Typography>
                                            <Typography variant="body2" sx={{ color: '#aaa' }}>
                                                {ObjectUtils.DateToString(boardItem?.BoardItemWriteDate, 'yyyy-MM-dd')}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'end',
                                            alignItems: 'end',
                                            color: '#aaa',
                                            '& *': {
                                                fontSize: '0.8rem'
                                            }
                                        }}
                                    >
                                        {/* 조회수 */}
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'start',
                                                alignItems: 'center',
                                                ml: 1
                                            }}
                                        >
                                            <RemoveRedEyeOutlined
                                                sx={{
                                                    mr: 0.5,
                                                    fontSize: '1.0rem !important',
                                                }}
                                            />
                                            <Typography>
                                                {boardItem?.BoardItemViewCount ?? 0}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                            <Divider sx={{ mt: 1, mb: 1 }} />

                            {
                                (boardFileList ?? []).length !== 0 &&
                                <Box
                                    sx={{

                                        border: '1px solid rgba(0,0,0,0.1)',
                                        p: 1,
                                        borderRadius: '5px',
                                        transition: '0.5s all cubic-bezier(0,0,0,1)',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'start',
                                            alignItems: 'center',
                                            mb: openDownloadSection ? 1 : 0,
                                            cursor: 'pointer'
                                        }}
                                        onClick={() => setOpenDownloadSection(!openDownloadSection)}
                                    >
                                        {
                                            !openDownloadSection
                                                ?
                                                <ArrowDownward
                                                    sx={{
                                                        mr: 1
                                                    }}
                                                />
                                                :
                                                <ArrowUpward
                                                    sx={{
                                                        mr: 1
                                                    }}
                                                />
                                        }

                                        <Typography>
                                            {
                                                openDownloadSection
                                                    ? `접기`
                                                    : `파일 다운로드 (${(boardFileList ?? []).length})`
                                            }

                                        </Typography>
                                    </Box>
                                    {openDownloadSection && <Divider />}
                                    {
                                        openDownloadSection &&
                                        <Box
                                            className="BoardUploadFiles"
                                        >
                                            {
                                                (boardFileList ?? []).map(fileItem => (
                                                    <Box
                                                        sx={{
                                                            width: '100%',
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                            alignItems: 'center',
                                                            p: 1
                                                        }}
                                                    >
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                justifyContent: 'start',
                                                                alignItems: 'center',
                                                                transition: '0.3s all cubic-bezier(0,0,0,1)',
                                                                cursor: 'pointer',
                                                                ":hover": {
                                                                    color: '#aaa',
                                                                }
                                                            }}
                                                            onClick={async () => {
                                                                const requestData = await RequestKeriAPI.File.DownloadFile(loginData?.token, fileItem);

                                                                if (requestData.state) {
                                                                    const jsonFile = new Blob([requestData.data]);
                                                                    const element = document.createElement("a");

                                                                    element.href = URL.createObjectURL(jsonFile);
                                                                    element.download = fileItem.UploadedFile?.orignlFileNm ?? 'undefined';
                                                                    document.body.appendChild(element);
                                                                    element.click();
                                                                    element.remove();
                                                                }
                                                                else {
                                                                    alert(requestData.message);
                                                                }
                                                            }}
                                                        >
                                                            <InsertDriveFile
                                                                sx={{
                                                                    m: 0,
                                                                    p: 0,
                                                                    mr: 1,
                                                                    color: '#75889d'
                                                                }}
                                                            />
                                                            <Typography>
                                                                {fileItem.UploadedFile?.orignlFileNm}
                                                            </Typography>

                                                        </Box>
                                                    </Box>
                                                ))
                                            }
                                        </Box>
                                    }
                                </Box>
                            }





                            <Box
                                sx={{
                                    p: 1
                                }}
                            >
                                <div dangerouslySetInnerHTML={{ __html: boardItem?.BoardItemContent ?? '' }} className='ContentHTML' />
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'end',
                                    alignItems: 'center'
                                }}
                            >
                                {
                                    ((boardItem?.BoardState ?? 'Receipt') !== 'Complete') &&
                                    <KeriActionButton
                                        label="수정"
                                        variant="contained"
                                        onClick={() => {
                                            // console.log(`${URLStructure.TechConsulting}/${param.boardItemID}/edit${location.search.replace('?', '').split('&').map(x => ({ key: x.split('=')[0], value: x.split('=')[1] })).find(x => x.key === 'auth') !== undefined ? '?auth' : ''}`);
                                            navigate(`edit${location.search.replace('?', '').split('&').map(x => ({ key: x.split('=')[0], value: x.split('=')[1] })).find(x => x.key === 'auth') !== undefined ? '?auth' : ''}`);
                                            // navigate(`${URLStructure.TechConsulting}/${param.boardItemID}/edit${location.search.replace('?', '').split('&').map(x => ({ key: x.split('=')[0], value: x.split('=')[1] })).find(x => x.key === 'auth') !== undefined ?? '?auth'}`)
                                        }}
                                        sx={{
                                            mr: 1
                                        }}
                                    />
                                }

                                <KeriActionButton
                                    label="목록"
                                    variant="contained"
                                    onClick={() => {
                                        navigate(`${process.env.PUBLIC_URL}/share-board/${param.boardID!}`);
                                    }}
                                    sx={{
                                        mr: 1
                                    }}
                                />
                            </Box>
                        </Paper>

                        {/* Comment Section */}
                        <Paper
                            sx={{
                                p: 2
                            }}
                        >
                            <Box>
                                <Box
                                    sx={{
                                        mb: 1
                                    }}
                                >
                                    <Typography variant="h6">
                                        댓글
                                    </Typography>
                                </Box>
                            </Box>
                            <Divider sx={{ mt: 1, mb: 1 }} />
                            <Box>
                                <ICTReplyBox
                                    value={commentList}
                                    displayProps={{
                                        displayText: (item) => item.CommentContent,
                                        displayUserName: (item) => item.CommentWriter,
                                        filterUserCode: (item) => item.CommentWriterCode,
                                        displayDate: (item) => ObjectUtils.DateToString(item.CommentCreateDate) ?? ''
                                    }}
                                    onSubmit={async (submitText) => funcObj.SetComment(param.boardID!, param.boardItemID!, submitText)}
                                />
                            </Box>
                        </Paper>
                    </InfoLayoutPage>
                </KeriBoardLockUnit>

            </LoadControlUnit>


            {/* Modal */}
            <React.Fragment>
                <WarningModal
                    open={deleteModalOpen}
                    token={loginData?.token}
                    boardID={param.boardID}
                    boardItemID={param.boardItemID}
                    onClose={() => setDeleteModalOpen(false)}
                    onSubmit={() => {
                        alert('삭제가 완료되었습니다.')
                        navigate(-1);
                    }}
                />
            </React.Fragment>
        </LoginChecker>
    )
}

interface IWarningModal {
    open: boolean,
    onClose: () => void,
    onSubmit: () => void,
    token?: string,
    boardID?: string,
    boardItemID?: string
}

const WarningModal = (prop: IWarningModal) => {

    const [password, setPassword] = useState<string | undefined>(undefined);

    const deleteBoardItem = async (): Promise<boolean> => {
        const getData = await RequestKeriAPI.Board.DeleteBoardData(prop.token, prop.boardID, prop.boardItemID, password);

        if (getData.state) {
            return true;
        }
        else {
            alert(getData.message);
            return false;
        }
    }

    return (
        <ICTBaseModal
            open={prop.open}
            onClose={prop.onClose}
            submitButton={
                <KeriModalSubmitAndCancelButton
                    onCancel={prop.onClose}
                    onSubmit={async () => {
                        const deleteItem = await deleteBoardItem();

                        if (deleteItem) {
                            prop.onClose();
                            prop.onSubmit();
                        }
                    }}
                />
            }
            title="게시글 삭제"
        >
            <Box
                sx={{
                    p: 2
                }}
            >
                <Typography>
                    게시글 삭제 시 복구할 수 없습니다.
                </Typography>
                <Typography
                    sx={{
                        mt: 2,
                        mb: 1
                    }}
                >
                    삭제를 원하시면 비밀번호 입력 후 확인을 누르세요.
                </Typography>
                <TextField
                    size="small"
                    fullWidth
                    type={'password'}
                    onChange={(e) => {
                        setPassword(e.currentTarget.value);
                    }}
                />
            </Box>

        </ICTBaseModal>
    )
}

export default ShareBoardItemInfoPage;