import { ObjectUtils } from "@ict-base/module/utils/ObjectUtils";
import { MISCFileAPI, MISCTechServiceAPI } from "@ictpeople/misc-api";
import { IKeriLocalFile, IKeriServerFile } from "@ictpeople/misc-core";
import { MISCFileGridView, MISCFileGridViewItem } from "@keri-portal/component/control/common/MISCFileGridView";
import { MISCTechSErviceSectionEmptyDataBox } from "@keri-portal/component/control/tech-service/info/MISCTechSErviceSectionEmptyDataBox";
import KeriConfig from "@keri-portal/config/KeriConfig";
import { IComponentRefresh, IForwardFunc } from "@keri-portal/page/member-info/tech-service-list/TechServiceItemViewPage";
import { Add, Assessment, InsertDriveFile, FileDownload, Delete, InsertDriveFileOutlined } from "@mui/icons-material";
import { CircularProgress, Typography, IconButton, Divider, Menu, MenuItem, Fade, ListItemIcon, Box, Paper, SxProps, Theme } from "@mui/material";
import { ILoginSessionData } from "@src/modules/redux/reducer/UserDataReducer";
import React, { useState, useImperativeHandle, useEffect, Dispatch, SetStateAction } from "react";
import { useMutation, useQuery } from "react-query";
import { useSelector } from "react-redux";
import { MISCTechServiceSectionErrorBox } from "../../../control/tech-service/info/MISCTechServiceSectionErrorBox";


interface ITechServiceProcessCompleteFilesComponent extends IComponentRefresh {
    TechServiceID: string;
}

export const TechServiceProcessCompleteFilesComponent = React.forwardRef<
    IForwardFunc,
    ITechServiceProcessCompleteFilesComponent
>((prop: ITechServiceProcessCompleteFilesComponent, ref) => {

    const userRedux: ILoginSessionData = useSelector((state: any) => state.user);

    const [openFileMenu, setOpenFileMenu] = useState<HTMLElement | null>(null);
    const [errorObj, setErrorObj] = useState<string | undefined>(undefined);


    const techServiceDataQuery = useQuery(
        'tech-service-data',
        async () => await MISCTechServiceAPI.GetTechServiceInfo({
            techServiceUniqueId: prop.TechServiceID,
            token: userRedux?.token
        }, process.env.REACT_APP_API_URL),
        {
            refetchOnWindowFocus: false,
            enabled: (prop.TechServiceID !== undefined && userRedux?.token !== undefined),
            onSuccess: (data) => {
                if (data.state === false) {
                    if (data.statusCode === 401) {
                        setErrorObj('로그인이 만료되었습니다. 다시 로그인해주시길 바랍니다.');
                    }
                    else {
                        setErrorObj(data.message);
                    }

                }
            },
            onError: (err) => {
                console.log('FAILED GET TECH SERVICE DATA', err);
            }
        }
    )


    const uploadFileMutate = useMutation(
        async (item: IKeriLocalFile) => await MISCFileAPI.UploadFile({
            file: item,
            token: userRedux?.token
        }, process.env.REACT_APP_API_URL),
        {
            onSuccess: (data) => {
                if (data.state === true) {
                    console.log('FILE UPLOAD SUCCESS', data.fileData);
                    appendTechServiceFileMutate.mutate(data.fileData?.fileStoreName === undefined ? [] : [data.fileData.fileStoreName]);
                }
                else {
                    alert('파일을 업로드하지 못했습니다.');
                    console.log(data);
                }
                // prop.refreshPage();
            },
            onError: (err) => {
                alert('파일을 업로드하지 못했습니다.');
                console.log(err)
            }
        }
    )

    const appendTechServiceFileMutate = useMutation(
        async (fileNames: string[]) => await MISCTechServiceAPI.AppendFileInTechService({
            appendFileStoreName: fileNames,
            techServiceId: techServiceDataQuery.data?.techServiceData?.uniqueId,
            token: userRedux?.token,
            atchFileId: techServiceDataQuery.data?.techServiceData?.atchFileId
        }, process.env.REACT_APP_API_URL),
        {
            onSuccess: (data) => {
                if (data.state === true) {
                    techServiceDataQuery.refetch();
                }
                else {
                    alert('파일 업로드 실패.')
                    console.log(data);
                }
            },
            onError: (err) => {
                alert('파일 업로드 실패.')
                console.log(err);
            }
        }
    )

    const deleteFileMutate = useMutation(
        async (items: IKeriServerFile[]) => await MISCTechServiceAPI.RemoveFileInTechService({
            removeFileStoreName: items.map(x => x.fileStoreName).filter(x => x !== undefined) as string[],
            techServiceId: techServiceDataQuery.data?.techServiceData?.uniqueId,
            token: userRedux?.token,
            atchFileId: techServiceDataQuery.data?.techServiceData?.atchFileId
        }, process.env.REACT_APP_API_URL),
        {
            onSuccess: (data) => {
                if (data.state === true) {
                    techServiceDataQuery.refetch();
                }
                else {
                    alert('파일 삭제 실패.')
                    console.log(data);
                }
            },
            onError: (err) => {
                alert('파일 삭제 실패.')
                console.log(err);
            }
        }
    )

    const downloadFileMutate = useMutation(
        async (item: IKeriServerFile) => await MISCFileAPI.GetFile({
            atchFileId: item.atchFileID,
            fileSn: item.fileSequence,
            token: userRedux.token
        }, process.env.REACT_APP_API_URL),
        {
            onSuccess: (data) => {
                if (data.state !== true) {
                    alert('파일 다운로드 실패.')
                    console.log(data);
                }
            },
            onError: (err) => {
                alert('파일 다운로드 실패.')
                console.log(err);
            }
        }
    )



    const completeFileList = techServiceDataQuery.data?.techServiceData?.files?.filter(x => x.fileCategory === 'TechServiceCompleteFiles');
    const isLoading = techServiceDataQuery.isLoading || appendTechServiceFileMutate.isLoading || uploadFileMutate.isLoading;
    const isManageExpertUser = userRedux.resultVO?.isProfesnal === 'Y' && techServiceDataQuery.data?.techServiceData?.expertUser?.uniqueId === userRedux.resultVO.uniqId;
    const isAccessAllow = isManageExpertUser && techServiceDataQuery.data?.techServiceData?.status?.code?.toUpperCase() === 'RECEIPT';



    const uploadFile = (type: 'CompleteReport' | 'Other') => {
        const element = document.createElement("input");
        element.type = 'file';
        element.hidden = true;

        element.onchange = (evt: any) => {

            const fileList: File[] = [...(evt.target.files ?? [])];

            if (fileList.length === 0) {
                alert('파일이 선택되지 않았습니다.');
            }
            else {
                const ext = fileList[0].name.split('.')[fileList[0].name.split('.').length - 1];
                const isSizeOverflow = fileList[0].size > KeriConfig.UploadMaxFileSize;

                console.log(fileList[0].size);

                if (KeriConfig.DenyUploadFileExt.find(x => x === ext) !== undefined) {
                    alert(`허용되지 않는 확장자입니다.\n(${KeriConfig.DenyUploadFileExt.join(', ')})`)
                }
                else if (isSizeOverflow) {
                    alert(`업로드 파일 용량이 500MB를 넘을 수 없습니다.`)
                }
                else {
                    const fileObj: IKeriLocalFile = {
                        fileCategory: 'TechServiceCompleteFiles',
                        fileData: fileList[0],
                        filePrefix: 'TEC_COMPL_',
                        fileExtension: ext,
                        fileTitle: type === 'CompleteReport' ? '완료보고서' : '참고자료',
                        fileRequired: type === 'CompleteReport'
                    }

                    uploadFileMutate.mutate(fileObj);
                }


            }
            element.remove();
        }

        document.body.appendChild(element);
        element.click();

    }

    const deleteFileHandler = async (item: IKeriServerFile, setLoading: Dispatch<SetStateAction<boolean>>) => {
        setLoading(true);
        if (window.confirm(`삭제 항목 : [${item.fileTitle}] ${item.fileOriginalName}\n삭제 후 되돌릴 수 없습니다.\n삭제하시겠습니까?`)) {
            await deleteFileMutate.mutateAsync([item])
        }
        setLoading(false);
    }


    const downloadFileHandler = async (item: IKeriServerFile, setLoading: Dispatch<SetStateAction<boolean>>) => {
        setLoading(true);

        const fileObj = (await downloadFileMutate.mutateAsync(item)).file;

        console.log(fileObj);

        if (fileObj === undefined) {
            alert('파일을 찾을 수 없습니다.');
        }
        else {
            const element = document.createElement("a");

            element.href = URL.createObjectURL(fileObj);
            element.download = item.fileOriginalName ?? 'download.unknown';
            document.body.appendChild(element);
            element.click();
            element.remove();
        }

        setLoading(false);
    }






    useImperativeHandle(ref, () => ({
        refresh: () => {
            techServiceDataQuery.refetch();
        },
    }));

    useEffect(() => {
        if (prop.TechServiceID !== undefined && userRedux?.loadComplete) {
            techServiceDataQuery.refetch();
        }

        return (() => {
            techServiceDataQuery.remove();
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prop.TechServiceID, userRedux]);




    return (
        <Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}
            >
                <Typography
                    variant="h6"
                    sx={{
                        fontWeight: 'bold',
                        m: 1
                    }}
                >
                    기술지원 결과 자료
                </Typography>
                {
                    (!techServiceDataQuery.isLoading && isAccessAllow) &&
                    <React.Fragment>
                        <Box>
                            <IconButton
                                onClick={(e) => setOpenFileMenu(e.currentTarget)}
                            >
                                <Add />
                            </IconButton>

                        </Box>
                        <Menu
                            anchorEl={openFileMenu}
                            open={openFileMenu !== null}
                            onClose={() => setOpenFileMenu(null)}
                            TransitionComponent={Fade}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                            {
                                completeFileList?.filter(x => x.fileTitle === '완료보고서').length === 0 &&
                                <MenuItem
                                    onClick={() => {
                                        setOpenFileMenu(null)
                                        uploadFile('CompleteReport');
                                    }}
                                >
                                    <ListItemIcon>
                                        <Assessment />
                                    </ListItemIcon>
                                    <Typography
                                        variant="inherit"
                                    >
                                        완료보고서 추가
                                    </Typography>

                                </MenuItem>
                            }

                            <MenuItem
                                onClick={() => {
                                    setOpenFileMenu(null)
                                    uploadFile('Other');
                                }}
                            >
                                <ListItemIcon>
                                    <InsertDriveFile />
                                </ListItemIcon>
                                <Typography>
                                    참고자료 추가
                                </Typography>

                            </MenuItem>
                        </Menu>
                    </React.Fragment>
                }
            </Box>
            <Divider />
            <Box>
                {
                    isLoading &&
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            minHeight: '150px'
                        }}
                    >
                        <CircularProgress
                            sx={{
                                color: 'black'
                            }}
                        />
                    </Box>
                }
                {
                    errorObj !== undefined &&
                    <MISCTechServiceSectionErrorBox
                        errorMessage={errorObj}
                        refreshFunction={() => techServiceDataQuery.refetch()}
                    />
                }
                {
                    (!isLoading && errorObj === undefined) && (completeFileList ?? []).length === 0 &&
                    <MISCTechSErviceSectionEmptyDataBox
                        refresh={() => techServiceDataQuery.refetch()}
                    />
                }
                {
                    !isLoading && (completeFileList ?? []).filter(x => x.fileTitle === '완료보고서').length !== 0 &&
                    <MISCFileGridView
                        title="완료보고서"
                    >
                        {
                            completeFileList?.filter(x => x.fileTitle === '완료보고서').map(x => (
                                <MISCFileGridViewItem
                                    item={x}
                                    onChange={() => techServiceDataQuery.refetch()}
                                    onDelete={deleteFileHandler}
                                    disableDelete={!isAccessAllow}
                                    onDownloadFile={downloadFileHandler}
                                />
                            ))
                        }

                    </MISCFileGridView>
                }
                {
                    !isLoading && (completeFileList ?? []).filter(x => x.fileTitle === '참고자료').length !== 0 &&
                    <MISCFileGridView
                        title="참고자료"
                    >
                        {
                            completeFileList?.filter(x => x.fileTitle === '참고자료').map(x => (
                                <MISCFileGridViewItem
                                    item={x}
                                    onChange={() => techServiceDataQuery.refetch()}
                                    onDelete={deleteFileHandler}
                                    disableDelete={!isAccessAllow}
                                    onDownloadFile={downloadFileHandler}
                                />
                            ))
                        }
                    </MISCFileGridView>

                }
                
                {
                    isAccessAllow &&
                    <Box
                        sx={{
                            pl : 1,
                            pr : 1
                        }}
                    >
                    <Typography variant="body2" sx={{ color: '#7171dd' }}>
                        ⚠ 다음 확장자는 업로드 할 수 없습니다. ({KeriConfig.DenyUploadFileExt.join(', ')}) <br />
                        ⚠ 500MB 이상 파일은 업로드 할 수 없습니다.
                    </Typography>
                </Box>
                    
                }
            </Box>
        </Box>
    );
});




