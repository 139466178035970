import { ObjectUtils } from "@ict-base/module/utils/ObjectUtils";
import { MISCTechServiceAPI } from "@ictpeople/misc-api";
import { IKeriLocalFile, IKeriTechServiceSimulation } from "@ictpeople/misc-core";
import { MISCModal } from "@ictpeople/misc-react";
import { Add, Close, Delete, InsertDriveFileOutlined } from "@mui/icons-material";
import { Typography, IconButton, Divider, Button, Paper } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/system";
import { ILoginSessionData } from "@src/modules/redux/reducer/UserDataReducer";
import { useState, useEffect } from 'react';
import { useMutation } from "react-query";
import { useSelector } from "react-redux";

interface IRefetchSimulationProp {
    open: boolean,
    onClose: () => void,
    item: IKeriTechServiceSimulation,
    onSubmit : () => void
}

const RefetchSimulation = (prop: IRefetchSimulationProp) => {

    const [selectInputFiles, setSelectInputFiles] = useState<IKeriLocalFile[]>([]);
    const userRedux : ILoginSessionData = useSelector((state : any) => state.user)

    const SetupSimulation = useMutation(
        async () => await MISCTechServiceAPI.RefetchSimulation({
            techServiceId : prop.item.techServiceId,
            inputFile : selectInputFiles[0].fileData,
            token : userRedux.token
        },process.env.REACT_APP_API_URL),
        {
            onSuccess : (data) => {
                if(data.state){
                    prop.onSubmit();
                }
                else{
                    alert(`시뮬레이션을 생성할 수 없습니다.\n${data.message}`);    
                    console.log('Simulation Create Error : ',data);    
                }
            },
            onError : (err) => {
                alert('시뮬레이션을 생성할 수 없습니다.');
                console.log('Simulation Create Error : ',err);
            }
        }
    )


    useEffect(() => {
        if (!prop.open) {
            setSelectInputFiles([]);
        }
    }, [prop.open])

    return (
        <MISCModal
            onClose={prop.onClose}
            open={prop.open}
            paperProps={{
                sx: {
                    width: '500px'
                }
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    pt: 1,
                    pr: 2,
                    pl: 2,
                    pb: 0
                }}
            >
                <Typography
                    variant="subtitle1"
                    sx={{
                        fontWeight: 'bold'
                    }}
                >
                    시뮬레이션 재생성
                </Typography>
                <IconButton
                    sx={{
                        m: 0,
                        p: 0
                    }}
                    onClick={prop.onClose}
                >
                    <Close />
                </IconButton>
            </Box>
            <Divider sx={{ mt: 1, mb: 1 }} />
            <Box
                sx={{
                    pl: 2,
                    pr: 2,
                    pb: 2,
                    display : SetupSimulation.isLoading ? 'none' : undefined
                }}
            >
                <Box
                    sx={{
                        mt: 2
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                    >
                        <Typography
                            variant="subtitle2"
                            sx={{

                            }}
                        >
                            Input Files
                        </Typography>
                        <IconButton
                            sx={{
                                m: 0,
                                p: 0
                            }}
                            onClick={() => {
                                const element = document.createElement("input");
                                element.type = 'file';
                                element.hidden = true;
                                // element.multiple = true;
                                // element.download = item.UploadedFile?.orignlFileNm ?? 'undefined';
                                document.body.appendChild(element);
                                element.click();
                                element.onchange = (evt: any) => {
                                    // console.log(evt);
                                    const fileList: File[] = [...(evt.target.files ?? [])];

                                    console.log(fileList);
                                    setSelectInputFiles([
                                        ...fileList.map(x => ({
                                            fileTitle: 'test',
                                            fileData: x
                                        }) as IKeriLocalFile)
                                    ])


                                    element.remove();
                                }

                            }}
                        >
                            <Add />
                        </IconButton>
                    </Box>
                    <Divider sx={{ mt: 1, mb: 1 }} />
                    <Box
                        sx={{
                            mt: 1,
                            background: "white",
                            transition: "0.3s background cubic-bezier(0,0,0,1)",
                        }}

                    >
                        {
                            selectInputFiles.length === 0 &&
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    minHeight: "100px"
                                }}
                            >
                                <Typography
                                    variant="subtitle2"
                                >
                                    파일이 없습니다.
                                </Typography>
                            </Box>
                        }
                        {
                            selectInputFiles.map(x => (
                                <UploadFileItem
                                    item={x}
                                    onDelete={(item) => {
                                        setSelectInputFiles([
                                            ...selectInputFiles.filter(x=>x !== item)
                                        ])
                                    }}
                                />
                            ))
                        }

                    </Box>
                    <Box
                        sx={{
                            mt: 2,
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center"
                        }}
                    >
                        <Button
                            variant="contained"
                            color="error"
                            onClick={prop.onClose}
                        >
                            취소
                        </Button>
                        <Button
                            variant="contained"
                            color="success"
                            disabled={selectInputFiles.length === 0}
                            onClick={() => {
                                window.confirm('시뮬레이션 생성 시 삭제할 수 없습니다.\n생성하시겠습니까?') && SetupSimulation.mutate()
                            }}
                        >
                            생성
                        </Button>
                    </Box>
                </Box>
            </Box>
            <Box
                sx={{
                    display : SetupSimulation.isLoading ? 'flex' : 'none',
                    justifyContent : 'center',
                    alignItems : 'center',
                    minHeight : '150px'
                }}
            >
                <CircularProgress sx={{color : 'black'}}/>
            </Box>
        </MISCModal>
    )
}

const UploadFileItem = (prop: { item: IKeriLocalFile, onDelete: (item: IKeriLocalFile) => void }) => {
    return (
        <Paper
            elevation={1}
            sx={{
                border: '1px solid rgba(0,0,0,0.05)',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
                height: '60px',
                p: 1
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'start',
                    alignItems: 'center',
                    height: '100%'
                }}
            >
                <InsertDriveFileOutlined
                    // fontSize="large"
                    sx={{
                        height: '80%',
                        width: "auto"
                    }}
                />
                <Box
                    sx={{
                        ml: 1
                    }}
                >
                    <Typography variant="subtitle2">
                        {prop.item.fileData?.name}
                    </Typography>
                    <Typography variant="body2" color="gray">
                        {ObjectUtils.FileSizeFormater(prop.item.fileData?.size ?? 0)}
                    </Typography>
                </Box>
            </Box>
            <Box>
                <IconButton
                    onClick={() => prop.onDelete(prop.item)}
                >
                    <Delete />
                </IconButton>
            </Box>
        </Paper>
    )
}

export default RefetchSimulation;