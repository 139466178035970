import { KeriEditGrid, KeriEditGridCheckBoxField, KeriEditGridInputField } from "@ict-base/components/control/KeriEditGrid"
import CallAPI from "@ict-base/module/utils/CallAPI"
import { ObjectUtils } from "@ict-base/module/utils/ObjectUtils"
import RegexUtil from "@ict-base/module/utils/RegexUtil"
import { IAPISendCertCodeRequestBody, IAPIValidCertCodeRequestBody } from "@keri-portal/interfaces/api/keri-api/IKeriAPIRequest"
import { IKeriAPIResponse } from "@keri-portal/interfaces/api/keri-api/IKeriAPIResponse"
import { UserType } from "@keri-portal/interfaces/data-object/data-common/IUserObject"
import { IUserInfoInputData, IUserInfoInputError } from "@keri-portal/interfaces/process-object/account/IUserInfo"
import RequestKeriAPI from "@keri-portal/module/method/api-connect/RequestKeriAPI"
import { VisibilityOff, Visibility } from "@mui/icons-material"
import { IconButton, Typography } from "@mui/material"
import { useState } from "react"
import { useTimer } from "react-timer-hook"

interface IUserInputField {
    editMode: 'Edit' | 'View',
    dataObject: {
        data: IUserInfoInputData,
        onChange: (item: IUserInfoInputData) => void
    },
    error: {
        data: IUserInfoInputError,
        onChange: (item: IUserInfoInputError) => void
    }
    displayObject?: {
        /** 회원 아이디 */
        UserID?: boolean,
        /** 회원명 */
        UserName?: boolean,
        /** 직급 */
        UserRank?: boolean,
        /** 부서 */
        UserDepartment?: boolean,
        /** 회원 이메일 */
        UserEmail?: boolean,
        /** 회원 전화번호 */
        UserCallNumber?: boolean,
        /** 회원 휴대전화 번호 */
        UserCellPhoneNumber?: boolean,
        /** 회원 비밀번호 */
        UserPassword?: boolean
        /** 회원 유형 */
        UserType?: boolean,
        /** 첨부파일 */
        UserFiles?: boolean,
        /** 회원 기업 정보 */
        UserCompany?: boolean,
        /** 비밀번호 재입력 */
        ValidPassword?: boolean,
    }
}


interface IUserType {
    key: string,
    value: string,
    returnData: UserType
}

const userTypeData: IUserType[] = [
    {
        key: 'comUser',
        value: '기업회원',
        returnData: 'NORMAL'
    },
    {
        key: 'outsider',
        value: '외부전문가',
        returnData: 'PRO'
    }
]

const TIMER_MAX = 5 * 60;
// const TIMER_MAX = 5;

export const UserInputField = (prop: IUserInputField) => {

    const time = new Date();
    time.setSeconds(time.getSeconds() + TIMER_MAX)
    const timer = useTimer({
        expiryTimestamp: time, onExpire: () => {
            prop.dataObject.onChange({
                ...prop.dataObject.data,
                CertProcess: {
                    ...prop.dataObject.data.CertProcess,
                    CertTimeout: false,
                    RequestCertCode: false,
                    CertCode: undefined,
                    ValidedCertCode: false
                }
            });
        }, autoStart: false
    })

    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showValPassword, setShowValPassword] = useState<boolean>(false);


    return (
        <KeriEditGrid>
            {
                (prop.displayObject?.UserID ?? true) &&
                <KeriEditGridInputField
                    title="아이디"
                    props={{
                        width: '50%'
                    }}
                    required={prop.editMode === 'Edit'}
                    value={prop.editMode === 'View' ? prop.dataObject.data.UserID : undefined}
                    editDisabled={prop.editMode === 'View'}
                    tooltip={{
                        // enable: prop.editMode === 'Edit',
                        // message: 't'
                        enable : false,
                        message : undefined
                    }}
                    defaultValue={prop.dataObject.data.UserID}
                    onChange={(changeText) => {
                        prop.dataObject.onChange({
                            ...prop.dataObject.data,
                            UserID: ObjectUtils.IsNullOrEmpty(changeText) ? undefined : changeText
                        })

                        if (prop.error.data.UserID) {
                            prop.error.onChange({
                                ...prop.error.data,
                                UserID: undefined
                            })
                        }
                    }}
                    error={{
                        isError: prop.error.data.UserID !== undefined,
                        message: prop.error.data.UserID
                    }}
                />
            }


            {
                (prop.displayObject?.UserName ?? true) &&
                <KeriEditGridInputField
                    title="담당자명"
                    props={{
                        width: '50%'
                    }}
                    required={prop.editMode === 'Edit'}
                    value={prop.editMode === 'View' ? prop.dataObject.data.UserName : undefined}
                    editDisabled={prop.editMode === 'View'}
                    tooltip={{
                        // enable: prop.editMode === 'Edit',
                        // message: 't'
                        enable : false,
                        message : undefined
                    }}
                    onChange={(changeText) => {
                        prop.dataObject.onChange({
                            ...prop.dataObject.data,
                            UserName: ObjectUtils.IsNullOrEmpty(changeText) ? undefined : changeText
                        })

                        if (prop.error.data.UserName) {
                            prop.error.onChange({
                                ...prop.error.data,
                                UserName: undefined
                            })
                        }
                    }}
                    error={{
                        isError: prop.error.data.UserName !== undefined,
                        message: prop.error.data.UserName
                    }}
                />
            }


            {
                (prop.displayObject?.UserRank ?? true) &&
                <KeriEditGridInputField
                    title="직급"
                    props={{
                        width: '50%'
                    }}
                    required={prop.editMode === 'Edit'}
                    value={prop.editMode === 'View' ? prop.dataObject.data.UserRank : undefined}
                    editDisabled={prop.editMode === 'View'}
                    tooltip={{
                        // enable: prop.editMode === 'Edit',
                        // message: 't'
                        enable : false,
                        message : undefined
                    }}
                    onChange={(changeText) => {
                        prop.dataObject.onChange({
                            ...prop.dataObject.data,
                            UserRank: ObjectUtils.IsNullOrEmpty(changeText) ? undefined : changeText
                        })

                        if (prop.error.data.UserRank) {
                            prop.error.onChange({
                                ...prop.error.data,
                                UserRank: undefined
                            })
                        }
                    }}
                    error={{
                        isError: prop.error.data.UserRank !== undefined,
                        message: prop.error.data.UserRank
                    }}
                />
            }

            {
                (prop.displayObject?.UserDepartment ?? true) &&
                <KeriEditGridInputField
                    title="부서"
                    props={{
                        width: '50%'
                    }}
                    required={prop.editMode === 'Edit'}
                    value={prop.editMode === 'View' ? prop.dataObject.data.UserDepartment : undefined}
                    editDisabled={prop.editMode === 'View'}
                    tooltip={{
                        // enable: prop.editMode === 'Edit',
                        // message: 't'
                        enable : false,
                        message : undefined
                    }}
                    onChange={(changeText) => {
                        prop.dataObject.onChange({
                            ...prop.dataObject.data,
                            UserDepartment: ObjectUtils.IsNullOrEmpty(changeText) ? undefined : changeText
                        })

                        if (prop.error.data.UserDepartment) {
                            prop.error.onChange({
                                ...prop.error.data,
                                UserDepartment: undefined
                            })
                        }
                    }}
                    error={{
                        isError: prop.error.data.UserDepartment !== undefined,
                        message: prop.error.data.UserDepartment
                    }}
                />
            }


            {
                (prop.displayObject?.UserEmail ?? true) &&
                <KeriEditGridInputField
                    title="이메일 주소"
                    props={{
                        width: '100%'
                    }}
                    required={prop.editMode === 'Edit'}
                    value={prop.editMode === 'View' ? prop.dataObject.data.UserEmail : undefined}
                    editDisabled={prop.editMode === 'View'}
                    tooltip={{
                        // enable: prop.editMode === 'Edit',
                        // message: 't'
                        enable : false,
                        message : undefined
                    }}
                    onChange={(changeText) => {
                        prop.dataObject.onChange({
                            ...prop.dataObject.data,
                            UserEmail: ObjectUtils.IsNullOrEmpty(changeText) ? undefined : changeText
                        })

                        if (prop.error.data.UserEmail) {
                            prop.error.onChange({
                                ...prop.error.data,
                                UserEmail: undefined
                            })
                        }

                        if ((prop.dataObject.data.CertProcess?.RequestCertCode ?? false)) {
                            prop.error.onChange({
                                ...prop.error.data,
                                CertProcess: {
                                    ...prop.error.data.CertProcess,
                                    CertCode: '이메일이 재설정되었습니다. 인증번호 요청을 다시 해주세요.'
                                }
                            })
                            timer.pause();
                            prop.dataObject.onChange({
                                ...prop.dataObject.data,
                                CertProcess: {
                                    ...prop.dataObject.data.CertProcess,
                                    RequestCertCode: false,
                                    ValidedCertCode: false
                                }
                            })
                        }
                    }}
                    error={{
                        isError: prop.error.data.UserEmail !== undefined,
                        message: prop.error.data.UserEmail,
                    }}
                    button={{
                        isEnable: prop.editMode === 'Edit',
                        clickDisabled: prop.dataObject.data.CertProcess?.RequestCertCode ?? false,
                        label: (prop.dataObject.data.CertProcess?.RequestCertCode ?? false) ? '요청 완료' : '인증번호 요청',
                        sx: {
                            width: 120
                        },
                        onClick: async () => {
                            if (!RegexUtil.CheckString(RegexUtil.RegexType.Email, prop.dataObject.data.UserEmail)) {
                                prop.error.onChange({
                                    ...prop.error.data,
                                    UserEmail: '이메일 형식이 맞지 않습니다.'
                                })
                            }
                            else {

                                const getRequestCertCode = await RequestKeriAPI.Common.RequestCertKey('EMAIL', prop.dataObject.data.UserEmail?.trim());

                                if (getRequestCertCode.state) {
                                    if (prop.error.data.CertProcess?.CertCode) {
                                        prop.error.onChange({
                                            ...prop.error.data,
                                            CertProcess: {
                                                ...prop.error.data.CertProcess,
                                                CertCode: undefined
                                            }

                                        })
                                    }
                                    const time = new Date();
                                    time.setSeconds(time.getSeconds() + TIMER_MAX)
                                    timer.restart(time);

                                    prop.dataObject.onChange({
                                        ...prop.dataObject.data,
                                        CertProcess: {
                                            ...prop.dataObject.data.CertProcess,
                                            CertTimeout: false,
                                            RequestCertCode: true
                                        }
                                    });
                                }
                                else {
                                    prop.error.onChange({
                                        ...prop.error.data,
                                        UserEmail: getRequestCertCode.message
                                    })
                                }
                            }

                        }
                    }}
                />
            }



            {
                (prop.displayObject?.UserEmail ?? true) && (prop.editMode === 'Edit') &&
                <KeriEditGridInputField
                    title="인증번호"
                    editDisabled={!(prop.dataObject.data.CertProcess?.RequestCertCode ?? false) || (prop.dataObject.data.CertProcess?.ValidedCertCode ?? false)}
                    props={{
                        width: '100%',
                        endAdornment:
                            <Typography variant="body2">
                                {
                                    timer.isRunning
                                        ? `${timer.minutes}:${timer.seconds.toString().length === 1 ? `0${timer.seconds}` : timer.seconds}`
                                        : ''
                                }
                            </Typography>,
                    }}
                    required={prop.editMode === 'Edit'}
                    tooltip={{
                        // enable: prop.editMode === 'Edit',
                        // message: 't'
                        enable : false,
                        message : undefined
                    }}
                    onChange={(changeText) => {
                        prop.dataObject.onChange({
                            ...prop.dataObject.data,
                            CertProcess: {
                                ...prop.dataObject.data.CertProcess,
                                CertCode: ObjectUtils.IsNullOrEmpty(changeText) ? undefined : changeText
                            }
                        })
                        if (prop.error.data.CertProcess?.CertCode) {
                            prop.error.onChange({
                                ...prop.error.data,
                                CertProcess: {
                                    CertCode: undefined
                                }

                            })
                        }
                    }}
                    error={{
                        isError: prop.error.data.CertProcess?.CertCode !== undefined,
                        message: prop.error.data.CertProcess?.CertCode
                    }}
                    button={{
                        isEnable: true,
                        clickDisabled: !(prop.dataObject.data.CertProcess?.RequestCertCode ?? false) || (prop.dataObject.data.CertProcess?.ValidedCertCode ?? false),
                        label: (prop.dataObject.data.CertProcess?.ValidedCertCode ?? false) ? '인증 완료' : (prop.dataObject.data.CertProcess?.CertTimeout ?? false) ? '시간 만료' : '인증 확인',
                        sx: {
                            width: 120,
                        },
                        onClick: async () => {
                            if (prop.dataObject.data.CertProcess?.CertCode === undefined) {
                                prop.error.onChange({
                                    ...prop.error.data,
                                    CertProcess: {
                                        ...prop.error.data.CertProcess,
                                        CertCode: '인증번호가 빈 값입니다.'
                                    }
                                })
                            }
                            else if (!RegexUtil.CheckString(RegexUtil.RegexType.Number, prop.dataObject.data.CertProcess.CertCode)) {
                                prop.error.onChange({
                                    ...prop.error.data,
                                    CertProcess: {
                                        ...prop.error.data.CertProcess,
                                        CertCode: '인증번호는 숫자만 가능합니다.'
                                    }
                                })
                            }
                            else {
                                const validKey = await RequestKeriAPI.Common.ValidCertKey(
                                    'EMAIL',
                                    prop.dataObject.data.UserEmail?.trim(),
                                    isNaN(parseInt(prop.dataObject.data.CertProcess.CertCode ?? '', 10)) ? '000000' : prop.dataObject.data.CertProcess.CertCode ?? '000000'
                                );

                                if (validKey.state) {
                                    prop.error.onChange({
                                        ...prop.error.data,
                                        CertProcess: {
                                            ...prop.error.data.CertProcess,
                                            CertCode: undefined
                                        }
                                    })
                                    timer.pause();
                                    prop.dataObject.onChange({
                                        ...prop.dataObject.data,
                                        CertProcess: {
                                            ...prop.dataObject.data.CertProcess,
                                            ValidedCertCode: true
                                        }
                                    })

                                    if (prop.error.data.CertProcess?.CertCode) {
                                        prop.error.onChange({
                                            ...prop.error.data,
                                            CertProcess: {
                                                CertCode: undefined
                                            }

                                        })
                                    }
                                }
                                else {
                                    alert(validKey.message ?? '인증번호가 일치하지 않습니다');
                                    prop.error.onChange({
                                        ...prop.error.data,
                                        CertProcess: {
                                            ...prop.error.data.CertProcess,
                                            CertCode: validKey.message
                                        }

                                    })
                                }

                            }


                        }
                    }}
                />
            }


            {
                (prop.displayObject?.UserCallNumber ?? true) &&
                <KeriEditGridInputField
                    title="전화번호"
                    props={{
                        width: '50%'
                    }}
                    required={prop.editMode === 'Edit'}
                    value={prop.editMode === 'View' ? prop.dataObject.data.UserCallNumber : undefined}
                    editDisabled={prop.editMode === 'View'}
                    tooltip={{
                        // enable: prop.editMode === 'Edit',
                        // message: 't'
                        enable : false,
                        message : undefined
                    }}
                    onChange={(changeText, e) => {
                        e.target.value = RegexUtil.ConvertRegexString.PhoneNumber(e.target.value)!;

                        prop.dataObject.onChange({
                            ...prop.dataObject.data,
                            UserCallNumber: ObjectUtils.IsNullOrEmpty(changeText) ? undefined : RegexUtil.ConvertRegexString.PhoneNumber(changeText!)!.replace(/-/g, '')
                        })

                        if (prop.error.data.UserCallNumber) {
                            prop.error.onChange({
                                ...prop.error.data,
                                UserCallNumber: undefined
                            })
                        }
                    }}
                    error={{
                        isError: prop.error.data.UserCallNumber !== undefined,
                        message: prop.error.data.UserCallNumber,
                    }}
                />
            }

            {
                (prop.displayObject?.UserCellPhoneNumber ?? true) &&
                <KeriEditGridInputField
                    title="휴대전화번호"
                    props={{
                        width: '50%'
                    }}
                    required={prop.editMode === 'Edit'}
                    value={prop.editMode === 'View' ? prop.dataObject.data.UserCellPhoneNumber : undefined}
                    editDisabled={prop.editMode === 'View'}
                    tooltip={{
                        // enable: prop.editMode === 'Edit',
                        // message: 't'
                        enable : false,
                        message : undefined
                    }}
                    onChange={(changeText, e) => {
                        e.target.value = RegexUtil.ConvertRegexString.CellPhoneNumber(e.target.value)!;
                        prop.dataObject.onChange({
                            ...prop.dataObject.data,
                            UserCellPhoneNumber: ObjectUtils.IsNullOrEmpty(changeText) ? undefined : RegexUtil.ConvertRegexString.CellPhoneNumber(changeText!)!.replace(/-/g, '')
                        })

                        if (prop.error.data.UserCellPhoneNumber) {
                            prop.error.onChange({
                                ...prop.error.data,
                                UserCellPhoneNumber: undefined
                            })
                        }
                    }}
                    error={{
                        isError: prop.error.data.UserCellPhoneNumber !== undefined,
                        message: prop.error.data.UserCellPhoneNumber,
                    }}
                />
            }

            {
                (prop.displayObject?.UserPassword ?? true) &&
                <KeriEditGridInputField
                    title="비밀번호"
                    required={prop.editMode === 'Edit'}
                    type={showPassword ? 'text' : 'password'}
                    props={{
                        width: '100%',
                        endAdornment:
                            prop.editMode === 'Edit' &&
                            <IconButton
                                onClick={() => setShowPassword(!showPassword)}
                            >
                                {
                                    showPassword ? <VisibilityOff /> : <Visibility />
                                }
                            </IconButton>

                    }}
                    value={prop.editMode === 'View' ? prop.dataObject.data.UserPassword : undefined}
                    editDisabled={prop.editMode === 'View'}
                    tooltip={{
                        // enable: prop.editMode === 'Edit',
                        // message: 't'
                        enable : false,
                        message : undefined
                    }}
                    onChange={(changeText) => {
                        prop.dataObject.onChange({
                            ...prop.dataObject.data,
                            UserPassword: ObjectUtils.IsNullOrEmpty(changeText) ? undefined : changeText
                        })
                        if (prop.error.data.UserPassword) {
                            prop.error.onChange({
                                ...prop.error.data,
                                UserPassword: undefined
                            })
                        }
                    }}
                    error={{
                        isError: prop.error.data.UserPassword !== undefined,
                        message: prop.error.data.UserPassword,
                    }}
                />
            }

            {
                (prop.displayObject?.ValidPassword ?? true) &&
                <KeriEditGridInputField
                    title="비밀번호 확인"
                    required={prop.editMode === 'Edit'}
                    type={showValPassword ? 'text' : 'password'}
                    props={{
                        width: '100%',
                        endAdornment:
                            prop.editMode === 'Edit' &&
                            <IconButton
                                onClick={() => setShowValPassword(!showValPassword)}
                            >
                                {
                                    showValPassword ? <VisibilityOff /> : <Visibility />
                                }
                            </IconButton>
                    }}

                    onChange={(changeText) => {
                        prop.dataObject.onChange({
                            ...prop.dataObject.data,
                            ValidPassword: ObjectUtils.IsNullOrEmpty(changeText) ? undefined : changeText
                        })
                        if (prop.error.data.ValidPassword) {
                            prop.error.onChange({
                                ...prop.error.data,
                                ValidPassword: undefined
                            })
                        }
                    }}
                    error={{
                        isError: prop.error.data.ValidPassword !== undefined,
                        message: prop.error.data.ValidPassword
                    }}
                />
            }

            {
                (prop.displayObject?.UserType ?? true) &&
                <KeriEditGridCheckBoxField
                    title="회원 유형"
                    required={prop.editMode === 'Edit'}
                    // defaultValue={userTypeData.find(x=>x.returnData === prop.dataObject.data.UserType) ?? userTypeData[0]}
                    nonDisableItemChecked
                    data={userTypeData.filter(x => x !== undefined).map(x => x!)}
                    displayValue={(item) => item.value}
                    displayKey={(item) => item.key}
                    props={{
                        sx: {
                            width: '100%'
                        }
                    }}
                    value={
                        prop.editMode === 'View'
                            ? [userTypeData[0]]
                            : userTypeData.find(x => x.returnData === prop.dataObject.data.UserType) === undefined
                                ? [userTypeData[0]]
                                : [userTypeData.find(x => x.returnData === prop.dataObject.data.UserType)!]
                    }
                    editDisabled={prop.editMode === 'View'}
                    tooltip={{
                        // enable: prop.editMode === 'Edit',
                        // message: 't'
                        enable : false,
                        message : undefined
                    }}
                    onItemChanged={(item) => {
                        console.log(item);
                        if (item.length !== 0) {
                            prop.dataObject.onChange({
                                ...prop.dataObject.data,
                                UserType: item[0].returnData
                            })

                            if (prop.error.data.UserType !== undefined) {
                                prop.error.onChange({
                                    ...prop.error.data,
                                    UserType: undefined
                                })
                            }
                        }
                        else {
                            prop.dataObject.onChange({
                                ...prop.dataObject.data,
                                UserType: undefined
                            })
                        }
                    }}
                    error={{
                        isError: prop.error.data.UserType !== undefined,
                        message: prop.error.data.UserType
                    }}
                />
            }

        </KeriEditGrid>
    )
}



export default UserInputField;