import RegexUtil from "@ict-base/module/utils/RegexUtil";
import { ObjectUtils } from "@ict-base/module/utils/ObjectUtils";
import KeriBaseConfig from "@keri-base/config/KeriBaseConfig";
import { IAccountRegisterAgree, IAccountRegisterCompany, IAccountRegisterUser } from "@keri-portal/interfaces/process-object/account/IAccountRegister";
import { IUserInfoInputError } from "@keri-portal/interfaces/process-object/account/IUserInfo";
import { ICompanyInfoError } from "@keri-portal/interfaces/process-object/account/register/ICompanyInfoError";

const AccountRegisterCommonValidationCheck = {
    /** 회원가입 약관 동의 Validation Check */
    Step1Agree: (data: IAccountRegisterAgree): { state: boolean, errorData: IAccountRegisterStep1AgreeError } => AccountRegisterStep1AgreeCheck(data),
    /** 개인정보 이용 동의 Validation Check */
    ProvideInformationAgree: (data: IAccountRegisterAgree): { state: boolean, errorData: IAccountRegisterProvideInformationAgreeError } => AccountRegisterProvideInformationAgreeCheck(data),
    /** 신규 기업정보 등록 Validation Check */
    NewCompany: (data: IAccountRegisterCompany): { state: boolean, errorData: ICompanyInfoError } => AccountRegisterNewCompanyCheck(data),
    NewUser: (data: IAccountRegisterUser): { state: boolean, errorData: IUserInfoInputError } => AccountRegisterNewUserCheck(data),
}


/** 회원가입 약관 동의 에러 체크 Interface */
export interface IAccountRegisterStep1AgreeError {
    Agree?: string
}

/** 회원가입 약관 동의 Validation Check */
const AccountRegisterStep1AgreeCheck = (data: IAccountRegisterAgree): { state: boolean, errorData: IAccountRegisterStep1AgreeError } => {
    let state: boolean = true;
    let errorData: IAccountRegisterStep1AgreeError = {};


    if (!(data.data?.filter(x => x.required && !x.checked).length !== 0 ?? false)) {
        state = false;
        errorData.Agree = '회원가입 시 약관 동의는 필수입니다.'
    }

    return {
        state: state,
        errorData: errorData
    }
}




/** 개인정보 이용 동의 에러 체크 Interface */
export interface IAccountRegisterProvideInformationAgreeError {
    // PrivateInfoCollect?: string | undefined,
    // SharedInfoThirdParty?: string | undefined
    Agree?: string
}


/** 개인정보 이용 동의 Validation Check */
const AccountRegisterProvideInformationAgreeCheck = (data: IAccountRegisterAgree): { state: boolean, errorData: IAccountRegisterProvideInformationAgreeError } => {
    let state: boolean = true;
    let errorData: IAccountRegisterProvideInformationAgreeError = {};


    if (!(data.data?.filter(x => x.required && !x.checked).length === 0??false)) {
        state = false;
        errorData.Agree = '회원가입 시 약관 동의는 필수입니다.'
    }

    return {
        state: state,
        errorData: errorData
    }
}


/** 신규 기업정보 등록 Validation Check */
const AccountRegisterNewCompanyCheck = (data: IAccountRegisterCompany): { state: boolean, errorData: ICompanyInfoError } => {
    let state: boolean = true;
    let errorData: ICompanyInfoError = {};


    // 사업자 등록번호 
    if (ObjectUtils.IsNullOrEmpty(data.CompanyRegistrationNumber)) {
        state = false;
        errorData.CompanyRegistrationNumber = '필수 값입니다.'
    }
    else if (!(RegexUtil.CheckString(RegexUtil.RegexType.CompanyRegistrationNumber, data.CompanyRegistrationNumber))) {
        state = false;
        errorData.CompanyRegistrationNumber = '형식이 맞지 않습니다.'
    }
    else if(!(data.CompanyNumberValidCheck??false)){
        state = false;
        errorData.CompanyRegistrationNumber = '사업자 등록번호 조회가 필요합니다.'
    }

    // 법인 등록번호 
    if (ObjectUtils.IsNullOrEmpty(data.CorporateRegistrationNumber)) {
        // state = false;
        // errorData.CorporateRegistrationNumber = '필수 값입니다.'
    }
    else if (!(RegexUtil.CheckString(RegexUtil.RegexType.CorporateRegistrationNumber, data.CorporateRegistrationNumber))) {

        console.warn(data.CorporateRegistrationNumber,RegexUtil.RegexType.CorporateRegistrationNumber)

        state = false;
        errorData.CorporateRegistrationNumber = '형식이 맞지 않습니다.'
    }

    // 상호명
    if (ObjectUtils.IsNullOrEmpty(data.BusinessName)) {
        state = false;
        errorData.BusinessName = '필수 값입니다.'
    }

    // 상호명 (약어)
    if (ObjectUtils.IsNullOrEmpty(data.AbbreviationBusinessName)) {
        state = false;
        errorData.AbbreviationBusinessName = '필수 값입니다.'
    }
    else if(data.AbbreviationBusinessName!.length > 10){
        state = false;
        errorData.AbbreviationBusinessName = '상호명(약어)는 최대 10자입니다.'
    }

    // 대표자명
    if (ObjectUtils.IsNullOrEmpty(data.CEOName)) {
        state = false;
        errorData.CEOName = '필수 값입니다.'
    }

    // 대표자 이메일
    // if (ObjectUtils.IsNullOrEmpty(data.CEOEmail)) {
    //     state = false;
    //     errorData.CEOEmail = '필수 값입니다.'
    // }
    // else if (!(RegexUtil.CheckString(RegexUtil.RegexType.Email, data.CEOEmail))) {
    //     state = false;
    //     errorData.CEOEmail = '형식이 맞지 않습니다.'
    // }
    if (!ObjectUtils.IsNullOrEmpty(data.CEOEmail) && !(RegexUtil.CheckString(RegexUtil.RegexType.Email, data.CEOEmail))) {
        state = false;
        errorData.CEOEmail = '형식이 맞지 않습니다.'
    }

    // 산업기술코드  
    if (data.TechCode === undefined || data.TechCode.TechCode === undefined) {
        state = false;
        errorData.TechCode = '필수 값입니다.'
    }

    // 기업형태
    // if (ObjectUtils.IsNullOrEmpty(data.CorporateType)) {
    //     state = false;
    //     errorData.CorporateType = '필수 값입니다.'
    // }

    // 업태
    // if (ObjectUtils.IsNullOrEmpty(data.BusinessStatus)) {
    //     state = false;
    //     errorData.BusinessStatus = '필수 값입니다.'
    // }

    // 종목
    // if (ObjectUtils.IsNullOrEmpty(data.BusinessItem)) {
    //     state = false;
    //     errorData.BusinessItem = '필수 값입니다.'
    // }

    // 전화번호
    // if (ObjectUtils.IsNullOrEmpty(data.CompanyCallNumber)) {
    //     state = false;
    //     errorData.CompanyCallNumber = '필수 값입니다.'
    // }
    // else if (!(RegexUtil.CheckString(RegexUtil.RegexType.PhoneNumber, data.CompanyCallNumber))) {
    //     state = false;
    //     errorData.CompanyCallNumber = '형식이 맞지 않습니다.'
    // }

    if (!ObjectUtils.IsNullOrEmpty(data.CompanyCallNumber) && !(RegexUtil.CheckString(RegexUtil.RegexType.PhoneNumber, data.CompanyCallNumber))) {
        state = false;
        errorData.CompanyCallNumber = '형식이 맞지 않습니다.'
    }

    

    // 팩스번호
    // if (ObjectUtils.IsNullOrEmpty(data.CompanyFaxNumber)) {
    //     state = false;
    //     errorData.CompanyFaxNumber = '필수 값입니다.'
    // }
    // else if (!(RegexUtil.CheckString(RegexUtil.RegexType.PhoneNumber, data.CompanyFaxNumber))) {
    //     state = false;
    //     errorData.CompanyFaxNumber = '형식이 맞지 않습니다.'
    // }

    if (!ObjectUtils.IsNullOrEmpty(data.CompanyFaxNumber) && !(RegexUtil.CheckString(RegexUtil.RegexType.PhoneNumber, data.CompanyFaxNumber))) {
        state = false;
        errorData.CompanyFaxNumber = '형식이 맞지 않습니다.'
    }


    // 주소 
    if (data.CompanyAddress === undefined || data.CompanyAddress.jibunAddr === undefined) {
        state = false;
        errorData.CompanyAddress = '필수 값입니다.'
    }

    // 자본금액
    // if (data.CompanyCapital === undefined) {
    //     state = false;
    //     errorData.CompanyCapital = '필수 값입니다.'
    // }

    // 종업원 수
    // if (data.CompanyWorkerCount === undefined) {
    //     state = false;
    //     errorData.CompanyWorkerCount = '필수 값입니다.'
    // }

    // 필수 첨부파일
    if (data.CompanyFiles?.filter(x => x.Required === true && x.UploadedFile === undefined).length !== 0) {
        state = false;
        errorData.CompanyFiles = '필수 파일이 업로드되지 않았습니다.'
    }



    return {
        state: state,
        errorData: errorData
    }
}


const AccountRegisterNewUserCheck = (data: IAccountRegisterUser): { state: boolean, errorData: IUserInfoInputError } => {
    let state: boolean = true;
    let errorData: IUserInfoInputError = {};

    // 인증 확인
    if (!(data.CertProcess?.RequestCertCode ?? false)) {
        state = false;
        errorData.CertProcess = {
            ...errorData.CertProcess,
            RequestCertCode: '인증이 완료되지 않았습니다.'
        };
    }

    console.log('INPUT ID',data.UserID);

    // 아이디
    if (ObjectUtils.IsNullOrEmpty(data.UserID)) {
        state = false;
        errorData.UserID = '필수 값입니다.'
    }
    else if(data.UserID!.indexOf(' ') > -1){
        state = false;
        errorData.UserID = '공백이 포함될 수 없습니다.'
    }
    else if(RegexUtil.CheckString(/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/g ,data.UserID!)){
        state = false;
        errorData.UserID = '한글이 포함될 수 없습니다.'
    }
    else if(data.UserID!.length < 8){
        state = false;
        errorData.UserID = '아이디는 최소 8자입니다.'
    }
    else if(!RegexUtil.CheckString(KeriBaseConfig.AccountIDRegExp ,data.UserID!)){
        state = false;
        errorData.UserID = '영문/숫자 혼용만 가능합니다.'
    }
    

    // 회원명
    if (ObjectUtils.IsNullOrEmpty(data.UserName)) {
        state = false;
        errorData.UserName = '필수 값입니다.'
    }

    // 직급
    if (ObjectUtils.IsNullOrEmpty(data.UserRank)) {
        state = false;
        errorData.UserRank = '필수 값입니다.'
    }

    // 부서
    if (ObjectUtils.IsNullOrEmpty(data.UserDepartment)) {
        state = false;
        errorData.UserDepartment = '필수 값입니다.'
    }

    // 이메일
    if (ObjectUtils.IsNullOrEmpty(data.UserEmail)) {
        state = false;
        errorData.UserEmail = '필수 값입니다.'
    }
    else if (!RegexUtil.CheckString(RegexUtil.RegexType.Email, data.UserEmail)) {
        state = false;
        errorData.UserEmail = '이메일 형식이 맞지 않습니다.'
    }

    // 비밀번호
    if (ObjectUtils.IsNullOrEmpty(data.UserPassword)) {
        state = false;
        errorData.UserPassword = '필수 값입니다.'
    }
    else if (!RegexUtil.CheckString(KeriBaseConfig.PasswordRegExp, data.UserPassword)) {
        state = false;
        errorData.UserPassword = '비밀번호 보안 형식에 만족하지 않았습니다. (영문, 숫자, 기호(@$!%*?&)를 조합하여 10자리 이상)'
    }


    // 비밀번호 재입력 (확인용)
    if (data.UserPassword?.trim() !== data.ValidPassword?.trim()) {
        state = false;
        errorData.ValidPassword = '입력한 비밀번호가 동일하지 않습니다.'
    }


    // 전화번호
    if (ObjectUtils.IsNullOrEmpty(data.UserCallNumber)) {
        state = false;
        errorData.UserCallNumber = '필수 값입니다.'
    }
    else if (!RegexUtil.CheckString(RegexUtil.RegexType.PhoneNumber, data.UserCallNumber)) {
        state = false;
        errorData.UserCallNumber = '번호 형식이 맞지 않습니다.'
    }

    console.log(data.UserCellPhoneNumber);

    // 휴대전화번호
    if (ObjectUtils.IsNullOrEmpty(data.UserCellPhoneNumber)) {
        state = false;
        errorData.UserCellPhoneNumber = '필수 값입니다.'
    }
    else if (!RegexUtil.CheckString(RegexUtil.RegexType.CellPhoneNumber, data.UserCellPhoneNumber)) {
        state = false;
        errorData.UserCellPhoneNumber = '번호 형식이 맞지 않습니다.'
    }

    // 회원 유형
    if (data.UserType === undefined) {
        state = false;
        errorData.UserType = '회원 유형이 선택되지 않았습니다.'
    }

    // 필수 첨부파일
    if (data.UserFiles?.filter(x => x.Required === true && x.UploadedFile === undefined).length !== 0) {
        state = false;
        errorData.UserFiles = '필수 파일이 업로드되지 않았습니다.'
    }

    return {
        state: state,
        errorData: errorData
    }
}



export default AccountRegisterCommonValidationCheck;