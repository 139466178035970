import KeriActionButton from "@ict-base/components/control/KeriActionButton";
import { KeriFileTable, KeriFileTableItem } from "@ict-base/components/control/KeriFileTable";
import KeriScrollBox from "@ict-base/components/control/KeriScrollBox";
import { ObjectUtils } from "@ict-base/module/utils/ObjectUtils";
import KeriBaseConfig from "@keri-base/config/KeriBaseConfig";
import { IKeriStepShareProps, KeriStepItemLayout, KeriStepItemContent } from "@keri-portal/component/layout/StepLayout";
import { IUploadFileVO } from "@keri-portal/interfaces/api/keri-api/object/IUploadFileVO";
import { IUploadFile } from "@keri-portal/interfaces/control/IUploadFile";
import { ITechServiceRequest } from "@keri-portal/interfaces/process-object/tech-service/ITechServiceRequest";
import TechServiceCommonValidation, { ITechServiceRequestUploadRequireFilesError } from "@keri-portal/module/method/validation-check/tech-service/ITechServiceRequestCommonValidationCheck";
import { Add } from "@mui/icons-material";
import { useEffect, useState } from "react";


const TECH_FILE_PREFIX = 'TEC_DOCS_';
const TECH_FILE_CATEGORY = 'ServiceRequestFile';

const fakeTermsComponent = `
<!-- <h4 class="card-title bold h4 mb-2">자료의 보안</h4> -->
<p class="card-text text-left"> 
    <ol>
        <li>
            <span class="h5 bold">자료의 보안</span>
            <ul class="circle_list ml40">
                <li class="circle_item">제출된 자료는 기업회원과 시스템 운영 관리자, 기술지원 서비스 업무 관련자(운영자, 센터 내 업무 담당자), 그리고 외부 전문가 의뢰 시 해당 전문가만 조회할 수 있습니다.</li>
                <li class="circle_item">외부 전문가의 경우에도 자신에게 할당된 기술지원 업무 외의 자료는 조회할 수 없습니다.</li>
            </ul>
        </li>
        <li>
            <span class="h5 bold">자료의 활용</span>
            <ul class="circle_list ml40">
                <li class="circle_item">제출된 자료는 시스템 운영관리의 목적이나 해당 기술지원 신청에 관한 심사 및 지원 업무 외의 목적으로 사용하지 않습니다.</li>
            </ul>
        </li>
        <li>
            <span class="h5 bold">문의사항</span>
            <ul class="circle_list ml40">
                <li class="circle_item">자료 제출과 관련한 문의 사항은 아래 연락처로 문의 바랍니다.  </li>
                <li>
                <span class="h5 bold">연락처</span>
                <ul class="circle_list ml40">
                    <li class="circle_item">담당자: 김민규 연구원</li>
                    <li class="circle_item">이메일: mingyu@keri.re.kr</li>
                    <li class="circle_item">전화번호: 055-280-1568</li>
                </ul>
            </li>
            </ul>
        </li>
    </ol>
</p>
`

const KeriRegisterTechServiceRequestFileItem = (prop: IKeriStepShareProps<ITechServiceRequest>) => {


    const [uploadFiles, setUploadFiles] = useState<IUploadFile<IUploadFileVO>[]>([]);
    const [errorData, setErrorData] = useState<ITechServiceRequestUploadRequireFilesError>({});
    const [techServiceFileInfoHTML, setTechServiceFileInfoHTML] = useState<string | undefined>(undefined);





    const fakeFileList: IUploadFile<IUploadFileVO>[] = [
        {
            Index: 1,
            Label: '기술지원 신청서',
            Required: true,
            Category: TECH_FILE_CATEGORY,
            Prefix: TECH_FILE_PREFIX,
        }
    ]


    const validationCheck = (): boolean => {

        let isError: boolean = false;
        const validationMethod = TechServiceCommonValidation.RequierFiles({ data: uploadFiles });

        if (!validationMethod.state) {
            isError = !validationMethod.state;
            setErrorData(validationMethod.errorData);
        }

        return !isError;
    }

    const GetInitData = async () => {
        prop.routeProp.EventFunction.EnableBackDrop(true);


        setUploadFiles([...((prop.result.data.RequestFiles?.data) ?? fakeFileList)]);


        // TODO : 자료 제출 안내 HTML 로드 구현 필요
        setTechServiceFileInfoHTML(fakeTermsComponent)

        
        prop.routeProp.EventFunction.EnableBackDrop(false);

    }


    const addEmptyFileItem = () => {
        setUploadFiles([
            ...uploadFiles,
            {
                Index: uploadFiles.length !== 0 ? uploadFiles.sort((x, y) => y.Index - x.Index)[0].Index + 1 : 1,
                Label: '참고자료',
                Category: TECH_FILE_CATEGORY,
                Prefix: TECH_FILE_PREFIX,
            }
        ])
    }




    useEffect(() => {
        GetInitData();
    }, [prop.step.activeStep])




    return (
        <KeriStepItemLayout
            {...prop}
            validationCheck={validationCheck}
            step={prop.step}
            page={{
                moveNext: (setup, validation) => {
                    if (validation) {
                        prop.result.onChange({
                            ...prop.result.data,
                            RequestFiles: { data: uploadFiles }
                        })
                        setup(prop.step.activeStep + 1);
                    }
                }
            }}
        >
            <KeriStepItemContent
                title='자료 제출 안내'
            >
                <KeriScrollBox
                    height={300}
                >
                    <div dangerouslySetInnerHTML={{ __html: techServiceFileInfoHTML ?? '' }} />
                </KeriScrollBox>
            </KeriStepItemContent>


            <KeriStepItemContent
                title='제출 자료 등록'
                endAdornment={
                    <KeriActionButton
                        label="참고자료 추가"
                        variant="outlined"
                        color="primary"
                        sx={{
                            m: 0,
                            // p: 0
                        }}
                        icon={<Add />}
                        onClick={() => addEmptyFileItem()}
                    />
                }
            >
                <KeriFileTable
                    error={{
                        isError: errorData.UploadFiles === undefined ? false : true,
                        message: errorData.UploadFiles
                    }}
                    denyFileExtension={KeriBaseConfig.DenyFileExtension}
                    fileMode='Upload'
                >
                    {
                        uploadFiles.sort((x, y) => x.Index - y.Index).map(item => (
                            <KeriFileTableItem
                                key={ObjectUtils.RandomStrings(10)}
                                fileObject={item}
                                fileMode='Upload'
                                uploadProp={{
                                    denyFileExtension: KeriBaseConfig.DenyFileExtension
                                }}
                                displayObject={{
                                    displayTitle: (item) => item.Label,
                                    displayFileName: (item) => item.UploadedFile === undefined ? item.OriginFile?.name : item.UploadedFile.orignlFileNm,
                                    displayDeleteRow: (item) => !item.Required
                                }}
                                event={{
                                    // onFileChange: (changeItem) => {
                                    //     setUploadFiles([
                                    //         ...uploadFiles.filter(reqItem => reqItem.Index !== changeItem.Index),
                                    //         changeItem
                                    //     ])

                                    //     if (errorData.UploadFiles !== undefined) {
                                    //         setErrorData({
                                    //             UploadFiles: undefined
                                    //         })
                                    //     }
                                    // },
                                    onUploaded : (state , item) => {
                                        setUploadFiles([
                                            ...uploadFiles.filter(reqItem => reqItem.Index !== item.Index),
                                            item
                                        ])

                                        if (errorData.UploadFiles !== undefined) {
                                            setErrorData({
                                                UploadFiles: undefined
                                            })
                                        }
                                    },
                                    onDeleteUploadFile: (changeItem) => {
                                        setUploadFiles([
                                            ...uploadFiles.filter(reqItem => reqItem.Index !== changeItem.Index),
                                            {
                                                ...changeItem,
                                                UploadedFile: undefined,
                                                OriginFile: undefined
                                            }
                                        ])

                                    },
                                    onDeleteRow: (deleteItem) => {
                                        setUploadFiles([
                                            ...uploadFiles.filter(reqItem => reqItem.Index !== deleteItem.Index)
                                        ])
                                    }
                                }}

                            />
                        ))
                    }

                </KeriFileTable>
            </KeriStepItemContent>
        </KeriStepItemLayout>
    )
}

export default KeriRegisterTechServiceRequestFileItem;