import { Box, Button, CircularProgress, Divider, Typography, useMediaQuery } from "@mui/material";
import { IPageProp } from "@keri-base/interface/common/IPageProp";
import { Autoplay, Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide, } from 'swiper/react';
import 'swiper/css';
import { useNavigate } from "react-router-dom";
import { EmojiObjectsOutlined, HeadsetMicOutlined, Info, InfoOutlined, LocationOnOutlined } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import URLStructure from "@src/modules/structure/URLStructure";
import { FaqContentSectionBoardList, MainContentSection, MainContentSectionBoardList, MainContentSectionSwiperList } from "@keri-portal/component/control/main/MainContentSection";
import { FakeTechInfoData, FakeWorkType } from "@keri-portal/config/KeriFakeDatas";
import KeriActionButton from "@ict-base/components/control/KeriActionButton";
import RequestKeriAPI from "@keri-portal/module/method/api-connect/RequestKeriAPI";
import { ObjectUtils } from "@ict-base/module/utils/ObjectUtils";
import { IMainData } from "@keri-portal/interfaces/data-object/data-common/IMainData";


import "swiper/css/pagination";
import "swiper/css/navigation"

export interface ISwiperImage {
    idx: number,
    url: string,
    title?: React.ReactNode | string,
    subTitle?: string,
    endTitle?: string,
    siteName? : string,
    button?: {
        value: string,
        link: string
    }
}

const MainViewPage = (prop: IPageProp) => {

    const navigate = useNavigate();
    const desktopLayout = useMediaQuery('(min-width:1400px)');

    const swiperItemStyle: React.CSSProperties = {
        width: '100%',
        height: '100%',
    }

    const [mainData, setMainData] = useState<IMainData | undefined>(undefined);
    const [swiperImage, setSwiperImage] = useState<ISwiperImage[]>([]);

    const centerYoutubeIFrame = `<iframe width="100%" height="360px" src="https://www.youtube.com/embed/RyQkcypuxaE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;



    const getData = async () => {

        // prop.EventFunction.EnableBackDrop(true);

        const getMainData = await RequestKeriAPI.Main.GetMainData();

        if (getMainData.state) {
            setMainData(getMainData.resultData);
        }
        else {
            navigate(`${process.env.PUBLIC_URL}/error/500`)
            return;
        }


        const getSwiperImageList = await RequestKeriAPI.Main.GetSwiperImageList();

        if (getSwiperImageList.state) {
            setSwiperImage(getSwiperImageList.resultData as ISwiperImage[]);
        }
        else {
            navigate(`${process.env.PUBLIC_URL}/error/500`)
            return;
        }

        // prop.EventFunction.EnableBackDrop(false);
    }

    useEffect(() => {
        getData();

    }, [])


    return (
        <Box
            sx={{
            }}
        >
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    zIndex: 9999,
                    opacity: mainData === undefined ? undefined : 0,
                    pointerEvents: mainData === undefined ? undefined : 'none',
                    // visibility : mainData === undefined ? undefined : 'hidden', 
                    transition: '1s all cubic-bezier(0,0,0,1)',
                    background: "#edf1f6",
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '100%',
                    }}
                >
                    <Box
                        sx={{
                            textAlign: 'center'
                        }}
                    >
                        <img src={`${URLStructure.Main}assets/img/ict/images/top_logo.svg`} style={{ width: '400px', maxWidth: '90vw' }} />
                        <Box
                            sx={{
                                mt: 4
                            }}
                        >
                            <CircularProgress color="inherit" />
                        </Box>
                    </Box>

                </Box>
            </Box>
            {
                mainData !== undefined &&
                <React.Fragment>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',

                        }}
                    >


                        <Box
                            sx={{
                                maxWidth: '1600px',
                                width: '100%',
                                height: '400px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'start',
                                mb: desktopLayout ? 1 : 0,
                                mt: desktopLayout ? 2 : 0,
                                // border: '1px solid black',
                            }}
                        >
                            <Box
                                sx={{
                                    width: desktopLayout ? 'calc(100% - 460px)' : '100%',
                                    // border: '1px solid black',?
                                    height: '100%',
                                    ...(desktopLayout && {
                                        pl: '15px'
                                    })
                                }}
                            >


                                {/* Main Swiper */}
                                <Swiper
                                    className="mySwiper"
                                    style={{ height: '100%', boxSizing: 'border-box' }}
                                    speed={300}
                                    navigation
                                    loop
                                    loopedSlides={1}
                                    autoplay={{
                                        delay: 5000,
                                        disableOnInteraction: false
                                    }}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    modules={[Autoplay, Navigation, Pagination]}
                                >
                                    {
                                        swiperImage.sort((x, y) => x.idx - y.idx).map(image => (
                                            <SwiperSlide key={image.idx}>
                                                <Box
                                                    sx={{
                                                        ...swiperItemStyle,
                                                        backgroundImage: `url(${image.url})`,
                                                        backgroundSize: 'cover',
                                                        backgroundPosition: 'center',
                                                        display: 'flex',
                                                        justifyContent: 'end',
                                                        alignItems: 'start',
                                                        flexDirection: 'column',
                                                        userSelect: 'none',
                                                        boxSizing: 'border-box'
                                                    }}>
                                                    <Box
                                                        sx={{
                                                            pb : 5,
                                                            pl : 7
                                                        }}
                                                    >
                                                        <Typography
                                                            sx={{
                                                                color: 'white',
                                                                textShadow: '0 0.5rem 0.75rem rgb(0 0 0 / 26%)',
                                                                fontWeight : 'light',
                                                                fontSize : '12px',
                                                                opacity : '0.7'
                                                            }}
                                                        >
                                                            {image.title}
                                                        </Typography>
                                                        {
                                                            image.subTitle &&
                                                            <div
                                                                dangerouslySetInnerHTML={{ __html: image.subTitle as string }}
                                                                style={{
                                                                    color: 'white',
                                                                    textShadow: '0 0.5rem 0.75rem rgb(0 0 0 / 26%)',
                                                                    textAlign: 'start',
                                                                    fontWeight : 'bold',
                                                                    fontSize : '24px',
                                                                    lineHeight : '1.2',
                                                                    marginBottom : '4px',
                                                                    marginTop : '4px'
                                                                }}
                                                            />
                                                        }
                                                        <Box
                                                            sx={{
                                                                display : 'flex',
                                                                justifyContent : 'start',
                                                                alignItems : 'end'
                                                            }}
                                                        >
                                                            <Typography
                                                                sx={{
                                                                    color: 'white',
                                                                    textShadow: '0 0.5rem 0.75rem rgb(0 0 0 / 26%)',
                                                                    fontWeight : '400',
                                                                    fontSize : '16px'
                                                                }}
                                                            >
                                                                {image.endTitle}
                                                            </Typography>
                                                            <Typography
                                                                sx={{
                                                                    color: 'white',
                                                                    textShadow: '0 0.5rem 0.75rem rgb(0 0 0 / 26%)',
                                                                    fontWeight : '500',
                                                                    fontSize : '16px',
                                                                    ml : 1
                                                                }}
                                                            >
                                                                {image.siteName}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </SwiperSlide>
                                        ))
                                    }

                                </Swiper>

                            </Box>
                            {
                                desktopLayout &&
                                <Box
                                    sx={{
                                        width: '460px',
                                        height: '100%',
                                        pr: '15px',
                                        pl: '15px'
                                    }}
                                >
                                    <Box
                                        sx={{
                                            width: '100%',
                                            height: '100%',
                                            display: 'flex',
                                            justifyContent: 'start',
                                            alignItems: 'start',
                                            flexDirection: 'column'
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                width: '100%',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                mb: 1
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    borderRadius: '10px',
                                                    width: 'calc((100% - 4px) / 2)',
                                                    height: '63px',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    transition: 'all 0.3s ease-out',
                                                    alignItems: 'center',
                                                    // border: '2px solid #375882',
                                                    textAlign: 'center',
                                                    wordBreak: 'keep-all',
                                                    mr: 1,
                                                    p: 2,
                                                    cursor: 'pointer',
                                                    backgroundColor: '#7082a1',
                                                    ":hover": {
                                                        filter: 'brightness(1.3)'
                                                    }
                                                }}
                                                onClick={() => navigate(URLStructure.TechServiceRegister)}
                                            >
                                                <EmojiObjectsOutlined
                                                    sx={{
                                                        color: 'white',
                                                        fontSize: '1.3rem'
                                                    }}
                                                />
                                                <Typography
                                                    variant="h6"
                                                    sx={{
                                                        color: 'white',
                                                        fontSize: '18px',

                                                    }}
                                                >

                                                    기술지원 신청
                                                </Typography>
                                            </Box>
                                            <Box
                                                sx={{
                                                    borderRadius: '10px',
                                                    width: 'calc((100% - 4px) / 2)',
                                                    height: '63px',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    // border: '2px solid #375882',
                                                    textAlign: 'center',
                                                    wordBreak: 'keep-all',
                                                    p: 2,
                                                    cursor: 'pointer',
                                                    // background: 'rgba(38,58,93,1)',
                                                    background : '#7082a1',
                                                    transition: 'all 0.3s ease-out',
                                                    ":hover": {
                                                        filter: 'brightness(1.3)'
                                                    }
                                                }}
                                                onClick={() => navigate(URLStructure.GuildesSimulationStep)}
                                            >
                                                <InfoOutlined
                                                    sx={{
                                                        color: 'white',
                                                        mr: 0.5,
                                                        fontSize: '1.3rem'
                                                    }}
                                                />

                                                <Typography
                                                    variant="h6"
                                                    sx={{
                                                        // color : '#375882',
                                                        color: 'white',
                                                        fontSize: '18px'
                                                    }}
                                                >
                                                    기술지원 절차 안내
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Box
                                            sx={{
                                                mt: '5px',
                                                mb: '8px'
                                            }}
                                        >

                                            <Typography
                                                sx={{
                                                    fontSize: '18px',
                                                    fontWeight: 'bold'
                                                }}
                                            >
                                                기술지원별 안내
                                            </Typography>
                                        </Box>

                                        <Box
                                            sx={{
                                                width: '100%',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                mb: 1,
                                            }}
                                        >
                                            {
                                                FakeTechInfoData.slice(0, 3).map(infoItem => (
                                                    <Box
                                                        key={ObjectUtils.RandomStrings(10)}
                                                        sx={{
                                                            position: 'relative',
                                                            overflow: 'hidden',
                                                            borderRadius: '5px',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            width: 'calc((100% - 8px) / 3)',
                                                            mr: 1,
                                                            flexWrap: 'wrap',
                                                            backgroundColor: 'white',
                                                            transition: 'all 0.3s ease-out',
                                                            cursor: 'pointer',
                                                            pt: 1,
                                                            pb: 1,
                                                            border : '1px solid rgba(0,0,0,0.2)',
                                                            ":nth-last-of-type(1)": {
                                                                mr: 0
                                                            },
                                                            ":hover": {
                                                                filter: 'brightness(0.85)'
                                                            }
                                                        }}
                                                        onClick={() => navigate(infoItem.link)}
                                                    >
                                                        <img
                                                            src={infoItem.iconURL}
                                                            style={{
                                                                maxWidth: '80px',
                                                                height: '80px',
                                                                marginTop : '8px'
                                                            }}
                                                        />
                                                        <Typography
                                                            sx={{
                                                                textAlign: 'center',
                                                                mt: '7px',
                                                                mb : 0.5,
                                                                fontWeight: '500'
                                                            }}
                                                        >
                                                            {infoItem.title}
                                                        </Typography>
                                                    </Box>
                                                ))
                                            }
                                        </Box>
                                        <Box
                                            sx={{
                                                width: '100%',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            {
                                                FakeTechInfoData.slice(3, 6).map(infoItem => (
                                                    <Box
                                                    key={ObjectUtils.RandomStrings(10)}
                                                        sx={{
                                                            position: 'relative',
                                                            overflow: 'hidden',
                                                            borderRadius: '5px',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            flexDirection : 'column',
                                                            width: 'calc((100% - 8px) / 3)',
                                                            mr: 1,
                                                            flexWrap: 'wrap',
                                                            backgroundColor: 'white',
                                                            transition: 'all 0.2s ease-out',
                                                            cursor: 'pointer',
                                                            pt: 1,
                                                            pb: 1,
                                                            border : '1px solid rgba(0,0,0,0.2)',
                                                            ":nth-last-of-type(1)": {
                                                                mr: 0
                                                            },
                                                            ":hover": {
                                                                filter: 'brightness(0.85)'
                                                            }
                                                        }}
                                                        onClick={() => navigate(infoItem.link)}
                                                    >
                                                        <img
                                                            src={infoItem.iconURL}
                                                            style={{
                                                                maxWidth: '80px',
                                                                height: '80px',
                                                                marginTop : '8px'
                                                            }}
                                                        />
                                                        <Typography
                                                            sx={{
                                                                textAlign: 'center',
                                                                mt: '7px',
                                                                mb : 0.5,
                                                                fontWeight: '500'
                                                            }}
                                                        >
                                                            {infoItem.title}
                                                        </Typography>
                                                    </Box>
                                                ))
                                            }
                                        </Box>
                                    </Box>
                                </Box>
                            }

                        </Box>


                        {
                            // 기술지원 종류 (1400px 이하만 표시)
                            !desktopLayout &&
                            <MainContentSection
                                title="기술지원 안내"
                                sectionProps={{
                                    sx: {
                                        width: '100%',
                                        pr: '15px',
                                        pl: '15px'
                                    }
                                }}
                                childProps={{
                                    sx: {
                                        height: '360px',
                                    }
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        flexWrap: 'wrap',
                                        p: 1
                                    }}
                                >
                                    {
                                        FakeTechInfoData.map(infoItem => (
                                            <Box
                                                key={ObjectUtils.RandomStrings(10)}
                                                sx={{
                                                    width: 'calc((100% - 8px) / 2)',
                                                    display: 'flex',
                                                    justifyContent: 'start',
                                                    alignItems: 'center',
                                                    background: infoItem.color,
                                                    borderRadius: '15px',
                                                    mb: 1,
                                                    ":nth-of-type(2n-1)": {
                                                        mr: 1
                                                    }
                                                    // m: 1
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        width: '110px',
                                                        height: '110px',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center'
                                                    }}
                                                >
                                                    <img
                                                        src={infoItem.iconURL}
                                                        style={{
                                                            width: '100px',
                                                            padding: '10px'
                                                        }}
                                                    />
                                                </Box>
                                                <Box
                                                    sx={{
                                                        width: 'calc(100% - 110px)',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'start',
                                                        flexDirection: 'column'
                                                    }}
                                                >
                                                    <Box
                                                        sx={{
                                                            mb: 1
                                                        }}
                                                    >
                                                        <Typography
                                                            sx={{
                                                                fontSize: '1.2rem',
                                                                fontWeight: 'bold',
                                                                transition: '0.3s all cubic-bezier(0,0,0,1)',
                                                                cursor: 'pointer',
                                                                ":hover": {
                                                                    color: 'rgba(0,0,0,0.3)'
                                                                }
                                                            }}
                                                            onClick={() => navigate(infoItem.link)}
                                                        >
                                                            {infoItem.title}
                                                        </Typography>
                                                    </Box>
                                                    <Box>
                                                        <Typography
                                                            sx={{
                                                                wordBreak: 'keep-all'
                                                            }}
                                                        >
                                                            {infoItem.description}
                                                        </Typography>
                                                    </Box>


                                                    {/* <Box
                                                        sx={{
                                                            height: '4.0rem',
                                                        }}
                                                    >
                                                        <Box
                                                            className="title-typography"
                                                            sx={{
                                                                width: '100%',
                                                                maxWidth: '100%',
                                                                overflow: 'hidden',
                                                                wordBreak: 'break-word',
                                                                textOverflow: 'ellipsis',
                                                                WebkitLineClamp: '2',
                                                                display: '-webkit-box',
                                                                WebkitBoxOrient: 'vertical',
                                                                pl: 1,
                                                                pt: 1,
                                                                lineHeight: '1.7rem',
                                                                minHeight: '3.4rem',
                                                                // boxSizing : 'content-box',
                                                            }}
                                                        >
                                                            {infoItem.description}
                                                        </Box>
                                                    </Box> */}

                                                </Box>

                                            </Box>
                                        ))
                                    }
                                </Box>
                            </MainContentSection>
                        }


                        <Box
                            sx={{
                                maxWidth: '1600px',
                                width: '100%',
                                pr: '15px',
                                pl: '15px'
                            }}
                        >
                            <MainContentSection
                                title="주요업무"
                                sectionProps={{
                                    sx: {
                                        // mt : '90px'
                                    }
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: desktopLayout ? 'start' : 'center',
                                        flexWrap: 'wrap'
                                    }}
                                >
                                    {
                                        FakeWorkType.map(workTypeItem => (
                                            <Box
                                                key={ObjectUtils.RandomStrings(10)}
                                                sx={{
                                                    width: desktopLayout ? '150px' : 'calc((100vw - 180px) / 4)',
                                                    m: 1,
                                                    ...(!desktopLayout && {
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center'
                                                    })

                                                }}
                                            >
                                                <img
                                                    src={workTypeItem.iconURL}
                                                    style={{
                                                        width: desktopLayout ? '100%' : "30%",
                                                        padding: desktopLayout ? "0 20px 10px" : undefined
                                                    }}
                                                />
                                                <Typography
                                                    sx={
                                                        desktopLayout ?
                                                            {
                                                                width: '100%',
                                                                textAlign: 'center',
                                                                wordBreak: 'keep-all'
                                                            }
                                                            :
                                                            {
                                                                width: '70%',
                                                                textAlign: 'start',
                                                                wordBreak: 'keep-all',
                                                                pl: 2
                                                            }}
                                                >
                                                    {workTypeItem.title}
                                                </Typography>
                                            </Box>
                                        ))
                                    }
                                </Box>

                            </MainContentSection>
                        </Box>

                        <Box
                            sx={{
                                maxWidth: '1600px',
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                pr: '15px',
                                pl: '15px'
                            }}
                        >
                            <MainContentSection
                                title="공지사항"
                                sectionProps={{
                                    sx: {
                                        width: 'calc(60% - 8px)',
                                        mr: 1
                                    }
                                }}
                                childProps={{
                                    sx: {
                                        height: '360px',
                                    }
                                }}
                            >
                                <MainContentSectionBoardList
                                    dataObject={mainData.notice}
                                    displayOption={{
                                        displayTitle: (item) => item.BoardItemTitle,
                                        displayImageURL: (item) => item.BoardItemThumbnailURL ?? [],
                                        displayDescription: (item) => item.BoardItemContent ?? '',
                                        sortObject: (item) => (item.BoardItemWriteDate ?? new Date()).getTime(),
                                        onClick: (item) => navigate(`${URLStructure.NotificationNotices}/${item.BoardItemID}`)
                                    }}
                                    contentOption={{
                                        displayContentLineItemCount: 2,
                                        limitContentCount: 6
                                    }}
                                />
                            </MainContentSection>
                            <MainContentSection
                                title="홍보영상"
                                sectionProps={{
                                    sx: {

                                        width: '40%',
                                    }
                                }}
                                childProps={{
                                    sx: {
                                        height: '360px',
                                    }
                                }}
                            >
                                <div
                                    dangerouslySetInnerHTML={{ __html: centerYoutubeIFrame }}
                                    style={{
                                        width: '100%',
                                    }}
                                />
                            </MainContentSection>
                        </Box>




                        <Box
                            sx={{
                                maxWidth: '1600px',
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                pr: '15px',
                                pl: '15px'
                            }}
                        >
                            <MainContentSection
                                title="보도자료"
                                sectionProps={{
                                    sx: {
                                        width: 'calc(50% - 8px)',
                                        mr: 1
                                    }
                                }}
                                childProps={{
                                    sx: {
                                        height: '360px',
                                    }
                                }}
                            >
                                <MainContentSectionBoardList
                                    dataObject={mainData.news}
                                    displayOption={{
                                        displayTitle: (item) => item.BoardItemTitle,
                                        displayImageURL: (item) => item.BoardItemThumbnailURL ?? [],
                                        displayDescription: (item) => item.BoardItemContent ?? '',
                                        sortObject: (item) => (item.BoardItemWriteDate ?? new Date()).getTime(),
                                        onClick: (item) => navigate(`${URLStructure.NotificationNews}/${item.BoardItemID}`)
                                    }}
                                    contentOption={{
                                        displayContentLineItemCount: 2,
                                        limitContentCount: 6
                                    }}
                                />
                            </MainContentSection>
                            <MainContentSection
                                title="기업지원 우수사례"
                                sectionProps={{
                                    sx: {
                                        width: 'calc(50% - 8px)',
                                        mr: 1
                                    }
                                }}
                                childProps={{
                                    sx: {
                                        height: '360px',
                                    }
                                }}
                            >
                                <MainContentSectionBoardList
                                    dataObject={mainData.case}
                                    displayOption={{
                                        displayTitle: (item) => item.BoardItemTitle,
                                        displayImageURL: (item) => item.BoardItemThumbnailURL ?? [],
                                        displayDescription: (item) => item.BoardItemContent ?? '',
                                        sortObject: (item) => (item.BoardItemWriteDate ?? new Date()).getTime(),
                                        onClick: (item) => navigate(`${URLStructure.NotificationCase}/${item.BoardItemID}`)
                                    }}
                                    contentOption={{
                                        displayContentLineItemCount: 2,
                                        limitContentCount: 6
                                    }}
                                />
                            </MainContentSection>
                        </Box>


                        <Box
                            sx={{
                                maxWidth: '1600px',
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                pr: '15px',
                                pl: '15px'
                            }}
                        >
                            <MainContentSection
                                title="자주 묻는 질문과 답"
                                sectionProps={{
                                    sx: {
                                        // mt : '90px'
                                    }
                                }}
                            >
                                <FaqContentSectionBoardList
                                    dataObject={mainData.faq}
                                    displayOption={{
                                        displayTitle: (item) => item.FaqQuestion,
                                        displayImageURL: (item) => item.FaqThumbnail ?? [],
                                        displayDescription: (item) => item.FaqAnswer,
                                        sortObject: (item) => (item.FaqWriteDate ?? new Date()).getTime(),
                                        onClick: (item) => navigate(`${URLStructure.NotificationFaq}/${item.FaqID}`)
                                    }}
                                    contentOption={{
                                        displayContentLineItemCount: 3,
                                        limitContentCount: 3,
                                        imageStyle: {
                                            // padding: '10px',
                                            // height: '100px',
                                            maxWidth: '60px',
                                            maxHeight : '60px',
                                            objectFit: 'scale-down'
                                        }
                                    }}
                                />
                            </MainContentSection>
                        </Box>




                    </Box>

                </React.Fragment>
            }




        </Box>
    )
}

export default MainViewPage;