import { IAPITechServiceRequestBody, IAPIAccountCompanyRegisterRequestBody as IAPIAccountRegisterApplyCompanyRequestBody, IAPIAccountUserRegisterRequestBody } from "@keri-portal/interfaces/api/keri-api/IKeriAPIRequest";
import { ICompanyInfoVO } from "@keri-portal/interfaces/api/keri-api/object/ICompanyInfoVO";
import { IMemberInfoVO } from "@keri-portal/interfaces/api/keri-api/object/IMemberInfoVO";
import { IProductNameVO, IProductTypeVO, IProductVO } from "@keri-portal/interfaces/api/keri-api/object/IProductVO";
import { IPurposeVO } from "@keri-portal/interfaces/api/keri-api/object/IPurposeVO";
import { ITechCodeVO } from "@keri-portal/interfaces/api/keri-api/object/ITechCodeVO";
import { PurposeType } from "@keri-portal/interfaces/api/keri-api/request/IKeriAPIPurposeRequest";
import { IAccountRegister } from "@keri-portal/interfaces/process-object/account/IAccountRegister";
import { IKeriPurpose } from "@keri-portal/interfaces/data-object/data-common/IKeriPurpose";
import { ICompanyInfo } from "@keri-portal/interfaces/data-object/data-common/ICompanyInfo";
import { ITechCodeInfo } from "@keri-portal/interfaces/data-object/data-common/ITechCodeInfo";
import { IExpertUser } from "@keri-portal/interfaces/process-object/account/IUserInfo";
import { IKeriProduct, IKeriProductName, IKeriProductType } from "@keri-portal/interfaces/data-object/IProduct";
import ITestAttributeVO from "@keri-portal/interfaces/api/keri-api/object/ITestAttributeVO";
import { ITestAttribute, TestAttribute } from "@keri-portal/interfaces/data-object/data-common/ITestAttribute";
import IExpertUserVO from "@keri-portal/interfaces/api/keri-api/object/IExpertUserVO";
import { APITechServiceType } from "@keri-portal/interfaces/api/keri-api/request/IKeriAPITechServiceRequest";
import { ITechServiceObjectData, ITechServiceSimulationData, TechServiceSimulationStatus, TechServiceStatus, TechServiceType } from "@keri-portal/interfaces/data-object/tech-service/ITechService";
import { ITechServiceRequest } from "@keri-portal/interfaces/process-object/tech-service/ITechServiceRequest";
import { IUserInfo } from "@keri-portal/interfaces/data-object/data-common/IUserObject";
import { ITechServiceVO } from "@keri-portal/interfaces/api/keri-api/object/ITechServiceVO";
import { ObjectUtils } from "@ict-base/module/utils/ObjectUtils";
import { ISimulationVO } from "@keri-portal/interfaces/api/keri-api/object/ISimulationVO";
import RequestKeriAPI from "../api-connect/RequestKeriAPI";
import { ITechServiceCommentVO } from "@keri-portal/interfaces/api/keri-api/object/ITechServiceCommentVO";
import { ITechServiceComment } from "@keri-portal/interfaces/data-object/tech-service/ITechServiceComment";
import { IKeriServerFile, IKeriTechServiceSimulation } from "@ictpeople/misc-core";
import { StringToDate } from "@ictpeople/common";
import { IUploadFileVO } from "@keri-portal/interfaces/api/keri-api/object/IUploadFileVO";

/** 데이터 변환 Methods */
const InterfaceExtensionMethod = {
    /** 기존 데이터 <-> API Data 변환 */
    API: {
        /** 기존 데이터 -> API Request Body 변환 */
        Request: {
            /** 기술지원 신청 데이터 -> API 데이터로 변환 */
            CastTechServiceRegisterObjectToAPI: (req: ITechServiceRequest | undefined): IAPITechServiceRequestBody | undefined => CastTechServiceRegisterObjectToAPI(req),
            /** 회원가입 - 기업 등록 데이터 -> API 데이터로 변환 */
            CastAccountRegisterCompanyObjectToAPI: (req: IAccountRegister | undefined): IAPIAccountRegisterApplyCompanyRequestBody | undefined => CastAccountRegisterCompanyObjectToAPI(req),
            /** 회원가입 - 회원 등록 데이터 -> API 데이터로 변환 */
            CastAccountRegisterObjectToAPI: (req: IAccountRegister | undefined): IAPIAccountUserRegisterRequestBody | undefined => CastAccountRegisterObjectToAPI(req),
            /** 유저 정보 -> API 유저 VO 변경 */
            CastMemberInfoObjectToAPIMemberVO: (req: IUserInfo | undefined): IMemberInfoVO | undefined => CastMemberInfoObjectToAPIMemberVO(req)
        },
        /** API Response Data -> 기존 데이터 변환 */
        Response: {
            /** API 기업 데이터 -> Interface 변환 */
            CastAPIToCompanyData: (res: ICompanyInfoVO | undefined): ICompanyInfo | undefined => CastAPIToCompanyData(res),
            CastAPIToTechCodeData: (res: ITechCodeVO | undefined): ITechCodeInfo | undefined => CastAPIToTechCodeData(res),
            /** API 유저 데이터 -> Interface 변환 */
            CastAPIToMemberInfoData: (res: IMemberInfoVO | undefined): IUserInfo | undefined => CastAPIToMemberInfoData(res),
            /** API Product Name Data -> Interface */
            CastAPIToProductNameData: (res: IProductNameVO | undefined): IKeriProductName | undefined => CastAPIToProductNameData(res),
            CastAPIToProductTypeData: (res: IProductTypeVO | undefined): IKeriProductType | undefined => CastAPIToProductTypeData(res),
            CastAPIToProductData: (res: IProductVO | undefined): IKeriProduct | undefined => CastAPIToProductData(res),
            CastAPIToAnalysisPurposeData: (res: IPurposeVO | undefined): IKeriPurpose | undefined => CastAPIToAnalysisPurposeData(res),
            CastAPIToTestAttributeData: (res: ITestAttributeVO | undefined, type: TestAttribute): ITestAttribute | undefined => CastAPIToTestAttributeData(res, type),
            CastAPIToExpertUserData: (res: IExpertUserVO | undefined): IExpertUser | undefined => CastAPIToExpertUserData(res),
            CastAPIToTechServiceListData: (res: ITechServiceVO | undefined , token : string | undefined , nonImangeDownload? : boolean): Promise<ITechServiceObjectData | undefined> => CastAPIToTechServiceListData(res,token,nonImangeDownload),
            CastAPIToTechServiceSimulationData: (res: ISimulationVO | undefined): IKeriTechServiceSimulation | undefined => CastAPIToTechServiceSimulationData(res),
            CastAPIToTechServiceCommentData: ( res : ITechServiceCommentVO | undefined) : ITechServiceComment | undefined => CastAPIToTechServiceCommentData(res),
        },
    },
    Utils: {
        Request: {
            CastTechServiceTypeToAPI: (res: TechServiceType | undefined): APITechServiceType | undefined => CastTechServiceTypeToAPI(res)
        },
        Response: {
            CastAPIToTechServiceType: (res: APITechServiceType | undefined): TechServiceType | undefined => CastAPIToTechServiceType(res)
        }
    }

}


/** 기술지원 신청 데이터 -> API 데이터로 변환 */
const CastTechServiceRegisterObjectToAPI = (req: ITechServiceRequest | undefined): IAPITechServiceRequestBody | undefined => {
    return {}
}


const CastAccountRegisterObjectToAPI = (req: IAccountRegister | undefined): IAPIAccountUserRegisterRequestBody | undefined => {

    console.log('user data', req);

    return {
        mberId: req?.AccountUserInfoData?.UserID?.trim(),
        mberNm: req?.AccountUserInfoData?.UserName?.trim(),
        mberRank: req?.AccountUserInfoData?.UserRank?.trim(),
        mberDept: req?.AccountUserInfoData?.UserDepartment?.trim(),
        mberEmailAdres: req?.AccountUserInfoData?.UserEmail?.trim(),
        moblphonNo: req?.AccountUserInfoData?.UserCellPhoneNumber?.trim(),
        middleTelno: req?.AccountUserInfoData?.UserCallNumber?.trim(),
        password: req?.AccountUserInfoData?.UserPassword?.trim(),
        isProfesnal: req?.AccountUserInfoData?.UserType === 'NORMAL' ? 'N' : 'Y',
        // atchFileId : req?.AccountUserInfoData?.UserFiles?.map(x=>x.UploadedFile?.atchFileId).join(','),.
        streFileNms: req?.AccountUserInfoData?.UserFiles?.filter(x => x.UploadedFile !== undefined && x.UploadedFile?.streFileNm !== undefined).map(x => x.UploadedFile!.streFileNm!.trim()),
        comId: req?.ExistCompanyInfoData?.CompanyID?.trim()
    }
}


const CastAPIToCompanyData = (res: ICompanyInfoVO | undefined): ICompanyInfo | undefined => {
    /** @todo CompanyFiles 매칭 필요 */
    return res === undefined ? undefined : {
        CompanyID: res.comId ?? undefined,
        BusinessName: res?.comNm ?? undefined,
        AbbreviationBusinessName: res?.comShortNm ?? undefined,
        CompanyRegistrationNumber: res?.comBusNo ?? undefined,
        CorporateRegistrationNumber: res?.comCorpNo ?? undefined,
        CEOName: res?.comCeoNm ?? undefined,
        CEOEmail: res?.comRepEmail ?? undefined,
        TechCode: { TechCode: res?.comIndsTechClassCd ?? undefined },
        CorporateType: res?.comTy ?? undefined,
        BusinessStatus: res?.comBusStats ?? undefined,
        BusinessItem: res?.comBusItemTy ?? undefined,
        CompanyCallNumber: res?.comTelNo ?? undefined,
        CompanyFaxNumber: res?.comFaxNo ?? undefined,
        CompanyAddress: {
            zipNo: res?.comZip ?? undefined,
            roadAddr: res?.comAdres ?? undefined
        },
        CompanyDetailAddress: res?.comDetailAdres ?? undefined,
        CompanyCapital: res?.comCaptal ?? undefined,
        CompanyWorkerCount: res?.comEmplyCnt ?? undefined,
        CompanyFiles: undefined,
        CompanyFileAttchID: res.atchFileId ?? undefined
    }
}

/** 회원가입 - 기업 등록 데이터 -> API 데이터로 변환 */
const CastAccountRegisterCompanyObjectToAPI = (req: IAccountRegister | undefined): IAPIAccountRegisterApplyCompanyRequestBody | undefined => {
    const item = req?.NewCompanyInfoData;
    return {
        // atchFileId : item?.CompanyFiles?.map(x=>x.UploadedFile?.atchFileId).join(','),
        comAdres: item?.CompanyAddress?.jibunAddr?.trim(),
        comDetailAdres: item?.CompanyDetailAddress?.trim(),
        comZip: item?.CompanyAddress?.zipNo?.trim(),
        comBusItemTy: item?.BusinessItem?.trim(),
        comBusNo: item?.CompanyRegistrationNumber?.replace(/[^0-9]/g, '').replace(/^(\d{3})(\d{2})(\d{5})$/, `$1-$2-$3`)?.trim(),
        comBusStats: item?.BusinessStatus?.trim(),
        comCaptal: item?.CompanyCapital?.toString()?.trim(),
        comCeoNm: item?.CEOName?.trim(),
        comCorpNo: item?.CorporateRegistrationNumber?.replace(/[^0-9]/g, '').replace(/^(\d{6})(\d{7})$/, `$1-$2`)?.trim(),
        comEmplyCnt: item?.CompanyWorkerCount?.toString()?.trim(),
        comFaxNo: item?.CompanyFaxNumber?.trim(),
        comIndsTechClassCd: item?.TechCode?.TechCode?.trim(),
        comNm: item?.BusinessName?.trim(),
        comShortNm: item?.AbbreviationBusinessName?.trim(),
        comRepEmail: item?.CEOEmail?.trim(),
        comTelNo: item?.CompanyCallNumber?.trim(),
        comTy: item?.CorporateType?.trim(),
        streFileNms: item?.CompanyFiles?.filter(x => x.UploadedFile !== undefined && x.UploadedFile?.streFileNm !== undefined).map(x => x.UploadedFile!.streFileNm!),
        comAreaState : item?.CompanyAddress?.siNm?.trim(),
        comAreaCity : (item?.CompanyAddress?.sggNm??'').split(' ')[0] === '' ? undefined : (item?.CompanyAddress?.sggNm??'').split(' ')[0].trim()
    }
}


const CastAPIToTechCodeData = (res: ITechCodeVO | undefined): ITechCodeInfo | undefined => {
    return res === undefined ? undefined : {
        TechCode: res.indsTechClsCd,
        TechName: res.indsTechClsNm,
        TechCategoryCode: res.mainCtgryCd,
        TechCategoryName: res.mainCtgryNm,
        TechSubCategoryCode: res.subCtgryCd,
        TechSubCategoryName: res.subCtgryNm
    }
}



const CastAPIToMemberInfoData = (res: IMemberInfoVO | undefined): IUserInfo | undefined => {
    return {
        // UserType : (res?.userTy ?? undefined) === '',
        UserCallNumber: res?.middleTelno ?? undefined,
        UserCellPhoneNumber: res?.moblphonNo ?? undefined,
        // UserCompany : 
        UserDepartment: res?.mberDept ?? undefined,
        UserEmail: res?.mberEmailAdres ?? undefined,
        UserID: res?.mberId ?? undefined,
        UserUniqueID: res?.uniqId ?? undefined,
        UserName: res?.mberNm ?? undefined,
        UserRank: res?.mberRank ?? undefined,
    }
}


const CastMemberInfoObjectToAPIMemberVO = (req: IUserInfo | undefined): IMemberInfoVO | undefined => {

    return req === undefined ? undefined : {
        middleTelno: req.UserCallNumber ?? undefined,
        moblphonNo: req.UserCellPhoneNumber ?? undefined,
        mberDept: req.UserDepartment ?? undefined,
        mberEmailAdres: req.UserEmail ?? undefined,
        mberId: req.UserID ?? undefined,
        uniqId: req.UserUniqueID ?? undefined,
        mberNm: req.UserName ?? undefined,
        mberRank: req.UserRank ?? undefined
    }
}

const CastAPIToProductNameData = (res: IProductNameVO | undefined): IKeriProductName | undefined => {
    return res === undefined ? undefined : {
        productName: res!.prodNm ?? '',
        productNameSequence: res!.seq ?? undefined
    }
}

const CastAPIToProductTypeData = (res: IProductTypeVO | undefined): IKeriProductType | undefined => {
    return res === undefined ? undefined : {
        productTypeName: res.prodTypeNm ?? '',
        productTypeSequence: res.seq ?? undefined
    }
}


const CastAPIToProductData = (res: IProductVO | undefined): IKeriProduct | undefined => {
    return res === undefined ? undefined : {
        productApplyDate: new Date(res.prodRegistPnttm ?? new Date()),
        productImage: { Index: 1, Category: '1', Label: '1', UploadedFile: { atchFileId: res.atchFileId ?? '', fileSn: '1' }, Prefix: 'PROD_' },
        productName: { productName: res.prodNm ?? '' },
        productNumber: res.prodNumber ?? '',
        productType: { productTypeName: res.prodTypeNm ?? '' },
        productID: res.prodId ?? ''
    }
}


const CastAPIToAnalysisPurposeData = (res: IPurposeVO | undefined): IKeriPurpose | undefined => {
    return res === undefined ? undefined : {
        purposeName: res.codeNm ?? '',
        purposeCode : res.code ?? '',
    }
}



const CastAPIToTestAttributeData = (res: ITestAttributeVO | undefined, type: TestAttribute): ITestAttribute | undefined => {
    const name = type === 'TestType' ? res?.testType : type === 'TestMethod' ? res?.testMethod : res?.testItem
    return res === undefined ? undefined : {
        TestType: type,
        TestName: name!
    }
}




const CastAPIToExpertUserData = (res: IExpertUserVO | undefined): IExpertUser | undefined => {
    return res === undefined ? undefined : {
        UniqueID: res.userUniqId ?? '',
        UserCompanyName: res.userComNm ?? '',
        UserName: res.userNm ?? ''
    }
}




const CastTechServiceTypeToAPI = (res: TechServiceType | undefined): APITechServiceType | undefined => {

    let returnData: APITechServiceType | undefined = undefined;


    switch (res) {
        case 'A': returnData = 'AR'; break;
        case 'C': returnData = 'CS'; break;
        case 'P': returnData = 'PS'; break;
        case 'R': returnData = 'VR'; break;
        case 'S': returnData = 'IS'; break;
        case 'T': returnData = 'PT'; break;
        case undefined: returnData = undefined; break;
    }


    return returnData;
}

const CastAPIToTechServiceType = (res: APITechServiceType | undefined): TechServiceType | undefined => {
    let returnData: TechServiceType | undefined = undefined;


    switch (res) {
        case 'AR': returnData = 'A'; break;
        case 'CS': returnData = 'C'; break;
        case 'PS': returnData = 'P'; break;
        case 'VR': returnData = 'R'; break;
        case 'IS': returnData = 'S'; break;
        case 'PT': returnData = 'T'; break;
        case undefined: returnData = undefined; break;
    }


    return returnData;
}





const CastAPIToTechServiceListData = async (res: ITechServiceVO | undefined , token : string | undefined , nonImangeDownload? : boolean): Promise<ITechServiceObjectData | undefined> => {

    let imageURL : string | undefined = undefined;

    if((res?.imageAtchId??undefined) !== undefined && !(nonImangeDownload??false)){

        const getImage = await RequestKeriAPI.File.DownloadFileUsingAtchFileID(token,  res?.imageAtchId ?? undefined , 1);

        if(getImage.state){
            imageURL = await ObjectUtils.CastBlobToURLStringAsync(getImage.data);
            // console.log('finish url',url);
        }
        
    }

    return res === undefined ? undefined : {
        RequestUniqueID: res.arId ?? undefined,
        RequestStatus: res.arSttus as TechServiceStatus,
        RequestType: InterfaceExtensionMethod.Utils.Response.CastAPIToTechServiceType((res.arType ?? undefined) === undefined ? undefined : res.arType as APITechServiceType | undefined) ?? 'A' as TechServiceType,
        RequestStatusName: res.arSttusNm ?? undefined,
        RequesterAddDate: (res.registPnttm ?? undefined) === undefined ? undefined : (isNaN(Date.parse(res.registPnttm!))) ? undefined : new Date(res.registPnttm!),
        RequesterData: {
            RequesterName: res.reqNm ?? undefined,
            RequesterCallNumber: res.reqTelNo ?? undefined,
            RequesterDepartment: res.reqDept ?? undefined,
            RequesterEmail: res.reqEmail ?? undefined
        },
        RequestInputData: {
            RequestDesiredDate: (res.arNeededDuePnttm ?? undefined) !== undefined ? (isNaN(Date.parse(res.arNeededDuePnttm!)) ? undefined : new Date(res.arNeededDuePnttm!)) : undefined,
            // RequestExpertUser: (res.profesnalId ?? undefined) === undefined ? undefined : {
            //     UniqueID: res.profesnalId ?? '',
            //     UserCompanyName: res.profesnalComNm ?? '',
            //     UserName: res.profesnalNm ?? ''
            // },
            RequestInfo: res.arReqContents ?? undefined,
            RequestProduct: (res.arProdId ?? undefined) === undefined ? undefined : {
                productImage: (res.imageAtchId ?? undefined) === undefined ? undefined : {
                    Category: '',
                    Index: 1,
                    Label: '',
                    Prefix: '',
                    UploadedFile: {
                        atchFileId: res.imageAtchId ?? undefined
                    }
                },
                productName: (res.arProdNm ?? undefined) === undefined ? undefined : {
                    productName: res.arProdNm ?? undefined,
                },
                productNumber: res.arProdNumber ?? undefined,
                productType: (res.arProdTypeNm ?? undefined) === undefined ? undefined : {
                    productTypeName : res.arProdTypeNm ?? undefined,
                    productTypeSequence  :1
                },
                productID: res.arProdId ?? undefined,
                productImageURL : imageURL
            },
            RequestPurpose : [{
               purposeName : res.arPurposeNm ?? '',
               purposeCode : res.arPurpose ?? ''
            }],
            // RequestPurpose: (res.arPurposeNm ?? '').split(',').filter(x => !ObjectUtils.IsNullOrEmpty(x)).map(x => ({
            //     purposeName: x,
            //     purposeCode : 
            // }) as IKeriPurpose),
            RequestTestItem: (res.testItem ?? '').split(',').filter(x => !ObjectUtils.IsNullOrEmpty(x)).map(x => ({
                TestName: x,
                TestType: 'TestItem'
            }) as ITestAttribute),
            RequestTestMethod: (res.testMethod ?? undefined) === undefined ? undefined : {
                TestName: res.testMethod ?? undefined,
                TestType: 'TestMethod'
            },
            RequestTestType: (res.testType ?? undefined) === undefined ? undefined : {
                TestType: 'TestType',
                TestName: res.testType ?? undefined
            },
            RequestTitle: res.arTitle ?? undefined

        },
        RequestNewReplyCount : (res.commentCnt??undefined) === undefined ? 0 : parseInt(res.commentCnt! ,10) ,
        RequestRating :  (res.arSatisfaction??undefined) !== undefined ? parseInt(res.arSatisfaction! ,10) : undefined,
    }
}



const CastAPIToTechServiceSimulationData = (res: ISimulationVO | undefined): IKeriTechServiceSimulation | undefined => {
    return res === undefined ? undefined : {
        order : res.properties.smOrd ?? undefined,
        atchFileId : res.properties.atchFileId??undefined,
        createDate : StringToDate(res.properties.smReqPnttm),
        inputFiles : IgnoreUndefinedItem((res.attachFiles??[]).filter(x=>x.fileCategory === 'inputSimulation').map(org =>CastAPIToKeriServerFile(org))),
        outputFiles : IgnoreUndefinedItem((res.attachFiles??[]).filter(x=>x.fileCategory === 'outputSimulation').map(org =>CastAPIToKeriServerFile(org))),
        statusCode : res.properties.smResult === 'N' ? 'Failed' : res.properties.smSttus??undefined,
        statusName :  res.properties.smResult === 'N' ? '실패' : res.properties.smSttusNm??undefined,
        supporterCallNumber : res.properties.suptTelNo??undefined,
        supporterDepartment : res.properties.suptDept??undefined,
        supporterEmail : res.properties.suptEmail??undefined,
        supporterName : res.properties.suptUsrNm??undefined,
        supporterUserId : res.properties.suptUsrId??undefined,
        techServiceId : res.properties.arId??undefined
    }
}

const CastAPIToKeriServerFile = (res : IUploadFileVO | undefined) : IKeriServerFile | undefined => {



        return res === undefined ? undefined : res.atchFileId === undefined ? undefined : {
            atchFileID : res.atchFileId,
            fileCategory : res.fileCategory,
            fileCreateDate : res.fileCreatDt === undefined ? undefined : isNaN(Date.parse(res.fileCreatDt ?? '')) ? undefined : new Date(Date.parse(res.fileCreatDt ?? '')),
            fileExtension : res.fileExtsn,
            fileOriginalName : res.orignlFileNm,
            fileSequence : res.fileSn === undefined ? undefined : isNaN(parseInt(res.fileSn)) ? undefined : parseInt(res.fileSn),
            fileSize : res.fileMg === undefined ? undefined : isNaN(parseInt(res.fileMg)) ? undefined : parseInt(res.fileMg),
            fileTitle : res.fileCn ?? ''
        }
}


const CastAPIToTechServiceCommentData = ( res : ITechServiceCommentVO | undefined) : ITechServiceComment | undefined => {
    return res === undefined ? undefined : {
        CommentDate : isNaN(Date.parse(res.registPnttm??'')) ? undefined : new Date(Date.parse(res.registPnttm??'')),
        CommentContent : (res.cmtContents??undefined) !== undefined ? res.cmtContents!.replaceAll('/n','<br />') :undefined,
        CommentID : res.cmtSndId??undefined,
        CommentSequence : res.cmtSn??undefined,
        CommentUserID : res.cmtSndId??undefined,
        CommentUserName : res.cmtSndNm??undefined
    } 
}


const IgnoreUndefinedItem = <T extends any>(data : (T | undefined)[]) : T[] => {
    return data.filter(x=>x !== undefined) as T[]
}


export default InterfaceExtensionMethod;

