import { IKeriAPIState } from "@ict-base/module/service/ICTRequestAPI";
import CallAPI from "@ict-base/module/utils/CallAPI";
import KeriAPIStructure from "@keri-portal/config/KeriAPIStructure";
import { IKeriAPIGetEducationScheduleInfoRequestBody, IKeriAPIGetEducationScheduleListRequestBody, IKeriAPIInsertEducationRequestRequestBody } from "@keri-portal/interfaces/api/keri-api/request/IKeriAPIEducationRequest";
import { IAPIGetTechServiceCompleteDocsRequestBody } from "@keri-portal/interfaces/api/keri-api/request/IKeriAPITechServiceRequest";
import { IKeriAPIGetEducationCategoryListResult, IKeriAPIGetEducationScheduleInfoResult, IKeriAPiGetEducationScheduleListResult, IKeriAPIGetEducationClassStateListResult } from "@keri-portal/interfaces/api/keri-api/response/IKeriAPIEducationResponse";
import { IEducationCategory, IEducationItem, IEducationState } from "@keri-portal/interfaces/data-object/data-common/IEducation";
import KeriAPIException from "../api-utils/KeriAPIException";
import KeriAPIExceptionFilter from "../api-utils/KeriAPIExceptionFilter";

const KeriEducationAPI = {
    GetEducationCategory: async (): Promise<IGetEducationCategory> => GetEducationCategory(),
    GetEducationClassState: async (): Promise<IGetEducationState> => GetEducationClassState(),
    GetEducationScheduleList: async (prop: IGetEducationScheduleListProp): Promise<IGetEducationScheduleList> => GetEducationScheduleList(prop),
    GetEducationScheduleInfo: async (prop: IGetEducationScheduleInfoProp): Promise<IGetEducationScheduleInfo> => GetEducationScheduleInfo(prop),
    InsertEducationRequest: async (prop: IInsertEducationRequestProp): Promise<IInsertEducationRequest> => InsertEducationRequest(prop),
}





//#region Get Education Category List


interface IGetEducationCategory extends IKeriAPIState {
    resultData?: IEducationCategory[]
}

const GetEducationCategory = async (): Promise<IGetEducationCategory> => {

    let apiResult: IGetEducationCategory = {
        state: true,
        message: undefined
    }

    try {
        await CallAPI<IKeriAPIGetEducationCategoryListResult>({
            url: KeriAPIStructure.Common.GetEducationCategory,
            method: "POST",
        })
            .then(res => {
                try {
                    console.log(res);
                    apiResult.state = true;
                    apiResult.statusCode = 200;
                    apiResult.resultData = res.data.eduCategoryList.map(item => ({
                        CodeID: item.codeId!,
                        CategoryID: item.code!,
                        CategoryName: item.codeNm!
                    }))
                }
                catch (e) {
                    throw new KeriAPIException("데이터를 읽어올 수 없습니다.");
                }
            })
            .catch((e) => {
                throw e;
            })

    }
    catch (e) {
        const exp = KeriAPIExceptionFilter(e);
        apiResult.message = exp.message;
        apiResult.state = false;
        apiResult.statusCode = exp.state;
    }




    return apiResult;

}


//#endregion

//#region Get Education Status List

interface IGetEducationState extends IKeriAPIState {
    resultData?: IEducationState[]
}

const GetEducationClassState = async (): Promise<IGetEducationState> => {
    let apiResult: IGetEducationState = {
        state: true,
        message: undefined,
    }

    try {
        await CallAPI<IKeriAPIGetEducationClassStateListResult>({
            url: KeriAPIStructure.Common.GetEducationClassState,
            method: "POST",
        })
            .then(res => {
                try {
                    console.log(res);
                    apiResult.state = true;
                    apiResult.statusCode = 200;
                    apiResult.resultData = res.data.eduStateList.map(item => ({
                        CodeID: item.codeId!,
                        StateID: item.code!,
                        StateName: item.codeNm!
                    }))
                }
                catch (e) {
                    throw new KeriAPIException("데이터를 읽어올 수 없습니다.");
                }
            })
            .catch((e) => {
                throw e;
            })

    }
    catch (e) {
        const exp = KeriAPIExceptionFilter(e);
        apiResult.message = exp.message;
        apiResult.state = false;
        apiResult.statusCode = exp.state;
    }




    return apiResult;
}

//#endregion

//#region Get Education Schedule List

interface IGetEducationScheduleListProp {
    searchText?: string,
    page?: number,
    pagePerCount?: number,
    selectCategory?: IEducationCategory[],
    selectClassState?: IEducationState[]
}

interface IGetEducationScheduleList extends IKeriAPIState {
    resultData?: IEducationItem[],
    categoryData?: IEducationCategory[],
    classStateData?: IEducationState[],
    receiptStateData?: IEducationState[],
}

const GetEducationScheduleList = async (prop: IGetEducationScheduleListProp): Promise<IGetEducationScheduleList> => {
    let apiResult: IGetEducationScheduleList = {
        state: true,
        message: undefined,
    }

    try {
        let reqBody: IKeriAPIGetEducationScheduleListRequestBody = {};

        try {
            reqBody = {
                pageIndex: prop.page?.toString(),
                pageUnit: prop.pagePerCount?.toString(),
                eduCategoryId: prop.selectCategory?.map(x => x.CategoryID),
                eduState: prop.selectClassState?.map(x => x.StateID),
                searchKeyword: prop.searchText
            }
        }
        catch (e) {
            throw new KeriAPIException("요청을 보낼 수 없습니다.");
        }


        await CallAPI<IKeriAPiGetEducationScheduleListResult>({
            url: KeriAPIStructure.Education.GetEducationList,
            method: "POST",
            requestBody: reqBody,
        })
            .then(res => {
                try {
                    if (res.data.resultCode !== 'S') {
                        apiResult.state = false;
                        apiResult.message = res.data.resultMessage;
                    } else if (res.data.resultCode === 'S') {

                        apiResult.state = true;
                        apiResult.message = res.data.resultMessage;

                        apiResult.categoryData = res.data.eduCategoryList.map(catItem => ({
                            CodeID: catItem.codeId!,
                            CategoryID: catItem.code!,
                            CategoryName: catItem.codeNm!
                        }))

                        apiResult.classStateData = res.data.eduStateList.map(sttItem => ({
                            CodeID: sttItem.codeId!,
                            StateID: sttItem.code!,
                            StateName: sttItem.codeNm!
                        }))

                        apiResult.receiptStateData = res.data.eduReceiptStateList.map(sttItem => ({
                            CodeID: sttItem.codeId!,
                            StateID: sttItem.code!,
                            StateName: sttItem.codeNm!
                        }))

                        console.log('RECEIPT LIST',res.data.eduReceiptStateList);

                        apiResult.resultData = res.data.curriculumList.map(item => ({
                            title: item.title!,
                            lecturer: item.instructors!,
                            category: res.data.eduCategoryList.filter(x => x.code === item.eduCategoryId).map(xs => ({
                                CodeID: xs.codeId!,
                                CategoryID: xs.code!,
                                CategoryName: xs.codeNm!
                            }))[0],
                            requestOpenDate: item.applyStartPnttm!.toDate(),
                            requestCloseDate: item.applyEndPnttm!.toDate(),
                            classOpenDate: item.eduStartPnttm!.toDate(),
                            classCloseDate: item.eduEndPnttm!.toDate(),
                            educationId: item.id!,
                            content: item.curriculumDesc!,
                            classState: res.data.eduStateList.filter(x => x.code === item.eduState).map(xs => ({
                                CodeID: xs.codeId!,
                                StateID: xs.code!,
                                StateName: xs.codeNm!
                            }))[0],
                            receiptState: res.data.eduReceiptStateList.filter(x => x.code === item.eduReceiptState).map(xs => ({
                                CodeID: xs.codeId!,
                                StateID: xs.code!,
                                StateName: xs.codeNm!
                            }))[0],
                        }))

                    }
                }
                catch (e) {
                    if (process.env.NODE_ENV === 'development') {
                        console.error('API DATA SCRIPT ERROR', e);
                    }
                    throw new KeriAPIException("데이터를 읽어올 수 없습니다.");
                }
            })
            .catch((e) => {
                throw e;
            })

    }
    catch (e) {
        if (process.env.NODE_ENV === 'development') {
            console.error('API ERROR', e);
        }

        const exp = KeriAPIExceptionFilter(e);
        apiResult.message = exp.message;
        apiResult.state = false;
        apiResult.statusCode = exp.state;
    }




    return apiResult;
}

//#endregion

//#region Get Education Schedule Info

interface IGetEducationScheduleInfoProp {
    scheduleID: string
}

interface IGetEducationScheduleInfo extends IKeriAPIState {
    resultData?: IEducationItem
}

const GetEducationScheduleInfo = async (prop: IGetEducationScheduleInfoProp): Promise<IGetEducationScheduleInfo> => {
    let apiResult: IGetEducationScheduleInfo = {
        state: true,
        message: undefined,
    }

    try {
        let reqBody: IKeriAPIGetEducationScheduleInfoRequestBody = {};

        try {
            reqBody = {
                id: prop.scheduleID
            }
        }
        catch (e) {
            throw new KeriAPIException("요청을 보낼 수 없습니다.");
        }


        await CallAPI<IKeriAPIGetEducationScheduleInfoResult>({
            url: KeriAPIStructure.Education.GetEducationInfo,
            method: "POST",
            requestBody: reqBody,
        })
            .then(res => {
                try {
                    if (res.data.resultCode !== 'S') {
                        apiResult.state = false;
                        apiResult.message = res.data.resultMessage;
                    } else if (res.data.resultCode === 'S') {

                        apiResult.state = true;
                        apiResult.message = res.data.resultMessage;

                        apiResult.resultData = {
                            title: res.data.curriculumInfo.properties.title!,
                            lecturer: res.data.curriculumInfo.properties.instructors!,
                            category: {
                                CodeID: '',
                                CategoryID: res.data.curriculumInfo.properties.eduCategoryId!,
                                CategoryName: res.data.curriculumInfo.properties.eduCategoryNm!
                            },
                            content: res.data.curriculumInfo.properties.curriculumDesc!,
                            requestOpenDate: res.data.curriculumInfo.properties.applyStartPnttm!.toDate(),
                            requestCloseDate: res.data.curriculumInfo.properties.applyEndPnttm!.toDate(),
                            classOpenDate: res.data.curriculumInfo.properties.eduStartPnttm!.toDate(),
                            classCloseDate: res.data.curriculumInfo.properties.eduEndPnttm!.toDate(),
                            educationId: res.data.curriculumInfo.properties.id!,
                            classState: {
                                CodeID: '',
                                StateID: res.data.curriculumInfo.properties.eduState!,
                                StateName: res.data.curriculumInfo.properties.eduStateNm!,
                            },
                            receiptState: {
                                CodeID: '',
                                StateID: res.data.curriculumInfo.properties.eduReceiptState!,
                                StateName: res.data.curriculumInfo.properties.eduReceiptStateNm!,
                            }
                        }
                    }
                }
                catch (e) {
                    if (process.env.NODE_ENV === 'development') {
                        console.error('API DATA SCRIPT ERROR', e);
                    }
                    throw new KeriAPIException("데이터를 읽어올 수 없습니다.");
                }
            })
            .catch((e) => {
                throw e;
            })

    }
    catch (e) {
        if (process.env.NODE_ENV === 'development') {
            console.error('API ERROR', e);
        }

        const exp = KeriAPIExceptionFilter(e);
        apiResult.message = exp.message;
        apiResult.state = false;
        apiResult.statusCode = exp.state;
    }




    return apiResult;
}

//#endregion

//#region Insert Education Request

interface IInsertEducationRequestProp{
    scheduleID? : string,
    requestTitle? : string,
    requestContent? :  string,
    requesterName? : string,
    // requesterPhoneNumber : string,
    requesterEmail?: string,
    addFiles? : string[],
}

interface IInsertEducationRequest extends IKeriAPIState{

}

const InsertEducationRequest = async (prop: IInsertEducationRequestProp): Promise<IInsertEducationRequest> => {
    let apiResult: IGetEducationScheduleInfo = {
        state: true,
        message: undefined,
    }

    try {
        let reqBody: IKeriAPIInsertEducationRequestRequestBody = {};

        try {
            reqBody = {
                curriculumId : prop.scheduleID,
                appDesc : prop.requestContent,
                appTitle : prop.requestTitle,
                appusrEmail : prop.requesterEmail,
                streFileNms : prop.addFiles
            }
        }
        catch (e) {
            throw new KeriAPIException("요청을 보낼 수 없습니다.");
        }


        await CallAPI<IKeriAPIGetEducationScheduleInfoResult>({
            url: KeriAPIStructure.Education.RequestEducation,
            method: "POST",
            requestBody: reqBody,
        })
            .then(res => {
                try {
                    if (res.data.resultCode !== 'S') {
                        apiResult.state = false;
                        apiResult.message = res.data.resultMessage;
                    } else if (res.data.resultCode === 'S') {
                        apiResult.state = true;
                        apiResult.message = res.data.resultMessage;
                    }
                }
                catch (e) {
                    if (process.env.NODE_ENV === 'development') {
                        console.error('API DATA SCRIPT ERROR', e);
                    }
                    throw new KeriAPIException("데이터를 읽어올 수 없습니다.");
                }
            })
            .catch((e) => {
                throw e;
            })

    }
    catch (e) {
        if (process.env.NODE_ENV === 'development') {
            console.error('API ERROR', e);
        }

        const exp = KeriAPIExceptionFilter(e);
        apiResult.message = exp.message;
        apiResult.state = false;
        apiResult.statusCode = exp.state;
    }




    return apiResult;
}

//#endregion

export default KeriEducationAPI;