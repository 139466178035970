const KeriAPIStructure = {
    /** 
     * ETC API
     * @url https://keri.ictpeople.co.kr/misc/api/swagger-ui/?urls.primaryName=AB.ETC_API 
     */
    ETC : {
        RefreshToken : 'kri/etc/TokenValidCheck.do',
        RequestCertCode : 'uat/uia/sendCertNumber.do',
        ValidCertCode : 'uat/uia/validCertNumber.do'
    },
    /**
     * Common API
     * @url https://keri.ictpeople.co.kr/misc/api/swagger-ui/?urls.primaryName=AA.Common_API
     */
    Common : {
        FileDownload: 'kri/cmm/fileDownload.do',
        FileUpload : 'kri/cmm/FileUpload.do',


        /** 시험항목 조회 */
        GetTestAttribute : 'kri/cmm/TestItems.do',


        SimulationFileUpload : 'kri/cmm/InputFileUpload.do',

        GetTechCodeMainCategory : 'kri/cmm/IdstrTechMainCategoryLIst.do',
        GetTechCodeSubCategory : 'kri/cmm/IdstrTechSubCategoryLIst.do',
        GetTechCode : 'kri/cmm/IdstrTechCodeList.do',

        GetEducationCategory : 'kri/cmm/eduCategoryCmmList.do',
        GetEducationClassState: 'kri/cmm/eduStateCmmList.do',
        GetEducationReceiptState : 'kri/cmm/eduReceiptStateCmmList.do'
    },
    /**
     * Product API
     * @url https://keri.ictpeople.co.kr/misc/api/swagger-ui/?urls.primaryName=A07.Products_API
     */
    Product : {
        /** 품목 생성 */
        AddProduct : 'kri/pdt/EgovProductInsert.do',
        /** 품목 조회 */
        GetProduct : 'kri/pdt/EgovProductList.do',
        /** 품목 삭제 */
        DeleteProduct : 'kri/pdt/EgovProductDelete.do',


        /** 품목명 생성 */
        AddProductName : 'kri/pdt/EgovProductNmInsert.do',
        /** 품목명 조회 */
        GetProductName : 'kri/pdt/EgovProductNmList.do',
        /** 품목명 삭제 */
        DeleteProductName : 'kri/pdt/EgovProductNmDelete.do',


        /** 품목 구분 생성 */
        AddProductType : 'kri/pdt/EgovProductTypeInsert.do',
        /** 품목 구분 조회 */
        GetProductType : 'kri/pdt/EgovProductTypeList.do',
        /** 품목 구분 삭제 */
        DeleteProductType : 'kri/pdt/EgovProductTypeDelete.do',
        
        
        /** 해석 목적 생성 */
        AddAnalysisPurpose : 'kri/pdt/ArPurposeInsert.do',
        /** 해석 목적 조회 */
        GetAnalysisPurpose : 'kri/cmm/ArPurposeCmmList.do',
        /** 해석 목적 삭제 */
        DeleteAnalysisPurpose : 'kri/pdt/ArPurposeDelete.do',
    },
    /**
     * AnalysisRequest API
     * @url https://keri.ictpeople.co.kr/misc/api/swagger-ui/?urls.primaryName=A05.AnalysisRequest_API#/
     */
    AnalysisRequest : {
        /** 전문가 조회 */
        GetExpertUserList : 'kri/arq/ExpertList.do',
        /** 기술지원 신청 */
        AddTechService : 'kri/arq/ArRequestInsert.do',
        /** 기술지원 신청 목록 조회 */
        GetTechService : 'kri/arq/ArRequestList.do',
        /** 기술지원 신청 상세 조회 */
        GetTechServiceInfo : 'kri/arq/ArRequestDetail.do',
        /** 기술지원 수정 */
        UpdateTechService : 'kri/arq/ArRequestUpdate.do',
        /** 설문내용 조회 */
        GetSurveyList : 'kri/arq/qri/QustnrMngInfo.do',
        /** 기술지원 완료 처리 */
        CompleteTechService : 'kri/arq/ArResultInsert.do',


        /** 시뮬레이션 조회 */
        GetSimulationList : 'kri/arq/ArSimulationList.do',
        /** 시뮬레이션 생성 */
        AddSimulation : 'kri/arq/ArSimulationInsert.do',
        /** 시뮬레이션 삭제 */
        DeleteSimulation : 'kri/arq/ArSimulationDelete.do',
        /** 시뮬레이션 실행 요청 */
        RunSimulation : 'kri/arq/ArSimulationRequest.do',
        /** 완료확인서 생성 */
        GetCompletePDF : 'kri/arq/ArCertPdfCreate.do'

        
    },
    /**
     * Login/Register API
     * @url https://keri.ictpeople.co.kr/misc/api/swagger-ui/?urls.primaryName=A01.Login_API#/
     */
    Login : {
        AddCompanyInfo : '/uat/uia/registCompany.do',
        AddUserInfo : '/uat/uia/registMber.do',
        GetCompanyInfo : '/uat/uia/EgovCompnyInfo.do',
        ResetUserPassword : '/uat/uia/changePassword.do',
        FindAccount : '/uat/uia/searchId.do'
    },
    /**
     * Comment API
     * @url https://keri.ictpeople.co.kr/misc/api/swagger-ui/?urls.primaryName=A06.Comments_API#/
     */
    Comments : {
        GetTechServiceComment : 'kri/cmt/ArCommentList.do',
        AddTechServiceComment : 'kri/cmt/ArCommentInsert.do',
        GetTechServiceUnreadCommentList : 'kri/cmt/NoReadCommentArList.do'
    },
    /**
     * Board API
     * @url https://keri.ictpeople.co.kr/misc/api/swagger-ui/?urls.primaryName=A02.Notice_API#/
     */
    Board : {
        GetBoardItemList : 'kri/bbs/BbsArticleList.do',
        GetBoardItem : 'kri/bbs/BbsArticleDetail.do',
        AddBoardItem : 'kri/bbs/BbsArticleInsert.do',
        DeleteBoardItem : 'kri/bbs/BbsArticleDelete.do',
        GetFaqItemList : 'uss/olh/faq/FaqList.do',
        GetFaqItem : '/uss/olh/faq/FaqDetail.do',
        SetRating : '/cop/stf/insertSatisfaction.do',
        EditBoardItem : '/kri/bbs/BbsArticleUpdate.do',
        GetBoardItemComment : '/cop/cmt/ArticleCommentList.do',
        SetBoardItemComment : '/cop/cmt/ArticleCommentInsert.do'
    },
    /**
     * Main API
     * @url https://keri.ictpeople.co.kr/misc/api/swagger-ui/?urls.primaryName=A00.MainPage_API#/
     */
    Main : {
        GetMenuList : 'kri/mai/MainMenuList.do',
        GetMainData : 'kri/mai/MainDisplayArticleList.do'
    },
    Template : {
        GetTemplateFiles : 'kri/cmm/FileTemplateDetail.do',
        GetTemplateList : 'kri/cmm/FileTemplateList.do'
    },
    Education : {
        GetEducationList : 'kri/edu/curriculumList.do',
        GetEducationInfo : 'kri/edu/curriculumDetail.do',
        RequestEducation : 'kri/edu/eduApplicateInsert.do',
    }
}


export default KeriAPIStructure;