import { ObjectUtils } from "@ict-base/module/utils/ObjectUtils"
import { IListPagination } from "@keri-base/interface/control/IListPagination"
import { Add, Delete } from "@mui/icons-material"
import { Box, CircularProgress, Divider, List, ListItemButton, ListItemText, Pagination, Paper, SxProps, TextField, Theme, Typography } from "@mui/material"
import { ILoginSessionData } from "@src/modules/redux/reducer/UserDataReducer"
import { DefaultScrollDesign } from "@src/modules/styles/DefaultScrollDesign"
import { AxiosError } from "axios"
import React, { HtmlHTMLAttributes, ReactElement, useEffect, useState } from "react"
import ICTBaseModal from "./ICTBaseModal"
import ICTPagination from "./ICTPagination"
import { ICTSearchBox } from "./ICTSearchBox"
import KeriActionButton from "./KeriActionButton"
import KeriCheckBox from "./KeriCheckBox"



interface IICTCategorySearchModal {
    open: boolean,
    onClose: () => void,
    openCategory: (category: string) => void,
    // onSubmit : () => void,
    title: string,
    contentProps?: {
        style?: SxProps<Theme>
    }
    children?: ReactElement[] | ReactElement
}

const ICTCategorySearchModal = (prop: IICTCategorySearchModal) => {
    const childElement = (Array.isArray(prop.children) ? prop.children : prop.children !== undefined ? [prop.children] : []).filter(x => x.props.enable);
    const [selectCategory, setSelectCategory] = useState<string | undefined>(undefined);

    const [activeItem, setActiveItem] = useState<JSX.Element | undefined>(undefined);

    useEffect(() => {
        if (prop.open) {
            setSelectCategory(childElement.length !== 0 ? (childElement[0].props.category?.toString()) : undefined);
        }
    }, [prop.open])

    useEffect(() => {
        selectCategory !== undefined && prop.openCategory(selectCategory);
        const selectElement = Array.isArray(prop.children) ? prop.children.find(x => x.props.category === selectCategory) : prop.children;
        if (activeItem !== selectElement) {
            setActiveItem(selectElement);
        }
    }, [selectCategory])

    return (
        <ICTBaseModal
            open={prop.open}
            onClose={prop.onClose}
            title={prop.title}
        >
            <Box
                sx={{
                    width: 800,
                    height: 700,
                    maxHeight: '100%',
                    maxWidth: '100vw',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'start',
                    p: 1,
                    pt: 0,
                    ...prop.contentProps?.style
                }}
            >
                {
                    childElement.length !== 1 &&
                    <React.Fragment>
                        <Box
                            className="ICTCategorySearchModal-CategoryBox"
                            sx={{
                                width: '20%',
                            }}
                        >
                            <List>
                                {
                                    childElement.map((childItem) => (
                                        <ListItemButton
                                            sx={{
                                                p: 0.5,
                                                pl: 1,
                                                borderLeft: childItem.props.category === selectCategory ? '2px solid #aaa' : undefined,
                                                background: childItem.props.category === selectCategory ? 'rgba(0,0,0,0.05)' : undefined,
                                            }}
                                            onClick={() => {
                                                setSelectCategory(childItem.props.category);

                                            }}
                                        >
                                            <ListItemText>
                                                {childItem.props.category?.toString()}
                                            </ListItemText>
                                        </ListItemButton>
                                    ))
                                }

                            </List>
                        </Box>
                        <Divider orientation="vertical" flexItem sx={{ borderColor: 'rgba(0,0,0,0.2)', ml: 0.5, mr: 0.5, mt: 0.5, mb: 0.5 }} />

                    </React.Fragment>
                }
                {
                    selectCategory !== undefined &&
                    <Box
                        className="ICTCategorySearchModal-ItemBox"
                        sx={{
                            width: childElement.length !== 1 ? '80%' : '100%',
                            maxHeight: '100%',
                            pr: 1,
                            pl: 1,
                            // height: '100%',
                        }}
                    >
                        {activeItem}
                    </Box>
                }

            </Box>
        </ICTBaseModal >
    )

}




interface IICTCategorySearchModalItem<T, D, E, O> {
    enable: boolean,
    // data?: T,
    activeCategory: string | undefined,
    category: string,
    dataLoad: {
        getDataFunction: (page: number, searchText?: string) => Promise<T>
        getDataArray: (item: T) => D[],
        getAPIState: {
            state: (item: T) => boolean,
            errorMessage: (item: T) => string | undefined
        },
        getPagination: {
            totalPage: (item: T) => number | undefined,
            nowPage: (item: T) => number | undefined
        }
    }
    display: {
        displayItemTitle: (item: D) => string,
        displayItemSubTitle?: (item: D) => string,
        disableAddButton?: boolean,
        disableDeleteButton?: boolean,
        disableSearch?: boolean,
        displayKey: (item: D) => string | number
    },
    event: {
        onSubmit: (item: D[]) => void,
        onClose: () => void
    },
    addData?: {
        addDataFunction: (addText: string) => Promise<E>,
        addAPIState: {
            state: (item: E) => boolean,
            errorMessage: (item: E) => string | undefined
        },
    },
    deleteData?: {
        deleteDataFunction: (deleteItem: D[]) => Promise<O>,
        deleteAPIState: {
            state: (item: O) => boolean,
            errorMessage: (item: O) => string | undefined
        },
    },
    selectData?: {
        selectFilter?: (item: D[]) => {
            state: boolean,
            message?: string | undefined
        } | undefined,
        defaultValue?: D[]
    }
    elementProps?: {
        activeSubmit?: boolean,
        waitForAuthReady?: {
            authData: ILoginSessionData | undefined,
            isComplete: (item: ILoginSessionData) => boolean
        }
    }
}


export const ICTCategorySearchModalCategory = <T extends any, D extends any, E extends any, O extends any>(prop: IICTCategorySearchModalItem<T, D, E, O>) => {

    const [checkItem, setCheckItem] = useState<D[]>([...(prop.selectData?.defaultValue ?? [])]);

    const [page, setPage] = useState<IListPagination>({
        pageObject: {
            nowPage: 1,
            totalPage: 1
        }
    })
    const [searchInputValue, setSearchInputValue] = useState<string>('');
    const [searchText, setSearchText] = useState<string | undefined>(undefined);
    const [dataLoading, setDataLoading] = useState<boolean>(false);
    const [dataList, setDataList] = useState<D[]>([]);
    const [openAddPanel, setOpenAddPanel] = useState<boolean>(false);
    const [addText, setAddText] = useState<string | undefined>(undefined);


    const getDataFromAPI = (getPage: number, getSearchText?: string) => {

        setDataLoading(true);
        const getAPIData = prop.dataLoad.getDataFunction(getPage, getSearchText);

        getAPIData
            .then(async (res: T) => {
                if (prop.dataLoad.getAPIState.state(res)) {


                    // setDataList(prop.dataLoad.getDataArray(res))
                    // setPage({
                    //     pageObject: {
                    //         nowPage: prop.dataLoad.getPagination.nowPage(res),
                    //         totalPage: prop.dataLoad.getPagination.totalPage(res)
                    //     }
                    // })


                    if(getPage !== 1 && prop.dataLoad.getDataArray(res).length === 0){
                        console.log(prop.dataLoad.getDataArray(res));
                        getDataFromAPI(1,getSearchText);
                    }
                    else{
                        setDataList(prop.dataLoad.getDataArray(res))
                        setPage({
                            pageObject: {
                                nowPage: prop.dataLoad.getPagination.nowPage(res),
                                totalPage: prop.dataLoad.getPagination.totalPage(res)
                            }
                        })
                    }

                    
                }
                else {
                    throw ({ message: prop.dataLoad.getAPIState.errorMessage(res) })
                }
            })
            .catch((err: AxiosError) => {
                alert(err.message);
            })
            .finally(() => {
                setDataLoading(false);
            })


    }

    const addDataAPI = (addText: string) => {


        if (prop.addData !== undefined) {
            setDataLoading(true);

            const getAPIData = prop.addData.addDataFunction(addText);

            getAPIData
                .then((res: E) => {
                    if (prop.addData!.addAPIState.state(res)) {

                    }
                    else {
                        throw ({ message: prop.addData!.addAPIState.errorMessage(res) })
                    }
                })
                .catch((err: AxiosError) => {
                    alert(err.message);
                })
                .finally(() => {
                    setDataLoading(false);
                    setAddText(undefined);
                    setOpenAddPanel(false);
                    getDataFromAPI(page.pageObject?.nowPage ?? 1, searchText);
                })

        }


    }

    const deleteDataAPI = (deleteItem: D[]) => {
        if (prop.deleteData !== undefined) {
            const confirmDelete = window.confirm('선택된 항목들을 삭제하시겠습니까?');

            if (confirmDelete) {
                setDataLoading(true);

                const getAPIData = prop.deleteData.deleteDataFunction(deleteItem);

                getAPIData
                    .then((res: O) => {
                        if (prop.deleteData!.deleteAPIState.state(res)) {

                        }
                        else {
                            throw ({ message: prop.deleteData!.deleteAPIState.errorMessage(res) })
                        }
                    })
                    .catch((err: AxiosError) => {
                        alert(err.message);
                    })
                    .finally(() => {
                        setDataLoading(false);
                        setCheckItem([]);
                        getDataFromAPI(page.pageObject?.nowPage ?? 1, searchText);
                    })
            }

        }

    }

    const onSubmit = () => {
        const checkFilter = prop.selectData?.selectFilter && prop.selectData.selectFilter(checkItem);

        if (checkFilter !== undefined) {
            if (checkFilter.state) {
                prop.event.onSubmit(checkItem);
                prop.event.onClose();
            }
            else {
                alert(checkFilter?.message);
            }
        }
        else {
            prop.event.onSubmit(checkItem);
            prop.event.onClose();
        }

    }

    useEffect(() => {
        setSearchInputValue('');
        if (prop.elementProps?.waitForAuthReady !== undefined) {
            if (prop.elementProps?.waitForAuthReady?.authData && prop.elementProps?.waitForAuthReady?.isComplete(prop.elementProps.waitForAuthReady.authData)) {
                getDataFromAPI(1);
            }
        }
        else {
            getDataFromAPI(1);
        }

    }, [prop.elementProps?.waitForAuthReady])

    useEffect(() => {
        setSearchInputValue('');

        if (prop.elementProps?.waitForAuthReady !== undefined) {
            if (prop.elementProps?.waitForAuthReady?.authData && prop.elementProps?.waitForAuthReady?.isComplete(prop.elementProps.waitForAuthReady.authData)) {
                getDataFromAPI(1);
            }
        }
        else {
            getDataFromAPI(1);
        }
    }, [prop.activeCategory])


    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'column',
                height: '100%',
                maxHeight: 'calc(100vh - 50px)'
            }}
        >
            
            <Box>
                <Box
                    className='ICTCategorySearchModal-ItemBoxToolbar'
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}
                >
                    <Box
                        sx={{
                            width: '60%'
                        }}
                    >
                        {
                            !(prop.display.disableSearch ?? false) &&
                            <ICTSearchBox
                                onSearch={(changeText) => {
                                    setCheckItem([]);
                                    getDataFromAPI(page.pageObject?.nowPage ?? 1, changeText);
                                }}
                                value={searchInputValue}
                                onChange={(changeText) => {
                                    setSearchInputValue(changeText);
                                    setSearchText(changeText)
                                }}
                            />
                        }

                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'end',
                            alignItems: 'center',
                            width: '40%'
                        }}
                    >
                        {
                            !(prop.display.disableAddButton ?? false) &&
                            <KeriActionButton
                                label="추가"
                                variant="outlined"
                                color='primary'
                                size="small"
                                icon={<Add fontSize="small" />}

                                onClick={() => {
                                    setOpenAddPanel(!openAddPanel);
                                }}
                                sx={{
                                    ml: 1
                                }}
                            />
                        }
                        {
                            !(prop.display.disableDeleteButton ?? false) &&
                            <KeriActionButton
                                label="삭제"
                                variant="outlined"
                                color='error'
                                size="small"
                                icon={<Delete fontSize="small" />}
                                onClick={() => {
                                    deleteDataAPI(checkItem)
                                }}
                                sx={{
                                    ml: 1
                                }}
                            />
                        }


                    </Box>


                </Box>
                <Divider sx={{ mt: 1, mb: 1 }} />
            </Box>

            <Box
                sx={{
                    // height: (prop.elementProps?.activeSubmit ?? true) ? '80%' : '90%',
                    overflow: 'auto',
                    ...DefaultScrollDesign
                }}
            >
                {
                    dataLoading &&
                    <Box
                        sx={{
                            textAlign: 'center',
                            height: '400px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <CircularProgress color="inherit" />
                    </Box>
                }
                {
                    !dataLoading && dataList.length === 0 && !openAddPanel &&
                    <Box
                        sx={{
                            textAlign: 'center',
                            height: '400px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <Typography>
                            검색된 값이 없습니다.
                        </Typography>
                    </Box>
                }
                {
                    !dataLoading && openAddPanel &&
                    <Paper
                        // elevation={2}
                        sx={{
                            p: 1,
                            mb: 1,
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <Box
                            sx={{
                                width: '80%'
                            }}
                        >
                            <TextField
                                size="small"
                                fullWidth
                                onChange={(newText) => {
                                    setAddText(newText.currentTarget.value);
                                }} />
                        </Box>

                        <Box
                            sx={{
                                width: '20%',
                                textAlign: 'end'
                            }}
                        >
                            <KeriActionButton
                                label="저장"
                                disabled={addText === undefined}
                                variant="outlined"
                                onClick={() => {
                                    addDataAPI(addText!)
                                }}
                            />
                        </Box>
                    </Paper>
                }
                {
                    !dataLoading && dataList.length !== 0 &&
                    <Box>
                        {
                            dataList.map(dataItem => (
                                <Paper
                                    key={ObjectUtils.RandomStrings(10)}
                                    sx={{
                                        mb: 0.5,
                                        ":nth-last-child": {
                                            mb: 0
                                        },
                                        p: 1,
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        cursor: 'pointer',
                                        transition: '0.5s all cubic-bezier(0,0,0,1)',
                                        ":hover": {
                                            pt: 1.4,
                                            pb: 1.4,
                                            background: 'rgba(0,0,0,0.05)'
                                        }
                                    }}
                                    onClick={() => {
                                        if (checkItem.find(y => prop.display.displayKey(y) === prop.display.displayKey(dataItem)) !== undefined) {
                                            setCheckItem([
                                                ...checkItem.filter(y => prop.display.displayKey(y) !== prop.display.displayKey(dataItem))
                                            ]);
                                        }
                                        else {
                                            setCheckItem([
                                                ...checkItem.filter(y => prop.display.displayKey(y) !== prop.display.displayKey(dataItem)),
                                                dataItem
                                            ]);
                                        }
                                    }}
                                >

                                    <Box>
                                        <KeriCheckBox
                                            checked={checkItem.find(y => prop.display.displayKey(y) === prop.display.displayKey(dataItem)) !== undefined}
                                            onChange={() => {
                                            }}
                                        />
                                    </Box>
                                    <Box
                                        sx={{
                                            width: '90%',
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                width: prop.display.displayItemSubTitle !== undefined ? undefined : '50%'
                                            }}
                                        >
                                            <Typography>
                                                {prop.display.displayItemTitle(dataItem)}
                                            </Typography>
                                        </Box>



                                        {
                                            prop.display.displayItemSubTitle &&
                                            <Box
                                                sx={{
                                                    width: '50%'
                                                }}
                                            >
                                                <Typography>
                                                    {prop.display.displayItemSubTitle(dataItem)}
                                                </Typography>
                                            </Box>
                                        }


                                    </Box>


                                </Paper>
                            ))
                        }

                    </Box>
                }

            </Box>
            <Box>
                <ICTPagination
                    align="center"
                    pageObject={{
                        nowPage: page.pageObject?.nowPage,
                        totalPage: page.pageObject?.totalPage,
                        onChangePage: (changePage) => {
                            setPage({
                                pageObject: {
                                    nowPage: changePage,
                                    totalPage: page.pageObject?.totalPage
                                }
                            })
                            getDataFromAPI(changePage, searchText);
                        }
                    }}
                />
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'end',
                    alignItems: 'center',
                }}
            >
                {
                    (prop.elementProps?.activeSubmit ?? true) &&
                    <KeriActionButton
                        label="적용"
                        variant="contained"
                        onClick={() => {
                            onSubmit();
                        }}
                    />
                }
            </Box>


        </Box>
    )
}

export default ICTCategorySearchModal;