import KeriActionButton from "@ict-base/components/control/KeriActionButton";
import InfoLayoutPage from "@keri-portal/component/layout/InfoLayoutPage";
import { Paper, Typography, TextField } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";


interface IKeriBoardLockUnit {
    isLock: boolean,
    onAuth: (state: boolean, password: string) => void,
    checkAuth: (password: string) => Promise<{ state: boolean, errorMessage?: string }>,
    children?: React.ReactNode | React.ReactNode[]
}

const KeriBoardLockUnit = (prop: IKeriBoardLockUnit) => {
    const [password , setPassword] = useState<string>('');

    return (
        <React.Fragment>
            {
                prop.isLock &&
                <InfoLayoutPage>
                    <Paper>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                minHeight: '200px',
                                width: '100%',
                                flexDirection: 'column'
                            }}
                        >
                            <Box
                                sx={{
                                    mb: 2
                                }}
                            >
                                <Typography>
                                    비밀번호를 입력해 주세요.
                                </Typography>
                            </Box>

                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'start',
                                    alignItems: 'center',
                                    border: '1px solid rgba(0,0,0,0.1)',
                                    borderRadius: '5px',
                                    overflow: 'hidden',
                                    height: '50px'
                                }}
                            >
                                <TextField
                                    type='password'
                                    size="small"
                                    inputProps={{
                                        style: {
                                            textAlign: 'center',
                                        }
                                    }}
                                    sx={{
                                        '& fieldset': {
                                            border: 'none'
                                        }
                                    }}
                                    value={password}
                                    onChange={(e) => {
                                        setPassword(e.currentTarget.value);
                                    }}
                                />
                                <KeriActionButton
                                    label="확인"
                                    onClick={async () => { 
                                        const authCheck = await prop.checkAuth(password)
                                        if(authCheck.state){
                                           prop.onAuth(true,password); 
                                        }
                                        else{
                                            alert(authCheck.errorMessage);
                                            prop.onAuth(false, password);
                                        }
                                    }}
                                    variant="contained"
                                    sx={{
                                        height: '100%',
                                        borderRadius: '0px'
                                    }}
                                />
                            </Box>

                        </Box>
                    </Paper>
                </InfoLayoutPage>
            }
            {
                !prop.isLock && prop.children
            }
        </React.Fragment>

    )
}


export default KeriBoardLockUnit;