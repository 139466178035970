import { Box, Checkbox, CheckboxProps, createTheme, FormControlLabel, FormGroup, FormHelperText, SxProps, Theme, ThemeProvider } from "@mui/material";


const KeriCheckBox = (prop: CheckboxProps) => {

    const theme = createTheme({
        components: {
            MuiCheckbox: {

            }
        }
    })

    return (
        <ThemeProvider theme={theme}>
            <Checkbox
                {...prop}
                sx={{
                    color: '#25476a',
                    p: 0,
                    '&.Mui-checked': {
                        color: '#25476a'
                    },
                    ...prop.sx,
                }}
            />
        </ThemeProvider>

    )
}

interface IKeriCheckLabel {
    label: string | React.ReactNode,
    onChange?: (state: boolean, e : React.ChangeEvent<HTMLInputElement>) => void,
    isError?: boolean,
    errorMessage?: string,
    sx? : SxProps<Theme>,
    checked? : boolean,
    disabled? : boolean,
    checkRef? : React.RefObject<HTMLInputElement>
}

export const KeriCheckLabel = (prop: IKeriCheckLabel) => {
    return (
        <Box
            sx={{
                ...prop.sx
            }}
        >
            <FormGroup>
                <FormControlLabel
                    label={prop.label}
                    control={
                        <KeriCheckBox
                            checked={prop.checked}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => prop.onChange && prop.onChange(e.target.checked,e)}
                            color={prop.isError ? 'error' : undefined}
                            sx={{
                                color: prop.isError ? 'rgb(223, 86, 69)' : undefined,
                                mr : 1
                            }}
                            disabled={prop.disabled??false}
                            inputRef={prop.checkRef}
                        />
                    }
                    color={prop.isError ? 'error' : "default"}
                    sx={{
                        color: prop.isError ? 'rgb(223, 86, 69)' : '#black',
                        m : 0
                    }}
                />
                {
                    prop.isError &&
                    <FormHelperText sx={{ color: 'rgb(223, 86, 69)', m: 0, p: 0 }}>
                        {prop.errorMessage}
                    </FormHelperText>
                }

            </FormGroup>



        </Box>
    )
}

export default KeriCheckBox;