import KeriCheckBox from "@ict-base/components/control/KeriCheckBox";
import ICTBaseModal, { KeriModalSubmitAndCancelButton } from "@ict-base/components/control/ICTBaseModal";
import { ICTSearchBox } from "@ict-base/components/control/ICTSearchBox";
import { Box, List, ListItemButton, ListItemIcon, ListItemText, Pagination, SxProps, Theme } from "@mui/material";
import { DefaultScrollDesign } from "@src/modules/styles/DefaultScrollDesign";
import { useEffect, useState } from "react";

// /** @deprecated */
interface IKeriSelectItemModal<T> {
    open: boolean,
    onClose: () => void,
    
    dataObject : {
        value : T[],
        onSubmit : (item : T[] | (T | undefined)) => void
    },
    displayObject : {
        windowTitle : string,
        displayItemTitle : (item: T) => string | undefined,
        displayItemSubTitle? : (item: T) => string | undefined,
        displayItemCode? : (item : T) => string | undefined
    }
    modalProp? : {
        windowWidth?: number,
    }
    listProps? : {
        itemProp? : {
            sx : SxProps<Theme>
        },
        multipleCheck?: boolean,
        maxHeight? : number | undefined
    }
    searchProp? : {
        onSearch? : (text: string) => void,
        maskRegex?: (text: string) => string,
        placeHolder?: string,
        searchBoxSx? : SxProps<Theme>
    }
    paging: {
        onPageChange: (page: number) => void,
        count: number,
        page: number
    },
    
    
}
/** @deprecated */
export interface IKeriPagination {
    count: number,
    page: number
}

// /** @deprecated */
const KeriSelectItemModal = <T extends any>(prop: IKeriSelectItemModal<T>) => {

    const [checkItem, setCheckItem] = useState<T | undefined>(undefined);
    const [checkList, setCheckList] = useState<T[]>([]);

    const [listItem, setListItem] = useState<T[]>([]);

    useEffect(() => {
        setListItem(prop.dataObject.value)
    }, [prop.dataObject.value])

    useEffect(() => {
        if (prop.open) {
            setCheckItem(undefined);
            setCheckList([]);
        }
    }, [prop.open])

    return (
        <ICTBaseModal
            open={prop.open}
            onClose={prop.onClose}
            submitButton={
                (prop.listProps?.multipleCheck ?? false) &&
                <KeriModalSubmitAndCancelButton
                    onCancel={() => prop.onClose()}
                    onSubmit={() => {
                        if (prop.listProps?.multipleCheck ?? false) {
                            prop.dataObject.onSubmit(checkList);
                        }
                        else {
                            prop.dataObject.onSubmit(checkItem);
                        }
                    }}
                />
            }
            title={prop.displayObject.windowTitle}
        >
            <Box
                sx={{
                    width: prop.modalProp?.windowWidth
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        pr: 1,
                        pl: 1
                    }}
                >
                    <ICTSearchBox
                        label={prop.searchProp?.placeHolder}
                        onSearch={(text) => {
                            prop.searchProp?.onSearch && prop.searchProp.onSearch(text);
                        }}
                        maskRegex={prop.searchProp?.maskRegex}
                        fontSize='12px'
                        sx={{
                            mb : 2,
                            mt : 1,
                            width : '70%',
                            ...prop.searchProp?.searchBoxSx,
                        }}
                    />
                </Box>

                <Box>


                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                    >
                        <Box>

                        </Box>
                    </Box>


                    <Box
                        sx={{
                            overflow: 'hidden',
                            boxSizing: 'border-box',
                        }}
                    >
                        {
                            listItem.length === 0 &&
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    pt: 4,
                                    pb: 2
                                }}
                            >
                                검색된 결과가 없습니다.
                            </Box>
                        }
                        {
                            listItem.length !== 0 &&
                            <List
                                sx={{
                                    maxHeight: prop.listProps?.maxHeight,
                                    m: 0,
                                    p: 0.5,
                                    overflowY: 'scroll',
                                    ...DefaultScrollDesign
                                }}
                            >
                                {

                                    listItem.map((item, idx) => (
                                        <ListItemButton
                                            key={idx}
                                            disableRipple
                                            dense
                                            sx={{
                                                p: 0.5,
                                                pl: 1,
                                                m: 0,
                                                alignItems: 'center',
                                                borderBottom: '1px solid rgba(0,0,0,0.1)',
                                                ...prop.listProps?.itemProp?.sx,
                                                ...(idx === 0 && { borderTop: '1px solid rgba(0,0,0,0.1)' })
                                            }}
                                            onClick={() => {

                                                if (prop.listProps?.multipleCheck ?? false) {
                                                    if (checkList.find(x => x === item)) {
                                                        setCheckList([
                                                            ...checkList.filter(x => x !== item),
                                                        ]);
                                                    }
                                                    else {
                                                        setCheckList([
                                                            ...checkList.filter(x => x !== item),
                                                            item
                                                        ]);
                                                    }
                                                }
                                                else {
                                                    prop.dataObject.onSubmit(item);
                                                }



                                            }}
                                        >
                                            {
                                                (prop.listProps?.multipleCheck ?? false) &&
                                                <ListItemIcon
                                                    sx={{
                                                        minWidth: 0
                                                    }}
                                                >
                                                    <KeriCheckBox
                                                        edge='start'
                                                        disableRipple
                                                        checked={
                                                            (prop.listProps?.multipleCheck ?? false)
                                                                ? checkList.find(x => x === item) !== undefined
                                                                : (checkItem !== undefined && checkItem === item)

                                                        }
                                                        sx={{
                                                            m: 0,
                                                            p: 0,
                                                            transform: 'scale(0.9)'
                                                        }}
                                                    />

                                                </ListItemIcon>
                                            }
                                            {
                                                prop.displayObject.displayItemCode !== undefined &&
                                                <ListItemText
                                                    primary={prop.displayObject.displayItemCode(item)}
                                                    sx={{
                                                        textAlign : 'center',
                                                        maxWidth : '20%'
                                                    }}
                                                />
                                            }

                                            <ListItemText
                                                primary={prop.displayObject.displayItemTitle(item)}
                                                secondary={prop.displayObject.displayItemSubTitle !== undefined ? prop.displayObject.displayItemSubTitle(item) : undefined}
                                                sx={{
                                                    pl: 0,
                                                    ml: 1,
                                                }}
                                            />
                                        </ListItemButton>
                                    ))
                                }
                            </List>
                        }

                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            mt: 2,
                            mb: 2
                        }}
                    >
                        <Pagination
                            page={prop.paging.page}
                            count={prop.paging.count}
                            onChange={(e, page) => prop.paging.onPageChange(page)}
                        />
                    </Box>
                </Box>
            </Box>

        </ICTBaseModal>
    )
}

export default KeriSelectItemModal;