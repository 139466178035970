import ICTCategorySearchModal, { ICTCategorySearchModalCategory } from "@ict-base/components/control/ICTCategorySearchModal"
import { IKeriPurpose } from "@keri-portal/interfaces/data-object/data-common/IKeriPurpose"
import { TechServiceType } from "@keri-portal/interfaces/data-object/tech-service/ITechService"
import useLoginCheck from "@keri-portal/module/hook/useLoginCheck"
import RequestKeriAPI from "@keri-portal/module/method/api-connect/RequestKeriAPI"
import React, { useEffect, useState } from "react"

interface IKeriSelectAnalysisPurposeModal {
    open: boolean,
    onClose: () => void,
    submit?: {
        onFilter: (item: IKeriPurpose[]) => { state: boolean, message?: string },
        onSubmit: (item: IKeriPurpose[]) => void,
    },
    defaultValue?: IKeriPurpose[],
    techServiceType : TechServiceType
}

const KeriSelectAnalysisPurposeModal = (prop: IKeriSelectAnalysisPurposeModal) => {

    const userRedux = useLoginCheck();

    const [defValue, setDefValue] = useState<IKeriPurpose[]>(prop.defaultValue ?? []);
    const [lazyLoad, setLazyLoad] = useState<boolean>(false);

    useEffect(() => {
        if (prop.open) {
            setDefValue(prop.defaultValue ?? []);
            setLazyLoad(true);
        }
        else{
            setLazyLoad(false);
        }
    }, [prop.open])

    useEffect(() => {
        if(prop.open){
            setLazyLoad(true);
        }
    }, [defValue])

    return (
        <React.Fragment>
            {
                lazyLoad &&
                <ICTCategorySearchModal
                    open={prop.open}
                    onClose={() => prop.onClose()}
                    title={`${prop.techServiceType === 'A' ? '해석' : '신청'} 목적 선택`}
                    openCategory={(category) => { }}
                >

                    <ICTCategorySearchModalCategory
                        enable={true}
                        activeCategory={'AnalysisPurpose'}
                        category="해석 목적"
                        dataLoad={{
                            getDataFunction: (page, searchText) => RequestKeriAPI.Attribute.GetAnalysisPurpose(userRedux?.token, page, searchText),
                            getDataArray: (item) => item.resultData ?? [],
                            getAPIState: {
                                state: (item) => item.state,
                                errorMessage: (item) => item.message
                            },
                            getPagination: {
                                nowPage: (item) => item.pageObject?.nowPage,
                                totalPage: (item) => item.pageObject?.totalPage
                            }
                        }}
                        display={{
                            disableAddButton : true,
                            disableDeleteButton : true,
                            disableSearch : true,
                            displayItemTitle: (item) => item.purposeName,
                            displayKey: (item) => `${item.purposeName}`
                        }}
                        event={{
                            onSubmit: (item) => {
                                prop.submit?.onSubmit && prop.submit.onSubmit(item);
                                prop.onClose();
                            },
                            onClose: () => { prop.onClose() }
                        }}
                        selectData={{
                            selectFilter: (item) => prop.submit?.onFilter(item),
                            defaultValue: defValue
                        }}
                        // addData={{
                        //     addDataFunction: (addText) => RequestKeriAPI.Attribute.AddAnalysisPurpose(userRedux?.token, addText),
                        //     addAPIState: {
                        //         state: (item) => item.state,
                        //         errorMessage: (item) => item.message
                        //     }
                        // }}
                        // deleteData={{
                        //     deleteDataFunction: (item) => RequestKeriAPI.Attribute.DeleteAnalysisPurpose(userRedux?.token, item),
                        //     deleteAPIState: {
                        //         state: (item) => item.state,
                        //         errorMessage: (item) => item.message
                        //     }
                        // }}
                        elementProps={{
                            activeSubmit: true,
                            waitForAuthReady: {
                                authData: userRedux,
                                isComplete: (item) => item.loadComplete
                            }
                        }}
                    />


                </ICTCategorySearchModal>
            }
        </React.Fragment>

    )
}



export default KeriSelectAnalysisPurposeModal;