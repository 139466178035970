import KeriActionButton from "@ict-base/components/control/KeriActionButton"
import { ObjectUtils } from "@ict-base/module/utils/ObjectUtils"
import { Box, BoxProps, Divider, Paper, Theme, Typography } from "@mui/material"
import { boxSizing, SxProps } from "@mui/system"
import { CSSProperties } from "styled-components"
import { Navigation, Autoplay, FreeMode, Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"


import 'swiper/css';
import "swiper/css/pagination";
import "swiper/css/navigation"
import URLStructure from "@src/modules/structure/URLStructure"
import { useNavigate } from "react-router-dom"
import KeriConfig from "@keri-portal/config/KeriConfig"
import { RemoveHTMLTag } from "@ictpeople/common"

interface IMainContentSection {
    children?: React.ReactNode | React.ReactNode[]
    title: string,
    sectionProps?: {
        sx?: SxProps<Theme>
    },
    childProps? : BoxProps
}

export const MainContentSection = (prop: IMainContentSection) => {
    return (
        <Box
            sx={{
                mt: 2,
                mb: 2,
                p: 2,
                pl: 0,
                pr: 0,
                ...prop.sectionProps?.sx
            }}
        >
            <Box
                sx={{
                    mb: 1,
                    ml: 1
                }}
            >
                <Typography
                    sx={{
                        fontSize: '30px',
                        fontWeight: 'bold'
                    }}
                >
                    {prop.title}
                </Typography>
            </Box>
            <Divider />
            <Box
            {...prop.childProps}
                sx={{
                    mt: 2,
                    ...prop.childProps?.sx
                }}
            >
                {prop.children}
            </Box>
        </Box>
    )
}



interface IMainContentSectionSwiperList<T> {
    dataObject: T[],
    displayOption: {
        displayImageURL: (item: T) => string,
        displayTitle: (item: T) => string,
        displayDescription: (item: T) => string,
        displayDividerColor: (item: T) => string,
        connectLinkURL : (item : T) => string,
        sortObject: (item: T) => number,
        sortOption?: 'DESC' | 'ASC'
    },
    contentOption?: {
        limitContentCount?: number,
        contentStyle?: SxProps<Theme>,
        imageStyle?: CSSProperties
    }
}

export const MainContentSectionSwiperList = <T extends any>(prop: IMainContentSectionSwiperList<T>) => {
    return (
        <Box>
            <Swiper
                className="mySwiper"
                speed={300}
                // loop
                // loopedSlides={1}
                navigation={true}
                slidesPerView={4}
                spaceBetween={5}
                freeMode={true}
                // autoplay={{
                //     delay: 1000,
                //     disableOnInteraction: false
                // }}
                pagination={{
                    clickable: true,
                }}
                allowTouchMove={true}
                modules={[FreeMode, Pagination, Navigation]}
                // modules={[...(prop.displayOption.displayImageURL(prop.dataObject).length !== 1 ? [Autoplay] : [])]}
                style={{
                    width: '100%',
                    height: '100%'
                }}
            >
                {
                    prop.dataObject.map(swiperItem => (
                        <SwiperSlide
                            key={ObjectUtils.RandomStrings(10)}
                            style={{
                                // width : '200px'
                            }}
                        >
                            <MainContentSectionSwiperItem
                                key={`${prop.displayOption.sortObject(swiperItem)}-${ObjectUtils.RandomStrings(10)}`}
                                dataObject={swiperItem}
                                displayOption={prop.displayOption}
                                contentOption={{
                                    contentItemStyle: prop.contentOption?.contentStyle,
                                    imageStyle: prop.contentOption?.imageStyle,
                                    dividerColor: prop.displayOption.displayDividerColor(swiperItem),
                                    linkURL : prop.displayOption.connectLinkURL(swiperItem)
                                }}
                            />
                        </SwiperSlide>
                    ))
                }


            </Swiper>
        </Box>
    )
}

interface IMainContentSectionSwiperItem<T> {
    dataObject: T,
    displayOption: {
        displayImageURL: (item: T) => string,
        displayTitle: (item: T) => string,
        displayDescription: (item: T) => string,
    },
    contentOption: {
        contentItemStyle?: SxProps<Theme>,
        imageStyle?: CSSProperties,
        dividerColor?: string,
        linkURL? : string
    }
}

export const MainContentSectionSwiperItem = <T extends any>(prop: IMainContentSectionSwiperItem<T>) => {
    const navigate = useNavigate();
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'center',
                mb: 3,
                ml: 5,
                mr: 5,
                ...prop.contentOption.contentItemStyle
            }}
        >
            <Box>
                <img
                    src={prop.displayOption.displayImageURL(prop.dataObject)}
                    style={{
                        width: '100px',
                        ...prop.contentOption.imageStyle
                    }}
                />
            </Box>
            <Box
                sx={{
                    width: '100%'
                }}
            >
                <Typography
                    sx={{
                        p: 1,
                        fontSize: '1.2rem',
                        fontWeight: 'bold',
                        transition: '0.3s all cubic-bezier(0,0,0,1)',
                        cursor : 'pointer',
                        ":hover" : {
                            color : prop.contentOption.dividerColor
                        }
                    }}
                    onClick={() => prop.contentOption.linkURL && navigate(prop.contentOption.linkURL)}
                >
                    {prop.displayOption.displayTitle(prop.dataObject)}
                </Typography>
                <Divider
                    sx={{
                        border: 'none',
                        borderBottom: `2px solid ${prop.contentOption.dividerColor ?? 'black'}`,
                        width: '100%'
                    }}
                />
                <Box
                    sx={{
                        height: '4.0rem',
                    }}
                >
                    <Box
                        className="title-typography"
                        sx={{
                            width: '100%',
                            maxWidth: '100%',
                            overflow: 'hidden',
                            wordBreak: 'break-word',
                            textOverflow: 'ellipsis',
                            WebkitLineClamp: '2',
                            display: '-webkit-box',
                            WebkitBoxOrient: 'vertical',
                            pl: 1,
                            pt: 1,
                            lineHeight: '1.7rem',
                            minHeight: '3.4rem',
                            // boxSizing : 'content-box',
                        }}
                    >
                        {prop.displayOption.displayDescription(prop.dataObject)}
                    </Box>
                </Box>

            </Box>

        </Box>
    )
}


interface IMainContentSectionBoardList<T> {
    dataObject: T[],
    displayOption: {
        displayImageURL: (item: T) => string[],
        displayTitle: (item: T) => string,
        displayDescription: (item: T) => string,
        sortObject: (item: T) => number,
        sortOption?: 'DESC' | 'ASC',
        onClick? : (item : T) => void
    },
    contentOption?: {
        displayContentLineItemCount?: number,
        limitContentCount?: number,
        imageStyle?: CSSProperties
    }
}
export const MainContentSectionBoardList = <T extends any>(prop: IMainContentSectionBoardList<T>) => {
    return (
        <Box
            sx={{
                p: 1,
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'start',
                flexWrap: 'wrap',
                flexDirection : 'column',
            }}
        >
            {
                (prop.contentOption?.limitContentCount !== undefined
                    ?
                    prop.dataObject
                        .sort((x, y) => (prop.displayOption.sortObject(x) - prop.displayOption.sortObject(y)) * ((prop.displayOption.sortOption ?? 'ASC') === 'ASC' ? -1 : 1))
                        .slice(0, prop.contentOption.limitContentCount)
                    :
                    prop.dataObject
                        .sort((x, y) => (prop.displayOption.sortObject(x) - prop.displayOption.sortObject(y)) * ((prop.displayOption.sortOption ?? 'ASC') === 'ASC' ? -1 : 1))
                ).length === 0 &&
                <Box
                    sx={{
                        display : 'flex',
                        justifyContent : 'center',
                        alignItems : 'center',
                        minHeight : '100px',
                        width : '100%'
                    }}
                >
                    등록된 데이터가 없습니다.

                    </Box>
            }
            {
                (prop.contentOption?.limitContentCount !== undefined
                    ?
                    prop.dataObject
                        .sort((x, y) => (prop.displayOption.sortObject(x) - prop.displayOption.sortObject(y)) * ((prop.displayOption.sortOption ?? 'ASC') === 'ASC' ? -1 : 1))
                        .slice(0, prop.contentOption.limitContentCount)
                    :
                    prop.dataObject
                        .sort((x, y) => (prop.displayOption.sortObject(x) - prop.displayOption.sortObject(y)) * ((prop.displayOption.sortOption ?? 'ASC') === 'ASC' ? -1 : 1))
                )
                    .map(dataItem => (
                        <MainContentSectionBoardItem
                            key={`${prop.displayOption.sortObject(dataItem)}-${ObjectUtils.RandomStrings(10)}`}
                            dataObject={dataItem}
                            displayOption={prop.displayOption}
                            contentOption={{
                                // contentItemWidth: `calc(100% / ${prop.contentOption?.displayContentLineItemCount ?? 1})`,
                                contentItemWidth : '100%',
                                imageStyle: prop.contentOption?.imageStyle
                            }}
                        />
                    ))
            }
        </Box>
    )
}


interface IMainContentSectionBoardItem<T> {
    dataObject: T,
    displayOption: {
        displayImageURL: (item: T) => string[],
        displayTitle: (item: T) => string,
        displayDescription: (item: T) => string,
        onClick? : (item : T) => void
    },
    contentOption: {
        contentItemWidth: string,
        imageStyle?: CSSProperties
    }
}
export const MainContentSectionBoardItem = <T extends any>(prop: IMainContentSectionBoardItem<T>) => {

    const keyCode = ObjectUtils.RandomStrings(10);

    return (
        <Box
            className={`Keri-MainContentSectionBoardItem ${keyCode}`}
            sx={{
                width: prop.contentOption?.contentItemWidth ?? '100%',
                display: 'flex',
                justifyContent: 'start',
                overflow: 'hidden',
                p: 1,
                alignItems: 'center',
                cursor: 'pointer',
                ":hover": {
                    '& .title-typography': {
                        color: '#aaa'
                    }
                }
                
            }}
            onClick={() => prop.displayOption.onClick && prop.displayOption.onClick(prop.dataObject)}
        >
            <Box
                sx={{
                    minWidth: '10rem',
                    width: '10rem',
                    height: '10rem',
                    overflow: 'hidden'
                }}
            >
                <Swiper
                    className="mySwiper"
                    speed={300}
                    loop
                    loopedSlides={1}
                    autoplay={{
                        delay: 1000,
                        disableOnInteraction: false
                    }}
                    allowTouchMove={false}
                    modules={[...(prop.displayOption.displayImageURL(prop.dataObject).length > 1 ? [Autoplay] : [])]}
                    style={{
                        width: '100%',
                        height: '100%'
                    }}
                >
                    {
                        (prop.displayOption.displayImageURL(prop.dataObject).length === 0 ? [KeriConfig.ThumbnailPlaceholder] : prop.displayOption.displayImageURL(prop.dataObject)).map(imageURL => (
                            <SwiperSlide
                                key={ObjectUtils.RandomStrings(10)}
                                style={{
                                    width: '100%',
                                    height: '100%'
                                }}
                            >
                                <Box
                                    sx={{
                                        width: '100%',
                                        height: '100%',
                                        overflow: 'hidden',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    <img
                                        src={imageURL}
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: prop.displayOption.displayImageURL(prop.dataObject).length === 0 ? 'scale-down' : 'cover',
                                            ...prop.contentOption.imageStyle
                                        }}
                                    />
                                </Box>

                            </SwiperSlide>
                        ))
                    }

                </Swiper>
            </Box>
            <Box
                sx={{
                    width: 'calc(100% - 10rem)',
                    maxWidth: 'calc(100% - 10rem)',
                    height : '10rem',
                    ml: 1
                }}
            >
                <Box
                    className="title-typography"
                    sx={{
                        width: '100%',
                        maxWidth: '100%',
                        overflow: 'hidden',
                        wordBreak: 'break-word',
                        textOverflow: 'ellipsis',
                        WebkitLineClamp: '2',
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        p: 0.5,
                        pt: 0,
                        lineHeight: '1.7rem',
                        maxHeight: '3.4rem',
                        fontWeight: '500',
                        fontSize: '1.2rem'
                        // boxSizing : 'content-box',
                    }}
                >
                    {prop.displayOption.displayTitle(prop.dataObject)}
                </Box>
                <Box
                    sx={{
                        width: '100%',
                        maxWidth: '100%',
                        overflow: 'hidden',
                        wordBreak: 'break-word',
                        textOverflow: 'ellipsis',
                        WebkitLineClamp: '4',
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        p: 0.5,
                        lineHeight: '1.6rem',
                        height: '6.4rem',
                        // boxSizing : 'content-box',
                        color: 'rgba(0,0,0,0.5)'
                    }}
                >
                    {RemoveHTMLTag(prop.displayOption.displayDescription(prop.dataObject))}
                </Box>
            </Box>


        </Box>
    )
}






//#region FAQ Content Box 


interface IFaqContentSectionBoardList<T> {
    dataObject: T[],
    displayOption: {
        displayImageURL: (item: T) => string[],
        displayTitle: (item: T) => string,
        displayDescription: (item: T) => string,
        sortObject: (item: T) => number,
        sortOption?: 'DESC' | 'ASC',
        onClick? : (item : T) => void
    },
    contentOption?: {
        displayContentLineItemCount?: number,
        limitContentCount?: number,
        imageStyle?: CSSProperties
    }
}
export const FaqContentSectionBoardList = <T extends any>(prop: IFaqContentSectionBoardList<T>) => {
    return (
        <Box
            sx={{
                p: 1,
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'start',
                flexWrap: 'wrap',
                flexDirection : 'column',
            }}
        >
            {
                (prop.contentOption?.limitContentCount !== undefined
                    ?
                    prop.dataObject
                        .sort((x, y) => (prop.displayOption.sortObject(x) - prop.displayOption.sortObject(y)) * ((prop.displayOption.sortOption ?? 'ASC') === 'ASC' ? -1 : 1))
                        .slice(0, prop.contentOption.limitContentCount)
                    :
                    prop.dataObject
                        .sort((x, y) => (prop.displayOption.sortObject(x) - prop.displayOption.sortObject(y)) * ((prop.displayOption.sortOption ?? 'ASC') === 'ASC' ? -1 : 1))
                ).length === 0 &&
                <Box
                    sx={{
                        display : 'flex',
                        justifyContent : 'center',
                        alignItems : 'center',
                        minHeight : '100px',
                        width : '100%'
                    }}
                >
                    등록된 데이터가 없습니다.

                    </Box>
            }
            {
                (prop.contentOption?.limitContentCount !== undefined
                    ?
                    prop.dataObject
                        .sort((x, y) => (prop.displayOption.sortObject(x) - prop.displayOption.sortObject(y)) * ((prop.displayOption.sortOption ?? 'ASC') === 'ASC' ? -1 : 1))
                        .slice(0, prop.contentOption.limitContentCount)
                    :
                    prop.dataObject
                        .sort((x, y) => (prop.displayOption.sortObject(x) - prop.displayOption.sortObject(y)) * ((prop.displayOption.sortOption ?? 'ASC') === 'ASC' ? -1 : 1))
                )
                    .map(dataItem => (
                        <FaqContentSectionBoardItem
                            key={`${prop.displayOption.sortObject(dataItem)}-${ObjectUtils.RandomStrings(10)}`}
                            dataObject={dataItem}
                            displayOption={prop.displayOption}
                            contentOption={{
                                // contentItemWidth: `calc(100% / ${prop.contentOption?.displayContentLineItemCount ?? 1})`,
                                contentItemWidth : '100%',
                                imageStyle: prop.contentOption?.imageStyle
                            }}
                        />
                    ))
            }
        </Box>
    )
}


interface IFaqContentSectionBoardItem<T> {
    dataObject: T,
    displayOption: {
        displayImageURL: (item: T) => string[],
        displayTitle: (item: T) => string,
        displayDescription: (item: T) => string,
        onClick? : (item : T) => void
    },
    contentOption: {
        contentItemWidth: string,
        imageStyle?: CSSProperties
    }
}
export const FaqContentSectionBoardItem = <T extends any>(prop: IFaqContentSectionBoardItem<T>) => {

    const keyCode = ObjectUtils.RandomStrings(10);

    return (
        <Box
            className={`Keri-MainContentSectionBoardItem ${keyCode}`}
            sx={{
                width: prop.contentOption?.contentItemWidth ?? '100%',
                display: 'flex',
                justifyContent: 'start',
                overflow: 'hidden',
                p: 1,
                mt : 1,
                mb : 1,
                alignItems: 'center',
                cursor: 'pointer',
                ":hover": {
                    '& .title-typography': {
                        color: '#aaa'
                    }
                }
                
            }}
            onClick={() => prop.displayOption.onClick && prop.displayOption.onClick(prop.dataObject)}
        >
            <Box
                sx={{
                    minWidth: '80px',
                    width: '80px',
                    // height: '10rem',
                    overflow: 'hidden'
                }}
            >
                <Swiper
                    className="mySwiper"
                    speed={300}
                    loop
                    loopedSlides={1}
                    autoplay={{
                        delay: 1000,
                        disableOnInteraction: false
                    }}
                    allowTouchMove={false}
                    modules={[...(prop.displayOption.displayImageURL(prop.dataObject).length > 1 ? [Autoplay] : [])]}
                    style={{
                        width: '100%',
                        height: '100%'
                    }}
                >
                    {
                        (prop.displayOption.displayImageURL(prop.dataObject).length === 0 ? [`${URLStructure.Asset}/img/ict/images/logo_v.svg`] : prop.displayOption.displayImageURL(prop.dataObject)).map(imageURL => (
                            <SwiperSlide
                                key={ObjectUtils.RandomStrings(10)}
                                style={{
                                    width: '100%',
                                    height: '100%'
                                }}
                            >
                                <Box
                                    sx={{
                                        width: '100%',
                                        height: '100%',
                                        overflow: 'hidden',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    <img
                                        src={imageURL}
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: prop.displayOption.displayImageURL(prop.dataObject).length === 0 ? 'scale-down' : 'cover',
                                            ...prop.contentOption.imageStyle
                                        }}
                                    />
                                </Box>

                            </SwiperSlide>
                        ))
                    }

                </Swiper>
            </Box>
            <Box
                sx={{
                    width: 'calc(100% - 10rem)',
                    maxWidth: 'calc(100% - 10rem)',
                    // height : '10rem',
                    ml: 1
                }}
            >
                <Box
                    className="title-typography"
                    sx={{
                        width: '100%',
                        maxWidth: '100%',
                        overflow: 'hidden',
                        wordBreak: 'break-word',
                        textOverflow: 'ellipsis',
                        WebkitLineClamp: '2',
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        p: 0.5,
                        pt: 0,
                        lineHeight: '1.7rem',
                        maxHeight: '3.2rem',
                        fontWeight: '500',
                        fontSize: '1.2rem'
                        // boxSizing : 'content-box',
                    }}
                >
                    {prop.displayOption.displayTitle(prop.dataObject)}
                </Box>
                <Box
                    sx={{
                        width: '100%',
                        maxWidth: '100%',
                        overflow: 'hidden',
                        wordBreak: 'break-word',
                        textOverflow: 'ellipsis',
                        WebkitLineClamp: '2',
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        p: 0.5,
                        lineHeight: '1.6rem',
                        height: '3.2rem',
                        // boxSizing : 'content-box',
                        color: 'rgba(0,0,0,0.5)'
                    }}
                >
                    {prop.displayOption.displayDescription(prop.dataObject)}
                </Box>
            </Box>


        </Box>
    )
}

//#endregion