import { useEffect, useState } from "react";
import { IKeriStepPhase, StepLayout } from "@keri-portal/component/layout/StepLayout";
import { IPageProp } from "@keri-base/interface/common/IPageProp";
import LoginChecker from "@keri-base/components/common/LoginChecker";
import { IAccountRegister } from "@keri-portal/interfaces/process-object/account/IAccountRegister";
import KeriRegisterStep1Item from "@keri-portal/component/step-item/account-register/KeriRegisterStep1Item";
import KeriRegisterStep2Item from "@keri-portal/component/step-item/account-register/KeriRegisterStep2Item";
import KeriRegisterStep3Item from "@keri-portal/component/step-item/account-register/KeriRegisterStep3Item";
import KeriRegisterStep4Item from "@keri-portal/component/step-item/account-register/KeriRegisterStep4Item";
import KeriRegisterStep5Item from "@keri-portal/component/step-item/account-register/KeriRegisterStep5Item";
import KeriRegisterStepSearchCompanyItem from "@keri-portal/component/step-item/account-register/KeriRegisterStepSearchCompanyItem";
import { useLocation, useNavigate } from "react-router-dom";
import URLStructure from "@src/modules/structure/URLStructure";
import { Typography } from "@mui/material";




const RegisterViewPage = (prop: IPageProp) => {

    const [resultData, setResultData] = useState<IAccountRegister>({});
    const [checkAuth, setCheckAuth] = useState<boolean>(false);
    const navigate = useNavigate();
    const location = useLocation();


    const stepData: IKeriStepPhase<IAccountRegister>[] = [
        {
            idx: 1,
            title: '회원 가입 안내',
            tabComponent: (obj) => {
                return (
                    <KeriRegisterStep1Item
                        result={obj.result}
                        step={obj.step}
                        routeProp={prop}
                    />

                )
            }
        },
        {
            idx: 2,
            title: '정보 제공 동의',
            tabComponent: (obj) => {
                return (
                    <KeriRegisterStep2Item
                        result={obj.result}
                        step={obj.step}
                        routeProp={prop}
                    />
                )
            }
        },
        {
            idx: 3,
            title: '기업정보 검색',
            tabComponent: (obj) => {
                return (
                    <KeriRegisterStepSearchCompanyItem
                        result={obj.result}
                        step={obj.step}
                        routeProp={prop}
                    />
                )
            }
        },
        {
            idx: 4,
            title: '신규 기업 등록',
            tabComponent: (obj) => {
                return (
                    <KeriRegisterStep3Item
                        result={obj.result}
                        step={obj.step}
                        routeProp={prop}
                    />
                )
            }
        },
        {
            idx: 5,
            title: '개인 정보 입력',
            tabComponent: (obj) => {
                return (
                    <KeriRegisterStep4Item
                        result={obj.result}
                        step={obj.step}
                        routeProp={prop}
                    />
                )
            }
        },
        {
            idx: 6,
            title: '회원가입 완료',
            tabComponent: (obj) => {
                return (
                    <KeriRegisterStep5Item
                        result={obj.result}
                        step={obj.step}
                        routeProp={prop}
                    />
                )
            }
        },
    ]



    console.log('req-data', resultData);


    return (
        <LoginChecker
            onCheck={(state) => {
                if (state === 'LOGIN') {
                    alert('로그인된 계정이 있습니다. 메인으로 이동합니다.');
                    navigate(`${URLStructure.Main}`);
                }
                else {
                    setCheckAuth(true);
                }
            }}
        >
            {
                checkAuth &&
                <StepLayout
                    enableLogo
                    stepData={stepData}
                    result={{
                        data: resultData,
                        onChange: (item) => setResultData(item)
                    }}
                    customLogo={
                        <Typography
                            sx={{
                                fontSize: '24px',
                                fontWeight: 'bold',
                                p: 1,
                                borderBottom: '3px groove #86918585'
                            }}
                        >
                            회원가입
                        </Typography>
                    }
                />
            }

        </LoginChecker>
    )
}

export default RegisterViewPage;