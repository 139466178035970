import { ILoginUserVO } from '@keri-portal/interfaces/api/keri-api/object/ILoginUserVO';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import SessionStorageServ from '@ict-base/module/utils/SessionStorageServ';
import { IKeriAlert } from '@ictpeople/misc-core'




export const UserAlertReducer = createSlice({
    name: 'userAlert',
    initialState: {
        unreadTechServiceComment: []
    } as IKeriAlert,
    reducers: {
        setAlert: (state, action: PayloadAction<IKeriAlert | undefined>) => {
            return action.payload;
        },
        clearAlert: () => {
            return undefined
        }
    }
})


export const { setAlert, clearAlert } = UserAlertReducer.actions;
export default UserAlertReducer.reducer;