import { ObjectUtils } from "@ict-base/module/utils/ObjectUtils";
import { IUploadFileVO } from "@keri-portal/interfaces/api/keri-api/object/IUploadFileVO";
import { IUploadFile } from "@keri-portal/interfaces/control/IUploadFile";
import { CancelRounded, Info } from "@mui/icons-material";
import { Box, Button, Chip, InputAdornment, InputBaseProps, SxProps, TextField, TextFieldProps, Theme, Tooltip, Typography } from "@mui/material";
import { ChangeEvent, KeyboardEvent, MutableRefObject, useEffect, useState } from "react";
import ICTDropImageFile from "./ICTDropImageFile";
import KeriActionButton from "./KeriActionButton";
import { KeriCheckLabel } from "./KeriCheckBox";
import KeriEditor from "./KeriEditor";





interface IKeriGrid {
    children: React.ReactNode | React.ReactNode[] | undefined,
    sx?: SxProps<Theme>
}

export const KeriEditGrid = (prop: IKeriGrid) => {
    return (
        <Box
            className="Keri-EditGriid"
            sx={{
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'top',
                flexWrap: 'wrap',
                pt: 1,
                pb: 1,
                ...prop.sx
            }}
        >
            {prop.children}
        </Box>
    )
}




export const KeriEditGridEmptyText = (prop: { children: React.ReactNode, sx?: SxProps<Theme> }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                width: '100%',
                pt: 3,
                pb: 3,
                ...prop.sx
            }}
        >
            {prop.children}
        </Box>
    )
}




interface IKeriGridButton {
    label: string,
    onClick?: () => void,
    sx?: SxProps<Theme>
}

export const KeriEditGridButton = (prop: IKeriGridButton) => {
    return (
        <Box
            sx={{
                pb: 1,
                pr: 2,
                pl: 2,
                ...prop.sx
            }}
        >
            <Button
                variant="contained"
                sx={{ height: "100%" }}
                onClick={() => {
                    if (prop.onClick !== undefined) {
                        prop.onClick();
                    }
                }}
            >
                {prop.label}
            </Button>
        </Box>
    )
}










interface IKeriGridInputField {

    editDisabled?: boolean,
    title?: string,
    value?: string | undefined
    defaultValue?: string | undefined,
    tooltip?: {
        enable: boolean,
        message: string | undefined
    },
    props?: {
        width?: number | string,
        height?: number | string,
        titleBoxWidth?: number | string,
        titleAlign?: 'start' | 'end' | 'center',
        endAdornment?: React.ReactNode,
        inputPattern?: string,
        sx?: SxProps<Theme>,
        placeHolder?: string,
        multiline?: boolean
    }
    error?: {
        isError: boolean,
        message?: string
    },
    button?: {
        isEnable: boolean,
        label?: string,
        color?: "error" | "primary" | "secondary" | "info" | "success" | "warning" | undefined,
        clickDisabled?: boolean,
        onClick?: () => void,
        sx?: SxProps<Theme>
    },
    onChange?: (text: string, event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
    onBlur?: (text: string) => void,
    onKeyUp?: (e: KeyboardEvent<HTMLDivElement>) => void,
    type?: React.InputHTMLAttributes<unknown>['type'],
    name?: string | undefined,
    inputRef?: MutableRefObject<TextFieldProps | undefined>,
    required?: boolean,
    disableTitle?: boolean,
    inputProp? : InputBaseProps['inputProps']
}

const DEFAULT_LABEL_WIDTH = '160px'


export const KeriEditGridInputField = (prop: IKeriGridInputField) => {
    return (
        <Box
            className="Keri-EditGridInputField"
            sx={{
                // flex: `1 1 ${prop.props?.width ?? '100%'}`,
                width: prop.props?.width ?? '100%',
                mb: 1,
                pr: 2,
                pl: 2,
                ...prop.props?.sx
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                {
                    !prop.disableTitle &&
                    <Box
                        sx={{
                            // flex : `1 1 50%`,
                            textAlign: prop.props?.titleAlign ?? 'start',
                            minWidth: `${typeof (prop.props?.titleBoxWidth ?? DEFAULT_LABEL_WIDTH) === 'number' ? prop.props!.titleBoxWidth + 'px' : prop.props?.titleBoxWidth ?? DEFAULT_LABEL_WIDTH}`,
                            userSelect: 'none',
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}
                    >
                        <Typography variant="body1" sx={{ wordWrap: 'break-word', wordBreak: 'keep-all' }}>
                            {prop.title}{(prop.required ?? false) && (<span style={{ color: 'red' }}> *</span>)}
                        </Typography>
                        {
                            (prop.tooltip?.enable ?? false) &&
                            <Box sx={{ display: 'flex', alignItems: 'center', width: '50px', justifyContent: 'center' }}>
                                <Tooltip title={prop.tooltip?.message ?? ''} arrow placement="top" sx={{}}>
                                    <Info sx={{ width: '20px', cursor: 'pointer' }} />
                                </Tooltip>

                            </Box>
                        }

                    </Box>
                }


                <Box
                    sx={{
                        flex: `1 1 calc(
                            ${prop.disableTitle
                                ? `100%`
                                : `99% - ${typeof (prop.props?.titleBoxWidth ?? DEFAULT_LABEL_WIDTH) === 'number' ? prop.props!.titleBoxWidth + 'px' : prop.props?.titleBoxWidth ?? DEFAULT_LABEL_WIDTH}`
                            }
                            )`


                    }}
                >
                    <TextField
                        multiline={prop.props?.multiline ?? false}
                        name={prop.name}
                        type={prop.type ?? 'text'}
                        fullWidth
                        value={prop.value}
                        defaultValue={prop.defaultValue}
                        size="small"
                        disabled={(prop.editDisabled ?? false)}
                        placeholder={prop.props?.placeHolder}
                        InputProps={{
                            endAdornment:
                                (prop.props?.endAdornment !== undefined || (prop.button?.isEnable ?? false))
                                    ? <InputAdornment position={(prop.button?.isEnable ?? false) ? 'end' : 'start'}>
                                        {
                                            prop.props?.endAdornment !== undefined &&
                                            prop.props?.endAdornment
                                        }
                                        {
                                            (prop.button?.isEnable ?? false) &&
                                            <KeriActionButton
                                                label={prop.button?.label ?? ''}
                                                disabled={prop.button?.clickDisabled}
                                                variant="contained"
                                                color={prop.button?.color ?? 'primary'}
                                                sx={{
                                                    borderRadius: 1,
                                                    borderTopLeftRadius: '0',
                                                    borderBottomLeftRadius: '0',
                                                    ":disabled": {
                                                        border: '1px solid rgba(0, 0, 0, 0.12)'
                                                    },
                                                    ...(prop.props?.endAdornment !== undefined ? { ml: 1 } : {}),
                                                    ...prop.button?.sx
                                                }}
                                                onClick={() => {
                                                    prop.button?.onClick !== undefined && prop.button?.onClick();
                                                }}
                                            />
                                        }

                                    </InputAdornment>
                                    : undefined,
                            autoComplete: 'new-password',
                            sx: {
                                pr: 0,
                                minHeight: prop.props?.height,
                                alignItems: prop.props?.height === undefined ? undefined : 'start'
                            },
                        }}
                        onChange={(e) => {
                            if (prop.onChange !== undefined) {
                                prop.onChange(e.target.value, e);
                            }
                        }}
                        onBlur={(e) => {
                            if (prop.onBlur !== undefined) {
                                prop.onBlur(e.target.value);
                            }
                        }}
                        error={prop.error?.isError ?? false}
                        autoComplete='off'
                        inputRef={prop.inputRef}
                        sx={{
                            ":focus": {
                                outline: 'none'
                            },
                            '&.Mui-focused': {
                                outline: 'none'
                            }
                        }}
                        onKeyUp={prop.onKeyUp}
                        inputProps={{
                            ...prop.inputProp
                        }}
                    />
                </Box>
            </Box>
            {
                (prop.error?.isError ?? false) &&
                <Box
                    sx={{
                        textAlign: 'start',
                        pl: prop.disableTitle
                            ? '10px'
                            : `calc(${typeof (prop.props?.titleBoxWidth ?? DEFAULT_LABEL_WIDTH) === 'number' ? prop.props!.titleBoxWidth + 'px' : prop.props?.titleBoxWidth ?? DEFAULT_LABEL_WIDTH} + 10px)`
                    }}
                >
                    <Typography variant="body2" color={'#d32f2f'}>
                        {prop.error?.message}
                    </Typography>
                </Box>
            }

        </Box>
    )
}



interface IKeriEditGridImageField {

    editDisabled?: boolean,
    title?: string,
    value?: string | undefined,
    tooltip?: {
        enable: boolean,
        message: string | undefined
    },
    props?: {
        width?: number | string,
        titleBoxWidth?: number | string,
        titleAlign?: 'start' | 'end' | 'center',
        sx?: SxProps<Theme>
    }
    error?: {
        isError: boolean,
        message?: string
    },
    itemProps?: {
        category?: string,
        prefix?: string
    }
    required?: boolean,
    onItemChanged?: (items: IUploadFile<IUploadFileVO>[]) => void,
    limitFileCount?: number
}


export const KeriEditGridImageField = (prop: IKeriEditGridImageField) => {
    return (
        <Box
            className="Keri-EditGridImageField"
            sx={{
                // flex: `1 1 ${prop.props?.width ?? '100%'}`,
                width: prop.props?.width ?? '100%',
                mb: 1,
                pr: 2,
                pl: 2,
                ...prop.props?.sx
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <Box
                    sx={{
                        // flex : `1 1 50%`,
                        textAlign: prop.props?.titleAlign ?? 'start',
                        width: `${typeof (prop.props?.titleBoxWidth ?? DEFAULT_LABEL_WIDTH) === 'number' ? prop.props!.titleBoxWidth + 'px' : prop.props?.titleBoxWidth ?? DEFAULT_LABEL_WIDTH}`,
                        userSelect: 'none',
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}
                >
                    <Typography variant="body1" sx={{ wordWrap: 'break-word', pr: 1 }}>
                        {prop.title}{(prop.required ?? false) && (<span style={{ color: 'red' }}> *</span>)}
                    </Typography>
                    {
                        (prop.tooltip?.enable ?? false) &&
                        <Box sx={{ display: 'flex', alignItems: 'center', width: '50px', justifyContent: 'center' }}>
                            <Tooltip title={prop.tooltip?.message ?? ''} arrow placement="top" sx={{}}>
                                <Info sx={{ width: '20px', cursor: 'pointer' }} />
                            </Tooltip>

                        </Box>
                    }

                </Box>

                <Box
                    sx={{
                        flex: `1 1 calc(99% - ${typeof (prop.props?.titleBoxWidth ?? DEFAULT_LABEL_WIDTH) === 'number' ? prop.props!.titleBoxWidth + 'px' : prop.props?.titleBoxWidth ?? DEFAULT_LABEL_WIDTH})`
                    }}
                >
                    <ICTDropImageFile
                        value={[]}
                        onChange={(item) => prop.onItemChanged && prop.onItemChanged(item)}
                        itemProps={{
                            category: prop.itemProps?.category,
                            limitCount: prop.limitFileCount,
                            prefix: prop.itemProps?.prefix,
                        }}
                    />
                </Box>
            </Box>
            {
                (prop.error?.isError ?? false) &&
                <Box
                    sx={{
                        textAlign: 'start',
                        pl: `calc(${typeof (prop.props?.titleBoxWidth ?? DEFAULT_LABEL_WIDTH) === 'number' ? prop.props!.titleBoxWidth + 'px' : prop.props?.titleBoxWidth ?? DEFAULT_LABEL_WIDTH} + 10px)`
                    }}
                >
                    <Typography variant="body2" color={'#d32f2f'}>
                        {prop.error?.message}
                    </Typography>
                </Box>
            }

        </Box>
    )
}













interface IKeriEditGridCheckBoxField<T> {

    editDisabled?: boolean,
    title?: string,
    defaultValue?: T,
    data: T[],
    tooltip?: {
        enable: boolean,
        message: string | undefined
    },
    props?: {
        width?: number | string,
        titleBoxWidth?: number | string,
        titleAlign?: 'start' | 'end' | 'center',
        sx?: SxProps<Theme>,
        itemAlign?: 'start' | 'end' | 'center'
    }
    error?: {
        isError: boolean,
        message?: string
    },
    itemProps?: {
        sx?: SxProps<Theme>
    }
    required?: boolean,
    onItemChanged?: (items: T[]) => void,
    displayValue: (item: T) => string,
    displayKey: (item: T) => string,
    multiChecked?: boolean,
    nonDisableItemChecked?: boolean,
    value?: T[]
}


export const KeriEditGridCheckBoxField = <T extends any>(prop: IKeriEditGridCheckBoxField<T>) => {


    return (
        <Box
            sx={{
                // flex: `1 1 ${prop.props?.width ?? '100%'}`,
                width: prop.props?.width ?? '100%',
                mb: 1,
                pr: 2,
                pl: 2,
                ...prop.props?.sx
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <Box
                    sx={{
                        // flex : `1 1 50%`,
                        textAlign: prop.props?.titleAlign ?? 'start',
                        width: `${typeof (prop.props?.titleBoxWidth ?? DEFAULT_LABEL_WIDTH) === 'number' ? prop.props!.titleBoxWidth + 'px' : prop.props?.titleBoxWidth ?? DEFAULT_LABEL_WIDTH}`,
                        userSelect: 'none',
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}
                >
                    <Typography variant="body1" sx={{ wordWrap: 'break-word', pr: 1 }}>
                        {prop.title}{(prop.required ?? false) && (<span style={{ color: 'red' }}> *</span>)}
                    </Typography>
                    {
                        (prop.tooltip?.enable ?? false) &&
                        <Box sx={{ display: 'flex', alignItems: 'center', width: '50px', justifyContent: 'center' }}>
                            <Tooltip title={prop.tooltip?.message ?? ''} arrow placement="top" sx={{}}>
                                <Info sx={{ width: '20px', cursor: 'pointer' }} />
                            </Tooltip>

                        </Box>
                    }

                </Box>

                <Box
                    sx={{
                        flex: `1 1 calc(99% - ${typeof (prop.props?.titleBoxWidth ?? DEFAULT_LABEL_WIDTH) === 'number' ? prop.props!.titleBoxWidth + 'px' : prop.props?.titleBoxWidth ?? DEFAULT_LABEL_WIDTH})`
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: prop.props?.itemAlign ?? 'start',
                            flexWrap: 'wrap'
                        }}
                    >
                        {
                            prop.data.map((x, idx) => (
                                <KeriCheckLabel
                                    key={idx}
                                    disabled={prop.editDisabled}
                                    label={prop.displayValue(x)}
                                    checked={
                                        prop.value === undefined
                                            ? idx === 0
                                            : prop.value.filter(checkValue => prop.displayKey(checkValue) === prop.displayKey(x)).length !== 0

                                    }
                                    // checked={true}
                                    onChange={(val, e) => {
                                        if (prop.multiChecked) {
                                            if ((prop.value ?? []).filter(item => prop.displayKey(item) === prop.displayKey(x)).length === 0) {
                                                prop.onItemChanged && prop.onItemChanged([
                                                    ...(prop.value ?? []).filter(item => prop.displayKey(item) !== prop.displayKey(x)),
                                                    x
                                                ]);
                                            }
                                            else {
                                                if (!(prop.nonDisableItemChecked ?? false)) {
                                                    prop.onItemChanged && prop.onItemChanged([
                                                        ...(prop.value ?? []).filter(item => prop.displayKey(item) !== prop.displayKey(x))
                                                    ]);
                                                }

                                            }
                                        }
                                        else {
                                            if ((prop.value ?? []).filter(item => prop.displayKey(item) === prop.displayKey(x)).length === 0) {
                                                prop.onItemChanged && prop.onItemChanged([x]);
                                            }
                                            else {
                                                if (!(prop.nonDisableItemChecked ?? false)) {
                                                    prop.onItemChanged && prop.onItemChanged([]);
                                                }

                                            }
                                        }
                                    }}
                                    sx={{
                                        ...((prop.props?.itemAlign ?? 'start') === 'start' ? { pr: 2 } : { pl: 2 }),
                                        pt: 1,
                                        pb: 1,
                                        ...prop.itemProps?.sx

                                    }}
                                />
                            ))
                        }
                    </Box>


                </Box>
            </Box>
            {
                (prop.error?.isError ?? false) &&
                <Box
                    sx={{
                        textAlign: 'start',
                        pl: `calc(${typeof (prop.props?.titleBoxWidth ?? DEFAULT_LABEL_WIDTH) === 'number' ? prop.props!.titleBoxWidth + 'px' : prop.props?.titleBoxWidth ?? DEFAULT_LABEL_WIDTH} + 10px)`
                    }}
                >
                    <Typography variant="body2" color={'#d32f2f'}>
                        {prop.error?.message}
                    </Typography>
                </Box>
            }

        </Box>
    )
}










interface IKeriEditGridWebEditorField {

    editDisabled?: boolean,
    title?: string,
    value?: string | undefined
    defaultValue?: string | undefined,
    tooltip?: {
        enable: boolean,
        message: string | undefined
    },
    props?: {
        width?: number | string,
        titleBoxWidth?: number | string,
        titleAlign?: 'start' | 'end' | 'center',
        endAdornment?: React.ReactNode,
        inputPattern?: string,
        sx?: SxProps<Theme>,
        placeHolder?: string,
    }
    error?: {
        isError: boolean,
        message?: string
    },
    button?: {
        isEnable: boolean,
        label?: string,
        color?: "error" | "primary" | "secondary" | "info" | "success" | "warning" | undefined,
        clickDisabled?: boolean,
        onClick?: () => void,
        sx?: SxProps<Theme>
    },
    onChange?: (text: string, event?: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
    onBlur?: (text: string) => void,
    onLoadEditor?: (e: any) => void,
    onKeyUp?: (e: KeyboardEvent<HTMLDivElement>) => void,
    type?: React.InputHTMLAttributes<unknown>['type'],
    name?: string | undefined,
    required?: boolean,
    disableTitle?: boolean
}



export const KeriEditGridWebEditorField = (prop: IKeriEditGridWebEditorField) => {

    const [editorContent, setEditorContent] = useState<string>('');

    return (
        <Box
            className="Keri-EditGridWebEditorField"
            sx={{
                // flex: `1 1 ${prop.props?.width ?? '100%'}`,
                width: prop.props?.width ?? '100%',
                mb: 1,
                // pr: 2,
                // pl: 2,
                ...prop.props?.sx
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                {
                    !prop.disableTitle &&
                    <Box
                        sx={{
                            // flex : `1 1 50%`,
                            textAlign: prop.props?.titleAlign ?? 'start',
                            width: `${typeof (prop.props?.titleBoxWidth ?? DEFAULT_LABEL_WIDTH) === 'number' ? prop.props!.titleBoxWidth + 'px' : prop.props?.titleBoxWidth ?? DEFAULT_LABEL_WIDTH}`,
                            userSelect: 'none',
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}
                    >
                        <Typography variant="body1" sx={{ wordWrap: 'break-word', wordBreak: 'keep-all', pr: 1 }}>
                            {prop.title}{(prop.required ?? false) && (<span style={{ color: 'red' }}> *</span>)}
                        </Typography>
                        {
                            (prop.tooltip?.enable ?? false) &&
                            <Box sx={{ display: 'flex', alignItems: 'center', width: '50px', justifyContent: 'center' }}>
                                <Tooltip title={prop.tooltip?.message ?? ''} arrow placement="top" sx={{}}>
                                    <Info sx={{ width: '20px', cursor: 'pointer' }} />
                                </Tooltip>

                            </Box>
                        }

                    </Box>
                }


                <Box
                    sx={{
                        flex: `1 1 calc(
                            ${prop.disableTitle
                                ? `100%`
                                : `99% - ${typeof (prop.props?.titleBoxWidth ?? DEFAULT_LABEL_WIDTH) === 'number' ? prop.props!.titleBoxWidth + 'px' : prop.props?.titleBoxWidth ?? DEFAULT_LABEL_WIDTH}`
                            }
                            )`,
                        width: `calc(99% - ${typeof (prop.props?.titleBoxWidth ?? DEFAULT_LABEL_WIDTH) === 'number' ? prop.props!.titleBoxWidth + 'px' : prop.props?.titleBoxWidth ?? DEFAULT_LABEL_WIDTH})`
                    }}
                >
                    <KeriEditor
                        onChange={(newValue) => {
                            prop.onChange && prop.onChange(newValue);
                        }}
                        defaultValue={prop.defaultValue}
                        onLoad={(e) => prop.onLoadEditor && prop.onLoadEditor(e)}
                    />
                </Box>
            </Box>
            {
                (prop.error?.isError ?? false) &&
                <Box
                    sx={{
                        textAlign: 'start',
                        pl: prop.disableTitle
                            ? '10px'
                            : `calc(${typeof (prop.props?.titleBoxWidth ?? DEFAULT_LABEL_WIDTH) === 'number' ? prop.props!.titleBoxWidth + 'px' : prop.props?.titleBoxWidth ?? DEFAULT_LABEL_WIDTH} + 10px)`
                    }}
                >
                    <Typography variant="body2" color={'#d32f2f'}>
                        {prop.error?.message}
                    </Typography>
                </Box>
            }

        </Box>
    )
}

























interface IKeriGridMultiItemField<T> {

    title?: string,
    value: T[],
    displayValue: (item: T) => string,
    tooltip?: {
        enable: boolean,
        message: string | undefined
    },
    props?: {
        width?: number | string,
        titleBoxWidth?: number | string,
        titleAlign?: 'start' | 'end' | 'center',
        endAdornment?: React.ReactNode,
        inputPattern?: string,
        sx?: SxProps<Theme>,
        placeHolder?: string,
        multiline?: boolean
    }
    error?: {
        isError: boolean,
        message?: string
    },
    button?: {
        isEnable: boolean,
        label?: string,
        color?: "error" | "primary" | "secondary" | "info" | "success" | "warning" | undefined,
        clickDisabled?: boolean,
        onClick?: () => void,
        sx?: SxProps<Theme>
    },
    type?: React.InputHTMLAttributes<unknown>['type'],
    name?: string | undefined,
    inputRef?: MutableRefObject<TextFieldProps | undefined>,
    required?: boolean,
    disableTitle?: boolean,
    onDelete: (item: T) => void
}


export const KeriEditGridMultiItemField = <T extends any>(prop: IKeriGridMultiItemField<T>) => {
    return (
        <Box
            className="Keri-EditGridChipField"
            sx={{
                width: prop.props?.width ?? '100%',
                mb: 1,
                pr: 2,
                pl: 2,
                ...prop.props?.sx
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                {
                    !prop.disableTitle &&
                    <Box
                        sx={{
                            // flex : `1 1 50%`,
                            textAlign: prop.props?.titleAlign ?? 'start',
                            width: `${typeof (prop.props?.titleBoxWidth ?? DEFAULT_LABEL_WIDTH) === 'number' ? prop.props!.titleBoxWidth + 'px' : prop.props?.titleBoxWidth ?? DEFAULT_LABEL_WIDTH}`,
                            userSelect: 'none',
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}
                    >
                        <Typography variant="body1" sx={{ wordWrap: 'break-word', wordBreak: 'keep-all', pr: 1 }}>
                            {prop.title}{(prop.required ?? false) && (<span style={{ color: 'red' }}> *</span>)}
                        </Typography>
                        {
                            (prop.tooltip?.enable ?? false) &&
                            <Box sx={{ display: 'flex', alignItems: 'center', width: '50px', justifyContent: 'center' }}>
                                <Tooltip title={prop.tooltip?.message ?? ''} arrow placement="top" sx={{}}>
                                    <Info sx={{ width: '20px', cursor: 'pointer' }} />
                                </Tooltip>

                            </Box>
                        }

                    </Box>
                }


                <Box
                    sx={{
                        flex: `1 1 calc(
                            ${prop.disableTitle
                                ? `100%`
                                : `99% - ${typeof (prop.props?.titleBoxWidth ?? DEFAULT_LABEL_WIDTH) === 'number' ? prop.props!.titleBoxWidth + 'px' : prop.props?.titleBoxWidth ?? DEFAULT_LABEL_WIDTH}`
                            }
                            )`


                    }}
                >
                    <TextField
                        multiline={prop.props?.multiline ?? false}
                        name={prop.name}
                        type={prop.type ?? 'text'}
                        fullWidth
                        size="small"
                        disabled
                        placeholder={prop.props?.placeHolder}
                        InputProps={{
                            startAdornment:
                                <InputAdornment position="start" sx={{ p: 1, pr: 0, pl: 0 }}>
                                    {
                                        (prop.value ?? []).map((item, index) => (
                                            <Chip
                                                key={index}
                                                tabIndex={-1}
                                                label={prop.displayValue(item)}
                                                onDelete={() => prop.onDelete(item)}
                                                sx={{
                                                    mr: 1
                                                }}
                                            />
                                        ))
                                    }
                                </InputAdornment>
                            ,
                            endAdornment:
                                (prop.props?.endAdornment !== undefined || (prop.button?.isEnable ?? false))
                                    ? <InputAdornment
                                        position={(prop.button?.isEnable ?? false) ? 'end' : 'start'}
                                        sx={{
                                        }}
                                    >
                                        {
                                            prop.props?.endAdornment !== undefined &&
                                            prop.props?.endAdornment
                                        }
                                        {
                                            (prop.button?.isEnable ?? false) &&
                                            <KeriActionButton
                                                label={prop.button?.label ?? ''}
                                                disabled={prop.button?.clickDisabled}
                                                variant="contained"
                                                color={prop.button?.color ?? 'primary'}
                                                sx={{
                                                    borderRadius: 1,
                                                    borderTopLeftRadius: '0',
                                                    borderBottomLeftRadius: '0',
                                                    ":disabled": {
                                                        border: '1px solid rgba(0, 0, 0, 0.12)'
                                                    },
                                                    ...(prop.props?.endAdornment !== undefined ? { ml: 1 } : {}),
                                                    ...prop.button?.sx
                                                }}
                                                onClick={() => {
                                                    prop.button?.onClick !== undefined && prop.button?.onClick();
                                                }}
                                            />
                                        }

                                    </InputAdornment>
                                    : undefined,
                            autoComplete: 'new-password',
                            sx: {
                                pr: 0,
                            },
                        }}
                        error={prop.error?.isError ?? false}
                        autoComplete='off'
                        inputRef={prop.inputRef}
                        sx={{
                            ":focus": {
                                outline: 'none'
                            },
                            '&.Mui-focused': {
                                outline: 'none'
                            }
                        }}
                    />
                </Box>
            </Box>
            {
                (prop.error?.isError ?? false) &&
                <Box
                    sx={{
                        textAlign: 'start',
                        pl: prop.disableTitle
                            ? '10px'
                            : `calc(${typeof (prop.props?.titleBoxWidth ?? DEFAULT_LABEL_WIDTH) === 'number' ? prop.props!.titleBoxWidth + 'px' : prop.props?.titleBoxWidth ?? DEFAULT_LABEL_WIDTH} + 10px)`
                    }}
                >
                    <Typography variant="body2" color={'#d32f2f'}>
                        {prop.error?.message}
                    </Typography>
                </Box>
            }

        </Box>
    )
}

interface IKeriEditGridChipField<T> {
    fieldTitle?: string,
    value: T[],
    labelProps?: {
        sx?: SxProps<Theme>,
        required?: boolean,
        tooltip?: {
            enable?: boolean,
            message?: string
        }
    },
    elementProps?: {
        sx?: SxProps<Theme>,
        display?: {
            displayChipLable: (item: T) => string
        },
        onDelete?: (item: T) => void,
        disabledDelete?: boolean
    },
    plugins?: {
        button?: {
            enable?: boolean,
            label?: string,
            onClick?: () => void
        }
    }
    error?: {
        isError: boolean,
        message?: string
    },
}

export const KeriEditGridChipField = <T extends any>(prop: IKeriEditGridChipField<T>) => {
    return (
        <KeriEditGridBaseField
            controlName="EditGridChipField"
            elementProps={{
                sx: prop.elementProps?.sx
            }}
            fieldLabelProps={{
                required: prop.labelProps?.required,
                tooltip: prop.labelProps?.tooltip,
                sx: prop.labelProps?.sx
            }}
            error={prop.error}
            fieldTitle={prop.fieldTitle}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'start',
                    border: `1px solid ${(prop.error?.isError ?? false) ? '#d32f2f' : 'rgba(0, 0, 0, 0.23)'}`,
                    borderRadius: '4px',

                    overflow: 'hidden',
                    minHeight: '48px'
                }}
            >
                <Box
                    sx={{
                        p: 0.5,
                        display: 'flex',
                        justifyContent: 'start',
                        alignItems: 'center',
                        flexWrap: 'wrap'
                    }}
                >
                    {
                        prop.value.map(x => (
                            <Chip
                                key={ObjectUtils.RandomStrings(10)}
                                sx={{
                                    m: 0.5
                                }}
                                label={prop.elementProps?.display?.displayChipLable(x)}
                                deleteIcon={!(prop.elementProps?.disabledDelete ?? false) ? <CancelRounded /> : undefined}
                                onDelete={(prop.elementProps?.disabledDelete ?? false) ? undefined : () => prop.elementProps?.onDelete && prop.elementProps.onDelete(x)}
                            />
                        ))
                    }

                </Box>
                {
                    (prop.plugins?.button?.enable ?? false) &&
                    <Box
                        sx={{
                            // backgroundColor: '#25476a',
                            minWidth: '64px',
                            height: '100%',
                            minHeight: '48px'
                        }}
                    >
                        <KeriActionButton
                            label={prop.plugins?.button?.label ?? ''}
                            variant='contained'
                            color="primary"
                            onClick={() => prop.plugins?.button?.onClick && prop.plugins?.button?.onClick()}
                            sx={{
                                m: 0,
                                borderRadius: 0,
                                height: '48px',
                                ":hover": {
                                    boxShadow: 'none'
                                }
                            }}
                        />

                    </Box>
                }

            </Box>
        </KeriEditGridBaseField>
    )
}

interface IKeriEditGridBaseField {
    controlName: string,
    fieldTitle?: string,
    elementProps?: {
        sx?: SxProps<Theme>
    },
    error?: {
        isError: boolean,
        message?: string
    },
    fieldLabelProps?: {
        sx?: SxProps<Theme>,
        required?: boolean,
        tooltip?: {
            enable?: boolean,
            message?: string
        }
    }

    children?: React.ReactNode[] | React.ReactNode
}

const KeriEditGridBaseField = (prop: IKeriEditGridBaseField) => {
    return (
        <Box
            className={`KERI-${prop.controlName}`}
            sx={{
                width: '100%',
                mb: 1,
                pr: 2,
                pl: 2,
                ...prop.elementProps?.sx
            }}
        >
            {/* FieldBox */}
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                {/* Field label */}
                <Box
                    sx={{
                        // flex : `1 1 50%`,
                        textAlign: 'start',
                        width: DEFAULT_LABEL_WIDTH,
                        userSelect: 'none',
                        display: 'flex',
                        justifyContent: 'space-between',
                        ...prop.fieldLabelProps?.sx
                    }}
                >
                    <Typography variant="body1" sx={{ wordWrap: 'break-word', wordBreak: 'keep-all', pr: 1 }}>
                        {prop.fieldTitle}{(prop.fieldLabelProps?.required ?? false) && (<span style={{ color: 'red' }}> *</span>)}
                    </Typography>
                    {
                        (prop.fieldLabelProps?.tooltip?.enable ?? false) &&
                        <Box sx={{ display: 'flex', alignItems: 'center', width: '50px', justifyContent: 'center' }}>
                            <Tooltip title={prop.fieldLabelProps?.tooltip?.message ?? ''} arrow placement="top" sx={{}}>
                                <Info sx={{ width: '20px', cursor: 'pointer' }} />
                            </Tooltip>

                        </Box>
                    }

                </Box>

                {/* Input Box */}
                <Box
                    sx={{
                        width: '100%',
                        maxWidth: `calc(100% - ${DEFAULT_LABEL_WIDTH})`,
                        minHeight: '48px'
                    }}
                >
                    {prop.children}
                </Box>
            </Box>

            {/* ErrorBox */}
            {
                (prop.error?.isError ?? false) &&
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Box
                        sx={{
                            // flex : `1 1 50%`,
                            width: DEFAULT_LABEL_WIDTH,
                            ...prop.fieldLabelProps?.sx
                        }}
                    >

                    </Box>
                    <Box
                        sx={{
                            width: '100%',
                            maxWidth: `calc(100% - ${DEFAULT_LABEL_WIDTH})`,
                            p: 1,
                            pt: 0.5,
                            pb: 0
                        }}
                    >
                        <Typography variant="body2" color={'#d32f2f'}>
                            {prop.error?.message}
                        </Typography>
                    </Box>
                    {/* <Box
                        sx={{
                            textAlign: 'start',
                            pl: prop.disableTitle
                                ? '10px'
                                : `calc(${typeof (prop.props?.titleBoxWidth ?? DEFAULT_LABEL_WIDTH) === 'number' ? prop.props!.titleBoxWidth + 'px' : prop.props?.titleBoxWidth ?? DEFAULT_LABEL_WIDTH} + 10px)`
                        }}
                    >
                        <Typography variant="body2" color={'#d32f2f'}>
                            {prop.error?.message}
                        </Typography>
                    </Box> */}
                </Box>

            }
        </Box>
    )
}