import { Add } from "@mui/icons-material";
import { useEffect, useState } from "react";
import KeriActionButton from "@ict-base/components/control/KeriActionButton";
import { KeriEditGrid } from "@ict-base/components/control/KeriEditGrid";
import { KeriFileTable, KeriFileTableItem } from "@ict-base/components/control/KeriFileTable";
import { IKeriStepShareProps, KeriStepItemLayout, KeriStepItemContent } from "@keri-portal/component/layout/StepLayout";
import { IAccountRegisterCompany, IAccountRegister } from "@keri-portal/interfaces/process-object/account/IAccountRegister";
import AccountRegisterCommonValidationCheck from "@keri-portal/module/method/validation-check/account-register/AccountRegisterCommonValidationCheck";
import { IUploadFile } from "@keri-portal/interfaces/control/IUploadFile";
import { IUploadFileVO } from "@keri-portal/interfaces/api/keri-api/object/IUploadFileVO";
import RequestKeriAPI from "@keri-portal/module/method/api-connect/RequestKeriAPI";
import CompanyInputField from "@keri-portal/component/input-field/CompanyInputField";
import KeriBaseConfig from "@keri-base/config/KeriBaseConfig";
import { ICompanyInfoError } from "@keri-portal/interfaces/process-object/account/register/ICompanyInfoError";


const requireFileData: IUploadFile<IUploadFileVO>[] = [
    {
        Index: 1,
        Label: '사업자 등록증 사본',
        Category: 'CompanyAccount',
        Prefix: 'COM_',
        Required: true
    },
    // {
    //     Index: 2,
    //     Label: '법인 통장 사본',
    //     Category: 'CompanyAccount',
    //     Prefix: 'COM_',
    //     Required: true
    // }
]



const KeriRegisterStep3Item = (prop: IKeriStepShareProps<IAccountRegister>) => {



    const [formData, setFormData] = useState<IAccountRegisterCompany>({
        CompanyFiles: [...requireFileData],
        ...prop.result.data.NewCompanyInfoData
    });
    const [errorData, setErrorData] = useState<ICompanyInfoError>({});




    const addEmptyFileItem = () => {
        setFormData({
            ...formData,
            CompanyFiles: [
                ...(formData.CompanyFiles ?? []),
                {
                    Index: formData.CompanyFiles ?? [].length !== 0 ? formData.CompanyFiles!.sort((x, y) => y.Index - x.Index)[0].Index + 1 : 1,
                    Label: '참고자료',
                    Category: 'CompanyAccount',
                    Prefix: 'COM_',
                }
            ]
        })
    }


    const validationCheck = async (): Promise<boolean> => {

        prop.routeProp.EventFunction.EnableBackDrop(true)
        let isError: boolean = false;

        const validationMethod = await AccountRegisterCommonValidationCheck.NewCompany(formData);

        if (!validationMethod.state) {
            isError = !validationMethod.state;
            setErrorData(validationMethod.errorData);
        }
        else {
           
        }

        prop.routeProp.EventFunction.EnableBackDrop(false)
        return !isError;
    }

    useEffect(() => {
        // return setFormData({CompanyFiles: requireFileData});
    },[])

    return (
        <KeriStepItemLayout
            {...prop}
            validationCheck={validationCheck}
            page={{
                moveNext: (setup, validationCheck) => {
                    if (validationCheck) {
                        prop.routeProp.EventFunction.EnableBackDrop(true);
                        RequestKeriAPI.RegisterAccount.Company({
                            ...prop.result.data,
                            NewCompanyInfoData: formData
                        }).then(res => {
                            if(res.state){
                          
                                prop.result.onChange({
                                    ...prop.result.data,
                                    ExistCompanyInfoData: {
                                        ...formData,
                                        CompanyID : res.companyID
                                    },
                                    NewCompanyInfoData: undefined,
                                })
                                setup(prop.step.activeStep + 1);

                            }
                            else{
                                alert(res.message);
                            }
                        })
                        .catch(err => {
                            alert(err.message);
                        })
                        .finally(() => {
                            prop.routeProp.EventFunction.EnableBackDrop(false);
                        })
                    }
                },
                movePrevious: (setup) => {
                    prop.result.onChange({
                        ...prop.result.data,
                        NewCompanyInfoData: formData
                    })
                    setup(prop.step.activeStep - 1);
                }
            }}
        >
            <KeriStepItemContent title='기업정보 입력'>
                <KeriEditGrid>
                    <CompanyInputField
                        editMode="Edit"
                        dataObject={{
                            data: formData,
                            onChange: (item) => setFormData(item)
                        }}
                        error={{
                            data: errorData,
                            onChange: (item) => setErrorData(item)
                        }}
                    />
                </KeriEditGrid>
            </KeriStepItemContent>
            <KeriStepItemContent title='제출 자료'
                endAdornment={
                    <KeriActionButton
                        label="참고자료 추가"
                        variant="outlined"
                        color="primary"
                        sx={{
                            m: 0,
                            // p: 0
                        }}
                        icon={<Add />}
                        onClick={() => addEmptyFileItem()}
                    />
                }
            >
                <KeriFileTable
                    error={{
                        isError: errorData.CompanyFiles !== undefined,
                        message: errorData.CompanyFiles
                    }}
                    denyFileExtension={KeriBaseConfig.DenyFileExtension}
                    fileMode="Upload"
                >
                    {

                        (formData.CompanyFiles ?? []).sort((x, y) => x.Index - y.Index).map((item, idx) => (
                            <KeriFileTableItem
                                fileObject={item}
                                fileMode='Upload'
                                displayObject={{
                                    displayTitle: (item) => item.Label,
                                    displayFileName: (item) => item.UploadedFile === undefined ? item.OriginFile?.name : item.UploadedFile.orignlFileNm,
                                    displayDeleteRow: (item) => !(item.Required ?? false)
                                }}
                                uploadProp={{
                                    denyFileExtension: KeriBaseConfig.DenyFileExtension
                                }}
                                event={{
                                    onFileChange: (changeItem) => {
                                        setFormData({
                                            ...formData,
                                            CompanyFiles: [
                                                ...(formData.CompanyFiles ?? []).filter(reqItem => reqItem.Index !== changeItem.Index),
                                                changeItem
                                            ]
                                        })


                                        if (errorData.CompanyFiles !== undefined) {
                                            setErrorData({
                                                CompanyFiles: undefined
                                            })
                                        }
                                    },
                                    onDeleteUploadFile: async (changeItem) => {
                                        setFormData({
                                            ...formData,
                                            CompanyFiles: [
                                                ...(formData.CompanyFiles ?? []).filter(reqItem => reqItem.Index !== changeItem.Index),
                                                {
                                                    ...changeItem,
                                                    UploadedFile: undefined,
                                                    OriginFile: undefined
                                                }
                                            ]
                                        })
                                        const apiRequest = await RequestKeriAPI.File.RemoveUploadTempFile(changeItem);

                                    },
                                    onDeleteRow: async (deleteItem) => {
                                        setFormData({
                                            ...formData,
                                            CompanyFiles: [
                                                ...(formData.CompanyFiles ?? []).filter(reqItem => reqItem.Index !== deleteItem.Index),
                                            ]
                                        })
                                        const apiRequest = await RequestKeriAPI.File.RemoveUploadTempFile(deleteItem);
                                    }
                                }}
                            />

                        ))
                    }

                </KeriFileTable>
            </KeriStepItemContent>





        </KeriStepItemLayout>
    )
}

export default KeriRegisterStep3Item;