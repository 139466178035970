import { ObjectUtils } from "@ict-base/module/utils/ObjectUtils";
import { Box, Container, Divider, Typography } from "@mui/material"
import React from "react";

interface IInfoLayoutPage {
    title?: string
    children: React.ReactNode | React.ReactNode[] | undefined,
    endAdornment?: React.ReactNode | undefined,
    showChild?: boolean
}

/**
 * 기본 Content Page Layout
 * 
 * @param prop 
 * @returns 
 */
const InfoLayoutPage = (prop: IInfoLayoutPage) => {
    return (
        <Box>
            <Container
                sx={{
                    maxWidth: '1024px',
                    pt: 2,
                    pb: 2,
                    mt: 3
                }}
            >
                {
                    !ObjectUtils.IsNullOrEmpty(prop.title) &&
                    <React.Fragment>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }}
                        >
                            <Typography variant="h5">
                                {prop.title}
                            </Typography>
                            <Box>
                                {prop.endAdornment}
                            </Box>
                        </Box>
                        <Divider sx={{ mt: 1, mb: 2 }} />
                    </React.Fragment>
                }
                {(prop.showChild ?? true) && prop.children}
            </Container>
        </Box>
    )
}

export default InfoLayoutPage;