import { ObjectUtils } from "@ict-base/module/utils/ObjectUtils";
import RequestKeriAPI from "@keri-portal/module/method/api-connect/RequestKeriAPI";
import { Box, SxProps, Theme, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import ICTContainerPopup from "./ICTContainerPopup";
import KeriActionButton from "./KeriActionButton";
import KeriCheckBox from "./KeriCheckBox";

// interface IICTAgreeGroup {
//     containerProps?: {
//         sx?: SxProps<Theme>
//     },
//     data: IAgreeProp[]
// }

// interface IAgreeProp {
//     idx : number,
//     state?: boolean,
//     title: string,
//     htmlURL?: string,
//     isCheck?: boolean
// }

interface IICTAgreeGroup {
    data: IICTAgreeItem[],
    nowUrl? : string,
    event: {
        onChange: (items: IICTAgreeItem[]) => void
    },
    error?: {
        isError?: boolean,
        message?: string
    }
}


const ICTAgreeGroup = (prop: IICTAgreeGroup) => {

    const [openExpend, setOpenExpend] = useState<{ state: boolean, code?: string, title?: string }>({ state: false });


    const onExpend = async (item : IICTAgreeItem ) => {


        let htmlCode = undefined;

        const getRequest = await RequestKeriAPI.Common.GetStaticPage(item.htmlName??'');

        if(getRequest.state){
            htmlCode = getRequest.code;
        }
        else{
            htmlCode = `<div style="margin : 0 auto"><span>${getRequest.message}</span></div>`;
        }

        setOpenExpend({
            state: true,
            title: item.title,
            code: htmlCode
        })
    }
    


    return (
        <Box>
            <Box>
                {
                    prop.data.sort((x, y) => x.idx! - y.idx!).map(agreeItem => (
                        <ICTAgreeItem
                            key={ObjectUtils.RandomStrings(10)}
                            {...agreeItem}
                            onCheck={() => {
                                prop.event.onChange && prop.event.onChange([
                                    ...prop.data.filter(x => x.idx !== agreeItem.idx),
                                    {
                                        ...agreeItem,
                                        checked: !agreeItem.checked,
                                        agreeDate: (!agreeItem.checked) ? new Date() : undefined
                                    }
                                ]);
                            }}
                            onExpend={() => onExpend(agreeItem)}
                            checked={prop.data.find(x => x.idx === agreeItem.idx)?.checked ?? false}
                        />
                    ))
                }

            </Box>

            <Box
                sx={{
                    mt: 2
                }}
            >
                <ICTAgreeItem
                    idx={0}
                    title="전체 동의"
                    disableExpend
                    onCheck={() => {

                        prop.event.onChange && prop.event.onChange([
                            ...prop.data.map(x => {
                                return {
                                    ...x,
                                    checked : prop.data.find(x => !(x.checked??false)) === undefined ? false : true,
                                    agreeDate : prop.data.find(x => !(x.checked??false)) === undefined ? undefined : new Date()
                                } as IICTAgreeItem
                            }),
                        ]);

                    }}
                    checked={prop.data.find(x => !x.checked) === undefined}
                    itemProps={{
                        sx: {
                            height: '50px'
                        }
                    }}
                />
            </Box>
            {
                (prop.error?.isError ?? false) &&
                <Box>
                    <Typography sx={{ color: 'red' }}>
                        {prop.error?.message}
                    </Typography>
                </Box>
            }




            {/* Modal */}
            <ICTContainerPopup
                open={openExpend.state}
                onClose={() => setOpenExpend({
                    state: false
                })}
                popupProps={{
                    title: openExpend.title ?? '',
                }}
                containerProps={{
                    importHTML: openExpend.code?.replaceAll('[%NOWURL%]',prop.nowUrl??'')
                }}
            >

            </ICTContainerPopup>
        </Box>
    )

}



export interface IICTAgreeItem {
    htmlName?: string,
    title: string,
    idx: number,
    disableExpend?: boolean,
    required?: boolean,
    checked?: boolean,
    agreeDate?: Date
}


interface IICTAgreeItemProp extends IICTAgreeItem {
    onCheck: () => void,
    onExpend?: () => void,
    itemProps?: {
        sx?: SxProps<Theme>
    }
}

const ICTAgreeItem = (prop: IICTAgreeItemProp) => {

    

    return (
        <Box
            key={prop.idx ?? 'ALL_CHECK'}
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                border: '1px solid rgba(0,0,0,0.1)',
                borderBottom: 'none',
                p: 1,
                pr: 2,
                ":nth-of-type(1)" : {
                    borderTopLeftRadius: '5px',
                    borderTopRightRadius: '5px',
                },
                '&:nth-last-of-type(1)': {
                    borderBottom: '1px solid rgba(0,0,0,0.1)',
                    borderBottomLeftRadius: '5px',
                    borderBottomRightRadius: '5px',
                },
                ...prop.itemProps?.sx
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'start',
                    alignItems: 'center',
                    minWidth: '50%',
                    cursor: 'pointer'
                }}
                onClick={() => {
                    prop.onCheck !== undefined && prop.onCheck();
                }}
            >
                <KeriCheckBox
                    sx={{
                        mr: 1,
                        ml: 1
                    }}
                    checked={prop.checked ?? false}
                />
                <Typography
                    sx={{
                        userSelect: 'none'
                    }}
                >
                    {prop.title}
                </Typography>
            </Box>

            {
                !(prop.disableExpend ?? false) && prop.onExpend !== undefined && prop.htmlName !== undefined &&
                <Box>
                    <KeriActionButton
                        label="내용 보기"
                        variant="contained"
                        onClick={() => prop.onExpend!()}
                    />
                </Box>
            }

        </Box>
    )
}

export default ICTAgreeGroup;