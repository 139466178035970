import { ObjectUtils } from "../utils/ObjectUtils";

declare global {
    interface Date {
        toStringFormat(format? : string) : string | undefined
    }
}

Date.prototype.toStringFormat = function(this : Date | undefined , format? : string) {
    return ObjectUtils.DateToString(this , format);
}

export { }