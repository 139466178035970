import { IKeriAPIState } from "@ict-base/module/service/ICTRequestAPI";
import CallAPI from "@ict-base/module/utils/CallAPI";
import KeriAPIStructure from "@keri-portal/config/KeriAPIStructure";
import { IUploadFileVO } from "@keri-portal/interfaces/api/keri-api/object/IUploadFileVO";
import { IKeriAPIGetTemplateFileListRequestBody } from "@keri-portal/interfaces/api/keri-api/request/IKeriAPITemplateRequest";
import { IKeriAPIGetTemplateFileListResult, IKeriAPIGetTemplateListResult } from "@keri-portal/interfaces/api/keri-api/response/IKeriAPITemplateResponse";
import { IKeriTemplate } from "@keri-portal/interfaces/data-object/data-common/IKeriTemplate";
import KeriAPIException from "../api-utils/KeriAPIException";
import KeriAPIExceptionFilter from "../api-utils/KeriAPIExceptionFilter";

const KeriTemplateAPI = {
    GetTemplateList: async (): Promise<IGetTemplateList> => GetTemplateList(),
    GetTemplateFileList: async (prop: IGetTemplateFileListProp): Promise<IGetTemplateFileList> => GetTemplateFileList(prop),
}




//#region Get Template List

interface IGetTemplateList extends IKeriAPIState {
    resultData: IKeriTemplate[]
}

const GetTemplateList = async (): Promise<IGetTemplateList> => {
    let apiResult: IGetTemplateList = {
        message: undefined,
        state: true,
        statusCode: -1,
        resultData: []
    }

    try {

        await CallAPI<IKeriAPIGetTemplateListResult>({
            url: KeriAPIStructure.Template.GetTemplateList,
            method: 'POST',
            requestBody: {}
        })
            .then(res => {
                try {
                    if (res.data.resultCode === 'S') {
                        apiResult.state = true;
                        apiResult.statusCode = 200;

                        apiResult.resultData = res.data.resultData.map(resItem => ({
                            TemplateAtchFileID: resItem.atchFileId!,
                            TemplateID: resItem.templateId!,
                            TemplateTitle: resItem.templateSj!
                        }))
                    }
                    else {
                        throw new KeriAPIException(res.data.resultMessage, 400);
                    }
                }
                catch (ex) {
                    if (ex instanceof KeriAPIException) {
                        throw ex;
                    }
                    else {
                        throw new KeriAPIException('API 요청 데이터 수집 실패');
                    }
                }
            })
            .catch(e => {
                throw e;
            })
    }
    catch (e) {
        const exp = KeriAPIExceptionFilter(e);
        apiResult.message = exp.message;
        apiResult.state = false;
        apiResult.statusCode = exp.state;
    }


    return apiResult;
}

//#endregion


//#region Get Template File List

interface IGetTemplateFileListProp {
    templateID?: string,
    atchFileID?: string
}

interface IGetTemplateFileList extends IKeriAPIState {
    resultData: IUploadFileVO[]
}

const GetTemplateFileList = async (prop: IGetTemplateFileListProp): Promise<IGetTemplateFileList> => {
    let apiResult: IGetTemplateFileList = {
        message: undefined,
        state: true,
        statusCode: -1,
        resultData: []
    }

    try {


        console.error('ATCHFILEID' , prop.atchFileID)

        let reqBody: IKeriAPIGetTemplateFileListRequestBody = {};

        try {
            reqBody = {
                templateId: prop.templateID!,
                atchFileId: prop.atchFileID!
            }
        }
        catch (e) {
            throw new KeriAPIException('API 요청 데이터 생성 실패')
        }


        await CallAPI<IKeriAPIGetTemplateFileListResult>({
            url: KeriAPIStructure.Template.GetTemplateFiles,
            method: 'POST',
            requestBody: reqBody
        })
            .then(res => {
                try {
                    if (res.data.resultCode === 'S') {
                        apiResult.state = true;
                        apiResult.statusCode = 200;

                        apiResult.resultData = res.data.resultData.fileInfo ?? [];
                    }
                    else {
                        throw new KeriAPIException(res.data.resultMessage, 400);
                    }
                }
                catch (ex) {
                    if (ex instanceof KeriAPIException) {
                        throw ex;
                    }
                    else {
                        throw new KeriAPIException('API 요청 데이터 수집 실패');
                    }
                }
            })
            .catch(e => {
                throw e;
            })
    }
    catch (e) {
        const exp = KeriAPIExceptionFilter(e);
        apiResult.message = exp.message;
        apiResult.state = false;
        apiResult.statusCode = exp.state;
    }


    return apiResult;
}

//#endregion



export default KeriTemplateAPI;