import { ILoginUserVO } from '@keri-portal/interfaces/api/keri-api/object/ILoginUserVO';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import SessionStorageServ from '@ict-base/module/utils/SessionStorageServ';
import { IMenuItem } from '@keri-portal/interfaces/data-object/data-common/IMenu';




export const MenuDataReducer = createSlice({
    name : 'menu',
    initialState : [] as IMenuItem[],
    reducers : {
        setMenuList : (state, action : PayloadAction<IMenuItem[]>) => {
            // SessionStorageServ.setData(process.env.REACT_APP_USER_LOCAL_STORAGE_KEY, action.payload);
            return action.payload;
        },
        resetMenuList : () => {
            // SessionStorageServ.removeData(process.env.REACT_APP_USER_LOCAL_STORAGE_KEY)
            return [] as IMenuItem[]
        }
    }
})


export const { setMenuList, resetMenuList } = MenuDataReducer.actions;
export default MenuDataReducer.reducer;