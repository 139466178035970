import { Cancel, Delete, Download, Upload } from "@mui/icons-material";
import { Box, CircularProgress, IconButton, Typography } from "@mui/material";
import { AxiosRequestHeaders } from "axios";
import React, { useEffect, useState } from "react";
import { CallAPI, WebRequestMethod } from "@ict-base/module/utils/CallAPI";
import KeriActionButton from "./KeriActionButton";
import { IUploadFile } from "@keri-portal/interfaces/control/IUploadFile";
import RequestKeriAPI from "@keri-portal/module/method/api-connect/RequestKeriAPI";
import { IUploadFileVO } from "@keri-portal/interfaces/api/keri-api/object/IUploadFileVO";
import { ObjectUtils } from "@ict-base/module/utils/ObjectUtils";

interface IKeriFileTable {
    children?: React.ReactNode | React.ReactNode[],
    error?: {
        isError?: boolean,
        message?: string
    },
    fileMode: 'Upload' | 'Download' | 'Both',
    denyFileExtension?: string[]
}

export const KeriFileTable = (prop: IKeriFileTable) => {
    return (
        <Box
            className="Keri-FileTable"
            sx={{
                p: 1
            }}
        >
            {
                (prop.error?.isError ?? false) &&
                <Typography variant="body1" color={'#df5645'}>
                    {prop.error?.message}
                </Typography>
            }
            {prop.children}
            {
                prop.children === undefined || (prop.children as React.ReactNode[]).length === 0 &&
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '70px'
                    }}
                >
                    <Typography variant="body1" >
                        업로드 자료가 없습니다.
                    </Typography>
                </Box>
            }
            {
                prop.denyFileExtension !== undefined && prop.denyFileExtension.length !== 0 && prop.fileMode === 'Upload' &&
                <Box
                    sx={{
                        mt: 1
                    }}
                >
                    <Typography variant="body2" sx={{ color: '#7171dd' }}>
                        ⚠ 다음 확장자는 업로드 할 수 없습니다. ({prop.denyFileExtension.join(', ')}) <br />
                        ⚠ 500MB 이상 파일은 업로드 할 수 없습니다.
                    </Typography>
                </Box>
            }
        </Box>
    )
}


interface IKeriFileTableItem<IUploadFileVO> {
    fileObject: IUploadFile<IUploadFileVO>
    fileMode: 'Upload' | 'Download' | 'Both',
    uploadProp?: {
        disableUpload?: boolean,
        axiosProp?: {
            requestURL?: string,
            header?: AxiosRequestHeaders,
            method?: WebRequestMethod
        },
        denyFileExtension?: string[]
    },
    downloadProp?: {
        enableDownload?: boolean
    },
    displayObject: {
        displayTitle: (item: IUploadFile<IUploadFileVO>) => string,
        displayFileName: (item: IUploadFile<IUploadFileVO>) => string | undefined,
        displayDate?: (item: IUploadFile<IUploadFileVO>) => string,
        displayDeleteRow?: (item: IUploadFile<IUploadFileVO>) => boolean,
        disabledTitle?: boolean
    },
    event?: {
        onFileChange?: (item: IUploadFile<IUploadFileVO>) => void,
        onUploading?: (item: IUploadFile<IUploadFileVO>) => void,
        onUploaded?: (state: boolean, item: IUploadFile<IUploadFileVO>) => void,
        onDownload?: (item: IUploadFile<IUploadFileVO>) => void,
        onDeleteUploadFile?: (item: IUploadFile<IUploadFileVO>) => void,
        onDeleteRow?: (item: IUploadFile<IUploadFileVO>) => void
    }
}




export const KeriFileTableItem = (prop: IKeriFileTableItem<IUploadFileVO>) => {

    const [isUploading, setUploading] = useState<boolean>(false);

    const [errData, setErrData] = useState<string | undefined>(undefined);

    const UploadInputComponent = (comprop: { item: IUploadFile<IUploadFileVO> }) => (<input
        hidden
        // accept={prop.uploadFilter ?? '*'}
        accept='*'
        type="file"
        onChange={async (e) => {
            setErrData(undefined);

            const fileArray: File[] = Array.from(e.target.files ?? []);

            if (fileArray.length !== 0) {
                const fileSplit = fileArray[0].name.split('.');

                if (prop.uploadProp?.denyFileExtension && prop.uploadProp.denyFileExtension.indexOf(fileSplit[fileSplit.length - 1]) > -1) {
                    alert('허용되지 않은 확장자입니다.');
                    return false;
                }

                let orgObj = comprop.item;

                orgObj.OriginFile = fileArray[0]
                prop.event?.onFileChange && prop.event.onFileChange(orgObj);

                if (!(prop.uploadProp?.disableUpload ?? false)) {
                    setUploading(true);
                    prop.event?.onUploading && prop.event.onUploading(orgObj);

                    const requestAPI = await RequestKeriAPI.File.UploadFile(orgObj);

                    if (requestAPI.state) {

                        prop.event?.onUploaded && prop.event.onUploaded(requestAPI.state, requestAPI.data!);
                    }
                    else {
                        setErrData('업로드 실패');
                    }

                    setUploading(false);
                }
            }
            else {
                let changeObj = prop.fileObject;
                changeObj.OriginFile = undefined;
                changeObj.UploadedFile = undefined;
                prop.event?.onFileChange && prop.event.onFileChange(changeObj);
            }
        }}
    />)

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
            }}
        >
            {
                !(prop.displayObject.disabledTitle ?? false) &&
                <Box
                    sx={{
                        minWidth: '250px'
                    }}
                >
                    {prop.displayObject.displayTitle(prop.fileObject)}{(prop.fileObject.Required ?? false) && ' *'}
                </Box>
            }

            <Box
                sx={{
                    textAlign: 'left',
                    width: '100%',
                    pl: (prop.displayObject.disabledTitle ?? false) ? 0 : 2
                }}
            >
                {
                    isUploading
                        ?
                        <Box>
                            <CircularProgress color="inherit" size={14} /> Uploading..
                        </Box>
                        :
                        errData === undefined
                            ?
                            <React.Fragment>
                                <Typography>
                                    {prop.displayObject.displayFileName(prop.fileObject)}
                                </Typography>
                                {
                                    prop.fileObject.UploadedFile?.fileMg !== undefined &&
                                    <Typography variant="body2" sx={{ color: '#aaa' }}>
                                        {ObjectUtils.FileSizeFormater(parseInt(prop.fileObject.UploadedFile!.fileMg!, 10))}
                                        {prop.displayObject.displayDate && `, ${ObjectUtils.DateToString(prop.displayObject.displayDate(prop.fileObject), 'yyyy-MM-dd HH:mm')}`}
                                    </Typography>
                                }

                            </React.Fragment>
                            :
                            <Typography color="#df5645">
                                {errData}
                            </Typography>
                }

            </Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'end',
                    alignItems: 'center'
                }}
            >
                {
                    prop.fileMode === 'Upload' &&
                    ((
                        ((prop.uploadProp?.disableUpload ?? false) && prop.fileObject.OriginFile !== undefined)
                        || (!(prop.uploadProp?.disableUpload ?? false) && prop.fileObject.UploadedFile !== undefined)
                    )
                        ?
                        <KeriActionButton
                            label="삭제"
                            variant="outlined"
                            color="primary"
                            icon={<Delete />}
                            onClick={() => prop.event?.onDeleteUploadFile && prop.event.onDeleteUploadFile(prop.fileObject)}
                        />
                        :
                        <KeriActionButton
                            disabled={isUploading}
                            label="업로드"
                            variant="outlined"
                            color="primary"
                            icon={<Upload />}
                            component={<UploadInputComponent
                                item={prop.fileObject}
                            />}
                            onClick={() => {
                                // alert(prop.uploadData.downloadURL)
                            }}
                        />)
                }
                {
                    prop.fileMode === 'Download' &&
                    <KeriActionButton
                        label="다운로드"
                        variant="outlined"
                        color="primary"
                        disabled={prop.fileObject.UploadedFile?.atchFileId === undefined}
                        icon={<Download />}
                        onClick={() => {
                            prop.event?.onDownload && prop.event.onDownload(prop.fileObject);
                        }}
                    />
                }

                <Box
                    sx={{
                        width: '30px'
                    }}
                >
                    {
                        (prop.fileMode !== 'Download' && (prop.displayObject.displayDeleteRow && prop.displayObject.displayDeleteRow(prop.fileObject))) &&
                        <IconButton
                            onClick={() => prop.event?.onDeleteRow && prop.event.onDeleteRow(prop.fileObject)}
                        >
                            <Cancel />
                        </IconButton>
                    }
                </Box>



            </Box>
        </Box>
    )

}

