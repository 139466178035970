import KeriActionButton from "@ict-base/components/control/KeriActionButton";
import { ObjectUtils } from "@ict-base/module/utils/ObjectUtils";
import { MISCFullBlurLoadingModal, MISCLoadingComponent , MISCModal } from "@ictpeople/misc-react";
import LoginChecker from "@keri-base/components/common/LoginChecker";
import { IPageProp } from "@keri-base/interface/common/IPageProp";
import KeriBoardRating from "@keri-portal/component/control/board/KeriBoardRating";
import InfoLayoutPage from "@keri-portal/component/layout/InfoLayoutPage";
import { IBoard } from "@keri-portal/interfaces/data-object/data-common/IBoard";
import RequestKeriAPI from "@keri-portal/module/method/api-connect/RequestKeriAPI";
import { RemoveRedEyeOutlined, InsertDriveFile, ArrowDownward, ArrowUpward } from "@mui/icons-material";
import { Avatar, Box, CircularProgress, Divider, Paper, Typography } from "@mui/material";
import { ILoginSessionData } from "@src/modules/redux/reducer/UserDataReducer";
import URLStructure from "@src/modules/structure/URLStructure";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const CommonBoardItemInfoPage = (prop: IPageProp) => {

    const [loginData, setLoginData] = useState<ILoginSessionData | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(true);
    const [boardData, setBoardData] = useState<IBoard | undefined>(undefined);
    const [loadingDownload , setLoadingDownload] = useState<boolean>(false);
    const param = useParams();
    const boardID = param.boardID;
    const boardItemID = param.boardItemID;
    const navigate = useNavigate();
    const [openDownloadSection, setOpenDownloadSection] = useState<boolean>(false);


    const getBoardData = async () => {
        setLoading(true);
        const getData = await RequestKeriAPI.Board.GetBoardItemData(loginData?.token, boardID, boardItemID);

        if (getData.state) {
            if ((getData.resultData?.BoardList ?? []).length !== 0) {
                setBoardData(getData.resultData);
            }
        }
        else {
            alert(getData.message);
        }

        setLoading(false);
    }


    useEffect(() => {
        getBoardData();

        // eslint-disable-next-line
    }, [])


    return (
        <LoginChecker
            onCheck={(state, data) => {
                if (state === 'LOGIN') {
                    setLoginData(data);
                }
                else if (state === 'UNLOGIN') {
                    setLoginData(undefined);
                }
            }}
        >
            {
                loading
                    ?
                    <InfoLayoutPage>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                pt: 20
                            }}
                        >
                            <CircularProgress color="inherit" />
                        </Box>
                    </InfoLayoutPage>
                    :
                    <React.Fragment>
                        {
                            boardData === undefined
                                ?
                                <InfoLayoutPage>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            pt: 20
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                textAlign: 'center'
                                            }}
                                        >
                                            <img src={`${URLStructure.Main}assets/img/ict/images/top_logo.svg`} alt="logo" />
                                            <Typography
                                                variant='h4'
                                                sx={{
                                                    mb: 4,
                                                    mt: 10
                                                }}
                                            >
                                                페이지를 찾을 수 없습니다.
                                            </Typography>
                                            <Typography
                                                onClick={() => navigate(-1)}
                                                sx={{
                                                    cursor: 'pointer',
                                                    transition: '0.5s all cubic-bezier(0,0,0,1)',
                                                    ":hover": {
                                                        color: '#52b5b0'
                                                    }
                                                }}
                                            >
                                                이전 페이지로 이동
                                            </Typography>
                                        </Box>

                                    </Box>
                                </InfoLayoutPage>

                                :
                                <InfoLayoutPage
                                    title={boardData?.BoardTitle}
                                >
                                    <Paper
                                        sx={{
                                            p: 3,
                                            pr: 5,
                                            pl: 5
                                        }}
                                    >
                                        <Typography
                                            variant="h6"
                                            sx={{
                                                mb: 2,
                                                overflow: 'wrap'
                                            }}
                                        >
                                            {boardData.BoardList[0].BoardItemTitle}
                                        </Typography>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'end'
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'start',
                                                    alignItems: 'center'
                                                }}
                                            >
                                                <Box>
                                                    <Avatar
                                                        sx={{
                                                            width: '2rem',
                                                            height: '2rem',
                                                        }}
                                                    />
                                                </Box>
                                                <Box
                                                    sx={{
                                                        ml: 1
                                                    }}
                                                >
                                                    <Typography variant="body2">
                                                        {boardData.BoardList[0].BoardItemWriter}
                                                    </Typography>
                                                    <Typography variant="body2" sx={{ color: '#aaa' }}>
                                                        {ObjectUtils.DateToString(boardData.BoardList[0].BoardItemWriteDate, 'yyyy-MM-dd')}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'end',
                                                    alignItems: 'end',
                                                    color: '#aaa',
                                                    '& *': {
                                                        fontSize: '0.8rem'
                                                    }
                                                }}
                                            >
                                                {/* 조회수 */}
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent: 'start',
                                                        alignItems: 'center',
                                                        ml: 1
                                                    }}
                                                >
                                                    <RemoveRedEyeOutlined
                                                        sx={{
                                                            mr: 0.5,
                                                            fontSize: '1.0rem !important',
                                                        }}
                                                    />
                                                    <Typography>
                                                        {boardData.BoardList[0].BoardItemViewCount ?? 0}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Divider sx={{ mt: 1, mb: 1 }} />

                                        {
                                            (boardData.BoardList[0].BoardItemUploadFiles ?? []).length !== 0 &&
                                            <Box
                                                sx={{

                                                    border: '1px solid rgba(0,0,0,0.1)',
                                                    p: 1,
                                                    borderRadius: '5px',
                                                    transition: '0.5s all cubic-bezier(0,0,0,1)',
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent: 'start',
                                                        alignItems: 'center',
                                                        mb: openDownloadSection ? 1 : 0,
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={() => setOpenDownloadSection(!openDownloadSection)}
                                                >
                                                    {
                                                        !openDownloadSection
                                                            ?
                                                            <ArrowDownward
                                                                sx={{
                                                                    mr: 1
                                                                }}
                                                            />
                                                            :
                                                            <ArrowUpward
                                                                sx={{
                                                                    mr: 1
                                                                }}
                                                            />
                                                    }

                                                    <Typography>
                                                        {
                                                            openDownloadSection
                                                                ? `접기`
                                                                : `파일 다운로드 (${(boardData.BoardList[0].BoardItemUploadFiles ?? []).length})`
                                                        }

                                                    </Typography>
                                                </Box>
                                                {openDownloadSection && <Divider />}
                                                {
                                                    openDownloadSection &&
                                                    <Box
                                                        className="BoardUploadFiles"
                                                    >
                                                        {
                                                            (boardData.BoardList[0].BoardItemUploadFiles ?? []).map(fileItem => (
                                                                <Box
                                                                    sx={{
                                                                        width: '100%',
                                                                        display: 'flex',
                                                                        justifyContent: 'space-between',
                                                                        alignItems: 'center',
                                                                        p: 1
                                                                    }}
                                                                >
                                                                    <Box
                                                                        sx={{
                                                                            display: 'flex',
                                                                            justifyContent: 'start',
                                                                            alignItems: 'center',
                                                                            transition: '0.3s all cubic-bezier(0,0,0,1)',
                                                                            cursor: 'pointer',
                                                                            ":hover": {
                                                                                color: '#aaa',
                                                                            }
                                                                        }}
                                                                        onClick={async () => {
                                                                            setLoadingDownload(true);
                                                                            const requestData = await RequestKeriAPI.File.DownloadFile(loginData?.token, fileItem);

                                                                            if (requestData.state) {
                                                                                const jsonFile = new Blob([requestData.data]);
                                                                                const element = document.createElement("a");

                                                                                element.href = URL.createObjectURL(jsonFile);
                                                                                element.download = fileItem.UploadedFile?.orignlFileNm ?? 'undefined';
                                                                                document.body.appendChild(element);
                                                                                element.click();
                                                                                element.remove();
                                                                            }
                                                                            else {
                                                                                alert(requestData.message);
                                                                            }
                                                                            setLoadingDownload(false);
                                                                        }}
                                                                    >
                                                                        <InsertDriveFile
                                                                            sx={{
                                                                                m: 0,
                                                                                p: 0,
                                                                                mr: 1,
                                                                                color: '#75889d'
                                                                            }}
                                                                        />
                                                                        <Typography>
                                                                            {fileItem.UploadedFile?.orignlFileNm}
                                                                        </Typography>

                                                                    </Box>
                                                                </Box>
                                                            ))
                                                        }
                                                    </Box>
                                                }
                                            </Box>
                                        }

                                        <Box
                                            sx={{
                                                pt: 2,
                                                pb: 2,
                                                width: "100%",
                                                overflow: 'auto'
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    overflow: 'wrap',
                                                    '&>div img': {
                                                        maxWidth: '100%'
                                                    }
                                                }}
                                            >
                                                <div className="ContentHTML" dangerouslySetInnerHTML={{ __html: boardData.BoardList[0].BoardItemContent ?? '' }} />
                                            </Typography>
                                        </Box>
                                        {
                                            !(boardID === undefined || boardItemID === undefined) &&
                                            <KeriBoardRating
                                                boardID={boardID}
                                                boardItemID={boardItemID}
                                            />
                                        }

                                    </Paper>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'end',
                                            alignItems: 'end'
                                        }}
                                    >
                                        <KeriActionButton
                                            label="목록"
                                            variant="contained"
                                            color="info"
                                            onClick={() => {
                                                navigate(`${process.env.PUBLIC_URL}/board/${boardID}`);
                                            }}
                                        />
                                    </Box>

                                </InfoLayoutPage>
                        }
                    </React.Fragment>

            }

            {/* <MISCLoadingComponent /> */}
            <MISCFullBlurLoadingModal 
                open={loadingDownload}
                onClose={() => {}}
                message={'다운로드 중..'}
            />
        </LoginChecker>
    )
}

export default CommonBoardItemInfoPage;