import { MISCPageStateComponent } from "@ictpeople/misc-react";
import InfoLayoutPage from "@keri-portal/component/layout/InfoLayoutPage";

const EducationItemSurveyPage = () => {
    return (
        <MISCPageStateComponent
            errorMessage={undefined}
            isLoading={false}
        >
            <InfoLayoutPage
                title="교육 설문평가"
            >

            </InfoLayoutPage>
        </MISCPageStateComponent>
    )
}

export default EducationItemSurveyPage;