import KeriActionButton from "@ict-base/components/control/KeriActionButton";
import { ObjectUtils } from "@ict-base/module/utils/ObjectUtils";
import InfoLayoutPage from "@keri-portal/component/layout/InfoLayoutPage";
import useGetEducationScheduleInfo, { IUseGetEducationScheduleInfo } from "@keri-portal/module/hook/api/education/useGetEducationScheduleInfo";
import useGetEducationScheduleList from "@keri-portal/module/hook/api/education/useGetEducationScheduleList";
import { ArrowBack, Assignment, Rule, Subtitles, Warning } from "@mui/icons-material";
import { Paper, Typography, Divider, Box, CircularProgress } from '@mui/material';
import URLStructure from "@src/modules/structure/URLStructure";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const EducationScheduleInfoPage = () => {

    const navigate = useNavigate();
    const param = useParams();
    const location = useLocation();

    //#region API Hook

    const [hookData, setHookData] = useState<IUseGetEducationScheduleInfo>({
        TriggerTime: new Date()
    });
    const eduScheduleInfoHook = useGetEducationScheduleInfo(hookData);


    //#endregion

    useEffect(() => {
        setHookData({
            TriggerTime: new Date(),
            scheduleID: param.id
        })
    }, [param.id])

    return (
        <InfoLayoutPage
            title="교육 일정 조회 및 신청"
        >
            {
                eduScheduleInfoHook.loading &&
                <Paper
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '150px'
                    }}
                >
                    <CircularProgress />
                </Paper>
            }
            {
                (!eduScheduleInfoHook.loading && eduScheduleInfoHook.data?.state === false) &&
                <Paper
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '250px'
                    }}
                >
                    <Box
                        sx={{
                            textAlign: 'center'
                        }}
                    >
                        <Warning
                            fontSize="large"
                        />
                        <Typography>
                            {eduScheduleInfoHook.data.message}
                        </Typography>
                        <Box
                            sx={{
                                mt: 2
                            }}
                        >
                            <KeriActionButton
                                label="목록으로"
                                icon={<ArrowBack />}
                                onClick={() => navigate(URLStructure.Edu.EducationList)}
                                variant="text"
                            />
                        </Box>
                    </Box>

                </Paper>
            }
            {
                (!eduScheduleInfoHook.loading && eduScheduleInfoHook.data?.state === true) &&
                <React.Fragment>
                    <Paper
                        sx={{
                            p: 1
                        }}
                    >



                        <Box
                            sx={{
                                p: 1,
                                pb: 0,
                                display: "flex",
                                alignItems: 'center'
                            }}
                        >
                            <Subtitles
                                sx={{
                                    mr: 1
                                }}
                            />
                            <Typography
                                variant="h6"
                            >
                                {eduScheduleInfoHook.data.resultData?.title}
                            </Typography>
                        </Box>

                        <Divider sx={{ mt: 1, mb: 1 }} />
                        <Box>
                            <ItemRowSection>
                                <ItemInfoSection
                                    width="half"
                                    title="신청 기간"
                                    content={`${eduScheduleInfoHook.data.resultData?.requestOpenDate.toStringFormat('yyyy-MM-dd')} ~ ${eduScheduleInfoHook.data.resultData?.requestCloseDate.toStringFormat('yyyy-MM-dd')}`}
                                />
                                <ItemInfoSection
                                    width="half"
                                    title="교육 기간"
                                    content={`${eduScheduleInfoHook.data.resultData?.classOpenDate.toStringFormat('yyyy-MM-dd')} ~ ${eduScheduleInfoHook.data.resultData?.classCloseDate.toStringFormat('yyyy-MM-dd')}`}
                                />
                            </ItemRowSection>
                            <ItemRowSection>
                                <ItemInfoSection
                                    width="half"
                                    title="신청 상태"
                                    content={eduScheduleInfoHook.data.resultData?.receiptState.StateName}
                                />
                                <ItemInfoSection
                                    width="half"
                                    title="교육 상태"
                                    content={eduScheduleInfoHook.data.resultData?.classState.StateName}
                                />
                            </ItemRowSection>
                            <ItemRowSection>
                                <ItemInfoSection
                                    width="half"
                                    title="강사"
                                    content={eduScheduleInfoHook.data.resultData?.lecturer}
                                />
                                <ItemInfoSection
                                    width="half"
                                    title="카테고리"
                                    content={eduScheduleInfoHook.data.resultData?.category.CategoryName}
                                />
                            </ItemRowSection>
                            <ItemRowSection>
                                <ItemInfoSection
                                    width="full"
                                    title="교육 설명"
                                    content={
                                        <div dangerouslySetInnerHTML={{ __html: eduScheduleInfoHook.data.resultData?.content ?? '' }} />
                                    }
                                />
                            </ItemRowSection>
                        </Box>
                    </Paper>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'end',
                            alignItems: 'center'
                        }}
                    >

                        {/* <KeriActionButton
                            label="설문 평가"
                            onClick={() =>  navigate('survey')}
                            icon={<Rule />}
                            variant='contained'
                        /> */}
                            <KeriActionButton
                                label="교육 신청"
                                onClick={() => {
                                    if(
                                        eduScheduleInfoHook.data?.resultData?.receiptState.StateName === '접수 중'
                                        && new Date(Date.parse(ObjectUtils.DateToString(eduScheduleInfoHook.data.resultData?.requestOpenDate, 'yyyy-MM-dd') ?? '')) <= new Date(Date.parse(ObjectUtils.DateToString(Date.now(), 'yyyy-MM-dd') ?? ''))
                                        && new Date(Date.parse(ObjectUtils.DateToString(eduScheduleInfoHook.data.resultData?.requestCloseDate, 'yyyy-MM-dd') ?? '')) >= new Date(Date.parse(ObjectUtils.DateToString(Date.now(), 'yyyy-MM-dd') ?? ''))
                                    ){
                                        navigate('request')
                                    }
                                    else{
                                        alert('신청 기간이 아닙니다.');
                                    }
                                }}
                                icon={<Assignment />}
                                variant='contained'
                                sx={{
                                    ml: 1
                                }}
                            />

                        <KeriActionButton
                            label="목록"
                            onClick={() => navigate(URLStructure.Edu.EducationList)}
                            variant='contained'
                            sx={{
                                ml: 1
                            }}
                        />
                    </Box>
                </React.Fragment>
            }

        </InfoLayoutPage>
    )
}


const ItemRowSection = (prop: { children?: React.ReactNode | React.ReactNode[] }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'center'
            }}
        >
            {prop.children}
        </Box>
    )
}

const ItemInfoSection = (prop: {
    width: 'half' | 'full',
    title?: string,
    content?: string | React.ReactNode
}) => {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'center',
                width: prop.width === 'half' ? '50%' : '100%',
                p: 1
            }}
        >
            {
                prop.title !== undefined &&
                <Box
                    sx={{
                        whiteSpace: 'nowrap',
                        minWidth: '100px'
                    }}
                >
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mr: 2 }}>
                        {prop.title}
                    </Typography>
                </Box>
            }
            <Box>
                {
                    typeof prop.content === 'string'
                        ?
                        <Typography variant="body1">
                            {prop.content}
                        </Typography>
                        :
                        prop.content
                }

            </Box>

        </Box>
    )
}

export default EducationScheduleInfoPage;