import ICTPagination from "@ict-base/components/control/ICTPagination";
import { ICTSearchBox } from "@ict-base/components/control/ICTSearchBox";
import KeriActionButton from "@ict-base/components/control/KeriActionButton";
import { ObjectUtils } from "@ict-base/module/utils/ObjectUtils";
import LoginChecker from "@keri-base/components/common/LoginChecker";
import { IPageProp } from "@keri-base/interface/common/IPageProp";
import InfoLayoutPage from "@keri-portal/component/layout/InfoLayoutPage";
import { IBoard } from "@keri-portal/interfaces/data-object/data-common/IBoard";
import RequestKeriAPI from "@keri-portal/module/method/api-connect/RequestKeriAPI";
import { Lock, PersonOutline, RemoveRedEyeOutlined } from "@mui/icons-material";
import { Box, Chip, CircularProgress, Divider, Paper, Typography } from "@mui/material";
import { ILoginSessionData } from "@src/modules/redux/reducer/UserDataReducer";
import URLStructure from "@src/modules/structure/URLStructure";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const ShareBoardPage = (prop: IPageProp) => {


    const [loginData, setLoginData] = useState<ILoginSessionData | undefined>(undefined);
    const param = useParams();
    const boardID = param.boardID;
    const navigate = useNavigate();
    const location = useLocation();



    //#region Get QueryString

    const queryString = location.search.replace('?', '').split('&').filter(x => x !== undefined);
    const searchText = queryString.find(x => x.split('=')[0] === 'search')?.replace(queryString.find(x => x.split('=')[0] === 'search')?.split('=')[0] + "=", '');
    const pageQuery = queryString.find(x => x.split('=')[0] === 'page')?.replace(queryString.find(x => x.split('=')[0] === 'page')?.split('=')[0] + "=", '');
    const pageNumber = isNaN(parseInt(pageQuery ?? '1')) ? undefined : parseInt(pageQuery ?? '1')
    console.log(searchText);

    //#endregion



    const [boardData, setBoardData] = useState<{ state: boolean, board?: IBoard | undefined }>({ state: false });
    const [pageObj, setPageObj] = useState<{ nowPage: number, totalPage: number, isMovePage: boolean } | undefined>({
        nowPage: 1,
        totalPage: 1,
        isMovePage: false
    })
    const [searchObj, setSearchObj] = useState<{ data: string | undefined, searchState: boolean, lastSearchText: string | undefined } | undefined>(undefined);


    const getData = async (searchText?: string, page?: number) => {

        console.log('getData');

        window.history.replaceState('', "", `${location.pathname}?${ObjectUtils.IsNullOrEmpty(searchText) ? '' : `search=${searchText}&`}page=${page ?? 1}`);

        const getResult = await RequestKeriAPI.Board.GetBoardContentList(loginData?.token, boardID, searchText, page, 10);

        if (getResult.state) {
            setBoardData({ state: true, board: getResult.resultData })

            setPageObj({
                nowPage: getResult.pageObject!.totalPage! < getResult.pageObject!.nowPage! ? getResult.pageObject!.totalPage! : getResult.pageObject!.nowPage!,
                totalPage: getResult.pageObject!.totalPage!,
                isMovePage: false
            });
            setSearchObj({
                data: searchText ?? '',
                lastSearchText: searchText,
                searchState: false
            })
        }
        else {
            alert(getResult.message);
        }


    }



    useEffect(() => {
        if (loginData !== undefined) {
            getData(searchText, pageNumber);
        }

        // eslint-disable-next-line
    }, [loginData, param])

    useEffect(() => {
        if (searchObj?.searchState) {
            getData(searchObj?.lastSearchText, pageObj?.nowPage ?? 1);
        }

        // eslint-disable-next-line
    }, [searchObj])

    useEffect(() => {
        if (pageObj !== undefined) {
            if (pageObj.isMovePage) {
                getData(searchObj?.lastSearchText, pageObj.nowPage)
            }
        }

        // eslint-disable-next-line
    }, [pageObj])



    return (
        <LoginChecker
            onCheck={(state, data) => {
                console.log('logincheck', state, data)
                if (state === 'LOGIN') {
                    setLoginData({ ...data });
                }
                else if (state === 'UNLOGIN') {
                    setLoginData({ ...data });
                }
            }}
        >
            {
                !boardData.state ?
                    <InfoLayoutPage>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                pt: 20
                            }}
                        >
                            <CircularProgress color="inherit" />
                        </Box>
                    </InfoLayoutPage>
                    :
                    <React.Fragment>
                        {
                            (boardData.state && boardData === undefined)
                                ?
                                <InfoLayoutPage>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            pt: 20
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                textAlign: 'center'
                                            }}
                                        >
                                            <img src={`${URLStructure.Main}assets/img/ict/images/top_logo.svg`} alt="logo" />
                                            <Typography
                                                variant='h4'
                                                sx={{
                                                    mb: 4,
                                                    mt: 10
                                                }}
                                            >
                                                페이지를 찾을 수 없습니다.
                                            </Typography>
                                            <Typography
                                                onClick={() => navigate(URLStructure.Main)}
                                                sx={{
                                                    cursor: 'pointer',
                                                    transition: '0.5s all cubic-bezier(0,0,0,1)',
                                                    ":hover": {
                                                        color: '#52b5b0'
                                                    }
                                                }}
                                            >
                                                메인 페이지로 이동
                                            </Typography>
                                        </Box>
                                    </Box>
                                </InfoLayoutPage>
                                :
                                <InfoLayoutPage
                                    title={boardData.board?.BoardTitle}
                                    endAdornment={
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'end',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <ICTSearchBox
                                                value={searchObj?.data}
                                                onSearch={(changeText) => {
                                                    setSearchObj({
                                                        data: changeText,
                                                        searchState: true,
                                                        lastSearchText: changeText
                                                    })
                                                    setPageObj({
                                                        nowPage: 1,
                                                        totalPage: 1,
                                                        isMovePage: false
                                                    })
                                                }}
                                                onChange={(changeText) => {
                                                    setSearchObj({
                                                        data: changeText,
                                                        searchState: false,
                                                        lastSearchText: searchObj?.lastSearchText
                                                    })
                                                }}
                                            />

                                        </Box>
                                    }
                                >
                                    <Paper
                                        sx={{
                                            pr: 1,
                                            pl: 1
                                        }}
                                    >
                                        {
                                            boardData.board?.BoardList.length === 0 &&
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    pt: 10,
                                                    width: '100%',
                                                    pb: 10
                                                }}
                                            >
                                                <Typography>
                                                    검색된 게시글이 없습니다.
                                                </Typography>
                                            </Box>
                                        }


                                        {
                                            //Header
                                            <React.Fragment>
                                                <Box
                                                    sx={{
                                                        p: 1,
                                                        pt: 2,
                                                        pb: 2,
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        alignItems: 'center',
                                                        cursor: 'pointer'
                                                    }}
                                                >

                                                    <Box
                                                        sx={{
                                                            width: '70%',
                                                            maxWidth: 'calc(100% - 370px)',
                                                            display: 'flex',
                                                            justifyContent: 'start',
                                                            alignItems: 'center',
                                                            pr: 1,
                                                            pl: 1
                                                        }}
                                                    >
                                                        <Typography
                                                            sx={{
                                                                mr: 2
                                                            }}
                                                        >
                                                            번호
                                                        </Typography>
                                                        <Box
                                                            sx={{
                                                                width: '30px',
                                                                display: "flex",
                                                                justifyContent: 'center',
                                                                alignItems: 'center'
                                                            }}
                                                        >

                                                        </Box>
                                                        <Typography
                                                            sx={{
                                                                ml: 2,
                                                                // fontWeight: '500',

                                                                overflow: 'hidden',
                                                                textOverflow: 'ellipsis',
                                                                whiteSpace: "nowrap"
                                                            }}
                                                        >
                                                            제목
                                                        </Typography>

                                                    </Box>
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            justifyContent: 'end',
                                                            alignItems: 'center',
                                                            width: '30%',
                                                            minWidth: '370px'
                                                        }}
                                                    >
                                                        {/*  */}
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                justifyContent: 'end',
                                                                alignItems: 'center',
                                                                // color: '#aaa',
                                                                '& *': {
                                                                    fontSize: '0.8rem'
                                                                }
                                                            }}
                                                        >
                                                            {/* 조회수 */}
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    justifyContent: 'start',
                                                                    alignItems: 'center',
                                                                    ml: 1
                                                                }}
                                                            >
                                                                <RemoveRedEyeOutlined
                                                                    sx={{
                                                                        mr: 0.5,
                                                                        fontSize: '1.0rem !important',
                                                                    }}
                                                                />
                                                                <Typography>
                                                                    조회수
                                                                </Typography>
                                                            </Box>

                                                            {/* 작성자 */}
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    justifyContent: 'start',
                                                                    alignItems: 'center',
                                                                    ml: 1
                                                                }}
                                                            >
                                                                <PersonOutline
                                                                    sx={{
                                                                        mr: 0.5,
                                                                        fontSize: '1.0rem !important',
                                                                    }}
                                                                />
                                                                <Typography>
                                                                    작성자
                                                                </Typography>
                                                            </Box>

                                                            {/* 작성일 */}
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    justifyContent: 'start',
                                                                    alignItems: 'center',
                                                                    ml: 1
                                                                }}
                                                            >
                                                                <Typography>
                                                                    작성일
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                        <Box
                                                            sx={{
                                                                ml: 3
                                                            }}
                                                        >
                                                            <Typography
                                                                sx={{
                                                                    fontSize: '0.8rem',
                                                                    // color: '#aaa',
                                                                    minWidth: '80px',
                                                                    textAlign: 'center'
                                                                }}
                                                            >
                                                                처리 여부
                                                            </Typography>
                                                            {/* <Chip
                                                                    sx={{
                                                                        backgroundColor: (boardItem.BoardState ?? 'WAIT') === 'WAIT' ? '#aaaaaa' : '#4ea6b4',
                                                                        color: 'white'
                                                                    }}
                                                                    label={(boardItem.BoardState ?? 'WAIT') === 'WAIT' ? '답변 대기' : '답변 완료'}
                                                                /> */}
                                                        </Box>
                                                    </Box>

                                                </Box>
                                                <Divider />
                                            </React.Fragment>
                                        }



                                        {
                                            boardData.board?.BoardList.map((boardItem, idx, arr) => (
                                                <React.Fragment>
                                                    <Box
                                                        sx={{
                                                            p: 1,
                                                            pt: 2,
                                                            pb: 2,
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                            alignItems: 'center',
                                                            cursor: 'pointer'
                                                        }}
                                                        onClick={() => navigate(`${boardItem.BoardItemID}${boardItem.IsLock && boardItem.IsAnonymouse ? '?auth=y' : ''}`)}
                                                    >

                                                        <Box
                                                            sx={{
                                                                width: '70%',
                                                                maxWidth: 'calc(100% - 370px)',
                                                                display: 'flex',
                                                                justifyContent: 'start',
                                                                alignItems: 'center',
                                                                pr: 1,
                                                                pl: 1
                                                            }}
                                                        >
                                                            <Typography
                                                                sx={{
                                                                    mr: 2
                                                                }}
                                                            >
                                                                {boardItem.BoardItemID}
                                                            </Typography>
                                                            <Box
                                                                sx={{
                                                                    width: '30px',
                                                                    display: "flex",
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center'
                                                                }}
                                                            >
                                                                {
                                                                    boardItem.IsLock &&
                                                                    <Lock
                                                                        sx={{
                                                                            color: '#f6b314'
                                                                        }}
                                                                    />
                                                                }
                                                            </Box>
                                                            <Typography
                                                                sx={{
                                                                    ml: 2,
                                                                    fontWeight: '500',

                                                                    overflow: 'hidden',
                                                                    textOverflow: 'ellipsis',
                                                                    whiteSpace: "nowrap"
                                                                }}
                                                            >
                                                                {boardItem.BoardItemTitle}
                                                            </Typography>

                                                        </Box>
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                justifyContent: 'end',
                                                                alignItems: 'center',
                                                                width: '30%',
                                                                minWidth: '370px'
                                                            }}
                                                        >
                                                            {/*  */}
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    justifyContent: 'end',
                                                                    alignItems: 'center',
                                                                    color: '#aaa',
                                                                    '& *': {
                                                                        fontSize: '0.8rem'
                                                                    }
                                                                }}
                                                            >
                                                                {/* 조회수 */}
                                                                <Box
                                                                    sx={{
                                                                        display: 'flex',
                                                                        justifyContent: 'start',
                                                                        alignItems: 'center',
                                                                        ml: 1
                                                                    }}
                                                                >
                                                                    <RemoveRedEyeOutlined
                                                                        sx={{
                                                                            mr: 0.5,
                                                                            fontSize: '1.0rem !important',
                                                                        }}
                                                                    />
                                                                    <Typography>
                                                                        {boardItem.BoardItemViewCount ?? 0}
                                                                    </Typography>
                                                                </Box>

                                                                {/* 작성자 */}
                                                                <Box
                                                                    sx={{
                                                                        display: 'flex',
                                                                        justifyContent: 'start',
                                                                        alignItems: 'center',
                                                                        ml: 1
                                                                    }}
                                                                >
                                                                    <PersonOutline
                                                                        sx={{
                                                                            mr: 0.5,
                                                                            fontSize: '1.0rem !important',
                                                                        }}
                                                                    />
                                                                    <Typography>
                                                                        {boardItem.BoardItemWriter}
                                                                    </Typography>
                                                                </Box>

                                                                {/* 작성일 */}
                                                                <Box
                                                                    sx={{
                                                                        display: 'flex',
                                                                        justifyContent: 'start',
                                                                        alignItems: 'center',
                                                                        ml: 1
                                                                    }}
                                                                >
                                                                    <Typography>
                                                                        {ObjectUtils.DateToString(boardItem.BoardItemWriteDate, 'yyyy-MM-dd')}
                                                                    </Typography>
                                                                </Box>
                                                            </Box>
                                                            <Box
                                                                sx={{
                                                                    ml: 3
                                                                }}
                                                            >
                                                                <Typography
                                                                    sx={{
                                                                        fontSize: '0.8rem',
                                                                        color: '#aaa',
                                                                        minWidth: '80px',
                                                                        textAlign: 'center'
                                                                    }}
                                                                >
                                                                    <Chip
                                                                        label={boardItem.BoardStateName ?? '진행중'}
                                                                        color={(ObjectUtils.IsNullOrEmpty(boardItem.BoardState) ? 'Receipt' : boardItem.BoardState) === 'Receipt' ? 'default' : "success"}
                                                                        sx={{

                                                                        }}
                                                                    />
                                                                </Typography>
                                                                {/* <Chip
                                                                    sx={{
                                                                        backgroundColor: (boardItem.BoardState ?? 'WAIT') === 'WAIT' ? '#aaaaaa' : '#4ea6b4',
                                                                        color: 'white'
                                                                    }}
                                                                    label={(boardItem.BoardState ?? 'WAIT') === 'WAIT' ? '답변 대기' : '답변 완료'}
                                                                /> */}
                                                            </Box>
                                                        </Box>

                                                    </Box>
                                                    {
                                                        arr.length - 1 !== idx &&
                                                        <Divider />
                                                    }
                                                </React.Fragment>

                                            ))
                                        }

                                    </Paper>
                                    <Box>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                width: '100%',
                                                pt: 1,
                                                pb: 5
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    width: '200px'
                                                }}
                                            >
                                            </Box>
                                            <ICTPagination
                                                pageObject={{
                                                    nowPage: pageObj?.nowPage,
                                                    totalPage: pageObj?.totalPage,
                                                    onChangePage: (changePage) => {
                                                        setPageObj({
                                                            nowPage: changePage,
                                                            totalPage: pageObj?.totalPage!,
                                                            isMovePage: true
                                                        })
                                                    }
                                                }}
                                            />
                                            <Box
                                                sx={{
                                                    width: '200px',
                                                    display: 'flex',
                                                    justifyContent: 'end'
                                                }}
                                            >
                                                <KeriActionButton
                                                    label="글쓰기"
                                                    variant="contained"
                                                    color="info"
                                                    onClick={() => navigate('write')}
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                </InfoLayoutPage>
                        }
                    </React.Fragment>
            }

        </LoginChecker>
    )
}

export default ShareBoardPage;