import { ICTSearchBox } from '@ict-base/components/control/ICTSearchBox';
import KeriActionButton from '@ict-base/components/control/KeriActionButton';
import InfoLayoutPage from '@keri-portal/component/layout/InfoLayoutPage';
import { Assignment, Subtitles, Warning } from '@mui/icons-material';
import { Box, BoxProps, Divider, Paper, Typography, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useState, useMemo, useRef } from 'react'
import { IEducationCategory, IEducationItem } from '@keri-portal/interfaces/data-object/data-common/IEducation';
import { ObjectUtils } from '@ict-base/module/utils/ObjectUtils';
import useGetEducationScheduleList, { IUseGetEducationSchedule } from '@keri-portal/module/hook/api/education/useGetEducationScheduleList';

const EducationScheduleListPage = () => {

    const navigate = useNavigate();



    //#region Select Box Render

    const [, setCategoryList] = useState<IEducationCategory[]>([]);

    //#endregion


    //#region API Hook

    const [hookData, setHookData] = useState<IUseGetEducationSchedule>({
        page: 1,
        pagePerCount: 10,
        TriggerTime: new Date(),
        usePagination: true
    });
    const educationListHook = useGetEducationScheduleList(hookData);

    useMemo(() => setCategoryList(educationListHook.data?.resultData?.category ?? []), [educationListHook])

    useMemo(() => {
        if ((educationListHook.data?.totalPage ?? -1) < (educationListHook.data?.page ?? -1)) {
            let applyData = { ...hookData };
            applyData.page = educationListHook.data?.totalPage ?? -1;
            setHookData(applyData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [educationListHook.data?.page, educationListHook.data?.totalPage])

    //#endregion





    return (
        <InfoLayoutPage
            title='교육 일정 조회 및 신청'
            endAdornment={
                <Box
                    sx={{
                        width: '300px'
                    }}
                >
                    <ICTSearchBox
                        onSearch={(text) => setHookData({
                            ...hookData,
                            searchKeyword: text
                        })}
                    />
                </Box>
            }
        >

            {
                educationListHook.loading &&
                <Box
                    sx={{
                        width: "100%",
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        minHeight: '150px'
                    }}
                >
                    <CircularProgress />
                </Box>
            }
            {
                educationListHook.data?.resultData?.items.map(item => (
                    <ScheduleItem
                        key={item.educationId}
                        itemData={item}
                        onClick={(e) => {
                            navigate(item.educationId)
                        }}
                        onRequest={(e) => {
                            navigate(`${item.educationId}/request`)
                        }}
                    />
                ))
            }
            {
                (!educationListHook.loading && (educationListHook.data?.resultData?.items ?? []).length === 0) &&
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: "200px"
                    }}
                >
                    <Box
                        sx={{
                            textAlign: 'center'
                        }}
                    >
                        <Warning fontSize='large' />
                        <Typography>
                            데이터가 없습니다
                        </Typography>
                    </Box>

                </Box>
            }
        </InfoLayoutPage>
    )
}


const ScheduleItem = (prop: {
    itemData: IEducationItem,
    onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
    onRequest?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}) => {

    const requestBtRef = useRef<HTMLButtonElement>(null);

    return (
        <Paper
            elevation={0}
            sx={{
                border: '1px solid rgba(0,0,0,0.1)',
                p: 2,
                pt: 1,
                mb: 1,
                cursor: prop.onClick === undefined ? 'default' : 'pointer'
            }}
            onClick={(e) => {
                if ((requestBtRef.current ?? undefined) !== undefined) {
                    console.log(requestBtRef.current);
                    const widthMatch = requestBtRef.current!.getBoundingClientRect().left <= e.clientX && requestBtRef.current!.getBoundingClientRect().right >= e.clientX;
                    const heightMatch = requestBtRef.current!.getBoundingClientRect().top <= e.clientY && requestBtRef.current!.getBoundingClientRect().bottom >= e.clientY

                    if (!(widthMatch && heightMatch)) {
                        prop.onClick && prop.onClick(e)
                    }
                }
                else {
                    prop.onClick && prop.onClick(e)
                }
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}
            >
                <Box>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        <Subtitles
                            sx={{
                                mr: 1
                            }}
                        />
                        <Typography
                            variant='h6'
                        >

                            {prop.itemData.title}
                        </Typography>
                    </Box>
                </Box>
                <Box>
                    <KeriActionButton
                        buttonRef={requestBtRef}
                        className='request-education-bt'
                        label='교육 신청'
                        onClick={(e) => {
                            if (
                                prop.onRequest !== undefined
                                && prop.itemData.receiptState.StateName === '접수 중'
                                && new Date(Date.parse(ObjectUtils.DateToString(prop.itemData.requestOpenDate, 'yyyy-MM-dd') ?? '')) <= new Date(Date.parse(ObjectUtils.DateToString(Date.now(), 'yyyy-MM-dd') ?? ''))
                                && new Date(Date.parse(ObjectUtils.DateToString(prop.itemData.requestCloseDate, 'yyyy-MM-dd') ?? '')) >= new Date(Date.parse(ObjectUtils.DateToString(Date.now(), 'yyyy-MM-dd') ?? ''))
                            ) {
                                prop.onRequest && prop.onRequest(e)
                            }
                            else {
                                alert('신청 기간이 아닙니다.');
                            }

                        }}
                        variant="contained"
                        icon={<Assignment />}
                    />
                </Box>
            </Box>

            <Divider sx={{ mt: 0, mb: 1 }} />
            <Box>
                <ScheduleItemRow>

                    <ScheduleItemInfoBox
                        title='교육 기간'
                        content={`${ObjectUtils.DateToString(prop.itemData.classOpenDate, 'yyyy-MM-dd')} ~ ${ObjectUtils.DateToString(prop.itemData.classCloseDate, 'yyyy-MM-dd')}`}
                        BoxProp={{
                            sx: {
                                width: '50%'
                            }
                        }}
                    />
                    <ScheduleItemInfoBox
                        title='신청 기간'
                        content={`${ObjectUtils.DateToString(prop.itemData.requestOpenDate, 'yyyy-MM-dd')} ~ ${ObjectUtils.DateToString(prop.itemData.requestCloseDate, 'yyyy-MM-dd')}`}
                        BoxProp={{
                            sx: {
                                width: '50%'
                            }
                        }}
                    />

                </ScheduleItemRow>
                <ScheduleItemRow>
                    <ScheduleItemInfoBox
                        title='교육 상태'
                        content={prop.itemData.classState.StateName}
                        BoxProp={{
                            sx: {
                                width: '50%'
                            }
                        }}
                    />

                    <ScheduleItemInfoBox
                        title='신청 상태'
                        content={prop.itemData?.receiptState?.StateName ?? ''}
                        BoxProp={{
                            sx: {
                                width: '50%'
                            }
                        }}
                    />
                </ScheduleItemRow>
                <ScheduleItemRow>
                    <ScheduleItemInfoBox
                        title='카테고리'
                        content={prop.itemData?.category?.CategoryName ?? ''}
                        BoxProp={{
                            sx: {
                                width: '50%'
                            }
                        }}
                    />

                    <ScheduleItemInfoBox
                        title='강사'
                        content={prop.itemData.lecturer}
                        BoxProp={{
                            sx: {
                                width: '50%'
                            }
                        }}
                    />
                </ScheduleItemRow>

                <ScheduleItemRow>
                    <ScheduleItemInfoBox
                        title='교육 내용'
                        content={ObjectUtils.DecodeHTMLCode(prop.itemData.content?.replaceHTMLTag())}
                    />
                </ScheduleItemRow>

            </Box>
        </Paper>
    )
}

const ScheduleItemRow = (prop: {
    children?: React.ReactNode | React.ReactNode[]
}) => {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'start',
            }}
        >
            {prop.children}
        </Box>
    )
}

const ScheduleItemInfoBox = (prop: {
    BoxProp?: BoxProps,
    title?: string,
    content?: string | React.ReactNode
}) => {
    return (
        <Box
            {...prop.BoxProp}
            sx={{
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'center',
                width: '100%',
                mb: 0.5,
                mt: 0.5,
                ...prop.BoxProp?.sx
            }}

        >
            {
                prop.title !== undefined &&
                <Box
                    sx={{
                        mr: 2,
                        minWidth: '80px'
                    }}
                >
                    <Typography variant='subtitle1' sx={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}>
                        {prop.title}
                    </Typography>
                </Box>
            }

            <Box>
                {
                    typeof prop.content === 'string'
                        ?
                        <Typography variant='body2'>
                            {prop.content}
                        </Typography>
                        :
                        prop.content
                }

            </Box>
        </Box>
    )
}

export default EducationScheduleListPage;