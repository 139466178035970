import { IHookState, IKeriAPIHookResultPagination, IKeriAPIHookSearchProp } from "@keri-portal/interfaces/api/hook/IAPIHook";
import { IEducationCategory, IEducationItem, IEducationState } from "@keri-portal/interfaces/data-object/data-common/IEducation";
import RequestKeriAPI from "@keri-portal/module/method/api-connect/RequestKeriAPI";
import { useState, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";

export interface IUseGetEducationSchedule extends IKeriAPIHookSearchProp {
    educationCategory? : IEducationCategory[],
    educationState? : IEducationState[]
}

interface IEducationDataReturn {
    items : IEducationItem[],
    category : IEducationCategory[]
}

const useGetEducationScheduleList = (prop : IUseGetEducationSchedule) => {
    // const userRedux = useSelector((state : any) => state.user);
    const [hookState , setHookState] = useState<IHookState<IKeriAPIHookResultPagination<IEducationDataReturn>>>({
        loading : false,
        data : undefined,
        complete : false
    });

    

    const getData = async () => {
        setHookState({
            loading : true,
            complete : false
        })

        const callAPI = await RequestKeriAPI.Education.GetEducationScheduleList({
            page : prop.page,
            pagePerCount : prop.pagePerCount,
            searchText : prop.searchKeyword,
            selectCategory : (prop.educationCategory??[]).length === 0 ? undefined : prop.educationCategory,
            selectClassState :  (prop.educationState??[]).length === 0 ? undefined : prop.educationState,
        })

        console.log(callAPI);
        

        setHookState({
            loading: false,
            data: {
                state: callAPI.state,
                message: callAPI.message,
                statusCode: callAPI.statusCode,
                resultData : {
                    category : callAPI.categoryData??[],
                    items : callAPI.resultData??[]
                },
            },
            complete: true
        })
    
    }

    const returnData = useMemo(() => getData() , [prop.searchKeyword, prop.page, prop.pagePerCount,prop.educationCategory, prop.TriggerTime]);
  

    useEffect(() => {
        returnData
        .finally(() => {
        })
    },[prop])

    return hookState;
}

export default useGetEducationScheduleList;