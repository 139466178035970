import ICTCategorySearchModal, { ICTCategorySearchModalCategory } from "@ict-base/components/control/ICTCategorySearchModal"
import useLoginCheck from "@keri-portal/module/hook/useLoginCheck"
import RequestKeriAPI from "@keri-portal/module/method/api-connect/RequestKeriAPI"
import React, { useEffect, useState } from "react"

interface IKeriProductAttributeModel {
    open: boolean,
    onClose: () => void,
    // onSubmit: (item) => void,
    showCategory: KeriProductAttribute,
    submit?: {
        enable: boolean,
        onSubmit?: (item: any[]) => void,
        onFilter?: (item: any[]) => { state: boolean, message?: string }
    }
}

export type KeriProductAttribute = 'ProductName' | 'ProductType' | 'ALL'


const KeriProductAttributeModel = (prop: IKeriProductAttributeModel) => {

    const [openCategory, setOpenCategory] = useState<string | undefined>(undefined);
    const userRedux = useLoginCheck();

    const [modalTitle, setModalTitle] = useState<string>('품목명/구분 관리');

    useEffect(() => {
        if (prop.showCategory === 'ALL') {
            setModalTitle('품목명/구분 관리');
        }
        else if (prop.showCategory === 'ProductName') {
            setModalTitle('품목명 관리');
        }
        else if (prop.showCategory === 'ProductType') {
            setModalTitle('품목 구분 관리');
        }
        else {
            setModalTitle('-');
        }
    }, [prop.showCategory])

    const onSubmit = (item: any[]) => {
        const filtering = prop.submit?.onFilter !== undefined ? prop.submit.onFilter(item) : undefined
        if (filtering === undefined) {
            prop.submit?.onSubmit && prop.submit.onSubmit(item);
            prop.onClose();
        }
        else {
            if (filtering.state) {
                prop.submit?.onSubmit && prop.submit.onSubmit(item);
                prop.onClose();
            }
            else {
                alert(filtering.message);
            }
        }

    }

    return (


        <ICTCategorySearchModal
            open={prop.open}
            onClose={() => prop.onClose()}
            title={modalTitle}
            openCategory={(category) => {
                setOpenCategory(category)
            }}
        >
            <ICTCategorySearchModalCategory
                enable={prop.showCategory === 'ProductName' || prop.showCategory === 'ALL'}
                activeCategory={openCategory}
                category="품목명"
                dataLoad={{
                    getDataFunction: (page, searchText) => RequestKeriAPI.Product.GetProductName(userRedux?.token, page, searchText),
                    getDataArray: (item) => item.resultData ?? [],
                    getAPIState: {
                        state: (item) => item.state,
                        errorMessage: (item) => item.message
                    },
                    getPagination : {
                        nowPage : (item) => item.pageObject?.nowPage,
                        totalPage : (item) => item.pageObject?.totalPage
                    }
                }}
                display={{
                    displayItemTitle: (item) => item.productName??'',
                    displayKey : (item) => `${item.productName}-${item.productNameSequence}`
                }}
                event={{
                    onSubmit: (item) => onSubmit(item),
                    onClose: () => { prop.onClose() }
                }}
                addData={{
                    addDataFunction: (addText) => RequestKeriAPI.Product.AddProductName({ productName: addText }, userRedux?.token),
                    addAPIState: {
                        state: (item) => item.state,
                        errorMessage: (item) => item.message
                    }
                }}
                deleteData={{
                    deleteDataFunction: (item) => RequestKeriAPI.Product.DeleteProductName(item, userRedux?.token),
                    deleteAPIState: {
                        state: (item) => item.state,
                        errorMessage: (item) => item.message
                    }
                }}
                elementProps={{
                    activeSubmit: (prop.showCategory !== 'ALL' && (prop.submit?.enable ?? false))
                }}
            />
            <ICTCategorySearchModalCategory
                enable={prop.showCategory === 'ProductType' || prop.showCategory === 'ALL'}
                activeCategory={openCategory}
                category="품목 구분"
                dataLoad={{
                    getDataFunction: (page, searchText) => RequestKeriAPI.Product.GetProductType(userRedux?.token, page, searchText),
                    getDataArray: (item) => item.resultData ?? [],
                    getAPIState: {
                        state: (item) => item.state,
                        errorMessage: (item) => item.message
                    },
                    getPagination : {
                        nowPage : (item) => item.pageObject?.nowPage,
                        totalPage : (item) => item.pageObject?.totalPage
                    }
                }}
                display={{
                    displayItemTitle: (item) => item.productTypeName??'',
                    displayKey : (item) => `${item.productTypeName}-${item.productTypeSequence}`
                }}
                event={{
                    onSubmit: (item) => onSubmit(item),
                    onClose: () => { prop.onClose() }
                }}
                addData={{
                    addDataFunction: (addText) => RequestKeriAPI.Product.AddProductType({ productTypeName: addText }, userRedux?.token),
                    addAPIState: {
                        state: (item) => item.state,
                        errorMessage: (item) => item.message
                    }
                }}
                deleteData={{
                    deleteDataFunction: (item) => RequestKeriAPI.Product.DeleteProductType(item, userRedux?.token),
                    deleteAPIState: {
                        state: (item) => item.state,
                        errorMessage: (item) => item.message
                    }
                }}
                selectData={{
                    selectFilter: (item) => item.length > 1 ? { state: false, message: '1개 이상 선택 불가' } : { state: true }
                }}
                elementProps={{
                    activeSubmit: (prop.showCategory !== 'ALL' && (prop.submit?.enable ?? false))
                }}
            />

        </ICTCategorySearchModal>
    )
}


export default KeriProductAttributeModel;
