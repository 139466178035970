import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import ReduxStore from './modules/redux/ReduxStore';
import { ThemeProvider } from '@mui/styles';
import { createTheme } from '@mui/system';
import './modules/i18n/i18n';
import ResetScroll from './@ict-base/components/common/ResetScroll';
import { QueryClientProvider, QueryClient } from 'react-query';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const queryClient = new QueryClient();

root.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={ReduxStore}>
      <BrowserRouter>
        <ResetScroll />
        <App />
      </BrowserRouter>
    </Provider>
  </QueryClientProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
