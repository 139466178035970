const SessionStorageServ = {
    getData : <T extends any = any>(storageKey : string) : T | undefined => {
        let storageObj  = sessionStorage.getItem(storageKey);
        return storageObj === null ? undefined : JSON.parse(storageObj);
    },
    setData : (storageKey : string,data : any) => {
        sessionStorage.setItem(storageKey, JSON.stringify(data));
    },
    removeData : (storageKey : string) => {
        sessionStorage.removeItem(storageKey);
    }   
}

export default SessionStorageServ;