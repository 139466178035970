import URLStructure from "@src/modules/structure/URLStructure"

const KeriConfig = {

    DenyUploadFileExt: [
        'jsp', 'cgi', 'php', 'php3', 'asp', 'aspx', 'exe', 'com', 'html', 'htm', 'cab', 'pl', 'java', 'class', 'js', 'css'
    ],
    UploadMaxFileSize : 1024*1024*500,
    ThumbnailPlaceholder : `${URLStructure.Asset}/img/ict/images/logo_f.svg`

}




export default KeriConfig