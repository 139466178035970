import * as React from 'react';
import Box from '@mui/material/Box';
import { createTheme, Theme, ThemeProvider } from '@mui/material/styles';
import { IPageProp } from "@keri-base/interface/common/IPageProp";
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Paper } from '@mui/material';
// import { defaultTheme } from '../layout/DefaultLayoutPage';
import { SxProps } from '@mui/system';
import FindAccountComp from '@keri-portal/component/control/login/FindAccountComp';
import LoginComp from '@keri-portal/component/control/login/LoginComp';
import FindPasswordComp from '@keri-portal/component/control/login/FindPasswordComp';
import { defaultTheme } from '@keri-portal/component/layout/DefaultLayoutPage';
import URLStructure from '@src/modules/structure/URLStructure';
import LoginChecker from '@keri-base/components/common/LoginChecker';
import { ILoginSessionData, removeUser, setUser } from '@src/modules/redux/reducer/UserDataReducer';
import RequestKeriAPI from '@keri-portal/module/method/api-connect/RequestKeriAPI';

const pageTheme = createTheme({
  typography: {
    fontFamily: 'Noto Sans KR'
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '&.MuiInputLabel-shrink': {
            fontSize: '0.1rem',
            transform: 'translate(14px, -19px) scale(0.75)'
          },
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        text: {
          fontFamily: ['Noto Sans KR'].join(',')
        }
      }
    }
  }
})


export const labelButtonStyle: SxProps<Theme> = {
  fontSize: '0.8rem',
  color: '#03a9f4',
  cursor: 'pointer',
  ':hover': {
    color: '#026e9f',
    textDecoration: 'underline'
  }
}

const LoginViewPage = (prop: IPageProp) => {

  const navigate = useNavigate();





  const [activePage, setActivePage] = React.useState<number>(0);





  //#region Reset Password









  //#endregion

  //#region Redux 
  const dispatch = useDispatch();
  //#endregion

  //#region redirect
  const location = useLocation();
  const paramString = location.search.slice(1, location.search.length);
  let paramDictionary: any = {};
  paramString.split('&').forEach(x => {
    paramDictionary = {
      ...paramDictionary,
      [x.split('=')[0]]: x.split('=')[1]
    }
  })
  //#endregion


  const [runAPIState, setRunAPIState] = React.useState<boolean>(false);







  return (
    <LoginChecker
      onCheck={async (state, data) => {
        if (state === 'LOGIN') {
          prop.EventFunction.EnableBackDrop(true);

          const getAuthCheck = await RequestKeriAPI.Common.CheckTokenValidation(data.token ?? '');

          if (getAuthCheck.state) {
            dispatch(setUser({
              loadComplete: true,
              token: getAuthCheck.refreshAccessToken,
              expireData: getAuthCheck.expireDate,
              resultVO: data.resultVO
            }))
            alert('이미 로그인된 브라우저입니다. \n메인으로 이동합니다.');
            navigate(paramDictionary.redirect ?? URLStructure.Main, { replace: true })
          }
          else {
            dispatch(removeUser());
          }

          prop.EventFunction.EnableBackDrop(false);
        }
      }}
    >
      <ThemeProvider theme={{
        ...defaultTheme,
        ...pageTheme
      }}>
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#edf1f6'
          }}
        >
          <Paper
            elevation={3}
            sx={{
              p: 3,
              width: '350px',
              alignItems: 'center',
              textAlign: 'center'
            }}
          >
            {
              activePage === 0 &&
              <LoginComp {...prop}
                pageControl={{
                  activePage: activePage,
                  setActivePage: setActivePage
                }}
                paramDictionary={paramDictionary}
                apiStateControl={{
                  setState: setRunAPIState,
                  state: runAPIState
                }}
              />
            }
            {
              activePage === 1 &&
              <FindAccountComp
                pageControl={{
                  activePage: activePage,
                  setActivePage: setActivePage
                }}
              />
            }
            {
              activePage === 2 &&
              <FindPasswordComp
                pageControl={{
                  activePage: activePage,
                  setActivePage: setActivePage
                }}
                pageProp={prop}
              />
            }


          </Paper>
        </Box>
      </ThemeProvider>
    </LoginChecker>



  );
}

export default LoginViewPage;