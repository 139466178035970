import ICTAgreeGroup, { IICTAgreeItem } from "@ict-base/components/control/ICTAgreeGroup";
import { IKeriStepShareProps, KeriStepItemLayout, KeriStepItemContent, KeriStepItemSubContent } from "@keri-portal/component/layout/StepLayout";
import { IAccountRegister } from "@keri-portal/interfaces/process-object/account/IAccountRegister";
import AccountRegisterCommonValidationCheck, { IAccountRegisterProvideInformationAgreeError } from "@keri-portal/module/method/validation-check/account-register/AccountRegisterCommonValidationCheck";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";



const KeriRegisterStep2Item = (prop: IKeriStepShareProps<IAccountRegister>) => {
    const location = useLocation();
    const [formData, setFormData] = useState<IICTAgreeItem[]>([

        {
            idx: 1,
            title: '이용약관 동의',
            htmlName: `user_guide`,
            required: true
        },
        {
            idx: 2,
            title: '개인정보 수집·이용 동의',
            htmlName: `pi-consent-1`,
            required: true
        },
        {
            idx: 3,
            title: '개인정보 제3자 제공 및 위탁 제공 동의',
            htmlName: `pi-consent-2`,
            required: true
        }

    ]);
    const [errorData, setErrorData] = useState<IAccountRegisterProvideInformationAgreeError>({});

    const validationCheck = async (): Promise<boolean> => {

        let isError: boolean = false;

        const validationMethod = await AccountRegisterCommonValidationCheck.ProvideInformationAgree({ data: formData });

        if (!validationMethod.state) {
            isError = !validationMethod.state;
            setErrorData(validationMethod.errorData);
        }

        return !isError;
    }

    return (
        <KeriStepItemLayout
            {...prop}
            validationCheck={validationCheck}
            step={prop.step}
            page={{
                moveNext: (setup, validation) => {

                    if (validation) {
                        prop.result.onChange({
                            ...prop.result.data,
                            RequestAgree: {
                                data: formData
                            }
                        })
                        setup(prop.step.activeStep + 1);
                    }
                }
            }}
        >
            <KeriStepItemContent
                title='정보제공 동의'
            >
                <KeriStepItemSubContent>
                    <ICTAgreeGroup
                        data={formData}
                        nowUrl={location.pathname}
                        event={{
                            onChange: (item) => {
                                setFormData(item)

                                if(errorData.Agree !== undefined){
                                    setErrorData({});
                                }
                            }
                        }}
                        error={{
                            isError: errorData.Agree !== undefined,
                            message: errorData.Agree
                        }}
                    />
                </KeriStepItemSubContent>
               
            </KeriStepItemContent>
        </KeriStepItemLayout>
    )
}

export default KeriRegisterStep2Item;