import { KeriEditGrid, KeriEditGridInputField } from "@ict-base/components/control/KeriEditGrid";
import { ITechServiceObjectData } from "@keri-portal/interfaces/data-object/tech-service/ITechService";

import { IRegisterTechServiceRequestInputFieldErrorData } from "./RegisterTechServiceRequestInputField";

interface IRegisterTechServiceRequesterInputField{
    value : ITechServiceObjectData,
    onChange : (item : ITechServiceObjectData) => void
    error?: {
        data: IRegisterTechServiceRequestInputFieldErrorData,
        onChange: (item: IRegisterTechServiceRequestInputFieldErrorData) => void
    },
    editMode? : boolean
}

const RegisterTechServiceRequesterInputField = (prop : IRegisterTechServiceRequesterInputField) => {
    return (
        <KeriEditGrid>
            <KeriEditGridInputField 
                title="부서/직급"
                props={{
                    width : '50%'
                }}
                editDisabled={!(prop.editMode??false)}
                onChange={(item) => {
                    prop.onChange({
                        ...prop.value,
                        RequesterData : {
                            ...prop.value.RequesterData,
                            RequesterDepartment : item
                        }
                    })

                    if(prop.error?.data.requesterDept !== undefined){
                        prop.error.onChange({
                            ...prop.error.data,
                            requesterDept : undefined
                        })
                    }
                }}
                value={prop.value.RequesterData?.RequesterDepartment}
                error={{
                    isError : prop.error?.data.requesterDept !== undefined,
                    message : prop.error?.data.requesterDept
                }}
            />
            <KeriEditGridInputField 
                title="신청자"
                props={{
                    width : '50%'
                }}
                editDisabled={!(prop.editMode??false)}
                value={prop.value.RequesterData?.RequesterName}
                error={{
                    isError : prop.error?.data.requesterName !== undefined,
                    message : prop.error?.data.requesterName
                }}
                onChange={(item) => {
                    console.log('change requester', item);
                    console.log(prop.value);
                    prop.onChange({
                        ...prop.value,
                        RequesterData : {
                            ...prop.value.RequesterData,
                            RequesterName : item
                        }
                    })

                    if(prop.error?.data.requesterName !== undefined){
                        prop.error.onChange({
                            ...prop.error.data,
                            requesterName : undefined
                        })
                    }
                }}
            />
            <KeriEditGridInputField 
                title="휴대전화 번호"
                props={{
                    width : '50%'
                }}
                editDisabled={!(prop.editMode??false)}
                value={prop.value.RequesterData?.RequesterCallNumber}
                error={{
                    isError : prop.error?.data.requesterCallNumber !== undefined,
                    message : prop.error?.data.requesterCallNumber
                }}
                onChange={(item , e) => {
                    e.target.value = e.target.value.replace(/[^0-9]/g, '').replace(/^(02|01[0-9]{1}|0[3-6]{1}[1-5]{1})(\d{3,4})(\d{4})$/, `$1-$2-$3`)

                    prop.onChange({
                        ...prop.value,
                        RequesterData : {
                            ...prop.value.RequesterData,
                            RequesterCallNumber : item.replace(/[^0-9]/g, '').replace(/^(02|01[0-9]{1}|0[3-6]{1}[1-5]{1})(\d{3,4})(\d{4})$/, `$1-$2-$3`)
                        }
                    })

                    if(prop.error?.data.requesterCallNumber !== undefined){
                        prop.error.onChange({
                            ...prop.error.data,
                            requesterCallNumber : undefined
                        })
                    }
                }}
            />
            <KeriEditGridInputField 
                title="이메일"
                props={{
                    width : '50%'
                }}
                editDisabled={!(prop.editMode??false)}
                value={prop.value.RequesterData?.RequesterEmail}
                error={{
                    isError : prop.error?.data.requesterEmail !== undefined,
                    message : prop.error?.data.requesterEmail
                }}
                onChange={(item) => {
                    prop.onChange({
                        ...prop.value,
                        RequesterData : {
                            ...prop.value.RequesterData,
                            RequesterEmail : item
                        }
                    })

                    if(prop.error?.data.requesterEmail !== undefined){
                        prop.error.onChange({
                            ...prop.error.data,
                            requesterEmail : undefined
                        })
                    }
                }}
            />
            
        </KeriEditGrid>
    )
}

export default RegisterTechServiceRequesterInputField;