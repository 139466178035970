import ICTBaseModal, { KeriModalCancelButton, KeriModalSubmitAndCancelButton } from '@ict-base/components/control/ICTBaseModal';
import { ICTSearchBox } from '@ict-base/components/control/ICTSearchBox';
import KeriActionButton from '@ict-base/components/control/KeriActionButton';
import { ObjectUtils } from '@ict-base/module/utils/ObjectUtils';
import { IEducationCategory, IEducationItem } from '@keri-portal/interfaces/data-object/data-common/IEducation';
import useGetEducationCategory, { IUseGetEducationCategory } from '@keri-portal/module/hook/api/education/useGetEducationCategory';
import useGetEducationScheduleList, { IUseGetEducationSchedule } from '@keri-portal/module/hook/api/education/useGetEducationScheduleList';
import { Subtitles, Assignment, Search, Warning } from '@mui/icons-material';
import { Box, BoxProps, Divider, Paper, Typography, CircularProgress } from '@mui/material';
import Chip from '@mui/material/Chip';
import React, { useRef, useState, useMemo, useEffect } from 'react';

interface IForwardModalFunc {

}

interface IKeriSelectEducationModal {
    open: boolean,
    onClose: () => void,
    onSubmit: (item: IEducationItem) => void
}

const KeriSelectEducationModal = React.forwardRef<IForwardModalFunc, IKeriSelectEducationModal>((prop: IKeriSelectEducationModal, ref) => {

    //#region Select Box Render

    const [categoryList, setCategoryList] = useState<IEducationCategory[]>([]);
    const [openDetailSearch, setOpenDetailSearch] = useState<boolean>(false);

    //#endregion

    //#region API Hook


    const [hookData, setHookData] = useState<IUseGetEducationSchedule>({
        page: 1,
        pagePerCount: 10,
        TriggerTime: new Date(),
        usePagination: true
    });
    const educationListHook = useGetEducationScheduleList(hookData);

    useMemo(() => setCategoryList(educationListHook.data?.resultData?.category ?? []), [educationListHook])

    useMemo(() => {
        if ((educationListHook.data?.totalPage ?? -1) < (educationListHook.data?.page ?? -1)) {
            let applyData = { ...hookData };
            applyData.page = educationListHook.data?.totalPage ?? -1;
            setHookData(applyData);
        }
    }, [educationListHook.data?.page, educationListHook.data?.totalPage])

    useMemo(() => {
        setHookData({
            ...hookData,
            educationCategory: undefined,
            educationState: undefined
        })
    }, [openDetailSearch])






    const [categoryHookData, setCategoryHookData] = useState<IUseGetEducationCategory>({
        TriggerTime: new Date()
    })
    const educationCategoryHook = useGetEducationCategory(categoryHookData);


    useMemo(() => setCategoryList(educationCategoryHook.data?.resultData ?? []), [educationCategoryHook]);



    useEffect(() => {
        console.log('RENDER ED', educationListHook)
        if (educationListHook.complete) {
            if (educationListHook.data?.state === false) {
                alert(educationListHook.data.message);
            }
        }
    }, [educationListHook])



    //#endregion


    return (
        <ICTBaseModal
            title='교육 선택'
            open={prop.open}
            onClose={prop.onClose}
            submitButton={
                <KeriModalCancelButton
                    onCancel={prop.onClose}
                />
            }
        >
            <Paper
                elevation={0}
                sx={{
                    p: 1,
                    height: '70vh',
                    overflow: 'auto',
                    width: '80vw'
                }}
            >
                {
                    (educationListHook.loading && educationCategoryHook.loading && !educationCategoryHook.complete && !educationListHook.complete)
                        ?
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '200px'
                            }}
                        >
                            <CircularProgress />
                        </Box>
                        :
                        <React.Fragment>
                            <Box className='ModalSearchToolBar'
                                sx={{
                                    mb: 1,
                                    height: openDetailSearch ? '120px' : '40px',
                                    overflow: 'auto'
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center'
                                    }}
                                >
                                    <Box
                                        sx={{
                                            width: 'calc(100% - 150px)'
                                        }}
                                    >
                                        <ICTSearchBox
                                            onSearch={(searchText) => {
                                                setHookData({
                                                    ...hookData,
                                                    searchKeyword: searchText
                                                })
                                            }}
                                        />
                                    </Box>
                                    <Box
                                        sx={{
                                            width: '150px'
                                        }}
                                    >
                                        <KeriActionButton
                                            label='상세 조회'
                                            variant='contained'
                                            sx={{
                                                mt: 0,
                                                mb: 0,
                                                ml: 1,
                                                width: '130px'
                                            }}
                                            icon={<Search />}
                                            onClick={() => setOpenDetailSearch(!openDetailSearch)}
                                        />
                                    </Box>

                                </Box>
                                {
                                    openDetailSearch &&
                                    <Box>
                                        <Divider sx={{ mt: 1, mb: 1 }} />
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'start',
                                                alignItems: 'start'
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    p: 1,
                                                    whiteSpace: 'nowrap'
                                                }}
                                            >
                                                <Typography
                                                    variant='subtitle1'
                                                    sx={{
                                                        lineHeight: '30px',
                                                        fontWeight: 'bold'
                                                    }}
                                                >
                                                    카테고리
                                                </Typography>
                                            </Box>
                                            <Box
                                                sx={{
                                                    p: 1,
                                                    overflow: 'auto',
                                                    height: '60px'
                                                }}
                                            >
                                                {
                                                    (educationCategoryHook.data?.resultData ?? []).map(categoryItem => (
                                                        <Chip
                                                            label={categoryItem.CategoryName}
                                                            sx={{
                                                                mr: 1,
                                                                mb: 1,
                                                                ...((hookData.educationCategory?.filter(x => x.CategoryID === categoryItem.CategoryID) ?? []).length !== 0 ? {
                                                                    background: 'rgba(0,0,0,0.4)',
                                                                    color: 'white'
                                                                } : {})
                                                            }}
                                                            onClick={() => {
                                                                if ((hookData.educationCategory?.filter(x => x.CategoryID === categoryItem.CategoryID) ?? []).length !== 0) {
                                                                    setHookData({
                                                                        ...hookData,
                                                                        educationCategory: (hookData.educationCategory?.filter(x => x.CategoryID !== categoryItem.CategoryID) ?? [])
                                                                    })
                                                                }
                                                                else {
                                                                    setHookData({
                                                                        ...hookData,
                                                                        educationCategory: [
                                                                            ...(hookData.educationCategory?.filter(x => x.CategoryID !== categoryItem.CategoryID) ?? []),
                                                                            categoryItem
                                                                        ]
                                                                    })
                                                                }

                                                            }}
                                                        />
                                                    ))
                                                }

                                            </Box>
                                        </Box>
                                    </Box>
                                }
                            </Box>
                            <Box
                                sx={{
                                    height: `calc(100% - ${openDetailSearch ? 130 + 10 : 40 + 10}px)`,
                                    overflow: 'auto'
                                }}
                            >
                                {
                                    (educationListHook.data?.resultData?.items ?? []).map(eduItem => (
                                        <ScheduleItem
                                            itemData={eduItem}
                                            onClick={(e) => {
                                                prop.onSubmit(eduItem);
                                                prop.onClose();
                                            }}
                                        />
                                    ))
                                }
                                {
                                    (educationListHook.data?.resultData?.items ?? []).length === 0 &&
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            width: '100%',
                                            height: '150px'
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                textAlign: 'center'
                                            }}
                                        >
                                            <Warning fontSize='large' sx={{ color: '#25476a' }} />
                                            <Typography variant='h6'>
                                                검색된 값이 없습니다.
                                            </Typography>
                                        </Box>

                                    </Box>
                                }
                            </Box>
                            <Box className='ModalPagination'>

                            </Box>
                        </React.Fragment>
                }

            </Paper>
        </ICTBaseModal>

    )
})





const ScheduleItem = (prop: {
    itemData: IEducationItem,
    onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
    onRequest?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}) => {

    const requestBtRef = useRef<HTMLButtonElement>(null);

    return (
        <Paper
            elevation={0}
            sx={{
                border: '1px solid rgba(0,0,0,0.1)',
                p: 2,
                mb: 1,
                cursor: prop.onClick === undefined ? 'default' : 'pointer'
            }}
            onClick={(e) => {
                if (requestBtRef.current !== undefined && prop.onRequest !== undefined) {
                    const widthMatch = requestBtRef.current!.getBoundingClientRect().left <= e.clientX && requestBtRef.current!.getBoundingClientRect().right >= e.clientX;
                    const heightMatch = requestBtRef.current!.getBoundingClientRect().top <= e.clientY && requestBtRef.current!.getBoundingClientRect().bottom >= e.clientY

                    if (!(widthMatch && heightMatch)) {
                        prop.onClick && prop.onClick(e)
                    }
                }
                else {
                    prop.onClick && prop.onClick(e);
                }
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <Subtitles
                        sx={{
                            mr: 1
                        }}
                    />
                    <Typography
                        variant='h6'
                    >

                        {prop.itemData.title}
                    </Typography>
                </Box>

                <Box>
                    {
                        prop.onRequest !== undefined &&
                        <KeriActionButton
                            buttonRef={requestBtRef}
                            className='request-education-bt'
                            label='교육 신청'
                            onClick={(e) => prop.onRequest && prop.onRequest(e)}
                            variant="contained"
                            icon={<Assignment />}
                        />
                    }

                </Box>
            </Box>

            <Divider sx={{ mt: 0.5, mb: 1 }} />
            <Box>
                <ScheduleItemRow>

                    <ScheduleItemInfoBox
                        title='교육 기간'
                        content={`${ObjectUtils.DateToString(prop.itemData.classOpenDate, 'yyyy-MM-dd')} ~ ${ObjectUtils.DateToString(prop.itemData.classCloseDate, 'yyyy-MM-dd')}`}
                        BoxProp={{
                            sx: {
                                width: '50%'
                            }
                        }}
                    />
                    <ScheduleItemInfoBox
                        title='신청 기간'
                        content={`${ObjectUtils.DateToString(prop.itemData.requestOpenDate, 'yyyy-MM-dd')} ~ ${ObjectUtils.DateToString(prop.itemData.requestCloseDate, 'yyyy-MM-dd')}`}
                        BoxProp={{
                            sx: {
                                width: '50%'
                            }
                        }}
                    />

                </ScheduleItemRow>
                <ScheduleItemRow>
                    <ScheduleItemInfoBox
                        title='카테고리'
                        content={prop.itemData.category.CategoryName}
                        BoxProp={{
                            sx: {
                                width: '50%'
                            }
                        }}
                    />

                    <ScheduleItemInfoBox
                        title='강사'
                        content={prop.itemData.lecturer}
                        BoxProp={{
                            sx: {
                                width: '50%'
                            }
                        }}
                    />
                </ScheduleItemRow>

                <ScheduleItemRow>
                    <ScheduleItemInfoBox
                        title='교육 내용'
                        content={prop.itemData.content?.replaceHTMLTag()}
                    />
                </ScheduleItemRow>

            </Box>
        </Paper>
    )
}

const ScheduleItemRow = (prop: {
    children?: React.ReactNode | React.ReactNode[]
}) => {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'start',
            }}
        >
            {prop.children}
        </Box>
    )
}

const ScheduleItemInfoBox = (prop: {
    BoxProp?: BoxProps,
    title?: string,
    content?: string
}) => {
    return (
        <Box
            {...prop.BoxProp}
            sx={{
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'center',
                width: '100%',
                mb: 0.5,
                mt: 0.5,
                ...prop.BoxProp?.sx
            }}

        >
            {
                prop.title !== undefined &&
                <Box
                    sx={{
                        mr: 2,

                    }}
                >
                    <Typography variant='subtitle1' sx={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}>
                        {prop.title}
                    </Typography>
                </Box>
            }

            <Box>
                <Typography variant='body2'>
                    {prop.content}
                </Typography>
            </Box>
        </Box>
    )
}

export default KeriSelectEducationModal;