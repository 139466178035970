import { TextFieldProps } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { ObjectUtils } from "@ict-base/module/utils/ObjectUtils";
import { KeriEditGrid, KeriEditGridInputField, KeriEditGridImageField } from "@ict-base/components/control/KeriEditGrid";
import ICTBaseModal, { KeriModalSubmitAndCancelButton } from "@ict-base/components/control/ICTBaseModal";
import KeriEditProductNameAndTypeModal from "./KeriEditProductNameAndTypeModal";
import { IKeriProduct, IKeriProductName, IKeriProductType } from "@keri-portal/interfaces/data-object/IProduct";
import KeriProductAttributeModel, { KeriProductAttribute } from "./KeriProductAttributeModel";
import RequestKeriAPI from "@keri-portal/module/method/api-connect/RequestKeriAPI";
import useLoginCheck from "@keri-portal/module/hook/useLoginCheck";
import { IUploadFile } from "@keri-portal/interfaces/control/IUploadFile";
import { IUploadFileVO } from "@keri-portal/interfaces/api/keri-api/object/IUploadFileVO";
import KeriProductValidationCheck from "@keri-portal/module/method/validation-check/product/KeriProductValidationCheck";

interface IKeriAddProductItemsModal {
    open: boolean,
    onClose: () => void,
    onSubmit: () => void
}

interface IKeriSubmitItemsData {
    type?: IKeriProductType,
    name?: IKeriProductName,
    code?: string,
    image?: File
}


const KeriAddProductItemsModal = (prop: IKeriAddProductItemsModal) => {

    const userRedux = useLoginCheck();

    // Input Data
    const [productNumber, setProductNumber] = useState<string | undefined>(undefined);
    const [productType, setProductType] = useState<IKeriProductType | undefined>(undefined);
    const [productName, setProductName] = useState<IKeriProductName | undefined>(undefined);
    const [productImage, setProductImage] = useState<IUploadFile<IUploadFileVO> | undefined>(undefined);

    // Error Data
    const [errorData, setErrorData] = useState<{
        productNumber?: string,
        productType?: string,
        productName?: string,
        productImage?: string
    }>({});


    // Modal Control
    const [openProductAttributeModal, setOpenProductAttributeModal] = useState<{ state: boolean, category?: KeriProductAttribute }>({ state: false });


    // Function
    const validationCheck = (): { state: boolean, message?: string, data?: IKeriProduct } => {
        let result: { state: boolean, message?: string ,  data?: IKeriProduct } = { state: true }

        const valCheck = KeriProductValidationCheck.addProduct({
            productImage : productImage,
            productName : productName,
            productNumber : productNumber,
            productType : productType
        })

        if(!valCheck.state){
            setErrorData(valCheck.errorData);
            result.state = false;
            
        }
        else{
            result.data = {
                productImage : productImage!,
                productName : productName!,
                productNumber : productNumber!,
                productType : productType!,
            }
        }

        return result;
    }


    const addProduct = async (data : IKeriProduct | undefined): Promise<boolean> => {

        let state : boolean = false;

        const getRequest = await RequestKeriAPI.Product.AddProduct(userRedux?.token,data);

        if(getRequest.state){
            state = true;
        }
        else{
            alert(getRequest.message);
            
            if(!getRequest.tokenValid){
                // TODO : MOVE LOGIN PAGE 
            }
            state = false;
        }

        return state;
    }





    // const addDataToAPI = async (data: IKeriSubmitItemsData): Promise<boolean> => {
    //     const getResponse = await RequestKeriAPI.Product.AddProduct(userRedux?.token, {
    //         productName: data.name!,
    //         productType: data.type!,
    //         productImage: {
    //             Index: 1,
    //             Category: 'PROD_IMAGE',
    //             Label: 'THUMBNAIL',
    //             Prefix: 'PRODIMG_',
    //         },
    //         productNumber: data.code!
    //     })

    //     if (getResponse.state) {
    //         return true;
    //     }
    //     else {
    //         alert(getResponse.message);
    //         if (!getResponse.tokenValid) {

    //         }

    //         return false;
    //     }
    // }

    useEffect(() => {
        setErrorData({});
        setProductImage(undefined);
        setProductName(undefined);
        setProductType(undefined);
        setProductNumber(undefined);
    }, [prop.open])


    return (
        <ICTBaseModal
            open={prop.open}
            onClose={() => {
                if (window.confirm('작성중인 데이터가 있습니다. \n팝업을 종료할까요?')) {
                    prop.onClose !== undefined && prop.onClose()
                }

            }}
            title="품목 추가"
            submitButton={
                <KeriModalSubmitAndCancelButton
                    onCancel={() => prop.onClose !== undefined && prop.onClose()}
                    onSubmit={async (setLoading) => {

                        setLoading(true);

                        const valCheck = validationCheck();

                        if (valCheck.state) {
                            const callRequest = await addProduct(valCheck.data);

                            if (callRequest) {
                                prop.onSubmit !== undefined && prop.onSubmit()
                            }
                        }


                        setLoading(false);



                    }}
                />
            }
        >

            <KeriEditGrid
                sx={{
                    width: '600px'
                }}
            >
                <KeriEditGridInputField
                    title="품목 구분"
                    required
                    editDisabled
                    value={productType?.productTypeName}
                    // value={selectItemTypeList.length.toString()}
                    button={{
                        isEnable: true,
                        label: '선택',
                        onClick: () => {
                            setOpenProductAttributeModal({
                                state: true,
                                category: 'ProductType'
                            })
                        },
                        sx: {
                            backgroundColor: "black",
                            border: '1px solid black',
                            ":hover": {
                                backgroundColor: "rgba(0,0,0,0.8)",
                                border: '1px solid rgba(0,0,0,0.8)',
                            }
                        }
                    }}
                    tooltip={{
                        enable: true,
                        message: "test"
                    }}
                    error={{
                        isError: errorData.productType !== undefined,
                        message: errorData.productType
                    }}
                />

                <KeriEditGridInputField
                    title="품목 이름"
                    required
                    editDisabled
                    value={productName?.productName}
                    button={{
                        isEnable: true,
                        label: '선택',
                        onClick: () => {
                            setOpenProductAttributeModal({
                                state: true,
                                category: 'ProductName'
                            })
                        },
                        sx: {
                            backgroundColor: "black",
                            border: '1px solid black',
                            ":hover": {
                                backgroundColor: "rgba(0,0,0,0.8)",
                                border: '1px solid rgba(0,0,0,0.8)',
                            }
                        }
                    }}
                    error={{
                        isError: errorData.productName !== undefined,
                        message: errorData.productName
                    }}
                />



                <KeriEditGridInputField
                    title="품목 번호"
                    required
                    error={{
                        isError: errorData.productNumber !== undefined,
                        message: errorData.productNumber
                    }}
                    onChange={(changeText) => {
                        setProductNumber(changeText);

                        if (errorData.productNumber !== undefined) {
                            setErrorData({
                                ...errorData,
                                productNumber: undefined
                            })
                        }
                    }}
                />

                <KeriEditGridImageField
                    title="품목 이미지"
                    // required
                    limitFileCount={1}
                    tooltip={{
                        enable: true,
                        message: 'png, jpg 등 이미지 파일만 가능합니다.'
                    }}
                    onItemChanged={(items) => {
                        console.log('image',items);
                        if (items.length === 0) {
                            setProductImage(undefined);
                        }
                        else {
                            setProductImage(items[0]);

                            if (errorData.productImage !== undefined) {
                                setErrorData({
                                    ...errorData,
                                    productImage: undefined
                                })
                            }
                        }
                    }}
                    error={{
                        isError: errorData.productImage !== undefined,
                        message: errorData.productImage
                    }}
                    itemProps={{
                        category: 'Product thumbnail',
                        prefix: 'PROD_THUMB_'
                    }}
                />
            </KeriEditGrid>



            {/* Modal */}
            <KeriProductAttributeModel
                open={openProductAttributeModal.state}
                onClose={() => setOpenProductAttributeModal({
                    state: false
                })}
                showCategory={openProductAttributeModal.category!}
                submit={{
                    enable: true,
                    onFilter: (item) => {
                        if (item.length > 1) {
                            return { state: false, message: '한개만 선택이 가능합니다.' };
                        }
                        else if (item.length === 0) {
                            return { state: false, message: '항목을 선택해야 합니다.' };
                        }

                        return { state: true };
                    },
                    onSubmit: (item) => {
                        if (openProductAttributeModal.category === 'ProductName') {
                            setProductName(item[0] as IKeriProductName);

                            if (errorData.productName !== undefined) {
                                setErrorData({
                                    ...errorData,
                                    productName: undefined
                                })
                            }
                        }
                        else if (openProductAttributeModal.category === 'ProductType') {
                            setProductType(item[0] as IKeriProductType);

                            if (errorData.productType !== undefined) {
                                setErrorData({
                                    ...errorData,
                                    productType: undefined
                                })
                            }
                        }


                    }
                }}
            />



        </ICTBaseModal>
    )
}

export default KeriAddProductItemsModal;