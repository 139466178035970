import KeriActionButton from "@ict-base/components/control/KeriActionButton";
import KeriCheckBox, { KeriCheckLabel } from "@ict-base/components/control/KeriCheckBox";
import { KeriEditGrid, KeriEditGridInputField, KeriEditGridWebEditorField } from "@ict-base/components/control/KeriEditGrid";
import { ObjectUtils } from "@ict-base/module/utils/ObjectUtils";
import RegexUtil from "@ict-base/module/utils/RegexUtil";
import InfoLayoutPage from "@keri-portal/component/layout/InfoLayoutPage";
import KeriSelectEducationModal from "@keri-portal/component/modal/education/KeriSelectEducationModal";
import { IUploadFileVO } from "@keri-portal/interfaces/api/keri-api/object/IUploadFileVO";
import { IUploadFile } from "@keri-portal/interfaces/control/IUploadFile";
import { IEducationItem } from "@keri-portal/interfaces/data-object/data-common/IEducation";
import useGetEducationScheduleInfo, { IUseGetEducationScheduleInfo } from "@keri-portal/module/hook/api/education/useGetEducationScheduleInfo";
import RequestKeriAPI from "@keri-portal/module/method/api-connect/RequestKeriAPI";
import { Warning } from "@mui/icons-material";
import { Box, Paper, TextField, Typography, CircularProgress, Backdrop } from "@mui/material";
import Button from "@mui/material/Button";
import { TextFieldProps } from "@mui/material/TextField";
import URLStructure from "@src/modules/structure/URLStructure";
import { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { IUploadSectionRef, UploadSection } from "./ShareBoardWritePage";

const EducationRequestPage = () => {

    const param = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    const uploadRef = useRef<IUploadSectionRef | null>(null);
    const [fileList, setFileList] = useState<IUploadFile<IUploadFileVO>[]>([]);


    const titleRef = useRef<HTMLInputElement>(null);
    const [descriptionData, setDescriptionData] = useState<string | undefined>(undefined);
    const phoneNumberRef = useRef<TextFieldProps | undefined>(undefined);
    const emailRef = useRef<TextFieldProps | undefined>(undefined);
    const agreeRef = useRef<HTMLInputElement>(null);

    const [selectEducation, setSelectEducation] = useState<IEducationItem | undefined>(undefined);



    const [applyStatus, setApplyStatus] = useState<{ state: boolean, message?: string }>({ state: false });


    //#region API Hook

    const [hookData, setHookData] = useState<IUseGetEducationScheduleInfo>({
        TriggerTime: new Date()
    })

    const educationScheduleHook = useGetEducationScheduleInfo(hookData);

    useMemo(() => {
        if (educationScheduleHook.data?.state === true) {
            setSelectEducation(educationScheduleHook.data.resultData);
        }
    }, [educationScheduleHook])

    //#endregion



    const applyHandler = async () => {


        if (ObjectUtils.IsNullOrEmpty(titleRef.current?.value)) {
            alert('제목은 필수 값입니다.');
            return;
        }

        if (selectEducation === undefined) {
            alert('선택된 신청 교육이 없습니다.');
            return;
        }

        if (ObjectUtils.IsNullOrEmpty(emailRef.current?.value as string | undefined)) {
            alert('이메일 주소는 필수 값입니다.');
            return;
        }
        else if (!RegexUtil.CheckString(RegexUtil.RegexType.Email, emailRef.current!.value as string)) {
            alert('이메일 주소 형식이 맞지 않습니다.');
            return;
        }

        // if (agreeRef.current === undefined || agreeRef.current?.checked === false) {
        //     alert('개인정보 이용 동의가 필요합니다');
        //     return;
        // }




        const uploadFiles = uploadRef.current?.GetUploadFiles();
        let uploadedFiles: string[] = [];


        for (let i = 0; i < (uploadFiles ?? []).length; i++) {
            setApplyStatus({ state: true, message: `첨부파일 업로드..(${i + 1}/${(uploadFiles ?? []).length})` });

            const callAPI = await RequestKeriAPI.File.UploadFile((uploadFiles ?? [])[i]);

            if (!callAPI.state) {
                alert(`첨부파일 업로드를 실패했습니다.\n - ${callAPI.message}`);
                break;
            }
            else {
                (callAPI.data?.UploadedFile?.streFileNm) !== undefined && uploadedFiles.push((callAPI.data?.UploadedFile?.streFileNm));
            }
        }




        const applyData = {
            title: titleRef.current?.value,
            content: descriptionData,
            // phoneNumber: (phoneNumberRef.current?.value as string).replaceAll(/-/g, ''),
            email: emailRef.current?.value as string,
            educationId: selectEducation?.educationId,
            streFiles: uploadedFiles.filter(x => x !== undefined) as string[]
        }




        setApplyStatus({ state: false, message: '교육 신청 중' });

        const requestAPI = await RequestKeriAPI.Education.InsertEducationRequest({
            scheduleID : selectEducation.educationId,
            requestContent : descriptionData??'',
            requesterEmail : emailRef.current!.value as string,
            addFiles : uploadedFiles,
            requestTitle : titleRef.current?.value
        })


        if(requestAPI.state){
            setApplyStatus({ state: false  , message : '신청 완료'});
            alert('신청이 완료되었습니다.')
            setApplyStatus({ state: false });
            navigate(URLStructure.Edu.EducationInfo(selectEducation.educationId))
        }
        else{
            alert(`신청 오류 발생 \n - ${requestAPI.message}`);
            setApplyStatus({ state: false });
        }


        

    }


    //#region Select Education Modal Control

    const [selEduModalOpen, setSelEduModalOpen] = useState<boolean>(false);

    //#endregion 


    useEffect(() => {
        setHookData({
            TriggerTime: new Date(),
            scheduleID: param.id
        })
    }, [param.id])

    return (
        <InfoLayoutPage
            title="교육 신청"
        >
            {
                param.id === undefined &&
                <Paper
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '150px'
                    }}
                >
                    <Box
                        sx={{
                            textAlign: 'center'
                        }}
                    >
                        <Warning fontSize="large" />
                        <Typography>
                            잘못된 접근입니다.
                        </Typography>
                    </Box>
                </Paper>
            }
            {
                (param.id !== undefined && educationScheduleHook.loading === true) &&
                <Paper
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '150px'
                    }}
                >
                    <CircularProgress />
                </Paper>
            }
            {
                (param.id !== undefined && educationScheduleHook.loading === false && educationScheduleHook.complete === true && educationScheduleHook.data?.state === false) &&
                <Paper
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '150px'
                    }}
                >
                    <Box
                        sx={{
                            textAlign: 'center'
                        }}
                    >
                        <Warning fontSize="large" />
                        <Typography>
                            {educationScheduleHook.data.message}
                        </Typography>
                    </Box>
                </Paper>
            }
            {
                (param.id !== undefined && educationScheduleHook.loading === false && educationScheduleHook.complete === true && educationScheduleHook.data?.state === true) &&
                <Paper
                    sx={{
                        p: 2,
                        mb: 2
                    }}
                >
                    <Box>
                        <Box
                            sx={{
                                mb: 1
                            }}
                        >
                            <TextField
                                fullWidth
                                size="small"
                                inputRef={titleRef}
                                defaultValue={''}
                                placeholder="제목"
                            />
                        </Box>
                    </Box>

                    <Box>
                        <KeriEditGridWebEditorField
                            defaultValue={''}
                            disableTitle
                            // value={editData?.content}
                            onChange={(text) => {
                                setDescriptionData(text)
                            }}
                        />
                    </Box>
                    <Box
                        sx={{

                            m: 1,
                            display: 'flex',
                            justifyContent: 'start',
                            alignItems: 'start',
                            pr: 1,
                            pl: 1
                        }}
                    >
                        <Box
                            sx={{
                                width: '160px',
                                height: '40px',
                                display: 'flex',
                                justifyContent: 'start',
                                alignItems: 'center',
                            }}
                        >
                            <Typography>
                                신청 교육
                            </Typography>
                            <Typography sx={{ ml: 1, color: 'red' }}>
                                *
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                width: 'calc(100% - 160px)',
                            }}
                        >
                            {
                                selectEducation === undefined
                                    ?
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            border: '1px solid rgba(0,0,0,0.1)',
                                            borderRadius: '5px',
                                            height: '40px',
                                            width: '100%',
                                            cursor: 'pointer',
                                            transition: '0.3s all cubic-bezier(0,0,0,1)',
                                            ":hover": {
                                                background: '#bbe7e7'
                                            }
                                        }}
                                        onClick={() => setSelEduModalOpen(true)}
                                    >
                                        <Typography variant="subtitle1">
                                            교육 선택
                                        </Typography>
                                    </Box>
                                    :
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            border: '1px solid rgba(0,0,0,0.1)',
                                            borderRadius: '5px',
                                            height: '40px',
                                            width: '100%',
                                            cursor: 'pointer',
                                            transition: '0.3s all cubic-bezier(0,0,0,1)',
                                            ":hover": {
                                                background: '#bbe7e7'
                                            }
                                        }}
                                        onClick={() => setSelEduModalOpen(true)}
                                    >
                                        <Typography>
                                            {`${selectEducation.title} (${selectEducation.classOpenDate.toStringFormat('yyyy-MM-dd')} ~ ${selectEducation.classCloseDate.toStringFormat('yyyy-MM-dd')})`}
                                        </Typography>
                                    </Box>
                            }
                        </Box>

                    </Box>
                    <Box>
                        {/* <KeriEditGrid>
                            <KeriEditGridInputField
                                title="휴대전화 번호"
                                type={'tel'}
                                inputRef={phoneNumberRef}
                                onChange={(e) => {
                                    phoneNumberRef.current && (phoneNumberRef.current.value = RegexUtil.ConvertRegexString.CellPhoneNumber(phoneNumberRef.current.value as string))
                                }}
                                required
                            />
                        </KeriEditGrid> */}
                        <KeriEditGrid>
                            <KeriEditGridInputField
                                title="이메일 주소"
                                type={'email'}
                                inputRef={emailRef}
                                required
                            />
                        </KeriEditGrid>
                    </Box>

                    <Box
                        sx={{

                            m: 1,
                            display: 'flex',
                            justifyContent: 'start',
                            alignItems: 'start',
                            pr: 1,
                            pl: 1
                        }}
                    >
                        <Box
                            sx={{
                                width: '160px',
                                height: '40px',
                                display: 'flex',
                                justifyContent: 'start',
                                alignItems: 'center',
                            }}
                        >
                            <Typography>
                                첨부 파일
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                width: 'calc(100% - 160px)',
                            }}
                        >
                            <UploadSection
                                ref={uploadRef}
                                defaultValue={fileList}
                            />
                        </Box>

                    </Box>
                    {/* <Paper
                        elevation={0}
                        sx={{
                            border: '1px solid rgba(0,0,0,0.1)',
                            m: 2,
                            p: 2
                        }}
                    >
                        <KeriCheckLabel
                            label="개인정보 이용에 동의합니다"
                            checkRef={agreeRef}
                        />
                    </Paper> */}

                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'end',
                            alignItems: 'center'
                        }}
                    >
                        <KeriActionButton
                            label="취소"
                            color="error"
                            variant="contained"
                            onClick={() => navigate(`${URLStructure.Edu.EducationInfo(param.id)}`)}
                            sx={{
                                mr: 1
                            }}
                        />
                        <KeriActionButton
                            label="저장"
                            variant="contained"
                            onClick={applyHandler}

                        />
                    </Box>
                </Paper>
            }



            {/* Modal */}
            <KeriSelectEducationModal
                open={selEduModalOpen}
                onClose={() => setSelEduModalOpen(false)}
                onSubmit={(item) => setSelectEducation(item)}
            />

            <UploadDrop
                state={applyStatus.state}
                message={applyStatus.message}
            />


        </InfoLayoutPage>
    )
}




const UploadDrop = (prop: {
    state: boolean,
    message?: string
}) => {
    return (
        <Backdrop
            open={prop.state}
            sx={{
                zIndex: '9999999'
            }}
        >
            <Paper
                sx={{
                    width: '300px',
                    height: '200px',
                    display: "flex",
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <Box
                    sx={{
                        textAlign: 'center'
                    }}
                >
                    <CircularProgress
                        sx={{
                            color: 'red'
                        }}
                    />
                    <Typography
                        sx={{
                            mt: 2
                        }}
                    >
                        {prop.message}
                    </Typography>
                </Box>
            </Paper>
        </Backdrop>
    )
}

export default EducationRequestPage;