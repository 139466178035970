import DefaultLayoutPage from "@keri-portal/component/layout/DefaultLayoutPage";
import { IPageProp } from "@keri-base/interface/common/IPageProp";
import ContectViewPage from "@keri-portal/page/about-center/VisitCenterViewPage";
import LoginViewPage from "@keri-portal/page/account/LoginViewPage";
import RegisterViewPage from "@keri-portal/page/account/RegisterViewPage";
import NotFoundViewPage from "@keri-portal/page/error/NotFoundViewPage";
import MainViewPage from "@keri-portal/page/MainViewPage";
import TechServiceItemViewPage from "@keri-portal/page/member-info/tech-service-list/TechServiceItemViewPage";
import UserBasicInfoViewPage from "@keri-portal/page/member-info/UserBasicInfoViewPage";
import UserItemManagementViewPage from "@keri-portal/page/member-info/UserItemManagementViewPage";
import StaticHTMLViewPage from "@keri-portal/page/StaticHTMLViewPage";
import RegisterTechService from "@keri-portal/page/tech-service/RegisterTechService";
import { Routes, Route } from "react-router-dom";
import TestPage from "@keri-portal/page/TestPage";
import CommonBoardItemInfoPage from "@keri-portal/page/notification/CommonBoardItemInfoPage";
import ShareBoardPage from "@keri-portal/page/notification/ShareBoardPage";
import ShareBoardWritePage from "@keri-portal/page/notification/ShareBoardWritePage";
import ShareBoardItemInfoPage from "@keri-portal/page/notification/ShareBoardItemInfoPage";
import Error404Page from "@keri-portal/page/ErrorPage";
import ShareBoardEditPage from "@keri-portal/page/notification/ShareBoardEditPage";
import FaqBoardPage from "@keri-portal/page/notification/FaqBoardPage";
import FaqBoardItemInfoPage from "@keri-portal/page/notification/FaqBoardItemInfoPage";
import URLStructure from "@src/modules/structure/URLStructure";
import EducationScheduleListPage from "@keri-portal/page/notification/EducationScheduleListPage";
import EducationScheduleInfoPage from "@keri-portal/page/notification/EducationScheduleInfoPage";
import EducationRequestPage from "@keri-portal/page/notification/EducationRequestPage";
import RequestTechServiceListPage from "@keri-portal/page/member-info/RequestTechServiceListPage";
import EducationItemSurveyPage from "@keri-portal/page/notification/EducationItemSurveyPage";
import NoticeCommonBoardListPage from "@keri-portal/page/notification/NoticeCommonBoardListPage";


const DefaultRoute = (prop: IPageProp) => {
  return (
    <Routes>
      <Route path={URLStructure.Main} element={<DefaultLayoutPage {...prop} />}>

        <Route index element={<MainViewPage {...prop} />} />

        <Route path='register' element={<RegisterViewPage {...prop} />} />

        <Route path='tech-service' >
          <Route path='register' element={<RegisterTechService {...prop} />} />
        </Route>

        <Route path='about-center'>
          <Route index element={<NotFoundViewPage />} />
          <Route path='visit-center' element={<ContectViewPage {...prop} />} />
        </Route>

        <Route path="member-info">
          <Route index element={<NotFoundViewPage />} />

          <Route caseSensitive path='basic-info' element={<UserBasicInfoViewPage {...prop} />} />
          <Route path='tech-service-list' >
            <Route index element={<RequestTechServiceListPage />} />
            <Route path=':id' element={<TechServiceItemViewPage {...prop} />} />
          </Route>

          <Route path='item-management' element={<UserItemManagementViewPage {...prop} />} />
        </Route>

        <Route path="static">
          <Route path=":staticFileName" element={<StaticHTMLViewPage {...prop} />} />
        </Route>

        <Route path="board">
          <Route path="BBSMSTR_D99999999999" >
            <Route index element={<FaqBoardPage {...prop} />} />
            <Route path=":boardItemID" element={<FaqBoardItemInfoPage {...prop} />} />
          </Route>
          <Route path=":boardID" >
            <Route index element={<NoticeCommonBoardListPage />} />
            <Route path=":boardItemID" element={<CommonBoardItemInfoPage {...prop} />} />
          </Route>
        </Route>

        <Route path="edu">
          <Route path="schedule">
            <Route index element={<EducationScheduleListPage />} />
            <Route path=":id">
              <Route index element={<EducationScheduleInfoPage />} />
              <Route path="request" element={<EducationRequestPage />} />
              <Route path="survey" element={<EducationItemSurveyPage />} />
            </Route>
          </Route>
        </Route>

        <Route path="share-board">
          <Route path=":boardID">
            <Route index element={<ShareBoardPage {...prop} />} />
            <Route path='write' element={<ShareBoardWritePage {...prop} />} />
            <Route path=":boardItemID" >
              <Route index element={<ShareBoardItemInfoPage {...prop} />} />
              <Route path='edit' element={<ShareBoardEditPage {...prop} />} />
            </Route>
          </Route>
        </Route>

        {
          process.env.NODE_ENV === 'development' &&
          <Route path="test" element={<TestPage {...prop} />} />
        }


        <Route path="*" element={<NotFoundViewPage />} />
      </Route>
      <Route path={URLStructure.UserLogin} element={<LoginViewPage {...prop} />} />
      <Route path={URLStructure.Error}>
        <Route path=":state" element={<Error404Page />} />
      </Route>

    </Routes>
  )
}

export default DefaultRoute;