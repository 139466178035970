import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ILoginSessionData } from "../../../modules/redux/reducer/UserDataReducer";

const useLoginCheck = () : ILoginSessionData | undefined => {

    const userRedux = useSelector((state: any) => state.user);


    const [isLogin , setIsLogin] = useState<ILoginSessionData | undefined>(undefined);

    useEffect(() => {
        setIsLogin(userRedux);
    },[userRedux])


    return isLogin;
}

export default useLoginCheck;