import { AxiosError } from "axios";
import KeriAPIException from "./KeriAPIException";

const KeriAPIExceptionFilter = (exception: unknown): { message?: string, state: number } => {

    let result: { message?: string, state: number } = {
        message: undefined,
        state: -1
    };

    if (exception instanceof AxiosError) {

        if(exception.response?.status !== undefined){
            result.state = exception.response.status;

            if (exception.response?.status === 401) {
                result.message = '로그인이 만료되었습니다. \n다시 로그인해주시길 바랍니다.'
            }
            else if (exception.response?.status === 403) {
                exception.message = '권한이 없습니다.'
            }
            else {
                exception.message = '서버와 연결이 원활하지 않습니다.'
            }
        }
        else {
            result.message = exception.message;
        }
    }
    else if (exception instanceof KeriAPIException) {
        result.message = exception.message;
        result.state = exception.status;
    }
    else {
        result.message = '시스템 오류입니다.';
        result.state = 500;
    }

    return result;

}

export default KeriAPIExceptionFilter;