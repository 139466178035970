/** Keri 기본 설정 (고정값) */
const KeriBaseConfig = {
    /** 비밀번호 형식 */
    PasswordRegExp : /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{10,}$/,
    AccountIDRegExp : /^(?=.*[a-zA-Z])(?=.*\d)[A-Za-z\d]{8,}$/,
    /** 업로드 불가 확장자명 */
    DenyFileExtension : [
        'jsp','cgi','php','php3','asp','aspx','exe','com','html','htm','cab','pl','java','class','js','css'
    ]
}


export default KeriBaseConfig