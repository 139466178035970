import { Box, Modal, Paper, SxProps, Theme } from "@mui/material";
import ICTBaseModal from "./ICTBaseModal";

interface IICTContainerPopup{
    open : boolean,
    onClose : () => void,
    children? : React.ReactNode | React.ReactNode[],
    popupProps : {
        title : string,
        sx? : SxProps<Theme>
    }
    containerProps? : {
        sx? : SxProps<Theme>,
        importHTML? : string
    }
}


const ICTContainerPopup = (prop : IICTContainerPopup) => {

    
    return (
        <ICTBaseModal
            open={prop.open}
            onClose={prop.onClose}
            title={prop.popupProps?.title}
            paperProp={{
                sx : {
                    width : '100%',
                    maxWidth : '1270px',
                    padding : '25px 30px',
                    }
            }}
            titleProps={{
                titleSx : {
                    fontSize : '20px'
                },
                dividerSx : {
                    borderBottomWidth : 'medium',
                    borderBottomColor : 'black', 
                    marginTop : '10px'
                },
                closeIconSx : {

                },
                closeIconSize : 'large'
            }}
        >
            <Box
                sx={{
                    // minWidth : '1024px',
                    p : 1,
                    maxHeight : '75vh',
                    // overflowX : 'scroll',
                    overflowY : 'auto'
                }}
            >
                <div dangerouslySetInnerHTML={{ __html: prop.containerProps?.importHTML??''}} style={{width : '100%', minHeight : '75vh'}}></div>
            </Box>
        </ICTBaseModal>
        // <Modal
        //     open={prop.open}
        //     onClose={prop.onClose}
        // >
        //     <Paper
        //         sx={{
        //             width : '70%',
        //             minWidth : '1024px',
        //             maxWidth : '1300px',
        //             position: 'absolute' as 'absolute',
        //             top: `50%`,
        //             left: `50%`,
        //             transform: 'translate(-50%, -50%)',
        //             boxShadow: 24,
        //             border: '1px solid rgba(0,0,0,0.1)',
        //             ...prop.popupProps?.sx
        //         }}
        //     >
                
        //     </Paper>
        // </Modal>

    )
}

export default ICTContainerPopup;