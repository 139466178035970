/**
 * 공통 게시판 리스트 조회 페이지
 * 
 * @author shlee
 * @since 2023.03.17
 * 
 */

import { IUseGetBoardMasterData, MISCDefaultPageLayout, MISCErrorComponent, MISCPageStateComponent, useGetBoardMasterData, MISCLoadingComponent, useGetCommonBoardItemList, IUseGetCommonBoardItemList, MISCCommonBoardList } from "@ictpeople/misc-react";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Box, CircularProgress, IconButton, Paper, Typography, useMediaQuery } from "@mui/material";
import { FormatListBulletedTwoTone, GridView, PersonOutline, RemoveRedEyeOutlined } from "@mui/icons-material";
import { IKeriCommonBoardItem } from "@ictpeople/misc-core";
import React from "react";
import { Autoplay } from "swiper";
import { SwiperSlide, Swiper } from "swiper/react";
import { MISCBoardAPI, MISCFileAPI } from '@ictpeople/misc-api';
import { DateToString, GetRandomString, IsUndefined, RemoveHTMLTag, URLParameterUtil } from '@ictpeople/common'
import { MISCPagination } from "@keri-portal/component/common/MISCPagination";
import URLStructure from "@src/modules/structure/URLStructure";
import { useQuery } from "react-query";


const NoticeCommonBoardListPage = () => {

    const param = useParams();
    const location = useLocation();


    const [viewMode, setViewMode] = useState<'gallery' | 'list'>('list');
    const isMobile = useMediaQuery('(max-width:425px)');


    const [boardMasterErrorMessage, setBoardMasterErrorMessage] = useState<string | undefined>(undefined);
    const [boardListErrorMessage , setBoardListErrorMessage] = useState<string | undefined>(undefined);
    const [searchObj, setSearchObj] = useState<{
        page: number,
        pageUnit: number,
        searchKeyword?: string
    }>({
        page: 1,
        pageUnit: 6
    })



    const boardMasterQuery = useQuery(
        'board-master',
        () => MISCBoardAPI.GetBoardMasterData({
            boardId: param.boardID
        },process.env.REACT_APP_API_URL),
        {
            refetchOnWindowFocus: false,
            enabled: param.boardID !== undefined,
            onSuccess: (data) => {
                if (data.state === true) {
                    setBoardMasterErrorMessage(undefined);
                }
                else {
                    setBoardMasterErrorMessage(data.message);
                    console.error('Failed load board master info : ', data);
                }
            },
            onError: (err) => {
                setBoardMasterErrorMessage('게시판 정보를 가져오지 못했습니다.')
                console.error('Failed load board master info : ', err)
            }
        }
    )

    const boardListQuery = useQuery(
        'board-item',
        () => MISCBoardAPI.GetCommonBoardItemList({
            page : searchObj.page,
            pageUnit : searchObj.pageUnit,
            usePagination : true,
            boardId : boardMasterQuery.data?.resultData?.uniqueId,
            searchKeyword : searchObj.searchKeyword
        },process.env.REACT_APP_API_URL),
        {
            refetchOnWindowFocus: false,
            enabled: boardMasterQuery.data?.resultData?.uniqueId !== undefined,
            onSuccess: (data) => {
                if (data.state === true) {

                    setBoardListErrorMessage(undefined);
                    
                    if (!IsUndefined(data.page, data.totalPage)) {
                        if (data.page! > data.totalPage!) {
                            setSearchObj({
                                ...searchObj,
                                page: data.totalPage!
                            })

                            boardListQuery.refetch();
                        }
                    }

                    
                }
                else {
                    setBoardListErrorMessage(data.message);
                }
            },
            onError: (err) => {
                setBoardListErrorMessage('데이터를 불러올 수 없습니다.');
            }
        }
    )


    /**
     * URL Parameter 조회 후 page , search keyword 적용
     */
    const URLFormetting = () => {
        boardMasterQuery.remove();
        boardListQuery.remove();

        const paramData = URLParameterUtil.GetParams(location.search);

        let stateData = searchObj;


        // console.log('prd', paramData);

        if (paramData.searchKeyword !== undefined) {
            stateData.searchKeyword = paramData.searchKeyword;
        }



        if (paramData.page !== undefined) {
            stateData.page = isNaN(parseInt(paramData.page)) ? 1 : parseInt(paramData.page)
        }
        else {
            URLParameterUtil.SetParam({
                page: 1,
                searchKeyword: paramData.searchKeyword
            }).BuildURL(location.pathname)
        }

        if (paramData.viewMode !== undefined) {
            setViewMode(paramData.viewMode === 'gallery' ? 'gallery' : 'list');
        }


        setSearchObj(stateData);
        boardMasterQuery.refetch();
    }


    /**
     * URL Parameter 변경 함수
     * @description null 일 경우 값 없음 처리 
     * @description any | undefined 의 경우 값 처리
     */
    const changeURLParam = (_page?: number | null, _searchKeyword?: string | null, _viewMode?: 'gallery' | 'list' | null) => {
        let inputParamData: { [key: string]: string | number | undefined } = {};

        inputParamData.page = _page === null ? 1 : _page;
        if (_searchKeyword !== null) {
            inputParamData.searchKeyword = _searchKeyword;
        }

        if (_viewMode !== null) {
            inputParamData.viewMode = _viewMode;
        }


        URLParameterUtil.SetParam(inputParamData).BuildURL(location.pathname)
    }








    useEffect(() => {
        console.log('BOARD ID' , param.boardID);

        if (param.boardID === undefined) {
            setBoardMasterErrorMessage('잘못된 접근입니다.');
        }
        else{
            URLFormetting();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [param.boardID, location.key])



    useEffect(() => {
        return (() => {
            boardListQuery.remove();
            boardMasterQuery.remove();
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])



    return (
        <MISCPageStateComponent
            isLoading={boardMasterQuery.isLoading}
            errorMessage={boardMasterErrorMessage}
            errorComponent={(errMessage) => (
                <MISCErrorComponent
                    errorMessage="게시판을 찾을 수 없습니다."
                    errorSubMessage="서버 오류이거나 잘못된 접근입니다."
                />
            )}
        >
            <MISCDefaultPageLayout
                pageTitle={boardMasterQuery.data?.resultData?.name}
                onSearch={(item) => {
                    setSearchObj({
                        ...searchObj,
                        searchKeyword: item.searchKeyword
                    })

                    changeURLParam(searchObj.page, item.searchKeyword, viewMode === 'gallery' ? 'gallery' : null);
                }}
                searchOptions={{
                    enableSearchKeyword: true,
                    defaultSearchKeyword: searchObj.searchKeyword
                }}
                isLoading={boardListQuery.isLoading}
                loadRenderDom={
                    <MISCLoadingComponent message="게시글 리스트를 조회중입니다." minHeight={'300px'} />
                }
                endAdornment={
                    !isMobile && (
                        <Box>
                            <IconButton
                                sx={{
                                    border: '1px solid #2a6f8b',
                                    m: 0,
                                    ml: 1,
                                    p: 0.5,
                                    color: '#2a6f8b',
                                    ":hover": {
                                        color: 'white',
                                        background: '#2a6f8b'
                                    }
                                }}
                                // onClick={() => }
                                onClick={() => {
                                    setViewMode(viewMode === 'list' ? 'gallery' : 'list')
                                    changeURLParam(searchObj.page, searchObj.searchKeyword, viewMode === 'list' ? 'gallery' : 'list');
                                }}
                            >
                                {viewMode === 'list' ? <FormatListBulletedTwoTone /> : <GridView />}

                            </IconButton>
                        </Box>
                    )
                }
            >
                <MISCCommonBoardList
                    techServiceList={boardListQuery.data?.resultData}
                    renderDom={(item) => <MISCCommonBoardListItem item={item} viewMode={isMobile ? 'gallery' : viewMode} />}
                    viewMode={viewMode}
                    boxProp={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexWrap: 'wrap'
                    }}
                />
                {
                    (boardListQuery.data?.resultData ?? []).length !== 0 &&
                    <MISCPagination
                        page={boardListQuery.data?.page ?? 1}
                        totalPage={boardListQuery.data?.totalPage ?? 1}
                        onChange={(changePage) => {
                            setSearchObj({
                                ...searchObj,
                                page: changePage
                            })


                            changeURLParam(changePage, searchObj.searchKeyword, viewMode === 'gallery' ? 'gallery' : null);
                        }}
                        boxSx={{
                            mt: 3,
                            mb: 3
                        }}
                        paginationSx={{

                        }}
                    />
                }



            </MISCDefaultPageLayout>
        </MISCPageStateComponent>
    )
}
















//#region Tech Service List Item

interface IMISCCommonBoardListItem {
    item: IKeriCommonBoardItem,
    viewMode?: 'gallery' | 'list'
}

const MISCCommonBoardListItem = (prop: IMISCCommonBoardListItem) => {
    const [loadImageURL, setLoadImageURL] = useState<string[]>([])
    const navigate = useNavigate();
    const mediaQuery = useMediaQuery('(max-width:768px)')
    const isMobile = useMediaQuery('(max-width:425px)');

    const getImage = async () => {

        if (prop.item.thumbnails !== undefined) {
            let imgArr: string[] = [];

            for (let imgCnt = 0; imgCnt < prop.item.thumbnails.length; imgCnt++) {

                const reqAPI = await MISCFileAPI.GetImage({
                    atchFileId: prop.item.thumbnails![imgCnt].atchFileID,
                    fileSn: prop.item.thumbnails![imgCnt].fileSequence
                }, process.env.REACT_APP_KERI_API_URL)

                if (reqAPI.state && reqAPI.url !== undefined) {
                    imgArr.push(reqAPI.url);
                }
            }

            if (imgArr.length === 0) {
                imgArr.push(URLStructure.PlaceHolderImage)
            }


            console.log('OUTPUT DATA', imgArr)

            setLoadImageURL(imgArr);
        }
    }

    useMemo(() => {
        getImage();
    }, [prop.item.thumbnails])

    return (
        <Box
            sx={
                prop.viewMode === 'list' ?
                    {
                        width: '100%'
                    }
                    :
                    {
                        width: mediaQuery ? isMobile ? '100%' : '50%' : "33%",
                        pr: 0.5,
                        pl: 0.5
                    }
            }
        >
            <Paper
                elevation={0}
                sx={{
                    width: '100%',
                    border: '1px solid rgba(0,0,0,0.1)',
                    borderRadius: '10px',
                    overflow: 'hidden',
                    p: 0,
                    mb: 2,
                    // transition: '0.3s all ease',
                    cursor: 'pointer',
                    ":hover": {
                        boxShadow: '0px 4px 4px #14658759'
                    }
                }}
                onClick={() => {
                    prop.item.uniqueId && navigate(prop.item.uniqueId)
                }}
            >
                <Box
                    sx={
                        prop.viewMode === 'list'
                            ?
                            {
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'start',
                                height: '200px',

                            }
                            :
                            {

                            }
                    }
                >
                    <Box
                        sx={
                            prop.viewMode === 'list' ?
                                {
                                    width: '20%',
                                    height: '100%',
                                    borderRight: '1px solid rgba(0,0,0,0.1)',
                                    minWidth: '200px',
                                    overflow: 'hidden',
                                    p: 1
                                }
                                :
                                {
                                    width: '100%',
                                    height: '200px',
                                    borderBottom: '1px solid rgba(0,0,0,0.1)'
                                }
                        }
                    >
                        {
                            loadImageURL.length === 0 &&
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: '100%',
                                    height: '100%'
                                }}
                            >
                                <CircularProgress />
                            </Box>
                        }
                        {
                            loadImageURL.length !== 0 &&


                            <Swiper
                                className="mySwiper"
                                style={{ height: '100%', width: '100%', zIndex: 0 }}
                                speed={300}
                                loop
                                loopedSlides={1}
                                autoplay={{
                                    delay: (loadImageURL ?? []).length > 1 ? 3000 : 1000000,
                                    disableOnInteraction: false
                                }}
                                allowTouchMove={false}
                                modules={[Autoplay]}
                            >
                                <React.Fragment>
                                    {
                                        (loadImageURL ?? []).map(thumbnailItem => (
                                            <SwiperSlide key={GetRandomString(10)}>
                                                <img
                                                    src={thumbnailItem}
                                                    style={{
                                                        height: '100%',
                                                        width: '100%',
                                                        objectFit: (loadImageURL ?? []).length === 0 ? 'scale-down' : 'cover'

                                                    }}
                                                    alt="thumbnail"
                                                />
                                            </SwiperSlide>
                                        ))
                                    }
                                </React.Fragment>
                            </Swiper>
                        }


                    </Box>
                    <Box
                        sx={
                            prop.viewMode === 'list' ?
                                {
                                    width: '80%',
                                    height: '100%',
                                    maxWidth: 'calc(100% - 200px)'
                                }
                                :
                                {
                                    width: '100%',
                                    height: '200px'
                                }
                        }
                    >
                        <Box
                            sx={{
                                minHeight: '100%',
                                p: 1,
                                pb: '30px',
                            }}
                        >
                            <Box
                                className="techServiceListItem-Header"
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'start',
                                        alignItems: 'center',
                                        width: "80%"
                                    }}
                                >

                                    <Typography
                                        variant="h6"
                                        sx={{
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                        }}
                                    >
                                        {prop.item.title}
                                    </Typography>
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    mt: 1,
                                    overflow: 'hidden'
                                }}
                            >
                                <Typography
                                    sx={{
                                        lineHeight: '20px',
                                        height: "80px",
                                        display: 'hidden',
                                        textOverflow: 'ellipsis',
                                        WebkitBoxOrient: 'vertical',
                                        WebkitLineClamp: '4',
                                    }}
                                >
                                    {RemoveHTMLTag(prop.item.content)}
                                </Typography>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                transform: 'translateY(-100%)',
                                height: '40px',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                borderTop: '1px solid rgba(0,0,0,0.1)',
                                background: '#f7f7f7',
                                pr: 1,
                                pl: 1
                            }}
                        >
                            <Box>
                                <Typography
                                    variant="body2"
                                    sx={{
                                        color: 'gray',
                                        ml: 0.5
                                    }}
                                >
                                    {DateToString(prop.item.createDate, 'yyyy-MM-dd')}
                                </Typography>
                            </Box>
                            <Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'end',
                                        alignItems: 'center'
                                    }}
                                >
                                    <React.Fragment>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                color: 'gray',
                                            }}
                                        >
                                            <RemoveRedEyeOutlined fontSize="small" sx={{ mr: 0.4, fontSize: '1rem' }} />
                                            <Typography
                                                variant="caption"
                                                sx={{
                                                    m: 0,
                                                    p: 0,
                                                }}
                                            >
                                                {prop.item.viewCount ?? 0}
                                            </Typography>
                                        </Box>
                                        {/* Custom Chip */}
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                color: 'gray',
                                                ml: 1
                                            }}
                                        >
                                            <PersonOutline fontSize="small" sx={{ mr: 0.2, fontSize: '1rem' }} />
                                            <Typography
                                                variant="caption"
                                                sx={{
                                                    m: 0,
                                                    p: 0,
                                                }}
                                            >
                                                {prop.item.writer}
                                            </Typography>
                                        </Box>
                                    </React.Fragment>


                                </Box>



                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Paper >
        </Box>

    )
}


//#endregion







export default NoticeCommonBoardListPage;