import { IListPagination } from "@keri-base/interface/control/IListPagination";
import { Box, Pagination, SxProps, Theme } from "@mui/material"

interface IICTPagination extends IListPagination {
    align?: 'start' | 'center' | 'end',
    sx?: SxProps<Theme>
}

const ICTPagination = (prop: IICTPagination) => {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: prop.align ?? 'center',
                pt: 1,
                ...prop.sx
            }}
        >
            <Pagination
                page={prop.pageObject?.nowPage??1}
                count={prop.pageObject?.totalPage??1}
                showFirstButton
                showLastButton
                onChange={(e, page) => page !== prop.pageObject?.nowPage && prop.pageObject?.onChangePage && prop.pageObject?.onChangePage(page)}
            />
        </Box>
    )
}

export default ICTPagination;