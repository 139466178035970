import { Close } from "@mui/icons-material";
import { Backdrop, Box, Button, Divider, IconButton, Modal, Paper, SxProps, Theme, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import KeriActionButton from "./KeriActionButton";

interface IKeriModal {
    open: boolean,
    onClose: () => void,
    children: React.ReactNode,
    title: string,
    submitButton?: React.ReactNode,
    paperProp? : {
        sx? :SxProps<Theme>
    },
    titleProps? : {
        titleSx? : SxProps<Theme>,
        dividerSx? : SxProps<Theme>,
        closeIconSx? : SxProps<Theme>,
        closeIconSize? : 'small' | 'medium' | 'large'
    }
}

const ICTBaseModal = (prop: IKeriModal) => {


    return (
        <Modal
            open={prop.open}
            onClose={prop.onClose}
            sx={{
                zIndex : 99999
            }}
        >
            <Paper
                sx={{
                    background: 'white',
                    position: 'absolute' as 'absolute',
                    top: `50%`,
                    left: `50%`,
                    transform: 'translate(-50%, -50%)',
                    boxShadow: 24,
                    border: '1px solid rgba(0,0,0,0.1)',
                    minWidth: 400,
                    maxWidth : '100vw',
                    maxHeight : '100vh',
                    outline : 'none',
                    ...prop.paperProp?.sx
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexDirection: 'column'
                    }}
                >

                    <Box>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                p: 1,
                                pl: 2
                            }}
                        >
                            <Box>
                                <Typography sx={{ fontWeight: '500', userSelect: 'none' ,...prop.titleProps?.titleSx}}>
                                    {prop.title}
                                </Typography>
                            </Box>

                            <Box>
                                <IconButton
                                    sx={{
                                        p: 0,
                                        
                                    }}
                                    onClick={() => {
                                        prop.onClose();
                                    }}
                                >
                                    <Close fontSize={prop.titleProps?.closeIconSize??'medium'} sx={{...prop.titleProps?.closeIconSx}}/>
                                </IconButton>
                            </Box>
                        </Box>

                        <Divider sx={{ mb: 1 , ...prop.titleProps?.dividerSx }} />
                    </Box>
                    <Box
                        sx={{
                        }}
                    >
                        {prop.children}
                    </Box>
                    <Box>
                        {prop.submitButton}
                    </Box>
                </Box>

            </Paper>
        </Modal>
    )
}

const KeriModalButtonGroupLayout = (prop: { children: React.ReactNode }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'end',
                alignItems: 'center',
                p: 2,
                pt : 1,
                pb : 1
            }}
        >
            {prop.children}
        </Box>
    )
}




export const KeriModalSubmitAndCancelButton = (prop: { onSubmit: (setLoading : (isLoading : boolean) => void) => void, onCancel: () => void }) => {

    const [submitLoading , setSubmitLoading] = useState<boolean>(false);

    return (
        <KeriModalButtonGroupLayout>
            <KeriActionButton
                label="취소"
                color='error'
                variant="contained"
                onClick={() => {
                    prop.onCancel()
                }}
            />
            <KeriActionButton
                color='primary'
                variant="contained"
                label='확인'
                onClick={() => {
                    prop.onSubmit((isLoading) => setSubmitLoading(isLoading))
                }}
                sx={{
                    ml: 1
                }}
                isLoading={submitLoading}
            />
            {/* <KeriModalButton
                label='취소'
                color="error"
                onClick={() => {
                    prop.onCancel()
                }}
            />

            <KeriModalButton
                label='확인'
                onClick={() => {
                    prop.onSubmit()
                }}
            /> */}

        </KeriModalButtonGroupLayout>
    )
}

export const KeriModalCancelButton = (prop: { onCancel: () => void }) => {
    return (
        <KeriModalButtonGroupLayout>
            <KeriActionButton
                label="취소"
                color='error'
                variant="contained"
                onClick={() => {
                    prop.onCancel()
                }}
            />

        </KeriModalButtonGroupLayout>
    )
}

export default ICTBaseModal;