import { ObjectUtils } from "@ict-base/module/utils/ObjectUtils"
import { IKeriServerFile } from "@ictpeople/misc-core"
import { InsertDriveFileOutlined, Delete, FileDownload } from "@mui/icons-material"
import { Typography, SxProps, Box, Theme, IconButton, Paper, CircularProgress } from "@mui/material"
import {useState , useEffect, Dispatch, SetStateAction} from 'react'


interface IMISCFileGridView {
    title: string,
    titleStyle?: SxProps<Theme>,
    boxStyle?: SxProps<Theme>,
    itemBoxStyle?: SxProps<Theme>,
    children?: React.ReactNode | React.ReactNode[]
}

export const MISCFileGridView = (prop: IMISCFileGridView) => {
    return (
        <Box
            sx={{
                p: 1,
                ...prop.boxStyle
            }}
        >
            <Typography
                variant="subtitle2"
                sx={{
                    fontWeight: 'bold',
                    ...prop.titleStyle
                }}
            >
                {prop.title}
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'start',
                    alignItems: 'start',
                    flexWrap: 'wrap',
                    mt: 0.5,
                    ...prop.itemBoxStyle
                }}
            >
                {prop.children}
            </Box>

        </Box>
    )
}


interface IMISCFileGridViewItem {
    item: IKeriServerFile,
    onChange: () => void,
    onDelete: (item: IKeriServerFile , setLoading : Dispatch<SetStateAction<boolean>>) => void,
    onDownloadFile: (item: IKeriServerFile, setLoading : Dispatch<SetStateAction<boolean>>) => void,
    width?: number | string,
    height?: number | string,
    disableDelete?: boolean,
    
}

export const MISCFileGridViewItem = (prop: IMISCFileGridViewItem) => {

    const [loading , setLoading] = useState<boolean>(false);

    return (
        <Paper
            sx={{
                p: 1,
                m: 0.5,
                width: prop.width ?? '500px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                height: prop.height ?? "70px",
                cursor: 'pointer',
                border: '1px solid rgba(0,0,0,0.1)',
                ":hover": {
                    boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 6px 7px 0px rgba(0,0,0,0.12)'
                },
                position: 'relative',
                overflow: "hidden"
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    backdropFilter: "blur(4px)",
                    width: '100%',
                    height: '100%',
                    zIndex: 990,
                    top: 0,
                    left: 0,
                    display: loading ? 'flex' : 'none',
                    justifyContent: 'center',
                    alignItems: 'center',
                    
                }}
            >
                <CircularProgress
                    size={20}
                    sx={{
                        color: 'black',
                        mr: 1
                    }}
                />
                <Typography
                    variant="subtitle2"
                >
                    loading
                </Typography>

            </Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'start',
                    alignItems: 'center',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        mr: 1
                    }}
                >
                    <InsertDriveFileOutlined
                        sx={{
                            height: '100%',
                            width: '40px',
                            m: 0,
                            p: 0,
                            color: '#207097'
                            // transform: 'translateY(0%)'
                        }}
                    />
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'start',
                        flexDirection: 'column',
                        maxWidth: '300px'
                    }}
                >
                    <Typography
                        variant="subtitle1"
                        sx={{
                            maxWidth: '100%',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                        }}
                    >
                        {prop.item.fileOriginalName}
                    </Typography>
                    <Typography
                        variant="subtitle2"
                        sx={{
                            color: 'gray'
                        }}
                    >
                        {ObjectUtils.FileSizeFormater(prop.item.fileSize ?? 0, 1)}
                    </Typography>
                </Box>
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'end',
                    alignItems: 'center',
                    height: "100%",
                    width: '100px'
                }}
            >
                {
                    !(prop.disableDelete ?? false) &&
                    <IconButton
                        onClick={() => {
                            prop.onDelete(prop.item , setLoading)
                        }}
                    >
                        <Delete />
                    </IconButton>
                }

                <IconButton
                    onClick={() => prop.onDownloadFile(prop.item , setLoading)}
                >
                    <FileDownload />
                </IconButton>
            </Box>
        </Paper>
    )
}