// export interface PlatformError extends Error {
//     isSystemError? : boolean
// }

// export interface PlatformErrorConstructor{
//     new(message?: string, isSystemError? : boolean): PlatformError;
//     (message?: string, isSystemError? : boolean): PlatformError;
//     readonly prototype: PlatformError;
// }

// declare var PlatformError : PlatformErrorConstructor;




export class PlatformError extends Error {
    isSystemError = true;

    constructor(message: string | undefined, isSystemError?: boolean) {
        super(message);
        this.isSystemError = isSystemError??true;
        this.message = message ?? '';
    }
}