import { KeriEditGrid, KeriEditGridChipField, KeriEditGridInputField } from "@ict-base/components/control/KeriEditGrid";
import { ObjectUtils } from "@ict-base/module/utils/ObjectUtils";
import { IKeriPurpose } from "@keri-portal/interfaces/data-object/data-common/IKeriPurpose";
import { ITestAttribute, TestAttribute } from "@keri-portal/interfaces/data-object/data-common/ITestAttribute";
import { ITechServiceObjectData, TechServiceType } from "@keri-portal/interfaces/data-object/tech-service/ITechService";
import React, { useEffect, useState } from "react";
import KeriSelectExpertUserModel from "../modal/KeriSelectExpertUserModel";
import KeriSelectProductItem from "../modal/product-item/KeriSelectProductItem";
import KeriSelectAnalysisPurposeModal from "../modal/purpose/KeriSelectAnalysisPurposeModal";
import KeriSelectTestAttributeModel from "../modal/test-object/KeriSelectTestAttributeModel";

interface IRegisterTechServiceRequestInputField {
    techServiceType: TechServiceType,
    displayObject?: {
        title?: boolean,
        purpose?: boolean,
        product?: boolean,
        info?: boolean,
        desiredDate?: boolean,
        expert?: boolean,
        testMethod?: boolean,
        testType?: boolean,
        testItem?: boolean,
        requestFiles?: boolean,
        requesterName?: boolean,
        requesterCallNumber?: boolean,
        requesterEmail?: boolean,
        requesterDept?: boolean,
        requestOrder?: boolean,
        requestID?: boolean,
        requestStatus?: boolean
    },
    value: ITechServiceObjectData,
    onChange: (item: ITechServiceObjectData) => void,
    error?: {
        data: IRegisterTechServiceRequestInputFieldErrorData,
        onChange: (item: IRegisterTechServiceRequestInputFieldErrorData) => void
    },
    editMode?: boolean
}

export interface IRegisterTechServiceRequestInputFieldErrorData {
    title?: string,
    purpose?: string,
    product?: string,
    info?: string,
    desiredDate?: string,
    expert?: string,
    testMethod?: string,
    testType?: string,
    testItem?: string,
    requestFiles?: string,
    requesterName?: string,
    requesterCallNumber?: string,
    requesterEmail?: string,
    requesterDept?: string,
}

// TODO : purpose는 techServiceType에 따라 유동적으로 변경될 수 있게 설정

/**
 * 기술지원 신청 - 기술지원 신청 항목 통합 관리 Component
 * @param prop input field interface
 * @returns ITechServiceRequestData
 */
const RegisterTechServiceRequestInputField = (prop: IRegisterTechServiceRequestInputField) => {

    // Modal Control
    const [openPurposeModal, setOpenPurposeModal] = useState<{
        state: boolean,
        type?: 'Analysis' | 'Request'
    }>({
        state: false
    });
    const [openProductModal, setOpenProductModal] = useState<boolean>(false);
    const [openExpertUserModal, setOpenExpertUserModal] = useState<boolean>(false);
    const [openTestModal, setOpenTestModal] = useState<{ state: boolean, category?: TestAttribute | 'ALL' }>({ state: false });

    const [purposeList, setPurposeList] = useState<IKeriPurpose[]>(prop.value.RequestInputData?.RequestPurpose ?? []);
    const [testItemList, setTestItemList] = useState<ITestAttribute[]>(prop.value.RequestInputData?.RequestTestItem ?? []);


    return (
        <React.Fragment>
            {/* 기술지원 신청 항목 */}
            <KeriEditGrid>
                {/* 신청 번호 */}
                {
                    (prop.displayObject === undefined || (prop.displayObject.requestID)) && !(prop.editMode ?? false) &&
                    <KeriEditGridInputField
                        title="신청번호"
                        props={{
                            width: '100%'
                        }}
                        editDisabled
                        value={prop.value.RequestUniqueID}
                    />
                }
                {/* 차수 */}
                {
                    (prop.displayObject === undefined || (prop.displayObject.requestID)) && !(prop.editMode ?? false) &&
                    <KeriEditGridInputField
                        title="차수"
                        props={{
                            width: '50%'
                        }}
                        editDisabled
                        value={prop.value.RequestSimulationOrder?.toString()}
                    />
                }
                {/* 상태 */}
                {
                    (prop.displayObject === undefined || (prop.displayObject.requestID)) && !(prop.editMode ?? false) &&
                    <KeriEditGridInputField
                        title="상태"
                        props={{
                            width: '50%'
                        }}
                        editDisabled
                        value={prop.value.RequestStatusName}
                    />
                }
                {/* 제목 */}
                {
                    ((prop.displayObject === undefined || (prop.displayObject.title ?? false)) && !(prop.editMode ?? false)) &&
                    <KeriEditGridInputField
                        title="신청 제목"
                        props={{
                            width: '100%'
                        }}
                        editDisabled
                        onChange={(changeText) => {
                            prop.onChange({
                                ...prop.value,
                                RequestInputData: {
                                    ...prop.value.RequestInputData,
                                    RequestTitle: changeText
                                }
                            })

                            if (prop.error?.data.title !== undefined) {
                                prop.error.onChange({
                                    ...prop.error.data,
                                    title: undefined
                                })
                            }
                        }}
                        value={prop.value.RequestInputData?.RequestTitle}
                        error={{
                            isError: prop.error?.data.title !== undefined,
                            message: prop.error?.data.title
                        }}
                    />
                }

                {/* 해석/신청 목적 */}
                {
                    (prop.displayObject === undefined || (prop.displayObject.purpose ?? false)) &&
                    <KeriEditGridChipField
                        fieldTitle={`${prop.techServiceType === 'A' ? '해석' : '신청'} 목적`}
                        value={prop.value.RequestInputData?.RequestPurpose ?? []}
                        plugins={{
                            button: {
                                enable: prop.editMode ?? false,
                                label: '선택',
                                onClick: () => setOpenPurposeModal({
                                    state: true,
                                    type: prop.techServiceType === 'A' ? 'Analysis' : 'Request'
                                })
                            }
                        }}
                        error={{
                            isError: prop.error?.data?.purpose !== undefined,
                            message: prop.error?.data?.purpose
                        }}
                        elementProps={{
                            display: {
                                displayChipLable: (item) => item.purposeName
                            },
                            disabledDelete: !prop.editMode,
                            onDelete: (item) => {
                                setPurposeList(prop.value.RequestInputData?.RequestPurpose?.filter(x => x.purposeName !== item.purposeName) ?? [])
                                prop.onChange({
                                    ...prop.value,
                                    RequestInputData: {
                                        ...prop.value.RequestInputData,
                                        RequestPurpose: prop.value.RequestInputData?.RequestPurpose?.filter(x => x.purposeName !== item.purposeName) ?? []
                                    }
                                })
                            }
                        }}
                    />
                }

                {/* 대상 제품 */}
                {
                    (prop.displayObject === undefined || (prop.displayObject.product ?? false)) &&
                    <KeriEditGridInputField
                        title="대상 제품"
                        props={{
                            width: '100%'
                        }}
                        button={{
                            isEnable: prop.editMode ?? false,
                            label: '선택',
                            onClick: () => setOpenProductModal(true)
                        }}
                        value={
                            (prop.value.RequestInputData?.RequestProduct) 
                            &&
                            `[${prop.value.RequestInputData?.RequestProduct?.productType?.productTypeName}] ${prop.value.RequestInputData.RequestProduct.productName?.productName } (${prop.value.RequestInputData?.RequestProduct?.productNumber})`
                        }
                        editDisabled
                        error={{
                            isError: prop.error?.data?.product !== undefined,
                            message: prop.error?.data?.product
                        }}

                    />
                }

                {/* 신청 내용 */}
                {
                    (prop.displayObject === undefined || (prop.displayObject.info ?? false)) &&
                    <KeriEditGridInputField
                        title="신청 내용"
                        editDisabled={!(prop.editMode ?? false)}
                        tooltip={{
                            enable: true,
                            message: '?'
                        }}
                        props={{
                            multiline: true,
                            width: '100%',
                            height: '150px'
                        }}
                        onChange={(newValue) => {
                            prop.onChange({
                                ...prop.value,
                                RequestInputData: {
                                    ...prop.value.RequestInputData,
                                    RequestInfo: newValue
                                }
                            })

                            if (prop.error?.data.info !== undefined) {
                                prop.error.onChange({
                                    ...prop.error.data,
                                    info: undefined
                                })
                            }
                        }}
                        error={{
                            isError: prop.error?.data?.info !== undefined,
                            message: prop.error?.data?.info
                        }}
                        value={prop.value.RequestInputData?.RequestInfo}
                    />
                }

                {/* 희망일 */}
                {
                    (prop.displayObject === undefined || (prop.displayObject.desiredDate ?? false)) &&
                    <KeriEditGridInputField
                        title={`완료 희망일`}
                        type={'date'}
                        props={{
                            width: '50%'
                        }}
                        editDisabled={!(prop.editMode ?? false)}
                        tooltip={{
                            enable: true,
                            message: '1'
                        }}
                        onChange={(newValue) => {
                            prop.onChange({
                                ...prop.value,
                                RequestInputData: {
                                    ...prop.value.RequestInputData,
                                    RequestDesiredDate: new Date(newValue)
                                }
                            })
                            if (prop.error?.data.desiredDate !== undefined) {
                                prop.error.onChange({
                                    ...prop.error.data,
                                    desiredDate: undefined
                                })
                            }

                        }}
                        error={{
                            isError: prop.error?.data?.desiredDate !== undefined,
                            message: prop.error?.data?.desiredDate
                        }}
                        value={ObjectUtils.DateToString(prop.value.RequestInputData?.RequestDesiredDate, 'yyyy-MM-dd')}
                        inputProp={{
                            min: ObjectUtils.DateToString(Date.now(), 'yyyy-MM-dd')
                        }}
                    />
                }

                {/* 전문가 */}
                {/* {
                    (prop.displayObject === undefined || (prop.displayObject.product ?? false)) &&
                    prop.techServiceType === 'A' &&
                    <KeriEditGridInputField
                        title="전문가"
                        props={{
                            width: '100%'
                        }}
                        button={{
                            isEnable: (prop.editMode ?? false),
                            label: '선택',
                            onClick: () => setOpenExpertUserModal(true)
                        }}
                        value={
                            prop.value.RequestInputData?.RequestExpertUser &&
                            `${prop.value.RequestInputData?.RequestExpertUser?.UserName}@${prop.value.RequestInputData?.RequestExpertUser?.UserCompanyName}`
                        }
                        editDisabled
                        error={{
                            isError: prop.error?.data?.expert !== undefined,
                            message: prop.error?.data?.expert
                        }}
                    />
                } */}

                {
                    prop.techServiceType === 'T' &&
                    <React.Fragment>
                        {/* 시험 방법 */}
                        {
                            (prop.displayObject === undefined || (prop.displayObject.testMethod ?? false)) &&
                            <KeriEditGridInputField
                                title="시험 방법"
                                props={{
                                    width: '100%'
                                }}
                                button={{
                                    isEnable: prop.editMode ? true : false,
                                    label: '선택',
                                    onClick: () => setOpenTestModal({ state: true, category: 'TestMethod' })
                                }}
                                value={prop.value.RequestInputData?.RequestTestMethod?.TestName}
                                editDisabled
                                error={{
                                    isError: prop.error?.data?.testMethod !== undefined,
                                    message: prop.error?.data?.testMethod
                                }}
                            />
                        }
                        {/* 시험 유형 */}
                        {
                            (prop.displayObject === undefined || (prop.displayObject.testType ?? false)) &&
                            <KeriEditGridInputField
                                title="시험 유형"
                                props={{
                                    width: '100%'
                                }}
                                button={{
                                    isEnable: prop.editMode ? true : false,
                                    label: '선택',
                                    onClick: () => setOpenTestModal({ state: true, category: 'TestType' })
                                }}
                                value={prop.value.RequestInputData?.RequestTestType?.TestName}
                                editDisabled
                                error={{
                                    isError: prop.error?.data?.testType !== undefined,
                                    message: prop.error?.data?.testType
                                }}
                            />
                        }
                        {/* 시험 항목 */}
                        {
                            (prop.displayObject === undefined || (prop.displayObject.testItem ?? false)) &&
                            <KeriEditGridChipField
                                fieldTitle='시험 항목'
                                value={prop.value.RequestInputData?.RequestTestItem ?? []}
                                plugins={{
                                    button: {
                                        enable: prop.editMode ? true : false,
                                        label: '선택',
                                        onClick: () => setOpenTestModal({ state: true, category: 'TestItem' })
                                    }
                                }}
                                error={{
                                    isError: prop.error?.data?.testItem !== undefined,
                                    message: prop.error?.data?.testItem
                                }}
                                elementProps={{
                                    display: {
                                        displayChipLable: (item) => item.TestName ?? ''
                                    },
                                    disabledDelete: prop.editMode ? false : true,
                                    onDelete: (item) => {
                                        setTestItemList(prop.value.RequestInputData?.RequestTestItem?.filter(x => x.TestName !== item.TestName) ?? [])

                                        prop.onChange({
                                            ...prop.value,
                                            RequestInputData: {
                                                ...prop.value.RequestInputData,
                                                RequestTestItem: prop.value.RequestInputData?.RequestTestItem?.filter(x => x.TestName !== item.TestName) ?? []
                                            }
                                        })
                                    }
                                }}
                            />
                        }
                    </React.Fragment>
                }



            </KeriEditGrid>

            {/* Modal */}

            {/* 품목 Modal */}
            <KeriSelectProductItem
                open={openProductModal}
                onClose={() => {
                    setOpenProductModal(false);
                }}
                onSubmit={(item) => {
                    prop.onChange({
                        ...prop.value,
                        RequestInputData: {
                            ...prop.value.RequestInputData,
                            RequestProduct: item
                        }
                    })

                    if (prop.error?.data.product !== undefined) {
                        prop.error.onChange({
                            ...prop.error.data,
                            product: undefined
                        })
                    }
                }}
            />

            {/* 
            <KeriSelectProductItem
                open={openProductModal}
                onClose={() => {
                    setOpenProductModal(false);
                }}
                dataObject={{
                    onSubmit: (item) => {
                        if (item.length !== 0) {
                            prop.onChange({
                                ...prop.value,
                                RequestInputData: {
                                    ...prop.value.RequestInputData,
                                    RequestProduct: item[0]
                                }
                            })
                        }

                        if (prop.error?.data.product !== undefined) {
                            prop.error.onChange({
                                ...prop.error.data,
                                product: undefined
                            })
                        }
                    },
                }}
                modalObject={{
                    selectMode: 'Single'
                }}
            />
             */}


            {/* 전문가 Modal */}
            {/* <KeriSelectExpertUserModel
                open={openExpertUserModal}
                onClose={() => setOpenExpertUserModal(false)}
                submit={{
                    onFilter: (item) => {
                        if (item.length > 1) {
                            return { state: false, message: '한개만 선택이 가능합니다.' };
                        }
                        else if (item.length === 0) {
                            return { state: false, message: '항목을 선택해야 합니다.' };
                        }

                        return { state: true };
                    },
                    onSubmit: (item) => {
                        prop.onChange({
                            ...prop.value,
                            RequestInputData: {
                                ...prop.value.RequestInputData,
                                RequestExpertUser: item[0]
                            }
                        })

                        if (prop.error?.data.expert !== undefined) {
                            prop.error.onChange({
                                ...prop.error.data,
                                expert: undefined
                            })
                        }

                    }
                }}
            /> */}

            {/* 해석 목적 Modal */}
            <KeriSelectAnalysisPurposeModal
                // open={openPurposeModal.state && openPurposeModal.type === 'Analysis'}
                techServiceType={prop.techServiceType}
                open={openPurposeModal.state}
                onClose={() => setOpenPurposeModal({ state: false })}
                defaultValue={purposeList}
                submit={{
                    onFilter: (item) => {
                        if (item.length === 0) {
                            return { state: false, message: '항목을 선택해야 합니다.' };
                        }
                        else if(item.length > 1){
                            return { state: false, message: '항목은 한개만 선택이 가능합니다.' };
                        }

                        return { state: true };
                    },
                    onSubmit: (item) => {
                        setPurposeList(item);

                        prop.onChange({
                            ...prop.value,
                            RequestInputData: {
                                ...prop.value.RequestInputData,
                                RequestPurpose: [...item]
                            }
                        })

                        if (prop.error?.data.purpose !== undefined) {
                            prop.error.onChange({
                                ...prop.error.data,
                                purpose: undefined
                            })
                        }
                    }
                }}
            />

            {/* 시험 항목 Modal */}
            <KeriSelectTestAttributeModel
                open={openTestModal.state}
                onClose={() => { setOpenTestModal({ state: false }) }}
                showCategory={openTestModal.category ?? 'ALL'}
                defaultValue={openTestModal.category === 'TestItem' ? prop.value.RequestInputData?.RequestTestItem : []}
                submit={{
                    onFilter: (item) => {
                        if (item.length === 0) {
                            return {
                                state: false,
                                message: '항목을 선택해야 합니다.'
                            }
                        }

                        if (openTestModal.category === 'TestMethod' || openTestModal.category === 'TestType') {

                            if (item.length > 1) {
                                return {
                                    state: false,
                                    message: '한개만 선택이 가능합니다.'
                                }
                            }
                            else {
                                return { state: true }
                            }
                        }
                        else {
                            return { state: true }
                        }
                    },
                    onSubmit: (item) => {
                        if (openTestModal.category === 'TestMethod') {
                            prop.onChange({
                                ...prop.value,
                                RequestInputData: {
                                    ...prop.value.RequestInputData,
                                    RequestTestMethod: item[0]
                                }
                            })

                            if (prop.error?.data.testMethod !== undefined) {
                                prop.error.onChange({
                                    ...prop.error.data,
                                    testMethod: undefined
                                })
                            }
                        }
                        else if (openTestModal.category === 'TestType') {
                            prop.onChange({
                                ...prop.value,
                                RequestInputData: {
                                    ...prop.value.RequestInputData,
                                    RequestTestType: item[0]
                                }
                            })

                            if (prop.error?.data.testType !== undefined) {
                                prop.error.onChange({
                                    ...prop.error.data,
                                    testType: undefined
                                })
                            }
                        }
                        else if (openTestModal.category === 'TestItem') {
                            setTestItemList(item);

                            prop.onChange({
                                ...prop.value,
                                RequestInputData: {
                                    ...prop.value.RequestInputData,
                                    RequestTestItem: item
                                }
                            })

                            if (prop.error?.data.testItem !== undefined) {
                                prop.error.onChange({
                                    ...prop.error.data,
                                    testItem: undefined
                                })
                            }
                        }
                    }
                }}
            />
        </React.Fragment>
    )
}

export default RegisterTechServiceRequestInputField;