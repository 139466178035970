import { ObjectUtils } from "@ict-base/module/utils/ObjectUtils";
import { IUploadFileVO } from "@keri-portal/interfaces/api/keri-api/object/IUploadFileVO";
import { IUploadFile } from "@keri-portal/interfaces/control/IUploadFile";
import { IKeriProduct, IKeriProductName, IKeriProductType } from "@keri-portal/interfaces/data-object/IProduct";

const KeriProductValidationCheck = {
    addProduct: (data: {
        productName: IKeriProductName | undefined,
        productType: IKeriProductType | undefined,
        productNumber: string | undefined,
        productImage: IUploadFile<IUploadFileVO> | undefined
    }): {
        state: boolean, errorData: {
            productNumber?: string,
            productType?: string,
            productName?: string,
            productImage?: string
        }
    } => {
        let result: {
            state: boolean, errorData: {
                productNumber?: string,
                productType?: string,
                productName?: string,
                productImage?: string
            }
        } = { state: true, errorData: {} };

        if (data.productName === undefined) {
            result.state = false;
            result.errorData = {
                ...result.errorData,
                productName : '품목명이 선택되지 않았습니다.'
            }
        }

        if (data.productType === undefined) {
            result.state = false;
            result.errorData = {
                ...result.errorData,
                productType : '품목 구분이 선택되지 않았습니다.'
            }
        }


        if (ObjectUtils.IsNullOrEmpty(data.productNumber)) {
            result.state = false;
            result.errorData = {
                ...result.errorData,
                productNumber : '품목 구분이 선택되지 않았습니다.'
            }
        }


        // if(data.productImage?.UploadedFile?.streFileNm === undefined){
        //     result.state = false;
        //     result.errorData = {
        //         ...result.errorData,
        //         productImage : '품목 이미지가 업로드되지 않았습니다.'
        //     }
        // }

        return result;
    }
}


export default KeriProductValidationCheck;