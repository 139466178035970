import ICTBaseModal, { KeriModalSubmitAndCancelButton } from "@ict-base/components/control/ICTBaseModal"
import ICTPagination from "@ict-base/components/control/ICTPagination"
import { ICTSearchBox } from "@ict-base/components/control/ICTSearchBox"
import KeriActionButton from "@ict-base/components/control/KeriActionButton"
import KeriCheckBox from "@ict-base/components/control/KeriCheckBox"
import { ObjectUtils } from "@ict-base/module/utils/ObjectUtils"
import { IListPagination } from "@keri-base/interface/control/IListPagination"
import { ProductSearchCondition } from "@keri-portal/interfaces/api/keri-api/IKeriAPIRequest"
import { IKeriProduct } from "@keri-portal/interfaces/data-object/IProduct"
import useLoginCheck from "@keri-portal/module/hook/useLoginCheck"
import RequestKeriAPI from "@keri-portal/module/method/api-connect/RequestKeriAPI"
import { Add, Close, Delete, Search, Warning, WarningRounded } from "@mui/icons-material"
import { Box, CircularProgress, Paper, Typography, Divider, IconButton, Pagination } from "@mui/material"
import React, { useEffect, useRef, useState, useMemo } from "react"
import KeriAddProductItemsModal from "./KeriAddProductItemsModal"
import KeriEditProductNameAndTypeModal from "./KeriEditProductNameAndTypeModal"
import KeriProductAttributeModel from "./KeriProductAttributeModel"
import { MISCModal, MISCSearchBox } from "@ictpeople/misc-react"
import { ILoginSessionData } from "@src/modules/redux/reducer/UserDataReducer"
import { useSelector } from "react-redux"

interface IKeriSelectProductItem1 {
    open: boolean,
    onClose: () => void,
    dataObject: {
        onSubmit: (item: IKeriProduct[]) => void,
        defaultItem?: IKeriProduct
    },
    modalObject: {
        selectMode: 'Single' | 'Multiple'
    }
}

interface IKeriSelectProductItem {
    open: boolean,
    onClose: () => void,
    onSubmit: (item: IKeriProduct) => void,
}


const KeriSelectProductItem = (prop: IKeriSelectProductItem) => {

    const [searchData, setSearchData] = useState<{ page: number, searchText?: string }>({ page: 1 });
    const [totalPage, setTotalPage] = useState<number>(1);
    const [openAddProductModal, setOpenAddProductModal] = useState<boolean>(false);
    const [productList, setProductList] = useState<IKeriProduct[]>([]);
    const [loadList, setLoadList] = useState<boolean>(false);
    const userRedux: ILoginSessionData = useSelector((state: any) => state.user);

    const searchInputRef = useRef<HTMLInputElement>(null);

    const getProductList = async (_page: number, _searchText?: string) => {

        
        setSearchData({
            page: _page,
            searchText: _searchText
        });


        setLoadList(true);

        const getResponse = await RequestKeriAPI.Product.GetProduct(userRedux?.token, _searchText, _page);

        if (getResponse.state) {
            setProductList(getResponse.resultData ?? []);
            setTotalPage(getResponse.pageObject?.totalPage ?? 1);
        }
        else {
            alert(getResponse.message);
        }

        setLoadList(false);
    }


    const DeleteProductItem = async (item: IKeriProduct) => {
        setLoadList(true);

        const getResponse = await RequestKeriAPI.Product.DeleteProduct(userRedux?.token, [item]);


        if (getResponse.state) {
            setLoadList(false);

            getProductList(searchData.page, searchData.searchText);
        }
        else {

            alert(getResponse.message);
            setLoadList(false);
        }
    }

    useMemo(() => {
        if (searchData.page > totalPage) {
            getProductList(totalPage, searchData.searchText);
        }
    }, [totalPage])



    useEffect(() => {
        if (prop.open) {

            getProductList(1);
        }
        else {
            setOpenAddProductModal(false);
            setLoadList(false);
            setProductList([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prop.open])


    return (
        <MISCModal
            open={prop.open}
            onClose={prop.onClose}
            paperProps={{
                sx: {
                    width: '600px',
                }
            }}
        >
            <Box
                sx={{
                    p: 1,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}
            >
                <Typography
                    variant="subtitle1"
                >
                    품목 선택
                </Typography>
                <IconButton
                    sx={{
                        m: 0,
                        p: 0
                    }}
                    onClick={prop.onClose}
                >
                    <Close />
                </IconButton>
            </Box>
            <Divider />
            <Box
                sx={{
                    p: 1,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}
            >
                <Box
                    sx={{
                        width: '40%',

                    }}
                >
                    <Box
                        sx={{
                            border: '1px solid black',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            p: 0.5,
                            borderRadius: '5px',
                            borderColor: 'gray'
                        }}
                    >
                        <input
                            ref={searchInputRef}
                            style={{
                                outline: 'none',
                                border: 'none',
                                width: '100%',
                                fontFamily: 'Noto Sans KR'
                            }}
                            onKeyUp={(e) => {
                                if (e.key === 'Enter') {
                                    getProductList(searchData.page, searchInputRef.current?.value);
                                }
                            }}
                        />
                        <IconButton
                            sx={{
                                m: 0,
                                p: 0
                            }}
                        >
                            <Search
                                sx={{
                                    color: 'gray'
                                }}
                            />
                        </IconButton>

                    </Box>
                </Box>
                <Box>
                    <KeriActionButton
                        label="품목 추가"
                        variant="outlined"
                        icon={<Add fontSize="small" />}
                        size={'small'}
                        onClick={() => setOpenAddProductModal(true)}
                        sx={{
                            ml: 1
                        }}
                    />
                </Box>

            </Box>

            {/* List */}
            <Box
                sx={{
                    p: 1,
                    pt: 0
                }}
            >
                {
                    loadList &&
                    <Box
                        sx={{
                            width: '100%',
                            height: '150px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column'
                        }}
                    >
                        <CircularProgress />
                    </Box>
                }
                {
                    !loadList && productList.length === 0 &&
                    <Box
                        sx={{
                            width: '100%',
                            height: '150px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column'
                        }}
                    >
                        <WarningRounded fontSize="large" />
                        <Typography>
                            검색된 데이터가 없습니다.
                        </Typography>
                    </Box>
                }
                {
                    !loadList && productList.map(item => (
                        <KeriSelectProductItemObject
                            onItemClick={() => {
                                prop.onSubmit(item);
                                prop.onClose();
                            }}
                            item={item}
                            onDelete={DeleteProductItem}
                        />
                    ))
                }
            </Box>
            <Box
                sx={{
                    mb: 2,
                    display : 'flex',
                    justifyContent : 'center',
                    alignItems : 'center'
                }}
            >
                {
                    !loadList &&
                    <Pagination
                        count={totalPage}
                        defaultPage={searchData.page}
                        onChange={(env, page) => getProductList(page, searchData.searchText)}
                    />
                }

            </Box>


            <KeriAddProductItemsModal
                open={openAddProductModal}
                onClose={() => setOpenAddProductModal(false)}
                onSubmit={() => {
                    setOpenAddProductModal(false);
                    getProductList(searchData.page, searchData.searchText);
                }}
            />
        </MISCModal>
    )
}

interface IKeriSelectProductItemObject {
    item: IKeriProduct,
    onDelete: (item: IKeriProduct) => void,
    onItemClick: (item: IKeriProduct) => void
}

const KeriSelectProductItemObject = (prop: IKeriSelectProductItemObject) => {


    const boxClickHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => prop.onItemClick(prop.item);

    const deleteClickHandler = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        const isDelete = window.confirm('품목을 삭제하시겠습니까?\n삭제 이후 복구가 불가능합니다.');
        isDelete && prop.onDelete(prop.item);
    }

    return (
        <Paper
            elevation={0}
            sx={{
                border: '1px solid rgba(0,0,0,0.3)',
                p: 1,
                mb: 1,
                cursor: 'pointer',
                transition: '0.3s filter cubic-bezier(0,0,0,1)',
                ":hover": {
                    filter: 'Brightness(0.95)'
                }
            }}
            onClick={boxClickHandler}

        >
            <Box
                sx={{
                    p: 1,
                    pb: 0,
                    pt: 0,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}
            >
                <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                    {`[${prop.item.productType?.productTypeName}] ${prop.item.productName?.productName}(${prop.item.productNumber})`}
                </Typography>
                <IconButton
                    onClick={deleteClickHandler}
                >
                    <Delete fontSize="small" color="error" />
                </IconButton>
            </Box>
            <Divider />
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'start',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    pt: 1
                }}
            >
                <KeriSelectProductItemObjectAttributeItem
                    title="품목명"
                    value={prop.item.productName?.productName}
                />

                <KeriSelectProductItemObjectAttributeItem
                    title="품목 구분"
                    value={prop.item.productType?.productTypeName}
                />

                <KeriSelectProductItemObjectAttributeItem
                    title="품목 번호"
                    value={prop.item.productNumber}
                />

                <KeriSelectProductItemObjectAttributeItem
                    title="생성일"
                    value={prop.item.productApplyDate?.toStringFormat("yyyy-MM-dd")}
                />
            </Box>
        </Paper>
    )
}

const KeriSelectProductItemObjectAttributeItem = (prop: { title: string, value?: string }) => {
    return (
        <React.Fragment>
            {
                prop.value !== undefined &&
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'start',
                        alignItems: 'center',
                        m: 1,
                        mb: 0.5,
                        mt: 0.5,
                        minWidth: '200px'
                    }}
                >
                    <Box>
                        <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
                            {prop.title}
                        </Typography>

                    </Box>
                    <Box
                        sx={{
                            ml: 1
                        }}
                    >
                        <Typography variant="subtitle2" sx={{ fontWeight: 300 }}>
                            {prop.value}
                        </Typography>
                    </Box>
                </Box>
            }
        </React.Fragment>
    )
}




const KeriSelectProductItem1 = (prop: IKeriSelectProductItem1) => {

    const [selectItem, setSelectItem] = useState<IKeriProduct[]>([]);
    const [productItemList, setProductItemList] = useState<IKeriProduct[]>([]);

    const [loadingData, setLoadingData] = useState<boolean>(false);
    const [searchText, setSearchText] = useState<string | undefined>(undefined);


    /** Modal */
    // 품목 속성 관리 Modal Control
    const [productAttributeControlModalOpen, setProductAttributeControlModalOpen] = useState<boolean>(false);
    // 품목 추가 Modal Control
    const [openAddProductModal, setOpenAddProductModal] = useState<boolean>(false);


    const [page, setPage] = useState<IListPagination>({
        pageObject: {
            nowPage: 1,
            totalPage: 1
        }
    });

    const userRedux = useLoginCheck();

    const getAPIData = async (searchText?: string, page?: number) => {
        setLoadingData(true);

        const getResponse = await RequestKeriAPI.Product.GetProduct(userRedux?.token, searchText, page);

        if (getResponse.state) {
            setProductItemList(getResponse.resultData ?? [])
        }
        else {
            alert(getResponse.message);

            if (!getResponse.tokenValid) {

            }
        }

        setLoadingData(false);
    }

    useEffect(() => {
        if (prop.open) {
            setProductItemList([]);
            setSelectItem([]);

            prop.dataObject.defaultItem && setSelectItem([prop.dataObject.defaultItem]);

            getAPIData(undefined, 1);
        }
    }, [prop.open])

    useEffect(() => {
        if (prop.open) {
            setProductItemList([]);
            setSelectItem([]);
            getAPIData(searchText, 1);
        }
    }, [searchText, page.pageObject?.nowPage])



    const onSubmit = (): boolean => {

        if (prop.modalObject.selectMode === 'Single' && selectItem.length > 1) {
            alert('최대 선택 갯수는 1개입니다.');
            return false;
        }
        else {
            prop.dataObject.onSubmit(selectItem);
            return true;
        }
    }

    return (
        <ICTBaseModal
            open={prop.open}
            onClose={prop.onClose}
            title="품목 선택"
            submitButton={<KeriModalSubmitAndCancelButton
                onSubmit={() => {
                    const valCheck = onSubmit();
                    valCheck && prop.onClose()
                }}
                onCancel={prop.onClose}
            />}
        >
            <Box
                sx={{
                    width: 800,
                    p: 2,
                    pb: 0
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <ICTSearchBox
                        onSearch={(text) => {
                            console.log('SEARCH', text);
                            setSearchText(text)
                        }}
                        sx={{
                            width: '40%'
                        }}
                        onChange={(text) => {
                            console.log('CHANGE EVENT', text);
                        }}
                    />
                    <Box>
                        {/* <KeriActionButton
                            label="품목명/구분 관리"
                            variant="outlined"
                            icon={<Add fontSize="small" />}
                            size={'small'}
                            color='secondary'
                            onClick={() => setProductAttributeControlModalOpen(true)}
                            sx={{
                                ml: 1
                            }}
                        /> */}
                        <KeriActionButton
                            label="품목 추가"
                            variant="outlined"
                            icon={<Add fontSize="small" />}
                            size={'small'}
                            onClick={() => {
                                setOpenAddProductModal(true);
                            }}
                            sx={{
                                ml: 1
                            }}
                        />
                        <KeriActionButton
                            label="품목 삭제"
                            variant="outlined"
                            color="error"
                            icon={<Add fontSize="small" />}
                            size={'small'}
                            onClick={async () => {
                                const deleteConf = window.confirm('선택된 항목들을 삭제하시겠습니까?');
                                if (deleteConf) {
                                    // setProductItemList([
                                    //     ...productItemList.filter(x => selectItem.find(y => y.productNumber === x.productNumber) === undefined)
                                    // ]);

                                    const getResponse = await RequestKeriAPI.Product.DeleteProduct(userRedux?.token, selectItem);
                                    setLoadingData(true);
                                    if (getResponse.state) {

                                    }
                                    else {
                                        alert(getResponse.message);
                                    }

                                    setLoadingData(false);

                                    getAPIData(searchText, page.pageObject?.nowPage);

                                    setSelectItem([]);
                                }
                            }}
                            sx={{
                                ml: 1
                            }}
                        />
                    </Box>
                </Box>
                {
                    loadingData
                        ?
                        <Box
                            sx={{
                                p: 1,
                                pt: 5,
                                pb: 3,
                                textAlign: 'center'
                            }}
                        >
                            <CircularProgress color="inherit" />
                        </Box>
                        :
                        <Box
                            sx={{
                                mt: 3
                            }}
                        >
                            {
                                productItemList.length === 0 &&
                                <Box
                                    sx={{
                                        p: 1,
                                        pt: 3,
                                        pb: 3,
                                        textAlign: 'center'
                                    }}
                                >
                                    검색된 값이 없습니다.
                                </Box>
                            }
                            {
                                productItemList.length !== 0 &&

                                <Box>
                                    <Paper
                                        elevation={0}
                                        sx={{
                                            p: 2,
                                            pb: 0,
                                            pt: 0,
                                            mr: 0.5,
                                            ml: 0.5,
                                            mb: 1,
                                            // mb: 2,
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                fontSize: '0.7rem'
                                            }}
                                        >
                                            <Box>
                                                <KeriCheckBox
                                                    sx={{
                                                        width: '30px'
                                                    }}
                                                    checked={selectItem.length === productItemList.length}
                                                    onClick={() => {
                                                        if (selectItem.length === productItemList.length) {
                                                            setSelectItem([]);
                                                        }
                                                        else {
                                                            setSelectItem(productItemList);
                                                        }
                                                    }}
                                                />
                                            </Box>
                                            <Box
                                                sx={{
                                                    width: '200px',
                                                    textAlign: 'start',
                                                    overflow: 'hidden',
                                                    whiteSpace: 'nowrap',
                                                    textOverflow: 'ellipsis',
                                                    pl: 1
                                                }}
                                            >
                                                품목 구분
                                            </Box>
                                            <Box
                                                sx={{
                                                    width: 'calc(100% - 200px - 150px - 100px - 30px)',
                                                    textAlign: 'start',
                                                    pl: 1
                                                }}
                                            >
                                                품목 이름
                                            </Box>

                                            <Box
                                                sx={{
                                                    width: '150px',
                                                    textAlign: 'center'
                                                }}
                                            >
                                                품목 번호
                                            </Box>
                                            <Box
                                                sx={{
                                                    width: '100px',
                                                    textAlign: 'center'
                                                }}
                                            >
                                                생성날짜
                                            </Box>
                                        </Box>
                                    </Paper>
                                    {
                                        productItemList.map(item => (
                                            <Paper
                                                elevation={2}
                                                sx={{
                                                    p: 2,
                                                    m: 0.5,
                                                    mb: 1,
                                                    cursor: 'pointer',
                                                    transition: '0.5s all cubic-bezier(0,0,0,1)',
                                                    border: '1px solid rgba(0,0,0,0.05)',
                                                    ":hover": {
                                                        background: 'rgba(0,0,0,0.1)',
                                                        mr: 0,
                                                        ml: 0
                                                    }
                                                }}
                                                onClick={() => {
                                                    if (selectItem.filter(x => x.productNumber === item.productNumber).length === 0) {
                                                        setSelectItem([
                                                            ...selectItem.filter(x => x.productNumber !== item.productNumber),
                                                            item
                                                        ])
                                                    }
                                                    else {
                                                        setSelectItem([
                                                            ...selectItem.filter(x => x.productNumber !== item.productNumber)
                                                        ])
                                                    }

                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        alignItems: 'center',
                                                        fontSize: '0.7rem'
                                                    }}
                                                >
                                                    <Box>
                                                        <KeriCheckBox
                                                            sx={{
                                                                width: '30px'
                                                            }}
                                                            checked={selectItem.filter(x => x.productNumber === item.productNumber).length !== 0}
                                                        />
                                                    </Box>
                                                    <Box
                                                        sx={{
                                                            width: '200px',
                                                            textAlign: 'start',
                                                            overflow: 'hidden',
                                                            whiteSpace: 'nowrap',
                                                            textOverflow: 'ellipsis',
                                                            pl: 1
                                                        }}
                                                    >
                                                        {item.productType?.productTypeName}
                                                    </Box>
                                                    <Box
                                                        sx={{
                                                            width: 'calc(100% - 200px - 150px - 100px - 30px)',
                                                            textAlign: 'start',
                                                            pl: 1
                                                        }}
                                                    >
                                                        {item.productName?.productName}
                                                    </Box>

                                                    <Box
                                                        sx={{
                                                            width: '150px',
                                                            textAlign: 'center'
                                                        }}
                                                    >
                                                        {item.productNumber}
                                                    </Box>
                                                    <Box
                                                        sx={{
                                                            width: '100px',
                                                            textAlign: 'center'
                                                        }}
                                                    >
                                                        {ObjectUtils.DateToString(item.productApplyDate, 'yyyy-MM-dd')}
                                                    </Box>
                                                </Box>
                                            </Paper>
                                        ))
                                    }
                                </Box>

                            }
                            <ICTPagination
                                align="center"
                                pageObject={{
                                    nowPage: page.pageObject?.nowPage,
                                    totalPage: page.pageObject?.totalPage,
                                    onChangePage: (changePage) => {
                                        setPage({
                                            pageObject: {
                                                ...page.pageObject,
                                                nowPage: changePage
                                            }
                                        })
                                    }
                                }}
                            />
                        </Box>
                }

            </Box>

            {/* Modal */}
            <KeriProductAttributeModel
                open={productAttributeControlModalOpen}
                onClose={() => setProductAttributeControlModalOpen(false)}
                showCategory='ALL'
            />

            <KeriAddProductItemsModal
                open={openAddProductModal}
                onClose={() => setOpenAddProductModal(false)}
                onSubmit={() => {
                    setOpenAddProductModal(false);
                    getAPIData(searchText, page.pageObject?.nowPage);
                }}
            />
        </ICTBaseModal>

    )
}

export default KeriSelectProductItem;