import { ITechServiceRequest } from "@keri-portal/interfaces/process-object/tech-service/ITechServiceRequest";
import { CheckCircleRounded } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import KeriActionButton from "@ict-base/components/control/KeriActionButton";
import { IKeriStepShareProps, KeriStepItemLayout } from "@keri-portal/component/layout/StepLayout";
import URLStructure from "@src/modules/structure/URLStructure";

const KeriRegisterTechServiceCompleteItem = (prop: IKeriStepShareProps<ITechServiceRequest>) => {

    const navigate = useNavigate();


    const validationCheck = (): boolean => {
        return false;
    }


    return (
        <KeriStepItemLayout
            {...prop}
            validationCheck={validationCheck}
            step={prop.step}
            page={{
                moveNext: (setup, validation) => {
                    if(validation){
                        setup(prop.step.activeStep + 1);
                    }
                },
            }}
            disableButton
        >
            <Box
                sx={{
                    textAlign: 'center'
                }}
            >
                <CheckCircleRounded
                    sx={{
                        fontSize: 200,
                        color: '#b7e349'
                    }}
                />
                <Box
                    sx={{
                        textAlign: 'center'
                    }}
                >
                    <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
                        기술지원 신청이 완료되었습니다.
                    </Typography>
                    <KeriActionButton 
                        label="기술지원 목록 바로가기"
                        variant='text'
                        color='primary'
                        onClick={() => {
                            navigate(URLStructure.MemberInfoTechServiceList)
                        }}
                    />
                </Box>
            </Box>
        </KeriStepItemLayout>
    )
}

export default KeriRegisterTechServiceCompleteItem;