import { ICTSearchBox } from "@ict-base/components/control/ICTSearchBox";
import LoginChecker from "@keri-base/components/common/LoginChecker";
import { IPageProp } from "@keri-base/interface/common/IPageProp";
import InfoLayoutPage from "@keri-portal/component/layout/InfoLayoutPage";
import { IBoard, IFaqItem } from "@keri-portal/interfaces/data-object/data-common/IBoard";
import { AutoAwesome, AutoAwesomeMosaic, AutoAwesomeMosaicOutlined, Filter, FilterAlt, FilterAltOutlined, FormatListBulleted, FormatListBulletedTwoTone, PanoramaFishEye, PersonOutline, PictureInPicture, RemoveRedEye, RemoveRedEyeOutlined } from "@mui/icons-material";
import { Box, CircularProgress, Divider, IconButton, Paper, Typography } from "@mui/material";
import { ILoginSessionData } from "@src/modules/redux/reducer/UserDataReducer";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Autoplay, Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide, } from 'swiper/react';
import 'swiper/css';
import { ObjectUtils } from "@ict-base/module/utils/ObjectUtils";
import RequestKeriAPI from "@keri-portal/module/method/api-connect/RequestKeriAPI";
import { Link } from "react-router-dom";
import URLStructure from "@src/modules/structure/URLStructure";
import ICTPagination from "@ict-base/components/control/ICTPagination";
import { IListPagination } from "@keri-base/interface/control/IListPagination";
import { KeriEditGridCheckBoxField } from "@ict-base/components/control/KeriEditGrid";


interface IFaqFilter {
    code: string,
    codeName: string
}

const FaqBoardPage = (prop: IPageProp) => {

    const param = useParams();
    const [loginData, setLoginData] = useState<ILoginSessionData | undefined>(undefined);
    const [faqData, setFaqData] = useState<{ state: boolean, itemList?: IFaqItem[] | undefined } | undefined>(undefined);
    const [searchObj, setSearchObj] = useState<{ data: string | undefined, searchState: boolean, lastSearchText: string | undefined } | undefined>(undefined);
    const [pageObj, setPageObj] = useState<{ nowPage: number, totalPage: number, isMovePage: boolean } | undefined>({
        nowPage: 1,
        totalPage: 1,
        isMovePage: false
    })
    const [boardMode, setBoardMode] = useState<'GALALY' | 'LIST'>('LIST');
    const [openFilter, setOpenFilter] = useState<boolean>(false);
    const [filterList, setFilterList] = useState<IFaqFilter[]>([]);
    const [selectFilter, setSelectFilter] = useState<IFaqFilter>({
        code: 'ALL',
        codeName: '전체'
    });
    const navigate = useNavigate();
    const location = useLocation();


    const ENABLE_BOARD_MODE_CHANGE = false;



    //#region Get QueryString

    const queryString = location.search.replace('?', '').split('&').filter(x => x !== undefined);
    let searchText = queryString.find(x => x.split('=')[0] === 'search')?.replace(queryString.find(x => x.split('=')[0] === 'search')?.split('=')[0] + "=", '');
    searchText = searchText !== undefined ? decodeURI(searchText) : undefined;
    const pageQuery = queryString.find(x => x.split('=')[0] === 'page')?.replace(queryString.find(x => x.split('=')[0] === 'page')?.split('=')[0] + "=", '');
    const pageNumber = isNaN(parseInt(pageQuery ?? '1')) ? undefined : parseInt(pageQuery ?? '1')

    //#endregion



    const getData = async (searchText?: string, page?: number, searchType?: string) => {

        console.warn('SEARCH OBJECT' , searchText??'NULL', page,  searchType);

        window.history.replaceState('', "", `${location.pathname}?${ObjectUtils.IsNullOrEmpty(searchText) ? '' : `search=${searchText}&`}${ObjectUtils.IsNullOrEmpty(searchType) ? '' : `type=${searchType}&`}page=${page ?? 1}`);

        const getResult = await RequestKeriAPI.Board.GetFaqContentList(searchText, page, searchType, 9);

        if (getResult.state) {
            console.log(getResult);
            setFaqData({ state: true, itemList: getResult.resultData })
            setFilterList([
                {
                    code: 'ALL',
                    codeName: '전체'
                },
                ...getResult.filterData ?? []
            ]);

            setPageObj({
                nowPage: getResult.pageObject!.nowPage!,
                totalPage: getResult.pageObject!.totalPage!,
                isMovePage: false
            });
            setSearchObj({
                data: searchText ?? '',
                lastSearchText: searchText,
                searchState: false
            })
        }
        else {
            setFaqData({ state: false });
            alert(getResult.message);
        }

    }

    useEffect(() => {
        getData(searchText, pageNumber);
    }, [])

    useEffect(() => {
        if ((searchObj?.searchState ?? false)) {
            getData(searchObj?.lastSearchText, pageObj?.nowPage , selectFilter.code);
        }
    }, [searchObj])

    useEffect(() => {
        if (pageObj !== undefined) {
            if (pageObj.isMovePage) {
                getData(searchObj?.lastSearchText, pageObj.nowPage)
            }
        }
    }, [pageObj])


    return (
        <LoginChecker
            onCheck={(state, data) => {
                if (state === 'LOGIN') {
                    setLoginData(data);
                }
                else {
                    setLoginData(undefined);
                }
            }}
        >
            {
                faqData === undefined ?
                    <InfoLayoutPage>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                pt: 20
                            }}
                        >
                            <CircularProgress color="inherit" />
                        </Box>

                    </InfoLayoutPage>
                    :
                    <React.Fragment>
                        {
                            (!(faqData.state ?? false))
                                ?
                                <InfoLayoutPage>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            pt: 20
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                textAlign: 'center'
                                            }}
                                        >
                                            <img src={`${URLStructure.Main}assets/img/ict/images/top_logo.svg`} />
                                            <Typography
                                                variant='h4'
                                                sx={{
                                                    mb: 4,
                                                    mt: 10
                                                }}
                                            >
                                                페이지를 찾을 수 없습니다.
                                            </Typography>
                                            <Typography
                                                onClick={() => navigate(URLStructure.Main)}
                                                sx={{
                                                    cursor: 'pointer',
                                                    transition: '0.5s all cubic-bezier(0,0,0,1)',
                                                    ":hover": {
                                                        color: '#52b5b0'
                                                    }
                                                }}
                                            >
                                                메인 페이지로 이동
                                            </Typography>
                                        </Box>

                                    </Box>
                                </InfoLayoutPage>
                                :
                                <InfoLayoutPage
                                    title={'자주 묻는 질문과 답'}
                                    endAdornment={
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'end',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <ICTSearchBox
                                                onSearch={(changeText) => {
                                                    setSearchObj({
                                                        data: changeText,
                                                        searchState: true,
                                                        lastSearchText: changeText
                                                    })
                                                    setPageObj({
                                                        nowPage: 1,
                                                        totalPage: 1,
                                                        isMovePage: false
                                                    })
                                                }}

                                            />
                                            {
                                                ENABLE_BOARD_MODE_CHANGE &&
                                                <IconButton
                                                    sx={{
                                                        ml: 1,
                                                        border: '1px solid #aaa',
                                                        borderRadius: '5px',
                                                        p: 0.7,
                                                    }}
                                                    onClick={() => setBoardMode(boardMode === 'LIST' ? 'GALALY' : 'LIST')}
                                                >
                                                    {
                                                        boardMode === 'LIST'
                                                            ?
                                                            <AutoAwesomeMosaicOutlined />
                                                            :
                                                            <FormatListBulletedTwoTone />
                                                    }
                                                </IconButton>
                                            }

                                            <IconButton
                                                sx={{
                                                    ml: 1,
                                                    border: '1px solid #aaa',
                                                    borderRadius: '5px',
                                                    p: 0.7,
                                                }}
                                                onClick={() => setOpenFilter(!openFilter)}
                                            >
                                                <FilterAltOutlined />
                                            </IconButton>
                                        </Box>
                                    }
                                >
                                    <Box
                                        sx={
                                            boardMode === 'GALALY' ?
                                                {
                                                    display: 'flex',
                                                    justifyContent: 'start',
                                                    alignItems: 'start',
                                                    flexWrap: 'wrap'
                                                }
                                                : undefined
                                        }
                                    >
                                        {
                                            (openFilter && filterList.length !== 0) &&
                                            <Box
                                                sx={{
                                                    mt: 1,
                                                    mb: 1
                                                }}
                                            >
                                                <KeriEditGridCheckBoxField
                                                    data={filterList}
                                                    value={[selectFilter]}
                                                    displayValue={(item) => item.codeName}
                                                    displayKey={(item) => item.code}
                                                    nonDisableItemChecked
                                                    onItemChanged={(item) => {
                                                        if ((item ?? []).length > 0) {
                                                            setSelectFilter(item[0]);
                                                            getData(searchObj?.lastSearchText, pageObj?.nowPage, item[0].code)
                                                        }
                                                    }}
                                                    props={{
                                                        titleBoxWidth: '0px',
                                                    }}
                                                />
                                            </Box>
                                        }
                                        {
                                            (faqData.itemList ?? []).map(boardItem => (
                                                <React.Fragment
                                                    key={ObjectUtils.RandomStrings(10)}
                                                >
                                                    {
                                                        boardMode === 'LIST'
                                                            ?
                                                            <Paper
                                                                sx={{
                                                                    p: 1,
                                                                    mb: 1,
                                                                    display: 'flex',
                                                                    justifyContent: 'space-between',
                                                                    alignItems: 'center',
                                                                    cursor: 'pointer',
                                                                    ":hover": {
                                                                        boxShadow: '0 1rem 1rem -0.75rem rgb(0 0 0 / 30%)',
                                                                        background: '#f4f4f4'
                                                                    }
                                                                }}
                                                                onClick={() => {
                                                                    navigate(boardItem.FaqID)
                                                                }}
                                                            >
                                                                <Box
                                                                    sx={{
                                                                        width: '100px',
                                                                        maxHeight: '150px',

                                                                    }}
                                                                >
                                                                    <Swiper
                                                                        className="mySwiper"
                                                                        style={{ height: '100%', width: '100px' }}
                                                                        speed={300}
                                                                        loop
                                                                        loopedSlides={1}
                                                                        autoplay={{
                                                                            delay: (boardItem.FaqThumbnail ?? []).length > 1 ? 3000 : 1000000,
                                                                            disableOnInteraction: false
                                                                        }}
                                                                        allowTouchMove={false}
                                                                        modules={[Autoplay]}
                                                                    >
                                                                        <React.Fragment>
                                                                            {
                                                                                ((boardItem.FaqThumbnail ?? []).length === 0 ? [`${URLStructure.Asset}/img/ict/images/top_logo.svg`] : (boardItem.FaqThumbnail ?? [])).map(thumbnailItem => (
                                                                                    <SwiperSlide
                                                                                        key={ObjectUtils.RandomStrings(10)}
                                                                                        style={{
                                                                                            height: "100%",
                                                                                            display: 'flex',
                                                                                            justifyContent: 'center',
                                                                                            alignItems: 'center',
                                                                                        }}>
                                                                                        <img
                                                                                            src={thumbnailItem}
                                                                                            style={{
                                                                                                height: '100%',
                                                                                                width: '60px',
                                                                                                objectFit: (boardItem.FaqThumbnail ?? []).length === 0 ? 'scale-down' : 'contain'

                                                                                            }}
                                                                                        />
                                                                                    </SwiperSlide>
                                                                                ))
                                                                            }
                                                                        </React.Fragment>
                                                                    </Swiper>
                                                                </Box>
                                                                <Box
                                                                    sx={{
                                                                        width: 'calc(100% - 80px)',
                                                                        p: 1,
                                                                        pl: 2
                                                                    }}
                                                                >
                                                                    <Box
                                                                        sx={{
                                                                            display: 'flex',
                                                                            justifyContent: 'space-between',
                                                                            alignItems: 'center'
                                                                        }}
                                                                    >
                                                                        <Typography
                                                                            variant="h5"
                                                                            sx={{
                                                                                mb: 2,
                                                                                fontWeight: '500',
                                                                                maxWidth: '70%',
                                                                                overflow: 'hidden',
                                                                                textOverflow: 'ellipsis',
                                                                                whiteSpace: "nowrap"
                                                                            }}
                                                                        >
                                                                            {boardItem.Subject}
                                                                        </Typography>

                                                                        <Box
                                                                            sx={{
                                                                                display: 'flex',
                                                                                justifyContent: 'end',
                                                                                alignItems: 'center',
                                                                                color: '#aaa',
                                                                                '& *': {
                                                                                    fontSize: '0.8rem'
                                                                                }
                                                                            }}
                                                                        >
                                                                            {/* 조회수 */}
                                                                            <Box
                                                                                sx={{
                                                                                    display: 'flex',
                                                                                    justifyContent: 'start',
                                                                                    alignItems: 'center',
                                                                                    ml: 1
                                                                                }}
                                                                            >
                                                                                <RemoveRedEyeOutlined
                                                                                    sx={{
                                                                                        mr: 0.5,
                                                                                        fontSize: '1.0rem !important',
                                                                                    }}
                                                                                />
                                                                                <Typography>
                                                                                    {boardItem.viewCount ?? 0}
                                                                                </Typography>
                                                                            </Box>

                                                                            {/* 작성자 */}
                                                                            {/* <Box
                                                                                sx={{
                                                                                    display: 'flex',
                                                                                    justifyContent: 'start',
                                                                                    alignItems: 'center',
                                                                                    ml: 1
                                                                                }}
                                                                            >
                                                                                <PersonOutline
                                                                                    sx={{
                                                                                        mr: 0.5,
                                                                                        fontSize: '1.0rem !important',
                                                                                    }}
                                                                                />
                                                                                <Typography>
                                                                                    {boardItem.BoardItemWriter}
                                                                                </Typography>
                                                                            </Box> */}

                                                                            {/* 작성일 */}
                                                                            {/* <Box
                                                                                sx={{
                                                                                    display: 'flex',
                                                                                    justifyContent: 'start',
                                                                                    alignItems: 'center',
                                                                                    ml: 1
                                                                                }}
                                                                            >
                                                                                <Typography>
                                                                                    {ObjectUtils.DateToString(boardItem.FaqWriteDate, 'yyyy-MM-dd')}
                                                                                </Typography>
                                                                            </Box> */}
                                                                        </Box>
                                                                    </Box>

                                                                    <Typography
                                                                        sx={{
                                                                            overflow: 'hidden',
                                                                            wordBreak: 'break-word',
                                                                            overflowWrap: 'break-word',
                                                                            lineHeight: '1.3rem',
                                                                            // height: '5.2rem',
                                                                            textOverflow: 'ellipsis',
                                                                            WebkitLineClamp: '4',
                                                                            display: '-webkit-box',
                                                                            WebkitBoxOrient: 'vertical',
                                                                        }}
                                                                    >
                                                                        {boardItem.FaqAnswer?.replaceAll(/<(.*?)>/g, '').replaceAll('&lt;', '<').replaceAll('&gt;', '>').replaceAll('&nbsp;', '')}
                                                                    </Typography>
                                                                </Box>
                                                            </Paper>
                                                            :
                                                            <Paper
                                                                sx={{
                                                                    p: 1,
                                                                    mb: 4,
                                                                    mr: '2%',
                                                                    width: '32%',
                                                                    cursor: 'pointer',
                                                                    ":hover": {
                                                                        boxShadow: '0 1rem 1rem -0.75rem rgb(0 0 0 / 30%)',
                                                                        background: '#f4f4f4'
                                                                    },
                                                                    ":nth-child(3n)": {
                                                                        mr: 0
                                                                    }
                                                                }}
                                                                onClick={() => {
                                                                    navigate(boardItem.FaqID)
                                                                }}
                                                            >
                                                                <Box
                                                                    sx={{
                                                                        width: '100%'
                                                                    }}
                                                                >
                                                                    <Swiper
                                                                        className="mySwiper"
                                                                        style={{ width: '100%' }}
                                                                        speed={300}
                                                                        loop
                                                                        loopedSlides={1}
                                                                        autoplay={{
                                                                            delay: (boardItem.FaqThumbnail ?? []).length > 1 ? 3000 : 1000000,
                                                                            disableOnInteraction: false
                                                                        }}
                                                                        allowTouchMove={false}
                                                                        modules={[Autoplay]}
                                                                    >
                                                                        <React.Fragment>
                                                                            {
                                                                                ((boardItem.FaqThumbnail ?? []).length === 0 ? [`${URLStructure.Asset}/img/ict/images/top_logo.svg`] : (boardItem.FaqThumbnail ?? [])).map(thumbnailItem => (
                                                                                    <SwiperSlide key={ObjectUtils.RandomStrings(10)}>
                                                                                        <img
                                                                                            src={thumbnailItem}
                                                                                            style={{
                                                                                                height: '25vw',
                                                                                                width: '100%',
                                                                                                maxWidth: '100%',
                                                                                                maxHeight: '200px',
                                                                                                objectFit: (boardItem.FaqThumbnail ?? []).length === 0 ? 'scale-down' : 'contain'
                                                                                            }}
                                                                                        />
                                                                                    </SwiperSlide>
                                                                                ))
                                                                            }
                                                                        </React.Fragment>
                                                                    </Swiper>
                                                                </Box>
                                                                <Divider />
                                                                <Box
                                                                    sx={{
                                                                        p: 1
                                                                    }}
                                                                >
                                                                    <Typography
                                                                        variant="h5"
                                                                        sx={{
                                                                            fontWeight: '500',
                                                                            overflow: 'hidden',
                                                                            wordBreak: 'break-word',
                                                                            overflowWrap: 'break-word',
                                                                            lineHeight: '1.6rem',
                                                                            height: '3.2rem',
                                                                            textOverflow: 'ellipsis',
                                                                            WebkitLineClamp: '2',
                                                                            display: '-webkit-box',
                                                                            WebkitBoxOrient: 'vertical',
                                                                        }}
                                                                    >
                                                                        {boardItem.Subject}
                                                                    </Typography>
                                                                    <Box
                                                                        sx={{
                                                                            display: 'flex',
                                                                            justifyContent: 'end',
                                                                            alignItems: 'end',
                                                                            mt: 2,
                                                                            color: '#aaa',
                                                                            '& *': {
                                                                                fontSize: '0.8rem'
                                                                            }
                                                                        }}
                                                                    >
                                                                        {/* 조회수 */}
                                                                        <Box
                                                                            sx={{
                                                                                display: 'flex',
                                                                                justifyContent: 'start',
                                                                                alignItems: 'center',
                                                                                ml: 1
                                                                            }}
                                                                        >
                                                                            <RemoveRedEyeOutlined
                                                                                sx={{
                                                                                    mr: 0.5,
                                                                                    fontSize: '1.0rem !important',
                                                                                }}
                                                                            />
                                                                            <Typography>
                                                                                {boardItem.viewCount ?? 0}
                                                                            </Typography>
                                                                        </Box>

                                                                        {/* 작성자 */}
                                                                        {/* <Box
                                                                            sx={{
                                                                                display: 'flex',
                                                                                justifyContent: 'start',
                                                                                alignItems: 'center',
                                                                                ml: 1
                                                                            }}
                                                                        >
                                                                            <PersonOutline
                                                                                sx={{
                                                                                    mr: 0.5,
                                                                                    fontSize: '1.0rem !important',
                                                                                }}
                                                                            />
                                                                            <Typography>
                                                                                {boardItem.BoardItemWriter}
                                                                            </Typography>
                                                                        </Box> */}

                                                                        {/* 작성일 */}
                                                                        {/* <Box
                                                                            sx={{
                                                                                display: 'flex',
                                                                                justifyContent: 'start',
                                                                                alignItems: 'center',
                                                                                ml: 1
                                                                            }}
                                                                        >
                                                                            <Typography>
                                                                                {ObjectUtils.DateToString(boardItem.BoardItemWriteDate, 'yyyy-MM-dd')}
                                                                            </Typography>
                                                                        </Box> */}
                                                                    </Box>
                                                                </Box>
                                                            </Paper>
                                                    }
                                                </React.Fragment>
                                            ))
                                        }
                                        {
                                            [...(faqData.itemList ?? [])].length !== 0 ?
                                                <Box
                                                    sx={{
                                                        width: '100%',
                                                        pt: 3,
                                                        pb: 5
                                                    }}
                                                >
                                                    <ICTPagination
                                                        pageObject={{
                                                            nowPage: pageObj?.nowPage,
                                                            totalPage: pageObj?.totalPage,
                                                            onChangePage: (changePage) => {
                                                                setPageObj({
                                                                    nowPage: changePage,
                                                                    totalPage: pageObj?.totalPage!,
                                                                    isMovePage: true
                                                                })
                                                            }
                                                        }}
                                                    />
                                                </Box>
                                                :
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        pt: 10,
                                                        width: '100%'
                                                    }}
                                                >
                                                    <Typography>
                                                        검색된 게시글이 없습니다.
                                                    </Typography>
                                                </Box>
                                        }
                                    </Box>

                                </InfoLayoutPage>
                        }
                    </React.Fragment>
            }


        </LoginChecker>
    )
}

export default FaqBoardPage;