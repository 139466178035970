import { IKeriAPIState } from "@ict-base/module/service/ICTRequestAPI";
import CallAPI from "@ict-base/module/utils/CallAPI";
import { ObjectUtils } from "@ict-base/module/utils/ObjectUtils";
import { IKeriTechServiceSimulation } from "@ictpeople/misc-core";
import { PlatformError } from "@keri-base/interface/common/PlatformError";
import KeriAPIStructure from "@keri-portal/config/KeriAPIStructure";
import { IUploadFileVO } from "@keri-portal/interfaces/api/keri-api/object/IUploadFileVO";
import { APITechServiceType, IAPIAddTechServiceCommentRequestBody, IAPIAddTechServiceRequestBody, IAPIAddTechServiceSimulationRequestBody, IAPIDeleteTechServiceSimulationRequestBody, IAPIGetTechServiceCommentRequestBody, IAPIGetTechServiceCompleteDocsRequestBody, IAPIGetTechServiceInfoRequestBody, IAPIGetTechServiceListRequestBody, IAPIGetTechServiceTemplateFilesRequestBody, IAPIGetTechServiceUnreadCommentListRequestBody, IAPIRunTechServiceSimulationRequestBody, IAPISetTechServiceCompleteRequestBody, IAPIUpdateTechServiceRequestBody, IGetTechServiceSimulationRequestBody } from "@keri-portal/interfaces/api/keri-api/request/IKeriAPITechServiceRequest";
import { IAPIAddTechServiceResult, IAPIAddTechServiceSimulationResult, IAPIGetTechServiceCommentResult, IAPIGetTechServiceInfoResult, IAPIGetTechServiceResult, IAPIGetTechServiceSimulationResult, IAPIGetTechServiceTemplateFilesResult, IAPIRunTechServiceSimulationResult, IAPISetTechServiceCompleteResult, IAPIUpdateTechServiceResult, IKeriAPIGetTechServiceCompleteDocsResult, IKeriAPIGetTechServiceUnreadCommentListResult } from "@keri-portal/interfaces/api/keri-api/response/IKeriAPITechServiceResponse";
import { IUploadFile } from "@keri-portal/interfaces/control/IUploadFile";
import { ITechServiceCompleteConfirm, ITechServiceInfoData, ITechServiceObjectData, ITechServiceSimulationData, TechServiceStatus, TechServiceType } from "@keri-portal/interfaces/data-object/tech-service/ITechService";
import { ITechServiceComment } from "@keri-portal/interfaces/data-object/tech-service/ITechServiceComment";
import { ITechServiceRequest } from "@keri-portal/interfaces/process-object/tech-service/ITechServiceRequest";
import { AxiosError } from "axios";
import InterfaceExtensionMethod from "../../extension-method/InterfaceExtensionMethod";
import KeriAPIException from "../api-utils/KeriAPIException";
import KeriAPIExceptionFilter from "../api-utils/KeriAPIExceptionFilter";
import RequestKeriAPI, { IKeriAuthAPIState } from "../RequestKeriAPI";




/**
 * 기술 지원 관련 API Call Object
 * 
 * @author shlee
 * @since 2022.10.09
 * 
 */
const KeriTechServiceAPI = {
    AddTechService: async (token?: string, type?: TechServiceType, data?: ITechServiceRequest): Promise<IKeriAuthAPIState> => AddTechService(token, type, data),
    GetTechServiceList: async (token?: string, searchText?: string, page?: number, state?: TechServiceStatus[], type?: TechServiceType[]): Promise<IGetTechServiceList> => GetTechServiceList(token, searchText, state, type, page),
    GetTechServiceInfo: async (token?: string, techServiceID?: string): Promise<IGetTechServiceInfo> => GetTechServiceInfo(token, techServiceID),
    EditTechService: async (token?: string, techServiceID?: string, data?: ITechServiceInfoData, addFiles?: IUploadFile<IUploadFileVO>[], deleteFiles?: IUploadFile<IUploadFileVO>[], status?: TechServiceStatus, atchFileId?: string): Promise<IKeriAuthAPIState> => EditTechService(token, techServiceID, data, addFiles, deleteFiles, status, atchFileId),
    UpdateTechServiceStatus: async (token?: string, techServiceID?: string, status?: TechServiceStatus): Promise<IKeriAuthAPIState> => UpdateTechServiceStatus(token, techServiceID, status),
    Simulation: {
        GetSimulationAttributeList: async (token?: string, techServiceID?: string, simulationOrder?: number): Promise<IGetSimulationAttributeList> => GetSimulationAttributeList(token, techServiceID, simulationOrder),
        GetSimulationList: async (token?: string, techServiceID?: string): Promise<IGetSimulationList> => GetSimulationList(token, techServiceID),
        AddSimulation: async (token?: string, techServiceID?: string): Promise<IKeriAuthAPIState> => AddSimulation(token, techServiceID),
        DeleteSimulation: async (token?: string, techServiceID?: string, simulationOrder?: number): Promise<IKeriAuthAPIState> => DeleteSimulation(token, techServiceID, simulationOrder),
        UploadSimulationFile: async (token?: string, techServiceID?: string, simulationOrder?: number, data?: IUploadFile<IUploadFileVO>): Promise<IKeriAuthAPIState> => UploadSimulationFile(token, techServiceID, simulationOrder, data),
        RunSimulation: async (token?: string, techServiceID?: string, simulationOrder?: number): Promise<IKeriAuthAPIState> => RunSimulation(token, techServiceID, simulationOrder),
    },
    Comment: {
        GetTechServiceCommentList: async (token?: string, techServiceID?: string): Promise<IGetTechServiceCommentList> => GetTechServiceCommentList(token, techServiceID),
        AddTechServiceComment: async (token?: string, techServiceID?: string, commentContent?: string): Promise<IKeriAuthAPIState> => AddTechServiceComment(token, techServiceID, commentContent),
        GetUnreadCommentList: async (token?: string): Promise<IGetUnreadCommentList> => GetUnreadCommentList(token),
    },
    SetCompleteTechService: async (token?: string, techServiceID?: string, completeDocsFileName?: string, confirmData?: ITechServiceCompleteConfirm): Promise<IKeriAuthAPIState> => SetCompleteTechService(token, techServiceID, completeDocsFileName, confirmData),
    GetTechServiceTemplateFiles: async (atchFileID: string): Promise<IGetTechServiceTemplateFiles> => GetTechServiceTemplateFiles(atchFileID),
    /**@deprecated */
    GetTechServiceCompleteDocs: async (techServiceID: string, token?: string): Promise<IGetTechServiceCompleteDocs> => GetTechServiceCompleteDocs(techServiceID, token),
    DownloadTechServiceCompleteDocs: async (techServiceID: string, token?: string): Promise<IDownloadTechServiceCompleteDocs> => DownloadTechServiceCompleteDocs(techServiceID, token),
}


/** 기술지원 신청 */
const AddTechService = async (token?: string, type?: TechServiceType, data?: ITechServiceRequest) => {
    let apiState: IKeriAuthAPIState = {
        state: true,
        message: undefined,
        tokenValid: true
    }
    
    console.error('INPUT DATA' , data);

    let filterReqBody: IAPIAddTechServiceRequestBody = {}
    let reqBody: IAPIAddTechServiceRequestBody =
    {
        tmpUid: data?.RequestTempUniqueKey,
        arType: InterfaceExtensionMethod.Utils.Request.CastTechServiceTypeToAPI(type),
        arPurpose: data?.RequesterData?.RequestInputData?.RequestPurpose?.map(x => x.purposeCode).join(','),
        arProdId: data?.RequesterData?.RequestInputData?.RequestProduct?.productID,
        arReqContents: data?.RequesterData?.RequestInputData?.RequestInfo,
        arNeededDuePnttm: ObjectUtils.DateToString(data?.RequesterData?.RequestInputData?.RequestDesiredDate, 'yyyy-MM-dd'),
        testMethod: data?.RequesterData?.RequestInputData?.RequestTestMethod?.TestName,
        testType: data?.RequesterData?.RequestInputData?.RequestTestType?.TestName,
        testItem: data?.RequesterData?.RequestInputData?.RequestTestItem?.map(x => x.TestName).join(','),
        // profesnalId: data?.RequesterData?.RequestInputData?.RequestExpertUser?.UniqueID,
        reqDept: data?.RequesterData?.RequesterData?.RequesterDepartment,
        reqEmail: data?.RequesterData?.RequesterData?.RequesterEmail,
        reqTelNo: data?.RequesterData?.RequesterData?.RequesterCallNumber,
        reqNm: data?.RequesterData?.RequesterData?.RequesterName,
        streFileNms: [
            ...((data?.RequesterData?.RequestInputFile ?? []).filter(x => x.UploadedFile?.streFileNm !== undefined).map(x => x.UploadedFile?.streFileNm!)),
            ...((data?.RequestFiles?.data ?? []).filter(x => x.UploadedFile?.streFileNm !== undefined).map(x => x.UploadedFile?.streFileNm!))
        ],
        // bfSales: data?.RequestBenefitData?.AfterSupport?.Sales?.toString(),
        afSales: data?.RequestBenefitData?.BeforeSupport?.Sales?.toString(),
        // bfReduceDevCost: data?.RequestBenefitData?.AfterSupport?.ReduceDevelopmentCost?.toString(),
        afReduceDevCost: data?.RequestBenefitData?.BeforeSupport?.ReduceDevelopmentCost?.toString(),
        afReduceDevPeriod: data?.RequestBenefitData?.BeforeSupport?.ReduceDevelopmentPeriod?.toString(),
        // bfReduceDevPeriod: data?.RequestBenefitData?.BeforeSupport?.ReduceDevelopmentPeriod?.toString(),
        // bfEmpCnt: data?.RequestBenefitData?.AfterSupport?.Employ?.toString(),
        afEmpCnt: data?.RequestBenefitData?.BeforeSupport?.Employ?.toString(),
        // bfMou: data?.RequestBenefitData?.AfterSupport?.MoU?.toString(),
        afMou: data?.RequestBenefitData?.BeforeSupport?.MoU?.toString(),
        // bfInvestAttract: data?.RequestBenefitData?.AfterSupport?.InvestmentAttraction?.toString(),
        afInvestAttract: data?.RequestBenefitData?.BeforeSupport?.InvestmentAttraction?.toString(),
        // bfProductRate: data?.RequestBenefitData?.AfterSupport?.productionRateImprovement?.toString(),
        afProductRate: data?.RequestBenefitData?.BeforeSupport?.productionRateImprovement?.toString(),
        // bfDefectRate: data?.RequestBenefitData?.AfterSupport?.productionDefectRateImprovement?.toString(),
        afDefectRate: data?.RequestBenefitData?.BeforeSupport?.productionDefectRateImprovement?.toString(),
        // bfFrstVendor: data?.RequestBenefitData?.AfterSupport?.Vender?.toString(),
        afFrstVendor: data?.RequestBenefitData?.BeforeSupport?.Vender?.toString(),

    }

    if (type === 'A') {
        filterReqBody = {
            tmpUid: reqBody.tmpUid,
            arType: reqBody.arType,
            arPurpose: reqBody.arPurpose,
            arProdId: reqBody.arProdId,
            arReqContents: reqBody.arReqContents,
            arNeededDuePnttm: reqBody.arNeededDuePnttm,
            profesnalId: reqBody.profesnalId,
            reqDept: reqBody.reqDept,
            reqEmail: reqBody.reqEmail,
            reqNm: reqBody.reqNm,
            reqTelNo: reqBody.reqTelNo,
            streFileNms: reqBody.streFileNms,
        }
    }
    else if (type === 'T') {
        filterReqBody = {
            tmpUid: reqBody.tmpUid,
            arType: reqBody.arType,
            arPurpose: reqBody.arPurpose,
            arProdId: reqBody.arProdId,
            arReqContents: reqBody.arReqContents,
            arNeededDuePnttm: reqBody.arNeededDuePnttm,
            streFileNms: reqBody.streFileNms,
            testItem: reqBody.testItem,
            testMethod: reqBody.testMethod,
            testType: reqBody.testType
        }
    }
    else if (type === 'S') {
        filterReqBody = {
            tmpUid: reqBody.tmpUid,
            arType: reqBody.arType,
            arPurpose: reqBody.arPurpose,
            arProdId: reqBody.arProdId,
            arReqContents: reqBody.arReqContents,
            streFileNms: reqBody.streFileNms,
        }
    }
    else if (type === 'C' || type === 'P' || type === 'R') {
        filterReqBody = {
            tmpUid: reqBody.tmpUid,
            arType: reqBody.arType,
            arPurpose: reqBody.arPurpose,
            arProdId: reqBody.arProdId,
            arReqContents: reqBody.arReqContents,
            arNeededDuePnttm: reqBody.arNeededDuePnttm,
            streFileNms: reqBody.streFileNms,
        }
    }

    await CallAPI<IAPIAddTechServiceResult>({
        url: KeriAPIStructure.AnalysisRequest.AddTechService,
        method: "PUT",
        token: token,
        requestBody: reqBody
    })
        .then(res => {
            if (res.data.resultCode !== 'S') {
                apiState.state = false;
                apiState.message = res.data.resultMessage;
            }
        })
        .catch((err) => {
            if (err.response?.status === 401) {
                apiState.tokenValid = false;
                apiState.message = '로그인이 만료되었습니다. \n다시 로그인해주시길 바랍니다.'
            }
            else {
                apiState.message = '서버와 연결이 원활하지 않습니다.'
            }
            apiState.state = false;

        })


    return apiState;
}


interface IGetTechServiceList extends IKeriAuthAPIState {
    resultData: ITechServiceObjectData[],
    statusList: { code: TechServiceStatus, name: string }[],
    typeList: { code: TechServiceType, name: string }[]
}

/** 기술지원 조회 */
const GetTechServiceList = async (token?: string, searchText?: string, state?: TechServiceStatus[], type?: TechServiceType[], page?: number): Promise<IGetTechServiceList> => {
    let apiState: IGetTechServiceList = {
        state: true,
        message: undefined,
        tokenValid: true,
        resultData: [],
        statusList: [],
        typeList: []
    }

    let reqBody: IAPIGetTechServiceListRequestBody = {
        pageIndex: page,
        pageUnit: 10000,
        arSttus: (state ?? []).length === 0 ? undefined : state,
        arType: (type ?? []).length === 0 ? undefined : type!.filter(x => (x ?? undefined) !== undefined).map(x => InterfaceExtensionMethod.Utils.Request.CastTechServiceTypeToAPI(x)!),
        searchKeyword: searchText
    }

    await CallAPI<IAPIGetTechServiceResult>({
        url: KeriAPIStructure.AnalysisRequest.GetTechService,
        method: "POST",
        token: token,
        requestBody: reqBody
    })
        .then(async res => {
            if (res.data.resultCode === 'S') {
                const promiseData = res.data.arRequestList.filter(x => x !== undefined).map(async x => await InterfaceExtensionMethod.API.Response.CastAPIToTechServiceListData(x, token)!)

                for (let i = 0; i < promiseData.length; i++) {
                    const item = await promiseData[i];

                    if (item !== undefined) {
                        apiState.resultData.push(item);
                    }
                }


                apiState.statusList = res.data.arStateList.filter(x => (x.code ?? undefined) !== undefined && (x.codeNm ?? undefined) !== undefined).map(x => ({ code: x.code! as TechServiceStatus, name: x.codeNm! }));
                apiState.typeList = res.data.arTypeList.filter(x => (x.code ?? undefined) !== undefined && (x.codeNm ?? undefined) !== undefined).map(x => ({ code: InterfaceExtensionMethod.Utils.Response.CastAPIToTechServiceType(x.code! as APITechServiceType)!, name: x.codeNm! }));
            }
            else {
                apiState.state = false;
                apiState.message = res.data.resultMessage;
            }
        })
        .catch((err) => {
            if (err.response?.status === 401) {
                apiState.tokenValid = false;
                apiState.message = '로그인이 만료되었습니다. \n다시 로그인해주시길 바랍니다.'
            }
            else {
                apiState.message = '서버와 연결이 원활하지 않습니다.'
            }
            apiState.state = false;

        })



    return apiState;
}

interface IGetTechServiceInfo extends IKeriAuthAPIState {
    resultData: ITechServiceInfoData
}

const GetTechServiceInfo = async (token?: string, techServiceID?: string): Promise<IGetTechServiceInfo> => {
    let apiState: IGetTechServiceInfo = {
        message: undefined,
        state: true,
        tokenValid: true,
        resultData: {}
    }

    let reqBody: IAPIGetTechServiceInfoRequestBody = {
        arId: techServiceID ?? ''
    }

    await CallAPI<IAPIGetTechServiceInfoResult>({
        url: KeriAPIStructure.AnalysisRequest.GetTechServiceInfo,
        method: "POST",
        token: token,
        requestBody: reqBody
    })
        .then(async res => {

            if (res.data.resultCode === 'S') {
                try {
                    apiState.resultData.TechServiceData = {
                        ...await InterfaceExtensionMethod.API.Response.CastAPIToTechServiceListData(res.data.arRequestInfo.properties, token, true) ?? {},
                        RequestType: InterfaceExtensionMethod.Utils.Response.CastAPIToTechServiceType(res.data.arRequestInfo.properties.arType === undefined ? undefined : res.data.arRequestInfo.properties.arType as APITechServiceType)!,
                        RequestInputFile: (res.data.arRequestInfo.attachFiles ?? []).filter(x => x !== undefined).map((x, y) => ({
                            Index: x.fileSn === undefined ? 0 : isNaN(parseInt(x.fileSn)) ? 0 : parseInt(x.fileSn),
                            Category: x.fileCategory ?? '',
                            Label: x.fileCn ?? '',
                            Prefix: x.streFileNm?.replace(x.streFileNm.split('_')[x.streFileNm.split('_').length - 1], '') ?? '',
                            Required: (x.isRequired ?? 'N') === 'Y' ? true : false,
                            UploadedFile: x
                        })),
                        RequestSimulationOrder: res.data.arSimulationList.length === 0 ? 0 : res.data.arSimulationList.filter(x => x !== undefined).map(x => InterfaceExtensionMethod.API.Response.CastAPIToTechServiceSimulationData(x)!).sort((x, y) => (x.order ?? 0) - (y.order ?? 0))[0].order,
                        RequestEffect: {
                            AfterSupport: {
                                Sales: res.data.comEffectData.bfSales ?? undefined,
                                Employ: res.data.comEffectData.bfEmpCnt ?? undefined,
                                InvestmentAttraction: res.data.comEffectData.bfInvestAttract ?? undefined,
                                MoU: res.data.comEffectData.bfMou ?? undefined,
                                productionDefectRateImprovement: res.data.comEffectData.bfDefectRate ?? undefined,
                                productionRateImprovement: res.data.comEffectData.bfProductRate ?? undefined,
                                ReduceDevelopmentCost: res.data.comEffectData.bfReduceDevCost ?? undefined,
                                ReduceDevelopmentPeriod: res.data.comEffectData.bfReduceDevPeriod ?? undefined,
                                Vender: res.data.comEffectData.bfFrstVendor ?? undefined
                            },
                            BeforeSupport: {
                                Sales: res.data.comEffectData.afSales ?? undefined,
                                Employ: res.data.comEffectData.afEmpCnt ?? undefined,
                                InvestmentAttraction: res.data.comEffectData.afInvestAttract ?? undefined,
                                MoU: res.data.comEffectData.afMou ?? undefined,
                                productionDefectRateImprovement: res.data.comEffectData.afDefectRate ?? undefined,
                                productionRateImprovement: res.data.comEffectData.afProductRate ?? undefined,
                                ReduceDevelopmentCost: res.data.comEffectData.afReduceDevCost ?? undefined,
                                ReduceDevelopmentPeriod: res.data.comEffectData.afReduceDevPeriod ?? undefined,
                                Vender: res.data.comEffectData.afFrstVendor ?? undefined
                            }
                        }
                    }
                    apiState.resultData.TechServiceSimulation = res.data.arSimulationList.filter(x => x !== undefined).map(x => InterfaceExtensionMethod.API.Response.CastAPIToTechServiceSimulationData(x)!);

                    apiState.resultData.TechServiceWriter = {
                        Company: {
                            CompanyNumber: res.data.userInfo.comBusNo ?? undefined,
                            CompanyName: res.data.userInfo.comNm ?? undefined
                        },
                        User: {
                            UserCallNumber: res.data.userInfo.middleTelNo ?? undefined,
                            UserCellPhoneNumber: res.data.userInfo.moblPhonNO ?? undefined,
                            UserDepartment: res.data.userInfo.mberDept ?? undefined,
                            UserEmail: res.data.userInfo.mberEmailAdres ?? undefined,
                            UserName: res.data.userInfo.mberNm ?? undefined,
                            UserRank: res.data.userInfo.mberRank ?? undefined
                        }
                    }

                    if (apiState.resultData.TechServiceData.RequestInputFile?.filter(x => x.Category === 'TechServiceConfirmCompleteDocs').length === 0) {
                        apiState.resultData.TechServiceData.RequestInputFile.push({
                            Index: (apiState.resultData.TechServiceData.RequestInputFile ?? []).length === 0 ? 1 : apiState.resultData.TechServiceData.RequestInputFile.sort((x, y) => y.Index - x.Index)[0].Index + 1,
                            Category: 'TechServiceConfirmCompleteDocs',
                            Prefix: 'TEC_CONFIRM_',
                            Label: '완료확인서',
                            Required: true
                        })
                    }

                    if (res.data.arResultInfo.properties !== undefined) {
                        apiState.resultData.TechCompleteConfirm = {
                            SurveyID: res.data.arResultInfo.properties?.arSurveyId ?? undefined,
                            ServiceRank: (res.data.arResultInfo.properties?.arSatisfaction ?? undefined) === undefined ? undefined : parseInt(res.data.arResultInfo.properties?.arSatisfaction!),
                            ServiceReview: res.data.arResultInfo.properties?.arRstOverview ?? undefined,
                            AtchFileID: res.data.arResultInfo.properties?.atchFileId ?? undefined,
                            CompleteDocs: (res.data.arResultInfo.attachFiles ?? []).map(x => ({
                                Index: parseInt(x.fileSn!),
                                Category: x.fileCategory ?? '',
                                Label: x.fileCn ?? '',
                                Prefix: 'TEC_CONFIRM_',
                                UploadedFile: x
                            }))
                        }
                    }

                }
                catch (e) {
                    console.error(e);
                    throw { ScriptMessage: `Script Error - ${(e as Error).message}` }
                }


            }
            else {
                apiState.state = false;
                apiState.message = res.data.resultMessage;
            }
        })
        .catch((err) => {
            console.error(err);
            if (err.response?.status === 401) {
                apiState.tokenValid = false;
                apiState.message = '로그인이 만료되었습니다. \n다시 로그인해주시길 바랍니다.'
            }
            else if (err.response?.status === 403) {
                apiState.message = '접근 권한이 없습니다.'
            }
            else if (err.ScriptMessage !== undefined) {
                // apiState.message = err.ScriptMessage;
                apiState.message = '시스템 오류가 발생했습니다.'
            }
            else {
                apiState.message = '서버와 연결이 원활하지 않습니다.'
            }
            apiState.state = false;

        })





    return apiState;
}

const EditTechService = async (token?: string, techServiceID?: string, data?: ITechServiceInfoData, addFiles?: IUploadFile<IUploadFileVO>[], deleteFiles?: IUploadFile<IUploadFileVO>[], status?: TechServiceStatus, atchFileId?: string): Promise<IKeriAuthAPIState> => {
    let apiState: IKeriAuthAPIState = {
        state: true,
        message: undefined,
        tokenValid: true
    }

    console.log('addFiles', addFiles)
    // console.log('expuser',data?.TechServiceData?.RequestInputData?.RequestExpertUser);

    let reqBody: IAPIUpdateTechServiceRequestBody = {
        arId: techServiceID,
        arPurpose: data?.TechServiceData?.RequestInputData?.RequestPurpose?.map(x => x.purposeCode).join(','),
        arProdId: data?.TechServiceData?.RequestInputData?.RequestProduct?.productID,
        arReqContents: data?.TechServiceData?.RequestInputData?.RequestInfo,
        arNeededDuePnttm: ObjectUtils.DateToString(data?.TechServiceData?.RequestInputData?.RequestDesiredDate, 'yyyy-MM-dd'),
        testMethod: data?.TechServiceData?.RequestInputData?.RequestTestMethod?.TestName,
        testType: data?.TechServiceData?.RequestInputData?.RequestTestType?.TestName,
        testItem: data?.TechServiceData?.RequestInputData?.RequestTestItem?.map(x => x.TestName).join(','),
        // profesnalId: data?.TechServiceData?.RequestInputData?.RequestExpertUser?.UniqueID,
        reqDept: data?.TechServiceData?.RequesterData?.RequesterDepartment,
        reqEmail: data?.TechServiceData?.RequesterData?.RequesterEmail,
        reqTelNo: data?.TechServiceData?.RequesterData?.RequesterCallNumber,
        reqNm: data?.TechServiceData?.RequesterData?.RequesterName,
        streFileNms: addFiles?.filter(x => x.UploadedFile?.streFileNm !== undefined).map(x => x.UploadedFile?.streFileNm!).length === 0 ? undefined : addFiles?.filter(x => x.UploadedFile?.streFileNm !== undefined).map(x => x.UploadedFile?.streFileNm!),
        deleteFileNms: deleteFiles?.filter(x => x.UploadedFile?.streFileNm !== undefined).map(x => x.UploadedFile?.streFileNm!).length === 0 ? undefined : deleteFiles?.filter(x => x.UploadedFile?.streFileNm !== undefined).map(x => x.UploadedFile?.streFileNm!),
        atchFileId: atchFileId,
        arSttus: status
    }

    await CallAPI<IAPIUpdateTechServiceResult>({
        url: KeriAPIStructure.AnalysisRequest.UpdateTechService,
        method: "PUT",
        token: token,
        requestBody: reqBody
    })
        .then(res => {
            if (res.data.resultCode !== 'S') {
                apiState.state = false;
                apiState.message = res.data.resultMessage;
            }
        })
        .catch((err) => {
            if (err.response?.status === 401) {
                apiState.tokenValid = false;
                apiState.message = '로그인이 만료되었습니다. \n다시 로그인해주시길 바랍니다.'
            }
            else {
                apiState.message = '서버와 연결이 원활하지 않습니다.'
            }
            apiState.state = false;

        })



    return apiState;
}


const UpdateTechServiceStatus = async (token?: string, techServiceID?: string, status?: TechServiceStatus): Promise<IKeriAuthAPIState> => {
    let apiState: IKeriAuthAPIState = {
        state: true,
        message: undefined,
        tokenValid: true
    }


    let reqBody: IAPIUpdateTechServiceRequestBody = {
        arId: techServiceID,
        arSttus: status
    }

    await CallAPI<IAPIUpdateTechServiceResult>({
        url: KeriAPIStructure.AnalysisRequest.UpdateTechService,
        method: "PUT",
        token: token,
        requestBody: reqBody
    })
        .then(res => {
            if (res.data.resultCode !== 'S') {
                apiState.state = false;
                apiState.message = res.data.resultMessage;
            }
        })
        .catch((err) => {
            if (err.response?.status === 401) {
                apiState.tokenValid = false;
                apiState.message = '로그인이 만료되었습니다. \n다시 로그인해주시길 바랍니다.'
            }
            else {
                apiState.message = '서버와 연결이 원활하지 않습니다.'
            }
            apiState.state = false;

        })



    return apiState;
}




interface IGetSimulationAttributeList extends IKeriAuthAPIState {
    resultData: ITechServiceSimulationData[]
}


const GetSimulationAttributeList = async (token?: string, techServiceID?: string, simulationOrder?: number): Promise<IGetSimulationAttributeList> => {
    let apiState: IGetSimulationAttributeList = {
        state: false,
        message: undefined,
        tokenValid: true,
        resultData: []
    }

    return apiState;
}



interface IGetTechServiceCommentList extends IKeriAuthAPIState {
    resultData: ITechServiceComment[]
}

const GetTechServiceCommentList = async (token?: string, techServiceID?: string): Promise<IGetTechServiceCommentList> => {
    let apiState: IGetTechServiceCommentList = {
        state: false,
        message: undefined,
        tokenValid: true,
        resultData: []
    }


    let reqBody: IAPIGetTechServiceCommentRequestBody = {
        objectId: techServiceID,
    }

    await CallAPI<IAPIGetTechServiceCommentResult>({
        url: KeriAPIStructure.Comments.GetTechServiceComment,
        method: "POST",
        token: token,
        requestBody: reqBody
    })
        .then(res => {
            if (res.data.resultCode === 'S') {
                apiState.state = true;
                apiState.message = res.data.resultMessage;
                apiState.resultData = res.data.arCommentLIst.filter(x => x !== undefined).map(x => InterfaceExtensionMethod.API.Response.CastAPIToTechServiceCommentData(x)!)
            }
            else {
                apiState.state = false;
                apiState.message = res.data.resultMessage;
            }
        })
        .catch((err) => {
            if (err.response?.status === 401) {
                apiState.tokenValid = false;
                apiState.message = '로그인이 만료되었습니다. \n다시 로그인해주시길 바랍니다.'
            }
            else {
                apiState.message = '서버와 연결이 원활하지 않습니다.'
            }
            apiState.state = false;

        })


    return apiState;
}


const AddTechServiceComment = async (token?: string, techServiceID?: string, commentContent?: string): Promise<IKeriAuthAPIState> => {
    let apiState: IKeriAuthAPIState = {
        state: true,
        message: undefined,
        tokenValid: true
    }


    let reqBody: IAPIAddTechServiceCommentRequestBody = {
        objectId: techServiceID,
        cmtContents: commentContent
    }

    await CallAPI<IAPIUpdateTechServiceResult>({
        url: KeriAPIStructure.Comments.AddTechServiceComment,
        method: "PUT",
        token: token,
        requestBody: reqBody
    })
        .then(res => {
            if (res.data.resultCode !== 'S') {
                apiState.state = false;
                apiState.message = res.data.resultMessage;
            }
        })
        .catch((err) => {
            if (err.response?.status === 401) {
                apiState.tokenValid = false;
                apiState.message = '로그인이 만료되었습니다. \n다시 로그인해주시길 바랍니다.'
            }
            else {
                apiState.message = '서버와 연결이 원활하지 않습니다.'
            }
            apiState.state = false;

        })



    return apiState;
}



interface IGetSimulationList extends IKeriAuthAPIState {
    resultData: IKeriTechServiceSimulation[]
}

const GetSimulationList = async (token?: string, techServiceID?: string): Promise<IGetSimulationList> => {
    let apiState: IGetSimulationList = {
        state: true,
        message: undefined,
        tokenValid: true,
        resultData: [],
    }


    let reqBody: IGetTechServiceSimulationRequestBody = {
        arId: techServiceID,
    }

    await CallAPI<IAPIGetTechServiceSimulationResult>({
        url: KeriAPIStructure.AnalysisRequest.GetSimulationList,
        method: "POST",
        token: token,
        requestBody: reqBody
    })
        .then(res => {
            if (res.data.resultCode === 'S') {

                apiState.state = true;
                apiState.message = res.data.resultMessage;

                apiState.resultData = res.data.arSimulationList.map(x => InterfaceExtensionMethod.API.Response.CastAPIToTechServiceSimulationData(x)!);
            }
            else {
                apiState.state = false;
                apiState.message = res.data.resultMessage;
            }
        })
        .catch((err) => {
            if (err.response?.status === 401) {
                apiState.tokenValid = false;
                apiState.message = '로그인이 만료되었습니다. \n다시 로그인해주시길 바랍니다.'
            }
            else {
                apiState.message = '서버와 연결이 원활하지 않습니다.'
            }
            apiState.state = false;

        })



    return apiState;
}


const AddSimulation = async (token?: string, techServiceID?: string): Promise<IKeriAuthAPIState> => {
    let apiState: IKeriAuthAPIState = {
        state: true,
        message: undefined,
        tokenValid: true
    }


    let reqBody: IAPIAddTechServiceSimulationRequestBody = {
        arId: techServiceID
    }

    await CallAPI<IAPIAddTechServiceSimulationResult>({
        url: KeriAPIStructure.AnalysisRequest.AddSimulation,
        method: "PUT",
        token: token,
        requestBody: reqBody
    })
        .then(res => {
            if (res.data.resultCode !== 'S') {
                apiState.state = false;
                apiState.message = res.data.resultMessage;
            }
        })
        .catch((err) => {
            if (err.response?.status === 401) {
                apiState.tokenValid = false;
                apiState.message = '로그인이 만료되었습니다. \n다시 로그인해주시길 바랍니다.'
            }
            else {
                apiState.message = '서버와 연결이 원활하지 않습니다.'
            }
            apiState.state = false;

        })



    return apiState;
}

const DeleteSimulation = async (token?: string, techServiceID?: string, simulationOrder?: number): Promise<IKeriAuthAPIState> => {
    let apiState: IKeriAuthAPIState = {
        state: true,
        message: undefined,
        tokenValid: true
    }


    let reqBody: IAPIDeleteTechServiceSimulationRequestBody = {
        arId: techServiceID,
        smOrd: simulationOrder?.toString()
    }

    await CallAPI<IAPIAddTechServiceSimulationResult>({
        url: KeriAPIStructure.AnalysisRequest.DeleteSimulation,
        method: "DELETE",
        token: token,
        requestBody: { data: reqBody }
    })
        .then(res => {
            if (res.data.resultCode !== 'S') {
                apiState.state = false;
                apiState.message = res.data.resultMessage;
            }
        })
        .catch((err) => {
            if (err.response?.status === 401) {
                apiState.tokenValid = false;
                apiState.message = '로그인이 만료되었습니다. \n다시 로그인해주시길 바랍니다.'
            }
            else {
                apiState.message = '서버와 연결이 원활하지 않습니다.'
            }
            apiState.state = false;

        })



    return apiState;

}



const UploadSimulationFile = async (token?: string, techServiceID?: string, simulationOrder?: number, data?: IUploadFile<IUploadFileVO>): Promise<IKeriAuthAPIState> => {
    let apiState: IKeriAuthAPIState = {
        state: true,
        message: undefined,
        tokenValid: true
    }


    var bodyFormData = new FormData();
    bodyFormData.append('file', data?.OriginFile!);
    bodyFormData.append('fileCn', data?.Label ?? '');
    bodyFormData.append('smOrd', (simulationOrder ?? 0).toString());
    bodyFormData.append('arId', techServiceID ?? '');
    bodyFormData.append('filePrefix', data?.Prefix ?? '');
    bodyFormData.append('fileCategory', data?.Category ?? '')

    await CallAPI<IAPIAddTechServiceSimulationResult>({
        url: KeriAPIStructure.Common.SimulationFileUpload,
        method: "POST",
        token: token,
        requestBody: bodyFormData
    })
        .then(res => {
            if (res.data.resultCode !== 'S') {
                apiState.state = false;
                apiState.message = res.data.resultMessage;
            }
        })
        .catch((err) => {
            if (err.response?.status === 401) {
                apiState.tokenValid = false;
                apiState.message = '로그인이 만료되었습니다. \n다시 로그인해주시길 바랍니다.'
            }
            else {
                apiState.message = '서버와 연결이 원활하지 않습니다.'
            }
            apiState.state = false;

        })



    return apiState;
}



const RunSimulation = async (token?: string, techServiceID?: string, simulationOrder?: number): Promise<IKeriAuthAPIState> => {
    let apiState: IKeriAuthAPIState = {
        state: true,
        message: undefined,
        tokenValid: true
    }


    let reqBody: IAPIRunTechServiceSimulationRequestBody = {
        arId: techServiceID,
        smOrd: simulationOrder?.toString()
    }

    await CallAPI<IAPIRunTechServiceSimulationResult>({
        url: KeriAPIStructure.AnalysisRequest.RunSimulation,
        method: "PUT",
        token: token,
        requestBody: reqBody
    })
        .then(res => {
            if (res.data.resultCode !== 'S') {
                apiState.state = false;
                apiState.message = res.data.resultMessage;
            }
        })
        .catch((err) => {
            if (err.response?.status === 401) {
                apiState.tokenValid = false;
                apiState.message = '로그인이 만료되었습니다. \n다시 로그인해주시길 바랍니다.'
            }
            else {
                apiState.message = '서버와 연결이 원활하지 않습니다.'
            }
            apiState.state = false;

        })



    return apiState;
}


const SetCompleteTechService = async (token?: string, techServiceID?: string, completeDocsFileName?: string, confirmData?: ITechServiceCompleteConfirm): Promise<IKeriAuthAPIState> => {
    let apiState: IKeriAuthAPIState = {
        state: true,
        message: undefined,
        tokenValid: true
    }


    let reqBody: IAPISetTechServiceCompleteRequestBody = {
        arId: techServiceID,
        arRstOverview: confirmData?.ServiceReview,
        arSatisfaction: confirmData?.ServiceRank?.toString(),
        qestnrId: confirmData?.SurveyID,
        qestnrTmplatId: confirmData?.SurveyTempleteID,
        streFileNms: completeDocsFileName !== undefined ? [completeDocsFileName] : undefined,
        surveyData: confirmData?.ApplySurvey?.map(x => ({
            qestnrAnswer: x.value,
            qestnrQesitmId: x.key,
            qestnTyCode: x.type === 'Rank' ? '3' : x.type === 'Text' ? '2' : '1'
        }))
    }

    await CallAPI<IAPISetTechServiceCompleteResult>({
        url: KeriAPIStructure.AnalysisRequest.CompleteTechService,
        method: "PUT",
        token: token,
        requestBody: reqBody
    })
        .then(res => {
            if (res.data.resultCode !== 'S') {
                apiState.state = false;
                apiState.message = res.data.resultMessage;
            }
        })
        .catch((err) => {
            if (err.response?.status === 401) {
                apiState.tokenValid = false;
                apiState.message = '로그인이 만료되었습니다. \n다시 로그인해주시길 바랍니다.'
            }
            else {
                apiState.message = '서버와 연결이 원활하지 않습니다.'
            }
            apiState.state = false;

        })



    return apiState;
}

interface IGetTechServiceTemplateFiles extends IKeriAuthAPIState {
    resultData: IUploadFileVO[]
}

const GetTechServiceTemplateFiles = async (atchFileID: string): Promise<IGetTechServiceTemplateFiles> => {
    let apiState: IGetTechServiceTemplateFiles = {
        state: true,
        message: undefined,
        tokenValid: true,
        resultData: []
    }


    let reqBody: IAPIGetTechServiceTemplateFilesRequestBody = {
        atchFileId: atchFileID,
        templateId: "TechServiceTempFile"
    }

    await CallAPI<IAPIGetTechServiceTemplateFilesResult>({
        url: KeriAPIStructure.Template.GetTemplateFiles,
        method: "POST",
        requestBody: reqBody
    })
        .then(res => {
            if (res.data.resultCode !== 'S') {
                apiState.state = false;
                apiState.message = res.data.resultMessage;
            }
            else if (res.data.resultCode === 'S') {
                apiState.state = true;
                apiState.resultData = res.data.resultData.fileInfo;
            }
        })
        .catch((err) => {
            if (err.response?.status === 401) {
                apiState.tokenValid = false;
                apiState.message = '로그인이 만료되었습니다. \n다시 로그인해주시길 바랍니다.'
            }
            else {
                apiState.message = '서버와 연결이 원활하지 않습니다.'
            }
            apiState.state = false;

        })



    return apiState;
}


interface IGetUnreadCommentList extends IKeriAuthAPIState {
    unReadCount: number,
    resultData: {
        techServiceID: string,
        techServiceTitle: string,
        commentList: {
            CommentID: string,
            CommentDate: Date,
            CommentContents: string
        }[]
    }[]
}

const GetUnreadCommentList = async (token?: string): Promise<IGetUnreadCommentList> => {
    let apiResult: IGetUnreadCommentList = {
        state: true,
        message: undefined,
        unReadCount: 0,
        resultData: [],
        tokenValid: true
    }

    try {
        let reqBody: IAPIGetTechServiceUnreadCommentListRequestBody = {};

        try {
            reqBody = {

            }
        }
        catch (e) {
            throw new KeriAPIException("요청을 보낼 수 없습니다.");
        }


        await CallAPI<IKeriAPIGetTechServiceUnreadCommentListResult>({
            url: KeriAPIStructure.Comments.GetTechServiceUnreadCommentList,
            method: "POST",
            requestBody: reqBody,
            token: token
        })
            .then(res => {
                try {
                    if (res.data.resultCode !== 'S') {
                        apiResult.state = false;
                        apiResult.message = res.data.resultMessage;
                    } else if (res.data.resultCode === 'S') {

                        apiResult.unReadCount = res.data.unReadCmtCount!;
                        apiResult.resultData = res.data.unReadCmtList.map(x => ({
                            techServiceID: x.arId!,
                            techServiceTitle: x.arTitle!,
                            commentList: x.commentList.map(cmmItem => ({
                                CommentID: cmmItem.cmtId!,
                                CommentDate: new Date(Date.parse(cmmItem.registPnttm!)),
                                CommentContents: cmmItem.cmtContents!
                            }))
                        }));

                    }
                }
                catch (e) {
                    throw new KeriAPIException("데이터를 읽어올 수 없습니다.");
                }
            })
            .catch((e) => {
                throw e;
            })

    }
    catch (e) {
        const exp = KeriAPIExceptionFilter(e);
        apiResult.message = exp.message;
        apiResult.state = false;
        apiResult.statusCode = exp.state;
        if (apiResult.statusCode === 401) {
            apiResult.tokenValid = false;
        }
    }




    return apiResult;
}





//#region 기술지원 완료확인서 생성

interface IGetTechServiceCompleteDocs extends IKeriAuthAPIState {
    CompleteDocsFileName?: string
}


/**@deprecated */
const GetTechServiceCompleteDocs = async (techServiceID: string, token?: string): Promise<IGetTechServiceCompleteDocs> => {
    let apiResult: IGetTechServiceCompleteDocs = {
        state: true,
        message: undefined,
        tokenValid: true,
        CompleteDocsFileName: undefined
    }

    try {
        let reqBody: IAPIGetTechServiceCompleteDocsRequestBody = {};

        try {
            reqBody = {
                arId: techServiceID!
            }
        }
        catch (e) {
            throw new KeriAPIException("요청을 보낼 수 없습니다.");
        }


        await CallAPI<IKeriAPIGetTechServiceCompleteDocsResult>({
            url: KeriAPIStructure.AnalysisRequest.GetCompletePDF,
            method: "POST",
            requestBody: reqBody,
            token: token
        })
            .then(res => {
                try {
                    if (res.data.resultCode !== 'S') {
                        apiResult.state = false;
                        apiResult.message = res.data.resultMessage;
                    } else if (res.data.resultCode === 'S') {

                        apiResult.state = true;
                        apiResult.message = res.data.resultMessage;
                        apiResult.CompleteDocsFileName = res.data.certFileName ?? undefined;

                    }
                }
                catch (e) {
                    throw new KeriAPIException("데이터를 읽어올 수 없습니다.");
                }
            })
            .catch((e) => {
                throw e;
            })

    }
    catch (e) {
        const exp = KeriAPIExceptionFilter(e);
        apiResult.message = exp.message;
        apiResult.state = false;
        apiResult.statusCode = exp.state;
        if (apiResult.statusCode === 401) {
            apiResult.tokenValid = false;
        }
    }




    return apiResult;
}


//#endregion


//#region 기술지원 완료확인서 견본 다운로드

interface IDownloadTechServiceCompleteDocs extends IKeriAuthAPIState {
    pdfFile? : Blob;
}

const DownloadTechServiceCompleteDocs = async (techServiceID: string, token?: string): Promise<IDownloadTechServiceCompleteDocs> => {
    let apiResult: IDownloadTechServiceCompleteDocs = {
        state: true,
        message: undefined,
        pdfFile: undefined,
        tokenValid : false
    }

    try {
        let reqBody: IAPIGetTechServiceCompleteDocsRequestBody = {};

        try {
            reqBody = {
                arId: techServiceID!
            }
        }
        catch (e) {
            throw new KeriAPIException("요청을 보낼 수 없습니다.");
        }


        await CallAPI<Blob>({
            url: KeriAPIStructure.AnalysisRequest.GetCompletePDF,
            method: "POST",
            requestBody: reqBody,
            responseType: 'blob',
            token: token
        })
            .then(res => {
                try {
                    console.log(res);
                    apiResult.state = true;
                    apiResult.statusCode = 200;
                    apiResult.pdfFile = res.data;
                }
                catch (e) {
                    throw new KeriAPIException("데이터를 읽어올 수 없습니다.");
                }
            })
            .catch((e) => {
                throw e;
            })

    }
    catch (e) {
        const exp = KeriAPIExceptionFilter(e);
        apiResult.message = exp.message;
        apiResult.state = false;
        apiResult.statusCode = exp.state;
        if (apiResult.statusCode === 401) {
            apiResult.tokenValid = false;
        }
    }




    return apiResult;
}

//#endregion
export default KeriTechServiceAPI;