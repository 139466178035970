import ICTBaseModal, { KeriModalSubmitAndCancelButton } from "@ict-base/components/control/ICTBaseModal"
import { ObjectUtils } from "@ict-base/module/utils/ObjectUtils"
import { IListPagination } from "@keri-base/interface/control/IListPagination"
import { ITechCodeInfo } from "@keri-portal/interfaces/data-object/data-common/ITechCodeInfo"
import RequestKeriAPI from "@keri-portal/module/method/api-connect/RequestKeriAPI"
import { Box, SxProps, Theme, Typography } from "@mui/material"
import { DefaultScrollDesign } from "@src/modules/styles/DefaultScrollDesign"
import { useEffect, useState } from "react"
import KeriSelectItemModal from "./KeriSelectItemModal"

interface IKeriSelectTechCodeModal {
    open: boolean
    onClose: () => void
    onSubmit: (item: ITechCodeInfo | undefined) => void,
    defaultValue?: ITechCodeInfo
}




const KeriSelectTechCodeModal = (prop: IKeriSelectTechCodeModal) => {

    const [techCategoryList, setTechCategoryList] = useState<ITechCodeInfo[]>([]);
    const [techSubCategoryList, setSubTechCategoryList] = useState<ITechCodeInfo[]>([]);
    const [techCodeList, setTechCodeList] = useState<ITechCodeInfo[]>([]);

   

    const [selectCode, setSelectCode] = useState<{
        first?: ITechCodeInfo,
        second?: ITechCodeInfo,
        third?: ITechCodeInfo
    }>({});




    //#region function

    const getMainCategory = async () => {
        const getMainCategory = await RequestKeriAPI.Attribute.GetTechCodeMainCategoryList();

        if (getMainCategory.state) {
            setTechCategoryList(getMainCategory.resultData);
            setSubTechCategoryList([]);
            setTechCodeList([]);

            if(prop.defaultValue !== undefined){

                const selectCategory = getMainCategory.resultData.find(x=>x.TechCode === prop.defaultValue?.TechCategoryCode) ;

                console.log('DEFAULT VALUE - FIRST : ',selectCategory,getMainCategory.resultData , prop.defaultValue);

                setSelectCode({
                    first : selectCategory
                })

                if(selectCategory  !== undefined && selectCategory.TechCode !== undefined){
                    getSubCategory(selectCategory);
                }
            }
        }
        else {
            alert(getMainCategory.message);
            prop.onClose();
        }
    }

    const getSubCategory = async (mainTechCode : ITechCodeInfo) => {
        const getSubCategory = await RequestKeriAPI.Attribute.GetTechCodeSubCategoryList(mainTechCode.TechCode);

        if (getSubCategory.state) {
            setSubTechCategoryList(getSubCategory.resultData);
            setTechCodeList([]);

            if(prop.defaultValue !== undefined){

                const selectCategory = getSubCategory.resultData.find(x=>x.TechCode === prop.defaultValue?.TechSubCategoryCode) ;

                console.log('DEFAULT VALUE - SECOND : ',selectCategory);

                setSelectCode({
                    first : mainTechCode,
                    second : selectCategory
                })

                if(selectCategory  !== undefined && selectCategory.TechCode !== undefined){
                    getTechCode(mainTechCode , selectCategory);
                }
            }
        }
        else {
            alert(getSubCategory.message);
            prop.onClose();
        }
    }

    const getTechCode = async (mainTechCode: ITechCodeInfo , subTechCode : ITechCodeInfo) => {
        const getTechCode = await RequestKeriAPI.Attribute.GetTechCodeList(mainTechCode.TechCode, subTechCode.TechCode);

        if (getTechCode.state) {
           setTechCodeList(getTechCode.resultData);

            if(prop.defaultValue !== undefined){

                const selectCategory = getTechCode.resultData.find(x=>x.TechCode === prop.defaultValue?.TechCode) ;

                console.log('DEFAULT VALUE - THIRD : ',selectCategory,getTechCode.resultData , prop.defaultValue);



                setSelectCode({
                    first : mainTechCode,
                    second : subTechCode,
                    third : selectCategory
                })
            }
        }
        else {
            alert(getTechCode.message);
            prop.onClose();
        }
    }

    //#endregion




    useEffect(() => {

        console.log('PROP', prop);

        if (prop.open) {
            if (prop.defaultValue === undefined) {
                setTechCategoryList([]);
                setSubTechCategoryList([]);
                setTechCodeList([]);
                setSelectCode({});
                getMainCategory();
            }
            else {
                getMainCategory();
            }
        }

    }, [prop.open])




    return (
        <ICTBaseModal
            open={prop.open}
            onClose={prop.onClose}
            title="산업기술분류"
            submitButton={
                <KeriModalSubmitAndCancelButton
                    onCancel={prop.onClose}
                    onSubmit={() => {
                        prop.onSubmit(selectCode.third);
                        prop.onClose();
                    }}
                />
            }
        >
            <Box
                sx={{
                    width: '700px',
                    height: '400px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'start',
                    p: 1
                }}
            >
                <CodeTypeBox
                    title='대분류'
                    listData={techCategoryList ?? []}
                    onSelect={(item) => {
                        setSelectCode({
                            first: item
                        })
                        getSubCategory(item);
                    }}
                    selectItem={selectCode.first}
                />

                <CodeTypeBox
                    title='중분류'
                    listData={techSubCategoryList ?? []}
                    onSelect={(item) => {
                        setSelectCode({
                            ...selectCode,
                            second: item,
                            third : undefined
                        })
                        getTechCode({
                            TechCode : item.TechCategoryCode!
                        }, item);
                    }}
                    selectItem={selectCode.second}
                />
                <CodeTypeBox
                    title='소분류'
                    listData={techCodeList ?? []}
                    onSelect={(item) => setSelectCode({
                        ...selectCode,
                        third: item
                    })}
                    selectItem={selectCode.third}
                />

            </Box>
        </ICTBaseModal>
    )
}

interface ICodeTypeBox {
    title: string,
    listData: ITechCodeInfo[],
    onSelect: (item: ITechCodeInfo) => void,
    selectItem: ITechCodeInfo | undefined
}

const CodeTypeBox = (prop: ICodeTypeBox) => {
    return (
        <Box
            sx={{
                width: '30%',
                height: '100%',
            }}
        >
            <Box
                sx={{
                    height: '40px',
                    display: 'flex',
                    alignItems: 'center',
                    pr: 1
                }}
            >
                <Typography>
                    {prop.title}
                </Typography>
            </Box>
            <Box
                sx={{
                    width: '100%',
                    height: 'calc(100% - 40px)',
                    border: '1px solid #aaa',
                    borderRadius: '5px',
                    overflowX: 'auto',
                    ...DefaultScrollDesign

                }}
            >
                {
                    prop.listData.map(item => (
                        <Box
                            sx={{
                                width: '100%',
                                p: 1,
                                cursor: 'pointer',
                                ":hover": {
                                    background: 'rgba(0,0,0,0.1)'
                                },
                                ...(prop.selectItem?.TechCode === item.TechCode ? { background: 'rgba(0,0,0,0.2)' } : {})
                            }}
                            onClick={() => prop.onSelect(item)}
                        >
                            {item.TechName}
                        </Box>
                    ))
                }
            </Box>
        </Box>
    )
}

export default KeriSelectTechCodeModal;
