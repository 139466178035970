import { IKeriAlert } from "@ictpeople/misc-core"
import { IUseGetAlertList, useGetAlertList } from "@ictpeople/misc-react"
import { setAlert } from "@src/modules/redux/reducer/UserAlertReducer"
import { ILoginSessionData } from "@src/modules/redux/reducer/UserDataReducer"
import URLStructure from "@src/modules/structure/URLStructure"
import React, { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

interface IMISCSystemAlertScript{
    token? : string,
    redux : {
        data : IKeriAlert | undefined,
        update : (item? : IKeriAlert ) => void
    }
}

export const MISCSystemAlertScript = (prop : IMISCSystemAlertScript) => {


    const [hookData , setHookData] = useState<IUseGetAlertList>({
        techServiceListURL : URLStructure.MemberInfoTechServiceList,
        TriggerTime : new Date(),
        token : undefined
    })
    const alertHook = useGetAlertList(hookData);


    useMemo(() => {
        if(alertHook.complete){
            if(alertHook.data?.state === true){
                prop.redux.update(alertHook.data.resultData)
            }
        }
    } , [alertHook])




    useEffect(() => {

        let interval = setInterval(async () => {

            setHookData({
                ...hookData, 
                token : prop.token,
                TriggerTime : new Date()
            })

        }, 3000)
        return () => clearInterval(interval)
    },[prop.token])





    

    return (<React.Fragment></React.Fragment>)
}