const KeriObjectUtil = {
    CastTechServiceEffectToKorean : (key : string) => {

        switch(key){
            case 'Sales' : return '매출(개발 및 관련 제품)';
            case 'Employ' : return '고용';
            case 'ReduceDevelopmentCost' : return '개발비용 절감 효과';
            case 'ReduceDevelopmentPeriod' : return '개발기간 단축 효과';
            case 'MoU' : return 'MoU';
            case 'InvestmentAttraction' : return '투자유치';
            case 'productionRateImprovement' : return '생산률 개선';
            case 'productionDefectRateImprovement' : return '불량률 개선';
            case 'Vender' : return '1차 벤더사 등록';
            default : return key;
        }
    },
    CastTechServiceEffectToUnit : (key : string) => {
        switch(key){
            case 'Sales' : return '백만원';
            case 'Employ' : return '명';
            case 'ReduceDevelopmentCost' : return '백만원';
            case 'ReduceDevelopmentPeriod' : return '개월';
            case 'MoU' : return 'MoU 체결';
            case 'InvestmentAttraction' : return '백만원';
            case 'productionRateImprovement' : return '%';
            case 'productionDefectRateImprovement' : return '%';
            case 'Vender' : return '벤더사 등록';
            default : return undefined;
        }
    }
}

export default KeriObjectUtil;