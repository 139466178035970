import { KeriHeaderHeight } from "@keri-portal/component/common/KeriHeader";
import { Box, Button, Typography } from "@mui/material";
import URLStructure from "@src/modules/structure/URLStructure";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import { KeriHeaderHeight } from "@keri-portal/component/common/KeriHeader";

const NotFoundViewPage = () => {
    const navigate = useNavigate();
    useEffect(() => {
        navigate(`${URLStructure.Error}/404` , {
            replace : true
        })
    },[])
    return (
        <Box
            sx={{
                pt: KeriHeaderHeight + 'px',
                backgroundColor: '#edf1f6',
                minHeight: `calc(100% - ${KeriHeaderHeight}px)`
            }}
        >
           
        </Box>
    )
}

export default NotFoundViewPage;