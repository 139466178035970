import { Theme , SxProps } from "@mui/material";

export const DefaultScrollDesign: SxProps<Theme> = {
    scrollbarWidth: '1px',
    scrollMargin: 0,
    '&::-webkit-scrollbar': {
        width: '0.2em',
        borderRadius : '5px'
    },
    '&::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0,0,0,.2)',
    }
}