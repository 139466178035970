import { Box, createTheme, Pagination, PaginationItem, Paper, SxProps, Theme, ThemeProvider, useMediaQuery } from "@mui/material"

interface IMISCPagination {
    icon?: {
        first?: React.ElementType;
        last?: React.ElementType;
        next?: React.ElementType;
        previous?: React.ElementType;
    },
    page: number,
    totalPage: number,
    onChange: (page: number) => void,
    boxSx?: SxProps<Theme>,
    paginationSx?: SxProps<Theme>
}


export const MISCPagination = (prop: IMISCPagination) => {

    const isMobile = useMediaQuery('(max-width : 425px)')

    const MISCPaginationTheme = createTheme({
        components: {
            MuiPaginationItem: {
                styleOverrides: {
                    "root": {
                        "&.Mui-selected": {
                            // "border": "1px solid #4a92b1",
                            "background" : '#4a92b1',
                            "fontWeight" : 'bold',
                            color : 'white',
                            ":hover" : {
                                background : '#9cc9dd',
                                color : 'white'
                                // borderColor : "#9cc9dd"
                            },
                        },
                        ":hover" : {
                            background : 'none',
                            color : '#4a92b1'
                        },
                        
                    }

                }
            }
        }
    })


    return (
        <Box
            sx={{
                width: "100%",
                display: 'flex',
                justifyContent: 'center',
                alignItems: "center",
                ...prop.boxSx
            }}
        >
            <Paper
                elevation={0}
                sx={{
                    p : 2,
                    minWidth : isMobile ? '100%' : '50%',
                    borderRadius : '30px',
                    display : 'flex',
                    justifyContent : 'center',
                    alignItems : 'center',
                    border : '1px solid rgba(0,0,0,0.1)'
                }}
            >
                <ThemeProvider theme={MISCPaginationTheme}>
                    <Pagination
                        renderItem={(item) => (
                            <PaginationItem
                                slots={{
                                    previous: prop.icon?.previous,
                                    next: prop.icon?.next
                                }}
                                {...item}

                            />
                        )}
                        sx={prop.paginationSx}
                        count={prop.totalPage}
                        defaultPage={prop.page}
                        onChange={(evt, page) => prop.onChange(page)}
                    />
                </ThemeProvider>
            </Paper>

        </Box>
    )
}