import { Box } from "@mui/material"

interface IEditControl{
    isEdit : boolean,
    editComponent? : React.ReactNode,
    viewComponent? : React.ReactNode
}   

const EditControl = (prop : IEditControl) => {
    return (
        <Box>
            {
                (prop.editComponent !== undefined && prop.isEdit ) && prop.editComponent
            }
            {
                (prop.viewComponent !== undefined && !prop.isEdit ) && prop.viewComponent
            }
        </Box>
    )
}

export default EditControl;