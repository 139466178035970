const URLStructure = {
    //#region 기술지원 서비스

    // 기술지원 신청
    TechServiceRegister : `${process.env.PUBLIC_URL}/tech-service/register`,
    // 시뮬레이션 뷰어
    TechServiceSimulationViewer : `${process.env.PUBLIC_URL}/tech-service/simulation-viewer`,
    // 기술지원 결과 비교
    TechServiceCompareResults : `${process.env.PUBLIC_URL}/tech-service/compare-results`,
    // 시뮬레이션 입력 파일
    TechServiceInputGeneratorDownload : `${process.env.PUBLIC_URL}/tech-service/input-generator-download`,

    //#endregion

    //#region 알림

    // 공지사항
    NotificationNotices : `${process.env.PUBLIC_URL}/board/BBSMSTR_B99999999999`,
    // 자주 묻는 질문과 답
    NotificationFaq : `${process.env.PUBLIC_URL}/board/BBSMSTR_D99999999999`,
    // 이벤트 공지
    NotificationEvent : `${process.env.PUBLIC_URL}/board/BBSMSTR_C99999999999`,
    // 언론 보도 자료
    NotificationNews : `${process.env.PUBLIC_URL}/board/BBSMSTR_E99999999999`,
    // 기업지원 우수사례 
    NotificationCase : `${process.env.PUBLIC_URL}/board/BBSMSTR_F99999999999`,
    // 배포 자료
    NotificationDistContent : `${process.env.PUBLIC_URL}/board/BBSMSTR_G99999999999`,

    //#endregion

    //#region 기술지원 안내

    // 해석기술지원 절차
    GuildesSimulationStep : `${process.env.PUBLIC_URL}/static/techSptStep`,
    // 해석기술지원 안내
    GuildesSimulationGuide : `${process.env.PUBLIC_URL}/static/techSupport`,
    // 제품성능시험 안내
    GuildesPerformanceTestGuide : `${process.env.PUBLIC_URL}/static/performance-test-guide`,
    // 지능형 시뮬레이션 안내
    GuidesActiveSimulationGuide : `${process.env.PUBLIC_URL}/static/active-simulation-guide`,
    // AR/VR 서비스 안내
    GuidesArVrServiceGuide : `${process.env.PUBLIC_URL}/static/ar-vr-Service-guide`,
    // 3D Printing 서비스 안내
    Guides3dPrintingGuide : `${process.env.PUBLIC_URL}/static/printing-3d-Service-guide`,
    // 제어 및 신호측정 서비스 안내
    GuidesControlsignalmeasurementGuide : `${process.env.PUBLIC_URL}/static/control-signal-measurement-service-guide`,
    // 교육지원 안내
    GuidesTechnicalEducationGuide : `${process.env.PUBLIC_URL}/guides/technical-education-guide`,
    // 서비스 요금 안내
    GuidesServiceChargeGuide : `${process.env.PUBLIC_URL}/guides/service-charge-guide`,

    //#endregion

    //#region 센터 소개

    // 센터 설립 개요
    AboutCenterMission : `${process.env.PUBLIC_URL}/static/company`,
    // 센터 보유 자산
    AboutCenterEnableMachine : `${process.env.PUBLIC_URL}/static/centerEquip`,
    // 센터 오시는 길
    AboutCenterVisitCenter : `${process.env.PUBLIC_URL}/static/location`,
    // 개인정보처리방침
    AboutUserDataUsing : `${process.env.PUBLIC_URL}/static/privacy`,
    // 이용약관
    UsingPur : `${process.env.PUBLIC_URL}/static/user_guide`,
    //센터 인적 구성
    AboutCenterHuman : `${process.env.PUBLIC_URL}/static/centerHuman`,
    //  센터 운영 실적
    AboutCenterPerf : `${process.env.PUBLIC_URL}/static/centerPerf`,

    //#endregion

    //#region 회원정보

    // 회원 기본정보
    MemberInfoBasicInfo : `${process.env.PUBLIC_URL}/member-info/basic-info`,
    // 기술지원 내역
    MemberInfoTechServiceList : `${process.env.PUBLIC_URL}/member-info/tech-service-list`,
    // 품목 관리
    MemberInfoItemManagement : `${process.env.PUBLIC_URL}/member-info/item-management`,
    // 기술교육 신청
    EduTechService : `${process.env.PUBLIC_URL}/share-board/BBSMSTR_I99999999999`,
    // 기술상담
    TechConsulting : `${process.env.PUBLIC_URL}/share-board/BBSMSTR_H99999999999`,

    //#endregion


    //#region System

    // 가입
    UserRegister : `${process.env.PUBLIC_URL}/register`,
    // 로그인
    UserLogin : `${process.env.PUBLIC_URL}/login`,
    // 메인
    Main : `${process.env.PUBLIC_URL}/`,
    // Asset
    Asset : `${process.env.PUBLIC_URL}/assets`,
    // Error
    Error : `${process.env.PUBLIC_URL}/error`,

    //#endregion


    Edu : {
        EducationList : `${process.env.PUBLIC_URL}/edu/schedule`,
        EducationInfo : (educationScheduleID? : string) => `${process.env.PUBLIC_URL}/edu/schedule/${educationScheduleID}`
    },





    PlaceHolderImage : `${process.env.PUBLIC_URL}/assets/img/img_placeholder.jpg`
    

}

export default URLStructure;