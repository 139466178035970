import { Refresh, WarningRounded } from "@mui/icons-material"
import { Box, Button, Typography } from "@mui/material"

interface IMISCTechSErviceSectionEmptyDataBoxProp {
    refresh?: () => void
}

export const MISCTechSErviceSectionEmptyDataBox = (prop: IMISCTechSErviceSectionEmptyDataBoxProp) => {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '200px',
                flexDirection: 'column'
            }}
        >
            <Box
                sx={{
                    textAlign: 'center'
                }}
            >
                <WarningRounded fontSize="large" />
                <Typography variant="subtitle1" fontWeight={'bold'}>
                    데이터가 없습니다.
                </Typography>
            </Box>
            {
                prop.refresh !== undefined &&
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: 'center',
                        alignItems: 'center',
                        mt: 1
                    }}
                >
                    <Button
                        variant="outlined"
                        sx={{
                            border : '1px solid rgba(0,0,0,0.2)',
                            transition: 'all 0.3s ease-out',
                            ":hover" : {
                                borderColor : 'rgba(0,0,0,0.5)',
                                filter : 'Brightness(0.5)'
                            }
                        }}
                        onClick={prop.refresh}
                    >
                        <Refresh fontSize="small" sx={{ color: 'gray', mr: 1 , }} />
                        <Typography variant="subtitle2" sx={{ color: "gray" }}>
                            새로고침
                        </Typography>
                    </Button>

                </Box>
            }


        </Box>
    )
}