import { IKeriMenuCategoryItem } from "@ictpeople/misc-core"
import { IUseGetAlertList, IUseGetMenuCategoryList, useGetAlertList, useGetMenuCategoryList } from "@ictpeople/misc-react"
import { setMenuCategory } from "@src/modules/redux/reducer/MenuCategoryReducer"
import { setAlert } from "@src/modules/redux/reducer/UserAlertReducer"
import { ILoginSessionData } from "@src/modules/redux/reducer/UserDataReducer"
import URLStructure from "@src/modules/structure/URLStructure"
import React, { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

interface IMISCSystemMenuListScript {
    token?: string,
    redux : {
        data : IKeriMenuCategoryItem[],
        update : (item : IKeriMenuCategoryItem[]) => void
    }
}

export const MISCSystemMenuListScript = (prop: IMISCSystemMenuListScript) => {



    const [menuHookData, setMenuHookData] = useState<IUseGetMenuCategoryList>({
        TriggerTime: new Date(),
        token: prop.token
    })

    const menuCategoryHook = useGetMenuCategoryList(menuHookData);


    useMemo(() => {
        
        if (menuCategoryHook.data?.state === true) {
            console.log('UPDATE MENU REDUX---------------------------------------\n',menuCategoryHook.data.resultData ?? []);
            prop.redux.update(menuCategoryHook.data.resultData ?? [])
        }
    }, [menuCategoryHook])

    useEffect(() => {
        console.log('CHANGE TOKEN---------------------------------------------------------\n',prop.token)
        prop.redux.update([]);
        setMenuHookData({
            TriggerTime : new Date(),
            token : prop.token
        })
    },[prop.token])


    useEffect(() => {
        console.log('MENU REDUX RUNNING');
    },[])


    




    return (<React.Fragment></React.Fragment>)
}