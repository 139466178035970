import KeriActionButton from "@ict-base/components/control/KeriActionButton";
import { KeriEditGridCheckBoxField } from "@ict-base/components/control/KeriEditGrid";
import { KeriFileTable, KeriFileTableItem } from "@ict-base/components/control/KeriFileTable";
import KeriScrollBox from "@ict-base/components/control/KeriScrollBox";
import { ObjectUtils } from "@ict-base/module/utils/ObjectUtils";
import RegexUtil from "@ict-base/module/utils/RegexUtil";
import { MISCFullBlurLoadingModal } from "@ictpeople/misc-react";
import KeriBaseConfig from "@keri-base/config/KeriBaseConfig";
import { IKeriStepShareProps, KeriStepItemLayout, KeriStepItemContent } from "@keri-portal/component/layout/StepLayout";
import { FakeTechServiceInfoDownloadFileList } from "@keri-portal/config/KeriFakeDatas";
import { IMemberInfoVO } from "@keri-portal/interfaces/api/keri-api/object/IMemberInfoVO";
import { IUploadFileVO } from "@keri-portal/interfaces/api/keri-api/object/IUploadFileVO";
import { IUploadFile } from "@keri-portal/interfaces/control/IUploadFile";
import { TechServiceType } from "@keri-portal/interfaces/data-object/tech-service/ITechService";
import { ITechServiceRequest } from "@keri-portal/interfaces/process-object/tech-service/ITechServiceRequest";
import useLoginCheck from "@keri-portal/module/hook/useLoginCheck";
import RequestKeriAPI from "@keri-portal/module/method/api-connect/RequestKeriAPI";
import { Download } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

interface ITechServiceType {
    key: string,
    value: string,
    returnData: TechServiceType
}

const techServiceType: ITechServiceType[] = [
    {
        key: 'serv1',
        value: '해석(A)',
        returnData: 'A'
    },
    {
        key: 'serv2',
        value: '제품성능시험(T)',
        returnData: 'T'
    },
    {
        key: 'serv3',
        value: '지능형 시뮬레이션(S)',
        returnData: 'S'
    },
    {
        key: 'serv4',
        value: 'AR/VR 서비스(R)',
        returnData: 'R'
    },
    {
        key: 'serv5',
        value: '제어 및 신호측정 서비스(C)',
        returnData: 'C'
    },
    {
        key: 'serv6',
        value: '3D 프린팅 서비스(P)',
        returnData: 'P'
    },
]







const fakehtml = `<img src="/misc/assets/html/img/sptStep.png" style="width : 100%" alt="기술지원절차">
<div> 
<h3>기술지원 단계별 설명</h3> 
<ul class="numberlist-ul"> 
 <li>기업회원만 기술지원을 신청하실 수 있습니다. 회원 가입을 원하시면 회원가입 안내를 참고해주시기 바랍니다.</li> 
 <li>기술지원 신청에 관한 문의 사항은 <자주 묻는 질문과 답> 또는 <기술지원 안내>를 통해 확인하실 수 있습니다. 보다 구체적인 상담이 필요하시면 기술지원 상담에 글을 남겨주시기 바랍니다.</li> 
 <li>기술지원 신청서 양식을 다운로드하여 신청서를 작성한 후, 기술지원 신청을 통해 제출해주시기 바랍니다.</li> 
 <li>제출하신 신청서는 과제선정 위원회의 심의 과정을 통해 기술지원 여부를 결정합니다. 이 과정에서 과제선정 위원회가 추가적인 자료 제출이나 제출하신 자료에 대한 보완을 요청할 수 있습니다. 선정 결과는 메일로 통지해 드립니다.</li> 
 <li>과제선정 위원회에서 기술지원이 결정되면, 기술지원 담당자가 배정됩니다.</li> 
 <li>기술지원 담당자는 제출하신 신청서를 검토하여 과업의 범위와 일정에 관한 협의를 진행하고, 기술지원 계획을 수립합니다.</li> 
 <li>신청 기업과 기관(공정혁신 시뮬레이션 센터)간 과제 협약을 체결합니다.</li> 
 <li>협약 체결 이후에는 기술지원 담당자를 중심으로 기술지원 업무를 수행합니다. 이 과정에서 1차적으로 도출된 결과를 신청 기업과 검토하고, 추가적인 기술지원 여부를 결정합니다.</li> 
 <li>기술지원의 최종 결과가 도출되면 기술지원 담당자는 최종 보고서를 작성하여 제출하고, 품질 검증 위원회가 그 결과에 대한 품질을 검증합니다.</li> 
 <li>신청 기업은 기술지원 내역에서 최종 보고서를 확인할 수 있으며, 기술지원 종료 확인서 양식을 작성하여 제출함으로써 기술지원 절차를 종료할 수 있습니다.</li> 
 <li>신청 기업은 종료된 기술지원에 대해 설문을 통해 기술지원 과정과 결과에 대한 만족도를 평가할 수 있습니다.</li> 
</ul> 
</div>`




const KeriRegisterTechServiceInfoItem = (prop: IKeriStepShareProps<ITechServiceRequest>) => {

    const userRedux = useLoginCheck();

    const [techServicePhaseInfoHTML, setTechServicePhaseInfoHTML] = useState<string | undefined>(undefined);

    const [downloadFileObjectList, setDownloadFileObjectList] = useState<IUploadFile<IUploadFileVO>[]>([]);

    const [defaultCheck, setDefaultCheck] = useState<ITechServiceType | undefined>(undefined);

    const [userData, setUserData] = useState<IMemberInfoVO | undefined>(undefined);

    const [loadingModalOpen , setLoadingModalOpen] = useState<boolean>(false);

    const navigate = useNavigate();

    const validationCheck = (): boolean => {

        let isError = false;


        if (defaultCheck === undefined) {
            alert('기술지원 유형이 설정되지 않았습니다.\n기술지원 유형을 필수로 선택해주세요.');
            isError = true;
        }

        return !isError;
    }

    const getInfoData = async () => {
        // prop.routeProp.EventFunction.EnableBackDrop(true);

        const getUserData = await RequestKeriAPI.Account.GetUserAndCompanyInfo(userRedux?.token);

        if (getUserData.state) {
            if (getUserData.userData !== undefined) {
                setUserData(getUserData.userData);
            }
            else {
                alert('회원 데이터를 불러올 수 없습니다.\n관리자에게 문의 바랍니다.');
                navigate(-1);

                return;
            }
        }
        else {
            alert('회원 데이터를 불러올 수 없습니다.\n관리자에게 문의 바랍니다.');
            navigate(-1);

            return;
        }



        const getTemplateList = await RequestKeriAPI.Common.GetTemplateList();

        console.log('get template list', getTemplateList);
        console.log('match list', getTemplateList.resultData.find(x => x.TemplateID === 'TechServiceTempFile' && x.TemplateTitle === '기술지원템플릿'))

        if (getTemplateList.state) {
            if (getTemplateList.resultData.find(x => x.TemplateID === 'TechServiceTempFile' && x.TemplateTitle === '기술지원템플릿') === undefined) {
                alert('제출자료 양식을 불러올 수 없습니다.\n관리자에게 문의 바랍니다.');
                navigate(-1);
            }
            else {
                const getTechServiceTemplateFiles = await RequestKeriAPI.TechService.GetTechServiceTemplateFiles(getTemplateList.resultData.find(x => x.TemplateID === 'TechServiceTempFile' && x.TemplateTitle === '기술지원템플릿')!.TemplateAtchFileID);

                console.log('Call Template Files', getTechServiceTemplateFiles);

                if (getTechServiceTemplateFiles.state) {
                    setDownloadFileObjectList(getTechServiceTemplateFiles.resultData.map((resItem, index) => (
                        {
                            Index: index,
                            Category: '',
                            Label: '',
                            Prefix: '',
                            UploadedFile: resItem
                        }
                    )))


                    // TODO : HTML 호출 필요
                    setTechServicePhaseInfoHTML(fakehtml);
                }
                else {
                    alert('제출자료 양식을 불러올 수 없습니다.\n관리자에게 문의 바랍니다.');
                    navigate(-1);
                }



            }
        }
        else {
            alert('제출자료 양식을 불러올 수 없습니다.\n관리자에게 문의 바랍니다.');
            navigate(-1);
        }





        prop.routeProp.EventFunction.EnableBackDrop(false);
    }


    useEffect(() => {
        if (userRedux?.loadComplete && userData === undefined) {
            getInfoData();
        }

    }, [prop.step, userRedux])

    useEffect(() => {
        if (defaultCheck === undefined) {
            setDefaultCheck(techServiceType.find(x => x.returnData === prop.result.data.TechServiceType) !== undefined ? techServiceType.find(x => x.returnData === prop.result.data.TechServiceType)! : undefined)
        }

    }, [prop.result.data.TechServiceType])

    return (
        <KeriStepItemLayout
            {...prop}
            validationCheck={validationCheck}
            step={prop.step}
            page={{
                moveNext: (setup,validate) => {

                    if(validate){
                        prop.result.onChange({
                            ...prop.result.data,
                            TechServiceType: defaultCheck?.returnData,
                            RequesterData: {
                                ...prop.result.data.RequesterData,
                                RequestType: defaultCheck?.returnData ?? 'A',
                                RequesterData: prop.result.data.RequesterData?.RequesterData === undefined ? {
                                    RequesterName: userData?.mberNm ?? undefined,
                                    RequesterEmail: userData?.mberEmailAdres ?? undefined,
                                    RequesterCallNumber: RegexUtil.ConvertRegexString.PhoneNumber(userData?.middleTelno ?? undefined),
                                    RequesterDepartment: (userData?.mberDept && userData.mberRank) ? `${userData?.mberDept}/${userData.mberRank}` : undefined
                                } : prop.result.data.RequesterData.RequesterData,
    
                            }
                        })
                        setup(prop.step.activeStep + 1);
                    }
                    
                }
            }}
        >
            <KeriStepItemContent
                title='기술지원 절차 안내(요약)'
            >
                <KeriScrollBox
                    height={500}
                >
                    <div dangerouslySetInnerHTML={{
                        __html: techServicePhaseInfoHTML ?? ''
                    }} />
                </KeriScrollBox>


            </KeriStepItemContent>

            <KeriStepItemContent
                title='기술지원 유형 선택'
            >
                {
                    // defaultCheck !== undefined &&
                    <KeriEditGridCheckBoxField
                        editDisabled={(prop.result.data.FixType ?? false)}
                        nonDisableItemChecked
                        title="기술지원 유형"
                        data={techServiceType}
                        displayValue={(item) => item?.value ?? ''}
                        displayKey={(item) => item?.key ?? ''}
                        // defaultValue={defaultCheck}
                        value={[defaultCheck]}
                        onItemChanged={(item) => {
                            if (item !== undefined) {
                                setDefaultCheck(item[0]);
                            }
                        }}
                        props={{
                            itemAlign: 'end',

                        }}
                        itemProps={{
                            sx: {
                                minWidth: '240px'
                            }
                        }}
                    />
                }

            </KeriStepItemContent>






            <KeriStepItemContent
                title='제출자료 양식'
            >
                <KeriFileTable
                    fileMode='Download'
                    denyFileExtension={KeriBaseConfig.DenyFileExtension}
                >

                    {

                        downloadFileObjectList.sort((x, y) => x.Index - y.Index).map((item, idx) => (
                            <KeriFileTableItem
                                key={ObjectUtils.RandomStrings(10)}
                                uploadProp={{
                                    denyFileExtension: KeriBaseConfig.DenyFileExtension
                                }}
                                fileObject={item}
                                fileMode='Download'
                                displayObject={{
                                    displayTitle: (item) => '',
                                    displayFileName: (item) => item.UploadedFile === undefined ? item.OriginFile?.name : item.UploadedFile.orignlFileNm,
                                    displayDeleteRow: (item) => !item.Required,
                                    disabledTitle: true
                                }}
                                event={{
                                    onDownload: async (item) => {
                                        setLoadingModalOpen(true);
                                        const downloadItem = await RequestKeriAPI.File.DownloadFileUsingAtchFileID(undefined, item.UploadedFile?.atchFileId, parseInt(item.UploadedFile?.fileSn ?? '-1'));
                                        if (downloadItem.state) {
                                            const jsonFile = new Blob([downloadItem.data!]);
                                            const element = document.createElement("a");

                                            element.href = URL.createObjectURL(jsonFile);
                                            element.download = item.UploadedFile?.orignlFileNm ?? 'NO_TITLE';
                                            document.body.appendChild(element);
                                            element.click();
                                            element.remove();
                                        }
                                        else {
                                            alert(downloadItem.message);
                                        }
                                        setLoadingModalOpen(false);
                                    }
                                }}
                            />

                        ))
                    }
                </KeriFileTable>
            </KeriStepItemContent>



            {/* Modal */}
            <MISCFullBlurLoadingModal 
                open={loadingModalOpen}
                onClose={() => {}}
                message='다운로드 중..'
            />
        </KeriStepItemLayout>
    )
}

export default KeriRegisterTechServiceInfoItem; 