import KeriActionButton from "@ict-base/components/control/KeriActionButton";
import KeriTheme from "@keri-portal/config/KeriTheme";
import { IPageProp } from "@keri-base/interface/common/IPageProp";
import { Info } from "@mui/icons-material";
import { Box, Container, createTheme, Divider, Paper, Step, StepLabel, Stepper, SxProps, Theme, ThemeProvider, Tooltip, Typography } from "@mui/material";
import { SetStateAction, useState } from "react";
import InfoLayoutPage from "./InfoLayoutPage";
import KeriLogo from "../common/KeriLogo";

export interface IKeriStepPhase<T> {
    idx: number,
    title: string,
    tabComponent: (prop: {
        result: {
            data: T,
            onChange: (item : T) => void
        },
        step: {
            activeStep: number,
            maxStepCount: number,
            setup: React.Dispatch<React.SetStateAction<number>>
        }
    }) => React.ReactNode

}

interface IKeriStepLayout<T> {
    enableLogo?: boolean,
    stepData: IKeriStepPhase<T>[],
    result: {
        data: T,
        onChange: (item : T) => void
    }
    customLogo?: React.ReactNode
}


/**
 * Step Layout Page
 * 
 * @param prop 
 * @returns 
 */
export const StepLayout = <T extends any>(prop: IKeriStepLayout<T>) => {

    const [activeStep, setActiveStep] = useState(1);


    const stepTheme = createTheme({
        palette: KeriTheme.KeriPalette,
        components: KeriTheme.KeriComponent.KeriStep
    })

    return (
        <InfoLayoutPage>

            {
                (prop.enableLogo ?? false) &&
                <Box
                    id="logo"
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        
                    }}
                >
                    {
                        prop.customLogo !== undefined
                            ? prop.customLogo
                            : <KeriLogo
                                style={{
                                    width: '33%',
                                    userSelect: 'none',
                                    marginTop :4
                                }}
                                isTop
                            />
                    }

                    {/* <img
                        src={`${process.env.PUBLIC_URL}/assets/img/ict/images/logo.svg`}
                        style={{
                            width: '33%',
                            userSelect: 'none'
                        }}
                    /> */}
                </Box>
            }


            <Box
                sx={{
                    mt: 8,
                    pb: 5
                }}
            >
                <Paper
                    elevation={3}
                    sx={{
                        background: 'white',
                        borderRadius: '5px',
                        p: 2
                    }}
                >
                    <Box
                        id="Keri-StepBox"
                        sx={{
                            p: 3,
                            pt: 10,
                            pb: 10
                        }}
                    >
                        <ThemeProvider theme={stepTheme}>
                            <Stepper
                                activeStep={activeStep - 1}
                                alternativeLabel
                                sx={{
                                    userSelect: 'none'
                                }}
                            >
                                {
                                    prop.stepData.sort((x, y) => x.idx - y.idx).map(step => (
                                        <Step key={step.idx}>
                                            <StepLabel>
                                                {step.title}
                                            </StepLabel>
                                        </Step>
                                    ))
                                }
                            </Stepper>
                        </ThemeProvider>
                    </Box>
                    <Divider />

                    <Container
                        sx={{
                            p: 5,
                            width: '90%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexDirection: 'column'
                        }}
                    >
                        <Box>
                            {
                                prop.stepData.find(x => x.idx === activeStep)?.tabComponent({
                                    result: prop.result,
                                    step: {
                                        activeStep: activeStep,
                                        maxStepCount: prop.stepData.length,
                                        setup: setActiveStep
                                    }
                                })
                            }
                        </Box>


                    </Container>
                </Paper>
            </Box>
        </InfoLayoutPage>
    )
}

export interface IKeriStepShareProps<T> {
    children?: React.ReactNode,
    result: {
        data: T,
        onChange : (item : T) => void
        // setup: React.Dispatch<SetStateAction<T>>
    },
    step: {
        activeStep: number,
        maxStepCount: number,
        setup: React.Dispatch<React.SetStateAction<number>>
    },
    routeProp: IPageProp,
    disableButton?: boolean
}


interface IKeriStepItemLayout<T> extends IKeriStepShareProps<T> {
    validationCheck: () => (Promise<boolean> | boolean)
    page?: {
        moveNext?: (setupPage: React.Dispatch<SetStateAction<number>>, validation: boolean) => void,
        movePrevious?: (setupPage: React.Dispatch<SetStateAction<number>>) => void
    }
}


export const KeriStepItemLayout = <T extends any>(prop: IKeriStepItemLayout<T>) => {


    return (
        <Box>
            <Box>
                {prop.children}
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'end',
                    alignItems: 'center'
                }}
            >
                {
                    (prop.step.activeStep !== 1 && !(prop.disableButton ?? false)) &&
                    <KeriActionButton
                        label="이전"
                        color="secondary"
                        variant="contained"
                        onClick={() => {
                            if (prop.page?.movePrevious !== undefined) {
                                prop.page.movePrevious(prop.step.setup);
                            }
                            else {
                                prop.step.setup(prop.step.activeStep - 1);
                            }

                            window.scrollTo(0, 0);

                        }}

                    />
                }
                {
                    (prop.step.activeStep !== prop.step.maxStepCount && !(prop.disableButton ?? false)) &&
                    <KeriActionButton
                        label="다음"
                        color="primary"
                        variant="contained"
                        onClick={async () => {
                            if (prop.page?.moveNext !== undefined) {
                                const valCheck  = await prop.validationCheck()
                                prop.page.moveNext(prop.step.setup,valCheck);
                                if(valCheck){
                                    window.scrollTo(0, 0);
                                }
                            }
                            else {
                                if (await prop.validationCheck()) {
                                    window.scrollTo(0, 0);
                                    prop.step.setup(prop.step.activeStep + 1);
                                }
                            }

                            
                        }}
                        sx={{
                            ml: 1
                        }}
                    />
                }

            </Box>
        </Box>
    )
}

interface IKeriStepItemContent {
    children: React.ReactNode,
    title?: string | React.ReactNode,
    endAdornment?: React.ReactNode,
    tooltip?: {
        enableTooltip?: boolean,
        tooltipMessage: string
    }
}

export const KeriStepItemContent = (prop: IKeriStepItemContent) => {
    return (
        <Box
            className='Keri-StepItemContent'
        >
            {
                prop.title !== undefined &&
                <Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'start',
                                alignItems: 'center'
                            }}
                        >
                            <Typography
                                sx={{
                                    fontWeight: 'bold',
                                    userSelect: 'none',
                                    fontSize : '18px'
                                }}
                            >
                                {prop.title}

                            </Typography>
                            {
                                (prop.tooltip?.enableTooltip ?? false) &&
                                <Tooltip title={prop.tooltip?.tooltipMessage ?? ''} arrow placement="top" sx={{ m: 0, p: 0 }}>
                                    <Info sx={{ width: '20px', cursor: 'pointer', m: 0, p: 0, ml: 1 }} />
                                </Tooltip>
                            }

                        </Box>


                        {
                            prop.endAdornment !== undefined &&
                            prop.endAdornment
                        }
                    </Box>


                    <Divider sx={{ mt: 1.5, mb: 1.5 }} />
                </Box>
            }

            <Box sx={{ mb: 3 }}>
                {prop.children}
            </Box>
        </Box>
    )
}


interface IKeriStepItemSubContent {
    children: React.ReactNode,
    title?: React.ReactNode,
    sx?: SxProps<Theme>,
    textProps?: SxProps<Theme>
}


export const KeriStepItemSubContent = (prop: IKeriStepItemSubContent) => {
    return (
        <Box
            sx={{
                mt: 2,
                mb: 4,
                ...prop.sx
            }}
        >
            {
                prop.title !== undefined &&
                <Box>
                    <Typography
                        variant='body1'
                        sx={{
                            fontWeight: 'bold',
                            ...prop.textProps
                        }}
                    >
                        {prop.title}
                    </Typography>
                </Box>
            }

            {prop.children}
        </Box>
    )
}