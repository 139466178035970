import { ILoginUserVO } from '@keri-portal/interfaces/api/keri-api/object/ILoginUserVO';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import SessionStorageServ from '@ict-base/module/utils/SessionStorageServ';


export interface ILoginSessionData{
    token : string | undefined,
    resultVO : ILoginUserVO | undefined,
    expireData : Date | undefined,
    loadComplete : boolean
}

export const UserDataReducer = createSlice({
    name : 'user',
    initialState : {
        token : undefined,
        resultVO : undefined,
        expireData : undefined,
        loadComplete : false
    } as ILoginSessionData,
    reducers : {
        setUser : (state, action : PayloadAction<ILoginSessionData>) => {
            SessionStorageServ.setData(process.env.REACT_APP_USER_LOCAL_STORAGE_KEY, action.payload);
            return action.payload;
        },
        removeUser : () => {
            SessionStorageServ.removeData(process.env.REACT_APP_USER_LOCAL_STORAGE_KEY)
            return {
                token : undefined,
                resultVO : undefined,
                expireData : undefined,
                loadComplete : true
            } as ILoginSessionData
        }
    }
})


export const { setUser, removeUser } = UserDataReducer.actions;
export default UserDataReducer.reducer;