import { Add } from "@mui/icons-material";
import { useState } from "react";
import KeriActionButton from "@ict-base/components/control/KeriActionButton";
import { KeriFileTable, KeriFileTableItem } from "@ict-base/components/control/KeriFileTable";
import { IKeriStepShareProps, KeriStepItemLayout, KeriStepItemContent } from "@keri-portal/component/layout/StepLayout";
import { IUploadFile } from "@keri-portal/interfaces/control/IUploadFile";
import { IAccountRegister, IAccountRegisterUser } from "@keri-portal/interfaces/process-object/account/IAccountRegister";
import AccountRegisterCommonValidationCheck from "@keri-portal/module/method/validation-check/account-register/AccountRegisterCommonValidationCheck";
import RequestKeriAPI from "@keri-portal/module/method/api-connect/RequestKeriAPI";
import { IUserInfoInputError } from "@keri-portal/interfaces/process-object/account/IUserInfo";
import { IUploadFileVO } from "@keri-portal/interfaces/api/keri-api/object/IUploadFileVO";
import UserInputField from "@keri-portal/component/input-field/UserInputField";
import KeriBaseConfig from "@keri-base/config/KeriBaseConfig";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";








const KeriRegisterStep4Item = (prop: IKeriStepShareProps<IAccountRegister>) => {



    const requireFileData: IUploadFile<IUploadFileVO>[] = [
        {
            Index: 1,
            Label: '건강보험자격득실확인서 (또는 재직증명서)',
            Required: true,
            Category: 'UserAccount',
            Prefix: 'MBR_',
        }
    ]

    
    const [formData, setFormData] = useState<IAccountRegisterUser>(prop.result.data.AccountUserInfoData ?? {
        UserFiles: [...requireFileData],
        UserType : 'NORMAL'
    });
    const [errorData, setErrorData] = useState<IUserInfoInputError>({});

    

    const addEmptyFileItem = () => {
        setFormData({
            ...formData,
            UserFiles: [
                ...(formData.UserFiles ?? []),
                {
                    Index: formData.UserFiles ?? [].length !== 0 ? formData.UserFiles!.sort((x, y) => y.Index - x.Index)[0].Index + 1 : 1,
                    Label: '참고자료',
                    Category: 'UserAccount',
                    Prefix: 'MBR_',
                }
            ]
        })
    }

    const validationCheck = async (): Promise<boolean> => {
        let state: boolean = true;

        const valCheck = AccountRegisterCommonValidationCheck.NewUser(formData);
        if (!valCheck.state) {
            state = false;
            setErrorData(valCheck.errorData);
        }

        return state;
    }

    return (
        <KeriStepItemLayout
            {...prop}
            validationCheck={async () => {
                return await validationCheck();
            }}
            page={{
                movePrevious: (setup) => {
                    if (prop.result.data.ExistCompanyInfoData !== undefined) {
                        setup(prop.step.activeStep - 2);
                    }
                    else {
                        setup(prop.step.activeStep - 1);
                    }
                },
                moveNext: (setup, validation) => {
                    if (validation) {
                        prop.routeProp.EventFunction.EnableBackDrop(true);
                        RequestKeriAPI.RegisterAccount.User({
                            ...prop.result.data,
                            AccountUserInfoData: formData
                        })
                        .then ( res => {
                            if(res.state){
                                setup(prop.step.activeStep + 1);
                            }
                            else{
                                alert(res.message);    
                            }
                        })
                        .catch( err => {
                            alert(err.message);
                        })
                        .finally(() => {
                            prop.routeProp.EventFunction.EnableBackDrop(false);
                        })


                      
                    }
                }
            }}
        >
            <KeriStepItemContent title='개인 정보 입력'>
                <UserInputField
                    editMode="Edit"
                    dataObject={{
                        data: formData,
                        onChange: (item) => setFormData(item)
                    }}
                    error={{
                        data: errorData,
                        onChange: (item) => setErrorData(item)
                    }}
                />
                <Box
                    sx={{
                        mt: 1
                    }}
                >
                    <Typography variant="body2" sx={{ color: '#7171dd' }}>
                        ⚠ 아이디는 영문과 숫자를 조합하여 8자리 이상이어야 합니다. <br />
                        ⚠ 비밀번호는 영문, 숫자, 기호(@$!%*?&)를 조합하여 10자리 이상이어야 합니다.
                    </Typography>
                </Box>
            </KeriStepItemContent>
            <KeriStepItemContent
                title='제출 자료'
                endAdornment={
                    <KeriActionButton
                        label="참고자료 추가"
                        variant="outlined"
                        color="primary"
                        sx={{
                            m: 0,
                            // p: 0
                        }}
                        icon={<Add />}
                        onClick={() => addEmptyFileItem()}
                    />
                }
            >
                <KeriFileTable
                    error={{
                        isError: errorData.UserFiles !== undefined,
                        message: errorData.UserFiles
                    }}
                    denyFileExtension={KeriBaseConfig.DenyFileExtension}
                    fileMode="Upload"
                >
                    {

                        (formData.UserFiles ?? []).sort((x, y) => x.Index - y.Index).map((item, idx) => (
                            <KeriFileTableItem
                                fileObject={item}
                                fileMode='Upload'
                                displayObject={{
                                    displayTitle: (item) => item.Label,
                                    displayFileName: (item) => item.UploadedFile === undefined ? item.OriginFile?.name : item.UploadedFile.orignlFileNm,
                                    displayDeleteRow: (item) => !(item.Required ?? false)
                                }}
                                uploadProp={{
                                    denyFileExtension : KeriBaseConfig.DenyFileExtension
                                }}
                                event={{
                                    onFileChange: (changeItem) => {
                                        setFormData({
                                            ...formData,
                                            UserFiles: [
                                                ...(formData.UserFiles ?? []).filter(reqItem => reqItem.Index !== changeItem.Index),
                                                changeItem
                                            ]
                                        })


                                        if (errorData.UserFiles !== undefined) {
                                            setErrorData({
                                                UserFiles: undefined
                                            })
                                        }
                                    },
                                    onDeleteUploadFile: async (changeItem) => {
                                        setFormData({
                                            ...formData,
                                            UserFiles: [
                                                ...(formData.UserFiles ?? []).filter(reqItem => reqItem.Index !== changeItem.Index),
                                                {
                                                    ...changeItem,
                                                    UploadedFile: undefined,
                                                    OriginFile: undefined
                                                }
                                            ]
                                        })
                                        const apiRequest = await RequestKeriAPI.File.RemoveUploadTempFile(changeItem);

                                    },
                                    onDeleteRow: async (deleteItem) => {
                                        setFormData({
                                            ...formData,
                                            UserFiles: [
                                                ...(formData.UserFiles ?? []).filter(reqItem => reqItem.Index !== deleteItem.Index),
                                            ]
                                        })
                                        const apiRequest = await RequestKeriAPI.File.RemoveUploadTempFile(deleteItem);
                                    }
                                }}
                            />

                        ))
                    }
                  
                </KeriFileTable>
            </KeriStepItemContent>





        </KeriStepItemLayout>
    )
}

export default KeriRegisterStep4Item;