import KeriActionButton from "@ict-base/components/control/KeriActionButton";
import { ObjectUtils } from "@ict-base/module/utils/ObjectUtils";
import useLoginCheck from "@keri-portal/module/hook/useLoginCheck";
import RequestKeriAPI from "@keri-portal/module/method/api-connect/RequestKeriAPI";
import { Box, CircularProgress, Rating, TextField, Typography } from "@mui/material";
import { DefaultScrollDesign } from "@src/modules/styles/DefaultScrollDesign";
import React, { useEffect, useState } from "react";

interface IKeriBoardRating {
    boardID: string,
    boardItemID: string,
}

const KeriBoardRating = (prop: IKeriBoardRating) => {

    const userRedux = useLoginCheck();


    const [ratingData, setRatingData] = useState<{ rate: number, description?: string, complete?: boolean }>({ rate: 0 });
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const validationCheck = (): { state: boolean, message?: string } => {
        let state: boolean = true;
        let message: string | undefined = undefined;
        if (ratingData.rate === 0) {
            state = false;
            message = "별점을 입력해주세요.";
        }

        return {
            state: state,
            message: message
        }
    }

    const submitData = async () => {

        console.log(ratingData);

        const valCheck = validationCheck();

        if (valCheck.state) {
            setIsLoading(true);
            const submitRate = await RequestKeriAPI.Board.SetBoardRating(prop.boardID, prop.boardItemID, ratingData.rate, ratingData.description?.replaceAll('\n', '<br />'), userRedux?.token);

            if (submitRate.state) {
                setRatingData({
                    ...ratingData,
                    complete: true
                })
            }
            else {
                alert(submitRate.message);
            }

            setIsLoading(false);
        }
        else {
            alert(valCheck.message);
        }

    }


    useEffect(() => {
        setRatingData({ rate: 0 })
    }, [])


    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                pt: 1,
                pb: 1
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    border: '1px solid rgba(0,0,0,0.1)',
                    borderRadius: '5px',
                    p: 2
                }}
            >
                {
                    (ratingData.complete ?? false) ?
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '400px',
                                height: '70px'
                            }}
                        >
                            <Typography>
                                만족도 조사에 참여해주셔서 감사합니다.
                            </Typography>
                        </Box>
                        :
                        <React.Fragment>
                            {
                                isLoading
                                    ?
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            width: '400px',
                                            height: '70px'
                                        }}
                                    >
                                        <CircularProgress color="inherit" />
                                    </Box>
                                    :
                                    <React.Fragment>
                                        <Box
                                            sx={{
                                                minWidth: '100px',
                                                mr: 2
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    textAlign: 'center'
                                                }}
                                            >
                                                평가
                                            </Typography>
                                        </Box>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'start',
                                                flexDirection: 'column',
                                                mr: 2
                                            }}
                                        >
                                            <Rating
                                                value={ratingData.rate}
                                                onChange={(e, val) => {
                                                    setRatingData({
                                                        ...ratingData,
                                                        rate: val ?? 0
                                                    })
                                                }}
                                                sx={{
                                                    mb: 1
                                                }}
                                            />
                                            <TextField
                                                size="small"
                                                sx={{
                                                    width: '400px',
                                                    '&>div': {
                                                        p: 0.5
                                                    },
                                                    '& textarea': {
                                                        scrollbarWidth: '1px',
                                                        scrollMargin: 0,
                                                        '&::-webkit-scrollbar': {
                                                            width: '0.2em',
                                                            borderRadius: '5px'
                                                        },
                                                        '&::-webkit-scrollbar-track': {
                                                            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                                                            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
                                                        },
                                                        '&::-webkit-scrollbar-thumb': {
                                                            backgroundColor: 'rgba(0,0,0,.2)',
                                                        },
                                                        p: 1
                                                    }

                                                }}
                                                inputProps={{
                                                    style: {

                                                    }
                                                }}
                                                InputProps={{
                                                    sx: {
                                                        ...DefaultScrollDesign
                                                    }
                                                }}
                                                multiline
                                                maxRows={2}
                                                value={ratingData.description}
                                                onChange={(e) => {
                                                    setRatingData({
                                                        ...ratingData,
                                                        description: e.currentTarget.value ?? undefined
                                                    })
                                                }}
                                            />

                                        </Box>
                                        <Box
                                            sx={{
                                                minWidth: '100px',
                                                height: '100%',
                                            }}
                                        >
                                            <KeriActionButton
                                                variant="outlined"
                                                label="전송"
                                                sx={{
                                                    width: '100%',
                                                    height: '60px'
                                                }}
                                                onClick={() => {
                                                    submitData();
                                                }}
                                            />
                                        </Box>
                                    </React.Fragment>
                            }


                        </React.Fragment>
                }
            </Box>
        </Box>
    )
}

export default KeriBoardRating;