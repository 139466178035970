import { IKeriAPIState } from "@ict-base/module/service/ICTRequestAPI"
import CallAPI from "@ict-base/module/utils/CallAPI"
import { ObjectUtils } from "@ict-base/module/utils/ObjectUtils"
import { PlatformError } from "@keri-base/interface/common/PlatformError"
import { IListPagination } from "@keri-base/interface/control/IListPagination"
import KeriAPIStructure from "@keri-portal/config/KeriAPIStructure"
import { FAKEDATA_BOARD_DATA } from "@keri-portal/config/KeriFakeDatas"
import { IAPIChangePasswordNonLoginResult } from "@keri-portal/interfaces/api/keri-api/IKeriAPIResponse"
import { IUploadFileVO } from "@keri-portal/interfaces/api/keri-api/object/IUploadFileVO"
import { IKeriAPIAddBoardItemRequestBody, IKeriAPIDeleteBoardItemRequestBody, IKeriAPIEditBoardItemRequestBody, IKeriAPIGetBoardItemCommentRequestBody, IKeriAPIGetBoardItemRequestBody, IKeriAPIGetBoardListRequestBody, IKeriAPIGetFAQItemListRequestBody, IKeriAPIGetFAQItemRequestBody, IKeriAPISetBoardItemCommentRequestBody, IKeriAPISetBoardItemRatingRequestBody } from "@keri-portal/interfaces/api/keri-api/request/IKeriAPIBoardRequest"
import { APITechServiceType } from "@keri-portal/interfaces/api/keri-api/request/IKeriAPITechServiceRequest"
import { IKeriAPIAddBoardItemResult, IKeriAPIGetBoardItemCommentListResult, IKeriAPIGetBoardItemListResult, IKeriAPIGetBoardItemResult, IKeriAPIGetFAQItemListResult, IKeriAPIGetFAQItemResult } from "@keri-portal/interfaces/api/keri-api/response/IKeriAPIBoardResponse"
import { IUploadFile } from "@keri-portal/interfaces/control/IUploadFile"
import { IBoard, IBoardItem, IBoardItemComment, IFaqItem } from "@keri-portal/interfaces/data-object/data-common/IBoard"
import { TechServiceType } from "@keri-portal/interfaces/data-object/tech-service/ITechService"
import URLStructure from "@src/modules/structure/URLStructure"
import { AxiosError } from "axios"
import InterfaceExtensionMethod from "../../extension-method/InterfaceExtensionMethod"
import RequestKeriAPI, { IKeriAuthAPIState } from "../RequestKeriAPI"

/**
 * 게시판 관련 API Call Object
 * 
 * @author shlee
 * @since 2022.10.19
 * 
 */
const KeriBoardAPI = {
    GetBoardContentList: async (token?: string, boardID?: string, searchText?: string, page?: number, pagePerCount?: number): Promise<IGetBoardContent> => GetBoardContentList(token, boardID, searchText, page, pagePerCount),
    GetBoardItemData: async (token?: string, boardID?: string, boardItemID?: string, authKey?: string, isLock?: boolean): Promise<IGetBoardItemData> => GetBoardItemData(token, boardID, boardItemID, authKey, isLock),
    AddBoardData: async (prop: IAddBoardItemRequestParam): Promise<IKeriAuthAPIState> => AddBoardData(prop),
    DeleteBoardData: async (token?: string, boardID?: string, boardItemID?: string, authKey?: string): Promise<IKeriAuthAPIState> => DeleteBoardData(token, boardID, boardItemID, authKey),
    GetFaqContentList: async (searchText?: string, page?: number, type?: string, pagePerCount?: number): Promise<IGetFaqContentList> => GetFaqContentList(searchText, page, type, pagePerCount),
    GetFaqItemData: async (faqID?: string): Promise<IGetFaqItemData> => GetFaqItemData(faqID),
    SetBoardRating: async (boardID: string, boardItemID: string, rating: number, description?: string, token?: string): Promise<ISetBoardRating> => SetBoardRating(boardID, boardItemID, rating, description, token),
    EditBoardItem: async (boardID: string, boardItemID: string, title: string, content: string, token?: string, authKey?: string, addFiles?: string[], removeFiles?: string[]): Promise<IEditBoardItem> => EditBoardItem(boardID, boardItemID, title, content, token, authKey, addFiles, removeFiles),
    Comment: {
        GetCommentList: async (boardID: string, boardItemID: string, token?: string): Promise<IGetCommentList> => GetCommentList(boardID, boardItemID, token),
        SetComment: async (boardID: string, boardItemID: string, comment: string, token?: string): Promise<ISetComment> => SetComment(boardID, boardItemID, comment, token),
    }
}



interface IGetBoardContent extends IKeriAuthAPIState, IListPagination {
    resultData?: IBoard
}

const GetBoardContentList = async (token?: string, boardID?: string, searchText?: string, page?: number, pagePerCount?: number): Promise<IGetBoardContent> => {

    console.log('parapm', token, boardID, searchText, page, pagePerCount);

    let apiState: IGetBoardContent = {
        state: true,
        message: undefined,
        tokenValid: true,
        resultData: undefined,
        pageObject: {
            nowPage: page,
            totalPage: 1
        }
    }

    const reqBody: IKeriAPIGetBoardListRequestBody = {
        bbsId: boardID,
        pageIndex: page?.toString(),
        pageUnit: (pagePerCount ?? 10).toString(),
        searchWrd: searchText
    }

    await CallAPI<IKeriAPIGetBoardItemListResult>({
        url: KeriAPIStructure.Board.GetBoardItemList,
        method: "POST",
        requestBody: reqBody,
        token: token
    })
        .then(async res => {
            if (res.data.resultCode === 'S') {
                let errData: AxiosError = {
                    config: {},
                    isAxiosError: false,
                    name: "",
                    message: "",
                    toJSON: async () => ''
                };
                apiState.state = true;


                try {

                    let listImage: { key: string, url: string }[] = [];






                    let downloadList: { nttId: string, atchFileID: string, fileSn: string }[] = [];

                    res.data.bbsList.forEach(item => {
                        downloadList = [
                            ...downloadList,
                            ...item.atchFiles.filter(x => x.fileCategory === 'THUMBNAIL').map(x => ({
                                nttId: item.properties.nttId!.toString(),
                                atchFileID: x.atchFileId!,
                                fileSn: x.fileSn!
                            } as { nttId: string, atchFileID: string, fileSn: string }))
                        ]
                    })



                    const getFileDownload = new Promise<{ key: string, url: string }[]>(async (resolve, reject) => {
                        console.log(downloadList);
                        if (downloadList.length === 0) resolve([]);
                        let imgArr: { key: string, url: string }[] = [];


                        for (let downloadObject of downloadList) {
                            const getImage = await RequestKeriAPI.File.DownloadFileUsingAtchFileID(token, downloadObject.atchFileID ?? undefined, parseInt(downloadObject.fileSn!));

                            if (getImage.state) {
                                const imageURL = await ObjectUtils.CastBlobToURLStringAsync(getImage.data);
                                imgArr.push({ key: downloadObject.nttId!.toString(), url: imageURL });
                            }
                            else {
                                // reject(getImage.message);
                                throw new PlatformError("이미지를 가져올 수 없습니다.");
                            }
                            console.log('download');

                        }

                        resolve(imgArr);

                        // await downloadList.forEach(async (downloadObject, index, array) => {


                        //     const getImage = await RequestKeriAPI.File.DownloadFileUsingAtchFileID(token, downloadObject.atchFileID ?? undefined, parseInt(downloadObject.fileSn!));

                        //     if (getImage.state) {
                        //         const imageURL = await ObjectUtils.CastBlobToURLStringAsync(getImage.data);
                        //         imgArr.push({ key: downloadObject.nttId!.toString(), url: imageURL });
                        //     }
                        //     else {
                        //         // reject(getImage.message);
                        //         throw new PlatformError("이미지를 가져올 수 없습니다.");
                        //     }
                        //     console.log('download');
                        //     if (index === array.length - 1) resolve(imgArr);

                        // })
                    })

                    listImage = [
                        ...listImage,
                        ...(await getFileDownload)
                    ]

                    console.log('Download Image', listImage);

                    apiState.resultData = {
                        BoardID: res.data.bbsMasterInfo.bbsId!,
                        BoardList: res.data.bbsList.map(resItem => ({
                            BoardItemID: (resItem.properties.nttId ?? undefined)!.toString(),
                            BoardItemTitle: resItem.properties.nttSj!,
                            BoardItemContent: resItem.properties.nttCn ?? undefined,
                            BoardItemThumbnailURL: listImage.filter(x => x.key === resItem.properties.nttId!.toString()).map(x => x.url),
                            BoardItemWriteDate: ((resItem.properties.frstRegisterPnttm ?? undefined) === undefined || isNaN(Date.parse(resItem.properties.frstRegisterPnttm ?? 'undefined'))) ? undefined : new Date(Date.parse(resItem.properties.frstRegisterPnttm!)),
                            BoardItemWriter: resItem.properties.frstRegisterNm ?? undefined,
                            BoardItemViewCount: resItem.properties.inqireCo ?? 0,
                            IsLock: resItem.properties.secretAt === 'Y' ? true : false,
                            IsAnonymouse: resItem.properties.frstRegisterId === 'anonymous',
                            WriterUserCode: resItem.properties.frstRegisterId === 'anonymous' ? undefined : resItem.properties.frstRegisterId ?? undefined,
                            BoardState: resItem.properties.bbsSttus ?? undefined,
                            BoardStateName: resItem.properties.bbsSttusNm ?? undefined
                        })),
                        BoardTitle: res.data.bbsMasterInfo.bbsNm!
                    }

                    apiState.pageObject = {
                        nowPage: res.data.paginationInfo?.currentPageNo,
                        totalPage: res.data.paginationInfo?.lastPageNo
                    };
                }
                catch (e) {
                    if (e instanceof PlatformError) {
                        throw e;
                    }
                    else {
                        throw new PlatformError("데이터를 불러올 수 없습니다.", false);
                    }
                }


            }
            else {
                apiState.state = false;
                apiState.message = res.data.resultMessage;
            }

        })
        .catch((err) => {
            if (err instanceof AxiosError) {
                apiState.message = '서버와 연결이 원활하지 않습니다.'
                console.log(err);
            }
            else if (err instanceof PlatformError) {
                apiState.message = err.message
                if (!err.isSystemError) {
                    apiState.message = err.message;
                }
                else {
                    apiState.message = '시스템 오류입니다.'
                }
            }
            else {
                apiState.message = '시스템 오류입니다.'
            }
            apiState.state = false;
        })


    return apiState;
}


interface IGetBoardItemData extends IKeriAuthAPIState {
    resultData?: IBoard,
    fileList?: IUploadFile<IUploadFileVO>[]
}

const GetBoardItemData = async (token?: string, boardID?: string, boardItemID?: string, authKey?: string, isLock?: boolean): Promise<IGetBoardItemData> => {
    let apiState: IGetBoardItemData = {
        state: true,
        message: undefined,
        tokenValid: true,
        resultData: undefined,
        statusCode: undefined,
    }

    const reqBody: IKeriAPIGetBoardItemRequestBody = {
        bbsId: boardID,
        nttId: boardItemID,
        password: authKey,
        // secretAt: authKey !== undefined ? 'Y' : 'N'
        // secretAt: (isLock ?? false) ? 'Y' : 'N'
    }



    await CallAPI<IKeriAPIGetBoardItemResult>({
        url: KeriAPIStructure.Board.GetBoardItem,
        method: "POST",
        requestBody: reqBody,
        token: token
    })
        .then(res => {
            if (res.data.resultCode === 'S') {
                try {
                    let listImage: { key: string, url: string }[] = [];

                    try {


                        // res.data.bbsList.forEach(async (val) => {
                        // TODO : Image 로드 추가 필요
                        // const getImage = await RequestKeriAPI.File.DownloadFileUsingAtchFileID(token , val.atchFileId)
                        listImage.push({ key: res.data.bbsDetailInfo.nttId!.toString(), url: "https://velog.velcdn.com/images/real-bird/post/e78122ca-dd16-4ae6-832d-03d0edfb18cb/image.png" });
                        listImage.push({ key: res.data.bbsDetailInfo.nttId!.toString(), url: "https://uploads.sitepoint.com/wp-content/uploads/2022/08/1660112010typescript-code.png" });
                        // })
                    }
                    catch (e) {
                        throw new PlatformError("이미지를 불러올 수 없습니다.", false);
                    }

                    console.log()

                    apiState.statusCode = 200;
                    apiState.state = true;
                    apiState.resultData = {
                        BoardID: res.data.bbsMasterInfo.bbsId!,
                        BoardTitle: res.data.bbsMasterInfo.bbsNm!,
                        isAuthorization: res.data.bbsMasterInfo.readAuthor?.indexOf('ROLE_ANONYMOUS') === -1,
                        isEnableReply: res.data.bbsMasterInfo.replyPosblAt === 'Y',
                        BoardList: [
                            {
                                BoardItemID: (res.data.bbsDetailInfo.nttId ?? undefined)!.toString(),
                                BoardItemTitle: res.data.bbsDetailInfo.nttSj!,
                                BoardItemContent: res.data.bbsDetailInfo.nttCn ?? undefined,
                                BoardItemThumbnailURL: listImage.filter(x => x.key === res.data.bbsDetailInfo.nttId!.toString()).map(x => x.url),
                                BoardItemWriteDate: ((res.data.bbsDetailInfo.frstRegisterPnttm ?? undefined) === undefined || isNaN(Date.parse(res.data.bbsDetailInfo.frstRegisterPnttm ?? 'undefined'))) ? undefined : new Date(Date.parse(res.data.bbsDetailInfo.frstRegisterPnttm!)),
                                BoardItemWriter: ObjectUtils.IsNullOrEmpty(res.data.bbsDetailInfo.frstRegisterNm) ? (ObjectUtils.IsNullOrEmpty(res.data.bbsDetailInfo.ntcrNm) ? undefined : res.data.bbsDetailInfo.ntcrNm!) : res.data.bbsDetailInfo.frstRegisterNm!,
                                BoardItemViewCount: res.data.bbsDetailInfo.inqireCo ?? 0,
                                BoardItemUploadFiles: res.data.bbsAtchFiles.filter(x => x.fileCategory === 'BoardFile').map((uploadItem, idx) => ({
                                    Category: uploadItem.fileCategory ?? 'BoardFile',
                                    Index: idx,
                                    Label: uploadItem.orignlFileNm ?? '',
                                    Prefix: 'BBS_FILE',
                                    Required: false,
                                    UploadedFile: uploadItem
                                })),
                                CommentList: res.data.commentList.map(commItem => ({
                                    CommentContent: commItem.commentCn ?? '',
                                    CommentCreateDate: new Date(Date.parse(commItem.frstRegisterPnttm ?? '')),
                                    CommentWriter: commItem.wrterNm ?? '',
                                    CommentWriterCode: commItem.wrterId ?? ''
                                })),
                                BoardState : res.data.bbsDetailInfo.bbsSttus ?? undefined
                            }
                        ]
                    }

                    apiState.fileList = res.data.bbsAtchFiles.map((atchItem, index) => ({
                        Index: index,
                        Category: atchItem.fileCategory!,
                        Label: '',
                        Prefix: 'SHARE_BBS_',
                        UploadedFile: atchItem,
                        Required: false,
                    }))
                }
                catch (e) {
                    if (e instanceof PlatformError) {
                        throw e;
                    }
                    else {
                        throw new PlatformError("데이터를 불러올 수 없습니다.", false);
                    }
                }

            }
            else {
                apiState.state = false;
                apiState.message = res.data.resultMessage;
            }
        })
        .catch((err) => {
            if (err instanceof AxiosError) {
                apiState.statusCode = err.request.status;
                if (err.request.status === 403) {
                    apiState.message = '권한이 없습니다.'
                }
                else {
                    apiState.message = '서버와 연결이 원활하지 않습니다.'
                }
            }
            else if (err instanceof PlatformError) {
                apiState.message = err.message
                apiState.statusCode = 500;
                if (!err.isSystemError) {
                    apiState.message = err.message;
                }
                else {
                    apiState.message = '시스템 오류입니다.'
                }
            }
            else {
                apiState.statusCode = 500;
                apiState.message = '시스템 오류입니다.'
            }
            apiState.state = false;
        })


    return apiState;
}

interface IAddBoardItemRequestParam {
    token?: string,
    boardID?: string,
    startDate?: Date,
    endDate?: Date,
    content?: string,
    subject?: string,
    password?: string,
    writer?: string,
    showMain?: boolean,
    showTop?: boolean,
    enableReply?: boolean,
    addFiles?: string[],
}


const AddBoardData = async (prop: IAddBoardItemRequestParam): Promise<IKeriAuthAPIState> => {
    let apiState: IKeriAuthAPIState = {
        state: true,
        message: undefined,
        tokenValid: true
    }

    try {
        let reqBody: IKeriAPIAddBoardItemRequestBody = {};

        try {
            reqBody = {
                bbsId: prop.boardID,
                ntceBgnde: prop.startDate === undefined ? '1990-01-01' : ObjectUtils.DateToString(prop.startDate, 'yyyy-MM-dd'),
                ntceEndde: prop.endDate === undefined ? '9999-12-31' : ObjectUtils.DateToString(prop.endDate, 'yyyy-MM-dd'),
                nttCn: prop.content,
                nttSj: prop.subject,
                password: prop.password,
                // anonymousAt: prop.writer === undefined ? 'Y' : 'N',
                anonymousAt: 'Y',
                secretAt: (prop.password !== undefined || prop.token !== undefined) ? 'Y' : 'N',
                mainDisplayAt: (prop.showMain ?? false) ? 'Y' : 'N',
                topFixAt: (prop.showTop ?? false) ? 'Y' : 'N',
                ntcrNm: prop.writer,
                replyAt: (prop.enableReply ?? false) ? 'Y' : 'N',
                streFileNms: prop.addFiles
            }
        }
        catch (e) {
            throw new PlatformError("요청을 보낼 수 없습니다.", true);
        }


        await CallAPI<IKeriAPIAddBoardItemResult>({
            url: KeriAPIStructure.Board.AddBoardItem,
            method: "PUT",
            token: prop.token,
            requestBody: reqBody
        })
            .then(res => {
                try {
                    if (res.data.resultCode !== 'S') {
                        apiState.state = false;
                        apiState.message = res.data.resultMessage;
                    }
                }
                catch (e) {
                    throw new PlatformError("데이터를 읽어올 수 없습니다.", false);
                }
            })
            .catch((e) => {
                throw e;
            })

    }
    catch (err) {
        if (err instanceof AxiosError) {
            if (err.response?.status === 401) {
                apiState.message = '로그인이 만료되었습니다. \n다시 로그인해주시길 바랍니다.'
            }
            else if (err.response?.status === 403) {
                apiState.message = '권한이 없습니다.'
            }
            else {
                apiState.message = '서버와 연결이 원활하지 않습니다.'
            }
            console.log(err);
        }
        else if (err instanceof PlatformError) {
            apiState.message = err.message
            if (!err.isSystemError) {
                apiState.message = err.message;
            }
            else {
                apiState.message = err.message !== undefined ? err.message : '시스템 오류입니다.'
            }
        }
        else {
            apiState.message = '시스템 오류입니다.'
        }
        apiState.state = false;
    }




    return apiState;
}




const DeleteBoardData = async (token?: string, boardID?: string, boardItemID?: string, authKey?: string): Promise<IKeriAuthAPIState> => {
    let apiState: IKeriAuthAPIState = {
        state: true,
        message: undefined,
        tokenValid: true
    }

    try {
        let reqBody: IKeriAPIDeleteBoardItemRequestBody = {};


        try {
            reqBody = {
                bbsId: boardID!,
                nttId: boardItemID!,
                password: authKey
            }
        }
        catch (e) {
            throw new PlatformError("요청을 보낼 수 없습니다.", true);
        }


        await CallAPI<IKeriAPIAddBoardItemResult>({
            url: KeriAPIStructure.Board.DeleteBoardItem,
            method: "DELETE",
            token: token,
            requestBody: { data: reqBody }
        })
            .then(res => {
                try {
                    if (res.data.resultCode !== 'S') {
                        apiState.state = false;
                        apiState.message = res.data.resultMessage;
                    }
                }
                catch (e) {
                    throw new PlatformError("데이터를 읽어올 수 없습니다.", false);
                }
            })
            .catch((e) => {
                throw e;
            })

    }
    catch (err) {
        if (err instanceof AxiosError) {
            if (err.response?.status === 401) {
                apiState.message = '로그인이 만료되었습니다. \n다시 로그인해주시길 바랍니다.'
            }
            else if (err.response?.status === 403) {
                apiState.message = '권한이 없습니다.'
            }
            else {
                apiState.message = '서버와 연결이 원활하지 않습니다.'
            }
            console.log(err);
        }
        else if (err instanceof PlatformError) {
            apiState.message = err.message
            if (!err.isSystemError) {
                apiState.message = err.message;
            }
            else {
                apiState.message = err.message !== undefined ? err.message : '시스템 오류입니다.'
            }
        }
        else {
            apiState.message = '시스템 오류입니다.'
        }
        apiState.state = false;
    }




    return apiState;
}

interface IGetFaqContentList extends IKeriAPIState, IListPagination {
    resultData?: IFaqItem[],
    filterData?: {
        code: string,
        codeName: string
    }[],
}

const GetFaqContentList = async (searchText?: string, page?: number, type?: string, pagePerCount?: number): Promise<IGetFaqContentList> => {
    let apiState: IGetFaqContentList = {
        state: true,
        message: undefined,
        resultData: undefined,
        filterData: undefined,
        pageObject: {
            nowPage: 1,
            totalPage: 1
        }
    }

    try {
        let reqBody: IKeriAPIGetFAQItemListRequestBody = {};

        try {
            reqBody = {
                pageIndex: (page ?? 1).toString(),
                pageUnit: (pagePerCount ?? 10).toString(),
                qestnTyCd: type === 'ALL' ? undefined : type,
                searchWrd: searchText
            }
        }
        catch (e) {
            throw new PlatformError("요청을 보낼 수 없습니다.", true);
        }


        await CallAPI<IKeriAPIGetFAQItemListResult>({
            url: KeriAPIStructure.Board.GetFaqItemList,
            method: "POST",
            requestBody: reqBody
        })
            .then(res => {
                try {
                    if (res.data.resultCode !== 'S') {
                        apiState.state = false;
                        apiState.message = res.data.resultMessage;
                    } else if (res.data.resultCode === 'S') {

                        const checkThumbnail = (type: string): string[] => {
                            let thumbnail = [];

                            switch (type) {
                                case 'AR': thumbnail.push(`${URLStructure.Asset}/img/main-techservice-image/ar_bg.png`); break;
                                case 'CS': thumbnail.push(`${URLStructure.Asset}/img/main-techservice-image/ct_bg.png`); break;
                                case 'IS': thumbnail.push(`${URLStructure.Asset}/img/main-techservice-image/is_bg.png`); break;
                                case 'PS': thumbnail.push(`${URLStructure.Asset}/img/main-techservice-image/3d_bg.png`); break;
                                case 'PT': thumbnail.push(`${URLStructure.Asset}/img/main-techservice-image/pt_bg.png`); break;
                                case 'VR': thumbnail.push(`${URLStructure.Asset}/img/main-techservice-image/arvr_bg.png`); break;
                                default: thumbnail.push(`${URLStructure.Asset}/img/main-techservice-image/etc_bg.png`); break;
                            }

                            return thumbnail;
                        }

                        apiState.state = true;
                        apiState.resultData = res.data.resultList.map(x => ({
                            FaqID: x.faqId!,
                            FaqType: x.qestnTyCd!,
                            FaqTypeName: x.qestnTyNm!,
                            Subject: x.qestnSj!,
                            FaqQuestion: x.qestnCn!,
                            FaqAnswer: x.answerCn!,
                            FaqWriteDate: new Date(Date.parse(x.frstRegisterId!)),
                            viewCount: parseInt(x.inqireCo!),
                            FaqThumbnail: checkThumbnail(x.qestnTyCd!)
                        }))
                        apiState.filterData = res.data.qestnTyList.map(x => ({
                            code: x.code!,
                            codeName: x.codeNm!
                        }))
                        apiState.pageObject = {
                            nowPage: res.data.paginationInfo?.currentPageNo,
                            totalPage: res.data.paginationInfo?.lastPageNo
                        }
                    }
                }
                catch (e) {
                    throw new PlatformError("데이터를 읽어올 수 없습니다.", false);
                }
            })
            .catch((e) => {
                throw e;
            })

    }
    catch (err) {
        if (err instanceof AxiosError) {
            if (err.response?.status === 401) {
                apiState.message = '로그인이 만료되었습니다. \n다시 로그인해주시길 바랍니다.'
            }
            else if (err.response?.status === 403) {
                apiState.message = '권한이 없습니다.'
            }
            else {
                apiState.message = '서버와 연결이 원활하지 않습니다.'
            }
            console.log(err);
        }
        else if (err instanceof PlatformError) {
            apiState.message = err.message
            if (!err.isSystemError) {
                apiState.message = err.message;
            }
            else {
                apiState.message = err.message !== undefined ? err.message : '시스템 오류입니다.'
            }
        }
        else {
            apiState.message = '시스템 오류입니다.'
        }
        apiState.state = false;
    }




    return apiState;
}


interface IGetFaqItemData extends IKeriAPIState {
    resultData?: IFaqItem
}


const GetFaqItemData = async (faqID?: string): Promise<IGetFaqItemData> => {
    let apiState: IGetFaqItemData = {
        state: true,
        message: undefined,
        resultData: undefined,
    }

    try {
        let reqBody: IKeriAPIGetFAQItemRequestBody = {};

        try {
            reqBody = {
                faqId: faqID
            }
        }
        catch (e) {
            throw new PlatformError("요청을 보낼 수 없습니다.", true);
        }


        await CallAPI<IKeriAPIGetFAQItemResult>({
            url: KeriAPIStructure.Board.GetFaqItem,
            method: "POST",
            requestBody: reqBody
        })
            .then(res => {
                try {
                    if (res.data.resultCode !== 'S') {
                        apiState.state = false;
                        apiState.message = res.data.resultMessage;
                    } else if (res.data.resultCode === 'S') {

                        apiState.state = true;
                        apiState.resultData = {
                            FaqID: res.data.faqDetailInfo.faqId!,
                            FaqType: res.data.faqDetailInfo.qestnTyCd!,
                            FaqTypeName: res.data.faqDetailInfo.qestnTyNm!,
                            Subject: res.data.faqDetailInfo.qestnSj!,
                            FaqQuestion: res.data.faqDetailInfo.qestnCn!,
                            FaqAnswer: res.data.faqDetailInfo.answerCn!,
                            FaqWriteDate: new Date(Date.parse(res.data.faqDetailInfo.frstRegisterId!)),
                            viewCount: parseInt(res.data.faqDetailInfo.inqireCo!),
                            FaqThumbnail: []
                        }

                    }
                }
                catch (e) {
                    throw new PlatformError("데이터를 읽어올 수 없습니다.", false);
                }
            })
            .catch((e) => {
                throw e;
            })

    }
    catch (err) {
        if (err instanceof AxiosError) {
            if (err.response?.status === 401) {
                apiState.message = '로그인이 만료되었습니다. \n다시 로그인해주시길 바랍니다.'
            }
            else if (err.response?.status === 403) {
                apiState.message = '권한이 없습니다.'
            }
            else {
                apiState.message = '서버와 연결이 원활하지 않습니다.'
            }
            console.log(err);
        }
        else if (err instanceof PlatformError) {
            apiState.message = err.message
            if (!err.isSystemError) {
                apiState.message = err.message;
            }
            else {
                apiState.message = err.message !== undefined ? err.message : '시스템 오류입니다.'
            }
        }
        else {
            apiState.message = '시스템 오류입니다.'
        }
        apiState.state = false;
    }




    return apiState;
}

interface ISetBoardRating extends IKeriAPIState {

}

const SetBoardRating = async (boardID: string, boardItemID: string, rating: number, description?: string, token?: string): Promise<ISetBoardRating> => {
    let apiState: IGetFaqItemData = {
        state: true,
        message: undefined,
        resultData: undefined,
    }

    try {
        let reqBody: IKeriAPISetBoardItemRatingRequestBody = {};

        try {
            reqBody = {
                bbsId: boardID,
                nttId: boardItemID,
                stsfdg: rating.toString(),
                stsfdgCn: description ?? ''
            }
        }
        catch (e) {
            throw new PlatformError("요청을 보낼 수 없습니다.", true);
        }


        await CallAPI<IKeriAPIGetFAQItemResult>({
            url: KeriAPIStructure.Board.SetRating,
            method: "PUT",
            requestBody: reqBody,
            token: token
        })
            .then(res => {
                try {
                    if (res.data.resultCode !== 'S') {
                        apiState.state = false;
                        apiState.message = res.data.resultMessage;
                    } else if (res.data.resultCode === 'S') {

                        apiState.state = true;
                    }
                }
                catch (e) {
                    throw new PlatformError("데이터를 읽어올 수 없습니다.", false);
                }
            })
            .catch((e) => {
                throw e;
            })

    }
    catch (err) {
        if (err instanceof AxiosError) {
            if (err.response?.status === 401) {
                apiState.message = '로그인이 만료되었습니다. \n다시 로그인해주시길 바랍니다.'
            }
            else if (err.response?.status === 403) {
                apiState.message = '권한이 없습니다.'
            }
            else {
                apiState.message = '서버와 연결이 원활하지 않습니다.'
            }
            console.log(err);
        }
        else if (err instanceof PlatformError) {
            apiState.message = err.message
            if (!err.isSystemError) {
                apiState.message = err.message;
            }
            else {
                apiState.message = err.message !== undefined ? err.message : '시스템 오류입니다.'
            }
        }
        else {
            apiState.message = '시스템 오류입니다.'
        }
        apiState.state = false;
    }




    return apiState;
}


interface IEditBoardItem extends IKeriAPIState {

}

const EditBoardItem = async (boardID: string, boardItemID: string, title: string, content: string, token?: string, authKey?: string, addFiles?: string[], removeFiles?: string[]): Promise<IEditBoardItem> => {
    let apiState: IGetFaqItemData = {
        state: true,
        message: undefined,
        resultData: undefined,
    }

    try {
        let reqBody: IKeriAPIEditBoardItemRequestBody = {};

        try {
            reqBody = {
                bbsId: boardID,
                nttId: boardItemID,
                nttCn: content,
                nttSj: title,
                oldPassword: authKey,
                streFileNms: addFiles,
                deleteFileNms: removeFiles
            }
        }
        catch (e) {
            throw new PlatformError("요청을 보낼 수 없습니다.", true);
        }


        await CallAPI<IKeriAPIGetFAQItemResult>({
            url: KeriAPIStructure.Board.EditBoardItem,
            method: "PUT",
            requestBody: reqBody,
            token: token
        })
            .then(res => {
                try {
                    if (res.data.resultCode !== 'S') {
                        apiState.state = false;
                        apiState.message = res.data.resultMessage;
                    } else if (res.data.resultCode === 'S') {

                        apiState.state = true;
                    }
                }
                catch (e) {
                    throw new PlatformError("데이터를 읽어올 수 없습니다.", false);
                }
            })
            .catch((e) => {
                throw e;
            })

    }
    catch (err) {
        if (err instanceof AxiosError) {
            if (err.response?.status === 401) {
                apiState.message = '로그인이 만료되었습니다. \n다시 로그인해주시길 바랍니다.'
            }
            else if (err.response?.status === 403) {
                apiState.message = '권한이 없습니다.'
            }
            else {
                apiState.message = '서버와 연결이 원활하지 않습니다.'
            }
            console.log(err);
        }
        else if (err instanceof PlatformError) {
            apiState.message = err.message
            if (!err.isSystemError) {
                apiState.message = err.message;
            }
            else {
                apiState.message = err.message !== undefined ? err.message : '시스템 오류입니다.'
            }
        }
        else {
            apiState.message = '시스템 오류입니다.'
        }
        apiState.state = false;
    }




    return apiState;
}

interface IGetCommentList extends IKeriAPIState {
    resultData: IBoardItemComment[]
}

const GetCommentList = async (boardID: string, boardItemID: string, token?: string): Promise<IGetCommentList> => {
    let apiState: IGetCommentList = {
        state: true,
        message: undefined,
        resultData: [],
        statusCode: undefined,
    }

    const reqBody: IKeriAPIGetBoardItemCommentRequestBody = {
        bbsId: boardID,
        nttId: boardItemID,
    }



    await CallAPI<IKeriAPIGetBoardItemCommentListResult>({
        url: KeriAPIStructure.Board.GetBoardItemComment,
        method: "POST",
        requestBody: reqBody,
        token: token
    })
        .then(res => {
            if (res.data.resultCode === 'S') {
                try {
                    apiState.statusCode = 200;
                    apiState.state = true;
                    apiState.resultData = res.data.commentList.map(voItem => ({
                        CommentContent: voItem.commentCn ?? '',
                        CommentCreateDate: new Date(Date.parse(voItem.frstRegisterPnttm ?? '')),
                        CommentWriter: voItem.wrterNm ?? '',
                        CommentWriterCode: voItem.wrterId ?? ''
                    }));
                }
                catch (e) {
                    if (e instanceof PlatformError) {
                        throw e;
                    }
                    else {
                        throw new PlatformError("데이터를 불러올 수 없습니다.", false);
                    }
                }

            }
            else {
                apiState.state = false;
                apiState.message = res.data.resultMessage;
            }
        })
        .catch((err) => {
            if (err instanceof AxiosError) {
                apiState.statusCode = err.request.status;
                if (err.request.status === 403) {
                    apiState.message = '권한이 없습니다.'
                }
                else {
                    apiState.message = '서버와 연결이 원활하지 않습니다.'
                }
            }
            else if (err instanceof PlatformError) {
                apiState.message = err.message
                apiState.statusCode = 500;
                if (!err.isSystemError) {
                    apiState.message = err.message;
                }
                else {
                    apiState.message = '시스템 오류입니다.'
                }
            }
            else {
                apiState.statusCode = 500;
                apiState.message = '시스템 오류입니다.'
            }
            apiState.state = false;
        })


    return apiState;
}


interface ISetComment extends IKeriAPIState {

}

const SetComment = async (boardID: string, boardItemID: string, comment: string, token?: string): Promise<ISetComment> => {
    let apiState: ISetComment = {
        state: true,
        message: undefined,
        statusCode: undefined,
    }

    console.log(token);

    const reqBody: IKeriAPISetBoardItemCommentRequestBody = {
        bbsId: boardID,
        nttId: boardItemID,
        wrterNm: token !== undefined ? undefined : 'anonymous',
        commentCn: comment
    }



    await CallAPI<IKeriAPIGetBoardItemCommentListResult>({
        url: KeriAPIStructure.Board.SetBoardItemComment,
        method: "PUT",
        requestBody: reqBody,
        token: token
    })
        .then(res => {
            if (res.data.resultCode === 'S') {
                try {
                    apiState.statusCode = 200;
                    apiState.state = true;
                }
                catch (e) {
                    if (e instanceof PlatformError) {
                        throw e;
                    }
                    else {
                        throw new PlatformError("데이터를 불러올 수 없습니다.", false);
                    }
                }

            }
            else {
                apiState.state = false;
                apiState.message = res.data.resultMessage;
            }
        })
        .catch((err) => {
            if (err instanceof AxiosError) {
                apiState.statusCode = err.request.status;
                if (err.request.status === 403) {
                    apiState.message = '권한이 없습니다.'
                }
                else {
                    apiState.message = '서버와 연결이 원활하지 않습니다.'
                }
            }
            else if (err instanceof PlatformError) {
                apiState.message = err.message
                apiState.statusCode = 500;
                if (!err.isSystemError) {
                    apiState.message = err.message;
                }
                else {
                    apiState.message = '시스템 오류입니다.'
                }
            }
            else {
                apiState.statusCode = 500;
                apiState.message = '시스템 오류입니다.'
            }
            apiState.state = false;
        })


    return apiState;
}

export default KeriBoardAPI;