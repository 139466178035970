import { ObjectUtils } from "@ict-base/module/utils/ObjectUtils";
import { Error, Refresh } from "@mui/icons-material";
import { Typography, IconButton, Box } from "@mui/material";

interface IMISCTechServiceSectionErrorBox {
  refreshFunction?: () => void;
  errorMessage?: string;
}

export const MISCTechServiceSectionErrorBox = (prop: IMISCTechServiceSectionErrorBox) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "150px",
        flexDirection: "column",
      }}
    >
      <Error color="error" sx={{ fontSize: "2rem", mb: 1.5 }} />
      <Typography variant="h6">
        {ObjectUtils.IsNullOrEmpty(prop.errorMessage) ? '오류가 발생했습니다.' : prop.errorMessage}
      </Typography>
      <IconButton
        onClick={() => prop.refreshFunction && prop.refreshFunction()}
        disableRipple
      >
        <Refresh
          sx={{
            color: "rgba(0,0,0,0.3)",
          }}
        />
      </IconButton>
    </Box>
  )
}
