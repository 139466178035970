import { useEffect, useState } from "react";
import { ObjectUtils } from "@ict-base/module/utils/ObjectUtils";
import KeriSelectItemModal from "./KeriSelectItemModal";
import RequestKeriAPI from "@keri-portal/module/method/api-connect/RequestKeriAPI";
import { ICompanyInfo } from "@keri-portal/interfaces/data-object/data-common/ICompanyInfo";

interface IKeriSelectCompanyModal {
    open: boolean
    onClose: () => void
    onSubmit: (item: ICompanyInfo | undefined) => void
}



const KeriSearchCompanyModal = (prop: IKeriSelectCompanyModal) => {
    const [loadData, setLoadData] = useState<ICompanyInfo[]>([]);
    const [page, setPage] = useState<number>(1);
    const [pageMaxCount, setPageMaxCount] = useState<number>(1);
    const [searchText, setSearchText] = useState<string>('');
    const [startProcess, setStartProcess] = useState<boolean>(false);

    const getSearchAddressFromAPI = (text?: string) => {
        const reqText = (ObjectUtils.IsNullOrEmpty(text) ? searchText : text);
        if (!ObjectUtils.IsNullOrEmpty(reqText)) {
            RequestKeriAPI.Account.LoadCompany(reqText!)
                .then((res) => {
                    if (res.state) {
                        if (res.data !== undefined) {
                            setLoadData([res.data]);
                        }
                        setPageMaxCount(1);
                    }
                    else {
                        apiError({
                            code: 'E',
                            message: res.message,
                        })
                    }
                })
                .catch(err => apiError({ code: err.code, message: err.message }))
        }
        else {
            alert('검색값이 빈 값입니다.')
        }
    }



    const apiError = (err: { code: string | undefined, message: string | undefined }) => {
        setLoadData([]);
        if (err.code === 'E') {
            alert(err.message);
        }
    }

    useEffect(() => {
        if (startProcess) {
            getSearchAddressFromAPI();
        }
    }, [page, searchText])

    return (
        <KeriSelectItemModal
            open={prop.open}
            onClose={() => {
                setStartProcess(false);
                prop.onClose();
            }}
            dataObject={{
                onSubmit: (item) => {
                    if (!Array.isArray(item)) {
                        prop.onSubmit(item as ICompanyInfo | undefined);
                    }
                    else {
                        alert('error');
                    }
                },
                value: loadData
            }}
            displayObject={{
                windowTitle: '기업정보 검색',
                displayItemTitle: (obj) => obj.BusinessName!,
                displayItemSubTitle: (obj) => obj.CorporateRegistrationNumber!,
                displayItemCode: (obj) => obj.CompanyRegistrationNumber!
            }}
            searchProp={{
                onSearch: (text) => {
                    setStartProcess(true);
                    getSearchAddressFromAPI(text);
                },
                maskRegex: (text) => text.replace(/[^0-9]/g, '').replace(/^(\d{3})(\d{2})(\d{5})$/, `$1-$2-$3`),
                placeHolder: "사업자등록번호 입력"
            }}
            modalProp={{
                windowWidth: 600
            }}
            paging={{
                count: pageMaxCount,
                page: page,
                onPageChange: (changePage: number) => {
                    setPage(changePage);
                }
            }}
        />
    )
}

export default KeriSearchCompanyModal;