import React from "react";

export const ObjectUtils = {
    IsNullOrEmpty: (value: string | JSX.Element | undefined | null): boolean => {
        let isEmpty: boolean = false;


        if (value === undefined || value === null) {
            isEmpty = true;
        }
        else {
            if (typeof (value) === 'string') {
                if ((value as string).trim() === '') {
                    isEmpty = true;
                }
            }
        }


        return isEmpty;
    },
    /**
     * byte to any
     * @param value 
     * @param fixedCount 
     * @returns 
     */
    FileSizeFormater: (value: string | number, fixedCount: number = 2): string => {

        let resultValue: string = '';
        const numValue: number = typeof (value) === 'number' ? value : parseFloat(value);

        if (numValue / 1024 < 1) {
            resultValue = (numValue).toFixed(fixedCount) + ' Byte';
        }
        else if (numValue / 1024 / 1024 < 1) {
            resultValue = (numValue / 1024).toFixed(fixedCount) + ' KB';
        }
        else if (numValue / 1024 / 1024 / 1024 < 1) {
            resultValue = (numValue / 1024 / 1024).toFixed(fixedCount) + ' MB';
        }
        else if (numValue / 1024 / 1024 / 1024 / 1024 < 1) {
            resultValue = (numValue / 1024 / 1024 / 1024).toFixed(fixedCount) + ' GB';
        }
        else if (numValue / 1024 / 1024 / 1024 / 1024 / 1024 < 1) {
            resultValue = (numValue / 1024 / 1024 / 1024 / 1024).toFixed(fixedCount) + ' TB';
        }
        else if (numValue / 1024 / 1024 / 1024 / 1024 / 1024 / 1024 < 1) {
            resultValue = (numValue / 1024 / 1024 / 1024 / 1024).toFixed(fixedCount) + ' PB';
        }

        return resultValue;
    },
    DateToString: (date: Date | number | string | null | undefined, format: string = 'yyyy-MM-dd HH:mm:ss'): string | undefined => {

        if (date === null || date === undefined) {
            return undefined;
        }
        else if (isNaN(new Date(date).getTime()!)) {
            return undefined;
        }
        // else if(!isNaN(Date.parse('foo')))

        let dt: Date = new Date(date);
        format = format.replace('yyyy', dt.getFullYear().toString());
        format = format.replace('yy', dt.getFullYear().toString().slice(2));
        format = format.replace('MM', (fixDateStringLength(dt.getMonth() + 1)).toString());
        format = format.replace('M', (dt.getMonth() + 1).toString());
        format = format.replace('dd', fixDateStringLength(dt.getDate()));
        format = format.replace('d', `${dt.getDate()}`);
        format = format.replace('hh', fixDateStringLength(dt.getHours()));
        format = format.replace('HH', fixDateStringLength(dt.getHours()));
        format = format.replace('mm', fixDateStringLength(dt.getMinutes()));
        format = format.replace('ss', fixDateStringLength(dt.getSeconds()));
        format = format.replace('SS', dt.getMilliseconds().toString());
        // const str = `${dt.getFullYear()}.${fixDateStringLength(dt.getMonth() + 1)}.${fixDateStringLength(dt.getDate())} ${fixDateStringLength(dt.getHours())}:${fixDateStringLength(dt.getMinutes())}:${fixDateStringLength(dt.getSeconds())}`;
        return format;
    },
    RandomStrings: (length: number) => {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() *
                charactersLength));
        }
        return result;
    },
    DonwnloadJsonFile: (jsonObject: any, fileName: string): void => {
        const jsonFile = new Blob([JSON.stringify(jsonObject)], { type: 'text/plain' });
        const element = document.createElement("a");

        element.href = URL.createObjectURL(jsonFile);
        element.download = fileName;
        document.body.appendChild(element);
        element.click();
        element.remove();
    },
    CastBlobToURLStringAsync: async (blob: Blob): Promise<string> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = async () => {
                try {
                    // const response = reader.result;
                    // Resolve the promise with the response value
                    resolve(reader.result as string);
                } catch (err) {
                    reject(err);
                }
            };
            reader.onerror = (error) => {
                reject(error);
            };
            reader.readAsDataURL(blob);
        });
    },
    DecodeHTMLCode: (htmlCode: string | undefined): string | undefined => {
        return htmlCode === undefined
            ? undefined
            : htmlCode
                .replaceAll(/<(.*?)>/g, '')
                .replaceAll('&lt;', '<')
                .replaceAll('&gt;', '>')
                .replaceAll('&nbsp;', '')
                .replaceAll('&ldquo;', '“')
                .replaceAll('&rdquo;', '”')
                .replaceAll('&middot;', '·')
                .replaceAll('&lsquo;','‘')
                .replaceAll('&sim;','∼')
                .replaceAll('&rsquo;','’')
                .replaceAll('&rarr;','’')
                .replaceAll(/<!--(.*?)-->/g,'')
            ;
    }

}


const fixDateStringLength = (dt: number): string => {
    let setString: string = `${dt}`;
    if (setString.length < 2) {
        setString = `0${dt}`;
    }

    return setString;
}